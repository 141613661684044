import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {UserBasicProvozovnaKod} from "./User";
import {MomentConverters} from "./Converters";
import {Moment} from "moment";

export enum KilometrovnikHlaseniStatus {NEW, PROCESSED}

export class KilomentrovnikHlaseniBase {
    id: number;
    osrmId: number;
    @JsonProperty({type: {enum: KilometrovnikHlaseniStatus}})
    status: KilometrovnikHlaseniStatus;
}

export class KilometrovnikHlaseniUser {
    osrmId: number;
    report: string;
}

export class KilomentrovnikHlaseniAdminList extends KilomentrovnikHlaseniBase {
    @JsonProperty({type:{clazz:UserBasicProvozovnaKod}})
    uzivatel: UserBasicProvozovnaKod

    @JsonProperty({converters:MomentConverters})
    datIns: Moment;

    @JsonProperty({converters:MomentConverters})
    datUzavreni: Moment;
}

export class KilomentrovnikHlaseniAdminDetail extends KilomentrovnikHlaseniBase {
    report: string;
    @JsonProperty({type:{clazz:UserBasicProvozovnaKod}})
    uzivatel: UserBasicProvozovnaKod
}

export class KilomentrovnikHlaseniFilter {
    @JsonProperty({type: {enum: KilometrovnikHlaseniStatus}})
    status: KilometrovnikHlaseniStatus;
}