import React from "react";
import {createStyles, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {PrepravyCiselnikView} from "./prohlizeni/PrepravyCiselnikView";
import {VlastniVozyCiselnikView} from "./prohlizeni/VlastniVozyCiselnikView";
import {PrepravyCiselnik} from "./zadani/PrepravyCiselnik";
import {VlastniVozyCiselnik} from "./zadani/VlastniVozyCiselnik";
import InzerceCiselnik from "./zadani/InzerceCiselnik";
import InzerceCiselnikView from "./prohlizeni/InzerceViewCiselnik";
import {UserRole} from "../../../model/User";
import {getBoolean} from "../../../../common/utils/Util";
import {useAppContext} from "../../../context/AppContext";
import {useData} from "../../../context/DataContext";
import {SystemParameter, SystemParamKey} from "../../../model/SystemParameter";
import {ComponentMode} from "../../../routes";
import {useLocation} from "react-router";

const useStyles = makeStyles((theme) => createStyles({
    cell: {
        marginTop: theme.spacing(4),
        overflow: "auto",
        display: "block",
        width: "100%",
        '& div.MuiPaper-rounded': {
            overflow: "hidden",
            width: "100%"
        },
        '& div.Box-class': {
            width: "100%"
        },
    },
    firstCell: {
        overflow: "auto",
        display: "block",
        width: "100%",
        '& div.MuiPaper-rounded': {
            overflow: "hidden",
            width: "100%"
        },
    },
    item: {
        overflow: "auto",
    }
}));

export function PviCiselnikProhlizeni() {
    const classes = useStyles();
    const {hasUserRole} = useAppContext();
    const [hideInzerce] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === SystemParamKey.HIDE_INZERCE));
    const {pathname} = useLocation();
    return <Grid container>
        <Grid item className={classes.item}>
            <Grid container className={classes.firstCell}>
                <PrepravyCiselnikView id={"pvi_preprava"} cacheGroup={"pvi_prohlizeni"} isTabbed={true} filter={{own: true}} pvi basePath={`${pathname}/prepravy`} mode={ComponentMode.GridMode} minimumTableHeight={500}/>
            </Grid>

            <Grid container className={classes.cell}>
                <VlastniVozyCiselnikView id={"pvi_vozy"} cacheGroup={"pvi_prohlizeni"} isTabbed={true} filter={{own: true}} pvi basePath={`${pathname}/volnevozy`} mode={ComponentMode.GridMode} minimumTableHeight={500}/>
            </Grid>

            {(hasUserRole(UserRole.ROLE_ADMIN) || !getBoolean(hideInzerce.value)) &&
            <Grid container className={classes.cell}>
                <InzerceCiselnikView id={"pvi_inzerce"} cacheGroup={"pvi_prohlizeni"} isTabbed={true} filter={{own: true}} pvi basePath={`${pathname}/inzerce`} mode={ComponentMode.GridMode} minimumTableHeight={500}/>
            </Grid>}
        </Grid>
    </Grid>
}

export function PviCiselnikZadani() {
    const classes = useStyles();
    const {hasUserRole} = useAppContext();
    const [hideInzerce] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === SystemParamKey.HIDE_INZERCE));
    const {pathname} = useLocation();
    return <Grid container>
        <Grid item className={classes.item}>
            <Grid container className={classes.firstCell}>
                <PrepravyCiselnik isTabbed={true} cacheGroup={'pvi_zadani'} basePath={`${pathname}/prepravy`} mode={ComponentMode.GridMode} minimumTableHeight={500}/>
            </Grid>

            <Grid container className={classes.cell}>
                <VlastniVozyCiselnik isTabbed={true} cacheGroup={'pvi_zadani'} autofocus={false} basePath={`${pathname}/volnevozy`} mode={ComponentMode.GridMode} minimumTableHeight={500}/>
            </Grid>

            {(hasUserRole(UserRole.ROLE_ADMIN) || !getBoolean(hideInzerce.value)) &&
            <Grid container className={classes.cell}>
                <InzerceCiselnik isTabbed={true} cacheGroup={'pvi_zadani'} autofocus={false} basePath={`${pathname}/inzerce`} mode={ComponentMode.GridMode} minimumTableHeight={500}/>
            </Grid>}
        </Grid>
    </Grid>
}
