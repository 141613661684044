import {LatLng} from "leaflet";
import {exist} from "../../common/utils/Util";


export class Kilometrovnik {
    id: number;
    mpz: string;
    psc: string;
    nazev: string;
    lon: number;
    lat: number;
    cc2: string;
    typ: number;
    enabled: boolean;

    setPointAsLatLng(latLng:LatLng) {
        this.lon = latLng.lng;
        this.lat = latLng.lat;
    }

    getLatLng() {
        return exist(this.lat) && exist(this.lon) ? new LatLng(this.lat, this.lon) : undefined;
    }
}
