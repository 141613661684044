import * as React from "react";
import {Box, Tooltip} from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {ARContext} from "./AutoRefresh";
import {DGContext} from "./raal_components/grid/DataGrid";
import useSound from 'use-sound';
import {makeStyles} from "@material-ui/core/styles";
import {createStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import DataStorage from "../common/DataStorage";
import {CrudUpdateEvent} from "./model/CrudUpdate";

const useStyles = makeStyles((theme) =>
    createStyles({
        alert: {
            transition: "0.3s all ease",
            display: "flex",
            borderRadius: "50%",
            padding: "4px",
            marginRight: "10px",
        },
        alertDisabled: {
            backgroundColor: theme.palette.type === 'dark' ? "#333333" : "#e6e6e6",
        },
        alertEnabled: {
            backgroundColor: theme.palette.type === 'dark' ? "#b15800" : "#ffbf77",
        },
        alertLocked: {
            backgroundColor: theme.palette.type === 'dark' ? "#bb0000" : "#ff4d4d",
        },
    }),
);

const isAudioLocked = () : Promise<boolean>  => {
    return new Promise(resolve => {
        const checkHTML5Audio = async () => {
            const audio = new Audio();
            try {
                await audio.play();
                resolve(false);
            } catch (err) {
                resolve(true);
            }
        };
        try {
            const context = new (window.AudioContext)();
            setTimeout(() => {
                resolve(context.state === 'suspended');
            }, 100);
        } catch (e) {
            checkHTML5Audio().then();
        }
    });
};

const key = '_alertEnabled';

export function RefreshAlert() {
    const {table, setState, name} = useContext(DGContext);
    const [audioLocked, setAudioLocked] = useState(false);
    const [alertEnabled, setAlertEnabled] = useState(
        (DataStorage.get(`${name}${key}`, false, "session") ?? `false`) === 'true'
    );
    const [arState] = useContext(ARContext);
    const {enabled, totalAdded} = arState;
    const lastCount = useRef(0);
    const canPlayNotification = useRef(true);
    const [playNotification] = useSound("/sounds/notification.mp3");
    const classes = useStyles();
    const {t} = useTranslation();

    useEffect(()=> {
        (async () => {
            setAudioLocked(await isAudioLocked());
        })();
    });

    useEffect(()=>{
        if(!audioLocked && alertEnabled && canPlayNotification.current && totalAdded > lastCount.current){
            playNotification();
            canPlayNotification.current = false;
            setTimeout(() => {
                canPlayNotification.current = true;
            }, 5000);
        }
        lastCount.current = totalAdded;
    }, [enabled, totalAdded, setState, table, audioLocked, alertEnabled, playNotification]);

    useEffect(()=>{
        DataStorage.set(`${name}${key}`, alertEnabled, false, "session");
    }, [alertEnabled, name]);

    return <Box className={`${classes.alert} ${alertEnabled ? (audioLocked ? classes.alertLocked : classes.alertEnabled) : classes.alertDisabled}`}>
        <Tooltip
            title={t(alertEnabled ? (audioLocked ? "RefreshAlert.TooltipInactive" : "RefreshAlert.TooltipOn")  : "RefreshAlert.TooltipOff")}
            key={alertEnabled ? "notificationOn" : "notificationOff"}
            onClick={() => {
                if(audioLocked) setAudioLocked(false); else setAlertEnabled(!alertEnabled);
            }}
            placement={"bottom-start"}
        >
            {alertEnabled && !audioLocked ? <NotificationsActiveIcon fontSize={"small"}/> : <NotificationsOffIcon fontSize={"small"}/>}
        </Tooltip>

    </Box>
}