import * as React from "react";
import {CSSProperties, MouseEventHandler, MutableRefObject, ReactElement, Ref} from "react";
import {FormInputType} from "../form/Form";
import {SelectProps} from "../../../common/component/form/FormSelect";
import {CustomFieldComponent} from "../../../common/component/form/FormFieldInterface";
import {CustomDateTimePickerOptions} from "../../../common/component/form/FormDatetime";
import {Mapper} from "../../../common/utils/objectmapper/Mapper";
import {FieldError} from "../../../common/component/form/ValidationError";
import {Moment} from "moment";
import {GenericMap} from "../../../index.d";
import {PropTypes, TextFieldProps} from "@material-ui/core";
import {TemplatesFunctions} from "./MTOverrides";
import {SvgIconComponent} from "@material-ui/icons";
import {CrudOperationsEnabled, DGExposed} from "./DataGrid";
import {Options} from "material-table";
import {LockSupport} from "../controller/CodeBookController.d";
import {ExportConfig} from "./MTExportContainer";
import {ConnectedCols} from "./MTToolbar";
import {FormButtonProps} from "../form/FormButton";
import {TableExposed} from "./table/TableComponent";

export type ToolbarAction = {
    icon: string|ReactElement
    tooltip: string
    onClick: VoidFunction
}

export type ToolbarButtonAction = {
    icon: string|ReactElement
    tooltip: string
    onClick: MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
    color?: PropTypes.Color
}

export type MultiColumnToolbarAction<T> = ToolbarAction & {
    onClick: (e: Event, selectedItems: T[]) => void;
    keyMappings?: string | string[];
}

export type DataGridFieldProps<Options=any> = {
    type:FormInputType|string
    disabled?:boolean,
    placeholder?:string
    dataTip?:string
    value?:any,
    selectProps?: SelectProps
    textFieldProps?: TextFieldProps
    customComponent?: CustomFieldComponent | any
    customComponentOptions?: Options
    dateTimeOptions?: CustomDateTimePickerOptions
    onChange?:(value:any, data:any, setData:(data:any)=>void, field:string)=>void,
    name?:string,
    style?:CSSProperties,
    autoSelectFirstValueOnTab?:boolean
}

export type Column<T> = {
    field:string;
    sortBy?:string;
    defaultSort?: ('asc' | 'desc');
    disableDynamicFontSize?: boolean
    title:string;
    render?(data:T):ReactElement|string,
    setFilterValue?:(data:T, value:any, fieldName:string)=>void,
    setEditValue?:(data:T, value:any, fieldName:string)=>void,
    hidden?: boolean
    filterProps?():DataGridFieldProps
    editProps?(data:T, edited:boolean, setData?:(data:any)=>void):DataGridFieldProps
    required?: boolean
    readonly?: boolean
    [prop:string]: any
}

export type Action<T> = {
    icon:string|((() => React.ReactElement<any>)) | SvgIconComponent,
    disabled?:boolean,
    tooltip?:string,
    onClick(event: any, data: T):void
};
export type ColumnAction<T> = (rowData: T) => Action<T>;
export type IsEditable<T extends object> = {isEditable?: (rowData: T) => boolean, isDeletable?: (rowData: T) => boolean, getModificationDate?:(data:T)=>Moment}
export type Editable<T extends object> = {
    onRowAdd?(props:Props<T>, newData:T):Promise<T>,
    onRowUpdate?(props:Props<T>, newData:T, oldData:T):Promise<T>,
    onRowDelete?(props:Props<T>, newData:T):Promise<T>,
}&IsEditable<T>&CrudOperationsEnabled;

export type ActionHeaderButton = {
    icon: () => JSX.Element,
    tooltip: string,
    onClick: () => void
}

export type Props<T extends object> = {
    id?:string
    cache?: {disabled?: boolean, group?: string}
    tableTitle?:string,
    endpoint:string,
    endpointDetail?:string,
    getDetailIdUrlPart?: (data: T) => string,
    clazz:{new(): T}
    columns:Column<T>[]
    version?:number,
    onRowClick?(row:T, origin?:T, merge?:boolean):void,
    disableRowClick?: boolean | ((data: T) => boolean),
    actions?:ColumnAction<T>[],
    disableExtendedFilterButtons?: boolean,
    actionsColumnIndex?: number,
    editable?: Editable<T>,
    masterDetail?(data:T):ReactElement,
    toolbarActions?:ToolbarAction[],
    getToolbarMultiActions?: (showMultiAction: (state?: boolean) => void) => MultiColumnToolbarAction<any>[],
    //shova tlacitko nad gridem pro pridani noveho zaznamu, nema vliv na crud operace v gridu
    hideAddNewActions?: boolean,
    defaultQueryParameters?: GenericMap,
    defaultQueryParametersClz?:{new(): any}
    alternativeButtons?: ActionHeaderButton[]
    validate?:(data:T)=>FieldError[],
    //hack kvuli material-table, kdyz totiz nejsou zadne akce, tak se nezobrazi filtrovaci buttony ve sloupci, pridavam tedy dummy akci, ale nekdy neni chtena, takze se to tady da vypnout
    hideDefaultDummyAction?:boolean,
    deleteRowWithoutQuestion?: boolean,
    hidePaginationButtons?:boolean,
    initialPageSize?:number,
    createNewObject?:()=>T,
    extendedButtonClicked?:(data: any)=>void,
    onEditingStart?:()=>void,
    onEditingEnd?:(data?: any)=>void,
    stomp?: {
        toggleable?:boolean,
        topic:string,
        wait?:number,
        clazz?: {new(): any}, // class for incoming stomp payload (inside CrudUpdate) message deserializatoin
        allowStompUiUpdates?:boolean, //flag indicating wheter immediate update of ui with stomp message is allowed
        userOnly?:boolean
    },
    autofocus?:boolean,
    preventScroll?:boolean,
    lazyLoading?:boolean,
    getDetail?:(data:T)=>any,
    lockSupport?: LockSupport,
    excludeFieldsForIntegerCheck?: string[],
    onMount?:()=>void,
    options?:Options<any>,
    useCurrentPage?:boolean,
    footerPagingText?:string,
    hideFooterButtons?: boolean
    maxCount?:number,
    prioritySort?: SortField[]
    defaultSort?: SortField
    isDetailReadOnly?: boolean | ((data: T) => boolean),
    isDetailLock?: boolean | ((data: T) => boolean),
    customReadOnlyTooltip?: string | ((data: T) => string),
    beforeSend?:(data: any) => void
    overflowHidden?: boolean,
    disableTableScroll?: boolean,
    minimumTableHeight?: number,
    indexOffset?: number,
    dataGridRef?:Ref<DGExposed>,
    tabDisabled?: boolean,
    onFocusedChildDataGridRow?: () =>void,
    onBlurChildDataGridRow?: () => void,
    customFilterHeaderButtons?: () => ReactElement<FormButtonProps, any>[],
    beforeAction?: ((action: string, data: T[], continuation: () => Promise<any>) => void),
    pagingDisabled?: boolean
    draggable?: boolean
    exportConfig?: ExportConfig
    requiredColumns?: string[]
    defaultHiddenColumns?: string[]
    lastBrowsedDateKey?: string
    showHideConnectedCols?: ConnectedCols[]
    watchChanges?: boolean
    onClearFilter?: () => void
    templatesEnabled?: boolean
}&FilteringProps;

export type FilteringProps = {
    filtering?:boolean
    hideItemAge?: boolean
    extendedFilter?: boolean
    rowFiltering?: boolean
    initialFilter?: {id?: number, name?: string, data: any}
}
export type FilterProps<Filter> ={
    filterClazz?:{new(): Filter}
    layoutFilter?(doFilter:(data?:Filter)=>void):ReactElement
    onFilterChanged?:(data: Filter)=>void
}

export type SortField = {
    field?:string,
    orderBy?: string
    orderDirection?: ('asc' | 'desc'),
}

export type MapperProps<Filter> ={
    mapper?:Mapper<Filter>
}

export type ModalProps = {
    name?: string
    callback:()=>void
    typ?: string
    isDefault?: boolean
    id?: number
    saveButtonText?: string
}&TemplatesFunctions
export enum TemplateDialogType {
    NAME_DIALOG, REMOVE_CONFIRMATION_DIALOG
}

export type QueryState<Filter> = {
    filters: Filter
    orderBy?: string,
    orderDirection?: ('asc' | 'desc'),
    pageSize?:number,
    page?:number,
    name?:string,
    vychozi?: boolean,
    templateFilters?: Filter,
    prioritySort?: SortField,
    id?: number
}

//jednoduchy, columns jsou ve stejnym poradi jako puvodni definice datagridu, hodnota v poli pak urcuje jejich skutecne poradi, kdyz se cokoliv v gridu zmeni, je treba zmenit i atribut version jinak to nebude fungovat spravne
export type State<Filter> = {columnsOrder:string[], current:QueryState<Filter>, version:number, hiddenColumns:string[], templateSettings?: State<Filter>}

export type DataGridLocationState = { useCurrentPage?: boolean }

export interface ContextProps<Filter> {
    getState():Readonly<State<Filter>>;
    setState(state:State<Filter>):void;
    fetch():void,
    table:MutableRefObject<TableExposed<any, any>>,
    editing:MutableRefObject<boolean>,
    name:string
}
