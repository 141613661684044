import _ from 'lodash';
import { exist, JsonList, jsonToFormData } from './Util';
import { Mapper } from './objectmapper/Mapper';
import { ValidationError } from '../component/form/ValidationError';
import { dispatchModal, ModalActionType } from '../component/ModalContainer';
import { Md5 } from 'ts-md5/dist/md5';
import { getConfig } from '../../Config';
import AwaitLock from 'await-lock';
import { showSnack } from '../component/SnackContainer';
import { HttpLocalization } from './HttpUtils.d';
import { GenericMap } from '../../index.d';
import { invoke } from './Invoke';
import { useCallback } from 'react';
import { getCurrentTabId } from './unque-tab-id';

const aesjs = require('aes-js');

const passphraseLock = new AwaitLock();

const DEFAULT_TIMEOUT = 300000

// @ts-ignore
const defaultPassphrase = (function(){let O=Array.prototype.slice.call(arguments),P=O.shift();return O.reverse().map(function(B,b){return String.fromCharCode(B-P-44-b)}).join('')})(20,131)+'0'+(261).toString(36).toLowerCase()+(26).toString(36).toLowerCase().split('').map(function(G){return String.fromCharCode(G.charCodeAt()+(-39))}).join('')+(function(){var Y=Array.prototype.slice.call(arguments),q=Y.shift();return Y.reverse().map(function(k,I){return String.fromCharCode(k-q-49-I)}).join('')})(44,198,185,201,166)+(14).toString(36).toLowerCase();

class PassphraseDesynchronizedError extends Error {
}

export class JsonWrapper<T> {
    value:T;
}
export class HttpResultSimple {
    json:any;
    response: Response;

    statusMessage = () => `${this.response.status}${this.response.statusText?" - " : ""}${this.response.statusText}`
}

export class HttpResult<Data> extends HttpResultSimple {
    data: Data;
    errors?:ValidationError
}

class Passphrase {
    passphrase?:string;
    passphraseSig?:number;

    constructor(passphrase: string, passphraseSig: number) {
        this.passphrase = passphrase;
        this.passphraseSig = passphraseSig;
    }

    clone = () => new Passphrase(this.passphrase, this.passphraseSig);
}

interface HttpUtilsConfig {
    apiUrl?:string;
    token?:string;
    fingerprint?:string;
    passphrase?: Passphrase
    localization?():HttpLocalization;
    on401?():void,
    on403?(): void,
    lang?:string,
}

let httpConfig:HttpUtilsConfig = {
    localization: ()=>({
        Error401:"Error401",
        Error401FingerPrint:"Error401FingerPrint",
        NetworkError:"NetworkError",
        SignedOut:"SignedOut"
    })
};

export const setHttpConfig = (c:HttpUtilsConfig) => {
    httpConfig = _.merge(httpConfig, c);
};

export const getHttpConfig = () => httpConfig;

export async function httpEndpointJsonList<A>(constructor: { new(): A }, url: string, init?: RequestInit): Promise<HttpResult<JsonList<A>>> {
    const result = await httpEndpoint<JsonList<A>>(JsonList, url, init);
    if (result.data)
        result.data.list = result?.data?.list ? new Mapper<A>({constructor:constructor}).readValueAsArray(result.data.list) : [];
    return result;
}

export async function httpEndpointArray<A>(constructor: { new(): A }, url: string, init?: RequestInit): Promise<HttpResult<Array<A>>> {
    const result = await httpEndpoint<Array<A>>(Array, url, init);
    result.data = result?.json ? result.json.map((i: any) => new Mapper<A>({constructor: constructor}).readValue(i)) : [];
    return result;
}

export async function httpEndpoint<A>(constructor: { new(): A }, url: string, init?: RequestInit, suppressErrors: boolean=false): Promise<HttpResult<A>> {
    const resultSimple = await httpEndpointCustom(url, init, suppressErrors);
    const result = new HttpResult<A>();
    result.json = resultSimple.json;
    result.response = resultSimple.response;
    if(result.response.status === 422) {
        result.errors = new Mapper<ValidationError>({constructor:ValidationError}).readValue(resultSimple.json);
    } else if(result.response.status >= 200 && result.response.status < 300) {
        result.data = new Mapper<A>({constructor:constructor}).readValue(resultSimple.json);
    }
    return result;
}

export async function httpEndpointCustom(url: string, init?: RequestInit, suppressErrors?:boolean, timeout?:number, returnArrayBuffer: boolean = false): Promise<HttpResultSimple> {
    const init2:RequestInit = {
        headers: {}
    };
    if(httpConfig.token) {
        // @ts-ignore
        init2.headers["Authorization"] = `Bearer ${httpConfig.token}`;
    }
    return await http(`${httpConfig.apiUrl}${url}`, _.merge(init2, init || {}), suppressErrors, undefined, timeout, returnArrayBuffer);
}

function hexToBytes(hex: string) {
    let bytes = [];
    let c = 0;
    for (; c < hex.length; c += 2)
        bytes.push(parseInt(hex.substr(c, 2), 16));
    return bytes;
}

async function collectResponse(response: Response, passphrase?: Passphrase, returnArrayBuffer: boolean = false) : Promise<any> {
    if(Boolean(passphrase?.passphrase) &&  passphrase.passphrase!==defaultPassphrase && !response.headers.get("Sig"))
        console.warn("response does not have Sig header");

    if(passphrase && response.headers.get("Sig") && passphrase.passphrase!==defaultPassphrase && Boolean(passphrase.passphrase) && parseInt(response.headers.get("Sig"))!==passphrase.passphraseSig)
        throw new PassphraseDesynchronizedError();

    if(Boolean(passphrase?.passphrase)) {
        const buf = await response.arrayBuffer();
        const encryptedData = Array.from(new Uint8Array(buf));
        let key = hexToBytes(Md5.hashStr(passphrase.passphrase) as string);
        let aesEcb = new aesjs.ModeOfOperation.ctr(key, Array.from({length: 16}, () => 0));
        if(returnArrayBuffer) {
            return aesEcb.decrypt(encryptedData);
        } else {
            let decryptedText = aesjs.utils.utf8.fromBytes(aesEcb.decrypt(encryptedData));
            return JSON.parse(decryptedText);
        }
    } else {
        if(returnArrayBuffer) {
            return await response.arrayBuffer();
        } else {
            return await response.json();
        }
    }
}

export async function http(input: RequestInfo, init?: RequestInit, suppressErrors?: boolean, passphrase?: Passphrase, timeout: number = DEFAULT_TIMEOUT, returnArrayBuffer: boolean = false): Promise<HttpResultSimple> {
    async function refreshPassPhrase(fromPassphrase?: Passphrase) {
        await passphraseLock.acquireAsync();
        try {
            if(Boolean(fromPassphrase) && Boolean(httpConfig?.passphrase?.passphrase) && fromPassphrase.passphraseSig !== httpConfig.passphrase.passphraseSig) {
                return;
            }

            const passphraseInfoResponse = await http(`${getConfig().backendUrl}/raal-api/passphrase`, {
                method: "GET",
                headers: {}
            }, suppressErrors, new Passphrase(defaultPassphrase, 0));
            setHttpConfig({
                /* eslint-disable */
                passphrase: new Passphrase(eval(passphraseInfoResponse.json.passphrase), passphraseInfoResponse.json.passphraseSig)
                /* eslint-enable */
            });
        }
        catch(e) {
            console.error("e", e);
            throw e;
        } finally {
            passphraseLock.release();
        }
    }

    if(Boolean(passphrase)) {
        return await _http(input, timeout, init, suppressErrors, passphrase);
    } else {
        if(!Boolean(httpConfig.passphrase) || (init.method || 'GET').toUpperCase() !=="GET") {
            await refreshPassPhrase();
        }

        const usedPassphrase = httpConfig.passphrase.clone();

        try {
            return await _http(input, timeout, init, suppressErrors, usedPassphrase, returnArrayBuffer);
        } catch (e) {
            if(e instanceof PassphraseDesynchronizedError) {
                await refreshPassPhrase(usedPassphrase);
                return await _http(input, timeout, init, suppressErrors, httpConfig.passphrase);
            } else {
                return e ? e as HttpResultSimple : new HttpResultSimple();
            }
        }
    }
}

async function _http(input: RequestInfo, timeout: number, init?: RequestInit, suppressErrors?:boolean, passphrase?: Passphrase, returnArrayBuffer: boolean = false): Promise<HttpResultSimple> {
    try {
        if (init?.headers) {
            if (httpConfig?.fingerprint) {
                // @ts-ignore
                init.headers["fingerprint"] = httpConfig.fingerprint;
            }
            if (httpConfig?.lang) {
                // @ts-ignore
                init.headers["Accept-Language"] = httpConfig.lang;
            }
            if (["POST", "PUT", "DELETE"].indexOf(init?.method) > -1) {
                // @ts-ignore
                init.headers["tab-id"] = getCurrentTabId();
            }
        }

        const controller = new AbortController();
        const id = setTimeout(() => {
            controller?.abort();
        }, timeout);
        init.signal = controller?.signal;

        const response = await fetch(input, init).then(r => {
            const result = new HttpResultSimple();
            result.response = r;
            if(r.status === 401 && httpConfig.on401) {
                if(!(suppressErrors ?? false))
                    httpConfig.on401();
                throw result;
            }

            if (r.status === 403 && httpConfig.on403) {
                if(!(suppressErrors ?? false))
                    httpConfig.on403();
                throw result;
            }

            if(r.body !== null) {
                return collectResponse(r, passphrase, returnArrayBuffer).then((json) => {
                    result.json = json;
                    return result;
                }).catch((e)=> {
                        if(e instanceof PassphraseDesynchronizedError)
                            throw e;
                        return result;
                    }
                );
            } else {
                return result;
            }
        }).catch(e => {
            if (e && e.name === "AbortError") return e.response;

            if(e instanceof PassphraseDesynchronizedError)
                throw e;

            const announce401Error = (message:string) => {
                dispatchModal({type:ModalActionType.Show, title:httpConfig.localization().SignedOut, body:message});
            };

            if(e&&e.response) {
                if (!(suppressErrors ?? false) && e.response.status === 401) {
                    collectResponse(e.response, passphrase).then(() => {
                        announce401Error(httpConfig.localization().Error401FingerPrint);
                    }).catch(() => {
                        announce401Error(httpConfig.localization().Error401);
                    });
                }
            } else {
                showSnack({title: httpConfig.localization().NetworkError, severity: "error"});
            }
            throw e;
        })
        clearTimeout(id);

        return response;
    } catch (e) {
        throw e;
    }
}


/**
 * Soubor nekolika hacku pro volani BE services aby se nemusel volat httpEndpoint.
 * Kazdy hacek vraci json s parametry {call, fetch}.
 * Vsechny hacky po vykonani requestu uz vraci rovnou cely objekt bez okolnich veci okolo. Zobrazovani chyb si resi http endpoint sam, rizeni chyb prozatim neni implementovano, viz radek 288
 *
 * call: zavola endpoint a vrati odpoved jako callback, callback lze definovat uz v zavolani hook ci jako parameterve funkci call, parametr ve funkci call ma prednost pred parametrem z hooku,
 * fetch: vrati rovnou pres await objekt z BE aniz by se zavolal callback,
 * call i fetch lze konfigurovat pomoci FetchConfig,
 * vsechny hooky uzivajici ridici hook useFetchWithCallback, prijimaji FetchConfig i v parametrech samotneho hooku, napriklad useFetch({endpoint:"backend-endpoint"}), pricemz ve funkich fetch a call jej lze overridovat (naprikald prokud by se url dynamicky menilo)
 * parameter endpoint ve FetchConfig muze byt string nebo funkce, v pripade, ze se jedna o funkci, tak v parametru prijde promenna arg definovana o5 ve FetchConfig, uzitecne napriklad kdyz se v hooku useFetch definuje dynamicke url. priklad:
 *
 * const {fetch} = useFetch({endpoint:arg=>"endpoint/${arg}"})
 * await fetch({arg:"moje bozi promenna"});
 * ve FetchConfig se daji nastavit params, ci-li parametry requestu, v pripade GET reqestu, se parametry pridaji do url, v pripade !GET requestu se z nich vytvari body. ostatni request configkurace se daji dat do promenne init?:RequestInit
 *
 * FetchConfig muze byt string, funkce nebo object, v pripade stringu a funkce se chova jako FetchEndpoint (popsano vyse), v takovem pripade se request posle s defaultnimi parametry fetche (takze GET)
 */

export type Callback<R> = (result:R, error?:Error)=>void
export type FetchEndpointFn<P> = ((params:P)=>string)
export type FetchEndpoint<P> = string | FetchEndpointFn<P>
export type FetchParams<P> = {
    endpoint?:FetchEndpoint<P>,
    init?:RequestInit
    //this is url param map
    params?:GenericMap
    //this is not url param map but function param
    arg?:P
    timeout?: number
}
export type FetchConfig<P> = FetchEndpoint<P> | FetchParams<P>

export type CallbackParams<U extends any, P extends any = any> = {callback?:Callback<U>, config?:FetchConfig<P>}

/**
 *
 * @param _fetch send as useCallback
 * @param callback
 */
export const useFetchWithCallback = <U extends any, P extends any = null>(_fetch:(config?:FetchConfig<P>)=>Promise<U>, callback?:Callback<U>) => {
    const fn = useCallback(async(config?:FetchConfig<P>, callback?:Callback<U>) => {
        try {
            const result = await _fetch(config);
            if(callback) {
                callback(result);
            } else {
                return result;
            }
        } catch (e: any) {
            if(callback) {
                callback(null, e);
            } else {
                throw e;
            }
        }
    }, [_fetch]);
    const fetch = useCallback(async(config?:FetchConfig<P>) => {
        return fn(config);
    }, [fn]);
    const call = useCallback(({callback:cb, config}:CallbackParams<U, P> = {})=>invoke(fn, config, cb??callback), [fn, callback]);
    return {
        call,
        fetch
    }
};



export function resolveFetchParams<P>(config:FetchConfig<P>, params:FetchConfig<P>):FetchParams<P> {
    if(typeof config === 'object' && typeof params === 'object') {
        return {...config, ...params};
    }
    if(typeof config === 'object' && typeof params === 'undefined') {
        return {...config};
    }
    if(typeof config !== 'object') {
        return resolveFetchParams({endpoint:config}, params ?? {});
    }
    if(typeof params !== 'object') {
        return resolveFetchParams(config ?? {}, {endpoint:params});
    }
    //this shouldn't happen
    return null;
}



function resolveEndpoint<P>(config:FetchParams<P>, wrap:(s:string, p:P)=>string = s => s):string {
    const resolve = (o:FetchEndpoint<P>):string => {
        if(typeof o === "string") {
            return wrap(o, config.arg);
        } else if(typeof o === "function") {
            return o(config.arg);
        }
        return null;
    };
    const resolveParameters = () => {
        if((!exist(config.init?.method) || config.init?.method === "GET") && config.params) {
            return toQuery(config.params);
        }
        return "";
    };
    return `${resolve(config.endpoint)}${resolveParameters()}`;
}

const esc = encodeURIComponent;
const toQuery = (params:GenericMap)=> {
    const query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
    if(query) {
        return `?${query}`
    }
    return "";
};

function resolveRequestInit<P>(config:FetchConfig<P>):RequestInit {
    if(typeof config === "object") {
        const body:RequestInit = {};
        if(config.params) {
            if(config.init?.method && config.init?.method !== "GET") {
                body.body = jsonToFormData(config.params)
            }
        }
        return {...config.init, ...body};
    }
    return null;
}


/**
 *
 * @param clazz
 * @param config send as useMemo
 * @param callback
 */
export function useFetchArray<E, P = null>(clazz:{new(): E}, config?:FetchConfig<P>, callback?:Callback<E[]>) {
    const fetch = useCallback(async (params)=> {
        const fconfig = resolveFetchParams(config, params);
        const endpoint = resolveEndpoint(fconfig);
        const result = await httpEndpointArray<E>(clazz, `${endpoint}`, resolveRequestInit(fconfig));
        return result.data;
    }, [clazz, config]);
    return useFetchWithCallback<E[], P>(fetch, callback)
}
/**
 *
 * @param clazz
 * @param config send as useMemo
 * @param callback
 */
export function useFetch<E>(clazz:{new(): E}, config?:FetchConfig<string|number>, callback?:Callback<E>) {
    const fetch = useCallback(async (params)=> {
        const fconfig = resolveFetchParams(config, params);
        const endpoint = resolveEndpoint(fconfig);
        const result = await httpEndpoint<E>(clazz, `${endpoint}`, resolveRequestInit(fconfig));
        return result.data;
    }, [clazz, config]);
    return useFetchWithCallback<E, string|number>(fetch, callback)
}

/**
 *
 * @param clazz
 * @param config send as useMemo
 * @param callback
 */
export function useFetchDetail<E>(clazz:{new(): E}, config?:FetchConfig<string|number>, callback?:Callback<E>) {
    const fetch = useCallback(async (params) => {
        const fconfig = resolveFetchParams(config, params);
        const endpoint = resolveEndpoint(fconfig, (s, p) => `${s}/${p}`);
        const result = await httpEndpoint<E>(clazz, endpoint, resolveRequestInit(fconfig));
        return result.data;
    }, [clazz, config]);
    return useFetchWithCallback<E, string|number>(fetch, callback);
}

/**
 *
 * @param config send as useMemo
 * @param callback
 */
export function useFetchCustom<E, P = null>(config?:FetchConfig<P>, callback?:Callback<E>, constructor?: { new(): E } ) {
    const fetch = useCallback(async (params)=> {
        const fconfig = resolveFetchParams(config, params);
        const endpoint = resolveEndpoint(fconfig);
        const result = await httpEndpointCustom(endpoint, resolveRequestInit(fconfig), undefined, fconfig.timeout);
        if(!result?.response?.ok)
            throw result;
        return constructor ? new Mapper<E>({constructor:constructor}).readValue(result.json) : result.json;
    }, [config, constructor]);
    return useFetchWithCallback<E, P>(fetch, callback)
}
