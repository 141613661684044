import React from "react";
import {Button, Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";


export interface RejstrikLink {
    titleKey: string,
    url: string,
    clipboardData?: string
}

interface RejstrikLinksComponentProps {
    links: RejstrikLink[]
}

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 0,
        padding: theme.spacing(2)
    }
}));

export function RejstrikLinksComponent(props: RejstrikLinksComponentProps) {
    const {t} = useTranslation();
    const classes = useStyles();

    return <Grid container direction="column" spacing={2} className={classes.root}>
        {props.links.map((l, n) =>
            <Grid item xs key={n}>
                <Button variant="contained" fullWidth={true} onClick={(e) => {
                    e.preventDefault();
                    if(l.clipboardData) {
                        navigator.clipboard.writeText(l.clipboardData).then(() => {}).catch(() => {}).finally(() => {window.open(l.url);});
                    } else {
                        window.open(l.url);
                    }
                }} title={t(l.titleKey)}>{t(l.titleKey)}</Button>
            </Grid>
        )}
    </Grid>
}

