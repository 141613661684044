import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {Moment} from "moment";
import {MomentConverters} from "./Converters";

export enum AppActivityType {
    View="View"
}

export enum AppActivitySubType {
    Row="Row", Detail="Detail"
}

export class Aktivita {
    id:number;

    @JsonProperty({type:{enum:AppActivityType}})
    type:AppActivityType;

    @JsonProperty({type:{enum:AppActivitySubType}})
    subType:AppActivitySubType;

    entity:string;

    kodProvozovny:string;

    recordId:string;

    @JsonProperty({converters:MomentConverters})
    date:Moment;

}