import {ProfilVozidla, ProfilVozidlaSelect} from "../../../../model/ProfilVozidla";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import {MuiDetailTabModal} from "../../../../../common/component/MuiDetailTabModal";
import {exist} from "../../../../../common/utils/Util";
import {CodeBookForm} from "../../../../raal_components/controller/CodebookForm";
import {Loading} from "../../../../../common/component/Loading";
import {ProfilVozidlaForm} from "./ProfilVozidlaForm";

type ModalProfilVozidlaFormProps = {
    onSaved: ()=> void
    onClose: ()=> void
    open?:boolean
    profilVozidla?: ProfilVozidlaSelect
}

export function ModalProfilVozidlaForm(props:ModalProfilVozidlaFormProps) {
    const {t} = useTranslation();
    const {profilVozidla, open, onClose, onSaved} = props;
    const href = `user/profilvozidla/${profilVozidla.id}`;
    const [profil, setProfil] = useState<ProfilVozidla>(null);
    const {fetch} = useFetchCustom<ProfilVozidla>({ endpoint: href}, undefined, ProfilVozidla);

    useEffect(() => {
        if (open ! && !exist(profil))
            fetch({}).then((profil) => {
                if (exist(profil.id)) setProfil(profil)
            });
        // eslint-disable-next-line
    }, [profilVozidla?.id, open])

    return (
        <>
            <Loading show={!exist(profil) && open} fullscreen/>
            { exist(profil) && open ?
            <MuiDetailTabModal
                open={open}
                onClose={() => {
                    onClose();
                }}
                fullScreen={true}
                title={t("Default.TabZakladni")}
            >
                <CodeBookForm
                    hideNewButtonOnEdit
                    focusFieldWhenMounted
                    edited
                    onSuccess={onSaved}
                    data={profil}
                    clazz={ProfilVozidla}
                    url={"user/profilvozidla"}
                    stopImmediatePropagation
                    render={() => <ProfilVozidlaForm/>}
                />
            </MuiDetailTabModal> : null}
        </>
    )
}
