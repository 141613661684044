import {useEffect, useMemo} from "react";
import uniqid from "uniqid";

export const TAB_ID_KEY = 'RAAL-Tab-Id'

export const getCurrentTabId: (() => string) = () => {
    const tabId = sessionStorage.getItem(TAB_ID_KEY);
    if (tabId) {
        return tabId;
    } else {
        const newUniqId = uniqid();
        if (typeof Storage !== 'undefined') {
            sessionStorage.setItem(TAB_ID_KEY, newUniqId)
        }
       return newUniqId;
    }
}

export function useUniqueId() {
    const id = useMemo(uniqid, [])

    useEffect(() => {
        if (typeof Storage !== 'undefined') {
            sessionStorage.setItem(TAB_ID_KEY, id)
        }
    }, [id])

    return id
}