import React from "react";
import {Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {
    useCurrencySelectObj,
    useEnumeratedSelect,
    useStatSelectAsAutocomplete
} from "../../../../raal_components/SelectOptions";
import {FormField} from "../../../../raal_components/form/Form";
import {decimalNumberFormat, shortNumberConstraint} from "../../../../../common/common-constraints";
import {RoadType} from "../../../../model/Preprava";


export function CenaMytaForm() {
    const {t} = useTranslation();
    const statSelect = useStatSelectAsAutocomplete();
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const typSilniceSelect = useEnumeratedSelect(RoadType, "Enumerations.RoadType", "number");

    return <Grid container spacing={1}>
        <Grid item lg={3} sm={6} xs={12}>
            <FormField name={"kodZeme"} title={t("CenaMyta.kodZeme")} type={"select"} selectProps={statSelect} required/>
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
            <FormField title={t("CenaMyta.typSilnice")} name='typSilnice' type='select' selectProps={typSilniceSelect} required/>
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
            <FormField title={t("CenaMyta.sazba")} name='sazba' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}} required/>
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
            <FormField title={t("Currency.Title")} name='currency' type='select' selectProps={currencySelectProps} required/>
        </Grid>
    </Grid>
}
