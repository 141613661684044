import {httpEndpoint, JsonWrapper} from "../../../../../common/utils/HttpUtils";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import * as React from "react";
import {useCodeBookDetailContextNew} from "../../../../raal_components/controller/CodeBookDetail";

/** Hook na vykonání akce (POST) ktera mění stav Případu nebo Nadpřípadu*/
export const useChangeStateAction = (endpointUrl: string) => {
    const {t} = useTranslation();
    const {fetchData} = useCodeBookDetailContextNew();
    const [showConfirm] = useConfirmDialog();

    return async (action: string, labelKeyPrefix: string = '') => {
            showConfirm({
                title: t('DluhStateActionConfirmDialog.Title'),
                body: t('DluhStateActionConfirmDialog.Body', {action: t(`${labelKeyPrefix}${action}`)}),
                onConfirm: async () => {
                    try {
                        await httpEndpoint<JsonWrapper<Boolean>>(JsonWrapper, endpointUrl + action, {method: "PUT"});
                            showSnack({title:t("DluhPripad.StavAction.Confirmation"), severity:"success"});
                        await fetchData();
                    } catch (e) {
                        showSnack({title: t("UnexpectedError"), severity: "error"});
                    }
                },
                buttons: {
                    confirm: t("Buttons.Confirm")
                }
            });
    }
}

interface ChangeStateButtonProps {
    action: string
    labelKeyPrefix: string
    changeStateAction: (action: string, labelKeyPrefix: string) => void
}


export function ChangeEntityStateButton({action, labelKeyPrefix, changeStateAction}: ChangeStateButtonProps) {
    const {t} = useTranslation();

    return <Button variant={"contained"} color={"primary"} type={"button"} style={{ margin: '0.3rem' }}
        onClick={() => changeStateAction(action, labelKeyPrefix)}>{t(`${labelKeyPrefix}${action}`)}
    </Button>
}
