import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {Currency} from "./Currency";
import {OsrmProfile} from "./OsrmProfile";

export class ProfilVozidlaSelect {
    id: number;
    nazev: string;
}

export class ProfilVozidla {
    id: number;
    nazev: string;
    @JsonProperty({type:{enum:OsrmProfile}})
    osrmProfile: OsrmProfile;
    @JsonProperty({type:{clazz:Currency}})
    currency: Currency;
    casovyNakladH: number;
    fixniNaklad: number;
    spotreba: number;
    cenaPaliva: number;
    displayPreprava: boolean;
    displayVozidlo: boolean;
    defaultPreprava: boolean;
    defaultVozidlo: boolean;
    hazmat: boolean;
    hazmatWater: boolean;
    delka: number;
    vaha: number;

    toSelect(): ProfilVozidlaSelect {
        const s = new ProfilVozidlaSelect();
        s.id = this.id;
        s.nazev = this.nazev;
        return s;
    }
}

export class ProfilVozidlaFilter {
    nazev: string;
}

