import {Dial} from "../../../raal_components/dial/Dial";
import {ProvozovnaZaznam, ProvozovnaZaznamFilter} from "../../../model/ProvozovnaZaznam";
import React from "react";
import {Provozovna} from "../../../model/Provozovna";
import {useTranslation} from "react-i18next";
import {FormField, FormInputType} from "../../../raal_components/form/Form";
import {CheckMark} from "../../../../common/component/CheckMark";
import {Grid} from "@material-ui/core";
import {useUserBasicSelectPz, useYesNoSelect} from "../../../raal_components/SelectOptions";
import {FormDateRange} from "../../../../common/component/form/FormDateRange";
import {ComponentMode} from "../../../routes";
import {useLocation} from "react-router";
import {useHistoryCustom} from "../../../raal_components/controller/NavigationHelper";
import {useDidMount} from "../../../../common/component/hooks/SharedHooks";

type ProvozovnaRecordsViewProps = {
    provozovna: Provozovna
    gridId: string
    mode?: ComponentMode
}

export function ProvozovnaRecordsView(props: ProvozovnaRecordsViewProps) {
    const {t} = useTranslation();
    const {state} = useLocation<any>();
    const provozovnaId = state?.tabDetailData?.provozovnaId ?? props.provozovna?.id
    const userSelectProps = useUserBasicSelectPz({isClearable:true, params: {provozovna: provozovnaId}});
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const {push} = useHistoryCustom();

    useDidMount(() => {
        // ochrana před přístupem k datům mimo tab
        if (!provozovnaId) push("/")
    })

    return <Dial<ProvozovnaZaznam, ProvozovnaZaznamFilter>
        mode={props.mode}
        config={{
            id: props.gridId,
            clazz: ProvozovnaZaznam,
            endpoint: `admin/provozovna/${provozovnaId}/zaznam`,
            tableTitle: t("Provozovna.Records"),
            filtering: true,
            columns: [
                {
                    title: t("Default.Text"),
                    field: 'text',
                    filterProps:() => ({type:FormInputType.Text})
                },
                {
                    title: t("Provozovna.Uzavreno"),
                    field: 'uzavreno',
                    render:(data:ProvozovnaZaznam)=> <CheckMark checked={data.uzavreno}/>,
                    filterProps:() => ({type: FormInputType.Select, selectProps:yesNoSelect})
                },
                {
                    title: t("Provozovna.DatumUzavreni"),
                    field: 'datUzav',
                    render: data => data.datUzav?.format("L LT"),
                    filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, name:"datUzavRange"})
                },
                {
                    title: t("Default.Datum"),
                    field: 'datum',
                    defaultSort:"desc",
                    render: data => data.datum?.format("L LT"),
                    filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, name:"datumRange"})
                },
                {
                    title: t("User.Title"),
                    field: 'autor',
                    render:(row:ProvozovnaZaznam)=> `${row.autor?.login ?? ''} ${row?.autor?.jmeno ? `(${row.autor.jmeno})` : ""}`,
                    filterProps:() => ({type: FormInputType.Select, selectProps: userSelectProps}),
                    cellStyle: {minWidth: 180},
                    headerStyle: {minWidth: 180}
                }
            ]
        }}
        crudConfig={{addEnabled:false, editEnabled: true, removeEnabled: true}}
        layoutForm={() => <ProvozovnaRecordForm />}
        tabDetailUrl={'/admin/provozovna/records'}
        tabDetailData={{provozovnaId: props.provozovna?.id}}
    />
}

export function ProvozovnaRecordForm() {
    const {t} = useTranslation();

    return (
        <Grid container>
            <FormField name={"text"} title={t("Default.Text")} type={"textarea"} required textFieldProps={{rows: 10, inputProps: {maxLength: 510}}}/>
            <FormField name={"uzavreno"} title={t("Provozovna.Uzavreno")} type={"checkbox"}/>
        </Grid>
    )
}
