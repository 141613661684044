import * as React from "react";
import {Box, createStyles, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface TabPanelProps {
    children?: React.ReactNode;
    persistContent?:boolean
    index: any;
    value: any;
    padding?: number;
}

const useStyles = makeStyles((theme) => createStyles({
    maxHeightColumn: {
        display: "flex",
        overflow: "auto",
        flexFlow: "column",
        flexGrow: 1
    }
}));

/**
 * Pomocna componenta pro obsah mui tabu (zalozky), lze konfigurovat pomoci persistContent jestli se ma obsah renderovat i kdyz zalozka neni aktivni, v takovem pripade se pouze skryje
 * @param props
 * @constructor
 */

export function MuiTabPanel(props: TabPanelProps) {
    const { children, value, index, persistContent, ...other } = props;
    const classes = useStyles();

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className={value === index ? classes.maxHeightColumn : undefined}
        >
            {persistContent ? (
                <Box hidden={value !== index} style={{overflow: "hidden"}}>{children}</Box>
            ) : value === index && <Box p={props.padding} className={classes.maxHeightColumn}>{children}</Box>}
        </Typography>
    );
}