import {useTranslation} from "react-i18next";
import {Dial} from "../../../raal_components/dial/Dial";
import React from "react";
import {Kilometrovnik} from "../../../model/Kilomentrovnik";
import {FormField, FormInputType} from "../../../raal_components/form/Form";
import {Grid} from "@material-ui/core";
import {useStatSelectAsAutocomplete} from "../../../raal_components/SelectOptions";
import {useMpzRevertResolver} from "../../../../common/utils/MpzUtils";
import {FormMap, FormMapOptions} from "../../../../common/component/form/FormMap";
import {mapDataConverterPoint} from "../../../raal_components/SharedConfig";
import {TrasaFormViewType} from "../user/kilometrovnik/TrasaForm";
import {CheckMark} from "../../../../common/component/CheckMark";
import {RouteComponentProps} from "../../../routes";


export function KilometrovnikCiselnik(props: RouteComponentProps) {
    const {t} = useTranslation();

    return <Dial<Kilometrovnik>
        mode={props.mode}
        config={{
            tableTitle: t("Dials.Kilometrovnik"), endpoint: "admin/kilometrovnik", clazz: Kilometrovnik, filtering: true,
            exportConfig: {
                exportable: true,
                exportAll: true,
                endpoint: "admin/kilometrovnik",
                fileName: "export_kilometrovnik",
                translationPrefix: ['Kilometrovnik', 'Default'],
                formats: ["csv"],
                exportableProps: [
                        {
                          type: ["csv"],
                          fields: ["id", "mpz", "cc2", "psc", "nazev", "lon", "lat", "typ"]
                      }
                ]
            },
            columns: [
                {
                    title: t("Default.Mpz"),
                    field: "mpz",
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Kilometrovnik.Cc2"),
                    field: "cc2",
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Kilometrovnik.Psc"),
                    field: "psc",
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Kilometrovnik.Nazev"),
                    field: "nazev",
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Kilometrovnik.Typ"),
                    field: "typ",
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title:t("Default.Enabled"),
                    field:"enabled",
                    render:(data: Kilometrovnik)=><CheckMark checked={data.enabled}/>
                }
            ]
        }}
        createInstance={() => {
            let s = new Kilometrovnik(); s.enabled = true; return s;
        }}
        excludeFieldsForIntegerCheck={["lat", "lon"]}
        crudConfig={{editEnabled: true, removeEnabled: true, addEnabled: false}}
        layoutForm={() => <KilometrovnikCiselnikForm/>}
    />
}


function KilometrovnikCiselnikForm() {
    const {t} = useTranslation();
    const statSelect = useStatSelectAsAutocomplete();
    const [resolveMpz] = useMpzRevertResolver();
    return <Grid container spacing={2}>
        <Grid item lg={4} sm={6} xs={12}>
            <FormField name={"mpz"} title={t("Default.Mpz")} type={"select"} selectProps={statSelect} forceUpdateWhenDataChanged={["cc2"]} setValue={((data, fieldName, value) => {
                data.mpz = value;
                data.cc2 = resolveMpz(value);
            })} required/>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
            <FormField name={"cc2"} title={t("Kilometrovnik.Cc2")} type={"text"} textFieldProps={{inputProps: {maxLength: 2, readOnly: true}}} disabled required/>
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
            <FormField name={"psc"} title={t("Kilometrovnik.Psc")} type={"text"} textFieldProps={{inputProps: {maxLength: 5}}}/>
        </Grid>
        <Grid item lg={1} sm={6} xs={12}>
            <FormField name={"typ"} title={t("Kilometrovnik.Typ")} type={"number"} numberProps={{format: "0,0", constraint:{precision:2, scale:0, allowNegative: false}}}/>
        </Grid>
        <Grid item lg={5} sm={6} xs={12}>
            <FormField name={"nazev"} title={t("Kilometrovnik.Nazev")} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}} required/>
        </Grid>
        <Grid item lg={1} sm={3} xs={6}>
            <FormField name={"enabled"} title={t("Default.Enabled")} type={"checkbox"}/>
        </Grid>
        <Grid item lg={2} sm={4} xs={12}>
            <FormField name={"lat"} title={t("Kilometrovnik.Lat")} type={"number"} numberProps={{format: "0.[0000000]", constraint:{precision:10, scale:7, allowNegative: true}}} required/>
        </Grid>
        <Grid item lg={2} sm={4} xs={12}>
            <FormField name={"lon"} title={t("Kilometrovnik.Lon")} type={"number"} numberProps={{format: "0.[0000000]", constraint:{precision:10, scale:7, allowNegative: true}}} required/>
        </Grid>
        <Grid item lg={2} sm={4} xs={12}>
            <FormField<FormMapOptions>
                name={"koordinat"}
                title={t("Default.MapDefine")}
                type={FormInputType.Custom}
                showIcon={false}
                customComponent={FormMap}
                isTabbedContainer={true}
                forceUpdateWhenDataChanged={["lat", "lon"]}
                customComponentOptions={{
                    converters:mapDataConverterPoint,
                    maxPoints:1,
                    valuePassObject: true,
                    updateMapOnOpen: true,
                    viewType: TrasaFormViewType.POINT
                }}
                setValue={(data, fieldName, value) => {
                    data.lon = value.coordinates[0]
                    data.lat = value.coordinates[1]
                }}
            />
        </Grid>
    </Grid>
}
