export function jsFileDownload(data, filename, mime, bom) {
    const blobData = (typeof bom !== 'undefined') ? [bom, data] : [data];
    const blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
    const w = window;

    if (typeof w.navigator.msSaveBlob !== 'undefined') {
        w.navigator.msSaveBlob(blob, filename);
    }
    else {
        var blobURL = (w.URL && w.URL.createObjectURL) ? w.URL.createObjectURL(blob) : w.webkitURL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        setTimeout(function() {
            document.body.removeChild(tempLink);
            w.URL.revokeObjectURL(blobURL);
        }, 200)
    }
}