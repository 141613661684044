import {KilometrovnikLinkType} from "./KilometrovnikLinkPart";
import {
    RouteCalculationStatus,
    TransportDokladkaTrasa,
    TransportPrejezdTrasa, TransportTrasa, TransportTrasaCalculationResult,
    TrasaState
} from "../../../../model/Trasa";
import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import {ProfilVozidla} from "../../../../model/ProfilVozidla";
import {Preprava} from "../../../../model/Preprava";
import {Vozidlo} from "../../../../model/Vozidlo";
import {SimpleValue} from "../../../../model/SimpleValue";
import {useData} from "../../../../context/DataContext";
import {Currency} from "../../../../model/Currency";
import {Waypoint} from "../../../../model/Waypoint";

export interface PathItem {
    waypointy: Waypoint[];
}

export function useCalculateResultFunc() {
    const {fetch: fetchRoute} = useFetchCustom<SimpleValue, string>({endpoint: "user/route"}, undefined, SimpleValue);

    const calculateResultFunc = async (profileId: string, t: TransportTrasa, pathItems: PathItem[]) => {
        t.calculationResult = [];
        t.status = RouteCalculationStatus.OK;
        t.state = TrasaState.CALCULATED;

        try {
            for (let pathItem of pathItems) {
                const r = await fetchRoute({
                    params: {
                        "profile-id": profileId,
                        waypoints: pathItem.waypointy.map(value => `${value.koordinat.getLatLng().lng},${value.koordinat.getLatLng().lat}`).join(";")
                    }
                })
                const calcResult = new TransportTrasaCalculationResult();
                calcResult.osrmData = r?.value
                if (r?.value) {
                    const osrm = JSON.parse(r.value);
                    calcResult.routeDistance = osrm.routes[0].distance;
                    calcResult.routeDuration = osrm.routes[0].duration;
                    t.calculationResult.push(calcResult);
                } else {
                    t.status = RouteCalculationStatus.NO_ROUTE;
                    t.state = TrasaState.ERROR;
                }
            }
        } catch (e) {
            console.error(e)
            t.status = RouteCalculationStatus.SERVER_ERROR;
            t.state = TrasaState.ERROR;
        }
    }

    return [calculateResultFunc];
}


export function useFetchRouteOnly(id: string, profileId: string, currencyCode: string, kilometrovnikLinkType: KilometrovnikLinkType): () => Promise<TransportTrasa> {
    const {fetch: fetchProfil} = useFetchCustom<ProfilVozidla, string>({endpoint: arg => `user/profilvozidla/${arg}`}, undefined, ProfilVozidla);
    const {fetch: fetchPreprava} = useFetchCustom<Preprava, string>({endpoint: arg => `user/prepravaview/${arg}`}, undefined, Preprava);
    const {fetch: fetchVozidlo} = useFetchCustom<Vozidlo, string>({endpoint: arg => `user/vozidloview/${arg}`}, undefined, Vozidlo);
    const [calculateResultFunc] = useCalculateResultFunc();

    const {meny} = useData<Currency>()

    return async () => {
        const profil = await fetchProfil({arg: profileId});
        const t = new TransportTrasa();
        t.profilVozidla = profil.toSelect();
        t.currency = meny.find(value => value.currencyCode === currencyCode);
        const n = kilometrovnikLinkType === KilometrovnikLinkType.PREPRAVA ? await fetchPreprava({arg: id}) : await fetchVozidlo({arg: id})
        t.waypointy = n.waypointy;
        await calculateResultFunc(profileId, t, [
            {waypointy: t.waypointy}
        ]);
        return t;
    };
}

export function usePrejezdFetchRouteOnly(id: string, profileId: string, currencyCode: string, kilometrovnikLinkType: KilometrovnikLinkType, prejezdOdWaypoint: Waypoint, prejezdKamWaypoint: Waypoint): () => Promise<TransportPrejezdTrasa> {
    const {fetch: fetchProfil} = useFetchCustom<ProfilVozidla, string>({endpoint: arg => `user/profilvozidla/${arg}`}, undefined, ProfilVozidla);
    const {fetch: fetchPreprava} = useFetchCustom<Preprava, string>({endpoint: arg => `user/prepravaview/${arg}`}, undefined, Preprava);
    const {fetch: fetchVozidlo} = useFetchCustom<Vozidlo, string>({endpoint: arg => `user/vozidloview/${arg}`}, undefined, Vozidlo);
    const [calculateResultFunc] = useCalculateResultFunc();

    const {meny} = useData<Currency>()

    return async () => {
        const profil = await fetchProfil({arg: profileId})
        const t = new TransportPrejezdTrasa();
        t.profilVozidla = profil.toSelect();
        t.currency = meny.find(value => value.currencyCode === currencyCode);
        const n = kilometrovnikLinkType === KilometrovnikLinkType.PREPRAVA ? await fetchPreprava({arg: id}) : await fetchVozidlo({arg: id})
        t.waypointy = n.waypointy;
        t.prejezdOd = prejezdOdWaypoint.koordinat
        t.prejezdKam = prejezdKamWaypoint.koordinat
        const prejezdWaypointy = [prejezdOdWaypoint, n.waypointy[0], n.waypointy[n.waypointy.length - 1], prejezdKamWaypoint];
        const zadaniWaypointy = [prejezdOdWaypoint, prejezdKamWaypoint];

        await calculateResultFunc(profileId, t, [
            {waypointy: prejezdWaypointy},
            {waypointy: t.waypointy},
            {waypointy: zadaniWaypointy},
            {waypointy: [prejezdOdWaypoint, t.waypointy[0]]},
            {waypointy: [t.waypointy[t.waypointy.length - 1], prejezdKamWaypoint]},
        ]);
        return t;
    };
}

export function useDokladkaFetchRouteOnly(id: string, profileId: string, currencyCode: string, kilometrovnikLinkType: KilometrovnikLinkType, nakladka: Waypoint, vykladka: Waypoint): () => Promise<TransportDokladkaTrasa> {
    const {fetch: fetchProfil} = useFetchCustom<ProfilVozidla, string>({endpoint: arg => `user/profilvozidla/${arg}`}, undefined, ProfilVozidla);
    const {fetch: fetchPreprava} = useFetchCustom<Preprava, string>({endpoint: arg => `user/prepravaview/${arg}`}, undefined, Preprava);
    const [calculateResultFunc] = useCalculateResultFunc();

    const {meny} = useData<Currency>()

    return async () => {
        const profil = await fetchProfil({arg: profileId})
        const t = new TransportDokladkaTrasa();
        t.profilVozidla = profil.toSelect();
        t.currency = meny.find(value => value.currencyCode === currencyCode);
        const n = await fetchPreprava({arg: id})
        t.waypointy = n.waypointy;
        const dokladkaWaypointy = [nakladka, ...n.waypointy, vykladka]
        const zadaniWaypointy = [nakladka, vykladka];

        await calculateResultFunc(profileId, t, [
            {waypointy: dokladkaWaypointy},
            {waypointy: t.waypointy},
            {waypointy: zadaniWaypointy}
        ]);

        return t;
    };
}