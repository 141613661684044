import {useTranslation} from "react-i18next";
import {Dial} from "../../../../raal_components/dial/Dial";
import {NadpripadPoznamka} from "../../../../model/NadpripadPoznamka";
import {FormField, FormInputType} from "../../../../raal_components/form/Form";
import {Grid} from "@mui/material";
import * as React from "react";
import {ComponentMode} from "../../../../routes";
import {useParams} from "react-router-dom";

interface NadpripadPoznamkyDialProps {
    id: number
    mode: ComponentMode
}

export function DluhNadpripadPoznamkyDial(props: NadpripadPoznamkyDialProps) {
    const {t} = useTranslation();
    const {nadpripadId} = useParams<{nadpripadId?: string}>();

    return <Dial<NadpripadPoznamka>
        mode={props.mode}
        config={{
            id: "nadpripad-poznamka",
            tableTitle : t("Dials.Notes"),
            endpoint: `admin/stiznost/dluh-nadpripad/${nadpripadId ? nadpripadId : props.id ?? 0}/poznamka`,
            clazz: NadpripadPoznamka,
            filtering: false,
            autofocus: false,
            columns: [
                {
                    title:t("Poznamka.poznamka"),
                    field:"poznamka",
                    filterProps:() => ({type:FormInputType.Text})
                },
                {
                    field: "uzivatel",
                    title: t("Poznamka.uzivatel"),
                    render: data => data.uzivatel?.displayName()
                },
                {
                    field:"datIns",
                    title:t("Poznamka.datIns"),
                    render:data=>data.datIns?.format("L LT")
                }
            ]
        }}
        isFormGrid
        preventClearFunctions
        hideNewButtonOnEdit
        focusFieldWhenMounted={false}
        stopImmediatePropagation={true}
        crudConfig={{editEnabled:true, removeEnabled:true, addEnabled:false}}
        modalHeaderName={()=>t("Default.ModalZakladni")}
        layoutForm={() => <NadpripadPoznamkaForm />}
        tabDetailUrl={`/stiznosti/dluh-nadpripad/${props.id}/poznamky`}
    />
}

function NadpripadPoznamkaForm() {
    const {t} = useTranslation();

    return <Grid container spacing={1}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormField title={t("Poznamka.poznamka")} type={FormInputType.Text} name={"poznamka"} textFieldProps={{inputProps: {maxLength: 510}}}/>
        </Grid>

        <Grid item lg={3} md={6} sm={12} xs={12}>
            <FormField title={t("Poznamka.uzivatel")} type={FormInputType.Text} name={"uzivatel"} disabled getValue={data =>
                data.uzivatel?.displayName()
            }/>
        </Grid>

        <Grid item lg={3} md={6} sm={12} xs={12}>
            <FormField title={t("Poznamka.datIns")} type={FormInputType.DateTime} name={"datIns"} disabled/>
        </Grid>
    </Grid>
}
