import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {Currency} from "./Currency";
import {RoadType} from "./Preprava";

export class CenaMyta {
    id: number;
    kodZeme: string;
    sazba: number;
    @JsonProperty({type: {clazz: Currency}})
    currency: Currency;
    @JsonProperty({type: {enum: RoadType}})
    typSilnice: RoadType
}

export class CenaMytaFilter {
    kodZeme: string;
    sazba: number;
    @JsonProperty({type: {clazz: Currency}})
    currency: Currency;
    @JsonProperty({type: {enum: RoadType}})
    typSilnice: RoadType
}