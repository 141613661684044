import React from 'react';
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../context/AppContext";
import {MessageComponent} from "../../../common/component/MessageComponent";
import {UserRole} from "../../model/User";
import {TypZpravy} from "../../model/Zprava";

export function Info() {
    const {t} = useTranslation();
    const {hasUserRole} = useAppContext();
    return <MessageComponent admin={hasUserRole(UserRole.ROLE_ADMIN)} title={t("Informations.Info")} typZpravy={TypZpravy.A} />;
}
