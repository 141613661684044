import React, { useEffect, useState } from 'react';
import { ProfilVozidla, ProfilVozidlaSelect } from '../../../../model/ProfilVozidla';
import { JsonProperty } from '../../../../../common/utils/objectmapper/Mapper';
import { Button, CircularProgress, Divider, Grid } from '@material-ui/core';
import { useCurrencySelectObj, useProfilVozidlaSelectObj } from '../../../../raal_components/SelectOptions';
import { useTranslation } from 'react-i18next';
import { exist } from '../../../../../common/utils/Util';
import { Currency } from '../../../../model/Currency';
import { useFetchCustom } from '../../../../../common/utils/HttpUtils';
import { Waypoint } from '../../../../model/Waypoint';
import { FormNominatim } from '../../../../../common/component/form/FormNominatim';
import { Geometry } from '../../../../model/Geometry';
import { GeoPosition } from 'geo-position.ts';
import { StandaloneField } from '../../../../../common/component/form/StandaloneField';
import { ModalTrasaFormProps } from './ModalTrasaForm';
import { GridData } from '../../../../raal_components/controller/CodeBookController.d';
import { useLocation } from 'react-router';
import { KilometrovnikVozidlaPart } from './KilometrovnikVozidlaPart';
import { GoogleMapsButton } from '../../../../raal_components/parts/GoogleMapsComponents';
import { sizeIntegerFormat, sizeViewFormat } from '../_vp/PrepravaAVozidlaShared';
import DataStorage from '../../../../../common/DataStorage';
import { KilometrovnikLinkType } from './KilometrovnikLinkPart';
import { getConfig } from '../../../../../Config';
import { DokladkaList, formatTempPlace } from '../../../../model/PrepravaVozidlo';

export interface DokladkaKilometrovnikLinkPartProps {
    offerId: number;
    nakladka: Waypoint;
    vykladka: Waypoint;
    dokladka: DokladkaList;
    trasa: Geometry[];
    checkIsEqual?: () => void
}

class DokladkaKilometrovnikLinkState {
    static of(offerId: number, nakladka: Waypoint, vykladka: Waypoint, dokladka: DokladkaList): DokladkaKilometrovnikLinkState {
        const r = new DokladkaKilometrovnikLinkState();
        r.offerId = offerId;
        r.nakladka = nakladka;
        r.vykladka = vykladka;
        r.dokladka = dokladka;
        return r;
    }

    @JsonProperty({type:{clazz:ProfilVozidlaSelect}})
    profilVozidla?: ProfilVozidlaSelect;
    @JsonProperty({type:{clazz:Currency}})
    currency: Currency;
    offerId: number;
    @JsonProperty({type:{clazz:Waypoint}})
    nakladka: Waypoint;
    @JsonProperty({type:{clazz:Waypoint}})
    vykladka: Waypoint;
    dokladka: DokladkaList;

    nakladkaDistance: number;

    vykladkaDistance: number;
}

export function DokladkaKilometrovnikLinkPart(props: DokladkaKilometrovnikLinkPartProps) {
    const [state, setState] = useState(() => DokladkaKilometrovnikLinkState.of(props.offerId, props.nakladka, props.vykladka, props.dokladka))
    const {t} = useTranslation();
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const {fetch} = useFetchCustom<ProfilVozidla>({ endpoint: 'user/profilvozidla/default-preprava' }, undefined, ProfilVozidla);
    const profilVozidlaSelectProps = useProfilVozidlaSelectObj({isClearable: true, autoOpenDisabled: true, params: {profileType: "PREPRAVA"}});
    const {state:locState} = useLocation<{data:GridData<any>, previousPage?: string, forcePreviousPage?:string}>();

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, [props.offerId]);

    const getData= () => {
        const distanceOd = props.nakladka && new GeoPosition(props.nakladka.koordinat.coordinates[1], props.nakladka.koordinat.coordinates[0]).Distance(new GeoPosition(props.trasa[0].coordinates[1], props.trasa[0].coordinates[0]))
        const distanceKam = props.vykladka && new GeoPosition(props.vykladka.koordinat.coordinates[1], props.vykladka.koordinat.coordinates[0]).Distance(new GeoPosition(props.trasa[props.trasa.length-1].coordinates[1], props.trasa[props.trasa.length-1].coordinates[0]))

        fetch().then((d) => {
            if(exist(d.id)) {
                setState({
                    ...state, profilVozidla: d.toSelect(),
                    currency: d.currency,
                    nakladkaDistance: distanceOd / 1000,
                    vykladkaDistance: distanceKam / 1000
                })
            } else {
                setState({
                    ...state,
                    nakladkaDistance: distanceOd / 1000,
                    vykladkaDistance: distanceKam / 1000
                })
            }
        });
    }

    const getTrasa = (routeOnly?: boolean) => {
        const storageKey = `kilometrovnik-trasa`;
        const trasaData : ModalTrasaFormProps = {
            profilVozidla: state.profilVozidla,
            currency: state.currency,
            routeOnly: routeOnly ?? false,
            linkType: KilometrovnikLinkType.PREPRAVA,
            offerId: props.offerId,
            prejezd: null,
            dokladka: {nakladka: state.nakladka, vykladka: state.vykladka},
            data: null
        };
        DataStorage.set(storageKey, JSON.stringify(trasaData),  true, 'session');
        DataStorage.redirectWithTargetBlank(`/kilometrovnik/trasa`);
        DataStorage.clear(storageKey, true, 'session');
    }

    return <Grid container spacing={2}>
        {
            props.dokladka ?
                <>
                    <Grid item xs={12} sm={4} lg={3}>
                        <StandaloneField title={t("Dokladky.nakladka")}  type={"Custom"} customComponent={FormNominatim} value={state.nakladka?.nazevMista} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <StandaloneField title={t("Dokladky.vykladka")}  type={"Custom"} customComponent={FormNominatim} value={state.vykladka?.nazevMista} disabled/>
                    </Grid>

                    <Divider style={{marginBottom: 0, marginTop: 0, height: 0}}/>

                    <Grid item xs={12} sm={4} lg={3}>
                        <StandaloneField title={t("Dokladky.odkud")}  type={"Custom"} customComponent={FormNominatim} value={formatTempPlace(props.dokladka.regOd, props.dokladka.pscOdkud, props.dokladka.odkud)} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <StandaloneField title={t("Dokladky.kam")}  type={"Custom"} customComponent={FormNominatim} value={formatTempPlace(props.dokladka.regKam, props.dokladka.pscKam, props.dokladka.kam)} disabled/>
                    </Grid>

                    <Divider style={{marginBottom: 0, marginTop: 0, height: 0}}/>

                    <Grid item xs={12} sm={4} lg={3}>
                        <StandaloneField title={t("Dokladky.zajizdka")}  type={"number"} value={exist(props.dokladka.zajizdka) ? (props.dokladka.zajizdka / 1000) : undefined} numberProps={{format: sizeIntegerFormat}} disabled variant="outlined"/>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <StandaloneField title={t("Dokladky.vyhodnost")}  type={"number"} value={props.dokladka.vyhodnost} numberProps={{format: sizeViewFormat, constraint: {allowNegative: true, precision: 7, scale: 2}}} disabled variant="outlined"/>
                    </Grid>

                    {getConfig().geoEnabled &&
                        <><Divider style={{marginBottom: 0, marginTop: 0, height: 0}}/>
                            <Grid item xs={12} sm={4} lg={3}>
                                <StandaloneField title={t("Trasa.profilVozidla")} value={state.profilVozidla} onValueChanged={v => setState({...state, profilVozidla: v, currency: v?.currency})}   type='select' selectProps={profilVozidlaSelectProps}/>
                            </Grid>

                            <Grid item xs={12} sm={4} lg={3}>
                                <StandaloneField title={t("Trasa.currency")} value={state.currency} onValueChanged={v => setState({...state, currency: v})}   type='select' selectProps={currencySelectProps}/>
                            </Grid></>
                    }
                    <Divider style={{marginBottom: 0, marginTop: 0, height: 0}}/>
                    <Grid item>
                        <Grid item container spacing={1}>
                            {getConfig().geoEnabled &&
                                <KilometrovnikVozidlaPart profilVozidla={state.profilVozidla} getProfil={getData} checkIsEqual={props.checkIsEqual} />
                            }
                            <Grid item>
                                <GoogleMapsButton from={state.nakladka.nazevMista} to={state.vykladka.nazevMista} waypoints={[locState?.data?.data?.odkudHelper, locState?.data?.data?.kamHelper]}  />
                            </Grid>
                            {getConfig().geoEnabled &&
                                <><Grid item>
                                    <Button type={"button"} variant="contained" color={"primary"}
                                            disabled={!(exist(state.profilVozidla) && exist(state.currency))}
                                            onClick={() => getTrasa(true)}>{t("Kilometrovnik.DisplayRouteOnly")}</Button>
                                </Grid><Grid item>
                                    <Button type={"button"} variant="contained" color={"primary"}
                                            disabled={!(exist(state.profilVozidla) && exist(state.currency))}
                                            onClick={() => getTrasa()}>{t("Kilometrovnik.DisplayRoute")}</Button>
                                </Grid></>
                            }
                        </Grid>
                    </Grid>
                </>
                : <Grid item xs={12} sm={12} lg={3} style={{textAlign: "center"}}><CircularProgress color="inherit" size={20}/></Grid>
        }
    </Grid>;
}
