import {useTranslation} from "react-i18next";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {Button} from "@material-ui/core";
import * as React from "react";
import {DluhNadpripad} from "../../../../model/Stiznosti";
import {useHistory, useLocation} from "react-router";
import {useCodeBookDetailContextNew} from "../../../../raal_components/controller/CodeBookDetail";

export enum NadpripadActionEnum {
    NEW = "New",
    NAVIGATE = "Navigate",
    NAVIGATETOPRIPAD = "NavigateToPripad"
}

interface NadpripadButtonProps {
    action: NadpripadActionEnum
    labelKeyPrefix: string
    changeStateAction: (action: NadpripadActionEnum, labelKeyPrefix: string) => void
}

// Hook pro manipulaci s nadpřípadem z případu
export const useNadpripadActions = (endpointUrl: string, pripadId: number) => {
    const {t} = useTranslation();
    const {fetchData} = useCodeBookDetailContextNew();
    const [showConfirm] = useConfirmDialog();
    const {fetch} = useFetchCustom<DluhNadpripad>({endpoint: endpointUrl})
    const {push} = useHistory();
    const {pathname} = useLocation();

    const NadpripadButton = ({action, labelKeyPrefix, changeStateAction}: NadpripadButtonProps) => {
        const {t} = useTranslation();

        return <Button variant={"contained"} color={"primary"} type={"button"} style={{ margin: '0.3rem' }}
                       onClick={() => changeStateAction(action, labelKeyPrefix)}>{t(`${labelKeyPrefix}.Buttons.${action}`)}
        </Button>
    }

    // Zobrazení confirmace
    const showModal = async (action: NadpripadActionEnum, labelKeyPrefix: string = '') => {
        showConfirm({
            title: t('DluhNadpripad.Confirmation'),
            body: t(`${labelKeyPrefix}.StavAction.${action}`),
            onConfirm: async () => {
                try {
                    switch (action) {
                        case NadpripadActionEnum.NEW: {
                            await createNewNadpripad();
                            break;
                        }
                    }
                    showSnack({title:t("DluhNadpripad.StavAction.CreateConfirmation"), severity:"success"});
                    await fetchData();
                } catch (e) {
                    showSnack({title: t("UnexpectedError"), severity: "error"});
                }
            },
            buttons: {
                confirm: t("Buttons.Confirm")
            }
        });
    }

    // Vytvoření nového nadpřípadu
    const createNewNadpripad = async () => {
        await fetch({params: {pripadId: pripadId}, init: {method: "POST"}}).then((res) => {
           push(`/stiznosti/dluh-nadpripad/${res.id}`)
        });
    }

    // Navigace do Nadpřípadu
    const navigateToNadpripad = (id: number) => {
        push(`/stiznosti/dluh-nadpripad/${id}`, { pripadId: pripadId, forcePreviousPage: pathname })
    }

    // Navigace do případu
    const navigateToPripad = (id: number) => {
        push(`/stiznosti/dluh-pripad/${id}`, {forcePreviousPage: pathname })
    }

    return {showModal, NadpripadButton, navigateToNadpripad, navigateToPripad}
}
