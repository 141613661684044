import {useTranslation} from "react-i18next";
import {FormPartBox} from "./FormPartBox";
import {Button, Grid} from "@material-ui/core";
import React, {PropsWithChildren, useRef} from "react";
import {ModalArchiveViewRecord, ModalArchiveViewRecordExposed} from "./ModalArchiveViewRecord";

export interface OfferActionsPartProps<T> {
    data: T;
}

export function OfferActionsPart<T>(props: PropsWithChildren<OfferActionsPartProps<T>>) {
    const {t} = useTranslation();
    const modalRef = useRef<ModalArchiveViewRecordExposed>();

    return <>
        <ModalArchiveViewRecord
            title={t("ArchiveView")}
            modalRef={modalRef}
            data={props.data}
        />

        <FormPartBox title={t("Actions")}>
            <Grid item container spacing={1}>
                <Grid item>
                    <Button variant={"contained"} color={"primary"} onClick={() => {
                        modalRef.current.setModalVisibility();
                    }}>{t("ArchiveRecordShort")}</Button>
                </Grid>
                {props.children}
            </Grid>
        </FormPartBox>
    </>;
}
