import {CiselnikTyp} from "../../../../model/Ciselnik";
import {SystemParameter, SystemParamKey} from "../../../../model/SystemParameter";
import {Vozidlo} from "../../../../model/Vozidlo";
import {Preprava} from "../../../../model/Preprava";
import {FormField, useForm} from "../../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {
    createProvozovnaEmailyContactSelect,
    useCiselnikSelect,
    useCurrencySelectObj,
    useDispecerForInputFormsSelect, usePriceUnitsSelectObj,
    useRychlyContactSelect,
} from '../../../../raal_components/SelectOptions';
import {Box, Divider, Grid, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyleContext} from '../../../../context/ThemeModeContext';
import {
    cenaConstraint,
    FormDateRangeGroupVozidloPreprava,
    FormDateRangeGroupVozidloPrepravaNew,
    FormOdkudKam,
    FormOdkudKamNew,
    paletyConstraint,
    sizeFormat,
    sizeIntegerFormat,
    useNSJLogic,
    useValidation,
    vahaConstraint,
    VozidloPrepravaSharedFields
} from "./PrepravaAVozidlaShared";
import {exist} from "../../../../../common/utils/Util";
import {useData} from "../../../../context/DataContext";
import {FieldError} from "../../../../../common/component/form/ValidationError";
import {useAppContext} from "../../../../context/AppContext";
import {KilometrovnikLinkPart, KilometrovnikLinkType} from "../kilometrovnik/KilometrovnikLinkPart";
import { LicenseType, priceUnits } from '../../../../model/CommonTypes';
import {FormPartBox} from "../prohlizeni/FormPartBox";
import {hasLicense} from "../../../../../common/logic/permissions-logic";
import {OfferArchiveActionsPart} from "../prohlizeni/OfferArchiveActionsPart";
import {
    useCodeBookDetailContext,
    useCodeBookDetailContextNew
} from "../../../../raal_components/controller/CodeBookDetail";
// eslint-disable-next-line
type DetailFormProps<T extends VozidloPrepravaSharedFields> = {
    typCiselniku:CiselnikTyp,
    typParametru:SystemParamKey,
    advanceParam:SystemParamKey,
    maxDruhyKey:SystemParamKey,
    preprava:boolean,
    defaultRange:number,
    defaultAdvanceValue: number
    archiveZadani?: boolean
}

/**
 * TODO stejná logika jako New, nutné nahradit všude novou novou
 * @constructor
 */
export function DetailForm<T extends Vozidlo | Preprava>({maxDruhyKey, defaultRange, typCiselniku, typParametru, preprava, advanceParam, defaultAdvanceValue, archiveZadani}: DetailFormProps<T>) {
    const form = useForm<Vozidlo | Preprava>();
    const {nsjValidation, maxDruhuValidation} = useValidation({maxDruhyKey, preprava});
    const {t} = useTranslation();
    const [maxDruhu] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === maxDruhyKey));
    const ciselnikSelect = useCiselnikSelect(typCiselniku, {isMulti:true, maxValuesCount: maxDruhu?.toInt() ?? 2});
    const useDispecerSelect = useDispecerForInputFormsSelect({isClearable:true, params: {uzivatelId: form.data.uzivatel?.id}, autoOpenDisabled: true});
    const currencySelectProps = useCurrencySelectObj({isClearable:true});
    const {classes} = useStyleContext();
    const [n, s, j] = useNSJLogic<T>(preprava);
    const {edited, data, setData} = useCodeBookDetailContext<T>();
    const {user} = useAppContext();
    const rychlyKontaktSelect = useRychlyContactSelect({isClearable: true, params: {provozovnaId: user.provozovna?.id}, noDynamicFontSize: true, autoOpenDisabled: true, inputType: "phone"});
	const priceUnitsSelect = usePriceUnitsSelectObj({isClearable: true, noDynamicFontSize: true, autoOpenDisabled: true, inputType: "phone"});
    const kilometrovnikLinkType = data instanceof Preprava ? KilometrovnikLinkType.PREPRAVA : KilometrovnikLinkType.VOZIDLO;
    const [hasKilomentrovnikLicense, setHasKilometrovnikLicense] = useState(false);

    useEffect(() => {
        setHasKilometrovnikLicense(hasLicense(user, data.getMista(), true))
        // eslint-disable-next-line
    }, [data.id]);

    return (
        <Grid container spacing={2}>
            {
                archiveZadani ?  <Grid container item spacing={2} alignItems="stretch" direction="row" style={{paddingBottom:8}}>
                    <Grid item xs>
                        <OfferArchiveActionsPart data={data}/>
                    </Grid>
                </Grid> : undefined
            }
            {edited &&
                <Grid container spacing={2} justifyContent={'flex-end'} style={{marginBottom: 10, marginRight: 0}}>
                    <Grid item>
                        <Typography variant="caption" className="bold">{t("PVI.Creator")}:</Typography><Typography variant="caption"> {`${data.uzivatel?.login ?? ''} ${data.uzivatel?.jmeno ? `(${data.uzivatel?.jmeno})` : ""}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" className="bold">{t("PVI.CreatedOn")}:</Typography><Typography variant="caption"> {data.datIns?.format("L LT")}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" className="bold">{t("PVI.ModifiedOn")}:</Typography><Typography variant="caption"> {data.modifiedOn?.format("L LT")}</Typography>
                    </Grid>
                </Grid>
            }
                <FormOdkudKam maxLength={20} />
                <Grid container item lg={4} sm={12}>
                    <Grid item lg={4} sm={4} xs={12}>
                        <FormField title={t("Preprava.naves")} name={"naves"} type={"checkbox"} forceUpdateWhenDataChanged={["delka", "vaha", "souprava","jine"]} onWillChange={(f, v) => n(data, setData, v)} validate={nsjValidation}/>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={12}>
                        <FormField title={t("Preprava.souprava")} name={"souprava"} type={"checkbox"}  forceUpdateWhenDataChanged={["delka", "vaha", "naves","jine"]} onWillChange={(f,v) => s(data, setData, v)} validate={nsjValidation}/>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={12}>
                        <FormField title={t("Preprava.jine")} name={"jine"} type={"checkbox"}  forceUpdateWhenDataChanged={["delka", "vaha", "souprava","naves"]} onWillChange={(f,v) => j(data, setData, v)} validate={nsjValidation}/>
                    </Grid>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <FormField title={t("Preprava.delka")} name={"delka"} type={"number"} numberProps={{format:sizeFormat, constraint:vahaConstraint}} required validate={(data: T) => {
                        if (exist(data.delka) && data.delka <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "delka")];
                        return null;
                    }}/>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <FormField title={t("Preprava.vaha")} name={"vaha"} type={"number"} numberProps={{format:sizeFormat, constraint:vahaConstraint}} required validate={(data: T)  => {
                        if (exist(data.vaha) && data.vaha <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "vaha")];
                        return null;
                    }}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField title={t("Preprava.druhy")} name={"druhy"} type={"select"}  selectProps={ciselnikSelect} autoSelectFirstValueOnTab required validate={maxDruhuValidation}/>
                </Grid>
                <FormDateRangeGroupVozidloPreprava {...{paramKey:typParametru, defaultRange: defaultRange, advanceKey: advanceParam, defaultAdvanceValue: defaultAdvanceValue, skipValidation: archiveZadani}} />
                <Grid item lg={2} sm={6} xs={12}>
                    <FormField title={t("Default.Telefon")} name={"rychlyKontakt"} type={"select"}  selectProps={rychlyKontaktSelect} autoSelectFirstValueOnTab serverValidation={{translate: t}}/>
                </Grid>
                <Grid item lg={2} sm={6} xs={12}>
                    <FormField title={t("Preprava.cena")} name={"cena"} type={"number"} forceUpdateWhenDataChanged={["currency", "jednotka"]}  numberProps={{format:sizeFormat, constraint:cenaConstraint}} validate={(data: T) => {
                        if (exist(data.cena) && data.cena <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "cena")];
                        return null;
                    }} onWillChange={(field, newValue) => {
                        if (!exist(newValue) && data.currency) {
                            data.currency = null;
                        }
						if (!exist(newValue) && data.jednotka) {
							data.jednotka = null;
						}
                    }}/>
                </Grid>
                <Grid item lg={2} sm={6} xs={12}>
                    <FormField title={t("Currency.Title")} name='currency' type='select' disabled={() => !exist(data?.cena) || data.cena === 0 } selectProps={currencySelectProps} autoSelectFirstValueOnTab={true} serverValidation={{translate:t}} validate={(data: T) => {
                        if (exist(data.cena) && !exist(data.currency))
                            return [FieldError.Create(t("Preprava.CurrencyRequiredWhenPrice"), false, "currency")];
                        return null;
                    }}/>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 5}}/>
                    <Grid item xs={12} lg={12}>
                        <Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Preprava.doplnujiciUdaje")}</Typography>
                    </Grid>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <FormField title={t("Preprava.palety")} name={"palety"} type={"number"} numberProps={{format:sizeIntegerFormat, constraint:paletyConstraint}} validate={(data: T)  => {
                        if (exist(data.palety) && data.palety <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "palety")];
                        return null;
                    }}/>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <FormField title={t("Preprava.verejnaPozn")} name={"verejnaPozn"} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}}/>
                </Grid>
                <Grid item lg={6} sm={6} xs={12}>
                    <FormField title={t("Preprava.dispecer")} name={"dispecer"} type={"select"}  selectProps={useDispecerSelect} autoSelectFirstValueOnTab={true}/>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <FormField title={t("Preprava.sirka")} name={"sirka"} type={"number"} numberProps={{format:sizeFormat, constraint:vahaConstraint}} validate={(data: T)  => {
                        if (exist(data.sirka) && data.sirka <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "sirka")];
                        return null;
                    }}/>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <FormField title={t("Preprava.vyska")} name={"vyska"} type={"number"} numberProps={{format:sizeFormat, constraint:vahaConstraint}} validate={(data: T)  => {
                        if (exist(data.vyska) && data.vyska <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "vyska")];
                        return null;
                    }}/>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <FormField title={t("Preprava.lozPlocha")} name={"lozPlocha"} type={"number"}  numberProps={{format:sizeFormat, constraint:vahaConstraint}} validate={(data: T)  => {
                        if (exist(data.lozPlocha) && data.lozPlocha <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "lozPlocha")];
                        return null;
                    }}/>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <FormField title={t("Preprava.objem")} name={"objem"} type={"number"} numberProps={{format:sizeFormat, constraint:vahaConstraint}} validate={(data: T)  => {
                        if (exist(data.objem) && data.objem <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "objem")];
                        return null;
                    }}/>
                </Grid>
                <Grid item lg={12} sm={6} xs={12}>
                    <FormField title={t("Preprava.neverPozn")} name={"neverPozn"} type={"text"} textFieldProps={{inputProps: {maxLength: 100}}}/>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 5}}/>
                    <Grid item xs={12} lg={12}>
                        <Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Preprava.doplnky")}</Typography>
                    </Grid>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormField title={t("Preprava.adr")} name={"adr"} type={"checkbox"}  />
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormField title={t("Preprava.naklProstorVcelku")} name={"naklProstorVcelku"} type={"checkbox"}  />
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormField title={t("Preprava.zvedaciCelo")} name={"zvedaciCelo"} type={"checkbox"}  />
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormField title={t("Preprava.druhyRidic")} name={"druhyRidic"} type={"checkbox"}  />
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormField title={t("Preprava.zakazDokladky")} name={"zakazDokladky"} type={"checkbox"}  />
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormField title={t("Preprava.hydraulickaRuka")} name={"hydraulickaRuka"} type={"checkbox"}  />
                </Grid>
                <Grid item lg={6} sm={6} xs={12}>
                    <FormField title={t("Preprava.nadrozmernyNaklad")} name={"nadrozmernyNaklad"} type={"checkbox"}  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 5}}/>
                    <Grid item xs={12} lg={12}>
                        <Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Preprava.zpusobNakladky")}</Typography>
                    </Grid>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormField title={t("Preprava.nakladkaZezadu")} name={"nakladkaZezadu"} type={"checkbox"}  />
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormField title={t("Preprava.nakladkaBokem")} name={"nakladkaBokem"} type={"checkbox"}  />
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormField title={t("Preprava.nakladkaShora")} name={"nakladkaShora"} type={"checkbox"}  />
                </Grid>
                <Box display="none">
                    <Grid item sm={4} xs={12}>
                        <FormField title={t("TransportNabidka.pocetEditaci")} name={"pocetEditaci"} type={"number"} disabled/>
                    </Grid>
                </Box>

                {hasKilomentrovnikLicense && user.typLicence === LicenseType.WITHKM && (data.getMista()?.length ?? 0) > 1 && data.id ?
                    <Grid item xs>
                        <FormPartBox title={t("Sections.Mileage")}>
                            <KilometrovnikLinkPart offerId={data.id}
                                                   linkType={kilometrovnikLinkType}
                                                   data={data}
                                                   switchType={true}
                                                   changeMainFocus={(state) => form.isChildFormFocused = state}
                                                   checkIsEqual={() => form.testBlockSaveUntilChange()}
                                                   zadani={true}
                            />
                        </FormPartBox>
                    </Grid> : undefined
                }
        </Grid>);
}

export function DetailFormNew<T extends Vozidlo | Preprava>({maxDruhyKey, defaultRange, typCiselniku, typParametru, preprava, advanceParam, defaultAdvanceValue, archiveZadani}: DetailFormProps<T>) {
    const form = useForm<T>();
    const {nsjValidation, maxDruhuValidation} = useValidation({maxDruhyKey, preprava});
    const {t} = useTranslation();
    const [maxDruhu] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === maxDruhyKey));
    const ciselnikSelect = useCiselnikSelect(typCiselniku, {isMulti:true, maxValuesCount: maxDruhu?.toInt() ?? 2});
    const useDispecerSelect = useDispecerForInputFormsSelect({isClearable:true, params: {uzivatelId: form.data.uzivatel?.id}, autoOpenDisabled: true});
    const currencySelectProps = useCurrencySelectObj({isClearable:true});
	const priceUnitsSelect = usePriceUnitsSelectObj({isClearable: true, autoOpenDisabled: true});
    const {classes} = useStyleContext();
    const [n, s, j] = useNSJLogic<T>(preprava);
    const {edited, data, setData} = useCodeBookDetailContextNew<T>();
    const {user} = useAppContext();
    const rychlyKontaktSelect = useRychlyContactSelect({isClearable: true, params: {provozovnaId: user.provozovna?.id}, noDynamicFontSize: true, autoOpenDisabled: true, inputType: "phone"});
    const kilometrovnikLinkType = data instanceof Preprava ? KilometrovnikLinkType.PREPRAVA : KilometrovnikLinkType.VOZIDLO;
    const [hasKilomentrovnikLicense, setHasKilometrovnikLicense] = useState(false);
    const emailSelect = createProvozovnaEmailyContactSelect({isClearable: true, params: {provozovnaId: user.provozovna.id}, autoOpenDisabled: true})

    useEffect(() => {
        setHasKilometrovnikLicense(hasLicense(user, data.getMista(), true))
        // eslint-disable-next-line
    }, [data.id]);

    return (
        <Grid container spacing={2}>
            {
                archiveZadani ?  <Grid container item spacing={2} alignItems="stretch" direction="row" style={{paddingBottom:8}}>
                    <Grid item xs>
                        <OfferArchiveActionsPart data={data}/>
                    </Grid>
                </Grid> : undefined
            }
            {edited &&
                <Grid container spacing={2} justifyContent={'flex-end'} style={{marginBottom: 10, marginRight: 0}}>
                    <Grid item>
                        <Typography variant="caption" className="bold">{t("PVI.Creator")}:</Typography><Typography variant="caption"> {`${data.uzivatel?.login ?? ''} ${data.uzivatel?.jmeno ? `(${data.uzivatel?.jmeno})` : ""}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" className="bold">{t("PVI.CreatedOn")}:</Typography><Typography variant="caption"> {data.datIns?.format("L LT")}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" className="bold">{t("PVI.ModifiedOn")}:</Typography><Typography variant="caption"> {data.modifiedOn?.format("L LT")}</Typography>
                    </Grid>
                </Grid>
            }
            <FormOdkudKamNew maxLength={20}/>
            <Grid container item lg={4} sm={12}>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormField title={t("Preprava.naves")} name={"naves"} type={"checkbox"} forceUpdateWhenDataChanged={["delka", "vaha", "souprava","jine"]} onWillChange={(f, v) => n(data, setData, v)} validate={nsjValidation}/>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormField title={t("Preprava.souprava")} name={"souprava"} type={"checkbox"}  forceUpdateWhenDataChanged={["delka", "vaha", "naves","jine"]} onWillChange={(f,v) => s(data, setData, v)} validate={nsjValidation}/>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormField title={t("Preprava.jine")} name={"jine"} type={"checkbox"}  forceUpdateWhenDataChanged={["delka", "vaha", "souprava","naves"]} onWillChange={(f,v) => j(data, setData, v)} validate={nsjValidation}/>
                </Grid>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={t("Preprava.delka")} name={"delka"} type={"number"} numberProps={{format:sizeFormat, constraint:vahaConstraint}} required validate={(data: T) => {
                    if (exist(data.delka) && data.delka <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "delka")];
                    return null;
                }}/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={t("Preprava.vaha")} name={"vaha"} type={"number"} numberProps={{format:sizeFormat, constraint:vahaConstraint}} required validate={(data: T)  => {
                    if (exist(data.vaha) && data.vaha <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "vaha")];
                    return null;
                }}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField title={t("Preprava.druhy")} name={"druhy"} type={"select"}  selectProps={ciselnikSelect} autoSelectFirstValueOnTab required validate={maxDruhuValidation}/>
            </Grid>
            <FormDateRangeGroupVozidloPrepravaNew {...{paramKey:typParametru, defaultRange: defaultRange, advanceKey: advanceParam, defaultAdvanceValue: defaultAdvanceValue, skipValidation: archiveZadani}} />
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={t("Default.Telefon")} name={"rychlyKontakt"} type={"select"} selectProps={rychlyKontaktSelect} autoSelectFirstValueOnTab serverValidation={{translate: t}}/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={"Email"} name={"email"} type={"select"} selectProps={emailSelect} autoSelectFirstValueOnTab/>
            </Grid>
            <Grid item lg={1} sm={6} xs={12}>
                <FormField title={t("Preprava.cena")} name={"cena"} type={"number"} forceUpdateWhenDataChanged={["currency", "jednotka"]}  numberProps={{format:sizeFormat, constraint:cenaConstraint}} validate={(data: T) => {
                    if (exist(data.cena) && data.cena <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "cena")];
                    return null;
                }} onWillChange={(field, newValue) => {
                    if (!exist(newValue) && data.currency) {
                        data.currency = null;
                    }
                }}/>
            </Grid>
            <Grid item lg={1} sm={6} xs={12}>
                <FormField title={t("Currency.Title")} name='currency' type='select' disabled={() => !exist(data?.cena) || data.cena === 0 } selectProps={currencySelectProps} autoSelectFirstValueOnTab={true} serverValidation={{translate:t}} validate={(data: T) => {
                    if (exist(data.cena) && !exist(data.currency))
                        return [FieldError.Create(t("Preprava.CurrencyRequiredWhenPrice"), false, "currency")];
                    return null;
                }}/>
            </Grid>
			<Grid item lg={1} sm={6} xs={12}>
				<FormField title={t("Default.Jednotka")} name={"jednotka"} type={"select"} disabled={() => !exist(data?.cena) || data.cena === 0 } selectProps={priceUnitsSelect} autoSelectFirstValueOnTab serverValidation={{translate: t}} />
			</Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 5}}/>
                <Grid item xs={12} lg={12}>
                    <Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Preprava.doplnujiciUdaje")}</Typography>
                </Grid>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={t("Preprava.palety")} name={"palety"} type={"number"} numberProps={{format:sizeIntegerFormat, constraint:paletyConstraint}} validate={(data: T)  => {
                    if (exist(data.palety) && data.palety <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "palety")];
                    return null;
                }}/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={t("Preprava.verejnaPozn")} name={"verejnaPozn"} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}}/>
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
                <FormField title={t("Preprava.dispecer")} name={"dispecer"} type={"select"}  selectProps={useDispecerSelect} autoSelectFirstValueOnTab={true}/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={t("Preprava.sirka")} name={"sirka"} type={"number"} numberProps={{format:sizeFormat, constraint:vahaConstraint}} validate={(data: T)  => {
                    if (exist(data.sirka) && data.sirka <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "sirka")];
                    return null;
                }}/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={t("Preprava.vyska")} name={"vyska"} type={"number"} numberProps={{format:sizeFormat, constraint:vahaConstraint}} validate={(data: T)  => {
                    if (exist(data.vyska) && data.vyska <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "vyska")];
                    return null;
                }}/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={t("Preprava.lozPlocha")} name={"lozPlocha"} type={"number"}  numberProps={{format:sizeFormat, constraint:vahaConstraint}} validate={(data: T)  => {
                    if (exist(data.lozPlocha) && data.lozPlocha <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "lozPlocha")];
                    return null;
                }}/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={t("Preprava.objem")} name={"objem"} type={"number"} numberProps={{format:sizeFormat, constraint:vahaConstraint}} validate={(data: T)  => {
                    if (exist(data.objem) && data.objem <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "objem")];
                    return null;
                }}/>
            </Grid>
            <Grid item lg={12} sm={6} xs={12}>
                <FormField title={t("Preprava.neverPozn")} name={"neverPozn"} type={"text"} textFieldProps={{inputProps: {maxLength: 100}}}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 5}}/>
                <Grid item xs={12} lg={12}>
                    <Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Preprava.doplnky")}</Typography>
                </Grid>
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <FormField title={t("Preprava.adr")} name={"adr"} type={"checkbox"}  />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <FormField title={t("Preprava.naklProstorVcelku")} name={"naklProstorVcelku"} type={"checkbox"}  />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <FormField title={t("Preprava.zvedaciCelo")} name={"zvedaciCelo"} type={"checkbox"}  />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <FormField title={t("Preprava.druhyRidic")} name={"druhyRidic"} type={"checkbox"}  />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <FormField title={t("Preprava.zakazDokladky")} name={"zakazDokladky"} type={"checkbox"}  />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <FormField title={t("Preprava.hydraulickaRuka")} name={"hydraulickaRuka"} type={"checkbox"}  />
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
                <FormField title={t("Preprava.nadrozmernyNaklad")} name={"nadrozmernyNaklad"} type={"checkbox"}  />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 5}}/>
                <Grid item xs={12} lg={12}>
                    <Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Preprava.zpusobNakladky")}</Typography>
                </Grid>
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <FormField title={t("Preprava.nakladkaZezadu")} name={"nakladkaZezadu"} type={"checkbox"}  />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <FormField title={t("Preprava.nakladkaBokem")} name={"nakladkaBokem"} type={"checkbox"}  />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <FormField title={t("Preprava.nakladkaShora")} name={"nakladkaShora"} type={"checkbox"}  />
            </Grid>
            <Box display="none">
                <Grid item sm={4} xs={12}>
                    <FormField title={t("TransportNabidka.pocetEditaci")} name={"pocetEditaci"} type={"number"} disabled/>
                </Grid>
            </Box>

            {hasKilomentrovnikLicense && user.typLicence === LicenseType.WITHKM && (data.getMista()?.length ?? 0) > 1 && data.id ?
                <Grid item xs>
                    <FormPartBox title={t("Sections.Mileage")}>
                        <KilometrovnikLinkPart offerId={data.id}
                                               linkType={kilometrovnikLinkType}
                                               data={data}
                                               switchType={true}
                                               changeMainFocus={(state) => form.isChildFormFocused = state}
                                               checkIsEqual={() => form.testBlockSaveUntilChange()}
                                               zadani={true}
                        />
                    </FormPartBox>
                </Grid> : undefined
            }
        </Grid>);
}
