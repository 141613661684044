import React, {PropsWithChildren} from "react";
import {Box, Card, CardContent, Typography} from "@material-ui/core";
import { useStyleContext } from "../../../../context/ThemeModeContext";

export interface FormPartBoxProps {
    title?: string;
}

export function FormPartBox(props: PropsWithChildren<FormPartBoxProps>) {
    const {classes} = useStyleContext();
    return <Card style={{height: "100%"}} className={classes.sectionBackground}>
        <CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
            {props.title && <Box marginBottom={2}>
                <Typography variant="h6" >{props.title}</Typography>
            </Box>}
            {props.children}
        </CardContent>
    </Card>;
}
