import DataStorage from "../common/DataStorage";
import {User} from "./model/User";

export type Account = {
    id: number
    token: string
    login: string
    name: string
    provozovna: string
    email: string
    kod: string
}
const key = "token";
const str = "session"

export const useAccounts = () => {
    const removeAccount = (token: string, logout: boolean = false) => {
        const user = DataStorage.getUserAccount();
        manageAccounts(token);
        DataStorage.clearAll(user?.id??0, str)
        if (logout) DataStorage.clear(key, false, str);
    }

    const addAccount = (user: User) => {
        const token = DataStorage.get(key,  false, str);
        manageAccounts(token, user);
    }

    const switchAccount = (token:string) =>  {
        DataStorage.set(key, token, false, str);
    }

    const getAccounts = (): Account[] =>  {
        const accounts = DataStorage.get("accounts",  false);
        return accounts ? JSON.parse(accounts) : [];
    }

    // Managing local accounts
    const manageAccounts = (token: string, user?: User) =>  {
        const accountsList = getAccounts();
        const index =  accountsList.findIndex(al => !user ? al.token === token : al.id === user.id);
        if (index !== -1) accountsList.splice(index, 1);
        if (user) {
            const newAccount: Account = {
                id: user.id,
                token: token,
                login: user.login,
                name: user.jmeno,
                email: user.email,
                kod: user.provozovna?.kod,
                provozovna: user.provozovna?.pNazev
            };
            accountsList.unshift(newAccount);
        }
        DataStorage.set("accounts", JSON.stringify(accountsList), false);
    }

    return {addAccount, switchAccount, removeAccount, getAccounts};
}