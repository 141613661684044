import {Button} from "@material-ui/core";
import React, {useMemo} from "react";
import {useTranslation} from 'react-i18next';
import {getSeznamMapsPointUrl} from "../../../common/utils/LinkUtils";
import {Geometry} from "../../model/Geometry";

const useSeznamGeometryUrl = (gps: Geometry) => useMemo(()=> getSeznamMapsPointUrl(gps), [gps]);

export function SeznamGeometryButton({gps}:{gps?: Geometry}) {
    const url = useSeznamGeometryUrl(gps);
    const {t} = useTranslation();

    return (
        <Button fullWidth variant="contained" href={url} target={"_blank"} disabled={url === null}>
            {t("SeznamMaps.Title")}
        </Button>
    );
}
