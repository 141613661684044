export type IDClass = { [id: string]: any };

export class TelCislo {
    telcislo: string
}

export enum LicenseType {
    WITHKM = 2, WITHOUTKM=1
}

export enum InzeratSearchEntityType {
    INZERAT, INZERAT_HISTORIE, INZERAT_ARCHIV, INZERAT_HISTORIE_ARCHIV
}

export enum PrepravaSearchEntityType {
    PREPRAVA, PREPRAVA_HISTORIE, PREPRAVA_ARCHIV, PREPRAVA_HISTORIE_ARCHIV
}

export enum VolneVozySearchEntityType {
    VOZIDLO, VOZIDLO_HISTORIE, VOZIDLO_ARCHIV, VOZIDLO_HISTORIE_ARCHIV
}

export const EU_COUNTRIES = ["BE","BG","HR","CY","DK","EE","FI","FR","DE","GR","HU","IE","IT","LV","LT","LU","MT","NL","PL","PT","RO","SK","SI","ES","SE","GB","AT"];
export const SK_COUNTRIES = ["SK"]
export const CZ_COUNTRIES = ["CZ"]
export const CZSK_COUNTRIES = ["CZ", "SK"]

export enum Region {
    CZ, EU, GLOBAL, SK, CZSK
}

export const RegionList = [Region.GLOBAL, Region.EU, Region.CZSK, Region.CZ, Region.SK];

export const RegionOrder = [Region.GLOBAL, Region.EU, Region.CZSK, Region.CZ, Region.SK];

export enum InvalDuvodPreprava{OK, DATUM_V_MINULOSTI, ZAKAZANE_SLOVO, ZNEPLATNENO_OPERATOREM, UZIVATEL_ZAKAZ, PROVOZOVNA_POZASTAVENA, DATUM_VZDALENE, VELKE_ROZMEZI_DATUMU, NEZNAME_TEL_CISLO, DUPLICITA, UZIVATEL_POZASTAVEN,  UZIVATEL_BEZ_TELEFONU, ODEBRANE_PRAVA}

export enum InvalDuvodVozidlo{OK, DATUM_V_MINULOSTI, ZAKAZANE_SLOVO, ZNEPLATNENO_OPERATOREM, UZIVATEL_ZAKAZ, PROVOZOVNA_POZASTAVENA, DATUM_VZDALENE, VELKE_ROZMEZI_DATUMU, NEZNAME_TEL_CISLO, DUPLICITA, UZIVATEL_POZASTAVEN,  UZIVATEL_BEZ_TELEFONU, ODEBRANE_PRAVA}

export class BasicUserData {
    id: string;

    login: string;

    jmeno: string;

    email: string;

    toString() {
        return `${this.login ?? ''} ${this.jmeno ? `(${this.jmeno})` : ""}`;
    }
}

export const priceUnits = ['t','km'];