import {Dial} from "../../../../raal_components/dial/Dial";
import React from "react";
import {ProfilVozidla, ProfilVozidlaFilter} from "../../../../model/ProfilVozidla";
import {FormInputType} from "../../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {ProfilVozidlaForm} from "./ProfilVozidlaForm";
import {exist} from "../../../../../common/utils/Util";
import {OsrmProfile} from "../../../../model/OsrmProfile";
import {RouteComponentProps} from "../../../../routes";
import {useParams} from "react-router-dom";


export function ProfilVozidlaDial(props: RouteComponentProps) {
    const {t} = useTranslation();
    const {id} = useParams<{ id?: string }>()

    return <Dial<ProfilVozidla, ProfilVozidlaFilter>
        focusFieldWhenMounted={true}
        mode={props.mode}
        config={
            {
                id: "profil-vozidla",
                clazz: ProfilVozidla,
                endpoint: "user/profilvozidla",
                tableTitle: t("ProfilVozidla.title"),
                columns: [
                    {
                        title: t("ProfilVozidla.nazev"),
                        field: 'nazev',
                        defaultSort:"asc",
                        filterProps:() => ({type:FormInputType.Text})
                    },
                    {
                        title: t("ProfilVozidla.osrmProfile"),
                        field: 'osrmProfile',
                        defaultSort:"asc",
                        filterProps:() => ({type:FormInputType.Text}),
                        render:(data) => exist(data.osrmProfile) ? OsrmProfile[data.osrmProfile].toString() : null
                    },
                    {
                        title: t("Currency.Title"),
                        field: 'currency.name',
                        defaultSort:"asc",
                        filterProps:() => ({type:FormInputType.Text})
                    },
                    {
                        title: t("ProfilVozidla.casovyNakladH"),
                        field: 'casovyNakladH',
                        defaultSort:"asc",
                        filterProps:() => ({type:FormInputType.Text})
                    },
                    {
                        title: t("ProfilVozidla.fixniNaklad"),
                        field: 'fixniNaklad',
                        defaultSort:"asc",
                        filterProps:() => ({type:FormInputType.Text})
                    },
                    {
                        title: t("ProfilVozidla.spotreba"),
                        field: 'spotreba',
                        defaultSort:"asc",
                        filterProps:() => ({type:FormInputType.Text})
                    },
                    {
                        title: t("ProfilVozidla.cenaPaliva"),
                        field: 'cenaPaliva',
                        defaultSort:"asc",
                        filterProps:() => ({type:FormInputType.Text})
                    }
                ]
            }
        }

        createInstance={() => {
            const pv = new ProfilVozidla();
            pv.displayPreprava = true;
            pv.displayVozidlo = true;
            return pv;
        }}
        allowDelete={true}
        preventCloseAfterSave={id === 'new'}
        crudConfig={{editEnabled: true, removeEnabled:true, addEnabled:false}}

        layoutForm={() => {
            return <ProfilVozidlaForm/>
        }}
    />
}
