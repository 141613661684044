import {useTranslation} from "react-i18next";
import {Dial} from "../../../../raal_components/dial/Dial";
import {report_text} from "../../../../raal_components/Endpoints";
import * as React from "react";
import {ReportText} from "../../../../model/ReportText";
import {ReportTextLayoutForm} from "./ReportTextLayoutForm";
import {RouteComponentProps} from "../../../../routes";

export function ReportTextsDial(props: RouteComponentProps) {
    const {t} = useTranslation();

    return <Dial<ReportText>
        excludeFieldsForDirtyCheck={["id", "textKey"]}
        mode={props.mode}
        config={{
            clazz: ReportText,
            tableTitle: t("Dials.ReportTexts"),
            endpoint: report_text,
            getDetailIdUrlPart: (data) => `${data.textKey}`,
            filtering: false,
            hideAddNewActions: true,
            columns: [
                {
                    title: t("ReportText.TextKey"),
                    field: 'textKey',
                    sorting: false,
                },
            ]
        }}
        pagingDisabled={true}
        hideNewButtonOnEdit
        crudConfig={{addEnabled: false, isDeletable: () => false}}
        layoutForm={edited => <ReportTextLayoutForm edited={edited}/>}
    />
}
