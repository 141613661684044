import {LatLng} from "leaflet";
import {exist} from "../../utils/Util";
import {GeoError} from "../../utils/error-utils";

let NominatimUrl = "";
export function setNominatimUrl(url:string) {
    NominatimUrl = url;
}
export type OsmAddress = {
    neighbourhood: string
    suburb: string
	town?: string
	municipality?: string,
    city: string
    county: string
    state: string
    postcode: string
    country: string
    country_code: string
    road: string
    village: string
}
export type OsmPlace = {
    place_id: string
    licence: string
    osm_type: string
    osm_id: string
    lat: number
    lon: number
    place_rank: string
    category: string
    type: string
    importance: string
    addresstype: string
    name: string
    display_name: string
    address:OsmAddress,

    //helper field
    isCustomValue:boolean
    isInitialValue:boolean
    isOption:boolean
    ignoreDetail: boolean
}

export class OsmAddressClz {
    neighbourhood: string;
    suburb: string;
    city: string;
    county: string;
    state: string;
    postcode: string;
    country: string;
    country_code: string;
    road: string;
}

export class OsmPlaceClz {
    place_id: string;
    licence: string;
    osm_type: string;
    osm_id: string;
    lat: number;
    lon: number;
    place_rank: string;
    category: string;
    type: string;
    importance: string;
    addresstype: string;
    name: string;
    display_name: string;
    address:OsmAddressClz;
    isCustomValue:boolean;
    isInitialValue:boolean;
    isOption:boolean;
    ignoreDetail: boolean;
}

export async function reverse({lat, lng}:LatLng):Promise<OsmPlace> {
    const result = await fetch(`${NominatimUrl}/reverse?lat=${lat}&lon=${lng}&format=jsonv2&accept-language=orig`);
    if(result.status === 200) {
        const rObj = await result.json();
        if(exist(rObj.error)) {
            throw new GeoError(rObj.error)
        }
        return await rObj as OsmPlace;
    }
    throw new Error(`${result?.status ?? "unspecified error"}`);
}

export function isFreeFormQuery(value:string) {
    return !value.includes(":")
}

export async function search(query:string):Promise<OsmPlace[]> {
    let q = `q=${query.replace(/\s+/g, "+")}`;

    if(!isFreeFormQuery(query)) {
        let byParams = query.split(":");
        if(byParams.length > 0 && byParams[0].trim() !== "") {
            //accept-language je hack aby se donutil nominatim posilat data v jazyce dane zeme, je otazka jestli to bude vzdy fungovat ok
            const params:string[] = [`country=${byParams[0]}`];
            if(byParams.length > 1 && byParams[1].trim() !== "") {
                params.push(`postalcode=${byParams[1]}`);
            }
            if(byParams.length > 2 && byParams[2].trim() !== "") {
                params.push(`city=${byParams[2]}`);
            }
            q = params.join("&");
        }
    }
    const result = await fetch(`${NominatimUrl}/search?${q}&addressdetails=1&format=jsonv2&accept-language=orig`);
    if(result.status === 200) {
        return (await result.json() as OsmPlace[]).map(p=>{
            if(p.lon)
                p.lon = parseFloat(p.lon as unknown as string);
            if(p.lat)
                p.lat = parseFloat(p.lat as unknown as string);
            if((p.address?.postcode?.length ?? 0) > 6) {
                p.address.postcode = p.address?.postcode.substr(0, 6)
            }
            return p;
        });
    }
    throw new Error(`${result?.status ?? "unspecified error"}`);
}
