import React, {PropsWithChildren} from "react";
import {useAppContext} from "../../context/AppContext";
import {User, UserRole} from "../../model/User";

interface SecuredContentByRoleProps {
    roles?: UserRole[]
    condition?: (user: User) => boolean
}

export function SecuredContentByRole(props: PropsWithChildren<SecuredContentByRoleProps>) {
    const {user} = useAppContext();
    const permitted = props.roles ? props.roles.find(r => user.roles.indexOf(r)>-1) : props.condition && props.condition(user);

    return <>{permitted ? props.children : undefined}</>
}