import {Provozovna} from "../model/Provozovna";
import {OptionType, SelectDateProps, SelectProps} from "../../common/component/form/FormSelect";
import {User, UserRole, UserRoleFilter} from "../model/User";
import {Firma} from "../model/Firma";
import {Stat} from "../model/Stat";
import {useTranslation} from "react-i18next";
import {Ciselnik, CiselnikTyp, useCiselnik} from "../model/Ciselnik";
import {useData, useLocalizeCiselnikValue} from "../context/DataContext";
import {formatDispecer, UserDispecer, UserViewDispecer} from "../model/Dispecer";
import {GenericMap} from "../../index.d";
import {Currency} from "../model/Currency";
import {InvalidDuvodInzerat} from "../model/Inzerat";
import { BasicUserData, InvalDuvodPreprava, InvalDuvodVozidlo, priceUnits } from '../model/CommonTypes';
import moment, {Moment} from "moment";
import {SystemParameter} from "../model/SystemParameter";
import {exist, isNumber} from "../../common/utils/Util";
import {SimpleValue} from "../model/SimpleValue";
import {KLASIFIKACE_VALUES} from "../model/Klasifikace";
import {SimpleTemplate} from "../model/Template";
import {ProfilVozidla, ProfilVozidlaSelect} from "../model/ProfilVozidla";
import {useAppContext} from "../context/AppContext";
import { HereProfilVozidla, HereProfilVozidlaSelect } from '../model/HereProfilVozidla';

export const useRoleSelect = (props:SelectProps={}, excludeRoles: UserRole[]=[]):SelectProps => {
    let roles = Object.values(UserRole);
    roles = roles.filter(role => !excludeRoles.includes(role as UserRole));
    return useEnumeratedSelect(roles, "Enumerations.Roles", "string", {isMulti:true, ...props});
};

export const useRoleSelectFilter = (props:SelectProps={}, excludeRoles: UserRoleFilter[]=[]):SelectProps => {
    let roles = Object.values(UserRoleFilter);
    roles = roles.filter(role => !excludeRoles.includes(role as UserRoleFilter));
    return useEnumeratedSelect(roles, "Enumerations.Roles", "string", {isMulti:true, ...props});
};


export const useYesNoSelect = (props:SelectProps={}):SelectProps => {
    const {t} = useTranslation();
    return (
        {
            options:["true", "false"],
            noDynamicFontSize: true,
            formatOption: (value: string) => {
                return {
                    value: value,
                    label: t(`YesNo.${value}`)
                }
            },
            formatValue: (value: any) => value["value"],
            ...props
        }
    );
};

export const useProvozovnaStavSelect = (props:SelectProps={}): SelectProps => {
    const {t} = useTranslation();
    return (
        {
            options:["0", "1"],
            noDynamicFontSize: true,
            formatOption: (value: string) => {
                return {
                    value: value,
                    label: t(`Enumerations.Stav.${value}`)
                }
            },
            formatValue: (value: any) => value["value"],
            ...props
        }
    );
}

export const useProvozovnaPodstavSelect = (props:SelectProps={}): SelectProps => {
    const {t} = useTranslation();
    return (
        {
            options:["0", "1", "2", "3", "4", "5", "6", "7", "8"],
            noDynamicFontSize: true,
            formatOption: (value: string) => {
                return {
                    value: value,
                    label: t(`Enumerations.Podstav.${value}`)
                }
            },
            formatValue: (value: any) => value["value"],
            ...props
        }
    );

}

export const useFirmaSelect = (props:SelectProps={}):SelectProps => {
    return (
        {
            ajax: {
                url:"ac/firma",
                clazz: Firma,
                paginable:true
            },
            formatOption:(value:Firma)=>({value:value, label:`${value.r1Nazev} ${value.ico?`(${value.ico})`:""}`}),
            ...props
        }
    );
};

export const useFirmaSelectWithoutICO = (props:SelectProps={}):SelectProps => {
    return (
        {
            ajax: {
                url:"ac/firma",
                clazz: Firma,
                paginable:true
            },
            formatOption:(value:Firma)=>({value:value, label:`${value.r1Nazev}`}),
            ...props
        }
    );
};

export const useStatSelect = (props:SelectProps={}):SelectProps => {
    return (
        {
            isClearable:true,
            ajax: {
                url:"ac/stat",
                clazz:Stat,
                paginable:true
            },
            formatOption:(value:Stat)=>({value:value, label:`${value.mpz} (${value.nazevCz})`}),
            ...props
        }
    );
};
export const useStatSelectAsAutocomplete = (props:SelectProps={}):SelectProps => {
    return (
        {
            sort:(options => {
                return [...options].sort((a, b) => {
                    if((a.mpz === "SK" && b.mpz === "CZ") ||
                        //this condition coz firefox
                        ((a.mpz !== "SK" && b.mpz === "CZ") || (a.mpz !== "CZ" && b.mpz === "SK"))) {
                        return 1;
                    }
                    if (a.mpz === "CZ" || a.mpz === "SK") {
                        return -1;
                    }
                    return 0;
                })
            }),
            isClearable:true,
            autoOpenDisabled: true,
            ajax: {
                url:"ac/stat-enabled",
                clazz:Stat,
                paginable:true
            },
            formatValue(value): any {
                return value.value
            },
            formatOption:(value:OptionType)=>{
                if(value instanceof Stat){
                    return {value:`${value.mpz}`, label:`${value.mpz} (${value.nazevCz})`}
                } else {
                    return {value:`${value}`, label:`${value}`}
                }
            },
            ...props
        }
    );
};
export const useProvozovnaSelect = (props:SelectProps & {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/provozovna",
                clazz:Provozovna,
                paginable:true,
                params: props.params
            },
            formatOption: (value:Provozovna) => ({label: `${value && value.firma && value.firma.r1Nazev} (${(value && value.kod) || '-'})`, value: value}),
            ...props
        }
    );
};

export const useProvozovnaFullSelect = (props:SelectProps & {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/provozovna-full",
                clazz:Provozovna,
                paginable:true,
                params: props.params
            },
            inputStyle: (value:any) => {
                return value && value.value && value.value.stav !== 0 ? {color: 'red'} : {}
            },
            customOptionStyle: (option) => {
                return option.value && option.value.stav !== 0 ? {color: 'red'} : {};
            },
            formatOption: (value:Provozovna) => ({label: `${value && value.firma && value.firma.r1Nazev} (${(value && value.kod) || '-'})`, value: value}),
            ...props
        }
    );
};

export const useProvozovnaSelectKod = (props:SelectProps & {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/provozovna",
                clazz:Provozovna,
                paginable:true,
                params: props.params,
            },
            formatOption: (value:any) => {return {label: value.kod ? `${value && value.firma && value.firma.r1Nazev} (${(value && value.kod) || '-'})` : value["label"] ?? value, value: value.kod ? value.kod : value["value"] ?? value};},
            formatValue: (value: any) => value["kod"] ?? value["value"] ?? value,
            ...props
        }
    );
};

export const useProvozovnaSelectId = (props:SelectProps & {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/provozovna-basic",
                clazz:Provozovna,
                paginable:true,
                params: props.params,
            },
            formatOption: (value:any) => ({label: `${value?.firma && value?.firma?.r1Nazev} (${(value && value?.kod) || '-'})`, value: value }),
            formatExcludeList: options => options.map(value => value?.value?.id),
            ...props
        }
    );
};

export const useEnumeratedSelect = <T extends object>(enumSource:T, prefixKey:string, type:'number'|'string', props:SelectProps={}, allowedValues?: any[], inputList?: any[]):SelectProps => {
    const {t} = useTranslation();
    return {
        options: (inputList ? inputList : Object.values(enumSource).filter(k => typeof k === type)).filter(value => (allowedValues && allowedValues.indexOf(value)>=0) || !allowedValues),
        formatOption: (value: string) => {
            return {
                value: value,
                label: t(`${prefixKey}.${value}`)
            }
        },
        formatValue: (value: any) => value["value"],
        ...props
    }
};


export const useCiselnikSelect = (typ:CiselnikTyp, props:SelectProps={}):SelectProps => {
    const ciselnik = useCiselnik(typ);
    return {
        options: ciselnik.sort((a, b) => a.kod - b.kod),
        formatOption: (value: Ciselnik) => {
            const format = (ciselnik:Ciselnik) => {
                return {
                    value: ciselnik.kod,
                    label: ciselnik.hodnota
                }
            };
            if(exist(value.kod)) {
                return format(value);
            } else {
                const c = ciselnik.find(f=>f.kod === Number(value));
                if(c)   return format(c);
            }
            return null;
        },
        formatValue: (value: any) => value["value"],
        ...props
    }
};

export const usePrepravaInvalDuvSelect = () => {
    const {t} = useTranslation();
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);

    const list = Object.keys(InvalDuvodPreprava).filter(k => typeof InvalDuvodPreprava[k as any] === "number").map(i =>
        {
            return {
                kod: i,
                text: locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, i)
        }}
    );

    const valuesList = Object.keys(InvalDuvodPreprava).filter(k => typeof InvalDuvodPreprava[k as any] === "number");

    return {
        options: valuesList,
        formatOption: (value: any) => {
                return {
                    value: value,
                    label: list.find(c => c.kod===value)?.text ?? t(value)
                }
            },
        formatValue: (value: any) => {
            return value.value;
        }
    }
};


export const useEnumCiselnikSelect = (props: (SelectProps & {enm?: any, enmName?: string, ciselnikTyp?: CiselnikTyp})={}) => {
    const {t} = useTranslation();
    const locFun = useLocalizeCiselnikValue(props.ciselnikTyp);

    const list = Object.keys(props.enm).filter(k => typeof props.enm[k as any] === "string").map(i =>
        {
            return {
                kod: i,
                text: locFun(props.enmName, props.enm, i)
            }}
    );

    const valuesList = Object.keys(props.enm).filter((k: any) => typeof props.enm[k as any] === "string");

    return {
        options: valuesList,
        formatOption: (value: any) => {
            let v = value;
            v = props.enm[value as any];
            if(typeof v==="string") {
                v = value;
            }
            return {
                value: v,
                label: list.find(c => c.kod === v)?.text ?? t(v)
            }
        },
        formatValue: (value: any) => {
            return value.value;
        },
        ...props
    }
};

export const useInzeratInvalDuvSelect = (props:SelectProps={}) => {
    const {t} = useTranslation();
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.I);

    const list = Object.keys(InvalidDuvodInzerat).filter(k => typeof InvalidDuvodInzerat[k as any] === "string").map(i =>
        {
            return {
                kod: i,
                text: locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, i)
            }}
    );

    const valuesList = Object.keys(InvalidDuvodInzerat).filter(k => typeof InvalidDuvodInzerat[k as any] === "string");

    return {
        options: valuesList,
        formatOption: (value: any) => {
            let v = value;
            v = InvalidDuvodInzerat[value as any];
            if(typeof v==="string") {
                v = value;
            }
            if(typeof v==="number") {
                v = value.toString();
            }
            return {
                value: isNumber(v) ? v : InvalidDuvodInzerat[value as any].toString(),
                label: list.find(c => c.kod === v || c.kod === InvalidDuvodInzerat[value as any].toString())?.text ?? t(v)
            }
        },
        formatValue: (value: any) => {
            return value.value
        },
        ...props
    }
};

export const useVozidloInvalDuvSelect = () => {
    const {t} = useTranslation();
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.V);

    const list = Object.keys(InvalDuvodVozidlo).filter(k => typeof InvalDuvodVozidlo[k as any] === "number").map(i =>
        {
            return {
                kod: i,
                text: locFun("Enumerations.InvalDuvodVozidlo", InvalDuvodVozidlo, i)
            }}
    );

    const valuesList = Object.keys(InvalDuvodVozidlo).filter(k => typeof InvalDuvodVozidlo[k as any] === "number");

    return {
        options: valuesList,
        formatOption: (value: any) => {
            return {
                value: value,
                label: list.find(c => c.kod===value)?.text ?? t(value)
            }
        },
        formatValue: (value: any) => {
            return value.value;
        },
    }
};


export const useUserSelect = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/uzivatel",
                clazz:User,
                params: props.params,
                paginable:true
            },
            formatOption: (value:User) => ({label: `${value && value.login} ${(value && value.jmeno) || ""}`, value: value}),
            ...props
        }
    );
};

export const useDateSelect = (props:SelectDateProps={}):SelectDateProps => {
    let dates: Moment[] = [];
    const [advance] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === props.typAdvance));
    const maxAdvance = advance ? parseInt(advance.value) : props.defaultAdvanceValue;
    for(let d = 0; d <= maxAdvance;d++){
        if (props.dateType === 'datOd') dates.push(moment().startOf('day').add(d, 'days'));
        if (props.dateType === 'datDo') dates.push(moment(props.startDate).endOf('day').add(d, 'days'));
    }
    return (
        {
            options: dates,
            noDynamicFontSize: true,
            formatOption: (value: any) => {
                return {
                    value: value,
                    label: value.format("D.M.")
                }
            },
            getOptionSelected: ((option, value) => {
                    return moment(option?.value).format("DD-MM") === moment(value?.value).format("DD-MM");
                })
        }
    );
};

export const useDateIsoSelect = (props:SelectDateProps={}):SelectDateProps => {
    let dates: Moment[] = [];
    const [advance] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === props.typAdvance));
    const maxAdvance = advance ? parseInt(advance.value) : props.defaultAdvanceValue;
    for(let d = 0; d <= maxAdvance;d++){
        if (props.dateType === 'datOd') dates.push(moment().startOf('day').add(d, 'days'));
        if (props.dateType === 'datDo') dates.push(moment(props.startDate).endOf('day').add(d, 'days'));
    }
    return (
        {
            options: dates,
            noDynamicFontSize: true,
            formatOption: (value: any) => {
                const d = moment(value).toISOStringWithMillis()
                const lbl = d.replace('T', '-').split('-')
                return {
                    value: d,
                    label: `${lbl[2]}.${lbl[1]}.`
                }
            }
        }
    );
};

export const useAllUsersSelect = (props:SelectProps):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/uzivatel-all",
                clazz:User,
                paginable:true
            },
            formatOption: (value:User) => ({label: `${value && value.login} ${(value && value.jmeno) || ""}`, value: value}),
            ...props
        }
    );
};

export const useUserDispecerSelect = (props:SelectProps & {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: (data) => {
                return {
                url: "ac/user-dispecer",
                clazz:UserDispecer,
                paginable:true,
                params: {uzivatelId: data?.uzivatel?.id, ...props.params}
            }},
            formatOption: (value:UserDispecer) => ({label: `${value?.jmeno ?? ''}`, value: value ? value : undefined}),
            ...props
        }
    );
};

export const useAllDispecerSelect = (props:SelectProps & {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/all-dispecer",
                clazz:UserDispecer,
                paginable:true,
                params: props.params
            },
            formatOption: (value:UserDispecer) => ({label: `${value?.jmeno ?? ''}`, value: value ? value : undefined}),
            ...props
        }
    );
};

export const useDispecerForInputFormsSelect = (props:SelectProps & {params?:GenericMap} ={}):SelectProps => {
    const {dispeceri} = useData<UserViewDispecer>();
    const {user} = useAppContext();
    return (
        {
            options: dispeceri,
            inputStyle: (value:any) => {
                return value && value.value.jmeno === user.jmeno ? {color: 'red'} : {}
            },
            formatOption: (value:UserViewDispecer) => ({label: formatDispecer(value) , value: value ? value : undefined}),
            ...props
        }
    );
};



export const useUserBasicSelect = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/user-uzivatel",
                clazz:BasicUserData,
                params: props.params,
                paginable:true
            },
            formatOption: (value:BasicUserData) => ({label: `${value && value.login}${(value?.jmeno && ` (${value.jmeno})`) || ""}`, value: value}),
            ...props
        }
    );
};

export const useUserBasicSelectPz = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/user-uzivatel-pz",
                clazz:BasicUserData,
                params: props.params,
                paginable:true
            },
            formatOption: (value:BasicUserData) => ({label: `${value && value.login}${(value?.jmeno && ` (${value.jmeno})`) || ""}`, value: value}),
            ...props
        }
    );
};

export const useCurrencySelect = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
    const {meny} = useData<Currency>();
    return (
        {
            options: meny.filter(value => value.enabled===true),
            formatValue(value): any {
                return value.value
            },
            formatOption:(value:Currency)=>{
                if(value instanceof Currency){
                    return {value:`${value.currencyCode}`, label:`${value.currencyCode}`}
                } else {
                    return {value:`${value}`, label:`${value}`}
                }
            },
            ...props
        }
    );
};

export const useCurrencySelectObj = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
    const {meny} = useData<Currency>();
    return (
        {
            options: meny.filter(value => value.enabled===true),
            noDynamicFontSize: true,
            formatOption: (value:Currency) => ({label: value.name, value: value}),
            getOptionSelected: ((option , value) => {
                return option.value?.currencyCode === value.value?.currencyCode
            }),
            formatValue(value): any {
                return value.value
            },
            ...props
        }
    );
};

export const usePriceUnitsSelectObj = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
	return (
		{
			options: priceUnits,
			noDynamicFontSize: true,
			formatOption: (value:SimpleValue) => {
				return {label: value, value: value}
			},
			formatValue(value): any {
				return value?.value
			},
			...props
		}
	);
};

export const useRychlyContactSelect = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
    const {telefony} = useData<SimpleValue>();
    return (
        {
            options: telefony,
            formatOption: (value:SimpleValue) => {
                if(value instanceof SimpleValue) {
                    return {label: value.value, value: value.value}
                } else {
                    return {label: value, value: value}
                }
            },
            formatValue(value): any {
                if(value.value instanceof SimpleValue){
                    return value?.value?.value
                } else {
                    return value?.value
                }
            },
            ...props
        }
    );
};

export const useTemplateSelect = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/filter-template",
                clazz: SimpleTemplate,
                params: props.params,
                paginable:true
            },
            getOptionSelected: ((option , value) => {
                return option.value?.id.toString() === value.value?.id.toString()
            }),
            formatOption: (v:SimpleTemplate | any) => {
                if(v instanceof SimpleTemplate) {
                    return {label: v.nazevFiltru, value: {id: v.id, vychozi: v.vychozi}}
                } else {
                    return {label: v.name, value: {id: v.id, vychozi: v.vychozi}}
                }
            },
            ...props
        }
    );
};

export const createProvozovnaTelCislaContactSelect = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/provozovna-cisla",
                clazz:SimpleValue,
                params: props.params,
                paginable:true
            },
            formatOption: (value:SimpleValue) => {
                if(value instanceof SimpleValue) {
                    return {label: value.value, value: value.value}
                } else {
                    return {label: value, value: value}
                }
            },
            formatValue(value): any {
                if(value.value instanceof SimpleValue){
                    return value?.value?.value
                } else {
                    return value?.value
                }
            },
            ...props
        }
    );
};

export const createProvozovnaEmailyContactSelect = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/provozovna-emaily",
                clazz:SimpleValue,
                params: props.params,
                paginable:true
            },
            formatOption: (value:SimpleValue) => {
                if(value instanceof SimpleValue) {
                    return {label: value.value, value: value.value}
                } else {
                    return {label: value, value: value}
                }
            },
            formatValue(value): any {
                if(value.value instanceof SimpleValue){
                    return value?.value?.value
                } else {
                    return value?.value
                }
            },
            ...props
        }
    );
};


export const useKlasifikaceSelect = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
    const {t, i18n} = useTranslation();
    return (
        {
            options: KLASIFIKACE_VALUES,
            formatOption: (value: string) => {
                return {
                    value: value,
                    label: i18n.exists(`Enumerations.KlasifikaceValue.${value}`) ? t(`Enumerations.KlasifikaceValue.${value}`) : value
                }
            },
            formatValue: (value: any) => value["value"],
            ...props
        }
    );
};

export const useProfilVozidlaSelect = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/profil-vozidla",
                clazz:ProfilVozidlaSelect,
                params: props.params,
                paginable:true
            },
            formatOption: (value:ProfilVozidlaSelect) => ({label: value.nazev, value: value}),
            getOptionSelected: (option, value) => {
              return option?.value?.id===value?.value?.id
            },
            ...props
        }
    );
};

export const useProfilVozidlaSelectObj = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
    return (
        {
            ajax: {
                url: "ac/profil-vozidla",
                clazz:ProfilVozidla,
                params: props.params,
                paginable:true
            },
            formatOption: (value:ProfilVozidlaSelect) => ({label: value.nazev, value: value}),
            getOptionSelected: (option, value) => {
                return option?.value?.id===value?.value?.id
            },
            ...props
        }
    );
};

export const useHereProfilVozidlaSelectObj = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
	return (
		{
			ajax: {
				url: "ac/here-profil-vozidla",
				clazz:HereProfilVozidla,
				params: props.params,
				paginable:true
			},
			formatOption: (value:HereProfilVozidlaSelect) => ({label: value.nazev, value: value}),
			getOptionSelected: (option, value) => {
				return option?.value?.id===value?.value?.id
			},
			...props
		}
	);
};
