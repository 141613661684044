import {ReactElement} from "react";
import {useCodeBookDetailContext, useCodeBookDetailContextNew} from "./CodeBookDetail";

export function TabHelper<Detail extends object>({render}: { render: (data: Detail, edited: boolean) => ReactElement }) {
    const {data, edited} = useCodeBookDetailContext<Detail>();
    return render(data, edited);
}

export function TabHelperNew<Detail extends object>({render}: { render: (data: Detail, edited: boolean) => ReactElement }) {
    const {data, edited} = useCodeBookDetailContextNew<Detail>();
    return render(data, edited);
}
