import * as React from "react";
import {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {Moment} from "moment";
import {Box, Grid, Link, Typography} from "@material-ui/core";
import {TFunction} from "i18next";
import {Breakpoint} from "@material-ui/core/styles/createBreakpoints";
import {GridSize} from "@material-ui/core/Grid/Grid";
import {SpacingProps} from "@material-ui/system";
import {exist} from "../../utils/Util";
import {useStyleContext} from '../../../web/context/ThemeModeContext';

type GridWrap = (tuple:ReactElement) => ReactElement;
interface TextTupleConfig {
    title: string;
    content: any;
    type?:TextTupleType;
    boldTitle?:boolean,
    url?:string;
    onClick?: () => void;
    wrap?:GridWrap;
    highlightValue?:boolean;
    hide?:boolean;
    visible?: boolean;
}
export enum TextTupleType {
    Text = "text",
    DateTime = "datetime",
    Number = "number",
    Checkbox = "checkbox",
}

export type TextTupleGridItem = Partial<Record<Breakpoint, boolean | GridSize>>
export const TextTupleWrapper = (gridProps:TextTupleGridItem, spacingProps?:SpacingProps) => (e:ReactElement) => <Grid item {...gridProps}><Box {...spacingProps}>{e}</Box></Grid>;


export function TextTuple(props: TextTupleConfig) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    if ((exist(props.hide) && props.hide) || (exist(props.visible) && !props.visible))
        return null;
    return (renderComponent(props, t, classes));
}
const renderComponent = ({boldTitle=true, wrap= node => node, ...props}:TextTupleConfig, t:TFunction, classes:any):ReactElement => {

    const hasValue = () => {
        return !(!exist(props.content) || (typeof props.content === "string" && props.content.trim().length === 0));
    };

    if (props.type === undefined || props.type === TextTupleType.Text || props.type === TextTupleType.Number) {
        return wrap(
            <>
                <div className={boldTitle? "bold":""}>{props.title}</div>
                {!props.url&&!props.onClick&&<div className={(hasValue() && props.highlightValue) ? classes.coloredText : undefined} style={{overflowWrap: "anywhere"}}>{!hasValue() ? "-" : props.content}</div>}
                {props.url&&<Link href={props.url} target={"_blank"}>{!hasValue() ? "-" : props.content}</Link>}
                {props.onClick&&<Link onClick={props.onClick} style={{userSelect: "text"}} component="button"><Typography variant="body1">{!hasValue() ? "-" : props.content}</Typography></Link>}
            </>
        );
    } else if (props.type === TextTupleType.Checkbox) {
        return wrap(
            <>
                <div className={boldTitle? "bold":""}>{props.title}</div>
                <div>{props.content ? t("Default.Yes") : t("Default.No")}</div>
            </>
        );
    } else if (props.type === TextTupleType.DateTime) {
        return wrap(
            <>
                <div className={boldTitle? "bold":""}>{props.title}</div>
                <div className={(hasValue() && props.highlightValue) ? classes.coloredText : undefined}>{!hasValue() ? "-" : (props.content as Moment).toDateFormat()}</div>
            </>
        );
    }
   return null;
};