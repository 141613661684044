import { HereProfilVozidla } from '../../../../model/HereProfilVozidla';

export class HereResponse {
	routing?: RouteResponse;
	tolls?: RouteResponse;
	uniqueLocations: Set<Location>;
	hereProfilVozidla: HereProfilVozidla;
}

export interface RouteResponse {
	routes: Route[];
}

export interface Route {
	id?: string;
	sections: Section[];
}

export interface Section {
	id?: string;
	type?: string;
	departure: Departure;
	arrival: Arrival;
	summary: Summary;
	polyline?: string;
	transport: Transport;
	tolls?: Toll[];
	tollSystems?: TollSystem[];
	preActions?: Action[];
	postActions?: Action[];
	notices?: Notice[];
}

export interface Departure {
	time?: string;
	place?: Place;
}

export interface Arrival {
	time?: string;
	place?: Place;
}

export interface Place {
	type?: string;
	location?: Location;
	originalLocation?: Location;
}

export interface Location {
	lat?: number;
	lng?: number;
	reverseGeocodingItems?: ReverseGeocodingItems;
}

export interface Summary {
	duration?: number;
	length?: number;
	baseDuration?: number;
}

export interface Transport {
	mode?: string;
	name?: string;
}

export interface Toll {
	countryCode?: string;
	tollSystemRef?: number;
	tollSystem?: string;
	fares?: Fare[];
}

export interface Fare {
	id?: string;
	name?: string;
	price?: Price;
	convertedPrice?: Price;
	reason?: string;
	paymentMethods?: string[];
	pass?: Pass;
}

export interface Price {
	type?: string;
	currency?: string;
	value?: number;
}

export interface Pass {
	returnJourney?: boolean;
	validityPeriod?: ValidityPeriod;
}

export interface ValidityPeriod {
	period?: string;
	count?: number;
}

export interface TollSystem {
	id?: number;
	name?: string;
}

export interface Action {
	action?: string;
	duration?: number;
}

export interface Notice {
	title?: string;
	code?: string;
	severity?: string;
}

export interface ReverseGeocodingItems {
	items: Item[];
}

export interface Item {
	title?: string;
	id?: string;
	resultType?: string;
	address?: Address;
	position?: Position;
	distance?: number;
	mapView?: MapView;
}

export interface Address {
	label?: string;
	countryCode?: string;
	countryName?: string;
	state?: string;
	countyCode?: string;
	county?: string;
	city?: string;
	district?: string;
	street?: string;
	postalCode?: string;
}

export interface Position {
	lat?: number;
	lng?: number;
}

export interface MapView {
	west?: number;
	south?: number;
	east?: number;
	north?: number;
}