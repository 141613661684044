import React from 'react';
import { Box } from '@material-ui/core';
import useConfig from './JsonConfig';

const Environment = () => {
	const config = useConfig();

	return (
		<>
			{config.environmentName && (
			<Box color="white" bgcolor={config.environmentColor} position="absolute" zIndex="9999"
				 fontSize={{ xs: '10px', sm: '12px', md: '14px' }} paddingLeft="2rem"
				 paddingRight="2rem" letterSpacing="0.2rem" fontWeight="bold">
				{config.environmentName}
			</Box>)}
		</>
	);
};

export default Environment