import {Account} from '../web/Accounts'
import {exist} from "./utils/Util";

interface IStorage {
    set(key:string, data:any, withToken?: boolean, type?:string):void
    get(key:string, withToken?: boolean, type?:string):any
    clear(key:string, withToken?: boolean, type?:string):void
    clearAll(id: number, type:string):void
    length(type:string):any
    reset(key:string, withToken?: boolean, type?:string):void
    getUserAccount(): Account
    redirectWithTargetBlank(url: string):void
}

const tokenStorage = "session"

class WebStorage implements IStorage {
    storages:{[key:string]:Storage};
    constructor() {
        this.storages = {
            local:localStorage,
            session:sessionStorage
        };
    }

    set(key:string, data:any, withToken:boolean, type: string) {
        const user = this.getUserAccount();
        const preventSet  = !user && withToken;
        if (!preventSet) this.storages[type].setItem(this.getKey(key, withToken), data);
    }

    get(key:string, withToken:boolean, type: string) {
        return this.storages[type].getItem(this.getKey(key, withToken));
    }

    clear(key:string, withToken:boolean, type: string) {
        this.storages[type].removeItem(this.getKey(key, withToken));
    }

    reset(key:string, withToken:boolean, type: string) {
        if (this.get(key, withToken, type))
            this.storages[type].setItem(this.getKey(key, withToken), null);
    }

    clearAll(id: number, type: string) {
        const storage = type === 'local' ? localStorage : sessionStorage;
        for (let k in storage){
            if (k?.includes(id.toString()))
                this.storages[type].removeItem(k);
        }
    }

    length(type: string) {
        return this.storages[type].length;
    }

    getKey(key:string, withToken: boolean) {
        if (withToken) {
            const user = this.getUserAccount();
            return `${user?.id??0}-${key}`;
        } else {
            return key;
        }
    }

    getUserAccount(): Account | null{
        const accountsData =  this.storages["local"].getItem("accounts");
        const accounts = accountsData ? JSON.parse(accountsData) : [];
        return accounts.find((a: Account) => a.token === this.storages[tokenStorage].getItem("token"));
    }

    redirectWithTargetBlank(url:string) {
        const asyncClearFunc = this.storages["session"].getItem(this.getKey("asyncClearFunc", true));
        if (exist(asyncClearFunc)) this.clear("asyncClearFunc", true, "session");
        window.open(url, '_blank');
        if (exist(asyncClearFunc)) this.set("asyncClearFunc",  asyncClearFunc, true, "session")
    }
}

class DataStorage implements IStorage {
    instance:IStorage;
    constructor() {
        this.instance = new WebStorage();
    }

    set(key:string, data:any, withToken = true, type="local") {
        this.instance.set(key, data, withToken, type);
    }

    get(key:string, withToken = true, type="local") {
        return this.instance.get(key, withToken, type);
    }

    clear(key:string, withToken = true, type="local") {
        return this.instance.clear(key, withToken, type);
    }

    clearAll(id: number, type="local") {
        return this.instance.clearAll(id, type);
    }

    length(type="local") {
        return this.instance.length(type);
    }

    reset(key:string, withToken = true, type="local") {
        this.instance.reset(key, withToken, type);
    }

    getUserAccount() {
        return this.instance.getUserAccount();
    }

    redirectWithTargetBlank(url:string) {
        this.instance.redirectWithTargetBlank(url)
    }
}


export default new DataStorage();
