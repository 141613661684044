import React from "react";
import {Grid} from '@material-ui/core';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {DashboardTile} from "../Dashboard/DashboardTile";
import {useLocation} from "react-router";

export function Help() {
    const {t} = useTranslation();
    const {pathname} = useLocation();
    return (
        <>
            <Grid style={{padding:20}}>
                <Grid container spacing={4}>
                    <DashboardTile text={t("Informations.Info")} icon={faIcon.faInfoCircle} href={`${pathname}/info`}/>
                    <DashboardTile text={t("Sections.Learning")} icon={faIcon.faSchool} href={`${pathname}/announcement`}/>
                    <DashboardTile text={t("Informations.System")} icon={faIcon.faDesktop} href={`${pathname}/system`}/>
                </Grid>
            </Grid>
        </>
    );
}
