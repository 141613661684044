const pl = {
  "MobileApp": {
    "AppLoading": {
      "CompanyInfo": "1. BAZA ŁADUNKÓW I DOSTĘPNYCH SAMOCHODÓW od 1992 roku."
    },
    "Buttons": {
      "Contact": "Kontakt"
    },
    "Login": {
      "InvalidCredentials": "Nieprawidłowe dane logowania"
    },
    "ConfigureParameters": {
      "NavigationTitle": "Edytuj widok",
      "SectionTitle": "Wybierz dane do wyświetlenia na karcie"
    },
    "SortParameters": {
      "NavigationTitle": "Sortuj listę według",
      "SortSectionTitle": "Wybierz kierunek zmiany:",
      "ParameterSectionTitle": "Wybierz parametr, według którego posortować listę:",
      "Descending": "Schodzenie",
      "Ascending": "Rosnąco"
    },
    "FilterParameters": {
      "CancelFilters": "Anuluj filtry",
      "Filter": "Filtr"
    },
    "Cargos": {
      "FilterCargos": "Filtruj transport",
      "AdditionalInfo": "Dodatkowe informacje o transporcie",
      "ChooseKinds": "Wybrane gatunki",
      "All": "Wszystkie",
      "NewCargo": "Nowy transport",
      "EditCargo": "Edycja transportu",
      "FillBasicInfo": "Wypełnij podstawowe informacje",
      "CargoDetail": "Szczegóły transportu",
      "Distance": "Dystans"
    },
    "Advertising": {
      "FilterAdvertising": "Filtruj reklamy",
      "NewAdvertising": "Nowa reklama",
      "EditAdvertising": "Edycja reklamy",
      "AdditionalInfo": "Dodatkowe informacje",
      "FillBasicInfo": "Wypełnij podstawowe informacje",
      "AdvertisingDetail": "Szczegóły reklamy"
    },
    "Lorries": {
      "FilterLorry": "Filtruj samochody",
      "NewLorry": "Nowy samochód",
      "FillBasicInfo": "Wypełnij podstawowe informacje",
      "AdditionalInfo": "Dodatkowe informacje",
      "LorryDetail": "Szczegóły samochodu",
      "EditLorry": "Edycja samochódu"
    },
    "Premises": {
      "FilterPremises": "Filtruj lokale użytkowe",
      "StreetNumber": "Ulica + numer opisowy",
      "Registration": "Rejestracja",
      "ContractDate": "Umowa /w RAAL z",
      "DetailInfo": "Informacje szczegółowe",
      "Accountants": "Księgowy"
    },
    "Tabs": {
      "Settings": "Ustawienia",
      "Cargos": "Transport",
      "Lorries": "Samochody",
      "Advertising": "Reklama"
    },
    "Settings": {
      "AppLanguage": "Język aplikacji",
      "AppAppearance": "Wygląd aplikacji",
      "AppSupport": "Wsparcie użytkownika",
      "AppSupportDescription": "W razie jakichkolwiek problemów prosimy o kontakt telefoniczny lub mailowy.",
      "AppSupportHtmlContent": "<p>email.: <a href=\"mailto:support@raal.cz\"  target=\"_self\">support@raal.cz</a>&nbsp;</p>                                                                                                                                 <p>mob.: +420 604 105 758</p>                                                                                                                                 <p>tel.: +420 495 217 281</p>                                                                                                                                 <p>tel.: +420 495 217 282</p>",
      "AppVersion": "Wersja {0}",
      "ChooseAppLanguage": "Wybór języka aplikacji",
      "ChooseAppAppearance": "Wybór wyglądu aplikacji",
      "DarkMode": "Ciemny",
      "LightMode": "Jasny",
      "SystemMode": "Ustawienia systemowe"
    }
  },
  "AppTitle": "RAALTRANS",
  "AppActivityType": {
    "View": "Przeglądanie"
  },
  "AppActivitySubType": {
    "Detail": "Szczegół",
    "Row": "Linia"
  },
  "AutoRefresh": {
    "true": "Automatyczne wczytywanie włączone (CTRL+spacja)",
    "false": "Automatyczne wczytywanie wyłączone (CTRL+spacja)",
    "title": "Automatyczne ładowanie",
    "NewData": "Nowe dane"
  },
  "RefreshAlert": {
    "TooltipOn": "Powiadomienia dźwiękowe o nowych ofertach WŁĄCZONE",
    "TooltipOff": "Powiadomienia dźwiękowe o nowych ofertach WYŁĄCZONE",
    "TooltipInactive": "Powiadomienie dźwiękowe WSTRZYMANE (aby wznowić, wymagana jest pewna czynność na stronie)"
  },
  "Buttons": {
    "Rejstriky": "Rejestry",
    "MakeInvalid": "Unieważnić",
    "MakeValid": "Przywrócić",
    "Help": "Pomoc",
    "Login": "Zaloguj sie",
    "Logout": "Wyloguj",
    "Save": "Narzucać",
    "SaveAsNew": "Zapisz jako nowy",
    "SaveAsCurrent": "Zapisz jako bieżący",
    "Delete": "Usuwać",
    "Refresh": "Przywrócić",
    "AddNewRecord": "Dodaj nowy rekord",
    "Cancel": "Anulować",
    "Confirm": "Potwierdzać",
    "Add": "Dodać",
    "ReloadWait": "Przeładuj z dłuższym oczekiwaniem",
    "Close": "Zamknąć",
    "Continue": "Kontynuować",
    "Cancellation": "Anulowanie",
    "Agree": "Zgadzam się",
    "SendTheFirstAccessData": "Wyślij 1. Dane dostępowe",
    "Check": "Sprawdzać"
  },
  "ConfirmDialog": {
    "Title": "Potwierdzenie",
    "Body": "Naprawdę chcesz działać?"
  },
  "DataChangedDialog": {
    "Title": "Formularz w toku",
    "Body": "Masz formularz w toku, czy na pewno chcesz opuścić stronę?\nWszystkie dane w toku zostaną utracone."
  },
  "Currency": {
    "Title": "Waluta",
    "name": "Nazwa",
    "currencyCode": "Kod",
    "kurz": "Kurs (CZK)",
    "datum": "Zsynchronizowane"
  },
  "DataContextChanged": {
    "title": "Dane uległy zmianie. Załaduj nowe dane za pomocą F5 lub naciskając przycisk.\nOstrzeżenie: Wszystkie dane w toku zostaną utracone."
  },
  "Default": {
    "Id": "ID",
    "Enabled": "Dozwolony",
    "Telefon": "Telefon",
    "QuickContact": "Szybki kontakt",
    "PhoneNumbers": "Numer telefonu.",
    "PhoneNumbersKratke": "Tel. c.",
    "FillCorrectPhone": "Wpisz poprawny numer telefonu",
    "Email": "E-mail",
    "Polozka": "Przedmiot",
    "Text": "Tekst",
    "Key": "Klucz",
    "Note": "Notatka",
    "NoteAdmin": "Uwaga administratora",
    "NoteMaster": "Notatka mistrza",
    "Slovo": "Słowo",
    "Name": "Nazwa",
    "DataName": "Nazwa",
    "Map": "Mapa",
    "Mpz": "MZR",
    "MapDefine": "Wprowadzanie współrzędnych",
    "Loading": "Ładowanie",
    "ModalZakladni": "Zapisz szczegóły",
    "TabZakladni": "Podstawowe dane",
    "region": "Rodzaj ofert",
    "typ": "Rodzaj ofert",
    "typLicence": "Drogomierz",
    "Datum": "Data",
    "DatCas": "Czas ostatniej zmiany",
    "Jazyky": "Języki",
    "Yes": "Tak",
    "No": "NIE",
    "Or": "Albo",
    "LoadingDetail": "Ładowanie szczegółów",
    "LoadingDetailFailed": "Nie udało się załadować szczegółów",
    "NotFound": "Nie znaleziono rekordu",
    "DeleteText": "Czy na pewno chcesz usunąć ten wpis??",
    "DeleteTitle": "Usuwanie rekordu",
    "Account": "Konta użytkowników",
    "AccountAdd": "Dodaj konto",
    "UnlockText": "Naprawdę chcesz odblokować zablokowany \n szczegół na tej karcie?",
    "UnlockTitle": "Odblokowanie rekordu",
    "PdfHeader": "Oferta {0} numer {1} i jej sponsor",
    "PdfFirma": "Pełne dane zakładu {0}",
    "PdfExportedUser": "Eksportował",
    "PdfExportedDate": "Data",
    "SendTheFirstAccessDataText2023": "Akcja skierowana jest przede wszystkim do lokali, które działały przed końcem 2023 roku. Czy na pewno chcesz kontynuować? ",
    "SendTheFirstAccessDataTitle2023": "Przesyłanie pierwszych danych dostępowych (2023)",
    "SendTheFirstAccessDataText2024": "Promocja skierowana jest przede wszystkim do placówek, które zostaną uruchomione dopiero z początkiem 2024 roku. Czy na pewno chcesz kontynuować?",
    "SendTheFirstAccessDataTitle2024": "Przesyłanie pierwszych danych dostępowych (2024)",
    "Jednotka": "Jednostka",
    "JednotkaShort": "JM"
  },
  "Dials": {
    "Users": "Użytkownicy",
    "Companies": "Firmy",
    "Places": "Lokale użytkowe",
    "Countries": "Stany",
    "Currencies": "Waluty i kursy walut",
    "Types": "Drugi",
    "Params": "Parametry",
    "Jobs": "Oferty pracy",
    "ZakazanaSlova": "Zakazane słowa",
    "NevhodnaSlovaFirma": "Wyszukiwane słowa dla firm",
    "HlidanaSlova": "Oglądane słowa",
    "VolneVozy": "Darmowe samochody",
    "Dispeceri": "Dyspozytorzy",
    "Prepravy": "Transport",
    "Advertising": "Reklama",
    "Messages": "Aktualności",
    "ThunderMessages": "Błyskawice",
    "HlidanaSlovaKonfiguraceFirem": "Konfiguracja firm",
    "Pvi": "Własny PVI",
    "PviShort": "PVI",
    "Firma": "Solidny",
    "Provozovna": "Sala operacyjna",
    "HistoryPrepravy": "Transport",
    "HistoryAdvertising": "Reklama",
    "HistoryVolneVozy": "Darmowe samochody",
    "Kilometrovnik": "Miejsca",
    "ProfilyVozidel": "Lista pojazdów",
    "Trasy": "Trasy",
    "PrepravaTrasa": "Trasa - Transport",
    "VozidloTrasa": "Trasa — pojazd",
    "KilometrovnikHlaseni": "Zgłaszanie błędów",
    "KilometrovnikHlaseniAdmin": "Zgłoszone błędy",
    "PrejezdyPreprav": "Przejazdy transportowe",
    "PrejezdyVozidel": "Przejazdy dla pojazdów",
    "Dokladky": "Ładowanie",
    "DiagPage": "Diagnostyka",
    "Notes": "Komentarz",
    "NepohodlneOsoby": "Niewygodni ludzie",
    "Pripady": "Sprawy",
    "Nadpripady": "Super sprawy",
    "Faktury": "Faktury",
    "ReportTexts": "Tłumaczenia tekstów",
    "TriADost": "3 razy i wystarczy",
    "Smlouvy": "Umowy"
  },
  "DialDefaults": {
    "CreateRecord": "Utwórz nagrywać",
    "CreateRecordAndNext": "Utwórz i następny",
    "CreateRecordAsNew": "Utwórz rekord jako nowy",
    "UpdateRecord": "Zapisz zmiany",
    "DeleteRecord": "Usuń",
    "ClearField": "Pusty",
    "UnlockRecord": "Odblokuj zapis",
    "ServerError": "Błąd serwera",
    "ServerErrorMessage": "Nieoczekiwany błąd serwera",
    "ServerTimeoutMessage": "Przekroczono limit czasu"
  },
  "Prejezdy": {
    "odkud": "Przejście skąd",
    "kam": "Przejście gdzie",
    "nabidkaOdkud": "Oferta - skąd",
    "nabidkaKam": "Oferta - gdzie",
    "radiusNabidkaOdkud": "Oferta - skąd [km]",
    "radiusNabidkaKam": "Oferta - gdzie [km]",
    "prejezdy": "Przejścia",
    "volneVozy": "Darmowe samochody",
    "prepravy": "Transport",
    "prejezd": "Ze skrzyżowaniem",
    "prejezdOnly": "Tylko przejście",
    "original": "Oryginalny",
    "found": "Znaleziony",
    "prejezdCelkem": "P-Ogółem",
    "prejezdOd": "P-Skąd",
    "prejezdKam": "W-Gdzie",
    "radiusPrejezd": "Maksymalna łączna przebyta odległość [km]",
    "radiusPrejezdOd": "Maksymalne przejście skąd [km]",
    "radiusPrejezdKam": "Maksymalne przejście do [km]",
    "kilometrovnikPrejezd": "Przeprawa kilometrowa",
    "vyhledaniPrepravy": "Przejazdy, wyszukiwanie transportu",
    "vyhledaniVozu": "Przejazdy, znalezienie wolnego samochodu",
    "calculate": "Szukaj skrzyżowań",
    "Preprava": "Domyślna wysyłka",
    "Vozidlo": "Pojazd domyślny",
    "trasa": "Trasa",
    "vozidlo": "Pojazd",
    "max": "Maksymalne przejście",
    "info": "Pomoc",
    "datum": "Data",
    "datumOd": "Data od",
    "datumDo": "Data do"
  },
  "Dispecer": {
    "Username": "Nazwisko dyspozytora",
    "DatCas": "Czas ostatniej zmiany"
  },
  "Enumerations": {
    "CiselnikTyp": {
      "I": "ja_reklama",
      "P": "P_Transport",
      "V": "V_wolne_samochody",
      "R": "Typ_R",
      "D": "D_Dodatek",
      "G": "G_Rejestr Spółek",
      "S": "S_Status"
    },
    "Druh": {
      "1": "Żagiel",
      "2": "Platon",
      "3": "Wywrotka",
      "4": "Szafa",
      "5": "Lodówka",
      "6": "Szafka izotermiczna",
      "7": "Tautliner",
      "8": "Szafa na ubrania",
      "9": "Cisterna",
      "10": "Mulda",
      "11": "Chodząca podłoga",
      "12": "Piętrowy",
      "13": "Podwozie",
      "14": "Płaski",
      "15": "Kontener. Podwozie",
      "16": "Rzeźnia",
      "17": "Mega",
      "18": "Jumbo",
      "19": "Solo",
      "20": "Dostawa-žagiel",
      "21": "Dostawa-szafa",
      "22": "Ulec poprawie",
      "23": "Transporter samochodowy",
      "250": "Inne"
    },
    "Duvod": {
      "NABIDKA": "Oferta",
      "POPTAVKA": "Zapytanie"
    },
    "InvalDuvodPreprava": {
      "0": "OK",
      "1": "Data należy już do przeszłości",
      "2": "Przewóz zawiera niedozwolone słowa",
      "3": "Unieważniony przez operatora",
      "4": "Użytkownik ma zakaz publikowania przesyłek",
      "5": "Operacja zawieszona",
      "6": "Data jest zbyt odległa od dzisiaj",
      "7": "Duży zakres między datą od a datą do",
      "8": "Zawiera numer telefonu niewymieniony w kontaktach",
      "9": "Dwulicowość",
      "10": "Użytkownik zawieszony",
      "11": "Użytkownik bez telefonu",
      "12": "Likwidacja uprawnień przez administratora",
      "OK": "Ok",
      "NEZNAME_TELC": "Nieznany numer telefonu"
    },
    "InvalDuvodVozidlo": {
      "0": "W porządku",
      "1": "Data należy już do przeszłości",
      "2": "Bezpłatny samochód zawiera zakazane słowa",
      "3": "Unieważniony przez operatora",
      "4": "Użytkownik ma zakaz umieszczania darmowych samochodów",
      "5": "Operacja zawieszona",
      "6": "Data jest zbyt odległa od dzisiaj",
      "7": "Duży zakres między datą od a datą do",
      "8": "Zawiera numer telefonu niewymieniony w kontaktach",
      "9": "Dwulicowość",
      "10": "Użytkownik zawieszony",
      "11": "Użytkownik bez telefonu",
      "12": "Likwidacja uprawnień przez administratora",
      "OK": "ok",
      "NEZNAME_TELC": "Nieznany numer telefonu"
    },
    "InvalidDuvodInzerat": {
      "0": "W porządku",
      "1": "Ogłoszenie jest za stare",
      "2": "Ogłoszenie zawiera słowa zabronione",
      "3": "Unieważniony przez operatora",
      "4": "Zakazuje się użytkownikowi zamieszczania reklam",
      "5": "Operacja zawieszona",
      "8": "Zawiera numer telefonu niewymieniony w kontaktach",
      "9": "Dwulicowość",
      "10": "Użytkownik zawieszony",
      "11": "Użytkownik bez telefonu",
      "12": "Likwidacja uprawnień przez administratora",
      "OK": "ok",
      "INZERAT_ZNEPLATNEN": "Ogłoszenie unieważnione"
    },
    "InvoiceFrequency": {
      "0": "Miesięczny",
      "1": "Kwartalnik",
      "2": "Półroczny",
      "3": "Rocznie",
      "MONTHLY": "Miesięczny",
      "QUARTERLY": "Čtvrtletně",
      "HALFYEARLY": "Co pół roku",
      "YEARLY": "Rocznie"
    },
    "KlasifikaceColor": {
      "0": "Brak",
      "1": "zielony",
      "2": "Pomarańczowy",
      "3": "Czerwony"
    },
    "KlasifikaceValue": {
      "0": "Brak",
      "1": "1",
      "2": "2",
      "3": "3"
    },
    "LicenceTyp": {
      "1": "Nie",
      "2": "Tak",
      "WITHKM": "Tak",
      "WITHOUTKM": "Nie"
    },
    "Podstav": {
      "0": "Bez podstawy",
      "1": "Akcja 3 razy i wystarczy",
      "2": "Płatności ryczałtowe",
      "3": "Niestandardowe żądanie",
      "4": "Znakomity SW",
      "5": "Naruszenie umowy",
      "6": "Zakończenie",
      "7": "Problem",
      "8": "Oryginalne dokumenty",
      "BEZ_PODSTAVU": "Bez podstawy",
      "X3_A_DOST": "Akcja 3 razy i wystarczy",
      "PLATBY": "Płatności ryczałtowe",
      "POZASTAVEN_VLASTNI_ZADOST": "Niestandardowe żądanie",
      "PODEZRELY": "Znakomity SW",
      "PORUSENI_SMLOUVY": "Naruszenie umowy",
      "VYPOVED": "Zakończenie",
      "PROBLEM": "Problem",
      "ORIGINAL": "Oryginalne dokumenty"
    },
    "Predmet": {
      "NAKLADNI_AUTA": "Samochody ciężarowe",
      "OSOBNI_AUTA": "Samochody",
      "PRIVESY_NAVESY": "Przyczepy/Naczepy",
      "NAHRADNI_DILY": "Części zamienne",
      "JINE": "Inny"
    },
    "Region": {
      "0": "CZ",
      "1": "EU",
      "2": "GLOBAL",
      "3": "SK",
      "4": "CZSK"
    },
    "Roles": {
      "ROLE_ADMIN": "Administrator",
      "ROLE_MASTER": "Gospodarz",
      "ROLE_USER": "Użytkownik",
      "ROLE_NONE": "Brak roli"
    },
    "TypOdesilatele": {
      "FYZICKA_OSOBA": "FO (osoba fizyczna)",
      "PRAVNICKA_OSOBA": "PO (podmiot prawny)",
      "SLOVENSKO": "SK (Słowacja)"
    },
    "SmlouvaS": {
      "NONE": "Bez kontraktu",
      "FOHK": "FO HK",
      "FONITRA": "FO Nitra",
      "RAALHK": "RAALTRANS a.s. HK",
      "RAALNITRA": "RAALTRANS a.s. Nitra",
      "RAALHKNOVA": "Nowy RAALTRANS a.s. HK",
      "FONITRANOVA": "Nowy FO Nitra",
      "FOHKNOVA": "Nowy FO HK",
      "RAAL_AS_2023": "RAALTRANS a.s. 2023",
      "RAAL_AS_2024": "RAALTRANS a.s. 2024",
      "RAAL_SRO_2023": "RAALTRANS s.r.o 2023",
      "RAAL_SRO_2024": "RAALTRANS s.r.o 2024"
    },
    "Stav": {
      "0": "Wchodzi",
      "1": "Zawieszony",
      "JEDE": "Wchodzi",
      "POZASTAVIL": "Zawieszony"
    },
    "SystemParamKey": {
      "PREPRAVA_DATE_RANGE": "Maksymalny zakres danych dla transportów (w dniach)",
      "VOZIDLA_DATE_RANGE": "Maksymalny zakres danych dla bezpłatnych samochodów (w dniach)",
      "INZERAT_MAX_AGE": "Maksymalny wiek reklamy (w dniach)",
      "PREPRAVY_DRUHY_MAX": "Maximální počet druhů u přeprav",
      "VOZIDLA_DRUHY_MAX": "Maksymalna liczba typów dla darmowych samochodów",
      "PREPRAVA_DATE_ADVANCE_MAX": "Maksymalne wyprzedzenie danych dla transportów (w dniach)",
      "VOZIDLO_DATE_ADVANCE_MAX": "Maksymalny postęp danych dla bezpłatnych samochodów (w dniach)",
      "PREPRAVA_LENGTH": "Miernik obciążenia dla transportów",
      "PREPRAVA_WEIGHT": "Waga transportowa",
      "VOZIDLO_LENGTH": "Miernik załadunku dla darmowych samochodów",
      "VOZIDLO_WEIGHT": "Waga dla wolnych samochodów",
      "PROVOZOVNA_CANCEL_PERIOD": "Ustawienie flagi anulowane po rozwiązaniu umowy (w dniach)",
      "MAX_OFFER_EDITS": "Maksymalna liczba odnowień / edycji menu (-1 = nieograniczony)",
      "OFFER_DELETE_DUPLICATES": "Usuń duplikaty z ofertą",
      "MAX_CALCULATION_JOBS_PER_NODE": "Maksymalna liczba obliczonych tras związanych z jednym serwerem",
      "UZIVATEL_KONTROLA_PODSTAVU": "Lista przesłanek do sprawdzenia logowania IP użytkownika",
      "ARCHIVE_RETENTION_PERIOD": "Usuwanie ofert z archiwum wpisów (w dniach do momentu ich wstawienia do archiwum)",
      "VIEW_ARCHIVE_RETENTION_PERIOD": "Usuwanie ofert z archiwum przeglądania (w dniach do momentu wstawienia ich do archiwum)",
      "ARCHIVE_PERIOD": "Przenieś oferty do archiwum wpisów (w dniach od unieważnienia)",
      "HIDE_INZERCE": "Ukryj reklamy",
      "IGNORE_NOTIFICATION_PROVOZOVNA_KOD": "Zakłady bez nadzoru"
    },
    "TypZpravy": {
      "B": "Błyskawica",
      "C": "C",
      "I": "I",
      "R": "R",
      "S": "S",
      "T": "Szkolenie",
      "H": "H",
      "V": "Opis systemu"
    },
    "RoadType": {
      "0": "Autostrada",
      "1": "Droga dla pojazdów mechanicznych",
      "2": "Droga klasy I",
      "3": "Droga klasy II",
      "4": "Droga klasy III",
      "5": "Inne"
    },
    "TrasaState": {
      "0": "Oczekiwanie na obliczenie",
      "1": "Obliczanie w toku",
      "2": "Obliczone",
      "3": "Błąd"
    },
    "OsrmProfile": {
      "0": "N1 (do 3,5 tony)",
      "1": "N2 (do 12 ton)",
      "2": "N3 (do 40 ton)"
    },
    "RouteCalculationStatus": {
      "0": "W porządku",
      "1": "Błąd serwera",
      "2": "Nie znaleziono trasy",
      "3": "Nie można obliczyć kosztu",
      "4": "Niekompletna konfiguracja – opłaty drogowe, stawki, ..."
    },
    "KilometrovnikHlaseniStatus": {
      "0": "Nový",
      "1": "Uzavřený"
    },
    "DluhNadpripadStav": {
      "0": "Otwórz",
      "1": "Zamknięte"
    },
    "DluhPripadStav": {
      "0": "Niewysłane",
      "1": "Niepotwierdzone",
      "2": "Wybitny",
      "3": "Zamknięte",
      "4": "Rozwiązany bez płatności",
      "5": "Zamknięte (Zakończenie)",
      "6": "Zamknięte (punkt 3.9)",
      "7": "Wygasł",
      "8": "Odrzucony"
    },
    "DluhFakturaStav": {
      "0": "Nie wysłano",
      "1": "Niepotwierdzone",
      "2": "Nierefundowane",
      "3": "Zwrot kosztów",
      "4": "Rozwiązany bez płatności",
      "5": "Zaległe (wypowiedzenie)",
      "6": "Bezrefundowane (klauzula 3.9)",
      "7": "Wygasł",
      "8": "Odrzucony"
    },
    "ReportLanguage": {
      "cs": "czeski",
      "en": "angielski",
      "de": "niemiecki",
      "pl": "polski",
      "sk": "słowacki"
    },
    "TypPravniDokumentace": {
      "VP": "Ogólne warunki",
      "LP": "Warunki licencji",
      "GDPR": "Zasady ochrony danych osobowych",
      "PDI": "Dostarczanie informacji o ruchu drogowym",
      "VYPCZ": "Rozwiązanie umowy - CZ",
      "VYPSK": "Rozwiązanie umowy - SK"
    },
    "LastAction": {
      "PASSWORD_CHANGE": "Zmiana hasła"
    }
  },
  "ErrorComponent": {
    "title": "Błąd",
    "homeButton": "Powrót do strony głównej",
    "redirectionMessage": "Za chwilę zostaniesz przekierowany na stronę główną.",
    "error403": "Odmowa dostępu"
  },
  "Export": {
    "detailHeader": "Szczegół"
  },
  "Filter": {
    "Templates": "Filtr wyszukiwania",
    "TemplateName": "Nazwa filtra",
    "SaveTemplate": "Nowy",
    "UpdateTemplate": "Narzucać",
    "RemoveTemplate": "Usuwać",
    "SelectTemplate": "Wybierz filtr wyszukiwania",
    "NoTemplates": "Nie znaleziono filtra",
    "ClearFilter": "Wyczyść filtr",
    "DoFilter": "Szukaj",
    "OpenFilter": "Filtry",
    "UseFilter": "Filtr",
    "TooltipForFilledFilter": "Filtr jest aktywny",
    "TooltipForEmptyFilter": "Filtr jest nieaktywny",
    "DeleteConfirmationText": "Czy na pewno chcesz usunąć filtr?",
    "Vychozi": "Domyślny",
    "SaveFilter": "Zapisz filtr",
    "SavedFilters": "Zapisane filtry"
  },
  "Firma": {
    "Id": "identyfikator firmy",
    "Title": "Solidny",
    "TabProvozovny": "Lokale użytkowe",
    "TabUzivatele": "Użytkownicy",
    "TabDispeceri": "Dyspozytorzy",
    "Provozovna": "Firma (Kod zakładu)",
    "pozastavena": "Odwołany",
    "gdpr": "Zgoda na RODO",
    "UserIdentityLabel": "Identyfikacja firmy",
    "UserLabel": "Solidny",
    "UserContactLabel": "Adres rejestracji",
    "UserNextLabel": "Inne informacje",
    "ContactLabel": "Adresy",
    "NextLabel": "Dane",
    "R1Nazev": "Nazwa",
    "R1Ulice": "Ulica",
    "R1CisloPopisne": "Liczba opisowa",
    "R1CisloPopisneKratke": "C.p.",
    "R1Obec": "Wieś",
    "R1CastObce": "Część wsi",
    "R1Psc": "kod pocztowy",
    "R1Stat": "Państwo",
    "ico": "NI",
    "dic": "Numer VAT UE / numer VAT",
    "dic1": "NIP",
    "vznik": "Założenie firmy",
    "NezadouciZakaznik": "Niechciany klient",
    "NezadouciZakaznikShort": "Niepożądany",
    "CreateFirmaAndProvozovna": "Utwórz firmę i zakład",
    "VytvoritFirmu": "Utwórz firmę",
    "Pojisteni": "Ubezpieczenie",
    "datPojist": "Data ubezpieczenia",
    "ChooseFirma": "Wybierz firmę",
    "FirmaMaSlovo": "Firma ma coś do powiedzenia",
    "EnabledOnFirma": "W firmie",
    "MissingFirma": "!!! Brakuje firmy !!!",
    "RegDatum": "Data rejestracji",
    "datSmlouvy": "Data zawarcia umowy / W RAAL od",
    "raalOd": "W RAAL od",
    "http": "WWW",
    "fPoznamka": "Uwaga administratora",
    "DatumZmenyRejstrik": "Data zmiany rejestrów",
    "PoznamkaRejstrik": "Uwaga na indeks",
    "AktualizaceRejstrik": "Zarejestruj aktualizacje",
    "Nadpripad": "Przykładem",
    "TooltipRejstrikyVeFirme": "Stan musi być wypełniony, aby wyświetlić rejestry"
  },
  "FormLocalization": {
    "DataSaved": "Rekord został pomyślnie zapisany",
    "DataSavedFailed": "Nie udało się zapisać rekordu",
    "DataRemoved": "Rekord został pomyślnie usunięty",
    "DataWasRemoved": "Rekord został usunięty",
    "DataWasEdited": "Rekord został zmieniony",
    "DataRemovedFailed": "Nie udało się usunąć rekordu",
    "ServerError": "Błąd serwera",
    "ValidationError": "Błąd weryfikacji",
    "FieldMessages": {
      "FieldIsRequired": "Dane obowiązkowe nie są wypełnione",
      "FieldNotNull": "Nie może wynosić zero",
      "FieldIsRequiredValue": "Dane obowiązkowe nie są wypełnione {0}",
      "CoordinatesMissing": "Brak współrzędnych pola {0}",
      "UserLicenceError": "Licencja licznika kilometrów nie obejmuje podanego adresu"
    },
    "DateTimePicker": {
      "invalidDate": "Data jest nieaktualna",
      "cancelLabel": "Anulować",
      "clearLabel": "Czysty",
      "emptyLabel": "Pusty",
      "invalidLabel": "Nieprawidłowa data",
      "okLabel": "Potwierdzać",
      "todayLabel": "Dzisiaj",
      "minDateMessage": "Data nie może przypadać w przeszłości",
      "minDateTimeMessage": "Data i godzina nie mogą przypadać w przeszłości",
      "maxDateMessage": "Data nie może przypadać w przyszłości",
      "disablePast": "Data nie może przypadać w przeszłości",
      "shouldDisableDate": "Data jest poza dozwolonym zakresem",
      "disableFuture": "Data nie może przypadać w przyszłości",
      "maxDate": "Nieprawidłowa data",
      "minDate": "Data nie może przypadać w przeszłości"
    },
    "Vicinity": {
      "Address": "Dokładnie",
      "Range": "Przejście",
      "NoKilom": "Wymagane prawo jazdy z licznikiem kilometrów"
    }
  },
  "FormEditor": {
    "add": "Přidat",
    "cancel": "Zrušit",
    "colorpicker": "Výběr barvy",
    "text": "Text",
    "background": "Pozadí",
    "h": "Nadpis {0}",
    "blockquote": "Odstavec",
    "code": "Kod",
    "blocktype": "Styly",
    "normal": "Normální",
    "embedded": "Vložené",
    "embeddedlink": "Vložený odkaz",
    "enterlink": "Otevřít odkaz",
    "emoji": "Emotikon",
    "fontfamily": "Písmo",
    "fontsize": "Velikost písma",
    "history": "Historie",
    "undo": "Zpět",
    "redo": "Znovu",
    "image": "Vložit obrázek",
    "fileUpload": "Nahrát soubor",
    "byURL": "URL",
    "dropFileText": "Přetáhněte sem soubor nebo klikněte pro výběr",
    "bold": "Tučné",
    "italic": "Kurzíva",
    "underline": "Podtržení",
    "strikethrough": "Přeškrtnuté",
    "monospace": "Kód",
    "superscript": "Horní index",
    "subscript": "Dolní index",
    "linkTitle": "Název odkazu",
    "linkTarget": "Cílová cesta",
    "linkTargetOption": "Otevřít odkaz v novém okně",
    "link": "Vložit odkaz",
    "unlink": "Odebrat odkaz",
    "list": "Seznam",
    "unordered": "Odrážky",
    "ordered": "Číslování",
    "indent": "Zvětšit odsazení",
    "outdent": "Zmenšit odsazení",
    "remove": "Vymazat formátování",
    "textalign": "Zarovnání textu",
    "left": "Zarovnat doleva",
    "center": "Zarovnat na střed",
    "right": "Zarovnat doprava",
    "justify": "Zarovnat do bloku"
  },
  "GeneratePassword": {
    "title": "Nowe hasło",
    "tooltip": "Nowe hasło zostanie automatycznie przesłane do użytkownika na podany adres e-mail.",
    "success": "Hasło zostało wysłane do użytkownika.c",
    "error": "Błąd generowania hasła."
  },
  "GoogleMaps": {
    "Title": "Mapa Google",
    "SearchPlaces": "Szukaj miejsc"
  },
  "HttpLocalization": {
    "SignedOut": "Wyrejestrowany",
    "NetworkError": "Błąd sieci",
    "Error401FingerPrint": "Zostałeś wylogowany, ponieważ zalogowałeś się w innej przeglądarce lub Twój token logowania wygasł",
    "LogoutBySystem": "Zostałeś wylogowany przez system.",
    "Error401": "Token logowania wygasł"
  },
  "Informations": {
    "Info": "Raal info",
    "System": "Opis systemu",
    "TollRate": "Stawka opłaty drogowej",
    "ExchangeRates": "Kurs wymiany",
    "ReleaseNotes": "Informacje o wydaniu"
  },
  "Inzerce": {
    "Id": "ID Inzerátu",
    "InvalidInzeratConfirm": "Czy poważnie myślisz o unieważnieniu reklamy?",
    "ValidInzeratConfirm": "Czy naprawdę chcesz anulować unieważnienie reklamy?",
    "CurrencyRequiredWhenPrice": "Należy go wypełnić wraz z ceną",
    "Price": "Cena",
    "currency": "Waluta",
    "popis": "Opis",
    "rychlyKontakt": "Szybki kontakt",
    "datIns": "Data utworzenia",
    "modifiedOn": "Data zmiany",
    "valid": "ważny",
    "invalDuv": "Przyczyna unieważnienia",
    "cena": "Waluta",
    "Description": "Opis",
    "AdText": "Opis",
    "Dispecer": "Dyspozytor",
    "History": "Historia",
    "OfferDetail": "Oferta",
    "ArchiveConfirm": "Czy poważnie myślisz o zarchiwizowaniu reklamy?",
    "zadavatel": "Wykonawca"
  },
  "ItemAge": {
    "Title": "Wiek oferty",
    "OldMax": "Co najwyżej stary",
    "FromLastBrowse": "Nowsze od ostatniego wyświetlenia",
    "ByDate": "Według daty",
    "Unknown": "Nieznany",
    "search": "Szukaj"
  },
  "javax": {
    "validation": {
      "constraints": {
        "NotEmpty": {
          "message": "nie może być pusty"
        },
        "Email": {
          "message": "Nieprawidłowy format adresu e-mail"
        }
      },
      "user": {
        "adminWithoutProvozovna": "Administrator nie może mieć przypisanego zakładu",
        "userWithProvozovna": "Użytkownik musi mieć przypisaną placówkę"
      },
      "dispatcher": {
        "licenseLimit": "Liczba dyspozytorów osiągnęła limit licencji"
      },
      "firma": {
        "requiredIdFields": "Obowiązkowa kombinacja stan + NIP/NIP/NIP"
      },
      "phone": {
        "notValid": "nieprawidłowy format numeru telefonu"
      },
      "offer": {
        "rychlyKontaktNotInProvozovna": "Numeru telefonu nie ma w wykazie numerów placówek"
      },
      "lock": {
        "couldNotLock": "Nie udało się zablokować rekordu"
      },
      "waypoint": {
        "noLicense": "Licencja licznika kilometrów nie obejmuje podanego adresu"
      }
    }
  },
  "Job": {
    "Stav": "Państwo",
    "Action": "Działanie"
  },
  "Licence": {
    "Id": "Id",
    "Title": "Licencja",
    "GenerateUsers": "Wygeneruj użytkownika",
    "NumberGreaterThan": "Liczba musi być większa niż {{value}}"
  },
  "Links": {
    "ZivnostenskyCr": "Handel CZ",
    "ZivnostenskySk": "Handel SK",
    "ObchodniCr": "handlowy ČR",
    "Ares": "ARESA",
    "ObchodniSk": "handlowy SK"
  },
  "Map": {
    "ClearWayPoints": "Wyczyść mapę",
    "SearchPlace": "Wybierz miejsce docelowe lub kliknij na mapę",
    "SearchNearPlace": "Wybierz miejsce docelowe *",
    "noLicense": "Nie masz licencji",
    "noHighway": "Nie znaleziono drogi",
    "noHighwaySelected": "Nie wybrano żadnej sekcji",
    "navigation": "Opis trasy"
  },
  "MaterialTable": {
    "columns": {
      "showColumnsTitle": "Ustaw kolumny",
      "menuTitle": "Dodaj lub usuń kolumny"
    },
    "body": {
      "emptyDataSourceMessage": "Brak dostępnych danych",
      "addTooltip": "Dodaj szybko nowy rekord",
      "deleteTooltip": "Usuń rekord",
      "removeTooltip": "Usuń rekord",
      "updateTooltip": "Odśwież rekord",
      "noUpdateTooltip": "Nie można zaktualizować rekordu",
      "editTooltip": "Edytuj rekord",
      "dataChanged": "Dane zostały zmienione",
      "dateTimePickerLocalization": {},
      "filterRow": {
        "filterTooltip": "filtr"
      },
      "editRow": {
        "saveTooltip": "Narzucać",
        "cancelTooltip": "Anulowanie",
        "mode": {
          "add": {
            "saveTooltip": "Uložit nový",
            "cancelTooltip": "Zrušit"
          },
          "update": {
            "saveTooltip": "Uložit změny",
            "cancelTooltip": "Zrušit změny"
          },
          "delete": {
            "saveTooltip": "Potvrdit smazání",
            "cancelTooltip": "Zrušit mazání"
          }
        },
        "deleteText": "Czy na pewno chcesz usunąć ten wpis?",
        "addTooltip": "Przejdź do zaawansowanego wpisu",
        "editTooltip": "Przejdź do zaawansowanej edycji",
        "readTooltip": "Przejdź do podglądu"
      }
    },
    "grouping": {
      "placeholder": "do grupy"
    },
    "pagination": {
      "labelDisplayedRows": "{from}-{to} z {count} ({overall} razem)",
      "labelRowsSelect": "wiersze",
      "labelRowsCount": "Liczba wierszy:",
      "labelRowsPerPage": "liczba na stronie",
      "firstAriaLabel": "Pierwszy",
      "firstTooltip": "Pierwszy",
      "previousAriaLabel": "poprzedni",
      "previousTooltip": "poprzedni",
      "nextAriaLabel": "Następny",
      "nextTooltip": "Następny",
      "lastAriaLabel": "ostatni",
      "lastTooltip": "ostatni"
    },
    "toolbar": {
      "searchTooltip": "szukaj",
      "searchPlaceholder": "szukaj"
    },
    "header": {
      "actions": ""
    },
    "resetColumnOrder": "Odśwież kolumny",
    "resetColumnOrderQuestion": "Czy na pewno chcesz przywrócić oryginalne kolumny tabeli?",
    "openMultipleAction": "Otwórz wiele akcji",
    "closeMultipleAction": "Zamknij wiele akcji",
    "export": "Eksport",
    "exportAll": "Eksportuj wszystko",
    "exportFilter": "Eksportuj filtr",
    "exportPdf": "Eksportuj do PDF",
    "exportXls": "Eksportuj do Excela (XLS)",
    "exportCsv": "Eksportuj do pliku CSV",
    "exportXml": "Eksportuj do XML-a"
  },
  "MuiAutocomplete": {
    "NoOption": "Brak wyników",
    "GPSCurrentPosition": "Pobierz aktualną pozycję GPS"
  },
  "MuiInput": {
    "MinTip": "Dolny limit filtrowania rekordów",
    "MaxTip": "Górny limit filtrowania rekordów"
  },
  "NevhodnaSlova": {
    "VsichniZakaznici": "Wszystkie firmy",
    "ZadniZakaznici": "Żadnych firm",
    "AllWords": "Wszystkie słowa",
    "HlidaneSlovoFirmy": "Firmy używające tego słowa"
  },
  "Vozidlo": {
    "id": "ID Vozidla",
    "ArchiveConfirm": "Chcesz poważnie zarchiwizować pojazd?"
  },
  "TransportNabidka": {
    "pocetEditaci": "Liczba edycji"
  },
  "Preprava": {
    "id": "Identyfikator transportu",
    "zadavatel": "Wykonawca",
    "regOd": "Podaj skąd",
    "pscOdkud": "KP skąd",
    "datOd": "Data od",
    "mena": "Waluta",
    "regKam": "Podaj gdzie",
    "datDo": "Data do",
    "datum": "data",
    "pscKam": "KP gdzie",
    "cena": "Cena",
    "vahaShort": "M [t]",
    "paletyShort": "P [ks]",
    "objemShort": "V [m3]",
    "delkaShort": "L [m]",
    "sirkaShort": "W [m]",
    "vyskaShort": "H [m]",
    "lozPlochaShort": "S [m2]",
    "doplnujiciUdaje": "Więcej szczegółów",
    "vaha": "Waga [t]",
    "palety": "Palety [ks]",
    "objem": "Tom [m3]",
    "lozPlocha": "Obszar ładunku [m2]",
    "lozPlochaSmaller": "L. ładunku [m2]",
    "delka": "Długość [m]",
    "sirka": "Szerokość [m]",
    "vyska": "Wysokość [m]",
    "datIns": "Data utworzenia",
    "modifiedOn": "Data zmiany",
    "valid": "ważny",
    "invalDuv": "Przyczyna unieważnienia",
    "odkud": "SKĄD",
    "odkudKamToolTip": "Aby wprowadzić wiele MZR / KP, użyj przecinka jako separatora",
    "kam": "GDZIE",
    "naklProstorVcelku": "Przestrzeń ładunkowa ogólnie",
    "neverPozn": "Prywatna notatka",
    "zvedaciCelo": "Podnoszenie czoła",
    "druhyRidic": "Drugi kierowca",
    "zakazDokladky": "Zakaz załadunku",
    "adr": "ADR",
    "hydraulickaRuka": "Ręka hydrauliczna",
    "zpusobNakladky": "Sposób załadunku",
    "datumZalozeni": "Data założenia",
    "datumArchivace": "Data archiwizacji",
    "nadrozmernyNaklad": "Ładunek ponadgabarytowy",
    "nakladkaZezadu": "Ładowanie od tyłu",
    "nakladkaBokem": "Ładowanie boczne",
    "nakladkaShora": "Topowe ładowanie",
    "nakladkaZezaduShort": "od tyłu",
    "nakladkaBokemShort": "bokiem",
    "nakladkaShoraShort": "powyżej",
    "doplnky": "Akcesoria",
    "jine": "Inny",
    "souprava": "Ustawić",
    "naves": "Naczepa",
    "druhy": "Drugi",
    "verejnaPozn": "Notatka",
    "uzivatel": "Wykonawca",
    "dispecer": "Dyspozytor",
    "History": "Dyspozytor",
    "InvalidPrepravaConfirm": "Poważnie chcesz unieważnić przesyłkę?",
    "ValidPrepravaConfirm": "Czy poważnie myślisz o anulowaniu przesyłki?",
    "InvalidVozidloConfirm": "Czy poważnie myślisz o unieważnieniu pojazdu?",
    "ValidVozidloConfirm": "Czy poważnie myślisz o wycofaniu swojego pojazdu?",
    "Misto": "Miejsce",
    "NSJAnyMustBeChecked": "Wybierz co najmniej jeden element z NSJ",
    "NSJOneMustBeChecked": "Wybierz tylko jedną pozycję z NSJ",
    "GeocodingCoordinates": "Otrzymuję współrzędne z ręcznie wprowadzonego adresu",
    "DruhyOverflow": "Maksymalny numer {0}",
    "AirDistance": "Dystans [km]",
    "AirDistanceShort": "KM",
    "AirDistanceToolTip": "Jest to odległość obliczona w linii prostej",
    "CurrencyRequiredWhenPrice": "Należy go wypełnić wraz z ceną",
    "okoliOdkud": "Okolice skąd",
    "okoliKam": "Okolice gdzie",
    "radiusOdkud": "Okolice skąd dokąd [km]",
    "radiusKam": "Okolice dokąd [km]",
    "ArchiveConfirm": "Czy poważnie myślisz o archiwizowaniu przesyłek?",
    "rychlyKontakt": "Szybki kontakt",
    "firma": "Solidny",
    "airDistance": "Dystans[km]",
    "currency": "Waluta",
    "nsj": "N,S,J",
    "psc": "KP",
    "mpz": "MZR"
  },
  "Provozovna": {
    "0": "Operacja w toku",
    "1": "Operacja zawieszona",
    "Id": "ID Lokale użytkowe",
    "Title": "Sala operacyjna",
    "Nazev": "Nazwa zakładu",
    "stopped": "Zawieszony",
    "PayInfo": "Fakturowanie",
    "souhrnne": "Rozliczane łącznie",
    "uOsobaUser": "Osoba zajmująca się księgowością",
    "uTelefonUser": "Telefon księgowy",
    "uEmailUser": "E-mail księgowy",
    "uosobaUser": "Osoba zajmująca się księgowością",
    "utelefonUser": "Telefon księgowy",
    "uemailUser": "E-mail księgowy",
    "uosoba": "Osoba do prowadzenia księgowości (fakturowanie)",
    "utelefon": "Telefon konta (fakturowanie)",
    "uemail": "E-mail księgowy (do fakturowania)",
    "OtherInfo": "Łączność",
    "emaily": "e-maile",
    "telCislaKontaktu": "Numery telefoniczne",
    "FilterInfo": "Użyj podstawowego / zaawansowanego filtra, aby potencjalnie wyszukać 30 odpowiednich placówek",
    "UserIdentityLabel": "Identyfikacja zakładu",
    "CompanyContactLabel": "Adres spółki",
    "UserContactLabel": "Adres zakładu",
    "UserCorespondLabel": "Adres kontaktowy",
    "KodProvozovny": "Kod zakładu",
    "UserInDetail": "szczegółowo",
    "IcoDic": "IČO / EU DIČ / DIČ",
    "PrefStat": "Stan prefiksu",
    "PNazev": "Tytuł P",
    "PUlice": "ul. P",
    "PCisloPopisne": "Numer opisowy",
    "PCisloPopisneKratke": "P n.o.",
    "PUliceCisloPopisne": "P Ulica + n.o",
    "PObec": "P Wieś",
    "PCastObce": "P Część wsi",
    "PPsc": "P KP",
    "PStat": "P Państwo",
    "KNazev": "K Nazwa",
    "KAdresat": "K Adresat",
    "KUlice": "K Ulica",
    "KCisloPopisne": "K Numer opisowy",
    "KObec": "K Wieś",
    "KCastObce": "K Część wsi",
    "KPsc": "K KP",
    "KStat": "K Państwo",
    "Kod": "Kod",
    "datSmlouvyZmena": "Nowa umowa",
    "RaalJmeno1": "Raal Kontakt 1",
    "RaalJmeno2": "Raal Kontakt 2",
    "RaalJmeno3": "Raal Kontakt 3",
    "KdoZmenil": "Kto się zmienił",
    "KdyZmena": "Kiedy się zmienił",
    "PotlacInzDatum": "Pomiń reklamy - data",
    "ZasilatReport": "Wyślij raport",
    "PotlacInz": "Pomiń reklamy",
    "PotlacPrepravy": "Tłumić transporty",
    "PotlacVv": "Tłumić darmowe samochody",
    "PotlacPrepravyDatum": "Potlačit přepravy - datum",
    "PotlacVvDatum": "Tłumić darmowe samochody - data",
    "VytvoritProvozovnu": "Stwórz zakład",
    "ZkusebniDoba": "Czas na test",
    "datzkus": "Okres próbny do godz",
    "ipDll": "Adres IP z zamówienia",
    "DatovaSchranka": "Skrzynka danych",
    "SmlouvaS": "Kontrakt z",
    "EmailProfa": "E-maile do wysyłania faktur i przypomnień",
    "SmsUpominky": "Numery telefonów do wysyłania przypomnień SMS o płatnościach",
    "Pojisteni": "Ubezpieczenie",
    "PojisteniNakladu": "Ubezpieczenie ładunków",
    "ZpusobFakturace": "Sposób wysyłania faktur",
    "email1": "Wysyłaj faktury e-mailem",
    "posta": "Wysyłaj faktury pocztą",
    "odlisadr": "Inny adres",
    "Nezobrazovat": "Nie wyświetlaj",
    "OmezeneInformace": "Ograniczone informacje",
    "NonGdprInfo": "Informacje niezwiązane z RODO",
    "ZkraceneInformace": "Skrócona informacja",
    "Fax": "Fax",
    "FrekvenceFaktur": "Częstotliwość faktur",
    "Stav": "stav",
    "Podstav": "Baza",
    "Dispecer": "Dyspozytor",
    "dispeceri": "Dyspozytorzy",
    "EmailsFilter": "E-mail (wyszukuje Biura, Dyspozytorzy, Księgowi)",
    "PhoneNumbersFilter": "Tel. Nr (przeszukuje Biura, Dyspozytorzy, Księgowi)",
    "TooltipZmenaVeFirme": "Zmiana wpłynie na całą firmę",
    "ProcessProvozovny": "Przetwarzać inne zakłady danej firmy?",
    "not1": "Notatka",
    "poznamka": "Uwaga administratora",
    "Records": "Dokumentacja",
    "Uzavreno": "Zamknięte",
    "DatumUzavreni": "Data zamknięcia",
    "ManualDataFetch": "Użyj podstawowego / zaawansowanego filtra, aby potencjalnie wyszukać 30 odpowiednich placówek",
    "PoslPripoj": "Ostatnie połączenie",
    "PocPripoj": "Liczba połączeń",
    "PocetUzivatelu": "Liczba użytkowników",
    "Zalozen": "Przyjęty",
    "datzmenstav": "Zmiana statusu",
    "SpStat": "Specjalizuje się w stanach",
    "jazyky": "Języki",
    "Auta": "Własne samochody",
    "vs": "Symbol zmiennej",
    "GenerateUsers": "Wygeneruj użytkownika",
    "ToolTipGenerateUsers": "Wpisz, ilu licencjonowanych użytkowników ma być generowanych automatycznie",
    "ContainsNumberOfBlocked": "Placówka zawiera numery telefonów zablokowanych placówek: {0}. Czy chcesz zapisać nagranie?",
    "okoli": "Okolica",
    "radius": "Okolice [km]",
    "airDistance": "Dystans [km]",
    "notEqualAddress": "Adres firmy i siedziby nie zgadzają się",
    "equalAddress": "Adres firmy i siedziba zgadzają się",
    "historieUzivatelu": "Historia użytkownika",
    "Smlouvy": "Umowy",
    "LastChange": "Ostatnia zmiana"
  },
  "PVI": {
    "RefreshRecord": "Odśwież ({{value}}x pozostało)",
    "Refresh": "Odśwież",
    "UpdateRecord": "Zapisz zmiany ({{value}}x pozostało)",
    "Inserted": "Wstawiony",
    "Modified": "Zmiana",
    "Creator": "Użytkownik",
    "CreatedOn": "Data utworzenia",
    "ModifiedOn": "Data zmiany",
    "ListEmpty": "Lista jest pusta",
    "Marked": "Wyraźny",
    "InvalDuv": "Przyczyna unieważnienia",
    "Valid": "Ważny",
    "codes": {
      "P": "P",
      "V": "V",
      "I": "I",
      "All": "PVI",
      "S": "S",
      "T": "T",
      "H": "H",
      "Pr": "P",
      "U": "U",
      "F": "F"
    },
    "tooltip": {
      "P": "Transport",
      "V": "Darmowe samochody",
      "I": "Reklama",
      "ALL": "PVI",
      "S": "Lista pojazdów",
      "T": "Trasy",
      "H": "Zgłaszanie błędów",
      "Pr": "Warsztaty",
      "U": "Użytkownicy",
      "F": "Firmy",
      "UpdateButton": "Zapisz / Odśwież aktualizuje datę sortowania ofert w wyszukiwarce.",
      "UpdateDisabledButton": "Osiągnięto już maksymalną liczbę powtórnych zapisów oferty"
    }
  },
  "RAALKratkyKody": {
    "N": "N",
    "S": "S",
    "J": "I",
    "ADR": "ADR"
  },
  "Sections": {
    "Start": "Wstęp",
    "Task": "Zadanie",
    "Browse": "Przeglądanie",
    "Company": "Lokale użytkowe",
    "Messages": "Nowości RAAL",
    "ArchiveTask": "Archiwum zadań",
    "ArchiveBrowse": "Przeglądanie archiwum",
    "Pass": "Przejścia",
    "Fill": "Ładowanie",
    "CollectionService": "Usługa windykacyjna",
    "Mileage": "Drogomierz",
    "Assign": "Zadanie",
    "View": "Przeglądanie",
    "Master": "Kierownictwo",
    "Administration": "Administracja",
    "Logs": "Logo",
    "Help": "Pomoc",
    "Zpravy": "Aktualności",
    "Informations": "Informacja",
    "Learning": "Szkolenie",
    "Support": "Wsparcie użytkownika",
    "Stiznosti": {
      "Title": "Uskarżanie się",
      "Statistiky": "Statystyka"
    },
    "Admin": {
      "Offers": "Oferuje",
      "History": "Historia",
      "Archiv": "Archiwa"
    },
    "History": "Historia",
    "User": {
      "Kilometrovnik": "Drogomierz"
    },
    "Search": "Szukaj",
    "Soubory": "Akta"
  },
  "ShowHidePhones": {
    "true": "Numery telefoniczne",
    "false": "Ukrywać"
  },
  "ShowHideMap": {
    "true": "Podaj współrzędne",
    "false": "Ukrywać"
  },
  "Stat": {
    "Id": "Id",
    "nazevCz": "Nazwa",
    "countryCode": "Kod",
    "Flag": "Flaga",
    "TooltipKodZeme": "Wpisz kod kraju dla celów OpenStreetMap",
    "predvolba": "Kod"
  },
  "State": {
    "true": "aktywny",
    "false": "nieaktywny"
  },
  "SystemParameter": {
    "Value": "Wartość"
  },
  "Texty": {
    "Kod": "Kod",
    "Hodnota": "Wartość ({0})",
    "TypTextu": "Typ"
  },
  "Theme": {
    "ThemeChange": "Jasny / ciemny motyw",
    "Title": "Temat",
    "Dark": "Ciemny",
    "Light": "Światło"
  },
  "ThunderNews": {
    "Notifications": "Notifikace",
    "MessagesType": "Typ wiadomości",
    "MessagesInstantLabel": "Błyskawica",
    "MessagesInstantUsers": "Ostrzeżenie: Jeśli nie wybierzesz konkretnego Użytkownika / Firmy / Placówki, wiadomość flash zostanie wysłana do WSZYSTKICH użytkowników!",
    "MessagesValidTo": "Ważne do",
    "NoMessages": "Brak wiadomości",
    "AllMessages": "Wszystkie wiadomości",
    "Predmet": "Temat",
    "TypZpravy": "Typ wiadomości",
    "Notification": "Masz nową wiadomość",
    "Ok": "Ukrywać",
    "Open": "otwarty"
  },
  "TimeMeasures": {
    "minutes": {
      "plural": "minuty",
      "singular": "minuta",
      "genitivPlural": "minut"
    },
    "hours": {
      "plural": "godziny",
      "singular": "godzina",
      "genitivPlural": "godziny"
    }
  },
  "User": {
    "Id": "ID użytkownika",
    "Title": "Użytkownik",
    "provozovna": "Sala operacyjna",
    "login": "Nazwa użytkownika",
    "jmeno": "nazwa użytkownika",
    "Password": "Hasło",
    "SignIn": "Zaloguj sie",
    "AddNew": "Dodanie nowego konta",
    "ChangePassword": "Zmień hasło",
    "Switch": "Przełącznik",
    "Remove": "Usunąć",
    "roles": "Rola",
    "IPAdresaPosledni": "Ostatni adres IP",
    "View": "Wykonawca",
    "ViewInfo": "Informacje o wykonawcy",
    "ToolTipGenerateUsers": "Generowanie użytkowników i licencji",
    "KdoZmenil": "Kto się zmienił",
    "NoUserRoleError": "To jest jedna licencja, proszę zatrzymać całe założenie.",
    "lastAction": "Ostatnia akcja"
  },
  "LoginPage": {
    "Info": "1. BAZA ŁADUNKÓW I DOSTĘPNYCH SAMOCHODÓW od 1992 roku",
    "NewPassword": "Nowe hasło",
    "ConfirmPassword": "Potwierdzenie hasła",
    "Captcha": "Kod z obrazka",
    "GenerateNewCaptcha": "Wygenerować nowy obraz",
    "RememberMe": "Zapamiętaj mnie",
    "TroubleLoggingIn": "Problem z logowaniem?",
    "RegularCustomersTitle": "Stali klien",
    "RegularCustomersInfo": "Dane do logowania zostały przesłane do Ciebie e-mailem z adresu <a href=\"mailto:noreply@raaltrans.com\">noreply@raaltrans.com</a>. W razie problemów skontaktuj się z nami <span>(+420 495 217 481 – 2)</span>, a chętnie Ci pomożemy. Alternatywnie możesz również skontaktować się z nami pod adresem <a href=\"mailto:support@raal.cz\">support@raal.cz</a>.",
    "NewCustomersTitle": "Nowi klienci",
    "NewCustomersInfo": "Formularz zamówienia znajdziesz na naszej stronie internetowej <a href=\"https://www.raal.cz/\" target=\"_blank\" rel=\"noopener noreferrer\">www.raal.cz</a>. Skontaktujemy się z Tobą po przetworzeniu zamówienia."
  },
  "InputErrors": {
    "Password": {
      "ConfirmationNotMatch": "Hasła nie pasują do siebie.",
      "TooShort": "Hasło jest za krótkie, minimalna długość wynosi 6 znaków."
    }
  },
  "UserModified": {
    "Title": "Dane użytkownika zostały zmienione",
    "Body": "Aby w pełni zainicjować zmiany na swoim koncie użytkownika, musisz wielokrotnie się logować. Anulować subskrypcję teraz?"
  },
  "Validations": {
    "RychlyKontaktLengthConstraint": "Liczba znaków musi mieścić się w przedziale od 9 do 15"
  },
  "Websocket": {
    "Connected": "Połączony",
    "Disconnected": "Bezładny",
    "Connecting": "łączę się"
  },
  "YesNo": {
    "false": "Nie",
    "true": "Tak"
  },
  "Kilometrovnik": {
    "Psc": "KP",
    "Nazev": "Nazwa",
    "Cc2": "Kod pocztowy",
    "Typ": "Typ",
    "Lat": "Szerokość",
    "Lon": "Długość geograficzna",
    "DisplayRoute": "Trasa i koszty",
    "DisplayRouteOnly": "Tylko trasa",
    "Vozidlo": "Pojazd",
    "Note": "Do polí \"Přejezd odkud\" a \"Přejezd kam\" zadejte místa, která co nejvíce odpovídají Vaší trase. To může být např. místo, kde stojí nevytížené auto a místo, kam s ním chcete dojet, např. sídlo firmy. V polích \"Maximální přejezd odkud/kam/celkem\" lze nepovinně zadat, z jak velkého okolí od zadaných míst se mají nabídky zobrazovat. Necháte-li příslušné okolí nevyplněné, vyberou se nabídky bez ohledu na vzdálenost od zvoleného místa."
  },
  "Contacts": {
    "SendEmail": "Wyślij e-mail",
    "SipEmail": "Kontakt za pomocą protokołu SIP (Teams itp.)",
    "Call": "Dzwonić",
    "SMS": "Wyślij SMS",
    "WhatsApp": "WhatsApp",
    "Viber": "Viber"
  },
  "Klasifikace": {
    "klasifikace": "Klasyfikacja",
    "klasifikaceShort": "[K]",
    "nezobrazovat": "Nie pokazuj ofert",
    "poznamka": "Uwaga użytkownika"
  },
  "ProfilVozidla": {
    "title": "Pojazdy",
    "nazev": "Nazwa",
    "casovyNakladH": "Koszt czasu [cena/h]",
    "casovyNakladHTooltip": "(np. wynagrodzenie kierowcy)",
    "fixniNaklad": "Koszty stałe [cena/km]",
    "fixniNakladTooltip": "(deprecjacja)",
    "spotreba": "Zużycie [l/100 km]",
    "cenaPaliva": "Cena paliwa [cena/l]",
    "tollPrice": "Ceny opłat drogowych",
    "osrmProfile": "Profil nawigacji",
    "displayPreprava": "Pokaż w przesyłkach",
    "displayVozidlo": "Pokaz w wolnych samochodach",
    "defaultPreprava": "Domyślnie w transportach",
    "defaultVozidlo": "Domyślnie w darmowych samochodach",
    "properties": "Cechy pojazdu",
    "maxWeight": "Masa całkowita pojazdu [t]",
    "maxHeight": "Całkowita wysokość pojazdu [m]",
    "maxWidth": "Szerokość całkowita pojazdu [m]",
    "maxLength": "Długość całkowita pojazdu [m]",
    "hazmat": "transport materiałów niebezpiecznych",
    "hazmatWater": "transport materiałów zanieczyszczających wodę",
    "switzerland": "ominięcie Szwajcarii, jeśli w tym kraju nie ma załadunku/rozładunku",
    "delka": "Długość ładunku [m]",
    "vaha": "Masa ładunku [t]"
  },
  "CenaMyta": {
    "title": "Cena za przejazd",
    "kodZeme": "Państwo",
    "sazba": "Stawka [cena/km]",
    "typSilnice": "Typ drogi",
    "CurrencyRequiredWhenPrice": "Należy go wypełnić razem ze stawką"
  },
  "Trasa": {
    "title": "Trasy",
    "Calculate": "Konwertować",
    "CalculateAndSave": "Przelicz i zapisz",
    "nazev": "Nazwa",
    "state": "Stan obliczeń",
    "profilVozidla": "Profil pojazdu",
    "currency": "Waluta kalkulacji kosztów",
    "distance": "Dystans",
    "duration": "Czas podróży",
    "cost": "Koszty",
    "mpz": "Mzr",
    "distancePaid": "Odcinki płatne [km]",
    "distanceUnpaid": "Odcinki nieopłacone [km]",
    "currencyTooltip": "Przybliżona kalkulacja według kursu CNB",
    "modifiedOn": "Data zmiany"
  },
  "KilometrovnikHlaseni": {
    "osrmId": "OSRM Id",
    "report": "Raport",
    "status": "Państwo",
    "uzivatel": "Użytkownik",
    "osrmEdit": "Zgłoś się do OSRM",
    "process": "Wyciągnąć wniosek",
    "reportSent": "wiadomość została wysłana",
    "title": "Zgłoszone błędy",
    "datIns": "Data utworzenia",
    "datUzavreni": "Data zamknięcia"
  },
  "Dokladky": {
    "kilometrovnik": "Ładowanie kilometrów",
    "nakladka": "Ładowanie",
    "vykladka": "Rozładunek",
    "odkud": "Transport - skąd",
    "kam": "Transport - gdzie",
    "vyhodnost": "Wygoda",
    "dokladky": "Ładowanie",
    "zajizdka": "Objazd [km]",
    "datumDokladkyOd": "Data załadunku od",
    "datumDokladkyDo": "Data załadunku do",
    "zadaniDokladky": "Załadunek przesyłek",
    "calculate": "Wyszukaj ładowanie",
    "preprava": "Transport",
    "total": "Razem",
    "original": "Oryginalny",
    "dokladka": "Z ładowaniem",
    "Preprava": "Domyślna wysyłka",
    "datum": "Data"
  },
  "SbernaSluzba": {
    "sbernaSluzba": "Usługa windykacyjna",
    "datumOd": "Wysyłka od",
    "datumDo": "Wysyłka do",
    "calculate": "Oblicz",
    "trasa": "Trasa",
    "vozidlo": "Pojazd",
    "datum": "Data",
    "search": "Zlecenie usługi windykacyjnej",
    "prepravaOrder": "Zamówienie",
    "map": "Zobacz mapę",
    "noTransportsFound": "Nie znaleziono odpowiednich transportów",
    "profilVozidlaAtributy": "Profil pojazdu musi być wypełniony: waluta, zużycie, koszty stałe",
    "parametryVypoctu": "Parametry obliczeniowe",
    "maxPrejezd": "Maksymalny przejazd od miejsca załadunku [km]",
    "maxZajizdka": "Maksymalizacja objazdu na trasie [km]",
    "nalezeniPreprav": "Realizacja usługi windykacyjnej",
    "neobsahujePrepravy": "Dodawać przesyłki automatycznie?",
    "doplnitPrepravy": "Automatycznie dodawaj przesyłki",
    "pridatPrepravu": "Dodaj wysyłkę ręcznie",
    "pridaniPrepravy": "Dodawaj przesyłki automatycznie",
    "calculateToll": "Oblicz koszt",
    "maxDelka": "Maksymalne długości ładunków częściowych [m]",
    "maxVaha": "Maksymalne masy ładunków częściowych [t]",
    "PrepravaRemoveConfirm": "Czy na pewno chcesz usunąć menu główne? Potwierdzenie tej czynności spowoduje usunięcie wszystkich ofert.",
    "noveZadani": "Nowy przydział",
    "noveZadaniBody": "Zlecenie zawiera transporty, czy chcesz usunąć transporty i zacząć od nowa?",
    "processing": "Nie można wykonać akcji, ponieważ trwa obliczanie."
  },
  "ServerErrors": {
    "404": "Informacje nie zostały znalezione na serwerze",
    "500": "Wewnętrzny błąd serwera",
    "FailedToFetch": "Nie udało się załadować danych, sprawdź połączenie internetowe.",
    "UnableToGeocode": "Geokodowanie współrzędnych nie powiodło się",
    "NetworkError": "Podczas przetwarzania żądania wystąpił błąd sieci, sprawdź połączenie internetowe."
  },
  "SeznamMaps": {
    "Title": "Mapa Seznam.cz"
  },
  "SearchEnum": {
    "entityType": "Typ rekordu",
    "Preprava": {
      "PREPRAVA": "N",
      "PREPRAVA_HISTORIE": "N (H)",
      "PREPRAVA_ARCHIV": "A",
      "PREPRAVA_HISTORIE_ARCHIV": "A (H)"
    },
    "VolneVozy": {
      "VOZIDLO": "N",
      "VOZIDLO_HISTORIE": "N (H)",
      "VOZIDLO_ARCHIV": "A",
      "VOZIDLO_HISTORIE_ARCHIV": "A (H)"
    },
    "Inzerat": {
      "INZERAT": "N",
      "INZERAT_HISTORIE": "N (H)",
      "INZERAT_ARCHIV": "A",
      "INZERAT_HISTORIE_ARCHIV": "A (H)"
    }
  },
  "TrikratADost": {
    "Title": "Statistika \"3x i wystarczy\" do tej pory {0}",
    "celkemPripadu": "Łącznie {0} przypadków",
    "celkemCastky": "Suma kwot: {0}",
    "NadpripadyTotal": "Super przypadki: {0} otwarte i {1} zamknięte, w tym {2} wyciągi zamknięte",
    "Stav": "Stav",
    "Castka": "Kwota",
    "CurrencyCode": "Waluta",
    "PocetPripad": "Liczba przypadków"
  },
  "DOMException": {
    "AbortError": "Ładowanie danych zostało przerwane (lub serwer nie odpowiedział w wymaganym czasie)"
  },
  "Diag": {
    "host": "Gość",
    "serverPort": "Port",
    "healthy": "Państwo",
    "instances": "Instancja",
    "dbReplicas": "Repliki baz danych",
    "couldNotLoad": "Nie można załadować danych",
    "replicationNotActive": "Baza danych nie jest replikowana",
    "resources": "Zasoby",
    "requiredDbConnectionsRead": "Wymagane połączenia DB do odczytu",
    "requiredDbConnectionsWrite": "Wymagane połączenia DB do zapisu",
    "availableDbConnectionsRead": "Dostępne połączenia DB do odczytu (1 db)",
    "availableDbConnectionsWrite": "Dostępne połączenia DB do zapisu",
    "availableDbConnections": "Dostępne połączenia DB",
    "dbConnectionsNotEnough": "Liczba połączeń z bazą danych może nie być wystarczająca do pokrycia zapotrzebowania serwerów",
    "jobs": "Oferty pracy",
    "rateLimit": "Informacje o limicie stawki"
  },
  "ArchiveRecord": {
    "typ": "Typ",
    "poznamka": "Notatka",
    "owner": "Zarchiwizowane"
  },
  "Archive": {
    "Archive": "Archiwa",
    "Archived": "Zarchiwizowane"
  },
  "Poznamka": {
    "poznamka": "Notatka",
    "uzivatel": "Ostatni autor",
    "datIns": "Data utworzenia"
  },
  "CommonGrid": {
    "History": "Historia"
  },
  "DluhNepohOsoba": {
    "Prijmeni": "Nazwisko",
    "Jmeno": "Nazwa",
    "Rodcis": "Osobisty numer identyfikacyjny",
    "Adresa": "Adres",
    "Ica": "NI",
    "Poznamka": "Notatka",
    "DatCas": "Data",
    "Uzivatel": "Zmienił się",
    "Provozovny": "Lokale użytkowe"
  },
  "EmailForm": {
    "Sender": "Nadawca",
    "Subject": "Temat emaila",
    "Receiver": "Wyślij e-mail na adres",
    "Body": "Treść wiadomości e-mail"
  },
  "EmailStiznostiForm": {
    "smlouva": "Data zawarcia umowy",
    "pozastaveno": "Zawieś dostęp do dnia"
  },
  "WithUserBase": {
    "Id": "Numer",
    "DatCas": "Czas ostatniej zmiany",
    "Uzivatel": "Zmienił ostatnią",
    "Zmena": "Ostatnia zmiana",
    "Generovat": "Wygeneruj i prześlij",
    "ChooseLanguage": "Wybierz język"
  },
  "DluhPripad": {
    "Dluznik": "Dłużnik",
    "Veritel": "Wierzyciel",
    "ZodpOsoba": "Odpowiedzialna osoba",
    "IcoDluznika": "NI pożyczkobiorcy",
    "DicDluznika": "NIP pożyczkobiorcy",
    "RegNazevDluznika": "Nazwa pożyczkobiorcy",
    "RegUliceDluz": "Kredytobiorcy ul",
    "RegSidloDluz": "Siedziba dłużnika",
    "RegPscDluz": "KP dłużnika",
    "RegStatDluz": "Państwo dłużnika",
    "PrefStatDluz": "Kod dłużnika",
    "PrefMestoDluz": "Kod miasta",
    "FaxDluz": "Faks pożyczkobiorcy",
    "TelDluz": "Numer telefonu pożyczkobiorcy",
    "MobilDluz": "Telefon komórkowy kredytobiorcy",
    "HttpDluz": "WWW dłużnika",
    "EmailDluz": "Email dłużnika",
    "IcqDluz": "Icq dłużnika",
    "SkypeIdDluz": "Skype dłużnika",
    "ProvozovnaDluznika": "Sala operacyjna dłużnika",
    "ProvozovnaDluznikaStav": "Stan zakładu dłużnika",
    "zodpMailDluz": "Odpowiedzialna osoba dłużnika",
    "IcoVer": "NI wierzyciele",
    "DicVer": "NIP wierzyciele",
    "RegNazevVer": "Název wierzyciele",
    "RegUliceVer": "Ulica wierzyciele",
    "RegSidloVer": "Rezydencja wierzyciele",
    "RegPscVer": "KP wierzyciele",
    "RegStatVer": "Państwo wierzyciele",
    "PrefStatVer": "Kod wierzyciele",
    "ProvozovnaVer": "Sala operacyjna wierzyciele",
    "ProvozovnaVerStav": "Stan zakładu wierzyciele",
    "zodpMailVer": "Odpowiedzialna osoba vwierzyciele",
    "PrefMestoVer": "Kod miasta wierzyciele",
    "FaxVer": "Fax wierzyciele",
    "TelVer": "Telefon wierzyciele",
    "MobilVer": "Mobil wierzyciele",
    "HttpVer": "WWW wierzyciele",
    "EmailVer": "Email wierzyciele",
    "IcqVer": "Icq wierzyciele",
    "SkypeIdVer": "Skype wierzyciele",
    "KdoZaloz": "Kto założył",
    "DatCas": "Data",
    "Stav": "Stav",
    "ZodpTel": "Numer telefonu osoby odpowiedzialnej",
    "ZodpEmail": "Email osoby odpowiedzialnej",
    "Kontakty": "Łączność",
    "ProvozovnyFirmy": "Siedziba firmy macierzystej",
    "AktKeDni": "Aktualizuj do chwili obecnej",
    "AktKeDniButton": "Teraz",
    "GenerovatDopisVer": "Wygeneruj i wyślij list do wierzyciela",
    "GenerovatDopisDluznik": "Wygeneruj i wyślij pismo do pożyczkobiorcy",
    "StavProvozovnyDluznika": "Status zakładu dłużnika",
    "StavProvozovnyVer": "Status siedziby wierzyciela",
    "StavAction": {
      "otevrit": "otwarty",
      "uzavrit": "Wyciągnąć wniosek",
      "vyresitBezUhrady": "Rozwiąż bez zapłaty",
      "promlcet": "Przedawnienie",
      "zamitnout": "Odrzucić",
      "Confirmation": "Status został pomyślnie zmieniony"
    },
    "PripadExist": "UWAGA! W systemie są już aktywne sprawy z tym samym dłużnikiem i wierzycielem: {caseIds}! Czy chcesz kontynuować?",
    "FaktExist": "UWAGA! Faktura o tym numerze już istnieje w systemie w sprawach: {caseIds}! Czy chcesz kontynuować?",
    "PripadExistTitle": "Potwierdzenie",
    "FaktExistTitle": "Potwierdzenie",
    "Active": "Aktywny"
  },
  "DluhNadpripad": {
    "NadpripadId": "Przykładem",
    "Stav": "Stav",
    "KdyPozastavit": "Kiedy zrobić pauzę",
    "Vypoved": "Zakończenie",
    "Bod39": "Punkt 3.9",
    "Upozorneni": "Ogłoszenie",
    "Confirmation": "Potwierdzenie",
    "GenerovatDopisDluznik": "Wygeneruj i wyślij pismo do pożyczkobiorcy",
    "StavAction": {
      "otevrit": "otwarty",
      "uzavritSUhradou": "Zamknij (z płatnością)",
      "uzavritBezUhrady": "Zamknij (bez płatności)",
      "uzavritVypoved": "zamknąć (zakończenie)",
      "uzavrit39": "Zamknij (punkt 3.9)",
      "New": "Czy na pewno chcesz utworzyć sprawę główną?",
      "CreateConfirmation": "Sprawa główna została pomyślnie utworzona"
    },
    "Buttons": {
      "New": "Utwórz sprawę wzorcową",
      "Navigate": "Zobacz studium przypadku",
      "NavigateToPripad": "Wróćmy do sprawy"
    }
  },
  "DluhFakt": {
    "Title": "Faktury",
    "Cislo": "Numer faktury",
    "Castka": "Kwota",
    "DatSplat": "Termin",
    "KdyUhradil": "Kiedy zapłacił?",
    "KdoUhradil": "Kto zapłacił",
    "KdyBezUhrady": "Kiedy bez zapłaty",
    "KdoBezUhrady": "Kto bez zapłaty",
    "Stav": "Stav",
    "UdajeFaktury": "Dane na fakturze",
    "OhlasUhr": "Zawiadomienie o zapłacie przez wierzyciela",
    "DuvodZamitnuti": "Powód odmowy",
    "Poznamka": "Notatka",
    "Poznamky": "Komentarz",
    "StavAction": {
      "uhradit": "Opędzać wydatek",
      "vyresitBezUhrady": "Rozwiąż bez zapłaty",
      "promlcet": "Przedawnienie",
      "zamitnout": "Odrzucić",
      "otevrit": "otwarty"
    }
  },
  "DluhSoub": {
    "Title": "Akta",
    "Soubor": "Plik",
    "NovySoubor": "Nowy plik",
    "Popis": "Opis",
    "Stazeni": "Pobieranie pliku"
  },
  "DluhStateActionConfirmDialog": {
    "Title": "Potwierdzenie",
    "Body": "Czy na pewno chcesz wykonać {{action}}?"
  },
  "ReportText": {
    "TextKey": "Klucz",
    "Translation": "Tłumaczenie"
  },
  "Report": {
    "Language": "Język"
  },
  "popis": "Opis",
  "NetworkError": "Błąd sieci",
  "UnexpectedError": "Wystąpił nieoczekiwany błąd podczas przetwarzania żądania",
  "Error401": "Wyrejestrowany",
  "Error401FingerPrint": "Zostałeś wylogowany, ponieważ zalogowałeś się przy użyciu innej przeglądarki.",
  "SignedOut": "Wyloguj",
  "BadCredentials": "Złe poświadczenia",
  "RecordCannotBeSaved": "Nie można zapisać nagrania",
  "ModificationConcurrency": "Element został zmodyfikowany przez innego użytkownika",
  "LoginFailed": "Logowanie nie powiodło się",
  "LoginFailedGeneratedPwd": "Logowanie nie powiodło się. Sprawdź, czy wprowadziłeś prawidłowe hasło, korzystając z przycisku oka obok hasła.",
  "PasswordChangeRequired": "Wymagana zmiana hasła",
  "TryConnectAgain": "Spróbuj połączyć się ponownie",
  "ConnectionError": "Błąd połączenia. Albo serwery są wyłączone, albo nie masz dostępu do internetu.",
  "Unique": "Rekord jest zduplikowany",
  "ipDll": "Ostatni IP",
  "DataFetchError": "Wystąpił błąd podczas ładowania danych",
  "WebsocketConnecting": "Nawiązuję połączenie",
  "Attempt": "Próbować",
  "ArchiveRecordShort": "Archiwum",
  "RestoreRecordShort": "Przywrócić",
  "ArchiveSuccess": "Archiwizacja przebiegła pomyślnie",
  "AppActivityTypeName": "Typ",
  "AppActivitySubTypeName": "Podtyp",
  "ActivityViewType": "Zobacz metodę",
  "ArchiveView": "Archiwizacja przeglądanej oferty",
  "Actions": "Działanie",
  "Errors": {
    "CouldNotFetchCode": "Wystąpił błąd podczas ładowania kodu bezpłatnego zakładu.",
    "CouldNotFetchCount": "Wystąpił błąd podczas pobierania liczby rekordów.",
    "CouldNotCancelEdit": "Nie można cofnąć edycji",
    "CouldNotEdit": "Nie można edytować rekordu",
    "CouldNotDelete": "Nie można usunąć rekordu",
    "CouldNotDeleteIsLocked": "Nie można usunąć rekordu, rekord jest zablokowany",
    "CouldNotArchiveIsLocked": "Nie można zarchiwizować nagrania, nagranie jest zablokowane",
    "LockLost": "Edycja została anulowana, ponieważ nagranie zostało odblokowane",
    "SystemOverload": "Strona nie może zostać wyświetlona, ​​ponieważ system jest zajęty obsługą innych żądań, spróbuj wyświetlić stronę później."
  },
  "Unable to geocode": "Nie można określić adresu lokalizacji",
  "Consent": {
    "BasicInfo": "Podstawowe informacje",
    "Agreements": "Zatwierdzanie dokumentacji prawnej",
    "MinimalRequirements": "Minimalne wymagania systemowe",
    "VPAgreement": "Zgadzam się na ogólne warunki",
    "LPAgreement": "Zgadzam się z warunkami licencji",
    "GDPRAgreement": "Zgadzam się z polityką prywatności",
    "PDIAgreement": "Wyrażam zgodę na umowę o świadczenie informacji o ruchu drogowym",
    "Download": "Pobierać",
    "Open": "Otwarty"
  },
  "PravniDokumentace": {
    "TypSmlouvy": "Typ umowy",
    "Soubor": "Umowa",
    "DatumPodepsaniRall": "Data podpisania przez RAALTRANS",
    "DatumPodepsaniPrijemce": "Data potwierdzenia przez Odbiorcę",
    "DatumVytvoreni": "Data utworzenia",
    "Sign": "Podpisz umowy",
    "SignConfirm": "Czy na pewno chcesz podpisać wszystkie umowy?",
    "AlreadySigned": "Umowy zostały już podpisane",
    "SignFailed": "Podpisanie umów nie powiodło się",
    "SignSuccess": "Umowy zostały podpisane",
    "Delete": "Usuń umowy",
    "DeleteConfirm": "Czy na pewno chcesz usunąć wszystkie umowy?",
    "AlreadyDeleted": "Umowy zostały już usunięte",
    "DeleteFailed": "Nie udało się usunąć umów",
    "DeleteSuccess": "Umowy zostały usunięte"
  },
  "StaticLinks": {
    "Thumbnail": {
      "MasterTutorial": "/videos/thumbnail/master_tutorial_pl.jpg"
    }
  },
  "Cms": {
    "Oznameni": {
      "TitleAfterLogin": "Nowości od ostatniego logowania",
      "TitleStomp": "Ważna uwaga",
      "ReloadPage": "ODŚWIEŻ STRONĘ",
      "ReloadInfo": "Aplikacja została właśnie zaktualizowana do nowej wersji - strona wymaga odświeżenia do poprawnego działania.",
      "ReloadDisclaimer": "W przeciwnym razie niektóre funkcje mogą nie działać poprawnie."
    }
  },
  "Oznaceni": {
    "DeleteViewRecords": "Wyczyść historię przeglądania",
    "ViewRecordsClearConfirm": "Czy na pewno chcesz wyczyścić historię przeglądania?"
  },
  "NabidkaMultiCRUD": {
    "RefreshTooltip": "Odśwież rekordy",
    "DeleteTooltip": "Usuń rekordy",
    "RefreshConfirm": "Czy na pewno chcesz odświeżyć wszystkie zaznaczone rekordy?",
    "DeleteConfirm": "Czy na pewno chcesz usunąć wszystkie zaznaczone rekordy?",
    "RefreshSuccess": "Oferty zostały odświeżone",
    "DeleteSuccess": "Oferty zostały usunięte",
    "UnableToRefresh": "Niektórych ofert nie udało się odświeżyć",
    "UnableToDelete": "Niektórych ofert nie udało się usunąć",
    "AlreadyDeletedRecords": "Już nieistniejące: {0}x",
    "LockedRecords": "Zablokowane: {0}x",
    "MaxEditsReachedRecords": "Osiągnięto maksymalną liczbę odświeżeń: {0}x",
    "ExpiredOfferRecords": "Nieprawidłowe oferty: {0}x",
    "InvalidOffersDateChangeInfo": "Wybrane oferty zawierają datę, która już minęła. Aby umożliwić odnowienie, należy ustawić nową datę:",
    "ValidOffersDateChangeInfo": "Uwaga: wybór zawiera także ważne oferty - dla nich zmieniona zostanie tylko data do.",
    "DateFrom": "Data od",
    "DateTo": "Data do"
  },
  "VlastniMisto": {
    "titulek": "Dodanie nowej lokalizacji",
    "Pridat": "Dodaj nowe miejsce",
    "mpz": "MZR",
    "psc": "Kod pocztowy",
    "mesto": "Miasto",
    "notFound": "Lokalizacja nie została znaleziona. Można jej użyć, ale odległość w kilometrach nie zostanie obliczona."
  },
  "Mail": {
    "KodZadavateleNabidky": "KOD licytanta",
    "PrepravaSubject": "Reakcja na ofertę transportową w RAALTRANS ({0})",
    "VozidloSubject": "Reakcja na ofertę bezpłatnego samochodu w RAALTRANS ({0})",
    "InzeratSubject": "Reakcja na ofertę reklamową w RAALTRANS ({0})",
    "PrepravaBodyBegin": "Odpowiadamy na Twoją ofertę transportu opublikowaną w bazie RAALTRANS (nasz kod w RAALTRANS to {0}):",
    "VozidloBodyBegin": "Odpowiadamy na Twoją ofertę darmowego samochodu opublikowaną w bazie RAALTRANS (nasz kod w RAALTRANS to {0}):",
    "InzeratBodyBegin": "Odpowiadamy na Twoje ogłoszenie zamieszczone w bazie RAALTRANS (nasz kod w RAALTRANS to {0}):"
  },
  "DataTemplate": {
    "Save": "Zapisz szablon",
    "Name": "Nazwa szablonu",
    "Add": "Dodaj szablon",
    "Select": "Wybierz szablon, aby utworzyć menu",
    "SavedTemplates": "Zapisane szablony",
    "NoData": "Nie znaleziono szablonu",
    "DeleteConfirmationText": "Czy na pewno chcesz usunąć szablon?",
    "RemoveTemplate": "Usuń",
    "Saved": "Szablon został zapisany",
    "SaveFailed": "Nie można zapisać szablonu, nazwa jest już przypisana do innego szablonu lub nazwa jest za długa",
    "Edited": "Szablon został edytowany",
    "EditFailed": "Nie udało się edytować szablonu",
    "Removed": "Szablon został usunięty",
    "RemoveFailed": "Nie udało się usunąć szablonu"
  },
  "Here": {
    "Dial": {
      "TitulekTabulky": "Pojazdy",
      "Nazev": "Nazwa",
      "Mena": "Waluta",
      "Vyska": "Wysokość [m]",
      "Sirka": "Szerokość [m]",
      "Delka": "Długość [m]",
      "CelkovaVaha": "Całkowita waga [t]"
    },
    "TrasaDetail": {
      "CelkovaCenaZaSekci": "Całkowita cena za sekcję",
      "PoplatkyV": "Opłaty w",
      "Routing": "TRASOWANIE",
      "Sekce": "Sekcja",
      "OdhadovanaDobaJizdy": "Szacowany czas jazdy",
      "Poplatky": "MYTO",
      "PoplatkyCelkem": "Łączne opłaty",
      "DetailPoplatku": "Kliknij tutaj, aby rozwinąć/zwinąć szczegóły opłat",
      "Table": {
        "IndexMyta": "Indeks opłat drogowych",
        "IndexPoplatku": "Indeks opłaty",
        "LokalniCena": "Cena lokalna",
        "KovertovanaCena": "Cena przeliczona",
        "Zeme": "Kraj",
        "MytnySystem": "System opłat drogowych",
        "PlatebniMetody": "Metody płatności",
        "PlatebniMetoda": {
          "cash": "Gotówka",
          "bankCard": "Karta bankowa",
          "creditCard": "Karta kredytowa",
          "transponder": "Transponder",
          "travelCard": "Karta podróżna",
          "passSubscription": "Subskrypcja przejazdu",
          "videoToll": "Opłata wideo"
        },
        "Predplatne": {
          "days": "Dzienna",
          "months": "Miesięczna",
          "annual": "Roczna"
        }
      },
      "HereError": "Profil pojazdu prawdopodobnie zawiera niedozwolone kombinacje.",
      "HereErrorNoRoutes": "Nie znaleziono trasy zgodnej z lokalizacją i profilem pojazdu. Może to być spowodowane na przykład ograniczonym krajem i trasą rozpoczynającą się lub kończącą w tym kraju.",
      "Upozorneni": "Uwaga",
      "NotTruckTransportNotification": "Uwaga: na trasie niektóre środki transportu są inne (prom/pociąg) niż na samej osi. Opłaty mogą nie być kompletne.",
      "NotTollsTransportNotification": "Należy pamiętać, że w tej sekcji nie wymieniono żadnych opłat. Nie oznacza to, że ich nie ma. Możliwe, że system nie był w stanie ich wykryć.",
      "ZobrazitMapu": "Kliknij tutaj, aby rozwinąć/zwinąć mapę",
      "KliknutimVybertePrujedniBod": "Kliknij mapę, aby wybrać punkt trasy.",
      "VyberZMapy": "Wybór z mapy"
    },
    "Kilometrovnik": {
      "Button": {
        "Trasa": "TRASA",
        "Naklady": "OPŁATY",
        "TrasaANaklady": "TRASA I OPŁATY"
      },
      "ProfilVozidla": "Profil pojazdu",
      "ViaInput": "Punkt przejścia",
      "AddViaToList": "Dodaj do listy"
    },
    "Nazev": "Nazwa profilu pojazdu",
    "Currency": {
      "Title": "Koszty zwrotu w walucie",
      "TitleToolTip": "Koszty obliczonej trasy zostaną zwrócone w tej walucie",
      "Shortcut": {
        "EUR": "Euro",
        "GBP": "Funt brytyjski",
        "CHF": "Frank szwajcarski",
        "NOK": "Korona norweska",
        "SEK": "Korona szwedzka",
        "DKK": "Korona duńska",
        "ISK": "Korona islandzka",
        "CZK": "Korona czeska",
        "PLN": "Złoty polski",
        "HUF": "Forint węgierski",
        "RON": "Lej rumuński",
        "BGN": "Lew bułgarski",
        "HRK": "Kuna chorwacka",
        "RSD": "Dinar serbski",
        "TRY": "Lira turecka",
        "BAM": "Marka zamienna Bośni i Hercegowiny",
        "MKD": "Denar macedoński",
        "BYN": "Rubel białoruski",
        "MDL": "Lej mołdawski",
        "UAH": "Hrywna ukraińska",
        "RUB": "Rubel rosyjski",
        "GEL": "Lari gruzińskie",
        "AZN": "Manat azerski",
        "AMD": "Dram armeński",
        "ALL": "Lek albański"
      }
    },
    "Vehicle": {
      "Section": "Podstawowe informacje o pojeździe",
      "PayloadCapacity": "Dopuszczalna ładowność wraz z przyczepami [t]",
      "Type": "Typ pojazdu ciężarowego",
      "TypeEnum": {
        "straightTruck": "Prosty ciężarówka",
        "tractor": "Ciagnik"
      },
      "TunnelCategory": "Kategoria tunelu",
      "TunnelCategoryToolTip": "Kategoria tunelu służąca do ograniczenia transportu określonego towaru",
      "TrailerAxleCount": "Całkowita liczba osi (wszystkie przyczepy)",
      "TrailerAxleCountToolTip": "Całkowita liczba osi we wszystkich przyczepach dołączonych do pojazdu",
      "AxleCount": "Całkowita liczba osi w pojeździe",
      "TrailerCount": "Liczba przyczep",
      "WeightPerAxleGroup": "Masa na grupę osi [t]",
      "WeightPerAxle": "Masa na oś [t]",
      "GrossWeight": "Całkowita masa [t]",
      "Length": "Długość [m]",
      "Width": "Szerokość [m]",
      "Height": "Wysokość [m]"
    },
    "TollEmission": {
      "Section": "Parametry do obliczania opłat drogowych na podstawie emisji",
      "TollEmissionType": "Typ emisji",
      "TollEmissionTypeCO2EmissionClass": "Klasa emisji CO2",
      "Type": {
        "euro1": "EURO1",
        "euro2": "EURO2",
        "euro3": "EURO3",
        "euro4": "EURO4",
        "euro5": "EURO5",
        "euro6": "EURO6",
        "euroEev": "EUROEEV"
      }
    },
    "HazardousGoods": {
      "Section": "Lista niebezpiecznych towarów przewożonych w pojeździe",
      "HazardousGood": {
        "explosive": "Wybuchowy",
        "gas": "Gaz",
        "flammable": "Łatwopalny",
        "combustible": "Palny",
        "organic": "Organiczny",
        "poison": "Trujący",
        "radioactive": "Radioaktywny",
        "corrosive": "Żrący",
        "poisonousInhalation": "Trujący przy wdychaniu",
        "harmfulToWater": "Szkodliwy dla wody",
        "other": "Inne"
      }
    },
    "AvoidFeatures": {
      "Section": "Unikanie określonych dróg",
      "Feature": {
        "tunnel": "Tunel",
        "ferry": "Prom"
      }
    },
    "ExcludeCountry": {
      "Section": "Unikanie następujących krajów",
      "Country": {
        "ALB": "Albania",
        "AND": "Andora",
        "ARM": "Armenia",
        "AUT": "Austria",
        "BLR": "Białoruś",
        "BEL": "Belgia",
        "BIH": "Bośnia i Hercegowina",
        "BGR": "Bułgaria",
        "HRV": "Chorwacja",
        "CYP": "Cypr",
        "CZE": "Czechy",
        "DNK": "Dania",
        "EST": "Estonia",
        "FIN": "Finlandia",
        "FRA": "Francja",
        "GEO": "Gruzja",
        "DEU": "Niemcy",
        "GRC": "Grecja",
        "HUN": "Węgry",
        "ISL": "Islandia",
        "IRL": "Irlandia",
        "ITA": "Włochy",
        "XKX": "Kosowo",
        "LVA": "Łotwa",
        "LIE": "Liechtenstein",
        "LTU": "Litwa",
        "LUX": "Luksemburg",
        "MLT": "Malta",
        "MDA": "Mołdawia",
        "MCO": "Monako",
        "MNE": "Czarnogóra",
        "NLD": "Holandia",
        "MKD": "Macedonia Północna",
        "NOR": "Norwegia",
        "POL": "Polska",
        "PRT": "Portugalia",
        "ROU": "Rumunia",
        "RUS": "Rosja",
        "SMR": "San Marino",
        "SRB": "Serbia",
        "SVK": "Słowacja",
        "SVN": "Słowenia",
        "ESP": "Hiszpania",
        "SWE": "Szwecja",
        "CHE": "Szwajcaria",
        "TUR": "Turcja",
        "UKR": "Ukraina",
        "GBR": "Wielka Brytania",
        "VAT": "Watykan"
      }
    },
    "DefaultPVSettings": {
      "Section": "Ustawienia w transporcie i wolnych pojazdach",
      "Preprava": "Domyślnie w transporcie",
      "Vozidlo": "Domyślnie w wolnych pojazdach"
    },
    "CustomNaklady": {
      "NaHodinu": "Koszt za godzinę",
      "NakladNaKm": "Koszt za km",
      "CelkemZaHodin": "Koszt trasy (odległość * stawka godzinowa)",
      "CelkemZaKm": "Koszt trasy (odległość * cena za km)",
      "Celkem": "Całkowite koszty trasy",
      "Titulek": "NIESTANDARDOWE KOSZTY"
    }
  }
};
export default pl;