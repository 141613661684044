import React, { useEffect } from 'react';
import { MapContainer, Polyline, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import H from '@here/maps-api-for-javascript';
import { RouteResponse, Section } from './HereResponse';

export class HereMapComponentProps {
	routing?: RouteResponse;
	tolls?: RouteResponse;
}

export function HereMapComponent(props: HereMapComponentProps) {

	const getPolylines = (): number[][] => {
		let sections: Section[] = null;
		if (props.routing?.routes && props.routing.routes[0]) {
			sections = props.routing.routes[0].sections;
		}
		if (props.tolls?.routes && props.tolls.routes[0]) {
			sections = props.tolls.routes[0].sections;
		}
		let result: number[][] = [];
		sections?.forEach((section) => {
			// const sectionLatLngAltArray =  H.geo.LineString.fromFlexiblePolyline(section.polyline).getLatLngAltArray();
			// @ts-ignore
			const sectionLatLngAltArray = convertArrayToCoordinates(H.geo.LineString.fromFlexiblePolyline(section.polyline).T);
			result = [...result, ...sectionLatLngAltArray];
		});
		return result;
	}

	const convertArrayToCoordinates = (inputArray: number[]) => {
		let result = [];
		for (let i = 0; i < inputArray.length; i += 3) {
			if (inputArray[i + 2] === 0) {  // Check if the third item is 0, which it should be according to your pattern
				result.push([inputArray[i], inputArray[i + 1]]);
			}
		}
		return result;
	}

	return (
		<MapComponentLeaflet polyline={getPolylines()}/>
	);

}

interface MapComponentLeafletProps {
	polyline: number[][];
}

export function MapComponentLeaflet(props: MapComponentLeafletProps) {
	return (
		<MapContainer center={[0, 0]} zoom={13} style={{ height: '100vh', width: '100%' }}>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
			/>
			{/*// @ts-ignore*/}
			<Polyline pathOptions={{ color: 'red' }} positions={props.polyline} />
			<SetupMap polyline={props.polyline} />
		</MapContainer>
	);
}

export function SetupMap(props: MapComponentLeafletProps) {
	const map = useMap();

	useEffect(() => {
		if (props.polyline && props.polyline.length > 0) {
			// @ts-ignore
			const bounds = L.latLngBounds(props.polyline);
			map.fitBounds(bounds);
		}
	}, [map, props.polyline]);

	return (<></>);
}