import React, {PropsWithChildren, ReactElement, useContext, useReducer} from "react";
import {MuiModal} from "./MuiModal";
import {useHistory} from "react-router";
import {useDidMount} from "./hooks/SharedHooks";

export enum ModalActionType {
    Show, Hide
}
export type ActionData = {
    title?:(()=>ValidModalRenderType)|ValidModalRenderType
    body?:(()=>ValidModalRenderType)|ValidModalRenderType,
    delay?:number,
    onClose?: () => void
}

type ValidModalRenderType = string|ReactElement
type State = {
    show:boolean,
    title?:ValidModalRenderType,
    body?:ValidModalRenderType,
    onClose?: () => void
}

type ReducerAction = {type:ModalActionType}&ActionData
type DispatchAction = (action:ReducerAction) => void
interface ContextProps {
    state: State;
    dispatch: DispatchAction;
}
export const Context = React.createContext({} as ContextProps);
function reducer(state:State, action:ReducerAction): State {
    switch (action.type) {
        case ModalActionType.Hide: {
            return {show:false};
        }
        case ModalActionType.Show: {
            let title = typeof action.title === 'function' ? action.title() : action.title as ValidModalRenderType;
            let body = typeof action.body === 'function' ? action.body() : action.body as ValidModalRenderType;
            return {show:true, title:title, body:body, onClose: action.onClose};
        }
    }
    return state;
}

export const globalContext:{dispatch?:DispatchAction, state?: State} = {};

export function dispatchModal(action:ReducerAction) {
    if(globalContext.dispatch) {
        setTimeout(()=>globalContext.dispatch(action), action.delay);
    }
}

function ModalComponent() {
    const {state, dispatch} = useContext(Context);
    useDidMount(()=>{
        //trochu hack abych nemusel mit celou aplikaci v modal provideru (reloaduje se pak cely ten kontext aplikace, kdyz se otevre tento globalni modal), nevim momentalne jestli je nejaky standardni postup
        globalContext.dispatch = dispatch;
        globalContext.state = state;
    });
    //prozatim je to toggle style aby tento dialog byl dycky on top protoze jinak neni on top (zIndex)
    return (
        state.show&&<MuiModal open={state.show} onClose={() => {
                dispatch({type:ModalActionType.Hide});
                state.onClose &&  state.onClose();
            }
        } title={state.title}>
            {state.body}
        </MuiModal>
    );
}

export type ModalLocationState = {
    skipHide:boolean
}

export function ModalContainer(_:PropsWithChildren<{}>) {
    const [state, dispatch] = useReducer(reducer, {show:false});
    const history = useHistory<ModalLocationState>();
    useDidMount(()=>{
        history.listen(location => {
            if(!location.state?.skipHide) {
                dispatch({type:ModalActionType.Hide});
            }
        })
    });
    return (
        <Context.Provider value={{ state, dispatch }}>
            <ModalComponent />
        </Context.Provider>
    );
}