import * as React from "react";
import {FormFieldInterface, FormFieldInterfaceProps} from "./FormFieldInterface";
import {Tooltip} from "@material-ui/core";

export interface FormColorPickerProps extends FormFieldInterfaceProps<any> {
    getColor:() => string
}

export class FormColorPicker extends React.Component<FormColorPickerProps> implements FormFieldInterface {
    render() {
        return (
            <Tooltip title={this.props.dataTip||""} disableHoverListener={!this.props.dataTip} disableFocusListener={!this.props.dataTip} disableTouchListener={!this.props.dataTip}>
                <div>
                    <div style={{width: "40px", height: "40px", backgroundColor: this.props.getColor()}}/>
                </div>
            </Tooltip>
        );
    }

}