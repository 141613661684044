import React from "react";
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {DashboardTile} from "./DashboardTile";

export function DashboardAdmin() {
    const {t} = useTranslation();
    return (
        <>
            <Grid style={{padding:20}}>
                <Grid container spacing={2}>
                    <DashboardTile text={t("Sections.Admin.History")} icon={faIcon.faHistory} buttons={[
                        /*{
                            href: "admin/historie/prepravy",
                            text: 'P'
                        }, {
                            href: "admin/historie/volnevozy",
                            text: 'V'
                        }, {
                            href: "admin/historie/inzerce",
                            text: 'I'
                        },*/ {
                            href: "admin/historie/uzivatele",
                            text: 'U'
                        }, {
                            href: "admin/historie/provozovny",
                            text: 'Pr'
                        }, {
                            href: "admin/historie/firmy",
                            text: 'F'
                        }
                    ]}/>
                    <DashboardTile text={t("Sections.Admin.Archiv")} icon={faIcon.faArchive} buttons={[
                        {
                            href: "admin/archiv/prepravy",
                            text: 'P'
                        }, {
                            href: "admin/archiv/volnevozy",
                            text: 'V'
                        }, {
                            href: "admin/archiv/inzerce",
                            text: 'I'
                        }
                    ]}/>
                    <DashboardTile text={t("Sections.Admin.Offers")} icon={faIcon.faList} buttons={[
                        {
                            href: "admin/nabidky/prepravy",
                            text: 'P'
                        }, {
                            href: "admin/nabidky/volnevozy",
                            text: 'V'
                        }, {
                            href: "admin/nabidky/inzerce",
                            text: 'I'
                        }
                    ]}/>
                    <DashboardTile text={t("Dials.Users")} icon={faIcon.faUser} href={"/admin/uzivatele"}/>
                    <DashboardTile text={t("Dials.Places")} icon={faIcon.faBuilding} href={"/admin/provozovny"}/>
                    <DashboardTile text={t("Dials.Companies")} icon={faIcon.faUserTie} href={"/admin/firmy"}/>
                    <DashboardTile text={t("Dials.ThunderMessages")} icon={faIcon.faEnvelope} href={"/admin/thunder-news"}/>
                    <DashboardTile text={t("Dials.Messages")} icon={faIcon.faMailBulk} href={"/admin/news"}/>
                </Grid>
            </Grid>
        </>
    );
}