import {Box, Divider, Grid, TextField, Typography, useTheme} from "@material-ui/core";
import * as React from "react";
import {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {CustomFieldComponentProps} from "./FormFieldInterface";
import Add from '@material-ui/icons/Add';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Delete from '@material-ui/icons/Delete';
import {useTranslation} from "react-i18next";
import {useFocusTypeDetection} from "./FocusHook";
import {useListRangeFocusable} from "./FormRange";
import {exist} from "../../utils/Util";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import {useStyleContext} from "../../../web/context/ThemeModeContext";
import {useForm} from "../../../web/raal_components/form/Form";
import {FlagIcon} from "../FlagIcon";
import {FormMuiAutocomplete} from "./FormMuiAutocomplete";
import {SelectProps} from "./FormSelect";
import {IconButton} from "@mui/material";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";

interface FormSimpleListProps {
    maxLines?: number
    maxLength?: number
    valueExtractor?: (v: any) => string
    valueConstructor?: (v: string) => any
    preFillValue?: string | (()=>string)
    valueType?: string
    showFlagAdornment?: boolean
    inputType?: string
    replacePreFillValue?: boolean
    cursorStartPosition?: number
    componentProvider?: (props: SimpleListComponentProps) => React.Component<SimpleListComponentProps>
}

export interface SimpleListComponentProps {
    focusedIndex?: number,
    focusedNumber?: number,
    focusChanged?: boolean,
    highlightError?: string,
    value: any,
    onChange: (v: any, sectionStart: number) => void
    disabled?: boolean
}

export interface FocusableAutocompleteProps extends SimpleListComponentProps {
    selectProps: SelectProps<any>
}

export function FocusableAutocomplete(props: FocusableAutocompleteProps) {
    return <FormMuiAutocomplete  value={props.value}
                                 focused={props.focusedNumber === props.focusedIndex+1}
                                 disabled={props.disabled}
                                 error={props.highlightError}
                                 name="phoneNumber"
                                 onError={()=>{}}
                                 onValueChanged={(v)=>{props.onChange(exist(v) ? v : "", 0)}}
                                 selectProps={{...props.selectProps, autoOpenDisabled: true, inputType: "phone"}}
                                 enableFocusSupport={() => {}}
    />
}

function FocusableTextField(props: CustomFieldComponentProps<[], FormSimpleListProps>&{focusedNumber:number, value:any, onChange:(value: any, cursorPosition: number)=>void, fIndex: number, focusChanged: boolean, highlightError?: string, onFocusField:(userClick: boolean)=>void, cursorPosition?: number, showFlagAdornment?: boolean, preFillValue?: string, cursorStartPosition?: number, inputType?: string}) {
    const ref = useRef<HTMLInputElement>(null);
    const {onMouseDown, onMouseUp, userClick} = useFocusTypeDetection();
    useEffect(()=>{
        if(props.focusedNumber === props.fIndex+1) {
            ref.current?.focus();
            if (props.focusChanged) {
                if (props.value !== props.preFillValue) {
                    ref.current?.select();
                }
                else {
                    ref.current?.setSelectionRange(props.cursorStartPosition??props.preFillValue.length, props.cursorStartPosition??props.preFillValue.length);
                }
            }
            else {
                ref.current?.setSelectionRange(props.cursorPosition, props.cursorPosition);
            }
        }
        // eslint-disable-next-line
    }, [])
    return <TextField disabled={props.disabled} fullWidth={true} value={props.value}
                      inputRef={ref}
                      onMouseDown={onMouseDown}
                      autoComplete={"off"}
                      onMouseUp={onMouseUp}
                      error={typeof props.highlightError !== 'undefined'}
                      InputProps={{
                          startAdornment: props.showFlagAdornment && props.value ? <FlagIcon phone={props.value} /> : null
                      }}
                      helperText={props.highlightError}
                      onFocus={() => props.onFocusField(userClick.current)}
                      inputProps= {{maxLength: Boolean((typeof props.options === 'function' ? props.options() : props.options).maxLength) ? (typeof props.options === 'function' ? props.options() : props.options).maxLength : undefined}}
                      onChange={nv => props.onChange(nv.target?.value?.trim(), nv.target.selectionStart) }/>;
}

export const FormSimpleList = forwardRef((props: CustomFieldComponentProps<[], FormSimpleListProps>, ref) => {
    const [li, setLi] = useState([]);
    const {t} = useTranslation();

    const {onValueChanged, options: pOptions, value:pValue, error: pError, onFocus, setLastField, allErrors, name: fieldName} = props;
    const options = typeof pOptions === 'function' ? pOptions() : pOptions;
    const {valueConstructor} = options;
    const maxLines = options.maxLines ?? 3;
    const theme = useTheme();
    const countAs = useCallback(() => li.length + 1, [li]);
    const [focused, setFocusedValue] = useListRangeFocusable(props, countAs);
    const [cursorPosition, setCursorPositionValue] = useState(0);
    const refButton = useRef<HTMLButtonElement>();
    const refRippleButton = useRef<any>();
    const {classes} = useStyleContext();
    const [focusError, setFocusErrorValue] = useState(false);
    const [focusChanged, setFocusChanged] = useState(false);
    const [allErrorsCpy, setAllErrorsCpy] = useState(() => allErrors);
    const skipFocusChanged = useRef(false);
    const isDoubleConfirm = useRef(0);
    const name = props.errorKey ?? fieldName;
    const form = useForm<any>();

    useImperativeHandle(ref, () => ({
        resetFocus,
        addNew,
        deleteItem
    }));

    useEffect(() => {
        setAllErrorsCpy(allErrors);
    }, [allErrors])

    useEffect(() => {
        if (Boolean(pValue))
            if(Boolean(options.valueExtractor))
                setLi(pValue.map(v => options.valueExtractor(v)));
            else
                setLi(pValue);
        // eslint-disable-next-line
    }, props.disabled ? [pValue] : []);

    useEffect(() => {
            if(!props.disabled) {
                onValueChanged(Boolean(valueConstructor) ? [...li].map(v => valueConstructor(v)) : [...li]);
            }
    // eslint-disable-next-line
    },[li]);

    const setFocused = useCallback((v:number) => {
      setFocusedValue(v);
    }, [setFocusedValue])

    const setFocusError = useCallback((v:boolean) => {
        setFocusErrorValue(v);
    }, [setFocusErrorValue])

    const setCursorPosition = useCallback((v:number) => {
      setCursorPositionValue(v);
    }, [setCursorPositionValue])

    // Validate field
    const validate = useCallback((value: string, index: number) => {
        if (pError && (!exist(value) || value === "")) {
            return pError;
        }
        const e = allErrorsCpy.find(fe => fe.name.startsWith(`${name}[${index}]`));
        return e?.getErrorMessage();
    }, [pError, allErrorsCpy, name])


    let prefillValue = options.preFillValue;
    if(typeof options.preFillValue === "function") {
        prefillValue = options.preFillValue();
    }

    // Add new field
    const addNewLi = useCallback(() => {
        setLi([...li, prefillValue ?? ""]);
        setTimeout(() => setFocused(li.length + 2), 1);
    }, [setLi, li, setFocused, prefillValue])

    const resetFocus = useCallback(() => {
        if (focused !== 0) {
            if (!focusError) setFocused(0);
            isDoubleConfirm.current++;
            setFocusError(false);
        }
    },[focused, setFocused, setFocusError, focusError])

    // Sorting after drag&drop
    const onSortEnd = ({oldIndex, newIndex} : { oldIndex: number, newIndex: number }) => {
        const value = li[oldIndex];
        const tempLi = [...li];
        tempLi.splice(oldIndex, 1);
        tempLi.splice(newIndex, 0, value)
        setLi([...tempLi]);
        setAllErrorsCpy(allErrors.map(e => {
                if(e.name.startsWith(`${name}[`)) {
                    let inx = parseInt(e.name.split("[")[1].split("]")[0]);
                    if(inx===oldIndex) {
                        e.name = e.name.replace(`${name}[${oldIndex}]`, `${name}[${newIndex}]`);
                    } else {
                        if(oldIndex > newIndex) {
                            if(inx>=newIndex && inx<=oldIndex) {
                                e.name = e.name.replace(`${name}[${inx}]`, `${name}[${inx+1}]`);
                            }
                        } else {
                            if(inx>=oldIndex && inx<=newIndex) {
                                e.name = e.name.replace(`${name}[${inx}]`, `${name}[${inx-1}]`);
                            }
                        }
                    }
                }
                return e;
            }
        ))
        setTimeout(() => setFocused(newIndex + 2), 1);
    }
    // Delete field
    const onDeleteItem = useCallback((i) => {
        setLi([...li.slice(0, i), ...li.slice(i + 1)]);
        if (li.length !== 0) setTimeout(() => setFocused(2), 1);
    }, [li, setLi, setFocused])

    const onChange = useCallback((value: any, i: number, cursorPos: number)=> {
        setFocusChanged(false);
        skipFocusChanged.current = focusError;
        isDoubleConfirm.current = 0;
        setCursorPosition(cursorPos);
        li[i] = value;
        setLi([...li]);
    }, [li, setLi, setFocusChanged, setCursorPosition, focusError])

    const onFocusField = useCallback((userClick: boolean, i: number, focusIndex: number)=> {
        if(userClick) {
            setFocused(focusIndex + 1);
        }
        onFocus && onFocus(userClick, focusIndex + 1);
        setLastField && setLastField(i === li.length - 1);
    }, [onFocus, setLastField,  setFocused, li.length])

    const addNew = useCallback(() => {
        if (li.length < maxLines) addNewLi();
    }, [li, maxLines, addNewLi])

    const deleteItem = useCallback(() => {
        if (focused > 1) onDeleteItem(focused - 2);
    }, [focused, onDeleteItem])

    const focusFirstError = useCallback(() => {
        let errorIndex: number;
        if (options.valueType) {
            errorIndex = pValue.findIndex(v => v[options.valueType] === '') + 2;
        } else {
            errorIndex = pValue.findIndex(v => v === '') + 2;
        }

        if(errorIndex===1) {
            let i: number = undefined;
            form.state.errors.errors.forEach((fe) =>{
                if(fe.name.startsWith(`${name}[`)) {
                    const eInx = parseInt(fe.name.split("[")[1].split("]")[0]);
                    i = Math.min(i ?? eInx, eInx)
                }
            });

            if(exist(i)) errorIndex = i + 2;
        }

        if(errorIndex===1)
            errorIndex = 2;

        setFocused(errorIndex);
        setFocusError(true);
        // eslint-disable-next-line
    },[pValue, options.valueType, setFocused])

    useEffect(()=>{
        if (!skipFocusChanged.current) setFocusChanged(true);
        // Select button
        if (focused === 1) {
            if (li.length >= maxLines && !(pError && !focusError)) {
                setFocused(2)
            }
            else {
                if (!pError || focusError) {
                    refButton.current?.focus();
                    // If list is empty
                    setLastField && setLastField(li.length === 0);
                }
                if ((pError && !focusError)) {
                    setTimeout(() => focusFirstError(), 0);
                }
            }
        } else {
            if (pError && isDoubleConfirm.current > 1) {
                setTimeout(() => focusFirstError(), 0);
            }
        }
        // eslint-disable-next-line
    }, [focused, setFocusChanged, li.length, pError, focusError])

    const DragHandle = SortableHandle(() => <DragHandleIcon />);

    const SortableItem = SortableElement(({value, i, focusIndex}:{value: any, i: number, focusIndex: number}) => {
        return (<Grid container item key={`dispatch-phone-number-${i}`} xs={12} md={6} lg={4}>
            <Grid container item alignItems="center" justifyContent={"space-between"} direction="row">
                <Grid item>
                    {!props.disabled ? <Grid container justifyContent={"center"} alignItems={"center"}><DragHandle/></Grid> : null}
                </Grid>
                <Grid item xs>
                    {exist(options.componentProvider) ?
                        options.componentProvider({
                            ...props,
                            value,
                            onChange: (v: any, sectionStart: any) => onChange(v, i, sectionStart),
                            highlightError: validate(value, i),
                            focusedIndex: focusIndex,
                            focusedNumber: focused,
                            focusChanged: focusChanged,
                            disabled: props.disabled
                        }) :
                        <FocusableTextField {...props}
                                            value={value}
                                            fIndex={focusIndex}
                                            focusedNumber={focused}
                                            focusChanged={focusChanged}
                                            showFlagAdornment={options?.showFlagAdornment}
                                            inputType={options?.inputType}
                                            preFillValue={!options?.replacePreFillValue ? typeof prefillValue === "function" ? prefillValue() : prefillValue ?? "" : ""}
                                            cursorPosition={cursorPosition}
                                            onChange={(v, cursorPos) => onChange(v, i, cursorPos)}
                                            onFocusField={(userClick) => onFocusField(userClick, i, focusIndex)}
                                            cursorStartPosition={options?.cursorStartPosition}
                                            highlightError={validate(value, i)}/>
                    }
                </Grid>
                <Grid item>
                    <IconButton disabled={props.disabled} onClick={() => onDeleteItem(i)}>
                        <Delete/>
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>)
    });

    const SortableList = SortableContainer(() => {
        return (
            <Grid container item alignItems="center" direction="row" wrap="wrap" justifyContent={"flex-start"}>
                {li.map((v: any, i) => <SortableItem key={i} index={i} value={v} i={i} focusIndex={i+1} />)}
            </Grid>
        )
    });

    const firstError = allErrorsCpy.find(fe => fe.name.startsWith(name));

    return <Box border={1} pl={1} pr={1} pb={1} borderRadius={4} borderColor={exist(firstError) ? theme.palette.error.main : undefined}>
        <Grid container direction="column">
            <Grid container item>
                <Grid container item alignItems="center" direction="row">
                    <Grid item>
                        {props.title}
                    </Grid>
                    <Grid container item xs justifyContent={"flex-end"}>
                        {li.length >=maxLines ?
                            <Box minHeight={48}/> :
                            <IconButton ref={refButton}
                                onFocus={() => refRippleButton?.current?.start({ center: true })}
                                disableRipple
                                onBlur={() => setTimeout(() => refRippleButton?.current?.stop({}), 1)}
                                disabled={props.disabled}
                                onClick={addNewLi}
                            >
                                <Add/>
                                <TouchRipple ref={refRippleButton} center />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Grid>

            <Divider style={{marginBottom: theme.spacing(1)}}/>

            <Grid container item>
                {((li?.length ?? 0) === 0) ?
                    <Typography>{t("PVI.ListEmpty")}</Typography>
                    :
                    <SortableList axis={"xy"} onSortEnd={onSortEnd} helperClass={classes.hocSorting} useDragHandle={true} />
                }
            </Grid>
        </Grid>
    </Box>
})
