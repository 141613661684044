import React, {Ref, useImperativeHandle, useState} from "react";
import {MapOSM, MapOSMFunctionsExposed} from "./MapOSM";
import {Marker, MarkerProps, Polyline} from "react-leaflet";
import L, {DragEndEvent, LatLng, LeafletMouseEvent} from "leaflet";
import {HighlightedTextInstruction} from "./osrmTextInstructions/OSRMTextInstructions";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ExtraRoute, ExtraWaypoint, FunctionMapProps} from "./FunctionalMap";
import {GeoPosition} from "geo-position.ts";
import {Waypoint} from "../../../web/model/Waypoint";
import {RouteFromOsrmData} from "../../utils/osrm-utils";
import {TrasaType} from "../../../web/page/Dials/user/kilometrovnik/ModalTrasaForm";

const useStyles = makeStyles(() => ({
    numberIcon: {
        backgroundImage: `url(${require("../../../assets/images/marker-icon.png").default})`,
        textAlign: 'center',
        color: '#fff',
        paddingTop: '5px',
        fontWeight: 700,
        fontSize: '10pt'
    }
}))

interface MapContainerProps {
    osmMapRef: Ref<MapOSMFunctionsExposed>
    markers: MarkerProps[]
    hasWayPoints: boolean
    mapRef: Ref<MapContainerExposed>
    routeChanged: boolean
    onRouteChanged: (state: boolean) => void
    points: any[]
    onClick:(event: LeafletMouseEvent) => void
    onDragEnd:(event: DragEndEvent) => void
    onMarkerDragged:(e: DragEndEvent, index: number) => void
    route: RouteFromOsrmData,
    extraRoutes?: ExtraRoute[],
    extraWaypoints?: ExtraWaypoint[],
    hideRoute?: boolean
    calcTabNum?: number
}

export interface MapContainerExposed {
    highlightRoute: (data: HighlightedTextInstruction) => void
}

export const MapContainer = (props: Omit<FunctionMapProps, "route" | "extraRoutes" | "extraWaypoints" | "hideRoute"> & MapContainerProps) => {
    const [highlightedRoute, setHighlightedRoute] = useState<HighlightedTextInstruction>(null);
    const {numberIcon} = useStyles();
    //defaults
    const markerIcon = (index: number) => {
        return L.divIcon({
            className: numberIcon,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [3, -40],
            html: `${index + 1}`
        });
    }

    const hiddenMarkerIcon = (index: number) => {
        return L.divIcon({
            className: numberIcon,
            iconSize: [25, 41],
            iconAnchor: [12, 75],
            popupAnchor: [3, -40],
            html: `${index + 1}`
        });
    }

    const markerIconText = (label: string) => {
        return L.divIcon({
            className: numberIcon,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [3, -40],
            html: label
        });
    }

    const hiddenMarkerIconText = (label: string) => {
        return L.divIcon({
            className: numberIcon,
            iconSize: [25, 41],
            iconAnchor: [12, 75],
            popupAnchor: [3, -40],
            html: label
        });
    }

    useImperativeHandle(props.mapRef, () => {
        return {
            highlightRoute: (data: HighlightedTextInstruction) => setHighlightedRoute(data)
        }
    })

    const comparePositions = (p1: any, p2: Waypoint) => {
        if (p1.lat && p1.lng && p2 instanceof Waypoint && p2.koordinat) {
            const distance = new GeoPosition(p1.lat, p1.lng).Distance(new GeoPosition(p2.koordinat.coordinates[1], p2.koordinat.coordinates[0]));
            return distance < 10;
        }

        return false;
    }

    const compareMarkers = (index: number) => {
        for (let i = props.markers.length - 2; i > 0; i--) {
            if (i === index) continue;
            const p1: any = props.markers[index].position;
            const p2: any = props.markers[i].position;
            const distance = new GeoPosition(p1.lat, p1.lng).Distance(new GeoPosition(p2.lat, p2.lng));
            if (distance < 10) {
                return true;
            }
        }

        return false;
    }

    return <Grid item xs={12} style={{position:"relative", padding:0}} >
        <MapOSM
            {...props}
            latLng={props.latLng}
            ref={props.osmMapRef}
            zoomControl={false}
            onclick={(event: LeafletMouseEvent) => {
                if (!props.disabled) {
                    if (!props.routeChanged) props.onRouteChanged(true);
                    props.onClick(event);
                }
            }}
            ondragend={props.onDragEnd}
            dimensions={{width:"100%", height:{value:80, relative:true}}}>
            {props.extraWaypoints && props.extraWaypoints.map((w, index) =>
                <Marker key={index} position={w.waypoint.koordinat.getLatLng()} icon={props.markers.some(value => comparePositions(value.position, w.waypoint)) ? hiddenMarkerIconText(w.label) : markerIconText(w.label)}/>
            )}


            {/*{props.markers.map((data, index)=>*/}
            {/*    <Marker draggable={!props.disabled} key={index}*/}
            {/*            icon={props.calcTabNum === 2 && props.trasaType !== TrasaType.NORMAL ?*/}
            {/*                markerIconText(`${props.trasaType === TrasaType.PREJEZD ? 'P' + (index + 1) : index === 0 ? 'N' : 'V'}`) :*/}
            {/*                compareMarkers(index) ? hiddenMarkerIcon(index) : markerIcon(index)*/}
            {/*            }*/}
            {/*            {...data}*/}
            {/*            ondragend={(e)=> {*/}
            {/*                if (!props.routeChanged) props.onRouteChanged(true);*/}
            {/*                props.onMarkerDragged(e, index);*/}
            {/*            }}/>)}*/}
            {props.hideRoute!==true && props.route?.routeData && !props.routeChanged ? <Polyline color="blue" lineCap={"round"} opacity={0.4} weight={10} stroke={true} positions={props.route.routeData.map((d) => {
                return d.route?.map(r => new LatLng(r[0], r[1]))
                })}/> : props.hideRoute!==true && props.hasWayPoints && <Polyline color="purple" weight={4} stroke={true} positions={props.points.map(p => p.latLng)} />
            }
            {props.extraRoutes ?
                <>
                    {props.extraRoutes.map((r, index) => r.route && <Polyline key={index} color={r.color} lineCap={"round"} dashArray={r.dashArray} opacity={0.4} weight={10} stroke={true} positions={r.route.routeData.map((r) => {
                        return r.route?.map(r2 => new LatLng(r2[0], r2[1]))
                    })}/>)}
                </> : undefined
            }
            {
                highlightedRoute && <Polyline color={highlightedRoute.color ?? "blue"} lineCap={"round"} opacity={0.6} weight={10} stroke={true} positions={highlightedRoute?.route.map(r => new LatLng(r[0], r[1]))}/>
            }
        </MapOSM>
    </Grid>
}
