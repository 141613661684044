import * as DG from "../DataGrid.d";
import {Column, FilterProps, MapperProps} from "../DataGrid.d";
import React, {
    forwardRef,
    MutableRefObject,
    Ref,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react";
import {Loading, LoadingExposed} from "../../../../common/component/Loading";
import {AutoRefreshStateHandler} from "../../../AutoRefresh";
import MaterialTable, {Action} from "material-table";
import {useMaterialTableOverrides} from "../MTOverrides";
import {evaluateBooleanOrFunction, evaluateStringOrFunction, exist} from "../../../../common/utils/Util";
import {useTranslation} from "react-i18next";
import {Box, Paper, useTheme} from "@material-ui/core";
import MTableBody from "../MTBody";
import {useConfirmDialog} from "../../ConfirmDialog";
import {useAppContext} from "../../../context/AppContext";
import MTableEditRow from "../MTDataGridEditRow";
import MTDataGridEditRow from "../MTDataGridEditRow";
import {MTDataGridFilterRow, MTDataGridFilterRowExposed} from "../MTDataGridFilterRow";
import {useData} from "../../../context/DataContext";
import {SystemParameter, SystemParamKey} from "../../../model/SystemParameter";
import {MaterialTableLocalizationExtended} from "../../../i18n/LocType.d";
import {globalStyles, useStyleContext, useThemeContext} from "../../../context/ThemeModeContext";
import {MTToolbar, MTToolbarExposed} from "../MTToolbar";
import {MTPagination, MTPaginationExposed} from "../MTPagination";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EditIcon from "@material-ui/icons/Edit";
import ReadIcon from "@material-ui/icons/Visibility";
import ClearIcon from "@material-ui/icons/Clear";
import CachedIcon from '@mui/icons-material/Cached';
import {httpEndpoint, JsonWrapper} from "../../../../common/utils/HttpUtils";
import {showSnack} from "../../../../common/component/SnackContainer";
import {isArray} from "../../../page/Dials/user/_vp/AssertPredicates";
import {getCurrentTabId} from "../../../../common/utils/unque-tab-id";
import DeleteIconOutline from "@material-ui/icons/DeleteOutline";
import {useTookHook} from "../../../../common/component/hooks/SharedHooks";
import {useStompSubscribe} from "../../../../common/utils/Websocket";
import {LockDto} from "../../../model/LockResult";
import DataStorage from "../../../../common/DataStorage";
import moment from "moment";
import {PubSub} from "use-pubsub-js";
import {
    StandaloneField,
    StandaloneFieldDataContext,
    StandaloneFieldExposed
} from "../../../../common/component/form/StandaloneField";
import {FieldError, ValidationError} from "../../../../common/component/form/ValidationError";
import {GenericMap} from "../../../../index.d";
import {invoke} from "../../../../common/utils/Invoke";
import AddIcon from "@material-ui/icons/AddCircle";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import RestoreIcon from "@mui/icons-material/Restore";
import MTableActions from "../MTActions";
import {DGContext} from "../DataGrid";
import {Mapper} from "../../../../common/utils/objectmapper/Mapper";
import {DataGridBackdrop} from "../DataGridBackdrop";
import {CrudOperationType, CrudUpdate, CrudUpdateEvent} from "../../../model/CrudUpdate";
import {TableExposed} from "./TableComponent";
import {LockStatus, useDetailLocker} from "../../controller/DetailLock";
import {getDetailId} from "../../controller/NavigationHelper";
import {tab} from "@testing-library/user-event/dist/tab";
import { useTableCache } from "../MTCache";

const MAX_OFFER_EDITS = SystemParamKey.MAX_OFFER_EDITS;

// eslint-disable-next-line
function useEditable<T extends object, Filter>(props: DG.Props<T>, converter: (data: any) => T, refresh: (extraHeaders?: GenericMap, crudUpdate?: CrudUpdateEvent, force?:boolean) => Promise<any>, setErrors:(errors:FieldError[])=>void, editRow:MutableRefObject<MTableEditRow[]>): {
    isEditable?: (rowData: T) => boolean;
    isDeletable?: (rowData: T) => boolean;
    onRowAdd?: (newData: T) => Promise<void>;
    onRowUpdate?: (newData: T, oldData?: T) => Promise<void>;
    onRowDelete?: (oldData: T) => Promise<void>;
} {
    const {t} = useTranslation();
    if (!exist(props.editable)) {
        return null;
    }
    const validate = (data:T):boolean => {
        const errors = props.validate&&props.validate(data);
        if(errors && errors.length > 0) {
            setErrors(errors);
            return false;
        }
        return true;
    };
    return {
        onRowAdd: props.editable.onRowAdd&&((data: T) => {
            return new Promise<void>((resolve, reject) => {
                if (validate(data)) {
                    props.editable.onRowAdd(props, data).then(()=>{
                        resolve();
                        setTimeout(() => {
                            refresh(undefined, undefined, true).then();
                        }, 0);
                        showSnack({title: t("FormLocalization.DataSaved"), severity: "success"});
                        editRow.current?.forEach(e=>e.cleanData());
                    }).catch((e)=>{
                        setErrors(e.errors);
                        showSnack({title: t("FormLocalization.DataSavedFailed"), severity: "error"});
                        reject();
                    });
                } else {
                    reject();
                }
            })
        }),

        onRowDelete:props.editable.onRowDelete&&((data: T) => {
            return new Promise<void>((resolve, reject) => {
                props.editable.onRowDelete(props, data).then(()=>{
                    resolve();
                    showSnack({title: t("FormLocalization.DataRemoved"), severity: "success"});
                    setTimeout(() => {
                        refresh(undefined, CrudUpdateEvent.of(CrudUpdate.of(data, CrudOperationType.REMOVE, getCurrentTabId()), false)).then();
                    }, 0);
                }).catch((e)=>{
                    if(e instanceof ValidationError) {
                        showSnack({title: `${t("FormLocalization.DataRemovedFailed")}: ${t(e.getOneErrorMessage())}`, severity: "error"});
                    } else {
                        showSnack({title: t("FormLocalization.DataRemovedFailed"), severity: "error"});
                    }
                    reject();
                });
            })
        }),
        onRowUpdate:props.editable.onRowUpdate&&((newData: T, oldData:T) => {
            return new Promise<void>((resolve, reject) => {
                if (validate(newData)) {
                    props.editable.onRowUpdate(props, newData, oldData).then(()=>{
                        resolve();
                        showSnack({title: t("FormLocalization.DataSaved"), severity: "success"});
                        setTimeout(() => {
                            refresh(undefined, CrudUpdateEvent.of(CrudUpdate.of(newData, CrudOperationType.UPDATE, getCurrentTabId()), false)).then();
                        }, 0);
                    }).catch((e)=>{
                        console.error("could not update record ", e);
                        setErrors(e.errors);
                        if(e instanceof ValidationError) {
                            showSnack({title: `${t("FormLocalization.DataSavedFailed")}: ${e.getOneErrorMessage()}`, severity: "error"});
                        } else {
                            showSnack({title: t("FormLocalization.DataSavedFailed"), severity: "error"});
                        }
                        reject();
                    });
                } else {
                    reject();
                }
            })
        }),
        isDeletable:props.editable.isDeletable,
        isEditable:props.editable.isEditable
    };
}

function useObjectConverter<T extends object>(constructor: { new(): T }): [(data: any) => T] {
    const mapper = useMemo<Mapper<T>>(() => new Mapper<T>({constructor: constructor}), [constructor]);
    return [
        data => {
            return mapper.readValue(data);
        }
    ];
}

export const OptimizedTable = <T extends object, Filter>({loadingRef, autofocus, preventScroll = true, arState, ...props}: DG.Props<T>&FilterProps<Filter> &{ loadingRef:MutableRefObject<LoadingExposed>, tbRef: MutableRefObject<TableExposed<T, Filter>>, createFilterDataObject():any}&MapperProps<Filter>&{arState: MutableRefObject<AutoRefreshStateHandler>}) => {
    const tableRef = useRef<MaterialTable<T>>();
    const bodyRef = useRef<MTableBody>();
    const rowDataChanged = useRef<boolean>(false);
    const typ = `${props.id || props.endpoint}`;
    const overrides = useMaterialTableOverrides<T, Filter>({...props, setLoading:status => {
            loadingRef.current?.setShow(status);
        }, tableRef, bodyRef, rowDataChanged, arState,
        typ,
        hasLayoutFilter: exist(props.layoutFilter)
    } );
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const {getState} = useContext(DGContext);
    const {filters, columns, updateColumnsOrder, data, onChangeRowsPerPage, onChangePage, onOrderChange, onFilterChanged, refresh, fetch, count, setLoading, modifiedCount, resetColumns, hideColumn} = overrides;
    const xPosition = useRef(0);
    const [showConfirm] = useConfirmDialog();
    const lastTabIndex = useRef<number>(2);
    const {checkDataChanged, setDataChanged, user, setEditRow, removeCallback} = useAppContext();
    const [convertJsonToObject] = useObjectConverter<T>(props.clazz);
    const MTableEditRowRef = useRef<MTableEditRow[]>([]);
    const MTableFilterRowRef = useRef<MTDataGridFilterRowExposed>(null);
    const [maxOfferEdits] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === MAX_OFFER_EDITS));
    const editable = useEditable(props, convertJsonToObject, refresh, errors => {
        if (MTableEditRowRef.current?.length !== 0)
            MTableEditRowRef.current[MTableEditRowRef.current?.length -1].setErrors(errors);
    }, MTableEditRowRef);
    let tableLocalization: MaterialTableLocalizationExtended = t("MaterialTable", { returnObjects: true });
    const createNewObject = ()=>props.createNewObject ? props.createNewObject() : new props.clazz();
    const {classes} = useStyleContext();
    const {mode} = useThemeContext();
    const toolbarRef = useRef<MTToolbarExposed>(null);
    const paginationRef = useRef<MTPaginationExposed>(null);
    const [showSelect, setShowSelect] = useState(false);
    const isDetailOpen = useRef(false);
    const isFiltersOpen = useRef(false);
    const {table} = useContext(DGContext);
    const detailLocker = useDetailLocker(props);
    const [refreshTable, setRefreshTable] = useState(false);
    const { clearTableCache } = useTableCache(props.id || props.endpoint, props?.cache?.group);

    useEffect(() => {
        window.addEventListener('keydown', onKey);
        return () => {
            // eslint-disable-next-line
            if (arState?.current?.enabled) setLastBrowsedDateKey();
            window.removeEventListener('keydown', onKey);
        }
        // eslint-disable-next-line
    }, [])

    const onKey = (e: KeyboardEvent) => {
        if (e.ctrlKey && e.key === ' ' && !isDetailOpen.current) {
            if (props.stomp?.toggleable) {
                if (arState.current?.enabled) {
                    arState.current?.disableAutorefresh();
                }
                if (arState.current && !arState.current?.enabled) {
                    arState.current?.enableAutorefresh();
                }
            }
        }
    }

    const onConfirmChangedData = (data: T[] | T) => {
        // Tady je zatim asi nejaka myslenka snizit pocitadlo AutoRefresh komponenty a refreshnout pouze jeden zaznam.
        // Request na aktualizaci jednoho zaznamu se aktualne neposila.
        // Otazkou je kolik lidi si vsimne klikaciho tlacitka s vykricnikem.
        // Prozatim nahrazeno stejnym refreshem jako dela tlaciko nacist dle filtru.
        // arState.current?.decreaseCounter();
        // @ts-ignore
        // bodyRef.current.confirmChangedData(data.id);
        // reloadTable(!r);
        table.current?.onResetLastTabIndex();
        arState.current?.resetCounter();
        table.current?.refresh(undefined, undefined, true);
        table.current?.setLastBrowsedDateKey();
    }

    const actions  = useMemo(()=>{
        const _actions:DG.ColumnAction<T>[] = [...props.actions ?? []];
        if (props.watchChanges) {
            _actions.push((rowData) => {
                // @ts-ignore
                return {
                    // @ts-ignore
                    hidden: !rowData?.changed,
                    // @ts-ignore
                    icon: () => <WarningAmberIcon style={globalStyles.chipChangedRecordIcon}/>,
                    tooltip: t("MaterialTable.body.dataChanged"),
                    onClick: (event, data) => onConfirmChangedData(data),
                    disabled: false,
                } as Action<T>;
            });
        }
        if(props.editable?.editEnabled) {
           _actions.push((rowData) => {
               return {
                   // @ts-ignore
                   hidden: props.hideDefaultDummyAction || rowData?.changed,
                   // @ts-ignore
                   icon: rowData?._deleted === true ? () =>
                       <Box/> : !evaluateBooleanOrFunction(props.isDetailReadOnly, rowData) ? EditIcon : ReadIcon,
                   tooltip: !evaluateBooleanOrFunction(props.isDetailReadOnly, rowData) ? tableLocalization.body.editRow.editTooltip : evaluateStringOrFunction(props.customReadOnlyTooltip, rowData) ?? tableLocalization.body.editRow.readTooltip,
                   onClick: (event, data) => {
                       if (MTableEditRowRef.current?.length !== 0)
                           MTableEditRowRef.current[0].moveFocusToFirstField();
                       props.onRowClick && props.onRowClick(data as T)
                   },
                   disabled: props.editable.isEditable && !props.editable.isEditable(rowData),
               } as Action<T>;
           });
        }
        if (props.deleteRowWithoutQuestion) {
            _actions.push((rowData) => {
                return {
                    // @ts-ignore
                    hidden: !props.deleteRowWithoutQuestion || rowData?.changed,
                    icon: () => <ClearIcon/>,
                    tooltip: t("MaterialTable.body.removeTooltip"),
                    onClick: async (event, data) => {
                        const continuation = async () => {
                            //TODO: change error msg - this is not lock, but record deletion
                            // @ts-ignore
                            const resultLock = await httpEndpoint<JsonWrapper<Boolean>>(JsonWrapper, `${props.endpoint}/${data.id}`, {method: "DELETE"});
                            if (resultLock.errors || resultLock.data.value === false) {
                                showSnack({title: t("javax.validation.lock.couldNotLock"), severity: "warning"});
                            }
                            if (exist(props.beforeAction)) {
                                await refresh(undefined, undefined, true);
                            }
                        }
                        if (exist(props.beforeAction)) {
                            props.beforeAction("delete", isArray(data) ? data : [data], continuation);
                        } else {
                            await continuation();
                        }
                    },
                    disabled: false
                } as Action<T>;
            });
        }
        if(!props.deleteRowWithoutQuestion && !(!exist(props.editable.onRowDelete) || props.hideDefaultDummyAction)) {
            _actions.push((rowData) => {
                // @ts-ignore
                const lock = exist(rowData?.lockUserInfo) && (rowData?.lockUserInfo?.login !== user.login || rowData?.tabId !== getCurrentTabId());
                return {
                    // @ts-ignore
                    hidden: lock || rowData?.changed,
                    isFreeAction: true,
                    // @ts-ignore
                    icon: rowData?._deleted === true ? () => <Box/> : () => <DeleteIconOutline/>,
                    tooltip: t("MaterialTable.body.deleteTooltip"),
                    onClick: async (event, data: T) => {
                        // @ts-ignore
                        if (data?._deleted === true)
                            return;
                        // @ts-ignore
                        if (exist(data?.lockUserInfo) && (data?.lockUserInfo?.login !== user.login || data?.tabId !== getCurrentTabId())) {
                            showSnack({title: t("Errors.CouldNotDeleteIsLocked"), severity: "warning"});
                            return;
                        }

                        arState.current?.disableAutorefresh(true);

                        await detailLocker.lock(getDetailId(props, data),
                            {title: t("javax.validation.lock.couldNotLock"), severity: "warning"}
                        );

                        if(!props.lockSupport || detailLocker.getLockStatus() === LockStatus.LOCKED) {
                            // @ts-ignore
                            tableRef.current.dataManager.changeRowEditing(data, "delete");
                            tableRef.current.setState({
                                // @ts-ignore
                                ...tableRef.current.dataManager.getRenderState(),
                            })
                        }
                    },
                    // @ts-ignore
                    disabled: rowData?._deleted === true || (props.editable.isDeletable && !props.editable.isDeletable(rowData)) || lock
                } as Action<T>;
            });
        }
        if(props.editable.refreshEnabled) {
            _actions.push((rowData) => {
                // @ts-ignore
                const lock = exist(rowData?.lockUserInfo) && (rowData?.lockUserInfo?.login !== user.login || rowData?.tabId !== getCurrentTabId());
                // @ts-ignore
                const deleted = rowData?._deleted === true || (props.editable.isDeletable && !props.editable.isDeletable(rowData));
                // @ts-ignore
                const invalid = rowData?.invalDuv > 1 && rowData?.invalDuv !== 10;
                const edits = Number.parseInt(maxOfferEdits?.value ?? '0');
                // @ts-ignore
                const count = ((edits - rowData?.pocetEditaci ?? edits) < 1) && !(maxOfferEdits?.value < 0);
                // @ts-ignore
                const availableEdits = (maxOfferEdits?.value < 0) ? '' : Math.max((edits - rowData?.pocetEditaci ?? maxOfferEdits?.value), 0);
                return {
                    // @ts-ignore
                    hidden: props.hideDefaultDummyAction || lock || deleted || invalid || !exist(rowData?.pocetEditaci) || rowData?.changed,
                    isFreeAction: true,
                    icon: () => <Box className={[classes.updateBox, count ? classes.disabledUpdateBox : ''].join(' ')}><CachedIcon className={classes.updateIcon} /><span className={classes.updateText}>{availableEdits}</span></Box>,
                    tooltip: count ? t("MaterialTable.body.noUpdateTooltip") : t("MaterialTable.body.updateTooltip"),
                    // @ts-ignore
                    onClick: rowData?.invalDuv === 0 || rowData?.invalDuv === 10 ? async (event, data: T) => {
                        if (MTableEditRowRef.current?.length !== 0) {
                            //#4436 - Filtr Datum se sám otevírá na pohledu v Zadání
                            //!count ? MTableEditRowRef.current[0].moveFocusToFirstField() : MTableEditRowRef.current[0].moveFocusToCurrentField();
                        }
                        if (count) return;

                        setLoading(true);

                        await detailLocker.lock(getDetailId(props, data),
                            {title: t("javax.validation.lock.couldNotLock"), severity: "warning"}
                        );
                        if(!props.lockSupport || detailLocker.getLockStatus() === LockStatus.LOCKED) {
                            // @ts-ignore
                            const result = await httpEndpoint<T>(props.clazz, `${props.endpoint}/${data.id}`,
                                {
                                    method: "PUT",
                                    // @ts-ignore
                                    headers: {
                                        'obnova': 'true',
                                        // @ts-ignore
                                        'modificationDate': data.modifiedOn.toISOStringWithMillis()
                                    },
                                    body: null
                                });
                            if (result.errors) {
                                showSnack({
                                    title: result.errors.errors?.map(e => e.message).join(', '),
                                    severity: "warning"
                                });
                            } else {
                                await detailLocker.unlock(getDetailId(props, data),
                                    {title: t("javax.validation.lock.couldNotLock"), severity: "warning"}
                                );
                                if(detailLocker.getLockStatus() === LockStatus.UNLOCKED) {
                                    if (arState.current?.enabled) await refresh(undefined, undefined, true).then();
                                }
                            }
                        }
                        setLoading(false);
                    } : async (event, data) => {
                        if (count) return;
                        if (((editable.isEditable && editable.isEditable(data as T)) || !editable.isEditable) && editable.onRowUpdate) {
                            arState.current.disableAutorefresh(true);
                            tbRef.current?.onRefreshTable(true);
                            // @ts-ignore
                            tableRef.current.dataManager.changeRowEditing(data as T, "update");
                            tableRef.current.setState({
                                // @ts-ignore
                                ...tableRef.current.dataManager.getRenderState(),
                                showAddRow: false
                            });
                        } else {
                            props.onRowClick && props.onRowClick(data as T);
                        }
                    },
                    disabled: false
                } as Action<T>;
            });
        }
        if (_actions.length === 0) {
            _actions.push((rowData) => {
                // @ts-ignore
                return {
                    // @ts-ignore
                    hidden: true,
                } as Action<T>;
            });
        }
        return _actions;
        // eslint-disable-next-line
    }, [props, tableLocalization, t, user]);

    useTookHook("Render Table");

    const getLabelDisplayedRows = (label: string) => {
        let tempLabel = label.replace('{overall}', `${data.overallCount}`);
        if (props.maxCount) tempLabel = tempLabel.replace('{count}', `${data.totalInfoCount}`);
        return tempLabel;
    }

    const onScrollX = (position: number) => {
        xPosition.current = position;
    }

    const onDataChanged = (isEqual: boolean) => {
        rowDataChanged.current = !isEqual;
        setDataChanged(!isEqual);
        if (MTableEditRowRef.current?.length !== 0) {
            setEditRow(isEqual ? null : MTableEditRowRef.current[MTableEditRowRef.current.length - 1]);
            MTableEditRowRef.current[MTableEditRowRef.current.length - 1].validateOnChange();
        }
    }

    //replace row style with deleted where applicable
    const {rowStyle, ...restOptions} = props.options ? props.options : {rowStyle: undefined};
    const newRowStyle = (data: any, index: number, level: number) => {
        if(data._deleted)
            return mode === 'dark' ? {...globalStyles.rowStyleDeleted, color: 'white'} : globalStyles.rowStyleDeleted;
        const calculated = exist(rowStyle) ?  (typeof rowStyle === "function") ? rowStyle(data, index, level) : rowStyle : undefined;
        return mode === 'dark' ? {...calculated, color: 'white'} : calculated;
    }

    useStompSubscribe<LockDto>( props.lockSupport?.enabled ? `/provozovna/${user.provozovna.kod}/lock/${props.lockSupport.stompPath}` : undefined, {callback:(lock) => {
            if(lock.uzivatel.login===user.login && getCurrentTabId()===lock.tabId && lock.locked===false && lock.unlockTabId !==getCurrentTabId()) {
                showSnack({title: t("Errors.LockLost"), severity:"warning", duration: 15000});
                MTableEditRowRef.current?.forEach(r=>r.onCancelClick(false, false));
            }
        }});

    const resetOrder = () =>{
        const confirm = () => {
            showConfirm({
                body: t("MaterialTable.resetColumnOrderQuestion"),
                onConfirm: () => {
                    resetColumns();
                    refresh(undefined, undefined, true);
                },
                onCancel: () => {
                    //@ts-ignore
                    MTableEditRowRef.current?.forEach(r=>r.moveFocusToCurrentField());
                },
                title: t("MaterialTable.resetColumnOrder"),
                buttons: {
                    cancel: t("Default.No"),
                    confirm:t("Default.Yes")
                }
            });
        }
        if (!checkDataChanged(() => confirm())) confirm();
    }

    const getFooterButtons = () => {
        if (props.hideFooterButtons) return null;
        return (<></>);
    }

    const getHiddenCols = (): string[] => {
        const storageKey = `datagrid_${props.id || props.endpoint}`;
        let sessionData = DataStorage.get(storageKey, true, "session");
        if (sessionData)
            return JSON.parse(sessionData)?.hiddenColumns ?? []
        return [];
    }

    const setLastBrowsedDateKey = () =>{
        if (props.lastBrowsedDateKey) {
            DataStorage.set(props.lastBrowsedDateKey, moment().format())
            PubSub.publish('setLastBrowsedDateChanged', moment().format())
        }
    }

    const getDefaultSort = (col: Column<T>) => {
        const orderBy = getState()?.current?.orderBy
        if (!orderBy) {
            return col.defaultSort;
        } else {
            if (orderBy === col.field) {
                return getState().current.orderDirection;
            }
        }

        return undefined;
    }

    const _table = ({rowFiltering=true, ...props}:DG.Props<T>, ref:Ref<TableExposed<T, Filter>>) => {
        useImperativeHandle(ref, () => (
            {
                refresh,
                onFilterChanged,
                getFilter() {
                    return getState().current;
                },
                fetch: async ()=> (await fetch()).data,
                count: async ()=> await count(),
                getData: ()=> data,
                setLoading: status => loadingRef.current.setShow(status),
                isLoading: () => loadingRef.current.show,
                modifiedCount: async (timestamp)=> await modifiedCount(timestamp),
                onDetailOpen: () => {
                    isDetailOpen.current = true
                    MTableEditRowRef.current?.forEach(r=>r.onCancelClick(false, false));
                },
                onResetLastTabIndex: () => {
                    lastTabIndex.current = 2;
                },
                onDetailClosed: () => {
                    isDetailOpen.current = false
                    MTableEditRowRef.current?.forEach(r=>r.prepareEditRow());
                    setTimeout(() => arState.current?.enableAutorefresh(), 100)
                },
                onRefreshTable: (addRowOnly?: boolean) => {
                    if (addRowOnly &&  MTableEditRowRef.current?.length !== 0) {
                        MTableEditRowRef.current[0].cleanData(false);
                    } else {
                        MTableEditRowRef.current?.forEach(r=>r.onCancelClick(false, false));
                        refresh(undefined, undefined, true).then();
                    }
                },
                setLastBrowsedDateKey: () => {
                    setLastBrowsedDateKey();
                },
                onConfirmChangedData: (data: T[] | T) => onConfirmChangedData(data),
                focusEditRow: () => {
                    //#4436 - Filtr Datum se sám otevírá na pohledu v Zadání
                    /*if (MTableEditRowRef.current?.length !== 0) {
                        MTableEditRowRef.current[0].moveFocusToCurrentField();
                    }*/
                },
				focusNextEditRow: () => {
					if (MTableEditRowRef.current?.length !== 0) {
						MTableEditRowRef.current[0].moveFocusToNextField();
					}
				},
                onRefreshFilterHeaderIndexes: () => {
                    MTableFilterRowRef.current?.setTabIndexes(true);
                },
                setFiltersOpened: opened => isFiltersOpen.current = opened,
                isFiltersOpened: () => isFiltersOpen.current,
            }
        ));

        const wrapperRef = useRef<HTMLDivElement>();
        const minTableHeight = props.minimumTableHeight ?? 300;

        useEffect(() => {
            if(!props.disableTableScroll){
                onResize(null);
                window.addEventListener('resize', onResize, true)
                return () => window.removeEventListener("resize", onResize, true);
            }
        }, []);

        const onResize = (e: any) => {
            setTimeout(() => {
                const wrapperHeight = wrapperRef?.current?.getBoundingClientRect().height;
                const tableHeight = wrapperRef?.current?.children[0]?.getBoundingClientRect().height;
                const tableElement = bodyRef?.current?.ref?.current?.parentNode?.parentNode?.parentNode as HTMLDivElement;
                if(tableElement != null && wrapperHeight != null && tableHeight != null){
                    const height = tableElement.getBoundingClientRect().height + (wrapperHeight - tableHeight);
                    if(height <= minTableHeight){
                        wrapperRef.current.style.overflow = 'initial';
                        tableElement.style.maxHeight = minTableHeight + "px";
                    } else {
                        wrapperRef.current.style.overflow = 'hidden';
                        tableElement.style.maxHeight = height + "px";
                    }
                }
            }, 0)
        }

        return (
            <div ref={wrapperRef} style={{ position: "relative", flexGrow: "inherit", overflow: props.disableTableScroll ? "initial" : "hidden" }}>
                <DataGridBackdrop id={props.tableTitle ?? ''} filtersOpened={isFiltersOpen?.current} />
                <MaterialTable
                    key={"table"}
                    style={{borderCollapse:"separate"}}
                    columns={
                        columns.map((c, index) => {
                            const newColumn = {...c};

                            //disable frontend level sorting, we are sort data on backend level
                            newColumn.customSort = () => 0;
                            newColumn.cellStyle = {...newColumn.cellStyle, padding: "0 4px", borderRight: (theme.palette.type === 'dark' ? '1px solid rgba(255, 255, 255, 0.12)': '1px solid rgba(0, 0, 0, 0.12)'), whiteSpace: 'nowrap'};
                            newColumn.removable = !c.required && !c.readonly;
                            newColumn.defaultSort = getDefaultSort(c);
                            newColumn.editComponent = (cprops:any) => {
                                const fields:StandaloneFieldExposed[] = cprops.fields;
                                const editProps = (c as Column<T>).editProps;
                                if (editProps) {
                                    const entity = cprops.rowData as T;
                                    const errors:FieldError[] = cprops.errors;
                                    const errorsToString = () => {
                                        const _errors = errors?.filter(e=>e.name===c.field || (c.additionalFields??[]).includes(e.name));
                                        if((_errors?.length ?? 0) === 0) {
                                            return undefined;
                                        }
                                        return _errors.map(e=>{
                                            if(e.message) {
                                                return e.message;
                                            } else {
                                                return t(`${[...e.codes].reverse()[0]}`)
                                            }
                                        }).join(",");
                                    };
                                    // @ts-ignore
                                    const {onChange, ...eProps} = editProps(entity, entity?.tableData?.editing === "update", cprops.setData);
                                    const value = eProps.value ?? (entity as GenericMap)[eProps.name ?? cprops.columnDef.field];
                                    return <StandaloneFieldDataContext.Provider value={entity}><StandaloneField
                                        onMount={o => {
                                            if(!fields.includes(o)) {
                                                fields.push(o);
                                            }
                                            cprops.onMount();
                                        }}
                                        onFocus={((o, userFocus, index) => {
                                            invoke(cprops.onFocus, o, userFocus, index);
                                            invoke(props.onFocusedChildDataGridRow);
                                        })}
                                        onBlur={(o => {
                                            invoke(cprops.onBlur, o);
                                            invoke(props.onBlurChildDataGridRow);
                                        })}
                                        onDismount={o => {
                                            fields.splice(fields.indexOf(o), 1);
                                        }}
                                        onError={cprops.onError}
                                        allErrors={errors}
                                        error={errorsToString()}
                                        autofillValue
                                        {...eProps}
                                        value={value}
                                        onInteractStart={cprops.onInteractStart}
                                        onDisableActionKeys={cprops.onDisableActionKeys}
                                        onValueChanged={v => {
                                            if(onChange) {
                                                // @ts-ignore
                                                onChange(v, entity, (d:T)=>{
                                                    cprops.setData(d);
                                                }, cprops.columnDef.field);

                                            } else {
                                                cprops.onChange(v);
                                            }
                                        }}
                                        variant={"standard"}
                                        showAdornment={false}
                                        useCustomPopperElement={true}
                                    /></StandaloneFieldDataContext.Provider>;
                                }
                                return null;
                            };
                            return newColumn;
                        })
                    }

                    data={data.data}
                    totalCount={data.totalCount}
                    page={data.page}

                    onColumnDragged={(sourceIndex, destinationIndex) => {
                        updateColumnsOrder(sourceIndex, destinationIndex);
                        setRefreshTable(!refreshTable);
                    }}
                    onChangeColumnHidden={(column, hidden) => {
                        const hiddenCols = getHiddenCols();
                        if (column?.field && !(hidden && hiddenCols.indexOf(column.field.toString()) !== -1)) {
                            hideColumn(column.field.toString(), hidden);
                            toolbarRef.current?.reload();
                        }
                        MTableEditRowRef.current?.forEach(r=>r.setTabIndexes(true));
                        MTableFilterRowRef.current?.setTabIndexes(true);
                    }}
                    onOrderChange={onOrderChange}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={(page) => {
                        onChangePage&&onChangePage(page);
                        if (page !== 0) arState.current.disableAutorefresh();
                    }}
                    tableRef={tableRef}
                    title={props.tableTitle ? props.tableTitle : "Datagrid"}
                    onSelectionChange={(rows: any[]) => toolbarRef.current?.selectedCountChanged(rows?.length??0)}
                    actions={actions.map(action => {
                        return (rowData: T) => {
                            const d = action(rowData);
                            return {
                                // @ts-ignore
                                hidden: d.hidden ?? false,
                                icon: d.icon,
                                onClick: d.onClick,
                                disabled: d.disabled,
                                tooltip:d.tooltip
                            } as Action<T>;
                        };
                    })}
                    options={{
                        ...restOptions,
                        rowStyle: newRowStyle,
                        ...(props.disableTableScroll ? undefined : {
                                maxBodyHeight: minTableHeight + "px",
                        }),
                        addRowPosition:"first",
                        draggable:true,
                        search:false,
                        columnsButton: true,
                        selection: showSelect,
                        selectionProps: (rowData: any) => ({
                            disabled: rowData.tableData?.disabled??false
                        }),
                        paging: !props.pagingDisabled,
                        pageSize: getState().current.pageSize,
                        filtering: rowFiltering && props.filtering,
                        actionsColumnIndex: props.actionsColumnIndex || 0,
                        headerStyle: {
                            backgroundColor: theme.palette.type === 'dark' ? "#333333" : theme.palette.grey[100],
                            color: theme.palette.type === 'dark' ? theme.palette.grey[50] : theme.palette.grey[900],
                            padding: "5px 0 5px 0",
                            textAlign: "center",
                            whiteSpace: "nowrap"
                        },
                        padding: "dense",
                        pageSizeOptions:[20,40,60,80,100],
                        emptyRowsWhenPaging:false,
                    }}
                    icons={{
                        Add:forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
                        ViewColumn: forwardRef((props, ref) => <ViewColumnIcon {...props} ref={ref} />),
                        Retry: forwardRef((props, ref) => <RestoreIcon {...props} ref={ref} />)
                    }}

                    components={{
                        Body: p=> <MTableBody
                            {...p}
                            renderData={data.data}
                            autofocus={autofocus && !isFiltersOpen.current}
                            arState={props.stomp?.toggleable}
                            disableLockSupport={!props.lockSupport?.enabled}
                            alwayShowAdd={exist(editable.onRowAdd)}
                            ref={bodyRef}
                            preventScroll={preventScroll}
                            onScrollX={onScrollX}
                            leftPosition={xPosition.current}
                            overflowHidden={props.overflowHidden}
                        />,
                        Actions: p => <MTableActions {...p} />,
                        FilterRow: p => <MTDataGridFilterRow<T, Filter>
                            fRowRef={MTableFilterRowRef}
                            onEditingStart={props.onEditingStart}
                            onEditingEnd={props.onEditingEnd}
                            {...p}
                            onFilterClick={(filter) => {
                                if (exist(props.onClearFilter) && !filter) {
                                    props.onClearFilter();
                                }
                                if (!filter) {
                                    clearTableCache();
                                    PubSub.publish('defaultTemplateSet' + props.tableTitle ?? '', false);
                                }
                                arState.current?.enableAutorefresh(true);
                                onFilterChanged&&onFilterChanged(filter);
                            }}
                            onFilterDataChanged={()=> {
                                if (arState.current?.enabled)
                                    arState.current.disableAutorefresh(true)
                            }}
                            checkAutorefreshState={() => {
                                return arState.current?.enabled ?? false;
                            }}
                            autoFocus={exist(autofocus) ? autofocus && !isFiltersOpen.current : !isFiltersOpen}
                            defaultFilters={filters}
                            indexOffset={props.indexOffset??1}
                            lastTabIndex={lastTabIndex.current}
                            setLastTabIndex={(index: number) => {
                                lastTabIndex.current = index
                            }} />,
                        Toolbar: props1 => <MTToolbar {...props1}
                                                      draggable={props.draggable ?? true}
                                                      templatesEnabled={props.templatesEnabled}
                                                      customActions={props.toolbarActions}
                                                      getCustomMultiColumnActions={props.getToolbarMultiActions}
                                                      componentRef={toolbarRef} datagridId={props.id || props.endpoint}
                                                      getHiddenCols={getHiddenCols}
                                                      exportConfig={props.exportConfig}
                                                      showSelect={showSelect}
                                                      requiredColumns={props.requiredColumns}
                                                      defaultHiddenColumns={props.defaultHiddenColumns}
                                                      connectedCols={props.showHideConnectedCols}
                                                      showExport={(state) => {
                                                          if(state == null) state = !showSelect;
                                                          if(state && arState.current?.enabled) arState.current?.disableAutorefresh(true);
                                                          if(!state && !arState.current?.enabled) arState.current?.enableAutorefresh();
                                                          if (!state) {
                                                              for (let i = 0; i < data.data?.length; i++) {
                                                                  // @ts-ignore
                                                                  if (data.data[i]?.tableData?.checked) data.data[i].tableData.checked = false
                                                              }
                                                          }
                                                          setShowSelect(state);
                                                      }}
                                                      getFilter={() => getState().current}
                                                      onResetOrder={resetOrder} />,
                        Pagination:p=> <MTPagination {...p} onRowsPerPageChange={p.onChangeRowsPerPage}
                                                     onPageChange={onChangePage}
                                                     overallCount={data.overallCount}
                                                     hidePaginationButtons={props.hidePaginationButtons}
                                                     footerPagingText={props.footerPagingText}
                                                     footerButtons={() => getFooterButtons()}
                                                     componentRef={paginationRef}
                                                     onClick={() => {
                                                         if (MTableEditRowRef.current?.length !== 0)
                                                             MTableEditRowRef.current[0].moveFocusToCurrentField();
                                                     }}
                        />,
                        Container:({children, ...props1}) => {
                            return (
                                <Paper elevation={2} {...props1}>
                                    {children}
                                    <Loading ref={loadingRef} title={t("Default.Loading")}/>
                                </Paper>
                            );
                        },
                        //@ts-ignore
                        EditRow: props1 => <MTDataGridEditRow {...props1}
                                                              setLoading={setLoading}
                                                              getDetail={props.getDetail}
                                                              detailLocker={detailLocker}
                                                              arState={arState}
                                                              tFun={t}
                                                              i18nFun={i18n}
                                                              validateOnChange={(data: T) => props.validate&&props.validate(data)}
                                                              excludeFieldsForIntegerCheck={props.excludeFieldsForIntegerCheck}
                                                              editRows={MTableEditRowRef}
                                                              onUpdateBegin={()=> {
                                                                  bodyRef.current?.setState({disableAddRow: true});
                                                                  toolbarRef.current?.dataChanged(false);
                                                              }}
                                                              onBeginDelete={()=> {
                                                                  bodyRef.current?.setState({disableAddRow: true});
                                                                  toolbarRef.current?.dataChanged(false);
                                                              }}
                                                              onDataError={()=>{ bodyRef.current?.setState({hasEditRowError:true})}}
                                                              onUpdateEnd={()=> {
                                                                  setTimeout(() => {
                                                                      //#4436 - Filtr Datum se sám otevírá na pohledu v Zadání
                                                                      /*if (MTableEditRowRef.current?.length !== 0) {
                                                                          MTableEditRowRef.current[0].moveFocusToFirstField();
                                                                      }*/
                                                                      toolbarRef.current?.dataChanged(true);
                                                                  }, 10)
                                                              }}
                                                              onDeleteEnd={(id: any)=> {
                                                                  removeCallback(id);
                                                                  bodyRef.current?.setState({disableAddRow: false});
                                                                  toolbarRef.current?.dataChanged(true);
                                                                  //#4436 - Filtr Datum se sám otevírá na pohledu v Zadání
                                                                  /*if (MTableEditRowRef.current?.length !== 0)
                                                                      MTableEditRowRef.current[0].moveFocusToFirstField();*/
                                                              }}
                                                              autofocus={xPosition.current === 0 ? autofocus && !isFiltersOpen : false}
                                                              dataChanged={checkDataChanged}
                                                              onEditingStart={props.onEditingStart}
                                                              onEditingEnd={()=> {
                                                                  props.onEditingEnd();
                                                                  bodyRef.current?.setState({disableAddRow: false,hasEditRowError:false});
                                                                  toolbarRef.current?.dataChanged(true);
                                                              }}
                                                              onDataChanged={(mode: string, isEqual: boolean) => {
                                                                  onDataChanged(isEqual);
                                                                  setTimeout(() => {
                                                                      if (mode === 'add' && bodyRef.current?.state && bodyRef.current?.state.disableEdit === isEqual) {
                                                                          bodyRef.current?.setState({disableEdit: !isEqual});
                                                                          toolbarRef.current?.dataChanged(isEqual);
                                                                      }
                                                                  }, 0);
                                                              }}
                                                              createNewObject={createNewObject}
                                                              tabDisabled={props.tabDisabled}
                                                              onEditingCanceled2={() => {
                                                                  refresh().then();
                                                                  toolbarRef.current?.dataChanged(true);
                                                              }}
                                                              onExtendedButtonClicked={
                                                                  (data:T, origin:T)=>{
                                                                      props.extendedButtonClicked && props.extendedButtonClicked(data);
                                                                      props.onRowClick(data, origin, true);
                                                                      setTimeout(() => bodyRef.current?.setState({disableEdit: false}), 500);
                                                                      toolbarRef.current?.dataChanged(true);
                                                                  }
                                                              }
                                                              indexOffset={props.indexOffset}
                                                              lastTabIndex={lastTabIndex.current}
                                                              setLastTabIndex={(index: number) => {
                                                                  lastTabIndex.current = index
                                                              }}
                                                              beforeSend={props.beforeSend}
                        />
                    }}

                    editable={{...editable, isEditable:()=>false, isDeletable:()=>false}}
                    detailPanel={props.masterDetail}
                    localization={{
                        ...tableLocalization,
                        pagination:{...tableLocalization.pagination, labelDisplayedRows: getLabelDisplayedRows(tableLocalization.pagination.labelDisplayedRows as string)},
                        body:{...tableLocalization.body, emptyDataSourceMessage:props.lazyLoading?t("Provozovna.ManualDataFetch"):tableLocalization.body.emptyDataSourceMessage}
                    }}

                    // @ts-ignore
                    onRowClick={editable ? (a, b, _) => !(b?._deleted) && !(b?.changed) && !evaluateBooleanOrFunction(props.disableRowClick, b) && checkDataChanged(() => {
                            // @ts-ignore
                            if (exist(tableRef.current.state?.lastEditingRow) || showSelect) return;

                            if(props.columns.some(c => c.editProps) && (((editable.isEditable && editable.isEditable(b)) || !editable.isEditable) && editable.onRowUpdate)) {
                                arState.current.disableAutorefresh(true);
                                tbRef.current?.onRefreshTable(true);
                                // @ts-ignore
                                tableRef.current.dataManager.changeRowEditing(b, "update");
                                tableRef.current.setState({
                                    // @ts-ignore
                                    ...tableRef.current.dataManager.getRenderState(),
                                    showAddRow: false
                                });
                            } else {
                                props.onRowClick && props.onRowClick(b);
                            }
                        }) :
                        (props.onRowClick && !showSelect ? (event, rowData) => checkDataChanged(() =>props.onRowClick(rowData)) : null)
                    }

                />
            </div>
        );
    };

    const FW = forwardRef<TableExposed<T, Filter>, DG.Props<T>>(_table);
    const {tbRef, ...props2} = props;
    return <FW {...props2} ref={tbRef}/>;

}
