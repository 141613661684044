import {useTranslation} from "react-i18next";
import {useCurrencySelectObj, useEnumeratedSelect} from "../../../../raal_components/SelectOptions";
import {FormField, FormInputType, useForm, useFormData} from "../../../../raal_components/form/Form";
import {Grid} from "@mui/material";
import {SectionLine} from "../../../../raal_components/parts/SectionLine";
import {longCenaConstraint, sizeFormat} from "../../user/_vp/PrepravaAVozidlaShared";
import {DluhFakt, DluhFakturaStav, DluhPripad, DluhPripadStav} from "../../../../model/Stiznosti";
import {FormPartBox} from "../../user/prohlizeni/FormPartBox";
import * as React from "react";
import {useRef, useState} from "react";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {ChangeEntityStateButton, useChangeStateAction} from "./state-change-action";
import {useDidMount} from "../../../../../common/component/hooks/SharedHooks";
import {FormFieldExposed} from "../../../../raal_components/form/FormField";
import {useParams} from "react-router-dom";
import {stiznost_dluh_pripad} from "../../../../raal_components/Endpoints";
import {invoke} from "../../../../../common/utils/Invoke";

export function DluhFaktLayoutForm() {
    const {t} = useTranslation();
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const stavSelectProps = useEnumeratedSelect(DluhFakturaStav, "Enumerations.DluhFakturaStav", "number", {isClearable: false})
    const {fetch} = useFetchCustom({endpoint: 'admin/stiznost/dluh-pripad/any/findByCisloFaktury'})
    const cisloRef = useRef<FormFieldExposed>();
    const dluhFakt = useFormData<DluhFakt>();
    const [showConfirm] = useConfirmDialog();
    const form = useForm<DluhFakt>();
    const prevFakNumber = useRef(null);
    const {pripadId} = useParams<{pripadId?: string}>();
    const {fetch: fetchPripad} = useFetchCustom<DluhPripad, string>({endpoint: `${stiznost_dluh_pripad}/${pripadId}`})
    const [pripad, setPripad] = useState<DluhPripad>(null);

    useDidMount(() => {
        const getPripad = async() => {
            const pripad = await fetchPripad();
            if (pripad) setPripad(pripad);
        }

        // Uložení předchozího čísla faktury
        prevFakNumber.current = dluhFakt.cislo;
        invoke(getPripad);
    })

    // Zobrazení potvrzovací hlášky
    const confirmation = (pripady?: DluhPripad[]) => {
        const filteredPripady = pripady ?? []
        if (filteredPripady.length !== 0) {
            const pripadyIds = filteredPripady.map(p => `<a onclick="window.open(this.href,'_blank');return false;" href='./${p.id.toString()}'>${p.id.toString()}</a>`).join(',');
            showConfirm({
                htmlBody: t("DluhPripad.FaktExist").replace('{pripadyIds}', pripadyIds),
                onCancel: () => {
                    dluhFakt.cislo = null;
                    form.onRefreshFieldValue('cislo');
                },
                title: t("DluhPripad.FaktExistTitle"),
                buttons: {
                    cancel: t("Default.No"),
                    confirm: t("Default.Yes")
                }
            });
        }
    }

    // Kontrola existence totožného případu
    const checkFaktNumberExist = () => {
        if (dluhFakt?.cislo && (!prevFakNumber.current || prevFakNumber.current !== dluhFakt.cislo)) {
            fetch({
                params:{cislo: dluhFakt.cislo}
            }).then(res => {
                confirmation(res as DluhPripad[]);
            }).catch(() => {
                showSnack({title: t("UnexpectedError"), severity: "error"});
            });
        }
    }

    const changeFakturaStateAction = useChangeStateAction(`admin/stiznost/dluh-pripad/${pripadId}/faktura/${dluhFakt.id}/action/`)
    const ChangeStateButton = (props: { action: string }) => <ChangeEntityStateButton {...props} changeStateAction={changeFakturaStateAction} labelKeyPrefix="DluhFakt.StavAction."/>

    return <Grid container spacing={1}>
        {(dluhFakt.stav === DluhFakturaStav.NEUHRAZENA || pripad?.stav === DluhPripadStav.NEUHRAZENY) && (
            <Grid item xs>
                <FormPartBox title="">
                    <Grid container justifyContent={"left"}>
                        <Grid item>
                            {dluhFakt.stav === DluhFakturaStav.NEUHRAZENA && <>
                                <ChangeStateButton action="uhradit"/>
                                <ChangeStateButton action="vyresitBezUhrady" />
                                <ChangeStateButton action="promlcet" />
                                <ChangeStateButton action="zamitnout" />
                            </>}
                            {(pripad?.stav === DluhPripadStav.NEUHRAZENY &&
                                    (dluhFakt.stav === DluhFakturaStav.UHRAZENA ||
                                        dluhFakt.stav === DluhFakturaStav.VYRESENO_BEZ_UHRAD ||
                                        dluhFakt.stav === DluhFakturaStav.PROMLCENO ||
                                        dluhFakt.stav === DluhFakturaStav.ZAMITNUTO)) &&  (
                                    <ChangeStateButton action="otevrit" />
                                )
                            }
                        </Grid>
                    </Grid>
                </FormPartBox>
            </Grid>
        )}
        <SectionLine title={t("DluhFakt.UdajeFaktury")}/>
        <Grid item xs={12} sm={6} lg={3}>
            <FormField
                name={"cislo"}
                title={t("DluhFakt.Cislo")}
                type={"text"}
                textFieldProps={{inputProps: {maxLength: 20}}}
                serverValidation={{translate: t}}
                fieldRef={cisloRef}
                onBlur={() => checkFaktNumberExist()}
                required/>
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
            <FormField name={"castka"} title={t("DluhFakt.Castka")} type={"number"} forceUpdateWhenDataChanged={["currency"]}  numberProps={{format:sizeFormat, constraint:longCenaConstraint}} serverValidation={{translate: t}} required/>
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
            <FormField title={t("Currency.Title")} name='currency' type='select' selectProps={currencySelectProps} serverValidation={{translate:t}} required/>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
            <FormField name={"datSplat"} title={t("DluhFakt.DatSplat")} type={FormInputType.DateTime}  serverValidation={{translate: t}} dateTimeOptions={{timeFormat: false}} required/>
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
            <FormField name={"ohlasUhr"} title={t("DluhFakt.OhlasUhr")} type={FormInputType.Checkbox} disabled serverValidation={{translate: t}}/>
        </Grid>
        <SectionLine title={t("DluhFakt.Poznamky")}/>
        <Grid item xs={12}>
            <FormField name={"duvodZamitnuti"} title={t("DluhFakt.DuvodZamitnuti")} type={"textarea"} textFieldProps={{inputProps: {maxLength: 510}}}/>
        </Grid>
        <Grid item xs={12}>
            <FormField name={"poznamka"} title={t("DluhFakt.Poznamka")} type={"textarea"} textFieldProps={{inputProps: {maxLength: 510}}}/>
        </Grid>
        <SectionLine title={t("DluhFakt.Stav")}/>
        <Grid item xs={12} sm={4} lg={2}>
            <FormField name={"kdyUhradil"} title={t("DluhFakt.KdyUhradil")} type={FormInputType.DateTime}/>
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
            <FormField name={"kdoUhradil"} title={t("DluhFakt.KdoUhradil")} type={FormInputType.Text} getValue={data => data.kdoUhradil?.displayName()}/>
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
            <FormField name={"kdyBezUhrady"} title={t("DluhFakt.KdyBezUhrady")} type={FormInputType.DateTime}/>
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
            <FormField name={"kdoBezUhrady"} title={t("DluhFakt.KdoBezUhrady")} type={FormInputType.Text} getValue={data => data.kdoBezUhrady?.displayName()}/>
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
            <FormField name={"stav"} title={t("DluhFakt.Stav")} type={FormInputType.Select} selectProps={stavSelectProps} disabled/>
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
            <FormField name={"datCas"} title={t("WithUserBase.DatCas")} type={FormInputType.DateTime} disabled/>
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
            <FormField name={"uzivatel"} title={t("WithUserBase.Uzivatel")} type={FormInputType.Text} disabled getValue={data => data.uzivatel?.displayName()}/>
        </Grid>
    </Grid>
}
