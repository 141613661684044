import React, {useEffect, useRef} from "react";
import {
    InvalidDuvodInzerat,
    Inzerat,
    InzeratFilter,
    InzeratViewDetail,
    LockableInzerat
} from "../../../../model/Inzerat";
import {Dial} from "../../../../raal_components/dial/Dial";
import {useTranslation} from "react-i18next";
import {FormButton, FormDialog, FormField, FormInputType} from "../../../../raal_components/form/Form";
import {
    createProvozovnaEmailyContactSelect,
    useCurrencySelectObj,
    useInzeratInvalDuvSelect,
    useRychlyContactSelect,
    useUserBasicSelect,
    useUserDispecerSelect
} from "../../../../raal_components/SelectOptions";
import {Grid} from "@material-ui/core";
import {FormNumberRange} from "../../../../../common/component/form/FormNumberRange";
import {FieldError} from "../../../../../common/component/form/ValidationError";
import {exist, isNumber} from "../../../../../common/utils/Util";
import numeral from "numeral";
import {cenaConstraint} from "../_vp/PrepravaAVozidlaShared";
import {globalStyles} from "../../../../context/ThemeModeContext";
import {useAppContext} from "../../../../context/AppContext";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {useData, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {SystemParameter, SystemParamKey} from "../../../../model/SystemParameter";
import {getCurrentTabId} from "../../../../../common/utils/unque-tab-id";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {INZERAT_CRUD_ENDPOINT} from "../../../../../common/constants";
import moment from "moment";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {useProvozovnaPdfLayout} from "../ProvozovnaCiselnik";
import {User} from "../../../../model/User";
import {FormDateRange, FormDateRangeOptions} from "../../../../../common/component/form/FormDateRange";
import {DataProps} from "../../../../raal_components/grid/MTExportContainer";
import {InzeratHistoryViewTab} from "../prohlizeni/InzerceViewCiselnik";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {useHashId} from "../../../../raal_components/controller/CodeBookController";
import {useSubscribe} from "use-pubsub-js";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {InzerceCiselnikFormNew} from "../_inzerce/InzerceCiselnikForm";
import {ComponentMode} from "../../../../routes";
import { ArchivPoznamka } from '../../../../model/ArchivPoznamka';
import { useNabidkaMultiCRUD } from "../_vp/NabidkaShared";
import {MultiColumnToolbarAction} from "../../../../raal_components/grid/DataGrid.d";
import { priceUnits } from '../../../../model/CommonTypes';

interface InzerceCiselnikProps {
    autofocus?:boolean
    isTabbed?:boolean
    mode?:ComponentMode
    /**
     * Defines custom base path.
     */
    basePath?: string
    minimumTableHeight?: number
    cacheGroup?: string
}

export const cenaFormat = "0,0.[000]";
const MAX_OFFER_EDITS = SystemParamKey.MAX_OFFER_EDITS;

export const useInzercePdfLayout = (user: User) => {
    const {t} = useTranslation();
    const {pdfLayout: provozovnaPdfLayout} = useProvozovnaPdfLayout(user);

    const pdfLayout = (data: any, fields: any, pageBreak: boolean, index: number, origin: any, extendedData?: any) => {
        const item = (field: string, unit?: string, marginLeft: number = 5, customField?: string) => {
            return <div style={{marginLeft: marginLeft}}><strong>{`${customField ?? fields[field]}: `}</strong><span>{!data[field] ? '-' : `${data[field]} ${unit ?? ''}`}</span></div>
        }

        const itemCustom = (title: string, data: string, checkString?: boolean, marginLeft: number = 5) => {
            //Test string length
            if (checkString && data && data.length > 25) {
                const stringPartsArray = data.split(' ');
                for (let i = 0; i < stringPartsArray.length; i++) {
                    if (stringPartsArray[i].length > 25) {
                        const parts = stringPartsArray[i].match(/.{1,25}/g);
                        stringPartsArray[i] = parts.join(' ');
                    }
                }

                data = stringPartsArray.join(' ');
            }
            return <div style={{marginLeft: marginLeft}}><strong>{title ? `${title}: ` : null}</strong><span>{data}</span></div>
        }
        const provozovna = origin?.uzivatel?.provozovna;

        return (<div className={'font-9'} key={'div_' + index}>
            {!pageBreak ? <table data-pdfmake="{'widths':['*','*']}" style={{marginBottom: 10}}>
                <tr>
                    <td rowSpan={2} style={{border: 0}}><img src={'logo'} style={{width: 200}} alt={"img"} /></td>
                    <td style={{border: 0, textAlign: 'right'}}>{`${t('Default.PdfExportedUser')}: ${user.jmeno ?? '-'}`}</td>
                </tr>
                <tr>
                    <td style={{border: 0, textAlign: 'right'}}>{`${t('Default.PdfExportedDate')}: ${moment().format('L LT')}`}</td>
                </tr>
            </table> : null}
            <hr/>
            <div style={{textAlign: 'center'}}>
                <h6>{t('Default.PdfHeader').replace("{0}", "inzerce").replace("{1}", origin.nabidkaId ?? origin.inzeratId ?? origin.id ?? '')}</h6>
            </div>
            <hr/>
            {item('popis')}
            {itemCustom(t('Inzerce.cena'), data.cena ? `${data.cena} ${data.currency}` : null)}
            {itemCustom(t('Provozovna.Kod'), provozovna?.kod)}
            {itemCustom(t('Inzerce.rychlyKontakt'), data.rychlyKontakt)}
            {itemCustom(t('Inzerce.zadavatel'), data.uzivatel)}
            {item('dispecer')}
            {itemCustom(t('Inzerce.datIns'), origin?.modifiedOn ? moment(origin.modifiedOn).format('L LT') : null)}
			{extendedData?.list &&
		        <table data-pdfmake="{'widths':['*','*','*']}">
			        <tr>
				        <th>{t('Poznamka.poznamka')}</th>
				        <th>{t('Poznamka.uzivatel')}</th>
				        <th>{t('Poznamka.datIns')}</th>
			        </tr>
					{extendedData?.list?.map((poznamka: ArchivPoznamka, index: number) => {
						return (
							<tr key={index}>
								<td>{poznamka.poznamka}</td>
								<td>{poznamka.uzivatel?.jmeno}</td>
								<td>{moment(poznamka.datIns).format('L LT')}</td>
							</tr>
						)
					})}
		        </table>
			}
            {provozovnaPdfLayout(data, fields, pageBreak, index, origin)}
        </div>)
    }

    return {pdfLayout}
}

export const inzerceExportableFields: DataProps[] = [
    {
        type: ["csv", "xls", "pdf"],
        fields: ["popis", "rychlyKontakt", "cena", "currency", "dispecer", "provozovna", "uzivatel", "modifiedOn", "invalDuv"]
    },
    {
        type: "xml",
        fields: ["id", "popis", "rychlyKontakt", "cena", "currency", "dispecer", "provozovna", "uzivatel", "modifiedOn", "invalDuv"]
    }
]

export const inzerceAdminExportableFields: DataProps[] = [
    {
        type: ["csv", "xls", "pdf"],
        fields: ["popis", "rychlyKontakt", "cena", "currency", "dispecer", "provozovna", "uzivatel", "datIns", "modifiedOn", "invalDuv"]
    },
    {
        type: "xml",
        fields: ["id", "popis", "rychlyKontakt", "cena", "currency", "dispecer", "provozovna", "uzivatel", "datIns", "modifiedOn", "invalDuv"]
    }
]

/**
 * TODO - Přepsáno do New, ale uvnitř není stejná logika jako v ExtendedViewNew, bude třeba ještě projít a sloučit.
 * @param props
 * @constructor
 */
export default function InzerceCiselnik(props: InzerceCiselnikProps = {autofocus: false}) {
    const {t} = useTranslation();
    const userSelectProps = useUserBasicSelect({isClearable:true});
    const currencySelectProps = useCurrencySelectObj({isClearable:true});
    const invalDuvSelect = useInzeratInvalDuvSelect({isClearable: true});
    const dateKey = "inzerat-insert-browse-date";
    const rangeCenaSharedOptions = {numberProps: {format:cenaFormat, constraint:cenaConstraint}};
    const {user} = useAppContext();
    const rychlyKontaktSelect = useRychlyContactSelect({isClearable: true, params: {provozovnaId: user.provozovna.id}, autoOpenDisabled: true, inputType: "phone"});
    const [showConfirm] = useConfirmDialog();
    const {pdfLayout} = useInzercePdfLayout(user);
    const dtGrid = useRef<DataGridExposed<LockableInzerat, InzeratFilter>>();
    const nabidkaMultiCRUD = useNabidkaMultiCRUD<Inzerat>({endpoint: INZERAT_CRUD_ENDPOINT});
    const emailSelect = createProvozovnaEmailyContactSelect({isClearable: true, params: {provozovnaId: user.provozovna.id}, autoOpenDisabled: true})

    const dialog: FormDialog = {
        body: t("Default.DeleteText"),
        title: t("Default.DeleteTitle"),
        buttons: { confirm: t("Buttons.Delete") }
    };
    const [maxOfferEdits] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === MAX_OFFER_EDITS));
    const getEditCounter = (pocetEditaci?: number) => {
        if (!isNumber(maxOfferEdits.value)) return 0;
        return Math.max(Number.parseInt(maxOfferEdits.value) - (pocetEditaci??0), 0);
    }
    const editCounterDisabled = Number.parseInt(maxOfferEdits.value) < 0;
    const id = useHashId();
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.I);

    const handler  = (token?:string | symbol) => {
        if (token === 'dataContextChanged') {
            showSnack({
                title: t('DataContextChanged.title'),
                severity: "warning",
                action: [{title: t('Buttons.Refresh'), onClick: () => window.location.reload()}],
                anchorOrigin: {vertical: "top", horizontal: "center"},
                preventAutoHide: true
            });
        }
    }

    const { unsubscribe, resubscribe } = useSubscribe({ token: 'dataContextChanged', handler })

    useEffect(() => {
        resubscribe();
        return () => {
            unsubscribe();
        }
        // eslint-disable-next-line
    }, [])

    return <Dial<LockableInzerat, InzeratFilter, Inzerat>
        mode={props.mode}
        isTabbed={props.isTabbed}
        tabDetailUrl= {props.basePath ? props.basePath : undefined}
        lastBrowsedDateKey={dateKey}
        isModal={!exist(id)}
        filterClazz={InzeratFilter}
        beforeSend={(data) => {
            if (data?.dispecer && Number(data.dispecer.id) === user.id) data.dispecer = null;
        }}
        getModificationDate={data => data.modifiedOn}
        focusFieldWhenMounted
        saveAndNextButton={!id}
        gridRef={dtGrid}
        config={{
            cache: {group: props.cacheGroup},
            getToolbarMultiActions: (showMultiAction: (state: boolean) => void) => {
                return [nabidkaMultiCRUD.getRefreshAction(showMultiAction), nabidkaMultiCRUD.getRemoveAction(showMultiAction)] as unknown as MultiColumnToolbarAction<any>[]
            },
            disableExtendedFilterButtons: true,
            minimumTableHeight: props.minimumTableHeight,
            lockSupport: {enabled: true, stompPath: "Inzerat"},
            autofocus: props.autofocus,
            stomp:{
                topic: `/provozovna/${user.provozovna.kod}/crud-inzerat`,
                toggleable: true,
                allowStompUiUpdates: true
            },
            watchChanges: true,
            options: {
                rowStyle: (data: any) => {
                    if(exist(data.lockUserInfo) && (data.lockUserInfo.login!==user.login || data.tabId!==getCurrentTabId())) return globalStyles.rowStyleLocked;

                    if (data.invalDuv===InvalidDuvodInzerat.DUPLICITA) return globalStyles.rowStyleDuplicated;

                    if (data.valid) return undefined;

                    return globalStyles.rowStyleAlert;
                }
            },
            defaultHiddenColumns: ["uzivatel", "invalDuv", "modifiedOn"],
            templatesEnabled: true,
            exportConfig: {
                exportable: true,
                endpoint: INZERAT_CRUD_ENDPOINT,
                fileName: "export_inzerce",
                translationPrefix: ['Inzerce', 'User', 'Preprava'],
                formats: ["pdf", "xls", "csv", "xml"],
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                exportableProps: inzerceExportableFields,
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod
                            }
                        }
                    }
                 ],
                excludedProps: [
                    {
                        type: ["csv", "xls"],
                        fields: ["datIns"]
                    }
                ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            clazz: LockableInzerat,
            tableTitle: t("Dials.Advertising"),
            endpoint: INZERAT_CRUD_ENDPOINT,
            filtering: true,
            rowFiltering:false,
            // @ts-ignore
            disableRowClick: data => data && (data.invalDuv===InvalidDuvodInzerat.DUPLICITA || data.invalDuv===InvalidDuvodInzerat.ZNEPLATNENO_OPERATOREM || (!editCounterDisabled && getEditCounter(data.pocetEditaci) < 1) || (exist(data.lockUserInfo) && (data.lockUserInfo!.login!==user.login || data.tabId!==getCurrentTabId()))),
            validate: (data: Inzerat) => {
                const errors = [] as FieldError [];
                const requiredFields = ["popis"];
                requiredFields.forEach(f => {
                    if (!exist((data as any)[f])) {
                        errors.push(FieldError.Create(t("FormLocalization.FieldMessages.FieldIsRequiredValue").replace("{0}", t(f)), false, f))
                    }
                });
                if(data.cena && !data.currency)
                    errors.push(FieldError.Create(t("Inzerce.CurrencyRequiredWhenPrice"), false, "currency"));

                if (exist(data.cena) && data.cena <= 0) errors.push(FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "cena"));

                return errors;
            },

            columns: [
                {
                    title: t("Inzerce.AdText").toUpperCase(),
                    required: true,
                    field: 'popis',
                    filterProps:() => ({type:FormInputType.Text}),
                    editProps: () => ({type: FormInputType.Text, textFieldProps: {inputProps: {maxLength: 70}}}),
                    cellStyle: {minWidth: 200},
                    headerStyle: {minWidth: 200},
                },
                {
                    title: t("Default.Telefon").toUpperCase(),
                    field: 'rychlyKontakt',
                    filterProps:() => ({type:FormInputType.Text}),
                    editProps: () => ({type: FormInputType.Select, selectProps: rychlyKontaktSelect, autoSelectFirstValueOnTab: true}),
                    cellStyle: {minWidth: 160, maxWidth: 160},
                    headerStyle: {minWidth: 160, maxWidth: 160},
                },
                {
                    title:t('Default.Email').toUpperCase(),
                    field:"email",
                    filterProps: () => ({type: FormInputType.Text}),
                    editProps:() => ({type: FormInputType.Select, selectProps: emailSelect, autoSelectFirstValueOnTab: true}),
                    sorting: false,
                },
                {
                    title: t("Inzerce.Price").toUpperCase(),
                    field: 'cena',
                    render:(data:Inzerat) => data.cena && numeral(data.cena).format(cenaFormat),
                    filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"cenaRange", customComponentOptions: rangeCenaSharedOptions}),
                    editProps: () => ({type: FormInputType.Number, numberProps:{format:cenaFormat, constraint:cenaConstraint},
                        onChange:(value, data, setData) => {
                            data.cena = value;
                            if (!value && data.currency) {
                                data.currency = null;
                            }
                            setData(data);
                        }
                    }),
                    cellStyle: {minWidth: 70, maxWidth: 70, textAlign:"right"},
                    headerStyle: {minWidth: 70, maxWidth: 70},
                },
                {
                    title: t("Currency.Title").toUpperCase(),
                    field: 'currency',
                    render:(row:Inzerat)=> row.currency?.name ?? '',
                    filterProps:() => ({type: FormInputType.Select, selectProps: currencySelectProps}),
                    editProps: (data) => ({type: FormInputType.Select, selectProps: currencySelectProps, disabled: !data?.cena, autoSelectFirstValueOnTab: true}),
                },
				{
					title: t("Default.Jednotka").toUpperCase(),
					field: 'jednotka',
					render: (row) => row.jednotka ?? '',
					filterProps: () => ({ type: FormInputType.Select, selectProps: { options: priceUnits } }),
					editProps: (data) => ({ type: FormInputType.Select, selectProps: { options: priceUnits}, disabled: !data?.cena }),
					cellStyle: {minWidth: 40, maxWidth: 40},
					headerStyle: {minWidth: 40, maxWidth: 40},
				},
                {
                    title: t("User.Title").toUpperCase(),
                    field: 'uzivatel',
                    render:(row:Inzerat)=> `${row.uzivatel?.login ?? ''} ${row?.uzivatel.jmeno ? `(${row.uzivatel.jmeno})` : ""}`,
                    filterProps:() => ({type: FormInputType.Select, selectProps: userSelectProps}),
                    cellStyle: {minWidth: 180},
                    headerStyle: {minWidth: 180}
                },
                {
                    title: t("PVI.Modified").toUpperCase(),
                    field: 'modifiedOn',
                    defaultSort:"desc",
                    render: data => data.modifiedOn?.format("L LT")
                },
                {
                    title: t("PVI.InvalDuv").toUpperCase(),
                    field: 'invalDuv',
                    filterProps:() => ({type: FormInputType.Select, selectProps: invalDuvSelect}),
                    render: data => exist(data.invalDuv) ? invalDuvSelect.formatOption(data.invalDuv).label : ''
                }
            ]
        }}
        isDetailReadOnly={data => data && (data.invalDuv===InvalidDuvodInzerat.DUPLICITA || data.invalDuv===InvalidDuvodInzerat.ZNEPLATNENO_OPERATOREM || (exist(data.lockUserInfo) && (data.lockUserInfo!.login!==user.login || data.tabId!==getCurrentTabId())))}
        isDetailLock={data => data && (exist(data.lockUserInfo) && (data.lockUserInfo!.login!==user.login || data.tabId!==getCurrentTabId()))}
        hideEditButton={true}
        hideNewButtonOnEdit={true}
        formDisabled={(edited, data) => data && (data.invalDuv===InvalidDuvodInzerat.DUPLICITA || data.invalDuv===InvalidDuvodInzerat.ZNEPLATNENO_OPERATOREM)}
        crudConfig={{addEnabled:true, editEnabled:true, removeEnabled: true, refreshEnabled: true}}
        hideCustomButtons={'None'}
        showHideConnectedCols={[{cols: ["currency", "cena"]}]}
        customButtons={(edited, data, changed, disabled) => edited &&
                [!disabled&&<FormButton key={"update"}
                            skipBlock
                            type={"update"}
                            main={true}
                            tooltip={!editCounterDisabled && getEditCounter(data.pocetEditaci) < 1 ?
                                t("PVI.tooltip.UpdateDisabledButton"):
                                t("PVI.tooltip.UpdateButton")
                            }
                            disabled={(!editCounterDisabled && getEditCounter(data.pocetEditaci) < 1) || (!changed && data.invalDuv !== InvalidDuvodInzerat.OK && data.invalDuv !== InvalidDuvodInzerat.UZIVATEL_POZASTAVEN)}
                            onSend={() => {
                                return {
                                    modifyUrl: (url: string) => `${url}/${data.id}`,
                                    skipValidation: !changed,
                                    requestInit: !changed ? {
                                        method: "PUT",
                                        headers: {'obnova': 'true'},
                                        body: null
                                    } : null
                                }
                            }}>
                    {!changed ?
                        (editCounterDisabled ? t("PVI.Refresh") : t("PVI.RefreshRecord", {value: getEditCounter(data.pocetEditaci)})) :
                        (editCounterDisabled ? t("DialDefaults.UpdateRecord") : t("PVI.UpdateRecord", {value: getEditCounter(data.pocetEditaci)}))
                    }
                </FormButton>,
                <FormButton key={"delete"}
                            skipBlock
                            type={"remove"}
                            confirmation={{showDialog: showConfirm, dialog: dialog}}
                            onSendWithConfirmation={() =>{
                                return {
                                    modifyUrl: (url: string) => `${url}/${data.id}`,
                                    skipValidation: true,
                                    requestInit: {
                                        method: "DELETE",
                                        body: null
                                    }
                                }
                            }}>
                    {t("Buttons.Delete")}
                </FormButton>
                ]}
        layoutForm={() =><InzerceCiselnikFormNew  /> }
        tabs={[{
                    title: t("Inzerce.History"),
                    render: ()=><TabHelperNew<InzeratViewDetail> render={(data, edited) => edited&&<InzeratHistoryViewTab inzerat={data} zadani/>}/>,
                    disabled: (data, edited) => !edited
                }
        ]}
        layoutFilter={() => <InzerceCiselnikFilter {...{lastBrowseDateDataKey: dateKey}}/>}
    />
}
// eslint-disable-next-line
function InzerceCiselnikFilter({}:{lastBrowseDateDataKey:string}) {
    const {t} = useTranslation();
    const dispecerSelectProps = useUserDispecerSelect({isClearable:true});
    const userSelectProps = useUserBasicSelect({isClearable:true});
    const currencySelectProps = useCurrencySelectObj({isClearable:true});
    const invalDuvSelect = useInzeratInvalDuvSelect({isClearable: true});
    const rangeCenaSharedOptions = {numberProps: {format:cenaFormat, constraint:cenaConstraint}};

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField title={t("Inzerce.Description")} name='popis' type='text'/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField title={t("Default.Telefon")} name="rychlyKontakt" type="text"/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField title={t("Inzerce.Dispecer")} name='dispecer' type='select' selectProps={dispecerSelectProps}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <FormField title={t("PVI.InvalDuv")} name='invalDuv' type='select' selectProps={invalDuvSelect}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <FormField title={t("User.Title")} name='uzivatel' type='select' selectProps={userSelectProps}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
                <FormField title={t("Currency.Title")} name='currency' type='select' selectProps={currencySelectProps}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
                <FormField title={t("Inzerce.Price")} name="cenaRange" type="Custom" customComponent={FormNumberRange} customComponentOptions={rangeCenaSharedOptions}  />
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
                <FormField<FormDateRangeOptions> title={t("PVI.Modified")} name={"modifiedOnRange"} type={"Custom"}
                                                 customComponent={FormDateRange}
                                                 customComponentOptions={{spacing: 1, timeFormat: false}}/>
            </Grid>
        </Grid>
    )
}
