import {GenericMap} from "../../../index.d";
import {CustomDateTimePickerLocalization} from "../../../common/component/CustomDateTimePicker";
import {FieldError, ValidationError} from "../../../common/component/form/ValidationError";
import {CSSProperties} from "react";
import {FormButtonClickEvent} from "./FormButton";
import {FormFieldOverrides} from "./FormField";
import {Mapper} from "../../../common/utils/objectmapper/Mapper";
import {FormComponent} from "./Form";

export type FormLocalization = {
    DataSaved: string,
    DataSavedFailed: string,
    DataRemoved: string,
    DataRemovedFailed: string,
    ServerError: string,
    ValidationError: string,
    FieldMessages: FieldMessages
    DateTimePicker:CustomDateTimePickerLocalization
}
export type FieldMessages = {
    FieldIsRequired: string
    FieldIsRequired2: string
    CoordinatesMissing:string
}&GenericMap

export class FormHttpResponse<T> {
    response: Response;
    status: FormStatus;
    data: T;
    validationError: ValidationError;
    extra?: any;
}

export enum FormStatus {
    Success, Error, Validation, Skip, Nothing, Custom
}

export type ResultType<Data> = Promise<FormStatus | FormHttpResponse<Data>>

export type HandlerActions<Data> = {
    handleSend: HandleSendAction<Data>
}

export type HandleSendAction<Data> = (continueAction: () => Promise<any>, progressFunction:(showProgress: boolean) => void, data: Data) => void

export interface HiddenFormProps {
    simpleLabel?: boolean;
    inputGroupEnabled?: boolean;
}

export interface FormProps<Data> extends HiddenFormProps {
    blockSaveUntilChange?:boolean,
    style?: CSSProperties;
    method?: string;
    isEndpoint?: boolean,
    suppressToastMessage?:boolean,
    requestInit?:RequestInit,
    rest?:boolean,
    showLoading?: boolean;
    onPropsChanged?(form:FormComponent<Data>):void
    url?: string;
    data: Data
    origin?: Data
    beforeSend?:(data: Data) => void
    onSend?: (form: FormComponent<Data>, event?: FormButtonClickEvent) => ResultType<Data>
    onResult?: (result: FormHttpResponse<Data>, event:FormButtonClickEvent) => void
    onChange?: (form: FormComponent<Data>, isEqual?: boolean) => void,
    localization:FormLocalization,
    validate?:(data:Data)=>FieldError[],
    validateOnLoad?:boolean,
    focusFieldWhenMounted?:boolean,
    focusedFieldWhenMounted?:string,
    updateOriginToo?:boolean,
    createParameters?: (data: Data) => any
    autofillValues?:boolean;
    formFieldOverrides?:FormFieldOverrides[],
    disabled?:boolean,
    isReadOnly?:boolean,
    excludeFieldsForDirtyCheck?:string[],
    excludeFieldsForIntegerCheck?: string[],
    excludeFieldsForTimeCheck?: string[],
    saveResponseSetter?: (result: FormHttpResponse<Data>, mapper: Mapper<Data>, json: any) => void,
    filterForm?: boolean,
    childForm?: boolean,
    onFilterButtonClick?:() => void,
    onFilterEscapePressed?:() => void,
    disableFocusForSaveButton?:boolean,
    handlers?: HandlerActions<Data>,
    className?: string
    stopImmediatePropagation?: boolean
    onDataChanged?: (form: FormComponent<Data>, isEqual: boolean) => void
    saveAndNextButton?: boolean
    preventCloseAfterSave?: boolean
	disableKeyEvents?: boolean
}

export type FormState = {
    errors: ValidationError;
	onKeyDisabled: boolean;
}
