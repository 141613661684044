import {AssetCache} from "../../AssetCache";
import {GenericMap} from "../../index.d";

export const lang:GenericMap = {
    cs: {
        name: "Čeština",
        icon:AssetCache.Image.CZ
    },
    sk: {
        name: "Slovenčina",
        icon:AssetCache.Image.SK
    },
    en: {
        name: "English",
        icon:AssetCache.Image.EN
    },
    de: {
        name: "Deutsch",
        icon:AssetCache.Image.DE
    },
    pl: {
        name: "Polski",
        icon:AssetCache.Image.PL
    }
};

export default lang;

// export default () => {
//     let props:GenericMap<any> = {};
//     Object.keys(lang).forEach(key=>{
//        props[key] = lang[key].props;
//     });
//     return props;
// };