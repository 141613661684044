import {Button} from "@material-ui/core";
import React, {useMemo} from "react";
import {useTranslation} from 'react-i18next';
import {getGoogleMapsPointUrl, getGoogleMapsTraceUrl} from "../../../common/utils/LinkUtils";
import {Geometry} from "../../model/Geometry";

export const useGoogleMapsAddressDirectionUrl = (from:string, to?:string, waypoints?: string[]) => useMemo(()=>getGoogleMapsTraceUrl(from, to, waypoints), [from, to, waypoints]);
const useGoogleMapsGeometryUrl = (gps: Geometry) => useMemo(()=> getGoogleMapsPointUrl(gps), [gps]);

interface GoogleMapsButtonProps {
    from?:string
    to?:string
    waypoints?: string[]
}

export function GoogleMapsButton(props: GoogleMapsButtonProps) {
    const url = useGoogleMapsAddressDirectionUrl(props.from, props.to, props.waypoints);
    const {t} = useTranslation();

    return (
        <Button variant="contained" color="primary" href={url} target={"_blank"} disabled={url === null}>
            {t("GoogleMaps.Title")}
        </Button>
    );
}

export function GoogleMapsGeometryButton({gps}:{gps?: Geometry}) {
    const url = useGoogleMapsGeometryUrl(gps);
    const {t} = useTranslation();

    return (
        <Button fullWidth variant="contained" href={url} target={"_blank"} disabled={url === null}>
            {t("GoogleMaps.Title")}
        </Button>
    );
}
