import React, {FC, forwardRef, MutableRefObject, ReactNode, Ref, useEffect, useState} from "react";
import {
    BaseDateTimePickerProps,
    DateTimePicker,
    KeyboardDatePicker,
    KeyboardDatePickerProps,
    KeyboardDateTimePicker,
    KeyboardDateTimePickerProps,
    KeyboardTimePicker,
    KeyboardTimePickerProps,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {IconButton, InputAdornment} from "@material-ui/core";
//pouziva se date-fns protoze z momentutils jsem to nerozjel
import DateFnsUtils from '@date-io/date-fns';
import * as Locales from 'date-fns/locale';
import {useLocale} from "../../web/context/LocaleContext";
import moment, {Moment} from "moment";
import {PureDateInputProps} from "@material-ui/pickers/_shared/PureDateInput";
import {GenericMap} from "../../index.d";
import {WithPureInputProps} from "@material-ui/pickers/Picker/makePickerWithState";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import {useDidMount, useMountedEffect} from "./hooks/SharedHooks";
import {invoke} from "../utils/Invoke";
import {exist} from "../utils/Util";
import {FormDateTimePicker} from "./form/FormDatetimePicker";

export interface DatePickerExposed {
    focusTextField: () => void
}

export type CustomDateTimePickerLocalization = {
    cancelLabel: string
    clearLabel: string
    emptyLabel: string
    invalidLabel: string
    okLabel: string
    todayLabel: string
    minDateMessage: string
    maxDateMessage: string
    maxDate: string
    minDate: string
    invalidDate: string
    disablePast: string
    shouldDisableDate: string
    disableFuture: string
};

const CalendarState = {
    UNFOCUSED: "UNFOCUSED",
    ACCEPT: "ACCEPT",
    CANCELED: "CANCELED",
    FOCUSED: "FOCUSED",
    NOT_SET: "NOT_SET"
};

export type CustomDateTimePickerProps = {
    label: string
    value?: Moment
    /** if false, the method onChange is not called if the date is invalid */
    triggerChangeIfInvalid?: boolean
    onChange?: (m: Moment) => void
    timeFormat?: boolean
    dateFormat?: boolean
    /** Disable picker and text field */
    disabled?: boolean
    /** Make picker read only */
    readOnly?: boolean
    clearable?: boolean
    textFieldProps?: Omit<PureDateInputProps, "openPicker" | "inputValue">
    dateTimePickerProps?: BaseDateTimePickerProps,
    customInputProps?: Omit<PureDateInputProps, "openPicker" | "inputValue">,
    keyboardDateTimeProps?: Omit<WithPureInputProps, "value" | "onChange"> & { mask?: string, maskChar?: string },
    onClose?:()=>void,
    initialValue?: Moment,
    width?: number,
    localization?: CustomDateTimePickerLocalization,
    onError?: (e: string) => void,
    error?:string,
    onMouseDown?: () => void,
    onMouseUp?: () => void,
    onFocus?: (userClick?: boolean) => void,
    onBlur?: () => void,
    raalRowStyle?:boolean,
    raalInlineStyle?: boolean
    wrapTextField?:(textField:ReactNode)=>void,
    onInteractStart?:()=>void,
    onInteractEnd?:()=>void,
    onDisableActionKeys?:(isDisabled: boolean, focusNext?: boolean)=>void,
    autoOpen?: boolean,
    focusNext?: boolean,
    okExtendedLabel?: string,
    defaultValue?: Moment,
    inputClassName?: string
    componentRef?: MutableRefObject<DatePickerExposed>
    addEndOfDay?: boolean
    autoSelectFirstValueOnTab?: boolean
    datePattern?: string
    timePattern?: string
    dateForCompare?: Moment
    onKeyDown?:(e: React.KeyboardEvent<HTMLInputElement>) => void
    clearIfDisabled?:boolean
    skipValidation?: boolean,
};
function RaalRowDatetimePicker({
                                   value,
                                   onChange,
                                   timeFormat = true,
                                   dateFormat = true,
                                   dateTimePickerProps,
                                   textFieldProps,
                                   customInputProps,
                                   ...props2
                               }: CustomDateTimePickerProps, ref:Ref<any>) {
    const [open, setOpen] = useState(false);
    const resolveRaalDateFormat = () => {
        const m = moment(value);
        if(m.isValid() && Boolean(value)) {
            return m.get("date");
        }
        return "";
    };
    const [inputValue, setInputValue] = useState(resolveRaalDateFormat());
    const {locale} = useLocale();
    useMountedEffect(()=>{
        setInputValue(resolveRaalDateFormat())
    }, [value]);
    //@ts-ignore
    const {onMouseLeave, onMouseOver, onTouchStart, title, ...props} = props2;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={(Locales as GenericMap)[locale]}>
            {

                <div {...{onMouseLeave, onMouseOver, onTouchStart, title}} ref={ref}>
                    <DateTimePicker
                        ampm={false}
                        value={value?.toDate()}
                        onChange={(date?: Date)=>{
                            const m = moment(date);
                            if (m.isValid() || date == null || props.triggerChangeIfInvalid) {
                                onChange && onChange(m);
                            }
                        }}
                        clearable={props.clearable}
                        open={open}
                        onOpen={props.onInteractStart}
                        onClose={() => {
                            invoke(props.onInteractEnd);
                            setOpen(false);
                            props.onClose&&props.onClose();
                            invoke(props.onDisableActionKeys,false)
                        }}
                        {...dateTimePickerProps}
                        invalidDateMessage={props?.localization?.invalidDate}

                        cancelLabel={props?.localization?.cancelLabel}
                        clearLabel={props?.localization?.clearLabel}
                        emptyLabel={""}
                        okLabel={props?.localization?.okLabel}
                        todayLabel={props?.localization?.todayLabel}
                        minDateMessage={props?.localization?.minDateMessage}
                        initialFocusedDate={props.initialValue?.toDate()}
                        helperText={props.error ?? textFieldProps.helperText}
                        error={typeof props.error !== "undefined"}
                        {...textFieldProps}
                        InputProps={{
                            ...customInputProps?.InputProps,
                            onBlur: (e:React.FocusEvent<HTMLInputElement>) => {
                                if (customInputProps?.InputProps?.onBlur) {
                                    customInputProps?.InputProps?.onBlur(e);
                                }
                                props.onBlur && props.onBlur();
                            },
                            onFocus: (e:React.FocusEvent<HTMLInputElement>) => {
                                if (customInputProps?.InputProps?.onFocus) {
                                    customInputProps?.InputProps?.onFocus(e);
                                }
                                props.onFocus && props.onFocus();
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => {
                                        setOpen(true)
                                        invoke(props.onDisableActionKeys,true)
                                    }} size={"small"}>
                                        <CalendarTodayIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                            error:typeof textFieldProps.error !== 'undefined',
                            style:{width: props.width},
                            onMouseUp:props.onMouseUp,
                            onMouseDown:props.onMouseDown,
                            onKeyDown: props.onKeyDown,
                            className: value ? props.inputClassName : ''
                        }}
                        inputProps={{
                            type:"text",
                            value:inputValue,
                            onChange:e=>{
                                let value = (e.target as HTMLInputElement).value?.replace(/\D/g,'');
                                if(value?.length > 2) {
                                    value = value.substr(0,1);
                                }
                                setInputValue(value);
                            },
                            readOnly:false
                        }}
                        onError={(error) => {
                            props.onError && props.onError(error as string);
                        }}
                        variant={"dialog"}
                    />
                </div>
            }
        </MuiPickersUtilsProvider>
    );
}

function Datetimepicker({
                            value,
                            onChange,
                            timeFormat = true,
                            dateFormat = true,
                            dateTimePickerProps,
                            textFieldProps,
                            customInputProps,
                            ...props2
                        }: CustomDateTimePickerProps, ref:Ref<any>) {

    const resolveState = (value: Date, trigger: boolean): { value: Date, triggerChangeFunction: boolean } => {
        return {value: value ?? null, triggerChangeFunction: trigger};
    };
    const [state, setState] = useState<{ value: Date, triggerChangeFunction: boolean }>(() => resolveState(value?.toDate(), false));
    const [openState, setOpenState] = useState(false);
    const [calendarState, setCalendarState] = useState(CalendarState.UNFOCUSED);
    const {locale} = useLocale();
    const handleDateChange = (date?: Date) => {
        if (date === null) {
            setState(resolveState(null, true));
        } else {
            setState(resolveState(date, true));
        }
    };


    useMountedEffect(() => {
        if (state.triggerChangeFunction) {
            if (state.value) {
                const m = moment(state.value);
                if (m.isValid() || props.triggerChangeIfInvalid) {
                    onChange && onChange(m);
                }
            } else {
                onChange && onChange(null);
            }
        }
    }, [state]);
    useMountedEffect(() => {
        setState(resolveState(value?.toDate(), false));
    }, [value]);

    useDidMount(()=>{
      if (exist(props.defaultValue)) setState(resolveState(props.defaultValue.toDate(), true));
    })

    useEffect(()=> {
        if (props2.autoOpen) {
            switch (calendarState) {
                case CalendarState.FOCUSED:
                    setOpenState(true);
                    break;
                case CalendarState.ACCEPT:
                    if (exist(state.value)) setOpenState(false);
                    break;
            }
        }
    }, [calendarState, state.value, props2.autoOpen]);

    // Nastavení parametrů po otevření kalendáře
    useEffect(()=> {
        if (openState) onOpen();
        if (!openState && calendarState !== CalendarState.NOT_SET) {
            if (calendarState !== CalendarState.ACCEPT && calendarState !== CalendarState.UNFOCUSED) setCalendarState(CalendarState.CANCELED);
            if (calendarState !== CalendarState.UNFOCUSED) onClose();
            if (calendarState === CalendarState.ACCEPT) setCalendarState(CalendarState.UNFOCUSED);
        }
        else {
            setCalendarState(CalendarState.FOCUSED)
        }
        // eslint-disable-next-line
    },[openState])

    const onOpen = () => {
        invoke(props.onInteractStart);
        invoke(props.onDisableActionKeys,true);
    };

    const onClose = () => {
        invoke(props.onInteractEnd)
        invoke(props.onDisableActionKeys,false, props.focusNext);
    }

    if (!timeFormat && !dateFormat) {
        return <>timeFormat and dateFormat cannot be false simultaneously</>;
    }
    const datePattern = moment.localeData().longDateFormat('L').replace(/D/gi, "d").replace(/Y/gi, "y");
    const timePattern = "HH:mm";
    let keyboardPattern = `${datePattern} ${timePattern}`;
    let Component: FC<KeyboardDateTimePickerProps> | FC<KeyboardTimePickerProps> | FC<KeyboardDatePickerProps> = KeyboardDateTimePicker;
    if (!timeFormat) {
        Component = KeyboardDatePicker;
        keyboardPattern = datePattern;
    }
    if (!dateFormat) {
        Component = KeyboardTimePicker;
        keyboardPattern = timePattern;
    }
    //@ts-ignore
    const {onMouseLeave, onMouseOver, onTouchStart, title,  ...props} = props2;

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={(Locales as GenericMap)[locale]}>
                {
                    <div {...{onMouseLeave, onMouseOver, onTouchStart, title}} ref={ref}>
                        <Component
                            placeholder={keyboardPattern}
                            format={keyboardPattern}
                            ampm={false}
                            value={state.value}
                            {...(!props.autoOpen ?
                                {onOpen: onOpen, onClose: onClose} :
                                {open: openState, onClose: () => setOpenState(false)}
                                )
                            }
                            onChange={handleDateChange}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                            label={props.label}
                            clearable={props.clearable}
                            {...props.keyboardDateTimeProps}
                            {...{
                                ...customInputProps, ...{
                                    InputProps: {
                                        onBlur: (e) => {
                                            if (state.value && !moment(state.value).isValid()) {
                                                handleDateChange();
                                            }
                                            customInputProps?.InputProps?.onBlur && customInputProps.InputProps.onBlur(e);
                                            props.onBlur && props.onBlur();
                                        },
                                        onFocus: (e) => {
                                            customInputProps?.InputProps?.onFocus && customInputProps.InputProps.onFocus(e);
                                            props.onFocus && props.onFocus();
                                            if (props.autoOpen)
                                            {
                                                if  (calendarState !== CalendarState.ACCEPT) setCalendarState(CalendarState.FOCUSED)
                                            }
                                        },
                                        onClick: () => {
                                            if (props.autoOpen){
                                                setOpenState(true);
                                            }
                                        },
                                        className: state.value ? props.inputClassName : ''
                                    }
                                }
                            }}
                            {...{...textFieldProps, onMouseDown: props.onMouseDown, onMouseUp: props.onMouseUp}}
                            {...dateTimePickerProps}
                            invalidDateMessage={props?.localization?.invalidDate}
                            // helperText={textFieldProps.error}
                            cancelLabel={props?.localization?.cancelLabel}
                            clearLabel={props?.localization?.clearLabel}
                            emptyLabel={""}
                            helperText={props.error ?? textFieldProps.helperText}
                            error={typeof props.error !== "undefined"}
                            invalidLabel={props?.localization?.invalidLabel}
                            okLabel={props?.okExtendedLabel ? `${props?.localization?.okLabel} ${props.okExtendedLabel}` : `${props?.localization?.okLabel}`}
                            todayLabel={props?.localization?.todayLabel}
                            minDateMessage={props?.localization?.minDateMessage}
                            maxDateMessage={props?.localization?.maxDateMessage}
                            initialFocusedDate={props.initialValue?.toDate()}
                            onError={(error) => {
                                props.onError && props.onError(error as string);
                            }}
                            onAccept={() => {
                                setCalendarState(CalendarState.ACCEPT)
                            }}
                        />
                    </div>
                }
            </MuiPickersUtilsProvider>
        </>
    );
}

export const CustomDateTimePicker = forwardRef<{}, CustomDateTimePickerProps>((props, ref) => {
    return Datetimepicker(props, ref);
});

export const CustomRaalRowDatetimePicker = forwardRef<{}, CustomDateTimePickerProps>((props, ref) => {
    return RaalRowDatetimePicker(props, ref);
});

export const DateTimePickerCustomComponent = forwardRef<{}, CustomDateTimePickerProps>((props, ref) => {
    return FormDateTimePicker(props, ref);
});
