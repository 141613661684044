import * as React from "react";
import {useState} from "react";
import {Loading} from "./Loading";


let setLoadingHolder:(show:boolean, title:string)=>void = null;
export function setLoading(status:boolean, title:string=null) {
    setLoadingHolder&&setLoadingHolder(status, title);
}
export function LoadingContainer() {
    const [state, setState] = useState({show:false, title:null});
    setLoadingHolder = (show:boolean = false, title:string=null) => {
        setState({show, title});
    };
    return (
        <Loading show={state.show} title={state.title} fullscreen />
    );
}
