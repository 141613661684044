export enum SystemParamKey {
    PREPRAVA_DATE_RANGE="PREPRAVA_DATE_RANGE",
    VOZIDLA_DATE_RANGE="VOZIDLA_DATE_RANGE",
    INZERAT_MAX_AGE="INZERAT_MAX_AGE",
    VOZIDLA_DRUHY_MAX="VOZIDLA_DRUHY_MAX",
    PREPRAVY_DRUHY_MAX="PREPRAVY_DRUHY_MAX",
    PREPRAVA_DATE_ADVANCE_MAX="PREPRAVA_DATE_ADVANCE_MAX",
    VOZIDLO_DATE_ADVANCE_MAX="VOZIDLO_DATE_ADVANCE_MAX",
    PREPRAVA_LENGTH = "PREPRAVA_LENGTH",
    PREPRAVA_WEIGHT = "PREPRAVA_WEIGHT",
    VOZIDLO_LENGTH = "VOZIDLO_LENGTH",
    VOZIDLO_WEIGHT = "VOZIDLO_WEIGHT",
    MAX_OFFER_EDITS = "MAX_OFFER_EDITS",
    OFFER_DELETE_DUPLICATES = "OFFER_DELETE_DUPLICATES",
    UZIVATEL_KONTROLA_PODSTAVU = "UZIVATEL_KONTROLA_PODSTAVU",
    ARCHIVE_RETENTION_PERIOD = "ARCHIVE_RETENTION_PERIOD",
    VIEW_ARCHIVE_RETENTION_PERIOD = "VIEW_ARCHIVE_RETENTION_PERIOD",
    ARCHIVE_PERIOD = "ARCHIVE_PERIOD",
    HIDE_INZERCE = "HIDE_INZERCE",
    IGNORE_NOTIFICATION_PROVOZOVNA_KOD = "IGNORE_NOTIFICATION_PROVOZOVNA_KOD"
}

export class SystemParameter {
    key: SystemParamKey;
    value: string;

    toInt(defaultValue=2) {
        return parseInt(this.value ?? `${defaultValue}`);
    }
}