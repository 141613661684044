import * as React from "react";
import {ModalContainer} from "../common/component/ModalContainer";
import {LocaleContext} from "./context/LocaleContext";
import {AppContext} from "./context/AppContext";
import {LoadingContainer} from "../common/component/LoadingContainer";
import {CssBaseline} from "@material-ui/core";
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import {SnackbarContainer} from "../common/component/SnackContainer";
import {MuiThemeContext} from "./context/ThemeModeContext";
import {useUniqueId} from "../common/utils/unque-tab-id";


export default function Root() {
    useUniqueId()

    return (
        <MuiThemeContext>
            <CssBaseline/>
            <LocaleContext>
                <Router>
                    <LoadingContainer/>
                    <SnackbarContainer/>
                    <ModalContainer/>
                    <AppContext>
                        <App/>
                    </AppContext>
                </Router>
            </LocaleContext>
        </MuiThemeContext>
    );
}