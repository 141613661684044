import React, {PropsWithChildren} from "react";
import {exist} from "../../utils/Util";

interface ShowProps {
    hide?:boolean;
    visible?: boolean;
}

export function Show(props: PropsWithChildren<ShowProps>) {
    if ((exist(props.hide) && props.hide) || (exist(props.visible) && !props.visible))
        return null;
    return <>{props.children}</>;
}