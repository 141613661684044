import React, {Ref, useEffect, useImperativeHandle, useState} from "react";
import {LatLng, LeafletMouseEvent} from "leaflet";
import {Polyline} from "react-leaflet";
import {MapOSM} from "./MapOSM";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {exist} from "../../utils/Util";
import {showSnack} from "../SnackContainer";
import { getConfig } from '../../../Config';
import {invoke} from "../../utils/Invoke";

interface MapReportProps {
    id?: number
    defaultPosition?: LatLng
    defaultZoom?: number
    disabled?: boolean
    waypointChanged?:(id:number) => void
    reportRef?: Ref<MapReportExposed>
}

interface MapReportInteraface {
    geometry?: LatLng[]
    tags?: any
    id: number
}

export type MapReportExposed = {
    clearMap: () => void
}

export const MapReport = (props:MapReportProps) => {
    const {id, defaultPosition, defaultZoom, disabled, waypointChanged,reportRef} = props

    const {t} = useTranslation();

    const [pos, setPos] = useState<LatLng[]>([])

    useEffect(() => {
        disabled && invoke(getWay());
        // eslint-disable-next-line
    }, [])

    const clearMap = () => {
        setPos([]);
    }

    useImperativeHandle(reportRef, ()=>({
        clearMap
    }));

    const getHighway = (jsonData: any) => {
        const elements: MapReportInteraface[] = jsonData.elements ? jsonData.elements as MapReportInteraface[] : [];
        return elements ? elements.find(e => exist(e.tags?.highway)) : null;
    }

    const getWay = async () => {
        try {
            if (!id) {
                showSnack({title: t('Map.noHighway'), severity: "error"});
                return;
            }
            const result = await fetch(`${getConfig().overpassUrl}?data=[out:json];way(${id});out+geom;`);
            if(result.status === 200) {
                const jsonData = await result.json();
                const highway = getHighway(jsonData);
                if (highway) {
                    setPos(highway.geometry)
                } else {
                    showSnack({title: t('Map.noHighway'), severity: "warning"});
                }
            }
        } catch {
            showSnack({title: t('ConnectionError'), severity: "error"});
        }
    }

    const reportWaypoint = async(event:LeafletMouseEvent) => {
        try {
            const result = await fetch(`${getConfig().overpassUrl}?data=[out:json];way(around:5,${event.latlng.lat},${event.latlng.lng});out+geom;`);
            if (result.status === 200) {
                const jsonData = await result.json();
                const highway = getHighway(jsonData);
                if (highway) {
                    setPos(highway.geometry)
                    waypointChanged && waypointChanged(highway.id);
                } else {
                    showSnack({title: t('Map.noHighway'), severity: "warning"});
                }
            }
        } catch {
            showSnack({title: t('ConnectionError'), severity: "error"});
        }

    }

    return <Grid container spacing={2}>
        <Grid item xs={12} style={{position:"relative", padding:0}} >
            <MapOSM
                latLng={id && pos && pos.length !== 0 ? pos[Math.round(pos.length / 2)] : defaultPosition}
                zoom={defaultZoom ?? 10}
                zoomControl={false}
                inlineMap
                mapHeight={'70vh'}
                onclick={(event: LeafletMouseEvent) => {
                    !disabled && reportWaypoint(event)
                }}
                dimensions={{width:"100%", height:{value:80, relative:true}}}>
                <Polyline color="darkviolet" lineCap={"round"} opacity={1} weight={5} stroke={true} positions={pos}/>
            </MapOSM>
        </Grid>
    </Grid>;
};
