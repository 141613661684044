import {useTranslation} from "react-i18next";
import React, {Ref, useImperativeHandle, useState} from "react";
import {MuiModal} from "../../../../../common/component/MuiModal";
import {invoke} from "../../../../../common/utils/Invoke";
import {SbernaSluzbaDokladkyDial} from "./SbernaSluzbaDokladkyDial";
import {SbernaSluzbaTrasa} from "../../../../model/Trasa";
import {SbernaSluzbaPrepravaSelectionListItem} from "../../../../model/PrepravaVozidlo";

export type ModalSbernaSluzbaDokladkyExposed = {
    setModalVisibility: () => void
}

type ModalSbernaSluzbaDokladkyProps = {
    modalRef?: Ref<ModalSbernaSluzbaDokladkyExposed>
    sbernaSluzbaTrasa: SbernaSluzbaTrasa
    onClose?: () => void
    onSelect: (preprava: SbernaSluzbaPrepravaSelectionListItem) => void
}

export function ModalSbernaSluzbaDokladky(props: ModalSbernaSluzbaDokladkyProps) {
    const {t} = useTranslation();
    const {sbernaSluzbaTrasa, modalRef} = props;
    const [zadaniOpen, setZadaniOpen] = useState(() => false);

    useImperativeHandle(modalRef, () => ({
        setModalVisibility
    }));

    const setModalVisibility = () => {
        setZadaniOpen(!zadaniOpen)
    }

    return (
        <MuiModal
            title={t("SbernaSluzba.pridatPrepravu")}
            maxWidth={"lg"}
            fullScreen={false}
            open={zadaniOpen}
            onClose={() => {
                setZadaniOpen(false);
                invoke(props.onClose)
            }}>
            {zadaniOpen ? <SbernaSluzbaDokladkyDial sbernaSluzbaTrasa={sbernaSluzbaTrasa} onSelect={props.onSelect}/> : null}
        </MuiModal>
    )
}
