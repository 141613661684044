import {LatLng} from "leaflet";
import {JsonClass, JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {exist} from "../../common/utils/Util";


export enum GeometryType {
    Point = "Point"
}
@JsonClass({converters:{
    toJson(value: Geometry, options): any {
        // toto tu je protoze spring converter ocekava json string
        // kdyby bylo potreba sofistikovanejsi converze, tak lze pouzit pres mapper, viz zakomentovany radek
        // return geoMapper.writeValueAsString(value, {skipJsonClass:true});
        return options?.springSupport ? JSON.stringify(value) : null;
    }
}})
export class Geometry {
    @JsonProperty({type:{enum:GeometryType}})
    type: GeometryType;

    @JsonProperty({type:{isArray:true}})
    coordinates: Array<number>;

    constructor(latitude: number = null, longitude: number = null) {
        if(latitude !== null && longitude != null) {
            this.setPoint(latitude, longitude);
        }
    }

    setPoint(latitude: number, longitude: number) {
        this.type = GeometryType.Point;
        this.coordinates = [Number(longitude), Number(latitude)];
    }

    setPointAsLatLng(latLng:LatLng) {
        this.setPoint(latLng.lat, latLng.lng);
    }

    getLatLng() {
        return exist(this.coordinates) ? new LatLng(this.coordinates[1], this.coordinates[0]) : undefined;
    }

    format() {
        return `lat: ${this.coordinates[1]}, lng: ${this.coordinates[0]}`;
    }

    toString() {
        return `POINT (${this.coordinates[0]} ${this.coordinates[1]})`;
    }

    toSimpleString() {
        return `${this.coordinates[0]},${this.coordinates[1]}`;
    }
}
