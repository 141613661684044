import React, { useEffect, useRef, useState } from 'react';
import { ProfilVozidla, ProfilVozidlaSelect } from '../../../../model/ProfilVozidla';
import { JsonProperty } from '../../../../../common/utils/objectmapper/Mapper';
import { Button, Divider, Grid } from '@material-ui/core';
import { useCurrencySelectObj, useProfilVozidlaSelectObj } from '../../../../raal_components/SelectOptions';
import { useTranslation } from 'react-i18next';
import { exist } from '../../../../../common/utils/Util';
import { Currency } from '../../../../model/Currency';
import { useFetchCustom } from '../../../../../common/utils/HttpUtils';
import { KilometrovnikLinkType } from './KilometrovnikLinkPart';
import { Waypoint } from '../../../../model/Waypoint';
import { FormNominatim } from '../../../../../common/component/form/FormNominatim';
import { Geometry } from '../../../../model/Geometry';
import { GeoPosition } from 'geo-position.ts';
import { StandaloneField } from '../../../../../common/component/form/StandaloneField';
import { ModalTrasaFormProps } from './ModalTrasaForm';
import { KilometrovnikVozidlaPart } from './KilometrovnikVozidlaPart';
import { GoogleMapsButton } from '../../../../raal_components/parts/GoogleMapsComponents';
import { ModalDokladkaFilter, ModalDokladkaFilterExposed } from './ModalDokladkaFilter';
import { DokladkaZadani } from '../../../../model/PrepravaVozidlo';
import { Preprava } from '../../../../model/Preprava';
import { Vozidlo } from '../../../../model/Vozidlo';
import { sizeIntegerFormat } from '../_vp/PrepravaAVozidlaShared';
import DataStorage from '../../../../../common/DataStorage';
import { getConfig } from '../../../../../Config';

export interface PrejezdKilometrovnikLinkPartProps {
    offerId: number;
    linkType: KilometrovnikLinkType;
    prejezdOd: Waypoint;
    prejezdKam: Waypoint;
    trasa: Geometry[];
    checkIsEqual?: () => void
    data?: Preprava | Vozidlo
}

class PrejezdKilometrovnikLinkState {
    static of(offerId: number, linkType: KilometrovnikLinkType, prejezdOd: Waypoint, prejezdKam: Waypoint): PrejezdKilometrovnikLinkState {
        const r = new PrejezdKilometrovnikLinkState();
        r.linkType = linkType;
        r.offerId = offerId;
        r.prejezdOd = prejezdOd;
        r.prejezdKam = prejezdKam;
        return r;
    }

    @JsonProperty({type:{clazz:ProfilVozidlaSelect}})
    profilVozidla?: ProfilVozidla;
    @JsonProperty({type:{clazz:Currency}})
    currency: Currency;
    offerId: number;
    linkType: KilometrovnikLinkType;
    @JsonProperty({type:{clazz:Waypoint}})
    prejezdOd: Waypoint;
    @JsonProperty({type:{clazz:Waypoint}})
    prejezdKam: Waypoint;

    prejezdOdDistance: number;

    prejezdKamDistance: number;
}

export function PrejezdKilometrovnikLinkPart(props: PrejezdKilometrovnikLinkPartProps) {
    const [state, setState] = useState(() => PrejezdKilometrovnikLinkState.of(props.offerId, props.linkType, props.prejezdOd, props.prejezdKam))
    const {t} = useTranslation();
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const {fetch} = useFetchCustom<ProfilVozidla>({ endpoint: props.linkType===KilometrovnikLinkType.PREPRAVA ? 'user/profilvozidla/default-preprava' : 'user/profilvozidla/default-vozidlo'}, undefined, ProfilVozidla);
    const profilVozidlaSelectProps = useProfilVozidlaSelectObj({isClearable: true, autoOpenDisabled: true, params: {profileType: props.linkType===KilometrovnikLinkType.PREPRAVA ? "PREPRAVA" : "VOZIDLO"}});
    const refDokladky = useRef<ModalDokladkaFilterExposed>();

    useEffect(() => {
     getData();
        // eslint-disable-next-line
    }, [props.offerId]);

    const getDokladka = (): DokladkaZadani => {
        const dokladka = new DokladkaZadani();
        dokladka.nakladka = props.data.getMista()[0];
        dokladka.vykladka = props.data.getMista()[1];
        dokladka.delka = state.profilVozidla?.delka && state.profilVozidla.delka > props.data.delka ? Number((state.profilVozidla?.delka - props.data.delka).toFixed(2)) : null;
        dokladka.vaha = state.profilVozidla?.vaha && state.profilVozidla.vaha > props.data.vaha ? Number((state.profilVozidla?.vaha - props.data.vaha).toFixed(2)) : null;
        dokladka.excludedNabidkaId = props.data.id;
        return dokladka;
    }

    const getData= () => {
        const distanceOd = props.prejezdOd && new GeoPosition(props.prejezdOd.koordinat.coordinates[1], props.prejezdOd.koordinat.coordinates[0]).Distance(new GeoPosition(props.trasa[0].coordinates[1], props.trasa[0].coordinates[0]))
        const distanceKam = props.prejezdKam && new GeoPosition(props.prejezdKam.koordinat.coordinates[1], props.prejezdKam.koordinat.coordinates[0]).Distance(new GeoPosition(props.trasa[props.trasa.length-1].coordinates[1], props.trasa[props.trasa.length-1].coordinates[0]))

        fetch().then((d) => {
            if(exist(d.id)) {
                setState({
                    ...state, profilVozidla: d,
                    currency: d.currency,
                    prejezdOdDistance: distanceOd / 1000,
                    prejezdKamDistance: distanceKam / 1000
                })
            } else {
                setState({
                    ...state,
                    prejezdOdDistance: distanceOd / 1000,
                    prejezdKamDistance: distanceKam / 1000
                })
            }
        });
    }

    const getTrasa = (routeOnly?: boolean) => {
        const storageKey = `kilometrovnik-trasa`;
        const trasaData : ModalTrasaFormProps = {
            profilVozidla: state.profilVozidla,
            currency: state.currency,
            routeOnly: routeOnly ?? false,
            linkType: props.linkType,
            offerId: props.offerId,
            prejezd: {prejezdOd: state.prejezdOd, prejezdKam: state.prejezdKam},
            dokladka: null,
            data: null
        };
        DataStorage.set(storageKey, JSON.stringify(trasaData),  true, 'session');
        DataStorage.redirectWithTargetBlank(`/kilometrovnik/trasa`);
        DataStorage.clear(storageKey, true, 'session');
    }

    return <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={3}>
            <StandaloneField title={t("Prejezdy.odkud")}  type={"Custom"} customComponent={FormNominatim} value={state.prejezdOd?.nazevMista} disabled/>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
            <StandaloneField title={t("Prejezdy.kam")}  type={"Custom"} customComponent={FormNominatim} value={state.prejezdKam?.nazevMista} disabled/>
        </Grid>

        <Divider style={{marginBottom: 0, marginTop: 0, height: 0}}/>

        <Grid item xs={12} sm={4} lg={3}>
            <StandaloneField title={t("Prejezdy.nabidkaOdkud")}  type={"Custom"} customComponent={FormNominatim} value={props.data?.odkudHelper} disabled/>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
            <StandaloneField title={t("Prejezdy.nabidkaKam")}  type={"Custom"} customComponent={FormNominatim} value={props.data?.kamHelper} disabled/>
        </Grid>

        <Divider style={{marginBottom: 0, marginTop: 0, height: 0}}/>

        <Grid item xs={12} sm={4} lg={3}>
            <StandaloneField title={t("Prejezdy.radiusNabidkaOdkud")}  type={"number"} value={state.prejezdOdDistance} numberProps={{format: sizeIntegerFormat}} disabled variant="outlined"/>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
            <StandaloneField title={t("Prejezdy.radiusNabidkaKam")}  type={"number"} value={state.prejezdKamDistance} numberProps={{format: sizeIntegerFormat}} disabled variant="outlined"/>
        </Grid>

        {getConfig().geoEnabled &&
            <><Divider style={{marginBottom: 0, marginTop: 0, height: 0}}/>
            <Grid item xs={12} sm={4} lg={3}>
                <StandaloneField title={t("Trasa.profilVozidla")} value={state.profilVozidla} onValueChanged={v => setState({...state, profilVozidla: v, currency: v?.currency})}  type='select' selectProps={profilVozidlaSelectProps}/>
            </Grid>

            <Grid item xs={12} sm={4} lg={3}>
                <StandaloneField title={t("Trasa.currency")} value={state.currency} onValueChanged={v => setState({...state, currency: v})}  type='select' selectProps={currencySelectProps}/>
            </Grid></>
        }
        <Divider style={{marginBottom: 0, marginTop: 0, height: 0}}/>
        <Grid item>
                <Grid item container spacing={1}>
                    {getConfig().geoEnabled &&
                        <KilometrovnikVozidlaPart profilVozidla={state.profilVozidla} getProfil={getData} checkIsEqual={props.checkIsEqual} />
                    }
                    <Grid item>
                        <GoogleMapsButton from={state.prejezdOd.nazevMista} to={state.prejezdKam.nazevMista} waypoints={[props.data.odkudHelper, props.data.kamHelper]} />
                    </Grid>
                    {getConfig().geoEnabled &&
                        <><Grid item>
                            <Button type={"button"} variant="contained" color={"primary"}
                                    disabled={!(exist(state.profilVozidla) && exist(state.currency))}
                                    onClick={() => getTrasa(true)}>{t("Kilometrovnik.DisplayRouteOnly")}</Button>
                        </Grid><Grid item>
                            <Button type={"button"} variant="contained" color={"primary"}
                                    disabled={!(exist(state.profilVozidla) && exist(state.currency))}
                                    onClick={() => getTrasa()}>{t("Kilometrovnik.DisplayRoute")}</Button>
                        </Grid></>
                    }
                    {props.data?.getMista()?.length === 2 && props.linkType === KilometrovnikLinkType.PREPRAVA ?
                        <Grid item><Button type={"button"} variant="contained" color={"primary"}
                                           onClick={() => {
                                               refDokladky.current?.setModalVisibility();
                                           }}>{t("Dokladky.dokladky")}</Button>
                        </Grid> : null}
                </Grid>
        </Grid>
        {exist(props.data) ? <ModalDokladkaFilter
            modalRef={refDokladky}
            dokladkaZadani={getDokladka()}
            redirectAfterSend={false} /> : null}
    </Grid>;
}
