import React, {forwardRef, Ref, useImperativeHandle, useMemo, useRef} from "react";
import DataStorage from "../../../common/DataStorage";
import * as DG from "./DataGrid.d";
import {Editable, FilterProps, IsEditable, Props} from "./DataGrid.d";
import {httpEndpoint, JsonWrapper} from "../../../common/utils/HttpUtils";
import {exist, jsonToFormUrlEncoded,} from "../../../common/utils/Util";
import {Mapper} from "../../../common/utils/objectmapper/Mapper";
import {IDClass} from "../../model/CommonTypes";
import {GenericMap} from "../../../index.d";
import {useDidMount} from "../../../common/component/hooks/SharedHooks";
import {TableComponent, TableExposed} from "./table/TableComponent";
import {getDetailEndpoint, getDetailId} from "../controller/NavigationHelper";
import {useTranslation} from "react-i18next";
import {DetailLocker} from "../controller/DetailLock";
import {useAppContext} from "../../context/AppContext";
import {getTemplateFilters, getTemplateHodnota} from "./MTOverrides";
import {templateSettings} from "lodash";

export const DGContext = React.createContext({} as DG.ContextProps<any>);

export type DataGridExposed<T extends object, Filter = {}> = {
    table(): TableExposed<T, Filter>
}

export type DGExposed = {
    disableAutorefresh: (temporarily?: boolean) => void
    onDetailOpen: () => void
    onDetailClosed: () => void
    onConfirmChangedData(data: any): void
    onRefreshFilterHeaderIndexes(): void
}

export type CrudOperationsEnabled = {editEnabled?:boolean, removeEnabled?:boolean, addEnabled?:boolean, refreshEnabled?: boolean}

export function useCRUDEditableActions<T extends IDClass>({getModificationDate, isEditable, isDeletable, editEnabled, removeEnabled, addEnabled, detailLocker, refreshEnabled}
                    :IsEditable<T>&CrudOperationsEnabled&{
                        lockSupport?: boolean,
                        detailLocker?: DetailLocker
                    }): Editable<T> {

    const {t} = useTranslation();

    return {
        onRowAdd:addEnabled?(
            async (props: Props<T>, data: T) => {
                const mapper = new Mapper<T>({constructor: props.clazz});
                const json = mapper.writeValueAsJson(data);
                const result = await httpEndpoint<T>(props.clazz, `${props.endpoint}/new`, {
                    method: "POST",
                    body: jsonToFormUrlEncoded(json),
                    headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'}
                });
                if (result.errors) {
                    throw result.errors;
                }
                return data;
            }
        ):null,
        onRowDelete:removeEnabled?(
            async (props: Props<T>, data: T) => {

                if(detailLocker){
                    await detailLocker.lock(getDetailId(props, data),
                        {title: t("javax.validation.lock.couldNotLock"), severity: "error"}
                    );
                }

                const result = await httpEndpoint<JsonWrapper<Boolean>>(JsonWrapper, `${getDetailEndpoint(props, data)}`, {method: "DELETE"});
                if (result.errors) {
                    throw result.errors;
                }
                return data;
            }
        ):null,
        onRowUpdate:editEnabled?(
            async (props: Props<T>, newData: T, oldData: T)=> {
                const mapper = new Mapper<T>({constructor: props.clazz});
                const json = mapper.writeValueAsJson(newData);
                const headers:GenericMap = {'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'};
                if(getModificationDate) {
                    const mDate = getModificationDate(newData);
                    if (mDate)
                        headers["modificationDate"] = mDate.toISOStringWithMillis();
                }
                const result = await httpEndpoint<T>(props.clazz, `${getDetailEndpoint(props, newData)}`, {
                    method: "PUT",
                    body: jsonToFormUrlEncoded(json),
                    headers,
                });
                if (result.errors) {
                    throw result.errors;
                }
                return newData;
            }
        ):null,
        isDeletable:isDeletable,
        isEditable:isEditable,
        editEnabled: editEnabled,
        refreshEnabled: refreshEnabled
    };
}

/**
 * Defaultni komponenta, ktera obaluje tabulku context providerem a uklada a nacita stav do/z local storage
 * @param filtering
 * @param extendedFilter
 * @param rowFiltering
 * @param props
 * @param autofocus
 * @constructor
 */
// eslint-disable-next-line
export default <T extends object, Filter = {}>({filtering=true, extendedFilter=true, rowFiltering=true, autofocus=true, ...props}: DG.Props<T> & { dgRef?: Ref<DataGridExposed<T, Filter>>, dataGridRef?:Ref<DGExposed> } & FilterProps<Filter>) => {
    const FW = forwardRef<DataGridExposed<T, Filter>, DG.Props<T>& FilterProps<Filter>>((props, ref) => {
        const storageIdentifier = props.id || props.endpoint;
        const storageKey = `datagrid_${storageIdentifier}`;
        const { initialTemplates } = useAppContext();
        const initialTemplate = initialTemplates.find(obj => obj?.typUlozenehoFiltru === storageIdentifier);
        let mapper: Mapper<Filter> | null = useMemo(()=> {
            if (props.filterClazz)
                return new Mapper<Filter>({constructor: props.filterClazz});

            return null;
        }, [props.filterClazz]);
        const editing = useRef<boolean>(false);
        const createFilterObject = ():Filter => props.filterClazz ? new props.filterClazz() : {} as Filter;

        const dgState:DG.State<Filter> = {
            columnsOrder: JSON.parse(getTemplateHodnota(initialTemplate, 'columnsOrder', 'grid') ?? '[]'),
            hiddenColumns: initialTemplate ? JSON.parse(getTemplateHodnota(initialTemplate,'hiddenColumns', 'grid') ?? '[]') : props.defaultHiddenColumns,
            current: {
                ...(props.initialFilter?.id && props.initialFilter?.name ? {
                    id: props.initialFilter?.id,
                    name: props.initialFilter?.name,
                } : {}),
                filters: {...createFilterObject(), ...props.initialFilter?.data??getTemplateFilters(initialTemplate)??{}},

                page: 0,
                orderDirection: getTemplateHodnota(initialTemplate, 'orderDirection', 'grid') as "asc" | "desc",
                orderBy: getTemplateHodnota(initialTemplate,'orderBy', 'grid'),
                pageSize: (Number.parseInt(getTemplateHodnota(initialTemplate,'pageSize', 'grid'))||props.initialPageSize)??60,
            },
            version:props.version||0,
        };

        const defState:DG.State<Filter> = {
            ...dgState,
            ...(dgState?.current.id ? {templateSettings: {...dgState}} : {}),
        }

        const stateRef = useRef<DG.State<Filter>>(
            ((defaultState) => {
                const jsonStr = DataStorage.get(storageKey, true, 'session');
                const persisted = JSON.parse(jsonStr) as DG.State<Filter>;
                let newState = (persisted !== null
                    ? {...defaultState, ...persisted}
                    : defaultState) as DG.State<Filter>;
                if(newState.version < defaultState.version) {
                    newState = defaultState;
                }

                if(mapper) {
                    newState.current.filters = mapper.readValue(newState.current.filters);
                }
                const defaultSortColumn = props.columns.find(f=>exist(f.defaultSort));
                if(defaultSortColumn && !newState.current?.orderBy && !newState.current?.orderDirection) {
                    newState.current.orderBy = defaultSortColumn.sortBy ?? defaultSortColumn.field;
                    newState.current.orderDirection = defaultSortColumn.defaultSort;
                }
                if (!props.useCurrentPage) newState.current.page =  0;
                return newState;
            })(defState)
        );

        const setState = (state:DG.State<Filter>) => {
            DataStorage.set(storageKey, JSON.stringify(state),  true, 'session');
            stateRef.current = state;
        };
        const tableRef = useRef<TableExposed<T, Filter>>();

        useImperativeHandle(ref, () => (
            {
                table() {
                    return tableRef.current;
                }
            }
        ));

        const setEditing = (status:boolean) => {
            editing.current = status;
        };
        useDidMount(()=>{
            props.onMount&&props.onMount();
        });

        return (
            <DGContext.Provider value={
                {
                    getState:()=>Object.freeze(stateRef.current),
                    fetch:() =>{tableRef.current.refresh()},
                    setState,
                    table:tableRef,
                    editing,
                    name:storageKey
                }
            }>
                <TableComponent<T, Filter> {...props} autofocus={autofocus} mapper={mapper} tbRef={tableRef} dataGridRef={props.dataGridRef} createFilterDataObject={createFilterObject} onEditingStart={()=>setEditing(true)} onEditingEnd={()=>setEditing(false)} />
            </DGContext.Provider>
        );
    });
    const {dgRef, ...props2} = props;

    return <FW {...props2} ref={dgRef} filtering={filtering} dataGridRef={props.dataGridRef} rowFiltering={rowFiltering} extendedFilter={extendedFilter}/>
};
