import {SectionLine} from "../../../../raal_components/parts/SectionLine";
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import {FormField, FormInputType} from "../../../../raal_components/form/Form";
import * as React from "react";

export function WithUserBaseInfo() {
    const {t} = useTranslation();

    return <>
        <SectionLine title={t("WithUserBase.Zmena")}/>
        <Grid item xs={12} sm={4} lg={2}>
            <FormField name={"datCas"} title={t("WithUserBase.DatCas")} type={FormInputType.DateTime} disabled/>
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
            <FormField name={"uzivatel"} title={t("WithUserBase.Uzivatel")} type={FormInputType.Text} disabled getValue={data => data.uzivatel?.displayName()}/>
        </Grid>
    </>

}
