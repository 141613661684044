import {Box, CardContent, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import {exist} from "../../../../common/utils/Util";
import {PhoneNumberDial} from "../../../../common/component/PhoneNumberDial";
import Avatar from "@material-ui/core/Avatar";
import {EmailClient} from "../../../../common/component/EmailClient";
import {Show} from "../../../../common/component/form/Show";
import React from "react";
import {Preprava} from "../../../model/Preprava";
import {Vozidlo} from "../../../model/Vozidlo";
import {useTranslation} from "react-i18next";
import {useEvaluateFieldsVisibility} from "../../../../common/logic/visibility-logic";
import {InzeratViewDetail} from "../../../model/Inzerat";

const KontaktDetail = ({name, title, phoneNumber, email, offerEmail} : {name?: string, title?: string, phoneNumber?: string | string[], email?: string | string[], offerEmail?: string
}) => {
    return (
        <CardContent style={{paddingTop: 16}}>
            <Box marginBottom={2}>
                <Typography variant="h6">{title}</Typography>
            </Box>
            {name && <ListItem>
                <ListItemAvatar><Avatar className={"avatar-contact"}/></ListItemAvatar>
                <ListItemText primary={name}/>
            </ListItem>}
            {phoneNumber ? phoneNumber instanceof Array ?
                phoneNumber.map((n: string, i) => (
                <ListItem key={i}>
                    <PhoneNumberDial phoneNumber={n}/>
                </ListItem>)) :
                <ListItem>
                    <PhoneNumberDial phoneNumber={phoneNumber}/>
                </ListItem> :
                null
            }
            {offerEmail && <ListItem>
                <EmailClient emailAddress={offerEmail}/>
            </ListItem>}
            {email ? email instanceof Array ? email.map((e: string, i) => (
                    <ListItem key={i}>
                        <EmailClient emailAddress={e}/>
                    </ListItem>)) :
                <ListItem>
                    <EmailClient emailAddress={email}/>
                </ListItem> :
                null
            }
        </CardContent>
    )
}

export const KontaktyView = (props:{data:Preprava|Vozidlo|InzeratViewDetail, admin:boolean}) => {
    const {t} = useTranslation();
    const ev = useEvaluateFieldsVisibility(props.data.uzivatel?.provozovna, props.data.uzivatel?.provozovna?.firma, props.admin);

    const rychlyKontakt = exist(props.data.rychlyKontakt) || exist(props.data.email);
    const zadavatel = exist(props.data.uzivatel?.jmeno);
    const uzivatel = exist(props.data.dispecer?.id);

    if (!rychlyKontakt && !uzivatel && !zadavatel) {
        return (
            <Show visible={ev(["provozovna.email", "provozovna.telCislaKontaktu"])}>
                <KontaktDetail
                    title={t("Provozovna.OtherInfo")}
                    phoneNumber={props.data.uzivatel.provozovna.telCislaKontaktu}
                    email={props.data.uzivatel.provozovna.emaily}  />
            </Show>
        )
    }

    return (
        <>
            {rychlyKontakt ? <KontaktDetail
                                title={t("Default.QuickContact")}
                                phoneNumber={props.data.rychlyKontakt}
                                offerEmail={props.data.email} /> : null}
            {uzivatel ? <KontaktDetail
                                title={t("Preprava.dispecer")}
                                phoneNumber={props.data.dispecer.phoneNumbers}
                                email={props.data.dispecer.email}
                                name={props.data.dispecer.jmeno}/> : null}
            {zadavatel ? <KontaktDetail
                                title={t("User.View")}
                                phoneNumber={props.data.uzivatel.phoneNumbers}
                                email={props.data.uzivatel.email}
                                name={props.data.uzivatel.jmeno}/> : null}
        </>
    )
}
