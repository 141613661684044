import {useAppContext} from "../../context/AppContext";
import {useTranslation} from "react-i18next";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Avatar,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Popover
} from "@material-ui/core";
import React from "react";
import {HeaderButtonProps, HeaderDropDown} from "./HeaderAction";
import {useAccounts} from "../../Accounts";
import TokenStore from "../../TokenStore";
import {blue} from "@material-ui/core/colors";
import DataStorage from "../../../common/DataStorage";

const useStyles = makeStyles({
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    },
  });

export function HeaderUserButton({showText}:HeaderButtonProps) {
    const {user, asyncClearFunctions, checkDataChanged} = useAppContext();
    const {t} = useTranslation();
    const {getAccounts} = useAccounts();
    const {switchAccount, removeAccount} = useAccounts();
    const classes = useStyles();
    let tooltip = t("User.Title");
    return (
        <HeaderDropDown tooltip={tooltip} showText={showText} icon={<PersonIcon />} text={`${user.provozovna&&user.provozovna.kod ? `${user.provozovna.kod}/${user.login}` : user.login}`} render={(handleClose) => (
            <>
                <DialogTitle id="simple-dialog-title">{t("Default.Account")}</DialogTitle>
                <List>
                    <ListItem button key={user.email}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${user.provozovna&&user.provozovna.kod ? `${user.provozovna.kod}/${user.login}` : user.login}`} secondary={`${user.email&&user.jmeno ? `${user.email} (${user.jmeno})` : user?.jmeno ? user.jmeno : user?.email??''}`} />
                        <IconButton aria-label="delete" onClick={(e) =>{
                            e.stopPropagation();
                           checkDataChanged(async () => {
                               await asyncClearFunctions();
                               removeAccount(TokenStore.getToken(), true);
                               window.location.replace("/");
                           })
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                    {getAccounts().filter(a => a.id !== user.id).map((a, index) =>
                        <ListItem button onClick={() => checkDataChanged(async () => {
                            await asyncClearFunctions();
                            switchAccount(a.token);
                            window.location.replace("/");
                        })}  key={index}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${a.kod ? `${a.kod}/${a.login}` : a.login}`} secondary={`${a.email && a.name ? `${a.email} (${a.name})` : a?.name ? a.name: a?.email??''}`} />
                            <IconButton aria-label="delete" onClick={(e) => {
                                e.stopPropagation();
                                removeAccount(a.token, false);
                                handleClose();
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    )}
                    <ListItem autoFocus button onClick={() => {
                        handleClose();
                        DataStorage.redirectWithTargetBlank("/#account=add")
                        }}>
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t("Default.AccountAdd")} />
                    </ListItem>
                </List>
            </>
        )} popupComponent={Popover}/>
    );
}
