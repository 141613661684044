import {JsonClass, JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {MomentConverters} from "./Converters";
import {Moment} from "moment";

@JsonClass({idFieldName:"currencyCode"})
export class Currency {
    currencyCode: string;
    name: string;
    kurz: number;
    enabled: boolean;
    
    @JsonProperty({converters:MomentConverters})
    datum:Moment;

    static of(): Currency {
        const c = new Currency();
        c.enabled = true;
        return c;
    }
}