import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {Provozovna} from "./Provozovna";
import {UserRole} from "./User";

export class UserSimple {
    id:number;

    login:string;

    jmeno:string;

    poznamka:string;

    email:string;

    @JsonProperty({type:{clazz:Provozovna}})
    provozovna:Provozovna = null;

    @JsonProperty({type:{isArray:true}})
    roles:UserRole[] = null;

    toString() {
        return `${this.login ?? ''} ${this.jmeno ? `(${this.jmeno})` : ""}`;
    }
}