import {useAppContext} from "../../context/AppContext";
import {ScrollableList} from "../../../common/utils/Util";
import {IDClass} from "../../model/CommonTypes";

export interface TableCache {
    data?: ScrollableList<IDClass & any>
    tableScroll?: {
        x: number
        y: number
    }
}

type InternalTableCache = {
    group?: string
    data: {[typ:string]: TableCache }
}

export const useTableCache = (typ: string, group?: string) => {
    const CACHE_NAME = 'table_cache';
    const { getCache, setCache } = useAppContext();
    const getParsedCache = () => {
        return (JSON.parse(getCache(CACHE_NAME) ?? null) ?? {typ: typ, data: {[typ] : {}}}) as InternalTableCache;
    }
    const getTableCache = () : TableCache => {
        let cache = getParsedCache();
        return (cache.group === group ? getParsedCache().data?.[typ] ?? {} : {}) as TableCache;
    };
    const setTableCache = (data: TableCache) => {
        let cache = getParsedCache();
        let currentData = cache.group != null && cache.group === group ? cache.data : {};
        setCache(CACHE_NAME, JSON.stringify({
            group: group,
            data: {...currentData, [typ]: data}
        }));
    }
    const clearTableCache = () => {
        setTableCache(null);
    }
    const modifyCacheRecord = (id: any, modifyFn: (item: any) => any) => {
        const tableCache = getTableCache();
        let list = tableCache?.data?.list;

        if (Array.isArray(list)) {
            // eslint-disable-next-line
            const index = list.findIndex(item => item.id == id);
            if (index !== -1) {
                const newList = [
                    ...list.slice(0, index),
                    modifyFn(list[index]),
                    ...list.slice(index + 1)
                ];
                setTableCache({ ...tableCache, data: { ...tableCache.data, list: newList } });
            }
        }
    }

    return { getTableCache, setTableCache, clearTableCache, modifyCacheRecord };
}