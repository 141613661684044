import {Mapped} from "../../common/utils/objectmapper/Mapper";

export class HlidaneSlovo {
    id: number;
    vsichniZakaznici:boolean;
    slovo: string;
}
export class HlidaneSlovoFirma implements Mapped {
    id: number;
    slovo: string;
    allCustomers: boolean;
    firmaMaSlovoId:number;
    allWords: boolean;
    enabled:boolean;
}