import {MutableRefObject, useCallback, useEffect, useRef} from "react";
import {FormFieldInterfaceProps} from "./FormFieldInterface";
import {useDidMount} from "../hooks/SharedHooks";

/**
 * Hook pro integraci focusu s formem, focusovaci mechanika je resena na formulari, timto hackem se akorat zaintegruje CustomField do onoho mechanismu
 * @param dom
 * @param props
 */
export function useFocusable<T>(dom:MutableRefObject<HTMLOrSVGElement>, props:FormFieldInterfaceProps<T>) {
    props.enableFocusSupport&&props.enableFocusSupport();
    useDidMount(()=>{
        if(props.focused) {
            dom.current.focus();
        }
    });
    useEffect(() => {
        if(props.focused) {
            dom.current.focus();
        }
        // eslint-disable-next-line
    }, [props.focused]);
}

/**
 * Slouzi k detekci jestli se jedna o fokus uzivatele, pokud ne, je to systemovy fokus, vysledna hodnota se posila do props.onFocus(userClick.current).
 *
 * Cely priklad implementace fokusovani custom poli, je napriklad ve FormNumber, staci se mrknout, jak se tam pouzivaji useFocusable a useFocusTypeDetection
 */
export function useFocusTypeDetection() {
    const userClick = useRef(false);
    const onMouseDown = useCallback(() => {
        userClick.current = true;
    }, [userClick]);
    const onMouseUp = useCallback(() => {
        userClick.current = false
    }, [userClick]);
    const onKeyDown = useCallback(() => {
        userClick.current = true
    }, [userClick]);
    const onKeyUp = useCallback(() => {
        userClick.current = false
    }, [userClick]);
    return {
        onMouseDown,
        onMouseUp,
        onKeyDown,
        onKeyUp,
        userClick
    }

}