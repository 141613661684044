import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../context/AppContext";
import React, {useRef} from "react";
import {cenaFormat, inzerceAdminExportableFields, useInzercePdfLayout} from "../zadani/InzerceCiselnik";
import {cenaConstraint, getExportableProps} from "../_vp/PrepravaAVozidlaShared";
import {useInzeratInvalDuvSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {formatPrice, truncateString} from "../../../../../common/utils/Util";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {
    InvalidDuvodInzerat,
    InzeratFilterOznaceno,
    InzeratView,
    InzeratViewAdmin,
    InzeratViewDetail
} from "../../../../model/Inzerat";
import {Dial} from "../../../../raal_components/dial/Dial";
import Cancel from "@material-ui/icons/Cancel";
import {httpEndpointCustom} from "../../../../../common/utils/HttpUtils";
import Check from "@material-ui/icons/Check";
import {FormInputType} from "../../../../raal_components/form/Form";
import {PhoneNumberDial} from "../../../../../common/component/PhoneNumberDial";
import {FormNumberRange} from "../../../../../common/component/form/FormNumberRange";
import {FormDateRange} from "../../../../../common/component/form/FormDateRange";
import {CheckMark} from "../../../../../common/component/CheckMark";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {InzerceViewCiselnikFilter} from "./InzerceViewCiselnik";
import {ComponentMode} from "../../../../routes";
import {InzeratViewDetailFormBasicPartNew} from "../_inzerce/InzerceCiselnikViewDetailFormBasicPart";
import {InzerceViewAdminPart} from "./InzerceViewAdminPart";


type InzerceHistoryViewPartProps = {
    inzerat?: InzeratViewDetail
    archive?: boolean
    admin?: boolean
    zadani?: boolean
    mode: ComponentMode
}

export default function InzerceHistoryViewPart(props: InzerceHistoryViewPartProps) {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const oznacenoFilter = useRef(false);
    const refreshFun = useRef(() => {});
    const rangeCenaSharedOptions = {numberProps: {format: cenaFormat, constraint: cenaConstraint}};
    const dateKey = "inzerat-view-browse-date";
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.I);
    const invalDuvSelect = useInzeratInvalDuvSelect({isClearable: true});
    const [showConfirm] = useConfirmDialog();
    const dtGrid = useRef<DataGridExposed<InzeratViewAdmin, InzeratFilterOznaceno>>();
    const {pdfLayout} = useInzercePdfLayout(user);
    const accessUrl = props.admin ? 'admin' : 'user';
    const endpoint = props.archive ? `${accessUrl}/inzerat-historie-archiv` : `${accessUrl}/inzeratview-history`;
    const zadaniEndpoint = 'user/inzerat-history';

    return <>
        <Dial<InzeratViewAdmin, InzeratFilterOznaceno, InzeratViewDetail>
            mode={props.mode}
            lastBrowsedDateKey={dateKey}
            logActivity
            gridRef={dtGrid}
            onGridMount={()=>{
                refreshFun.current = () => dtGrid.current?.table()?.refresh();
                oznacenoFilter.current = dtGrid.current.table().getFilter().filters.oznaceno;
            }}

            onFilterChanged={(data) => {
                oznacenoFilter.current =data?.oznaceno ?? false;
            }}
            filterClazz={InzeratFilterOznaceno}
            clazzDetail={InzeratViewDetail}
            config={{
                lockSupport: {enabled: false},
                overflowHidden: true,
                requiredColumns: ['popis'],
                exportConfig: {
                    exportable: true,
                    endpoint: props.zadani ? zadaniEndpoint : endpoint,
                    fileName: props.archive ? "export_historie_archiv_inzerce" : "export_historie_inzerce",
                    translationPrefix: ['Inzerce', 'User', 'Preprava', 'Archive'],
                    formats: ["pdf", "xls", "csv", "xml"],
                    exportAllFormats: ["csv"],
                    pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                    exportableProps: getExportableProps(props.admin ? inzerceAdminExportableFields : [
                        {
                            type: ["csv", "xls", "pdf"],
                            fields: ["popis", "rychlyKontakt", "cena", "currency", "provozovna", "dispecer", "uzivatel", "modifiedOn"]
                        }
                    ], false),
                    excludedProps: [
                        {
                            type: ["csv", "xls"],
                            fields: ["id", "sekce"]
                        }
                    ],
                    extendedProps: [
                        {
                            type: ["csv", "xls"],
                            addExtendedProps: (data) => {
                                return {
                                    provozovna: data.uzivatel?.provozovna?.kod,
                                    ...(props.archive) && {archived: data.deletedOn}
                                }
                            }
                        }
                    ],
                    formattedProps: [
                        {
                            type: ["csv", "xls", "pdf"],
                            field: "invalDuv",
                            format: (data) => {
                                return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data)
                            }
                        },
                        {
                            type: ["csv", "xls", "pdf"],
                            field: "rychlyKontakt",
                            format: (data) => {
                                return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                            }
                        }
                    ]
                },
                options: {
                    rowStyle: (data: any) => {
                        if (data.klasifikace?.barva) {
                            return {backgroundColor: data.klasifikace?.barva}
                        }
                    }
                },
                clazz: InzeratViewAdmin,
                hideDefaultDummyAction: false,
                tableTitle: t("Dials.Advertising"),
                endpoint: props.zadani ? zadaniEndpoint : endpoint,
                filtering: true,
                hideAddNewActions: true,
                defaultQueryParameters: props.inzerat && {parentId: props.inzerat.id},
                actions: props.admin && !props.archive ? [(data:InzeratViewAdmin) => ({
                    hidden: data?.invalDuv===InvalidDuvodInzerat.ZNEPLATNENO_OPERATOREM,
                    icon: () => <Cancel/>,
                    onClick: async () => {
                        showConfirm({
                            body: t("Inzerce.InvalidInzeratConfirm"),
                            onConfirm: async () => {
                                try {
                                    dtGrid.current.table().setLoading(true);
                                    await httpEndpointCustom(`admin/inzerat/${data.id}/invalidate`, {method: "POST"});
                                    dtGrid.current.table().refresh();
                                } finally {
                                    dtGrid.current.table().setLoading(false);
                                }
                            }
                        });
                    }
                }),
                    (data:InzeratViewAdmin) => ({
                        hidden: data?.invalDuv!==InvalidDuvodInzerat.ZNEPLATNENO_OPERATOREM,
                        icon: () => <Check/>,
                        onClick: () => {
                            showConfirm({
                                body: t("Inzerce.ValidInzeratConfirm"),
                                onConfirm: async () => {
                                    try {
                                        dtGrid.current.table().setLoading(true);
                                        await httpEndpointCustom(`admin/inzerat/${data.id}/validate`, {method: "POST"});
                                        dtGrid.current.table().refresh();
                                    } finally {
                                        dtGrid.current.table().setLoading(false);
                                    }
                                }
                            });
                        }
                    })
                ] : [],
                columns: [
                    {
                        title: t("Inzerce.AdText"),
                        field: 'popis',
                        filterProps:() => ({type:FormInputType.Text}),
                        cellStyle: {minWidth: 200},
                        headerStyle: {minWidth: 200}
                    },
                    {
                        title: t("Default.Telefon"),
                        field: 'rychlyKontakt',
                        render:(row:InzeratView)=><PhoneNumberDial hideIcon phoneNumber={row.rychlyKontakt} />,
                        filterProps: () => ({type: FormInputType.Text})
                    },
                    {
                        title: t("Inzerce.Price"),
                        field: 'cena',
                        render:(data:InzeratView) => data.cena && data.currency?.currencyCode ? formatPrice(data.cena, data.currency.currencyCode) : data.cena ?? '',
                        filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"cenaRange", customComponentOptions:rangeCenaSharedOptions}),
                        cellStyle: {textAlign:"right"}
                    },
                    (!props.archive || props.admin) &&  {
                        title: t("Provozovna.Title"),
                        field: 'provozovna',
                        render:(data:InzeratView) => data.provozovna && data.firma ? truncateString(`${data.provozovna} (${data.firma})`, 20) : '',
                        filterProps: () => ({type: FormInputType.Text})
                    },
                    {
                        title: t("Inzerce.Dispecer"),
                        field: 'dispecer',
                        render:(row:InzeratView)=> `${row.dispecer?.jmeno ?? ''}`,
                        filterProps:() => ({type: FormInputType.Text, name: "dispecerText"}),
                        cellStyle: {width: 200, maxWidth: 200},
                        headerStyle: {width: 200, maxWidth: 200}
                    },
                    (props.admin) ?
                        {
                            title: t("PVI.Inserted"),
                            field: 'datIns',
                            render: (data:InzeratView) => data.datIns?.format("L LT")
                        } : undefined,
                    {
                        title: t("PVI.Modified"),
                        field: 'modifiedOn',
                        defaultSort: "desc",
                        render: (data: any) => data.modifiedOn?.format("L LT"),
                        filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, customComponentOptions:{timeFormat: false},  name: "modifiedOnRange"}),
                    },
                    ...(props.admin? [
                        {
                            title: t("PVI.Valid"),
                            field: 'valid',
                            render: (data: InzeratViewAdmin) => <CheckMark checked={data.valid}/>,
                            filterProps: () => ({type: FormInputType.Select, selectProps: yesNoSelect})
                        },
                        {
                            title: t("PVI.InvalDuv"),
                            field: 'invalDuv',
                            render: (data: InzeratViewAdmin) =>  {return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data.invalDuv)},
                            filterProps: () => ({type: FormInputType.Select, selectProps: invalDuvSelect}),
                            cellStyle: {minWidth: 180},
                            headerStyle: {minWidth: 180}
                        },
                        {
                            title: t("User.Title"),
                            field: 'uzivatelText',
                            render:(data:InzeratViewAdmin)=> data.uzivatel?.toString() ?? "",
                            filterProps: () => ({type: FormInputType.Text, name: "uzivatelText"}),
                            cellStyle: {minWidth: 180},
                            headerStyle: {minWidth: 180}
                        }
                    ] : []),
                ].filter(i => Boolean(i)) as Column<InzeratView>[]
            }}
            layoutDetail={()=><TabHelperNew<InzeratViewDetail> render={(data) =>
                <>
                    <InzeratViewDetailFormBasicPartNew data={data} admin={props.admin} archive={props.archive} history />
                    {props.admin ? <InzerceViewAdminPart inzerat={data} history archive={props.archive}/> : null}
                </>}/>}
            crudConfig={{addEnabled:false, editEnabled:false, removeEnabled: false}}
            layoutFilter={() =><InzerceViewCiselnikFilter {...{lastBrowseDateDataKey: dateKey, historyTab: true, admin: props.admin, history: true, historyView: true, archive: props.archive}}/>}
            hideSaveButton
            hideNewButtonOnEdit
            tabDetailUrl={`${props.admin ? `/${accessUrl}` : ''}${props.archive ? `/historie/inzerce/archiv` : `/historie/inzerce`}`}
        />
    </>
}
