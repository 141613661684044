const sk = {
  "MobileApp": {
    "AppLoading": {
      "CompanyInfo": "1. DATABANKA NÁKLADOV A VOĽNÝCH VOZIDIEL od roku 1992."
    },
    "Buttons": {
      "Contact": "Kontaktovať"
    },
    "Login": {
      "InvalidCredentials": "Neplatné prihlasovacie údaje"
    },
    "ConfigureParameters": {
      "NavigationTitle": "Upraviť zobrazenie",
      "SectionTitle": "Vyberte údaje, ktoré sa majú zobraziť na karte ponuky"
    },
    "SortParameters": {
      "NavigationTitle": "Zoradiť zoznam podľa",
      "SortSectionTitle": "Vyberte smer radenia:",
      "ParameterSectionTitle": "Vyberte parameter, podľa ktorého chcete zoznam zoradiť:",
      "Descending": "Zostupne",
      "Ascending": "Vzostupne"
    },
    "FilterParameters": {
      "CancelFilters": "Zrušiť filtre",
      "Filter": "Filtrovať"
    },
    "Cargos": {
      "FilterCargos": "Filtrovanie prepráv",
      "AdditionalInfo": "Ďalšie informácie o preprave",
      "ChooseKinds": "Vybrať druhy",
      "All": "Všetky",
      "NewCargo": "Nová preprava",
      "EditCargo": "Editácia prepravy",
      "FillBasicInfo": "Vyplňte základné informácie",
      "CargoDetail": "Detail prepravy",
      "Distance": "Vzdialenosť"
    },
    "Lorries": {
      "FilterLorry": "Filtrovať vozidlá",
      "NewLorry": "Nové vozidlo",
      "FillBasicInfo": "Vyplňte základné informácie",
      "AdditionalInfo": "Ďalšie informácie o vozidle",
      "LorryDetail": "Detail vozidla",
      "EditLorry": "Upraviť vozidlo"
    },
    "Advertising": {
      "FilterAdvertising": "Filtrovať inzeráty",
      "NewAdvertising": "Nový inzerát",
      "EditAdvertising": "Editácia inzerátu",
      "AdditionalInfo": "Ďalšie informácie",
      "FillBasicInfo": "Vyplňte základné informácie",
      "AdvertisingDetail": "Detail inzerátu"
    },
    "Premises": {
      "FilterPremises": "Filtrovať prevádzky",
      "StreetNumber": "Ulica + ČP",
      "Registration": "Registrácia",
      "ContractDate": "Smlouva /V RAAL od",
      "DetailInfo": "Detailné informácie",
      "Accountants": "Účtovník"
    },
    "Tabs": {
      "Settings": "Nastavenia",
      "Cargos": "Prepravy",
      "Lorries": "Voľné vozidlá",
      "Advertising": "Inzercia"
    },
    "Settings": {
      "AppLanguage": "Jazyk aplikácie",
      "AppAppearance": "Vzhľad aplikácie",
      "AppSupport": "Podpora užívateľov",
      "AppSupportDescription": "V prípade akýchkoľvek problémov nás neváhajte kontaktovať telefonicky alebo e-mailom.",
      "AppSupportHtmlContent": "<p>email.: <a href=\"mailto:support@raal.cz\"  target=\"_self\">support@raal.cz</a>&nbsp;</p>                                                                                                                                 <p>mob.: +420 604 105 758</p>                                                                                                                                 <p>tel.: +420 495 217 281</p>                                                                                                                                 <p>tel.: +420 495 217 282</p>",
      "AppVersion": "Verzia {0}",
      "ChooseAppLanguage": "Zvoľte jazyk aplikácie",
      "ChooseAppAppearance": "Zvoľte vzhľad aplikácie",
      "DarkMode": "Tmavá",
      "LightMode": "Jasná",
      "SystemMode": "Systémové nastavenie"
    }
  },
  "AppTitle": "RAALTRANS",
  "AppActivityType": {
    "View": "Prezeranie"
  },
  "AppActivitySubType": {
    "Detail": "Detail",
    "Row": "Riadok"
  },
  "AutoRefresh": {
    "true": "Automatické načítanie ZAPNUTÉ (CTRL+Medzerník)",
    "false": "Automatické načítanie VYPNUTÉ (CTRL+Medzerník)",
    "title": "Auto-načítanie",
    "NewData": "Nové dáta"
  },
  "RefreshAlert": {
    "TooltipOn": "Zvukové upozornenie na nové ponuky ZAPNUTÉ",
    "TooltipOff": "Zvukové upozornenie na nové ponuky VYPNUTÉ",
    "TooltipInactive": "Zvukové upozornenie POZASTAVENÉ (pre obnovenie je na stránke vykonať nejakú akciu)"
  },
  "Buttons": {
    "Rejstriky": "Registry",
    "MakeInvalid": "Zneplatniť",
    "MakeValid": "Obnoviť",
    "Help": "Pomocník",
    "Login": "Prihlásiť",
    "Logout": "Odhlásiť",
    "Save": "Uložiť",
    "SaveAsNew": "Uložiť ako nový",
    "SaveAsCurrent": "Uložiť ako existujúce",
    "Delete": "Zmazať",
    "Refresh": "Obnoviť",
    "AddNewRecord": "Pridať nový záznam",
    "Cancel": "Zrušiť",
    "Confirm": "Potvrdiť",
    "Add": "Pridať",
    "ReloadWait": "Načítať znova s ​​dlhším čakaním",
    "Close": "Zavrieť",
    "Continue": "Pokračovať",
    "Cancellation": "Storno",
    "Agree": "Súhlasím",
    "SendTheFirstAccessData": "Odoslať 1. Prístup k údajom",
    "Check": "Skontrolovať"
  },
  "ConfirmDialog": {
    "Title": "Potvrdenie",
    "Body": "Naozaj chcete akciu vykonať?"
  },
  "DataChangedDialog": {
    "Title": "Rozpracovaný formulár",
    "Body": "Máte rozpracovaný formulár, naozaj chcete opustiť stránku?\nVšetky rozpracované dáta budú stratené."
  },
  "Currency": {
    "Title": "Mena",
    "name": "Názov",
    "currencyCode": "Kód",
    "kurz": "Kurz (CZK)",
    "datum": "Synchronizované"
  },
  "DataContextChanged": {
    "title": "Došlo k zmene dát. Načítajte nové dáta pomocou F5, alebo stlačením tlačidla.\nUpozornenie: Všetky rozpracované dáta budú stratené."
  },
  "Default": {
    "Id": "ID",
    "Enabled": "Povolený",
    "Telefon": "Telefón",
    "QuickContact": "Rýchly kontakt",
    "PhoneNumbers": "Telefónne č.",
    "PhoneNumbersKratke": "Tel. č.",
    "FillCorrectPhone": "Vyplňte správne telefónne číslo",
    "Email": "Email",
    "Polozka": "Položka",
    "Text": "Text",
    "Key": "Kľúč",
    "Note": "Poznámka",
    "NoteAdmin": "Administrátorská poznámka",
    "NoteMaster": "Masterova poznámka",
    "Slovo": "Slovo",
    "Name": "Meno",
    "DataName": "Názov",
    "Map": "Mapa",
    "Mpz": "MPZ",
    "MapDefine": "Zadanie súradníc",
    "Loading": "Načítanie",
    "ModalZakladni": "Detail záznamu",
    "TabZakladni": "Základné údaje",
    "region": "Typ ponúk",
    "typ": "Typ ponúk",
    "typLicence": "Kilometrovník",
    "Datum": "Dátum",
    "DatCas": "Čas poslednej zmeny",
    "Jazyky": "Jazyky",
    "Yes": "Áno",
    "No": "Nie",
    "Or": "Alebo",
    "LoadingDetail": "Načítam detail",
    "LoadingDetailFailed": "Načítanie detailu zlyhalo",
    "NotFound": "Záznam nebol nájdený",
    "DeleteText": "Naozaj chcete zmazať tento záznam?",
    "DeleteTitle": "Zmazanie záznamu",
    "Account": "Užívateľské účty",
    "AccountAdd": "Pridať účet",
    "UnlockText": "Naozaj si prajete odomknúť uzamknutý \n detail na tomto tabu?",
    "UnlockTitle": "Odomknutie záznamu",
    "PdfHeader": "Ponuka {0} číslo {1} a jej zadávateľ",
    "PdfFirma": "Úplné informácie o prevádzke {0}",
    "PdfExportedUser": "Exportoval",
    "PdfExportedDate": "Dátum",
    "SendTheFirstAccessDataText2023": "Táto akcia je primárne určená pre prevádzkovne, ktoré boli aktívne (Stav JEDE) pred koncom roku 2023. Opravdu si přejete pokračovat? ",
    "SendTheFirstAccessDataTitle2023": "Odoslanie prvých prístupových údajov (2023)",
    "SendTheFirstAccessDataText2024": "Táto akcia je primárne určená pre prevádzky, ktoré budú aktivované až od začiatku roka 2024. Naozaj si prajete pokračovať?",
    "SendTheFirstAccessDataTitle2024": "Odoslanie prvých prístupových údajov (2024)",
    "Jednotka": "Jednotka",
    "JednotkaShort": "MJ"
  },
  "Dials": {
    "Users": "Užívatelia",
    "Companies": "Firmy",
    "Places": "Prevádzkárne",
    "Countries": "Štáty",
    "Currencies": "Meny a kurzy",
    "Types": "Druhy",
    "Params": "Parametre",
    "Jobs": "Joby",
    "ZakazanaSlova": "Zakázaná slová",
    "NevhodnaSlovaFirma": "Strážené slová u firiem",
    "HlidanaSlova": "Strážené slová",
    "VolneVozy": "Voľné vozidlá",
    "Dispeceri": "Dispečeri",
    "Prepravy": "Prepravy",
    "Advertising": "Inzercia",
    "Messages": "Správy",
    "ThunderMessages": "Bleskovky",
    "HlidanaSlovaKonfiguraceFirem": "Konfigurácia firiem",
    "Pvi": "Vlastné PVI",
    "PviShort": "PVI",
    "Firma": "Firma",
    "Provozovna": "Prevádzkareň",
    "HistoryPrepravy": "Prepravy",
    "HistoryAdvertising": "Inzercie",
    "HistoryVolneVozy": "Voľné vozidlá",
    "Kilometrovnik": "Miesta",
    "ProfilyVozidel": "Zoznam vozidiel",
    "Trasy": "Trasy",
    "PrepravaTrasa": "Trasa - Preprava",
    "VozidloTrasa": "Trasa - Vozidlo",
    "KilometrovnikHlaseni": "Hlásenie chýb",
    "KilometrovnikHlaseniAdmin": "Hlásené chyby",
    "PrejezdyPreprav": "Prejazdy prepráv",
    "PrejezdyVozidel": "Prejazdy vozidiel",
    "Dokladky": "Dokládky",
    "DiagPage": "Diagnostika",
    "Notes": "Poznámky",
    "NepohodlneOsoby": "Nepohodlné osoby",
    "Pripady": "Prípady",
    "Nadpripady": "Nadprípady",
    "Faktury": "Faktúry",
    "ReportTexts": "Preklady textov",
    "TriADost": "3x a dosť",
    "Smlouvy": "Zmluvy"
  },
  "DialDefaults": {
    "CreateRecord": "Vytvoriť záznam",
    "CreateRecordAndNext": "Vytvoriť a ďalšie",
    "CreateRecordAsNew": "Vytvoriť záznam ako nový",
    "UpdateRecord": "Uložiť zmeny",
    "DeleteRecord": "Zmazať",
    "ClearField": "Vyprázdniť",
    "UnlockRecord": "Odomknúť záznam",
    "ServerError": "Chyba servera",
    "ServerErrorMessage": "Neočakávaná chyba servera",
    "ServerTimeoutMessage": "Vypršal časový limit"
  },
  "Prejezdy": {
    "odkud": "Prejazd odkiaľ",
    "kam": "Prejazd kam",
    "nabidkaOdkud": "Ponuka - odkiaľ",
    "nabidkaKam": "Ponuka - kam",
    "radiusNabidkaOdkud": "Ponuka - odkiaľ [km]",
    "radiusNabidkaKam": "Ponuka - kam [km]",
    "prejezdy": "Prejazdy",
    "volneVozy": "Voľné vozidlá",
    "prepravy": "Prepravy",
    "prejezd": "S prejazdom",
    "prejezdOnly": "Iba prejazd",
    "original": "Pôvodný",
    "found": "Nájdená",
    "prejezdCelkem": "P-Celkom",
    "prejezdOd": "P-Odkiaľ",
    "prejezdKam": "P-Kam",
    "radiusPrejezd": "Maximálny prejazd celkom [km]",
    "radiusPrejezdOd": "Maximálny prejazd odkiaľ [km]",
    "radiusPrejezdKam": "Maximálny prejazd kam [km]",
    "kilometrovnikPrejezd": "Kilometrovník prejazd",
    "vyhledaniPrepravy": "Prejazdy, vyhľadanie prepravy",
    "vyhledaniVozu": "Prejazdy, vyhľadanie voľného vozidla",
    "calculate": "Hľadať prejazdy",
    "Preprava": "Predvolená preprava",
    "Vozidlo": "Predvolené vozidlo",
    "trasa": "Trasa",
    "vozidlo": "Vozidlo",
    "max": "Maximálny prejazd",
    "info": "Pomocník",
    "datum": "Dátum",
    "datumOd": "Dátum od",
    "datumDo": "Dátum do"
  },
  "Dispecer": {
    "Username": "Meno dispečera",
    "DatCas": "Čas poslednej zmeny"
  },
  "Enumerations": {
    "CiselnikTyp": {
      "I": "I_Inzercia",
      "P": "P_Prepravy",
      "V": "V_Voľné_vozy",
      "R": "R_Druh",
      "D": "D_Doplnok",
      "G": "G_Register firem",
      "S": "S_Stav"
    },
    "Druh": {
      "1": "Plachta",
      "2": "Plato",
      "3": "Sklápač",
      "4": "Skriňa",
      "5": "Frigo",
      "6": "Izotermická skriňa",
      "7": "Tautliner",
      "8": "Skriňa na zav. šatstvo",
      "9": "Cisterna",
      "10": "Mulda",
      "11": "Walkingfloor",
      "12": "Double decker",
      "13": "Podvalník",
      "14": "Valník",
      "15": "Kontejner. podvozok",
      "16": "Klanice",
      "17": "Mega",
      "18": "Jumbo",
      "19": "Sólo",
      "20": "Dodávka-plachta",
      "21": "Dodávka-skriňa",
      "22": "Pick-up",
      "23": "Autoprepravník",
      "250": "Iný"
    },
    "Duvod": {
      "NABIDKA": "Ponuka",
      "POPTAVKA": "Dopyt"
    },
    "InvalDuvodPreprava": {
      "0": "OK",
      "1": "Dátum už leží v minulosti",
      "2": "Preprava obsahuje zakázané slová",
      "3": "Zneplatnené operátorom",
      "4": "Užívateľ má zakázané zverejňovať prepravy",
      "5": "Prevádzkareň pozastavená",
      "6": "Dátum je oddnes príliš vzdialený",
      "7": "Veľké rozmedzie medzi dátumom od a dátumom do",
      "8": "Obsahuje telefónne číslo neuvedené v kontaktoch",
      "9": "Duplicita",
      "10": "Užívateľ pozastavený",
      "11": "Užívateľ bez telefónu",
      "12": "Odobratie práv administrátorom",
      "OK": "Ok",
      "NEZNAME_TELC": "Neznáme telefónne číslo"
    },
    "InvalDuvodVozidlo": {
      "0": "OK",
      "1": "Dátum už leží v minulosti",
      "2": "Voľné vozidlo obsahuje zakázané slová",
      "3": "Zneplatnené operátorom",
      "4": "Užívateľ má zakázané zverejňovať prepravy",
      "5": "Prevádzkareň pozastavená",
      "6": "Dátum je oddnes príliš vzdialený",
      "7": "Veľké rozmedzie medzi dátumom od a dátumom do",
      "8": "Obsahuje telefónne číslo neuvedené v kontaktoch",
      "9": "Duplicita",
      "10": "Užívateľ pozastavený",
      "11": "Užívateľ bez telefónu",
      "12": "Odobratie práv administrátorom",
      "OK": "Ok",
      "NEZNAME_TELC": "Neznáme telefónne číslo"
    },
    "InvalidDuvodInzerat": {
      "0": "OK",
      "1": "Inzerát je príliš starý",
      "2": "Inzerát obsahuje zakázané slová",
      "3": "Zneplatnené operátorom",
      "4": "Užívateľ má zakázané zverejňovať inzeráty",
      "5": "Prevádzkareň pozastavená",
      "8": "Obsahuje telefónne číslo neuvedené v kontaktoch",
      "9": "Duplicita",
      "10": "Užívateľ pozastavený",
      "11": "Užívateľ bez telefónu",
      "12": "Odobratie práv administrátorom",
      "OK": "Ok",
      "INZERAT_ZNEPLATNEN": "Inzerát neplatí"
    },
    "InvoiceFrequency": {
      "0": "Mesačne",
      "1": "Štvrťročne",
      "2": "Polročne",
      "3": "Ročne",
      "MONTHLY": "Mesačne",
      "QUARTERLY": "Štvrťročne",
      "HALFYEARLY": "Polročne",
      "YEARLY": "Ročne"
    },
    "KlasifikaceColor": {
      "0": "Žiadna",
      "1": "Zelená",
      "2": "Oranžová",
      "3": "Červená"
    },
    "KlasifikaceValue": {
      "0": "Žiadna",
      "1": "1",
      "2": "2",
      "3": "3"
    },
    "LicenceTyp": {
      "1": "Nie",
      "2": "Áno",
      "WITHKM": "Áno",
      "WITHOUTKM": "Ne"
    },
    "Podstav": {
      "0": "Bez podstavu",
      "1": "Akcia 3x a dosť",
      "2": "Paušálne platby",
      "3": "Vlastná žiadosť",
      "4": "Neuhradený SW",
      "5": "Porušenie zmluvy",
      "6": "Výpoveď",
      "7": "Problém",
      "8": "Originálne dokumenty",
      "BEZ_PODSTAVU": "Bez podstavu",
      "X3_A_DOST": "Akcia 3x a dosť",
      "PLATBY": "Paušálne platby",
      "POZASTAVEN_VLASTNI_ZADOST": "Vlastní žiadosť",
      "PODEZRELY": "Neuhradený SW",
      "PORUSENI_SMLOUVY": "Porušenie zmluvy",
      "VYPOVED": "Výpoveď",
      "PROBLEM": "Problém",
      "ORIGINAL": "Originálne dokumenty"
    },
    "Predmet": {
      "NAKLADNI_AUTA": "Nákladné autá",
      "OSOBNI_AUTA": "Osobné autá",
      "PRIVESY_NAVESY": "Prívesy/Návesy",
      "NAHRADNI_DILY": "Náhradné diely",
      "JINE": "Iné"
    },
    "Region": {
      "0": "CZ",
      "1": "EU",
      "2": "GLOBAL",
      "3": "SK",
      "4": "CZSK"
    },
    "Roles": {
      "ROLE_ADMIN": "Administrátor",
      "ROLE_MASTER": "Master",
      "ROLE_USER": "Užívateľ",
      "ROLE_NONE": "Bez role"
    },
    "TypOdesilatele": {
      "FYZICKA_OSOBA": "FO (fyzická osoba)",
      "PRAVNICKA_OSOBA": "PO (právnická osoba)",
      "SLOVENSKO": "SK (Slovensko)"
    },
    "SmlouvaS": {
      "NONE": "Bez zmluvy",
      "FOHK": "FO HK",
      "FONITRA": "FO Nitra",
      "RAALHK": "RAALTRANS a.s. HK",
      "RAALNITRA": "RAALTRANS a.s. Nitra",
      "RAALHKNOVA": "Nová RAALTRANS a.s. HK",
      "FONITRANOVA": "Nová FO Nitra",
      "FOHKNOVA": "Nová FO HK",
      "RAAL_AS_2023": "RAALTRANS a.s. 2023",
      "RAAL_AS_2024": "RAALTRANS a.s. 2024",
      "RAAL_SRO_2023": "RAALTRANS s.r.o 2023",
      "RAAL_SRO_2024": "RAALTRANS s.r.o 2024"
    },
    "Stav": {
      "0": "Ide",
      "1": "Pozastavená",
      "JEDE": "Ide",
      "POZASTAVIL": "Pozastavená"
    },
    "SystemParamKey": {
      "PREPRAVA_DATE_RANGE": "Maximálne dátové rozpätie pri prepravách (v dňoch)",
      "VOZIDLA_DATE_RANGE": "Maximálne dátové rozpätie pri voľných vozidlách (v dňoch)",
      "INZERAT_MAX_AGE": "Maximálny vek inzerátu (v dňoch)",
      "PREPRAVY_DRUHY_MAX": "Maximálny počet druhov pri prepravách",
      "VOZIDLA_DRUHY_MAX": "Maximálny počet druhov pri voľných vozidlách",
      "PREPRAVA_DATE_ADVANCE_MAX": "Maximálny dátový predstih pri prepravách (v dňoch)",
      "VOZIDLO_DATE_ADVANCE_MAX": "Maximálny dátový predstih pri voľných vozidlách (v dňoch)",
      "PREPRAVA_LENGTH": "Ložný meter pri prepravách",
      "PREPRAVA_WEIGHT": "Hmotnosť pri prepravách",
      "VOZIDLO_LENGTH": "Ložný meter pri voľných vozidlách",
      "VOZIDLO_WEIGHT": "Hmotnosť pri voľných vozidlách",
      "PROVOZOVNA_CANCEL_PERIOD": "Nastavenie príznaku zrušené po ukončení zmluvy (v dňoch)",
      "MAX_OFFER_EDITS": "Maximálny počet obnovení / editácií ponuky (-1 = neobmedzene)",
      "OFFER_DELETE_DUPLICATES": "Mazať s ponukou aj duplicity",
      "MAX_CALCULATION_JOBS_PER_NODE": "Maximálny počet počítaných trás vztiahnutých na jeden server",
      "UZIVATEL_KONTROLA_PODSTAVU": "Zoznam podstavov prevádzok pre kontrolu IP prihlásenia užívateľa",
      "ARCHIVE_RETENTION_PERIOD": "Vymazanie ponúk z archívu zadania (v dňoch do vloženia do archívu)",
      "VIEW_ARCHIVE_RETENTION_PERIOD": "Vymazanie ponúk z archívu prehliadania (v dňoch do vloženia do archívu)",
      "ARCHIVE_PERIOD": "Presun ponúk do archívu zadania (v dňoch od zneplatnenia)",
      "HIDE_INZERCE": "Skrytie inzercie",
      "IGNORE_NOTIFICATION_PROVOZOVNA_KOD": "Nestrážené prevádzky"
    },
    "TypZpravy": {
      "B": "Bleskovka",
      "C": "C",
      "I": "I",
      "R": "R",
      "S": "S",
      "T": "Školenie",
      "H": "H",
      "V": "Popis systému"
    },
    "RoadType": {
      "0": "Diaľnica",
      "1": "Cestnice pre motorové vozidlá",
      "2": "Cesta I. triedy",
      "3": "Cesta II. triedy",
      "4": "Cesta III. triedy",
      "5": "Iné"
    },
    "TrasaState": {
      "0": "Čaká na výpočet",
      "1": "Prebieha výpočet",
      "2": "Vypočítané",
      "3": "Chyba"
    },
    "OsrmProfile": {
      "0": "N1 (do 3,5 tony)",
      "1": "N2 (do 12 ton)",
      "2": "N3 (do 40 ton)"
    },
    "RouteCalculationStatus": {
      "0": "OK",
      "1": "Chyba servera",
      "2": "Trasa nenájdená",
      "3": "Nedá sa vypočítať náklady",
      "4": "Neúplná konfigurácia - ceny mýta, kurzy, ..."
    },
    "KilometrovnikHlaseniStatus": {
      "0": "Nový",
      "1": "Uzavretý"
    },
    "DluhNadpripadStav": {
      "0": "Otvorený",
      "1": "Uzavretý"
    },
    "DluhPripadStav": {
      "0": "Neodoslaný",
      "1": "Nepotvrdený",
      "2": "Neuhradený",
      "3": "Uzavretý",
      "4": "Vyriešený bez úhrad",
      "5": "Uzavretý (výpoveď)",
      "6": "Uzavretý (bod 3.9)",
      "7": "Premlčané",
      "8": "Zamietnuté"
    },
    "DluhFakturaStav": {
      "0": "Neodoslaná",
      "1": "Nepotvrdená",
      "2": "Neuhradená",
      "3": "Uhradená",
      "4": "Vyriešené bez úhrad",
      "5": "Neuhradená (výpoveď)",
      "6": "Neuhradená (bod 3.9)",
      "7": "Premlčané",
      "8": "Zamietnuté"
    },
    "ReportLanguage": {
      "cs": "Čeština",
      "en": "Angličtina",
      "de": "Němčina",
      "pl": "Polština",
      "sk": "Slovenčina"
    },
    "TypPravniDokumentace": {
      "VP": "Všeobecné podmienky",
      "LP": "Licenčné podmienky",
      "GDPR": "Zásady ochrany osobných údajov",
      "PDI": "Poskytovanie dopravných informácií",
      "VYPCZ": "Ukončenie zmluvy - CZ",
      "VYPSK": "Ukončenie zmluvy - SK"
    },
    "LastAction": {
      "PASSWORD_CHANGE": "Zmena hesla"
    }
  },
  "ErrorComponent": {
    "title": "Chyba ",
    "homeButton": "Späť na úvodnú stránku",
    "redirectionMessage": "Za moment budete presmerovaní na úvodnú stránku.",
    "error403": "Prístup bol zamietnutý."
  },
  "Export": {
    "detailHeader": "Detail"
  },
  "Filter": {
    "Templates": "Filter pre vyhľadávanie",
    "TemplateName": "Názov filtra",
    "SaveTemplate": "Nový",
    "UpdateTemplate": "Uložiť",
    "RemoveTemplate": "Zmazať",
    "SelectTemplate": "Vyberte filter pre vyhľadávanie",
    "NoTemplates": "Nenájdený žiadny filter",
    "ClearFilter": "Vyčistiť filter",
    "DoFilter": "Hľadať",
    "OpenFilter": "Filtre",
    "UseFilter": "Filter",
    "TooltipForFilledFilter": "Filter je aktívny",
    "TooltipForEmptyFilter": "Filter je neaktívny",
    "DeleteConfirmationText": "Naozaj chcete filter zmazať?",
    "Vychozi": "Predvolené",
    "SaveFilter": "Uložiť filter",
    "SavedFilters": "Uložené filtre"
  },
  "Firma": {
    "Id": "ID firmy",
    "Title": "Firma",
    "TabProvozovny": "Prevádzkárne",
    "TabUzivatele": "Užívatelia",
    "TabDispeceri": "Dispečeři",
    "Provozovna": "Firma (Kód prevádzky)",
    "pozastavena": "Zrušené",
    "gdpr": "Súhlas s GDPR",
    "UserIdentityLabel": "Identifikácia firmy",
    "UserLabel": "Firma",
    "UserContactLabel": "Adresa registrácie",
    "UserNextLabel": "Ostatné informácie",
    "ContactLabel": "Adresy",
    "NextLabel": "Údaje",
    "R1Nazev": "Názov",
    "R1Ulice": "Ulica",
    "R1CisloPopisne": "Číslo popisné",
    "R1CisloPopisneKratke": "Č.p.",
    "R1Obec": "Obec",
    "R1CastObce": "Časť Obce",
    "R1Psc": "PSČ",
    "R1Stat": "Štát",
    "ico": "IČO",
    "dic": "EÚ DIČ / IČ DPH",
    "dic1": "DIČ",
    "vznik": "Vznik firmy",
    "NezadouciZakaznik": "Nežiaduci zákazník",
    "NezadouciZakaznikShort": "Nežiaduce",
    "CreateFirmaAndProvozovna": "Vytvoriť firmu a prevádzku",
    "VytvoritFirmu": "Vytvoriť firmu",
    "Pojisteni": "Poistenie",
    "datPojist": "Dátum poistenia",
    "ChooseFirma": "Vyberte firmu",
    "FirmaMaSlovo": "Firma má slovo",
    "EnabledOnFirma": "Zapnuté na firme",
    "MissingFirma": "!!! Chýba firma !!!",
    "RegDatum": "Dátum registrácie",
    "datSmlouvy": "Dátum zmluvy / V RAALe od",
    "raalOd": "V RAALu od",
    "http": "WWW",
    "fPoznamka": "Administrátorská poznámka",
    "DatumZmenyRejstrik": "Dátum zmeny register",
    "PoznamkaRejstrik": "Poznámka register",
    "AktualizaceRejstrik": "Aktualizácia register",
    "Nadpripad": "Nadprípad",
    "TooltipRejstrikyVeFirme": "Pre zobrazenie registrov musí byť vyplnený štát"
  },
  "FormLocalization": {
    "DataSaved": "Záznam bol úspešne uložený",
    "DataSavedFailed": "Záznam sa nepodarilo uložiť",
    "DataRemoved": "Záznam bol úspešne zmazaný",
    "DataWasRemoved": "Záznam bol odstránený",
    "DataWasEdited": "Záznam bol zmenený",
    "DataRemovedFailed": "Záznam sa nepodarilo zmazať",
    "ServerError": "Chyba servera",
    "ValidationError": "Validačná chyba",
    "FieldMessages": {
      "FieldIsRequired": "Nie je vyplnený povinný údaj",
      "FieldNotNull": "Nesmú byť nulové",
      "FieldIsRequiredValue": "Nie je vyplnený povinný údaj {0}",
      "CoordinatesMissing": "Chýbajú súradnice k poľu {0}",
      "UserLicenceError": "Licencia kilometrovníka nepokrýva zadanú adresu"
    },
    "DateTimePicker": {
      "invalidDate": "Dátum nie je validný",
      "cancelLabel": "Zrušiť",
      "clearLabel": "Vyčistiť",
      "emptyLabel": "Prázdny",
      "invalidLabel": "Nesprávny dátum",
      "okLabel": "Potvrdiť",
      "todayLabel": "Dnes",
      "minDateMessage": "Dátum nesmie byť v minulosti",
      "minDateTimeMessage": "Dátum a čas nesmie byť v minulosti",
      "maxDateMessage": "Dátum nesmie byť v budúcnosti",
      "disablePast": "Dátum nesmie byť v minulosti",
      "shouldDisableDate": "Dátum je mimo povoleného rozsahu",
      "disableFuture": "Dátum nesmie byť v budúcnosti",
      "maxDate": "Nesprávny dátum",
      "minDate": "Dátum nesmie byť v minulosti"
    },
    "Vicinity": {
      "Address": "Presne",
      "Range": "Prejazd",
      "NoKilom": "Nutná licencia s kilometrovníkom"
    }
  },
  "FormEditor": {
    "add": "Přidat",
    "cancel": "Storno",
    "colorpicker": "Výběr barvy",
    "text": "Text",
    "background": "Pozadí",
    "h": "Nadpis {0}",
    "blockquote": "Odstavec",
    "kód": "Kód",
    "blocktype": "Styly",
    "normal": "Normální",
    "embedded": "Vloženo",
    "embeddedlink": "Vložený odkaz",
    "enterlink": "Otevřít odkaz",
    "emoji": "Emotikon",
    "fontfamily": "Písmo",
    "fontsize": "Velikost písma",
    "history": "Historie",
    "undo": "Zpět",
    "redo": "Znovu",
    "image": "Vložit obrázek",
    "fileUpload": "Nahrát soubor",
    "byURL": "URL",
    "dropFileText": "Přetáhněte sem soubor nebo klikněte pro výběr",
    "bold": "Tučné",
    "italic": "Kurziva",
    "underline": "Podtržení",
    "strikethrough": "Přeškrtnuto",
    "monospace": "Kód",
    "superscript": "Horní index",
    "subscript": "Dolní index",
    "linkTitle": "Název odkazu",
    "linkTarget": "Cílová cesta",
    "linkTargetOption": "Otevřít odkaz v novém okně",
    "link": "Vložit odkaz",
    "unlink": "Odebrat odkaz",
    "list": "Seznam",
    "unordered": "Odrážky",
    "ordered": "Číslování",
    "indent": "Zvětšit odsazení",
    "outdent": "Zmenšit odsazení",
    "remove": "Vymazat formátování",
    "textalign": "Zarovnání textu",
    "left": "Zarovnat doleva",
    "center": "Zarovnat na střed",
    "right": "Zarovnat doprava",
    "justify": "Zarovnat do bloku"
  },
  "GeneratePassword": {
    "title": "Nové heslo",
    "tooltip": "Užívateľovi bude automaticky odoslané nové heslo na vyplnenú e-mailovú adresu.",
    "success": "Heslo bolo odoslané užívateľovi.",
    "error": "Chyba pri generovaní hesla."
  },
  "GoogleMaps": {
    "Title": "Mapa Google",
    "SearchPlaces": "Hľadať miesta"
  },
  "HttpLocalization": {
    "SignedOut": "Odhlásené",
    "NetworkError": "Chyba siete",
    "Error401FingerPrint": "Boli ste odhlásení, pretože ste sa prihlásili v inom prehliadači, alebo vypršal prihlasovací token",
    "LogoutBySystem": "Boli ste odhlásení systémom.",
    "Error401": "Prihlasovací token vypršal"
  },
  "Informations": {
    "Info": "Raal info",
    "System": "Popis systému",
    "TollRate": "Sadzba mýta",
    "ExchangeRates": "Kurzový lístok",
    "ReleaseNotes": "Poznámky k vydaniu"
  },
  "Inzerce": {
    "Id": "ID Inzerátu",
    "InvalidInzeratConfirm": "Chcete vážne zneplatniť inzerát?",
    "ValidInzeratConfirm": "Chcete vážne zrušiť zneplatnenie inzerátu?",
    "CurrencyRequiredWhenPrice": "Musí byť vyplnené spoločne s cenou",
    "Price": "Cena",
    "currency": "Mena",
    "popis": "Popis",
    "rychlyKontakt": "Rýchly kontakt",
    "datIns": "Dátum vytvorenia",
    "modifiedOn": "Dátum zmeny",
    "valid": "valid",
    "invalDuv": "Dôvod zneplatnenia",
    "cena": "Cena",
    "Description": "Popis",
    "AdText": "Popis",
    "Dispecer": "Dispečer",
    "History": "História",
    "OfferDetail": "Ponuka",
    "ArchiveConfirm": "Chcete vážne archivovať inzerát?",
    "zadavatel": "Zadávateľ"
  },
  "ItemAge": {
    "Title": "Vek ponuky",
    "OldMax": "Staré maximálne",
    "FromLastBrowse": "Novšie od posledného prezerania",
    "ByDate": "Podľa dátumu",
    "Unknown": "Nevedno",
    "search": "Vyhľadať"
  },
  "javax": {
    "validation": {
      "constraints": {
        "NotEmpty": {
          "message": "nesmie byť prázdne"
        },
        "Email": {
          "message": "nesprávny formát emailovej adresy"
        }
      },
      "user": {
        "adminWithoutProvozovna": "Administrátor nesmie mať priradenú prevádzku",
        "userWithProvozovna": "Užívateľ musí mať priradenú prevádzkareň"
      },
      "dispatcher": {
        "licenseLimit": "Počet dispečerov dosiahol limit počtu licencií"
      },
      "firma": {
        "requiredIdFields": "Povinná kombinácia štát + IČO/DIČ/IČ DPH"
      },
      "phone": {
        "notValid": "neplatný formát telefónneho čísla"
      },
      "offer": {
        "rychlyKontaktNotInProvozovna": "Telefónne číslo nie je v zozname čísel prevádzky"
      },
      "lock": {
        "couldNotLock": "Nepodarilo sa uzamknúť záznam"
      },
      "waypoint": {
        "noLicense": "Licencia kilometrovníka nepokrýva zadanú adresu"
      }
    }
  },
  "Job": {
    "Stav": "Stav",
    "Action": "Akcia"
  },
  "Licence": {
    "Id": "Id",
    "Title": "Licencia",
    "GenerateUsers": "Generovať používateľa",
    "NumberGreaterThan": "Číslo musí byť väčšie ako {{value}}"
  },
  "Links": {
    "ZivnostenskyCr": "Živnostenský ČR",
    "ZivnostenskySk": "Živnostenský SK",
    "ObchodniCr": "Obchodný ČR",
    "Ares": "ARES",
    "ObchodniSk": "Obchodný SK"
  },
  "Map": {
    "ClearWayPoints": "Vyčistiť mapu",
    "SearchPlace": "Zvoľte cieľ alebo kliknite do mapy",
    "SearchNearPlace": "Zvoľte cieľ *",
    "noLicense": "Nemáte licenciu",
    "noHighway": "Cesty nenájdená",
    "noHighwaySelected": "Nebol vybraný žiadny úsek",
    "navigation": "Popis trasy"
  },
  "MaterialTable": {
    "columns": {
      "showColumnsTitle": "Nastaviť stĺpce",
      "menuTitle": "Pridať alebo ubrať stĺpce"
    },
    "body": {
      "emptyDataSourceMessage": "Žiadne údaje nie sú k dispozícii",
      "addTooltip": "Pridať nový záznam rýchlo",
      "deleteTooltip": "Zmazať záznam",
      "removeTooltip": "Odobrať záznam",
      "updateTooltip": "Obnoviť záznam",
      "noUpdateTooltip": "Záznam nemožno obnoviť",
      "editTooltip": "Editovať záznam",
      "dataChanged": "Dáta boli zmenené",
      "dateTimePickerLocalization": {},
      "filterRow": {
        "filterTooltip": "filter"
      },
      "editRow": {
        "saveTooltip": "Uložiť",
        "cancelTooltip": "Storno",
        "mode": {
          "add": {
            "saveTooltip": "Uložit nový",
            "cancelTooltip": "Zrušit"
          },
          "update": {
            "saveTooltip": "Uložit změny",
            "cancelTooltip": "Zrušit změny"
          },
          "delete": {
            "saveTooltip": "Potvrdit smazání",
            "cancelTooltip": "Zrušit mazání"
          }
        },
        "deleteText": "Naozaj chcete zmazať tento záznam?",
        "addTooltip": "Prejsť na rozšírené zadanie",
        "editTooltip": "Prejsť na rozšírenú editáciu",
        "readTooltip": "Prejsť na náhľad"
      }
    },
    "grouping": {
      "placeholder": "zoskupiť"
    },
    "pagination": {
      "labelDisplayedRows": "{from}-{to} z {count} ({overall} celkom)",
      "labelRowsSelect": "riadkov",
      "labelRowsCount": "Počet riadkov:",
      "labelRowsPerPage": "počet na stránku",
      "firstAriaLabel": "prvá",
      "firstTooltip": "prvá",
      "previousAriaLabel": "predchádzajúca",
      "previousTooltip": "predchádzajúca",
      "nextAriaLabel": "ďalšia",
      "nextTooltip": "ďalšia",
      "lastAriaLabel": "posledná",
      "lastTooltip": "posledná"
    },
    "toolbar": {
      "searchTooltip": "vyhľadať",
      "searchPlaceholder": "vyhľadať"
    },
    "header": {
      "actions": ""
    },
    "resetColumnOrder": "Obnoviť stĺpce",
    "resetColumnOrderQuestion": "Naozaj chcete vrátiť stĺpce tabuľky do pôvodnej podoby?",
    "openMultipleAction": "Otvoriť hromadnú akciu",
    "closeMultipleAction": "Zavrieť hromadnú akciu",
    "export": "Export",
    "exportAll": "Exportovať všetko",
    "exportFilter": "Exportovať filter",
    "exportPdf": "Export do PDF",
    "exportXls": "Export do Excelu (XLS)",
    "exportCsv": "Export do CSV",
    "exportXml": "Export do XML"
  },
  "MuiAutocomplete": {
    "NoOption": "Žiadne výsledky",
    "GPSCurrentPosition": "Načítať aktuálnu GPS pozíciu"
  },
  "MuiInput": {
    "MinTip": "Dolná hranica pre filtrovanie záznamov",
    "MaxTip": "Horná hranica pre filtrovanie záznamov"
  },
  "NevhodnaSlova": {
    "VsichniZakaznici": "Všetky firmy",
    "ZadniZakaznici": "Žiadne firmy",
    "AllWords": "Všetky slová",
    "HlidaneSlovoFirmy": "Firmy používajúce toto slovo"
  },
  "Vozidlo": {
    "id": "ID Vozidlá",
    "ArchiveConfirm": "Chcete vážne archivovať vozidlo?"
  },
  "TransportNabidka": {
    "pocetEditaci": "Počet editácií"
  },
  "Preprava": {
    "id": "ID Prepravy",
    "zadavatel": "Zadávateľ",
    "regOd": "Štát odkiaľ",
    "pscOdkud": "PSČ odkiaľ",
    "datOd": "Dátum od",
    "mena": "Mena",
    "regKam": "Štát kam",
    "datDo": "Dátum do",
    "datum": "dátum",
    "pscKam": "PSČ kam",
    "cena": "Cena",
    "vahaShort": "M [t]",
    "paletyShort": "P [ks]",
    "objemShort": "V [m3]",
    "delkaShort": "L [m]",
    "sirkaShort": "W [m]",
    "vyskaShort": "H [m]",
    "lozPlochaShort": "S [m2]",
    "doplnujiciUdaje": "Upresňujúce údaje",
    "vaha": "Váha [t]",
    "palety": "Palety [ks]",
    "objem": "Objem [m3]",
    "lozPlocha": "Ložná plocha [m2]",
    "lozPlochaSmaller": "L. plocha [m2]",
    "delka": "Dĺžka [m]",
    "sirka": "Šírka [m]",
    "vyska": "Výška [m]",
    "datIns": "Dátum vytvorenie",
    "modifiedOn": "Dátum zmeny",
    "valid": "valid",
    "invalDuv": "Dôvod zneplatnenia",
    "odkud": "ODKIAL",
    "odkudKamToolTip": "Na zadanie viacerých MPZ / PSČ použite čiarku ako oddeľovač",
    "kam": "KAM",
    "naklProstorVcelku": "Nákladový priestor vcelku",
    "neverPozn": "Neverejná poznámka",
    "zvedaciCelo": "Zdvíhacie čelo",
    "druhyRidic": "Druhý vodič",
    "zakazDokladky": "Zákaz dokládky",
    "adr": "ADR",
    "hydraulickaRuka": "Hydraulická ruka",
    "zpusobNakladky": "Spôsob nakládky",
    "datumZalozeni": "Dátum založenia",
    "datumArchivace": "Dátum archivácie",
    "nadrozmernyNaklad": "Nadrozmerný náklad",
    "nakladkaZezadu": "Nakládka zezadu",
    "nakladkaBokem": "Nakládka bokem",
    "nakladkaShora": "Nakládka zhora",
    "nakladkaZezaduShort": "zozadu",
    "nakladkaBokemShort": "bokom",
    "nakladkaShoraShort": "zhora",
    "doplnky": "Doplnky",
    "jine": "Iné",
    "souprava": "Súprava",
    "naves": "Náves",
    "druhy": "Druhy",
    "verejnaPozn": "Poznámka",
    "uzivatel": "Zadávateľ",
    "dispecer": "Dispečer",
    "History": "História",
    "InvalidPrepravaConfirm": "Chcete vážne zneplatniť prepravu?",
    "ValidPrepravaConfirm": "Chcete vážne zrušiť zneplatnenie prepravy?",
    "InvalidVozidloConfirm": "Chcete vážne zneplatniť vozidlo?",
    "ValidVozidloConfirm": "Chcete vážne zrušiť zneplatnenie vozidla?",
    "Misto": "Miesto",
    "NSJAnyMustBeChecked": "Vyberte aspoň jednu položku z NSJ",
    "NSJOneMustBeChecked": "Vyberte práve jednu položku z NSJ",
    "GeocodingCoordinates": "Získavam súradnice z ručne zadanej adresy",
    "DruhyOverflow": "Maximálny počet {0}",
    "AirDistance": "Vzdialenosť [km]",
    "AirDistanceShort": "KM",
    "AirDistanceToolTip": "Ide o vypočítanú vzdialenosť vzdušnou čiarou",
    "CurrencyRequiredWhenPrice": "Musí byť vyplnené spoločne s cenou",
    "okoliOdkud": "Okolie odkiaľ",
    "okoliKam": "Okolie kam",
    "radiusOdkud": "Okolie odkiaľ do [km]",
    "radiusKam": "Okolí kam do [km]",
    "ArchiveConfirm": "Chcete vážne archivovať prepravu?",
    "rychlyKontakt": "Rýchly kontakt",
    "firma": "Firma",
    "airDistance": "Vzdialenosť [km]",
    "currency": "Mena",
    "nsj": "N,S,J",
    "psc": "PSČ",
    "mpz": "MPZ"
  },
  "Provozovna": {
    "0": "Provozovna jede",
    "1": "Prevádzkareň pozastavená",
    "Id": "ID Prevádzkárne",
    "Title": "Prevádzkareň",
    "Nazev": "Názov prevádzky",
    "stopped": "Pozastavená",
    "PayInfo": "Fakturácia",
    "souhrnne": "Fakturované súhrnne",
    "uOsobaUser": "Účtovná osoba",
    "uTelefonUser": "Účtovný telefón",
    "uEmailUser": "Účtovný email",
    "uosobaUser": "Účtovná osoba",
    "utelefonUser": "Účtovný telefón",
    "uemailUser": "Účtovný email",
    "uosoba": "Účtovná osoba (fakturácia)",
    "utelefon": "Účtovný telefón (fakturácia)",
    "uemail": "Účtovný email (fakturácia)",
    "OtherInfo": "Kontakty",
    "emaily": "Emaily",
    "telCislaKontaktu": "Telefónne čísla",
    "FilterInfo": "Použite základný / rozšírený filter pre možné vyhľadanie 30 relevantných prevádzok",
    "UserIdentityLabel": "Identifikácia prevádzky",
    "CompanyContactLabel": "Adresa firmy",
    "UserContactLabel": "Adresa prevádzky",
    "UserCorespondLabel": "Kontaktná adresa",
    "KodProvozovny": "Kód prevádzky",
    "UserInDetail": "v detaile",
    "IcoDic": "IČO / EU DIČ / DIČ",
    "PrefStat": "Predvoľba štát",
    "PNazev": "P Názov",
    "PUlice": "P Ulica",
    "PCisloPopisne": "P Číslo popisné",
    "PCisloPopisneKratke": "P č.p.",
    "PUliceCisloPopisne": "P Ulica + č.p",
    "PObec": "P Obec",
    "PCastObce": "P Časť Obce",
    "PPsc": "P PSČ",
    "PStat": "P Štát",
    "KNazev": "K Názov",
    "KAdresat": "K Adresát",
    "KUlice": "K Ulica",
    "KCisloPopisne": "K Číslo popisné",
    "KObec": "K Obec",
    "KCastObce": "K Část Obce",
    "KPsc": "K PSČ",
    "KStat": "K Štát",
    "Kod": "Kód",
    "datSmlouvyZmena": "Nová zmluva",
    "RaalJmeno1": "Raal Kontakt 1",
    "RaalJmeno2": "Raal Kontakt 2",
    "RaalJmeno3": "Raal Kontakt 3",
    "KdoZmenil": "Kto změnil",
    "KdyZmena": "Kdy zmenil",
    "PotlacInzDatum": "Potlačiť inzeráty - dátum",
    "ZasilatReport": "Zasielať report",
    "PotlacInz": "Potlačiť inzeráty",
    "PotlacPrepravy": "Potlačiť prepravy",
    "PotlacVv": "Potlačiť voľné vozidlá",
    "PotlacPrepravyDatum": "Potlačiť prepravy - dátum",
    "PotlacVvDatum": "Potlačiť voľné vozidlá - dátum",
    "VytvoritProvozovnu": "Vytvoriť prevádzkareň",
    "ZkusebniDoba": "Skúšobná doba",
    "datzkus": "Skúšobná doba do",
    "ipDll": "IP adresa z objednávky",
    "DatovaSchranka": "Dátová schránka",
    "SmlouvaS": "Zmluva s",
    "EmailProfa": "Emaily pre zasielanie faktúr a upomienok",
    "SmsUpominky": "Telefónne čísla pre SMS upomienky fakturácie",
    "Pojisteni": "Poistenie",
    "PojisteniNakladu": "Poistenie nákladu",
    "ZpusobFakturace": "Spôsob odosielania faktúr",
    "email1": "Odosielať faktúry emailom",
    "posta": "Odosielať faktúry poštou",
    "odlisadr": "Odlišná adresa",
    "Nezobrazovat": "Nezobrazovat",
    "OmezeneInformace": "Obmedzené informácie",
    "NonGdprInfo": "Non-GDPR informácie",
    "ZkraceneInformace": "Skrátené informácie",
    "Fax": "Fax",
    "FrekvenceFaktur": "Frekvencia faktúr",
    "Stav": "Stav",
    "Podstav": "Podstav",
    "Dispecer": "Dispečer",
    "dispeceri": "Dispečeri",
    "EmailsFilter": "E-mail (prehľadá Prevádzkárne, Dispečery, Účtovné)",
    "PhoneNumbersFilter": "Tel. č. (prehľadá Prevádzkárne, Dispečery, Účtovné)",
    "TooltipZmenaVeFirme": "Zmena sa prejaví v celej firme",
    "ProcessProvozovny": "Spracovať ostatné prevádzky danej firmy?",
    "not1": "Poznámka",
    "poznamka": "Administrátorská poznámka",
    "Records": "Záznamy",
    "Uzavreno": "Uzavreté",
    "DatumUzavreni": "Dátum uzavretia",
    "ManualDataFetch": "Použite základný / rozšírený filter pre možné vyhľadanie 30 relevantných prevádzok",
    "PoslPripoj": "Posledné pripojenie",
    "PocPripoj": "Počet pripojení",
    "PocetUzivatelu": "Počet používateľov",
    "Zalozen": "Založený",
    "datzmenstav": "Zmena stavu",
    "SpStat": "Špecializácia na štáty",
    "jazyky": "Jazyky",
    "Auta": "Vlastné vozidlá",
    "vs": "Variabilný symbol",
    "GenerateUsers": "Generovať používateľa",
    "ToolTipGenerateUsers": "Zadajte, koľko má byť automaticky vygenerovaných užívateľov s licenciami",
    "ContainsNumberOfBlocked": "Prevádzkareň obsahuje telefónne čísla blokovaných prevádzok: {0}. Chcete uložiť záznam?",
    "okoli": "Okolie",
    "radius": "Okolie [km]",
    "airDistance": "Vzdialenosť [km]",
    "notEqualAddress": "Adresa firmy a prevádzky nesúhlasí",
    "equalAddress": "Adresa firmy a prevádzky súhlasí",
    "historieUzivatelu": "História užívateľov",
    "Smlouvy": "Zmluvy",
    "LastChange": "Posledná zmena"
  },
  "PVI": {
    "RefreshRecord": "Obnoviť (zostáva {{value}}x)",
    "Refresh": "Obnoviť",
    "UpdateRecord": "Uložiť zmeny (zostáva {{value}}x)",
    "Inserted": "Vložené",
    "Modified": "Zmena",
    "Creator": "Užívateľ",
    "CreatedOn": "Dátum vytvorenia",
    "ModifiedOn": "Dátum zmeny",
    "ListEmpty": "Zoznam je prázdny",
    "Marked": "Označené",
    "InvalDuv": "Dôvod zneplatnenia",
    "Valid": "Platný",
    "codes": {
      "P": "P",
      "V": "V",
      "I": "I",
      "All": "PVI",
      "S": "S",
      "T": "T",
      "H": "H",
      "Pr": "P",
      "U": "U",
      "F": "F"
    },
    "tooltip": {
      "P": "Prepravy",
      "V": "Voľné vozidlá",
      "I": "Inzercia",
      "All": "PVI",
      "S": "Zoznam vozidiel",
      "T": "Trasy",
      "H": "hlásenie chýb",
      "Pr": "Prevádzkárne",
      "U": "Používatelia",
      "F": "Firmy",
      "UpdateButton": "Uloženie / Obnova aktualizuje dátum, podľa ktorého sa radí ponuky v prezeraní.",
      "UpdateDisabledButton": "Už bol vyčerpaný maximálny počet opakovaného uloženia ponuky"
    }
  },
  "RAALKratkyKody": {
    "N": "N",
    "S": "S",
    "J": "I",
    "ADR": "ADR"
  },
  "Sections": {
    "Start": "Úvod",
    "Task": "Zadanie",
    "Browse": "Prezeranie",
    "Company": "Prevádzkárne",
    "Messages": "RAAL správy",
    "ArchiveTask": "Archív zadania",
    "ArchiveBrowse": "Archív prehliadania",
    "Pass": "Prejazdy",
    "Fill": "Dokládky",
    "CollectionService": "Zberná služba",
    "Mileage": "Kilometrovník",
    "Assign": "Zadanie",
    "View": "Prezeranie",
    "Master": "Správa",
    "Administration": "Administracia",
    "Logy": "Logy",
    "Help": "Pomoc",
    "Spravy": "Správy",
    "Informations": "Informácie",
    "Learning": "Školenia",
    "Support": "Užívateľská podpora",
    "Stiznosti": {
      "Title": "Sťažnosti",
      "Štatistiky": "Štatistiky"
    },
    "Admin": {
      "Offers": "Ponuky",
      "History": "História",
      "Archiv": "Archív"
    },
    "History": "História",
    "User": {
      "Kilometrovnik": "Kilometrovník"
    },
    "Search": "Vyhľadávanie",
    "Soubory": "Súbory"
  },
  "ShowHidePhones": {
    "true": "Telefónne čísla",
    "false": "Skryť"
  },
  "ShowHideMap": {
    "true": "Zadať súradnice",
    "false": "Skryť"
  },
  "Stat": {
    "Id": "Id",
    "nazevCz": "Názov",
    "countryCode": "Kód",
    "Flag": "Vlajka",
    "TooltipKodZeme": "Vyplňte kód štátu na účely OpenStreetMap",
    "predvolba": "Predvoľba"
  },
  "State": {
    "true": "aktívna",
    "false": "neaktívny"
  },
  "SystemParameter": {
    "Value": "Hodnota"
  },
  "Texty": {
    "Kod": "Kód",
    "Hodnota": "Hodnota ({0})",
    "TypTextu": "Typ"
  },
  "Theme": {
    "ThemeChange": "Světlá / Tmavá téma",
    "Title": "Téma",
    "Dark": "Tmavé",
    "Light": "Svetlé"
  },
  "ThunderNews": {
    "Notifications": "Notifikace",
    "MessagesType": "Typ správy",
    "MessagesInstantLabel": "Bleskovka",
    "MessagesInstantUsers": "Upozornění: Pokud nezvolíte konkrétního Uživatele / Firmu / Provozovnu, blesková zpráva se odešle VŠEM uživatelům!",
    "MessagesValidTo": "Platnosť do",
    "NoMessages": "Žiadne správy",
    "AllMessages": "Všetky správy",
    "Predmet": "Predmet",
    "TypZpravy": "Typ správy",
    "Notification": "Máte novú správu",
    "Ok": "Skryť",
    "Open": "Otvoriť"
  },
  "TimeMeasures": {
    "minutes": {
      "plural": "minúty",
      "singular": "minúta",
      "genitivPlural": "minút"
    },
    "hours": {
      "plural": "hodiny",
      "singular": "hodina",
      "genitivPlural": "hodín"
    }
  },
  "User": {
    "Id": "ID používateľa",
    "Title": "Užívateľ",
    "provozovna": "Prevádzkareň",
    "login": "Prihlasovacie meno",
    "jmeno": "Meno používateľa",
    "Password": "Heslo",
    "SignIn": "Prihlásenie",
    "AddNew": "Pridanie nového účtu",
    "ChangePassword": "Zmena hesla",
    "Switch": "Prepnúť",
    "Remove": "Odobrať",
    "roles": "Úloha",
    "IPAdresaPosledni": "Posledná IP adresa",
    "View": "Zadávateľ",
    "ViewInfo": "Informácie o zadávateľovi",
    "ToolTipGenerateUsers": "Generovanie užívateľov a licencií",
    "KdoZmenil": "Kto změnil",
    "NoUserRoleError": "Toto je jedna licencia, zastavte prosím celý priestor.",
    "lastAction": "Posledná akcia"
  },
  "LoginPage": {
    "Info": "1. DATABANKA NÁKLADOV A VOĽNÝCH VOZIDIEL od roku 1992",
    "NewPassword": "Nové heslo",
    "ConfirmPassword": "Heslo znova",
    "Captcha": "Kód z obrázku",
    "GenerateNewCaptcha": "Generovať nový obrázok",
    "RememberMe": "Zapamätaj si ma",
    "TroubleLoggingIn": "Máte problémy s prihlásením?",
    "RegularCustomersTitle": "Stáli zákazníci",
    "RegularCustomersInfo": "Prihlasovacie údaje Vám boli zaslané na mail z adresy <a href=\"mailto:noreply@raaltrans.com\">noreply@raaltrans.com</a>. V prípade problémov nás kontaktujte <span>(+421 376 581 230)</span> a my Vám radi pomôžeme. Prípadne nás môžete kontaktovať aj na <a href=\"mailto:support@raal.sk\">support@raal.sk</a>.",
    "NewCustomersTitle": "Noví zákazníci",
    "NewCustomersInfo": "Na našich webových stránkach <a href=\"https://www.raal.sk/\" target=\"_blank\" rel=\"noopener noreferrer\">www.raal.sk</a> nájdete objednávkový formulár. Po vybavení objednávky Vás budeme kontaktovať."
  },
  "InputErrors": {
    "Password": {
      "ConfirmationNotMatch": "Heslá sa nezhodujú.",
      "TooShort": "Heslo je príliš krátke, minimálna dĺžka je 6 znakov."
    }
  },
  "UserModified": {
    "Title": "Užívateľské dáta zmenené",
    "Body": "Pre kompletnú inicializáciu zmien Vášho používateľského účtu je potrebné sa opakovane prihlásiť. Odhlásiť teraz?"
  },
  "Validations": {
    "RychlyKontaktLengthConstraint": "Počet znakov musí byť 9 až 15"
  },
  "Websocket": {
    "Connected": "Pripojené",
    "Disconnected": "Odpojené",
    "Connecting": "Pripájam"
  },
  "YesNo": {
    "false": "Nie",
    "true": "Áno"
  },
  "Kilometrovnik": {
    "Psc": "PSČ",
    "Nazev": "Názov",
    "Cc2": "Kód krajiny",
    "Typ": "Typ",
    "Lat": "Zemepisná šírka",
    "Lon": "Zemepisná dĺžka",
    "DisplayRoute": "Trasa a náklady",
    "DisplayRouteOnly": "Iba trasa",
    "Vozidlo": "Vozidlo",
    "Note": "Do polí \"Přejezd odkud\" a \"Prejazd kam\" zadajte miesta, ktoré čo najviac zodpovedajú Vašej trase. To môže byť napr. miesto, kde stojí nevyťažené auto a miesto, kam s ním chcete dôjsť, napr. sídlo firmy. V poliach \"Maximálny prejazd odkiaľ/kam/celkom\" je možné nepovinne zadať, z akého veľkého okolia od zadaných miest sa majú ponuky zobrazovať.Ak necháte príslušné okolie nevyplnené, vyberú sa ponuky bez ohľadu na vzdialenosť od zvoleného miesta."
  },
  "Contacts": {
    "SendEmail": "Poslať email",
    "SipEmail": "Kontaktovať pomocou SIP protokolu (Teams, ai)",
    "Call": "Volať",
    "SMS": "Poslať SMS",
    "WhatsApp": "WhatsApp",
    "Viber": "Viber"
  },
  "Klasifikace": {
    "klasifikace": "Klasifikácia",
    "klasifikaceShort": "[K]",
    "nezobrazovat": "Nezobrazovať ponuky",
    "poznamka": "Užívateľská poznámka"
  },
  "ProfilVozidla": {
    "title": "Vozidlá",
    "nazev": "Názov",
    "casovyNakladH": "Časové náklady [cena/h]",
    "casovyNakladHTooltip": "(napr. mzda vodiča)",
    "fixniNaklad": "Fixní náklady [cena/km]",
    "fixniNakladTooltip": "(odpisy)",
    "spotreba": "Spotreba paliva [l / 100 km]",
    "cenaPaliva": "Cena paliva [cena/l]",
    "tollPrice": "Ceny mýta",
    "osrmProfile": "Navigační profil",
    "displayPreprava": "Zobraziť v prepravách",
    "displayVozidlo": "Zobraziť vo voľných vozidlách",
    "defaultPreprava": "Predvolené v prepravách",
    "defaultVozidlo": "Predvolené vo voľných vozidlách",
    "properties": "Vlastnosti vozidla",
    "maxWeight": "Celková hmotnosť vozidla [t]",
    "maxHeight": "Celková výška vozidla [m]",
    "maxWidth": "Celková šírka vozidla [m]",
    "maxLength": "Celková dĺžka vozidla [m]",
    "hazmat": "prevoz nebezpečných materiálov",
    "hazmatWater": "převoz materiálů kontaminujících vodu",
    "switzerland": "vyhýbanie sa Švajčiarsku, pokiaľ nie je v danom štáte nakládka/vykládka",
    "delka": "Dĺžka nákladu [m]",
    "vaha": "Váha nákladu [t]"
  },
  "CenaMyta": {
    "title": "Cena mýta",
    "kodZeme": "Štát",
    "sazba": "Sadzba [cena/km]",
    "typSilnice": "Typ cesty",
    "CurrencyRequiredWhenPrice": "Musí byť vyplnené spoločne so sadzbou"
  },
  "Trasa": {
    "title": "Trasy",
    "Calculate": "Prepočítať",
    "CalculateAndSave": "Prepočítať a uložiť",
    "nazev": "Názov",
    "state": "Stav výpočtu",
    "profilVozidla": "Profil vozidla",
    "currency": "Mena výpočtu nákladov",
    "distance": "Vzdialenosť",
    "duration": "Čas jazdy",
    "cost": "Náklady",
    "mpz": "Mpz",
    "distancePaid": "Platené úseky [km]",
    "distanceUnpaid": "Neplatené úseky [km]",
    "currencyTooltip": "Orientačný výpočet podľa kurzu ČNB",
    "modifiedOn": "Dátum zmeny"
  },
  "KilometrovnikHlaseni": {
    "osrmId": "OSRM Id",
    "report": "Report",
    "štatút": "Stav",
    "užívateľ": "Používateľ",
    "osrmEdit": "Nahlásiť na OSRM",
    "process": "Uzavrieť",
    "reportSent": "Hlásenie bolo odoslané",
    "title": "Hlásené chyby",
    "datIns": "Dátum vytvorenia",
    "dátUzavretie": "Dátum uzavretia"
  },
  "Dokladky": {
    "kilometrovnik": "Kilometrovník dokládka",
    "nakladka": "Nakládka",
    "vykladka": "Vykládka",
    "odkud": "Preprava - odkiaľ",
    "kam": "Preprava - kam",
    "vyhodnost": "Výhodnosť",
    "dokladky": "Dokládky",
    "zajizdka": "Zachádzka [km]",
    "datumDokladkyOd": "Dátum dokládky od",
    "datumDokladkyDo": "Dátum dokládky do",
    "zadaniDokladky": "Dokládky prepráv",
    "calculate": "Hľadať dokládky",
    "preprava": "Preprava",
    "total": "Celkom",
    "original": "Pôvodný",
    "dokladka": "S dokládkou",
    "Preprava": "Predvolená preprava",
    "datum": "Dátum"
  },
  "SbernaSluzba": {
    "sbernaSluzba": "Sběrná služba",
    "datumOd": "Prepravy od",
    "datumDo": "Prepravy do",
    "calculate": "Vypočítať",
    "trasa": "Trasa",
    "vozidlo": "Vozidlo",
    "datum": "Dátum",
    "search": "Zadanie zbernej služby",
    "prepravaOrder": "Pořadí",
    "map": "Zobraziť mapu",
    "noTransportsFound": "Žiadne vhodné prepravy neboli nájdené",
    "profilVozidlaAtributy": "Profil vozidla musí mať vyplnené: mena, spotreba, fixné náklady",
    "parametryVypoctu": "Parametre výpočtu",
    "maxPrejezd": "Maximanie prejazd z miesta nakládky [km]",
    "maxZajizdka": "Maximanie zachádzky na trase [km]",
    "nalezeniPreprav": "Naplnenie zbernej služby",
    "neobsahujePrepravy": "Doplniť prepravy automaticky?",
    "doplnitPrepravy": "Automaticky doplniť prepravy",
    "pridatPrepravu": "Pridať prepravu ručne",
    "pridaniPrepravy": "Pridať prepravy automaticky",
    "calculateToll": "Vypočítať náklady",
    "maxDelka": "Maximálne dĺžky čiastkových nákladov [m]",
    "maxVaha": "Maximálne váhy čiastkových nákladov [t]",
    "PrepravaRemoveConfirm": "Skutočne chcete odstrániť hlavnú ponuku? Potvrdením tejto akcie dôjde k odstráneniu všetkých ponúk.",
    "noveZadani": "Nové zadanie",
    "noveZadaniBody": "Zadanie obsahuje prepravy, prajete si prepravy odstrániť a začať znova?",
    "processing": "Akciu nemožno vykonať, pretože prebieha výpočet."
  },
  "ServerErrors": {
    "404": "Informácie neboli na serveri nájdené",
    "500": "Vnútorná chyba servera",
    "FailedToFetch": "Nepodarilo sa načítať dáta, skontrolujte spojenie k internetu.",
    "UnableToGeocode": "Nepodarilo sa geokódovať súradnicu",
    "NetworkError": "Pri vybavovaní požiadavky došlo k chybe siete, skontrolujte pripojenie k internetu."
  },
  "SeznamMaps": {
    "Title": "Mapa Seznam.cz"
  },
  "SearchEnum": {
    "entityType": "Typ záznamu",
    "Preprava": {
      "PREPRAVA": "N",
      "PREPRAVA_HISTORIE": "N (H)",
      "PREPRAVA_ARCHIV": "A",
      "PREPRAVA_HISTORIE_ARCHIV": "A (H)"
    },
    "VolneVozy": {
      "VOZIDLO": "N",
      "VOZIDLO_HISTORIE": "N (H)",
      "VOZIDLO_ARCHIV": "A",
      "VOZIDLO_HISTORIE_ARCHIV": "A (H)"
    },
    "Inzerat": {
      "INZERAT": "N",
      "INZERAT_HISTORIE": "N (H)",
      "INZERAT_ARCHIV": "A",
      "INZERAT_HISTORIE_ARCHIV": "A (H)"
    }
  },
  "TrikratADost": {
    "Title": "Statistika \"3x a dosť\" ke dni {0}",
    "celkemPripadu": "Celkom {0} prípadov",
    "celkemCastky": "Celkom čiastky: {0}",
    "NadpripadyTotal": "Nadprípady: {0} otvorených a {1} uzavretých, z toho {2} uzavretých výpovedí",
    "Stav": "Stav",
    "Castka": "Čiastka",
    "CurrencyCode": "Mena",
    "PocetPripad": "Počet prípadov"
  },
  "DOMException": {
    "AbortError": "Načítanie dát bolo prerušené (alebo server nestihol odpovedať v požadovanom čase)"
  },
  "Diag": {
    "host": "Hosť",
    "serverPort": "Port",
    "healthy": "Stav",
    "instances": "Inštancia",
    "dbReplicas": "Databázové repliky",
    "couldNotLoad": "Nebolo možné načítať dáta",
    "replicationNotActive": "Databáza nie je replikovaná",
    "resources": "Zdroje",
    "requiredDbConnectionsRead": "Požadovaných DB spojenie na čítanie",
    "requiredDbConnectionsWrite": "Požadovaných DB spojenie pre zápis",
    "availableDbConnectionsRead": "Dostupných DB spojenie na čítanie (1 db)",
    "availableDbConnectionsWrite": "Dostupných DB spojenie pre zápis",
    "availableDbConnections": "Dostupných DB spojenia",
    "dbConnectionsNotEnough": "Počet databázových spojení nemusí stačiť na pokrytie požiadaviek serverov",
    "jobs": "Joby",
    "rateLimit": "Rate limit info"
  },
  "ArchiveRecord": {
    "typ": "Typ",
    "poznamka": "Poznámka",
    "owner": "Archivované"
  },
  "Archive": {
    "Archive": "Archív",
    "Archived": "Archivované"
  },
  "Poznamka": {
    "poznamka": "Poznámka",
    "uzívateľ": "Posledný autor",
    "datIns": "Dátum vytvorenia"
  },
  "CommonGrid": {
    "Historie": "História"
  },
  "DluhNepohOsoba": {
    "Prijmeni": "Priezvisko",
    "Jmeno": "Meno",
    "Rodcis": "Rodné číslo",
    "Adresa": "Adresa",
    "Ica": "Iča",
    "Poznamka": "Poznámka",
    "DatCas": "Dátum",
    "Uzivatel": "Zmenil",
    "Provozovny": "Prevádzkárne"
  },
  "EmailForm": {
    "Sender": "Odosielateľ",
    "Subject": "Predmet e-mailu",
    "Receiver": "Odoslať e-mail na adresu",
    "Body": "Obsah e-mailu"
  },
  "EmailStiznostiForm": {
    "smlouva": "Dátum uzavretia zmluvy",
    "pozastaveno": "Pozastavenie prístupu ku dňu"
  },
  "WithUserBase": {
    "Id": "Číslo",
    "DatCas": "Čas poslednej zmeny",
    "Uzivatel": "Posledná zmenil",
    "Zmena": "Posledná zmena",
    "Generovat": "Generovať a odoslať",
    "ChooseLanguage": "Zvoliť jazyk"
  },
  "DluhPripad": {
    "Dluznik": "Dlžník",
    "Veriteľ": "Veriteľ",
    "ZodpOsoba": "Zodpovedná osoba",
    "IcoDluznika": "IČO dlžníka",
    "DicDluznika": "DIČ dlžníka",
    "RegNazevDluznika": "Názov dlžníka",
    "RegUlicaDlz": "Ulica dlžníka",
    "RegSidloDlz": "Sídlo dlžníka",
    "RegPscDluz": "Psč dlžníka",
    "RegStatDluz": "Štát dlžníka",
    "PrefStatDluz": "Predvoľba dlžníka",
    "PrefMestoDluz": "Predvoľba mesta",
    "FaxDluz": "Fax dlžníka",
    "TelDluz": "Telefón dlžníka",
    "MobilDluz": "Mobil dlžníka",
    "HttpDluz": "WWW dlžníka",
    "EmailDlz": "Email dlžníka",
    "IcqDluz": "Icq dlžníka",
    "SkypeIdDluz": "Skype dlžníka",
    "ProvozovnaDluznika": "Prevádzkareň dlžníka",
    "ProvozovnaDluznikaStav": "Stav prevádzky dlžníka",
    "zodpMailDluz": "Zodpovedná osoba dlžníka",
    "IcoVer": "IČO veriteľa",
    "DicVer": "DIČ veriteľa",
    "RegNazevVer": "Názov veriteľa",
    "RegUliceVer": "Ulica veriteľa",
    "RegSidloVer": "Sídlo veriteľa",
    "RegPscVer": "Psč veriteľa",
    "RegStatVer": "Štát veriteľa",
    "PrefStatVer": "Predvoľba veriteľa",
    "ProvozovnaVer": "Prevádzkareň veriteľa",
    "ProvozovnaVerStav": "Stav prevádzky veriteľa",
    "zodpMailVer": "Zodpovedná osoba veriteľa",
    "PrefMestoVer": "Predvoľba mesta veriteľa",
    "FaxVer": "Fax veriteľa",
    "TelVer": "Telefón veriteľa",
    "MobilVer": "Mobil veriteľa",
    "HttpVer": "WWW veriteľa",
    "EmailVer": "Email veriteľa",
    "IcqVer": "Icq veriteľa",
    "SkypeIdVer": "Skype veriteľa",
    "KdoZaloz": "Kto založil",
    "DatCas": "Dátum",
    "Stav": "Stav",
    "ZodpTel": "Telefón zodpovednej osoby",
    "ZodpEmail": "Email zodpovednej osoby",
    "Kontakty": "Kontakty",
    "ProvozovnyFirmy": "Prevádzkárne nadradenej firmy",
    "AktKeDni": "Aktualizácia ku dňu",
    "AktKeDniButton": "Teraz",
    "GenerovatDopisVer": "Generovať a odoslať list veriteľovi",
    "GenerovaťDopisDluznik": "Generovať a odoslať list dlžníkovi",
    "StavProvozovnyDluznika": "Stav prevádzky dlžníka",
    "StavProvozovnyVer": "Stav prevádzky veriteľa",
    "StavAction": {
      "otevrit": "Otvoriť",
      "uzavrit": "Uzavrieť",
      "vyresitBezUhrady": "Vyriešiť bez úhrady",
      "promlcet": "Premlčať",
      "zamitnout": "Zamietnuť",
      "Confirmation": "Stav bol úspešne zmenený"
    },
    "PripadExist": "POZOR! S totožným dlžníkom aj veriteľom už v systéme existujú aktívne prípady: {pripadyIds}! Chcete pokračovať?",
    "FaktExist": "POZOR! Faktúra s týmto číslom už v systéme existuje v prípadoch: {pripadyIds}! Chcete pokračovať?",
    "PripadExistTitle": "Potvrdenie",
    "FaktExistTitle": "Potvrdenie",
    "Active": "Aktívne"
  },
  "DluhNadpripad": {
    "NadpripadId": "Nadprípad",
    "Stav": "Stav",
    "KdyPozastavit": "Kedy pozastaviť",
    "Vypoved": "Výpoveď",
    "Bod39": "Bod 3.9",
    "Upozorneni": "Upozornenie",
    "Confirmation": "Potvrdenie",
    "GenerovatDopisDluznik": "Generovať a odoslať list dlžníkovi",
    "StavAction": {
      "otevrit": "Otvoriť",
      "uzavrieťSuhradou": "Uzavrieť (s úhradou)",
      "uzavrieťBezUhrady": "Uzavrieť (bez úhrady)",
      "uzavrieťVypoved": "Uzavrieť (výpoveď)",
      "uzavrit39": "Uzavrieť (bod 3.9)",
      "New": "Naozaj chcete založiť nadprípad?",
      "CreateConfirmation": "Nadprípad bol úspešne vytvorený"
    },
    "Buttons": {
      "New": "Založiť nadprípad",
      "Navigate": "Zobraziť nadprípad",
      "NavigateToPripad": "Späť na prípad"
    }
  },
  "DluhFakt": {
    "Title": "Faktúry",
    "Cislo": "Číslo faktúry",
    "Castka": "Čiastka",
    "DatSplat": "Dátum splatnosti",
    "KdyUhradil": "Kedy uhradil",
    "KdoUhradil": "Kto uhradil",
    "KdyBezUhrady": "Kedy bez úhrady",
    "KdoBezUhrady": "Kto bez úhrady",
    "Stav": "Stav",
    "UdajeFaktury": "Údaje faktury",
    "OhlasUhr": "Ohlásenie úhrady veriteľom",
    "DuvodZamitnuti": "Dôvod zamietnutia",
    "Poznamka": "Poznámka",
    "Poznamky": "Poznámky",
    "StavAction": {
      "uhradit": "Uhradiť",
      "vyresitBezUhrady": "Vyriešiť bez úhrady",
      "promlcet": "Premlčať",
      "zamitnout": "Zamietnuť",
      "otevrit": "Otvoriť"
    }
  },
  "DluhSoub": {
    "Title": "Soubory",
    "Soubor": "Soubor",
    "NovySoubor": "Nový súbor",
    "Popis": "Popis",
    "Stazeni": "Stiahnutie súboru"
  },
  "DluhStateActionConfirmDialog": {
    "Title": "Potvrdenie",
    "Body": "Naozaj chcete vykonať akciu {{action}}?"
  },
  "ReportText": {
    "TextKey": "Kľúč",
    "Translation": "Preklad"
  },
  "Report": {
    "Language": "Jazyk"
  },
  "popis": "Popis",
  "NetworkError": "Chyba v sieti",
  "UnexpectedError": "Pri vybavovaní požiadavky došlo k neočakávanej chybe",
  "Error401": "Odhlásené",
  "Error401FingerPrint": "Boli ste odhlásení, pretože ste sa prihlásili v inom prehliadači.",
  "SignedOut": "Odhlásenie",
  "BadCredentials": "Zlé prihlasovacie údaje",
  "RecordCannotBeSaved": "Záznam nemožno uložiť",
  "ModificationConcurrency": "Entita bola modifikovaná iným užívateľom",
  "LoginFailed": "Prihlásenie zlyhalo",
  "LoginFailedGeneratedPwd": "Prihlásenie zlyhalo. Skontrolujte prosím, či máte zadané správne heslo pomocou tlačidla oka vedľa hesla.",
  "PasswordChangeRequired": "Vyžaduje sa zmena hesla",
  "TryConnectAgain": "Znovu skúsiť pripojiť",
  "ConnectionError": "Chyba pripojenia. Buď sú servery nedostupné alebo nemáte prístup k internetu.",
  "Unique": "Záznam je duplicitný",
  "ipDll": "Posledná IP",
  "DataFetchError": "Pri načítaní dát došlo k chybe",
  "WebsocketConnecting": "Naväzujem spojenie",
  "Attempt": "Pokus",
  "ArchiveRecordShort": "Archivovať",
  "RestoreRecordShort": "Obnoviť",
  "ArchiveSuccess": "Archivácia prebehla úspešne",
  "AppActivityTypeName": "Typ",
  "AppActivitySubTypeName": "Podtyp",
  "ActivityViewType": "Spôsob prehliadnutia",
  "ArchiveView": "Archivace prohlížené nabídky",
  "Actions": "Akcia",
  "Errors": {
    "CouldNotFetchCode": "Pri načítaní voľného kódu prevádzky došlo k chybe.",
    "CouldNotFetchCount": "Pri načítaní počtu záznamov došlo k chybe.",
    "CouldNotCancelEdit": "Nie je možné zrušiť editáciu",
    "CouldNotEdit": "Nie je možné editovať záznam",
    "CouldNotDelete": "Nie je možné zmazať záznam",
    "CouldNotDeleteIsLocked": "Nie je možné vymazať záznam, záznam je uzamknutý",
    "CouldNotArchiveIsLocked": "Nie je možné archivovať záznam, záznam je uzamknutý",
    "LockLost": "Editování bylo zrušeno, protože bylo zrušeno uzamčení záznamu",
    "SystemOverload": "Stránku nie je možné zobraziť, pretože je systém vyťažený obsluhou iných požiadaviek, skúste stránku zobraziť neskôr."
  },
  "Unable to geocode": "Nie je možné určiť adresu umiestnenia",
  "Consent": {
    "BasicInfo": "Základné informácie",
    "Agreements": "Odsúhlasenie právnej dokumentácie",
    "MinimalRequirements": "Minimálne požiadavky na systém",
    "VPAgreement": "Súhlasím so všeobecnými podmienkami",
    "LPAgreement": "Súhlasím s licenčnými podmienkami",
    "GDPRAgreement": "Súhlasím so zásadami ochrany osobných údajov",
    "PDIAgreement": "Súhlasím so zmluvou o poskytovaní dopravných informácií",
    "Download": "Stiahnuť",
    "Open": "Otvoriť"
  },
  "PravniDokumentace": {
    "TypSmlouvy": "Typ zmluvy",
    "Soubor": "Zmluva",
    "DatumPodepsaniRall": "Dátum podpisu RAALTRANS",
    "DatumPodepsaniPrijemce": "Dátum potvrdenia príjemcom",
    "DatumVytvoreni": "Dátum vytvorenia",
    "Sign": "Podpísať zmluvy",
    "SignConfirm": "Naozaj si želáte podpísať všetky zmluvy?",
    "AlreadySigned": "Zmluvy už boli podpísané",
    "SignFailed": "Podpísanie zmlúv zlyhalo",
    "SignSuccess": "Zmluvy boli podpísané",
    "Delete": "Zmazať zmluvy",
    "DeleteConfirm": "Naozaj si prajete všetky zmluvy vymazať?",
    "AlreadyDeleted": "Zmluvy už boli zmazané",
    "DeleteFailed": "Zmazanie zmlúv zlyhalo",
    "DeleteSuccess": "Zmluvy boli zmazané"
  },
  "StaticLinks": {
    "Thumbnail": {
      "MasterTutorial": "/videos/thumbnail/master_tutorial_sk.jpg"
    }
  },
  "Cms": {
    "Oznameni": {
      "TitleAfterLogin": "Novinky od posledného prihlásenia",
      "TitleStomp": "Dôležité oznámenie",
      "ReloadPage": "OBNOVIŤ STRÁNKU",
      "ReloadInfo": "Práve došlo k aktualizácii aplikácie na novú verziu - pre správne fungovanie je potrebné stránku obnoviť.",
      "ReloadDisclaimer": "V opačnom prípade Vám niektorá z funkcionalít nemusí fungovať správne."
    }
  },
  "Oznaceni": {
    "DeleteViewRecords": "Vymazať históriu zobrazenia",
    "ViewRecordsClearConfirm": "Naozaj si prajete vymazať históriu zobrazenia?"
  },
  "NabidkaMultiCRUD": {
    "RefreshTooltip": "Obnoviť záznamy",
    "DeleteTooltip": "Vymazať záznamy",
    "RefreshConfirm": "Naozaj chcete obnoviť všetky označené záznamy?",
    "DeleteConfirm": "Naozaj chcete vymazať všetky označené záznamy?",
    "RefreshSuccess": "Ponuky boli obnovené",
    "DeleteSuccess": "Ponuky boli vymazané",
    "UnableToRefresh": "Niektoré ponuky sa nepodarilo obnoviť",
    "UnableToDelete": "Niektoré ponuky sa nepodarilo vymazať",
    "AlreadyDeletedRecords": "Už neexistujúce: {0}x",
    "LockedRecords": "Uzamknuté: {0}x",
    "MaxEditsReachedRecords": "Vyčerpaný počet obnovení: {0}x",
    "ExpiredOfferRecords": "Neplatné ponuky: {0}x",
    "InvalidOffersDateChangeInfo": "Vybrané ponuky obsahujú dátum, ktorý už leží v minulosti. Pre možnosť obnovy je potrebné nastaviť nový dátum:",
    "ValidOffersDateChangeInfo": "Upozornenie: výber obsahuje aj platné ponuky - u nich bude zmenený iba dátum do.",
    "DateFrom": "Dátum od",
    "DateTo": "Dátum do"
  },
  "VlastniMisto": {
    "titulek": "Pridanie nového miesta",
    "Pridat": "Pridať nové miesto",
    "mpz": "MPZ",
    "psc": "PSČ",
    "mesto": "Mesto",
    "notFound": "Miesto nebolo nájdené. Môžete ju použiť, ale kilometre sa nevypočítajú."
  },
  "Mail": {
    "KodZadavateleNabidky": "KÓD ZADAVATEĽA PONUKY",
    "PrepravaSubject": "Reakcia na ponuku prepravy v RAALTRANS ({0})",
    "VozidloSubject": "Reakcia na ponuku voľného vozidla v RAALTRANS ({0})",
    "InzeratSubject": "Reakcie na ponuku inzerátu v RAALTRANS ({0})",
    "PrepravaBodyBegin": "Reagujeme na Vašu ponuku prepravy zverejnenú v databanke RAALTRANS (náš kód v RAALTRANS je {0}):",
    "VozidloBodyBegin": "Reagujeme na Vašu ponuku voľného vozidla zverejnenú v databanke RAALTRANS (náš kód v RAALTRANS je {0}):",
    "InzeratBodyBegin": "Reagujeme Váš inzerát zverejnený v databanke RAALTRANS (náš kód v RAALTRANS je {0}):"
  },
  "DataTemplate": {
    "Save": "Uložiť šablónu",
    "Name": "Názov šablóny",
    "Add": "Pridať šablónu",
    "Select": "Vyberte šablónu pre založenie ponuky",
    "SavedTemplates": "Uložené šablóny",
    "NoData": "Nenájdená žiadna šablóna",
    "DeleteConfirmationText": "Naozaj chcete šablónu zmazať?",
    "RemoveTemplate": "Zmazať",
    "Saved": "Šablóna bola uložená",
    "SaveFailed": "Šablónu sa nepodarilo uložiť, názov je už priradený k inej šablóne, alebo je názov veľmi dlhý",
    "Edited": "Šablóna bola upravená",
    "EditFailed": "Šablónu sa nepodarilo upraviť",
    "Removed": "Šablóna bola odstránená",
    "RemoveFailed": "Šablónu sa nepodarilo odstrániť"
  },
  "Here": {
    "Dial": {
      "TitulekTabulky": "Vozidlá",
      "Nazev": "Názov",
      "Mena": "Mena",
      "Vyska": "Výška [m]",
      "Sirka": "Šírka [m]",
      "Delka": "Dĺžka [m]",
      "CelkovaVaha": "Celková hmotnosť [t]"
    },
    "TrasaDetail": {
      "CelkovaCenaZaSekci": "Celková cena za sekciu",
      "PoplatkyV": "Poplatky v",
      "Routing": "TRASA",
      "Sekce": "Sekcia",
      "OdhadovanaDobaJizdy": "Odhadovaný čas jazdy",
      "Poplatky": "MÝTO",
      "PoplatkyCelkem": "Poplatky spolu",
      "DetailPoplatku": "Pre rozbalenie / zbalenie detailu mýta kliknite sem",
      "Table": {
        "IndexMyta": "Index mýta",
        "IndexPoplatku": "Index poplatku",
        "LokalniCena": "Lokálna cena",
        "KovertovanaCena": "Konvertovaná cena",
        "Zeme": "Krajina",
        "MytnySystem": "Mýtny systém",
        "PlatebniMetody": "Platobné metódy",
        "PlatebniMetoda": {
          "cash": "Hotovosť",
          "bankCard": "Banková karta",
          "creditCard": "Kreditná karta",
          "transponder": "Transpondér",
          "travelCard": "Cestovná karta",
          "passSubscription": "Predplatné jazdenky",
          "videoToll": "Video mýto"
        },
        "Predplatne": {
          "days": "Denné",
          "months": "Mesačné",
          "annual": "Ročné"
        }
      },
      "HereError": "Pravdepodobne profil vozidla obsahuje nepovolené kombinácie.",
      "HereErrorNoRoutes": "Podľa polohy a profilu vozidla sa nenašla žiadna trasa. Dôvodom môže byť napríklad obmedzená krajina a trasa začínajúca alebo končiaca v tejto krajine.",
      "Upozorneni": "Upozornenie",
      "NotTruckTransportNotification": "Upozornenie: na trase sa niektoré druhy dopravy líšia (trajekt/vlak) od skutočnej osi. Poplatky nemusia byť úplné.",
      "NotTollsTransportNotification": "Upozorňujeme, že v tejto časti nie sú uvedené žiadne poplatky. To však neznamená, že žiadne neexistujú. Je možné, že ich systém nedokázal zistiť.",
      "ZobrazitMapu": "Pre rozbalenie / zbalenie mapy kliknite sem",
      "KliknutimVybertePrujedniBod": "Kliknutím do mapy vyberte prejazdný bod.",
      "VyberZMapy": "Výber z mapy"
    },
    "Kilometrovnik": {
      "Button": {
        "Trasa": "TRASA",
        "Naklady": "MÝTO",
        "TrasaANaklady": "TRASA A MÝTO"
      },
      "ProfilVozidla": "Profil vozidla",
      "ViaInput": "Punkt przejścia",
      "AddViaToList": "Pridať do zoznamu"
    },
    "Nazev": "Názov profilu vozidla",
    "Currency": {
      "Title": "Náklady na vrátenie v mene",
      "TitleToolTip": "Náklady na vypočítanú trasu budú vrátené v tejto mene",
      "Shortcut": {
        "EUR": "Euro",
        "GBP": "Britská libra",
        "CHF": "Švajčiarsky frank",
        "NOK": "Nórska koruna",
        "SEK": "Švédska koruna",
        "DKK": "Dánska koruna",
        "ISK": "Islandská koruna",
        "CZK": "Česká koruna",
        "PLN": "Poľský zlotý",
        "HUF": "Maďarský forint",
        "RON": "Rumunský lei",
        "BGN": "Bulharský lev",
        "HRK": "Chorvátska kuna",
        "RSD": "Srbský dinár",
        "TRY": "Turecká líra",
        "BAM": "Bosniansko-hercegovinská konvertibilná marka",
        "MKD": "Macedónsky denár",
        "BYN": "Bieloruský rubeľ",
        "MDL": "Moldavský lei",
        "UAH": "Ukrajinská hrivna",
        "RUB": "Ruský rubeľ",
        "GEL": "Gruzínske lari",
        "AZN": "Azerbajdžanský manat",
        "AMD": "Arménsky dram",
        "ALL": "Albánsky lek"
      }
    },
    "Vehicle": {
      "Section": "Základné informácie o vozidle",
      "PayloadCapacity": "Povolená nosnosť vrátane prívesov [t]",
      "Type": "Typ nákladného auta",
      "TypeEnum": {
        "straightTruck": "Priamy nákladný vůz",
        "tractor": "Traktor"
      },
      "TunnelCategory": "Kategória tunelov",
      "TunnelCategoryToolTip": "Kategória tunela slúžiaca k obmedzeniu prepravy konkrétneho tovaru",
      "TrailerAxleCount": "Celkový počet náprav (všetky prívesy)",
      "TrailerAxleCountToolTip": "Celkový počet náprav naprieč všetkými prívesmi pripojenými k vozidlu",
      "AxleCount": "Celkový počet náprav vo vozidle",
      "TrailerCount": "Počet prívesov",
      "WeightPerAxleGroup": "Hmotnosť na skupinu náprav [t]",
      "WeightPerAxle": "Váha na nápravu [t]",
      "GrossWeight": "Celková hmotnosť [t]",
      "Length": "Dĺžka [m]",
      "Width": "Šírka [m]",
      "Height": "Výška [m]"
    },
    "TollEmission": {
      "Section": "Parametre pre výpočet mýta podľa emisií",
      "TollEmissionType": "Emisný typ",
      "TollEmissionTypeCO2EmissionClass": "CO2 emisná trieda",
      "Type": {
        "euro1": "EURO1",
        "euro2": "EURO2",
        "euro3": "EURO3",
        "euro4": "EURO4",
        "euro5": "EURO5",
        "euro6": "EURO6",
        "euroEev": "EUROEEV"
      }
    },
    "HazardousGoods": {
      "Section": "Zoznam nebezpečného tovaru prepravovaného vo vozidle",
      "HazardousGood": {
        "explosive": "Explozívny",
        "gas": "Plyn",
        "flammable": "Horľavý",
        "combustible": "Horľavý",
        "organic": "Organický",
        "poison": "Jed",
        "radioactive": "Radioaktívny",
        "corrosive": "Žieravý",
        "poisonousInhalation": "Jedovatý pri inhalácii",
        "harmfulToWater": "Škodlivý pre vodu",
        "other": "Iný"
      }
    },
    "AvoidFeatures": {
      "Section": "Vyhnúť sa špecifickým cestám",
      "Feature": {
        "tunnel": "Tunel",
        "ferry": "Trajekt"
      }
    },
    "ExcludeCountry": {
      "Section": "Vyhnúť sa nasledujúcim krajinám",
      "Country": {
        "ALB": "Albánsko",
        "AND": "Andorra",
        "ARM": "Arménsko",
        "AUT": "Rakúsko",
        "BLR": "Bielorusko",
        "BEL": "Belgicko",
        "BIH": "Bosna a Hercegovina",
        "BGR": "Bulharsko",
        "HRV": "Chorvátsko",
        "CYP": "Cyprus",
        "CZE": "Česká Republika",
        "DNK": "Dánsko",
        "EST": "Estónsko",
        "FIN": "Fínsko",
        "FRA": "Francúzsko",
        "GEO": "Gruzínsko",
        "DEU": "Nemecko",
        "GRC": "Grécko",
        "HUN": "Maďarsko",
        "ISL": "Island",
        "IRL": "Írsko",
        "ITA": "Taliansko",
        "XKX": "Kosovo",
        "LVA": "Lotyšsko",
        "LIE": "Lichtenštajnsko",
        "LTU": "Litva",
        "LUX": "Luxembursko",
        "MLT": "Malta",
        "MDA": "Moldavsko",
        "MCO": "Monako",
        "MNE": "Čierna Hora",
        "NLD": "Holandsko",
        "MKD": "Severné Makedónsko",
        "NOR": "Nórsko",
        "POL": "Poľsko",
        "PRT": "Portugalsko",
        "ROU": "Rumunsko",
        "RUS": "Rusko",
        "SMR": "San Maríno",
        "SRB": "Srbsko",
        "SVK": "Slovensko",
        "SVN": "Slovinsko",
        "ESP": "Španielsko",
        "SWE": "Švédsko",
        "CHE": "Švajčiarsko",
        "TUR": "Turecko",
        "UKR": "Ukrajina",
        "GBR": "Veľká Británia",
        "VAT": "Vatikán"
      }
    },
    "DefaultPVSettings": {
      "Section": "Nastavenia v prepravách a voľných vozidlách",
      "Preprava": "Predvolené v prepravách",
      "Vozidlo": "Predvolené vo voľných vozidlách"
    },
    "CustomNaklady": {
      "NaHodinu": "Náklady na hodinu",
      "NakladNaKm": "Náklady na km",
      "CelkemZaHodin": "Náklady na trasu (vzdialenosť * hodinová sadzba)",
      "CelkemZaKm": "Náklady na trasu (vzdialenosť * cena za km)",
      "Celkem": "Náklady na trasu celkom",
      "Titulek": "VLASTNÉ NÁKLADY"
    }
  }
};
export default sk;