import {Dial} from "../../../../raal_components/dial/Dial";
import {FormField, FormInputType} from "../../../../raal_components/form/Form";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {ArchivPoznamka} from "../../../../model/ArchivPoznamka";
import {Grid} from "@mui/material";
import {ComponentMode} from "../../../../routes";
import {useParams} from "react-router-dom";
import {useDidMount} from "../../../../../common/component/hooks/SharedHooks";
import {useHistoryCustom} from "../../../../raal_components/controller/NavigationHelper";
import {useLocation} from "react-router";

interface ArchivPoznamkyDialProps {
    url?: string
    mode?: ComponentMode
    nabidkaId?: number
    viewArchive?: boolean
    nabidkaType?: 'P' | 'V' | 'I'
}

export function ArchivPoznamkyDial(props: ArchivPoznamkyDialProps) {
    const {t} = useTranslation();
    const {nabidkaId} = useParams<{nabidkaId: string}>();
    const {replace} = useHistoryCustom();
    const {pathname} = useLocation<any>();

    const getType = () => {
        if (props.nabidkaType === "P") return 'prepravy';
        if (props.nabidkaType === "V") return 'volnevozy';
        if (props.nabidkaType === "I") return 'inzerce';
    }

    const getUrl = () => {
        if (!props.viewArchive) {
            if (props.nabidkaType === "P") return `user/preprava-archiv/${nabidkaId ?? props.nabidkaId}/poznamka`;
            if (props.nabidkaType === "V") return `user/vozidlo-archiv/${nabidkaId ?? props.nabidkaId}/poznamka`;
            if (props.nabidkaType === "I") return `user/inzerat-archiv/${nabidkaId ?? props.nabidkaId}/poznamka`;
        } else {
            if (props.nabidkaType === "P") return `user/preprava-prohlizeni-archiv/${nabidkaId??props.nabidkaId}/poznamka`;
            if (props.nabidkaType === "V") return `user/vozidlo-prohlizeni-archiv/${nabidkaId??props.nabidkaId}/poznamka`;
            if (props.nabidkaType === "I") return `user/inzerat-prohlizeni-archiv/${nabidkaId??props.nabidkaId}/poznamka`;
        }
    }

    useDidMount(() => {
        // Replace url na detail archivu
        if (!props.nabidkaId && props.mode === ComponentMode.GridMode) {
            const index = pathname.indexOf('/poznamky');
            const url = pathname.slice(0, index);
            replace(url);
        }
    })

    return <Dial<ArchivPoznamka>
        mode={props.mode}
        config={{
            id: "archiv-poznamka",
            tableTitle : t("Dials.Notes"),
            endpoint: getUrl(),
            clazz: ArchivPoznamka,
            filtering: false,
            autofocus: false,
            columns: [
                {
                    title:t("Poznamka.poznamka"),
                    field:"poznamka",
                    filterProps:() => ({type:FormInputType.Text})
                },
                {
                    field: "uzivatel",
                    title: t("Poznamka.uzivatel"),
                    render: data => data.uzivatel?.displayName()
                },
                {
                    field:"datIns",
                    title:t("Poznamka.datIns"),
                    render:data=>data.datIns?.format("L LT")
                }
            ]
        }}
        isFormGrid
        preventClearFunctions
        hideNewButtonOnEdit
        focusFieldWhenMounted={false}
        stopImmediatePropagation={true}
        crudConfig={{editEnabled:true, removeEnabled:true, addEnabled:false}}
        layoutForm={() => <ArchivPoznamkaForm />}
        tabDetailUrl={`${props.viewArchive ? '/archiv-prohlizeni' : '/archiv'}/${getType()}/${props.nabidkaId}/poznamky`}
    />
}

export function ArchivPoznamkaForm() {
    const {t} = useTranslation();

    return <Grid container spacing={1}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormField title={t("Poznamka.poznamka")} type={FormInputType.Text} name={"poznamka"} textFieldProps={{inputProps: {maxLength: 510}}}/>
        </Grid>

        <Grid item lg={3} md={6} sm={12} xs={12}>
            <FormField title={t("Poznamka.uzivatel")} type={FormInputType.Text} name={"uzivatel"} disabled getValue={data =>
                data.uzivatel?.displayName()
            }/>
        </Grid>

        <Grid item lg={3} md={6} sm={12} xs={12}>
            <FormField title={t("Poznamka.datIns")} type={FormInputType.DateTime} name={"datIns"} disabled/>
        </Grid>
    </Grid>
}

