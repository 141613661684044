import {DependencyList, MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import {exist} from "../../utils/Util";
import {useErrorTranslator} from "../../utils/error-utils";
import {showSnack} from "../SnackContainer";
import {HttpResultSimple} from "../../utils/HttpUtils";

/* eslint-disable */
/**
 * !!! Bug inclined usage !!!
 * Hook is called only once when component is mounted. be careful to use this hook according to https://reactjs.org/docs/hooks-faq.html#can-i-skip-an-effect-on-updates
 */
export const useDidMount = (callback:(isMounted:MutableRefObject<boolean>)=>(()=>void)|void) => {
    const ref = useRef(true);
    useEffect(()=>{
        const clean = callback(ref);
        return ()=>{
            ref.current = false;
            if(typeof clean === "function") {
                clean();
            }
        }
    }, []);
};
/* eslint-enable */

/**
 * Use useMountedEffect only when need to avoid calling useEffect(callback, dep) if component is rendering for first time
 * @param callback
 * @param deps
 */
/* eslint-disable */
export const useMountedEffect = (callback:()=>void, deps:DependencyList) => {
    const mounted = useRef(false);
    useEffect(()=>{
        if(mounted.current){
            callback();
        }
    }, deps);
    useDidMount(()=>{
        mounted.current = true;
        return ()=> mounted.current = false;
    });
};
/* eslint-enable */
/**
 * Stop and repeat setTimeout when call first parameter of returned array [0](). Callback of setTimeout is called after specified ms after stop recalling function in [0].
 * This function is helping when need to call http request while user typing in input but after little time whe stop writing.
 * @param ms
 */
export const useSafeTimeout = (ms:number = 0): [(callback:VoidFunction, overrideMs?:number)=>void, ()=>void, MutableRefObject<number>, MutableRefObject<number>] => {
    const timeoutHolder = useRef(null as number);
    const currentMs = useRef(ms);
    useEffect(()=>{
        return () => clearTimeout(timeoutHolder.current)
    }, []);
    return [
        (callback:VoidFunction, overrideMs?:number) => {
        currentMs.current = overrideMs ?? currentMs.current
            if(timeoutHolder.current) {
                clearTimeout(timeoutHolder.current);
            }
            // @ts-ignore
            timeoutHolder.current = setTimeout(async ()=>{
                timeoutHolder.current = undefined;
                await callback();
            }, currentMs.current);
        },
        ()=> {clearTimeout(timeoutHolder.current); timeoutHolder.current = undefined; },
        currentMs,
        timeoutHolder
    ];
};
/**
 * Vypise do konzole jak dlouho trvalo zavolani side effectu od renderu
 */
export const useTookHook = (name="took") => {
    const start = Date.now();
    useEffect(()=>{
        console.log(name, Date.now() - start, "ms");
    });
};

interface RateLimitedLogicProps {
    fun: (data?: any) => Promise<any>;
    defaultWaitMs?: number;
    abortOnTimeout?: number;
    throttling?: boolean;
    throttlingDelay?: number;
    randomizeRateLimitDelay?: boolean;
    randomizedInitialDelay?:number;
}

interface ThrottlingLogicProps<T, R> {
    fun: (data?: T) => Promise<R>;
    throttlingDelay?: number;
}

/**
 * Funkcionalita throttlingu by měla být použita především pro volání API
 * Throttling pustí jedno volání za defaultWaitMs (aktuálně 5s), v případě že budou přicházet další, předchozí je zahozeno a zpracuje se jen to poslední
 */
export function useRateLimitedLogic({fun, defaultWaitMs = 5000, abortOnTimeout = 110000, throttling = false, throttlingDelay = 5000, randomizeRateLimitDelay = false, randomizedInitialDelay = undefined} : RateLimitedLogicProps) {
    const [setTimeout, , timeoutMs, timeoutHolder] = useSafeTimeout(defaultWaitMs / 2)
    const [thrSetTimeout, thrClearFun, , thrTimeoutHolder] = useSafeTimeout(throttlingDelay)

    const rfun = useCallback(async (ignoreThrottling: boolean = false, data?:any) => {
        try {
            if(throttling && !ignoreThrottling) {
                if(!exist(timeoutHolder.current)) { //if not rescheduled due to rate limit
                    if (exist(thrTimeoutHolder.current)) {
                        thrClearFun();
                        thrSetTimeout(() => {
                            rfun(ignoreThrottling, data);
                        })
                    } else {
                        thrSetTimeout(() => {
                        })
                        //randomize initial start
                        if (exist(randomizedInitialDelay)) {
                            setTimeout(() => {fun(data);}, Math.ceil(Math.random() * randomizedInitialDelay))
                        } else {
                            await fun(data);
                        }
                    }
                }
            } else {
                if (exist(randomizedInitialDelay) && !ignoreThrottling) {
                    setTimeout(() => {rfun(ignoreThrottling, data);}, Math.ceil(Math.random() * randomizedInitialDelay))
                } else {
                    await fun(data);
                }
            }

            timeoutMs.current = defaultWaitMs;
        } catch (e: any) {
            if(e?.response?.status === 429 && timeoutMs.current * 2 < abortOnTimeout) {
                setTimeout(() => {
                    rfun(true, data);
                }, timeoutMs.current + (randomizeRateLimitDelay ? Math.ceil(Math.random() * timeoutMs.current) : timeoutMs.current))
            }
        }
        // eslint-disable-next-line
    }, [fun])

    return rfun;
}

export function useThrottlingLogic<T, R>({fun, throttlingDelay = 5000} : ThrottlingLogicProps<T, R>) {
    const [setTimeout, clearFun , timeoutHolder] = useSafeTimeout(throttlingDelay)

    return useCallback(async (data?: any) => {
        try {
            if (exist(timeoutHolder.current)) {
                clearFun();
            }
            setTimeout(() => {
                fun(data);
            });
        } catch (e) {
        }
        // eslint-disable-next-line
    }, [fun]);
}

export interface LoadablePartProps<T> {
    loadFun: (params?: any) => Promise<T>
    loadOnInit: boolean;
}

export interface LoadableStateReturn<T> {
    loadableState: LoadablePartState;
    data?: T;
    updateData: (data: T) => void;
    reload: (params?: any) => Promise<T>;
    lastError?: ErrorDetail;
}

export enum LoadablePartState {LOADING, LOADED, ERROR}

export interface ErrorDetail {
    error: any;
    errorMessage: string;
}

export function useLoadablePart<T extends object>(props: LoadablePartProps<T>): LoadableStateReturn<T> {
    const [loadableState, setLoadableState] = useState(props.loadOnInit ? LoadablePartState.LOADING : undefined)
    const [data, setData] = useState<T>(undefined);
    const [lastError, setLastError] = useState<ErrorDetail>(undefined);
    const errorTranslator = useErrorTranslator();
    const version = useRef(1);

    const loadFun = useCallback(async (params?: any) => {
        setLoadableState(LoadablePartState.LOADING);
        const v = version.current;

        try {
            const d = await props.loadFun(params)
            if(v === version.current) {
                setData(d);
            }
            setLoadableState(LoadablePartState.LOADED);
            setLastError(undefined);
            return d;
        } catch (e) {
            console.error(e);
            if(v === version.current) {
                setData(undefined);
            }
            setLoadableState(LoadablePartState.ERROR);
            setLastError({error: e, errorMessage: errorTranslator(e)});
            return undefined;
        }
        // eslint-disable-next-line
    }, [props])

    useEffect( () => {
        if(props.loadOnInit)
            loadFun();
        // eslint-disable-next-line
    }, [props]);

    const reload = async (params?: any): Promise<T> => {
        return loadFun(params);
    }

    return {
        loadableState: loadableState,
        data: data,
        updateData: data1 => {
            version.current = version.current + 1;
            setData(data1);
        },
        reload: reload,
        lastError: lastError}
}

export interface NetworkActionReturn {
    protectedCall: (action: () => Promise<HttpResultSimple | void>) => Promise<boolean>
}

export interface NetworkActionReturnValue<V> {
    protectedCall: (action: () => Promise<V>) => Promise<V>
}

export function useNetworkAction(): NetworkActionReturn {
    const et = useErrorTranslator();

    const protectedCall = async (action: () => Promise<HttpResultSimple | void>) => {
        try {
            const result = await action()
            if(exist(result) && (result instanceof Object) && !result.response.ok)
                showSnack({title: et(result), severity: "error"});
            else
                return true;
        } catch (e) {
            showSnack({title: et(e), severity: "error"});
        }

        return false;
    }

    return {protectedCall}
}

export function useNetworkActionValue<V>(): NetworkActionReturnValue<V> {
    const et = useErrorTranslator();

    const protectedCall = async (action: () => Promise<V>) => {
        try {
            return await action()
        } catch (e) {
            showSnack({title: et(e), severity: "error"});
        }

        return null;
    }

    return {protectedCall}
}

