import {ModalProps, TemplateDialogType} from "../DataGrid.d";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import {StandaloneField} from "../../../../common/component/form/StandaloneField";
import {FormInputType} from "../../form/Form";
import {dispatchModal, ModalActionType} from "../../../../common/component/ModalContainer";
import i18n from "../../../i18n/i18n";
import {TemplatesFunctions} from "../MTOverrides";
import DividerWithText from "../../DividerWithText";

const ModalDispatchModalSave = (props: ModalProps) => {
    const [templateName, setTemplateName] = useState(props.name);
    const [vychozi, setVychozi] = useState((props.name != null && props.isDefault) ?? false);
    const [error, setError] = useState(undefined);
    const {t} = useTranslation();
    const isFilterSet = props.name != null;

    return (
        <><Box display="flex" flexDirection="row">
            <Box mr={2}>
                <Grid container direction="column">
                    <Grid item>
                        <StandaloneField disabled={isFilterSet} type={FormInputType.Text} onValueChanged={value => {
                            setTemplateName(value);
                        }} value={templateName} variant={"outlined"} title={t("Filter.TemplateName")} error={error} />
                    </Grid>
                    <Grid item>
                        <StandaloneField type={FormInputType.Checkbox} onValueChanged={value => {
                            setVychozi(value);
                        }} value={vychozi} variant={"outlined"} title={<span style={{textTransform: "uppercase", fontWeight: 600, fontSize: "0.95em"}}>{t("Filter.Vychozi")}</span>} error={error} />
                    </Grid>
                </Grid>
            </Box>
            <Box mb={2} style={{flexGrow: 1}}>
                <Button style={{width: "100%"}} variant={"contained"} color={"primary"} onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    if (templateName?.trim().length > 0) {
                        props.saveTemplate(templateName, vychozi, props.typ, props.id);
                        props.callback();
                        dispatchModal({type: ModalActionType.Hide});
                    } else {
                        setError(t("FormLocalization.FieldMessages.FieldIsRequired"));
                    }
                }}>{isFilterSet ? t("Buttons.SaveAsCurrent") : props.saveButtonText ?? i18n.t("Buttons.Save")}</Button>
            </Box>
        </Box>
        {isFilterSet && <Grid container direction="column">
            <Grid container direction="row" style={{marginBottom: "20px", textTransform: "uppercase", fontSize: "1.5em"}}>
                <DividerWithText>{t("Default.Or")}</DividerWithText>
            </Grid>
            <ModalDispatchModalSave {...props} saveButtonText={t("Buttons.SaveAsNew")} name={undefined} id={undefined} isDefault={undefined} />
        </Grid>}
        </>
    );
}

const ModalDispatchModalRemove = (props: ModalProps) => {
    return (
        <Box  display="flex" flexDirection="row">
            <Box mr={2}>
                <Typography variant={"h6"}>
                    {i18n.t("Filter.DeleteConfirmationText")}
                </Typography>
            </Box>
            <Box mb={2}>
                <Button variant={"contained"} color={"primary"} onClick={async (e:React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    await props.removeTemplate(props.id);
                    props.callback();
                    dispatchModal({type:ModalActionType.Hide});
                }}>{i18n.t("Buttons.Delete")}</Button>
            </Box>
        </Box>
    );
}

export const showFilterHeaderDialog = (type: TemplateDialogType, templateFns:TemplatesFunctions, callback:()=>void, id?: number, typ?: string, isDefault?:boolean, name?:string, saveButtonText?:string,) => {
    dispatchModal({
        type: ModalActionType.Show, title: type === TemplateDialogType.NAME_DIALOG ? i18n.t("Buttons.Save") : i18n.t("Buttons.Delete"), body: (
            <>
                {(type === TemplateDialogType.NAME_DIALOG && <ModalDispatchModalSave {...templateFns} id={id} name={name} callback={callback} typ={typ} isDefault={isDefault} />)}
                {(type === TemplateDialogType.REMOVE_CONFIRMATION_DIALOG && <ModalDispatchModalRemove id={id} {...templateFns} callback={callback}/>)}
            </>
        )
    });
}