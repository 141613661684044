import {useData} from "../../web/context/DataContext";
import {Stat} from "../../web/model/Stat";

export const useMpzResolver = (): [(isoCode: string) => string] => {
    const {staty} = useData();
    return [isoCode => staty.find(s => s.countryCode?.toLowerCase() === isoCode?.toLocaleLowerCase())?.mpz ?? "MPZ_UNRESOLVED"]
};

export const useMpzRevertResolver = (): [(mpz: string) => string] => {
    const {staty} = useData();
    return [mpz => staty?.find(s => s.mpz?.toLowerCase() === mpz?.toLocaleLowerCase())?.countryCode ?? undefined]
};

export const useStatResolver = (): [(mpz: string) => Stat] => {
    const {staty} = useData();
    return [mpz => staty?.find(s => s.mpz?.toLowerCase() === mpz?.toLocaleLowerCase())]
};