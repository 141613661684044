import {User} from "../../model/User";
import {useTranslation} from "react-i18next";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@material-ui/core";
import {TextTuple} from "../../../common/component/form/TextTuple";
import React from "react";
import {Fingerprint, Today} from '@material-ui/icons';
import {useEvaluateFieldsVisibility} from '../../../common/logic/visibility-logic';
import {Show} from '../../../common/component/form/Show';
import {useStyleContext} from "../../context/ThemeModeContext";

export function ZadavatelBasicInfoView(props:{userData:User, admin: boolean}) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const ev = useEvaluateFieldsVisibility(props.userData.provozovna, props.userData.provozovna?.firma, props.admin);
    return ev&&props.userData.provozovna&&(
        <>
            <Card style={{height: "100%"}} className={classes.sectionBackground}>
                <CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
                    <Box marginBottom={2}>
                        <Typography variant="h6" >{t("User.ViewInfo")}</Typography>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} spacing={3}>
                            <Show visible={ev("firma.ico")}>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <ListItem>
                                        <ListItemAvatar><Avatar className={"avatar-company"}><Fingerprint/></Avatar></ListItemAvatar>
                                        <ListItemText>
                                            <TextTuple title={t("Firma.ico")} content={`${props.userData.provozovna.firma && props.userData.provozovna.firma.ico?props.userData.provozovna.firma.ico:' -'}`} />
                                        </ListItemText>
                                    </ListItem>
                                </Grid>
                            </Show>
                            <Show visible={ev("firma.dic")}>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <ListItem>
                                        <ListItemAvatar><Avatar className={"avatar-company"}><Fingerprint/></Avatar></ListItemAvatar>
                                        <ListItemText>
                                            <TextTuple  title={t("Firma.dic")} content={`${props.userData.provozovna.firma && props.userData.provozovna.firma.dic?props.userData.provozovna.firma.dic:' -'}`} />
                                        </ListItemText>
                                    </ListItem>
                                </Grid>
                            </Show>
                            <Show visible={ev("firma.dic1")}>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <ListItem>
                                        <ListItemAvatar><Avatar className={"avatar-company"}><Fingerprint/></Avatar></ListItemAvatar>
                                        <ListItemText>
                                            <TextTuple  title={t("Firma.dic1")} content={`${props.userData.provozovna.firma && props.userData.provozovna.firma.dic1?props.userData.provozovna.firma.dic1:' -'}`} />
                                        </ListItemText>
                                    </ListItem>
                                </Grid>
                            </Show>
                        </Grid>
                        <Grid container item xs={12} spacing={3}>
                            <Show visible={ev("firma.regDatum")}>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <ListItem>
                                        <ListItemAvatar><Avatar className={"avatar-company"}><Today/></Avatar></ListItemAvatar>
                                        <ListItemText>
                                            <TextTuple  title={t("Firma.RegDatum")} content={`${props.userData.provozovna.firma && props.userData.provozovna.firma.regDatum?props.userData.provozovna.firma.regDatum.format("L"):' -'}`} />
                                        </ListItemText>
                                    </ListItem>
                                </Grid>
                            </Show>
                            <Show visible={ev("firma.datSmlouvy")}>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <ListItem>
                                        <ListItemAvatar><Avatar className={"avatar-company"}><Today/></Avatar></ListItemAvatar>
                                        <ListItemText>
                                            <TextTuple  title={t("Firma.datSmlouvy")} content={`${props.userData.provozovna.firma && props.userData.provozovna.firma.datSmlouvy?props.userData.provozovna.firma.datSmlouvy.format("L"):' -'}`} />
                                        </ListItemText>
                                    </ListItem>
                                </Grid>
                            </Show>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
