import * as React from "react";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {CiselnikTyp, useCiselnik} from "../model/Ciselnik";
import {invoke} from "../../common/utils/Invoke";
import {httpEndpoint, httpEndpointArray, httpEndpointJsonList} from "../../common/utils/HttpUtils";
import {useAppContext} from "./AppContext";
import {useTranslation} from "react-i18next";
import {setLoading} from "../../common/component/LoadingContainer";
import {SystemParameter} from "../model/SystemParameter";
import {showSnack} from "../../common/component/SnackContainer";
import {Stat} from "../model/Stat";
import {exist} from "../../common/utils/Util";
import {useDidMount} from "../../common/component/hooks/SharedHooks";
import {StompListener, useStompSubscribe, useWebsocketContext} from "../../common/utils/Websocket";
import {Currency} from "../model/Currency";
import {UserViewDispecer} from "../model/Dispecer";
import {SimpleValue} from "../model/SimpleValue";
import {User} from "../model/User";
import {PubSub} from "use-pubsub-js";
import i18next from "i18next";

type DataState = {
    systemParam:SystemParameter[],
    staty:Stat[],
    meny: Currency[]
    dispeceri?: UserViewDispecer[],
    telefony?: SimpleValue[]
}

type DataContextState = {
    getData: () => DataState
}

export const ContextData = React.createContext({} as DataContextState);

type UseData<A> = [A?] & DataState

export function useData<A=any>(filter?:(state:DataState)=>A):UseData<A> {
    const {getData} = useContext(ContextData);
    const state = getData();
    let filtered:A = null;
    if(filter !== null && filter !== undefined) {
        filtered = filter(state);
    }
    return Object.assign<[A?], DataState>(
        [filtered],
       state
    );
}

export function useLocalizeCiselnikValue(typ: CiselnikTyp) {
    const ciselnik = useCiselnik(typ);
    const {t} = useTranslation();
    return (locKey:string, en: any, code: any) => {
        return exist(code) ? ciselnik.find(c => c.kod === code)?.hodnota ?? ciselnik.find(c => c.kod === +en[code])?.hodnota ?? t(`${locKey}.${code.toString()}`) : undefined;
    }
}

export function useCiselnikValues(typ:CiselnikTyp):{druhy:(codes:number[])=>string[], druhyJoined:(codes:number[])=>string} {
    const ciselnik = useCiselnik(typ);
    const fn = (codes:number[])=>ciselnik.filter(i=>(codes ?? []).includes(i.kod)).map(i=>i.hodnota);
    return {druhy:fn, druhyJoined:(codes)=>codes?.map(i => fn([i]))?.join(", ")};
}

let reloadDataFlag = false;

export const getDispeceriTelefony = async (user: User) : Promise<{dispeceri: UserViewDispecer[], telefony: SimpleValue[]} > => {
    let dispeceri: UserViewDispecer[] = null;
    let telefony: SimpleValue[] = null;
    if (user?.provozovna) {
        await httpEndpointJsonList<UserViewDispecer>(UserViewDispecer, "ac/user-dispecer-input-form").then(res => {
            dispeceri = res.data?.list.sort((a,b) =>
                (a.jmeno > b.jmeno) ? 1 : ((b.jmeno > a.jmeno) ? -1 : 0))
        });
        await httpEndpointJsonList<SimpleValue>(SimpleValue, `ac/rychly-kontakt?provozovnaId=${user.provozovna.id}`).then(res => {
            telefony = res.data?.list
        });
    }

    return {dispeceri, telefony};
}

export function DataContext({children}:React.PropsWithChildren<{}>) {
    const {service} = useWebsocketContext();
    const {t} = useTranslation();
    const [dataFetched, setDataFetched] = useState(false);

    const dataState = useRef<DataState>({} as DataState);
    const {logout, user} = useAppContext();

    const connectionListener: StompListener = useMemo(() => ({
        onWebsocketConnected(s) {if(dataFetched) reloadDataFlag = true;},
        onWebsocketConnecting(s) {},
        onWebsocketDisconnected(s) {},
        onWebsocketReconnectFailed(s) {}
    }), [dataFetched]);

    useEffect(() => {
        service.addConnectionListener(connectionListener);
        return () => service.removeConnectionListener(connectionListener);
    }, [service, connectionListener]);

    const loadFun = async () => {
        // eslint-disable-next-line
        reloadDataFlag =false;
        const sysparam = await httpEndpointArray<SystemParameter>(SystemParameter, "full-fetch/systemparam");
        if (!sysparam.json) return;
        const staty = await httpEndpointArray<Stat>(Stat, "full-fetch/stat");
        const currency = await httpEndpointArray<Currency>(Currency, "full-fetch/currency");
        const localizedTexts = await httpEndpoint<String>(String,"full-fetch/localizedTexts");
        for (const language in localizedTexts.json) {
            i18next.addResources(language, 'translations', localizedTexts.json[language]);
        }
        const dispTel = await getDispeceriTelefony(user);
        dataState.current = {
            systemParam:sysparam.data,
            staty:staty.data,
            meny: currency.data,
            dispeceri: dispTel.dispeceri,
            telefony: dispTel.telefony
        };
        setDataFetched(true);
        console.log("fetched configuration parameters");
    }

    useStompSubscribe("/config-data", {userOnly:false, callback: async(json) => {
            const data = json as {configType: string, value: any}

            if (data.configType === 'PROVOZOVNA' && (!user.provozovna || data.value?.id !== user.provozovna.id)) {
                return;
            }

            if(data.configType === 'USER' && (!user.provozovna || ((data.value?.provozovna && data.value.provozovna.id !== user.provozovna.id) || !data.value?.provozovna))) {
                return;
            }
            await loadFun();
            PubSub.publish('dataContextChanged', true);
        }
    });

    const getData = () => {
        return {...dataState.current}
    }

    useDidMount(()=> {
        async function fetchAll() {
            setLoading(true);
            try {
                await loadFun();
            } catch (e) {
                showSnack({title:t("DataFetchError"), severity:"error"});
                logout();
            } finally {
                setLoading(false);
            }
        }
        invoke(fetchAll)
    });
    return (
        <ContextData.Provider value={{getData}}>
            {dataFetched&&children}
        </ContextData.Provider>
    );
}
