import React from 'react';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { DashboardTile } from './DashboardTile';
import { useAppContext } from '../../context/AppContext';
import { LicenseType } from '../../model/CommonTypes';
import { getConfig } from '../../../Config';


export function DashboardUser() {
    const {t} = useTranslation();
    const {user} = useAppContext();
    return (
        <>
            <Grid style={{padding:20}}>
                <Grid container spacing={4}>
                    <DashboardTile highlited={true} text={t("Sections.Task")} icon={faIcon.faPencilAlt} buttons={[
                        {
                            href: "zadat/prepravy",
                            text: "P"
                        }, {
                            href: "zadat/volnevozy",
                            text: "V"
                        }, {
                            href: "zadat/inzerce",
                            text: 'I'
                        }, {
                            href: "zadat/pvi",
                            text: 'All'
                        }
                    ]}/>
                    <DashboardTile text={t("Sections.Browse")} icon={faIcon.faSearch} buttons={[
                        {
                            href: "prohlizet/prepravy",
                            text: 'P'
                        }, {
                            href: "prohlizet/volnevozy",
                            text: 'V'
                        }, {
                            href: "prohlizet/inzerce",
                            text: 'I'
                        }, {
                            href: "prohlizet/pvi",
                            text: 'All'
                        }
                    ]}/>
                    <DashboardTile text={t("Sections.Company")} icon={faIcon.faBuilding} href={"provozovny"}/>
                    <DashboardTile text={t("Sections.ArchiveTask")} icon={faIcon.faArchive} buttons={[
                        {
                            href: "archiv/prepravy",
                            text: 'P'
                        }, {
                            href: "archiv/volnevozy",
                            text: 'V'
                        }, {
                            href: "archiv/inzerce",
                            text: 'I'
                        }
                    ]}/>
                    <DashboardTile text={t("Sections.ArchiveBrowse")} icon={faIcon.faFileArchive} buttons={[
                        {
                            href: "archiv-prohlizeni/prepravy",
                            text: 'P'
                        }, {
                            href: "archiv-prohlizeni/volnevozy",
                            text: 'V'
                        }, {
                            href: "archiv-prohlizeni/inzerce",
                            text: 'I'
                        }
                    ]}/>

                    {(user.typLicence===LicenseType.WITHKM) &&
                    <>
                        <DashboardTile text={t("Sections.Pass")} icon={faIcon.faTruck} buttons={[
                            {
                                href: "/prejezdy/prepravy",
                                text: 'P'
                            }, {
                                href: "/prejezdy/volnevozy",
                                text: 'V'
                            }
                        ]}/>
                        <DashboardTile text={t("Sections.Fill")} icon={faIcon.faDolly} href={"dokladky"} />
                        {getConfig().geoEnabled &&
                            <><DashboardTile text={t("Sections.CollectionService")} icon={faIcon.faTruckLoading} href={"sberna-sluzba"} />
                            <DashboardTile text={t("Sections.Mileage")} icon={faIcon.faMapMarkedAlt} buttons={[
                            {
                                href: "/kilometrovnik/vozy",
                                text: 'S'
                            }, {
                                href: "/kilometrovnik/trasy",
                                text: 'T'
                            }, {
                                href: "/kilometrovnik/kilometrovnik-hlaseni",
                                text: 'H'
                            }
                            ]} /></>
                        }
                    </>
                    }
                </Grid>
            </Grid>
        </>
    );
}
