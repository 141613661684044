
export const KLASIFIKACE_VALUES = ["1", "2", "3"]

export enum KlasifikaceColor {
    "#00800082"= 1, "#ffa500a6"= 2, "#ff000080"= 3
}

export class Klasifikace {
    nezobrazovat: boolean;
    barva?: string;
    poznamka?: string;
    oblibena?: boolean;
    klasifikace?: string;

}