import React, {useState} from "react";
import {Avatar, Grid, Typography} from "@material-ui/core";
import {Form, useForm} from "../../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {useDidMount} from "../../../../../common/component/hooks/SharedHooks";
import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import {invoke} from "../../../../../common/utils/Invoke";
import moment from "moment";
import {useLocale} from "../../../../context/LocaleContext";
import {green} from "@material-ui/core/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import {FormSectionCard} from "../../../../../common/component/form/FormSectionCard";
import {DataGrid, GridDensityTypes} from "@material-ui/data-grid";
import {DluhPripadStav} from "../../../../model/Stiznosti";
import {TrikratADost} from "../../../../model/TrikratADost";

export const TrikratADostForm = () => {
    const {t} = useTranslation();
    const [data, setData] = useState<TrikratADost>(new TrikratADost())
    const {fetch} = useFetchCustom<TrikratADost>({endpoint: 'admin/stiznost/dluh-statistika/'})

    useDidMount(() => {
        const  getData = async() => {
            await fetch().then((res)=>{
                setData(res);
            }).catch(() => {
                showSnack({title: t("UnexpectedError"), severity: "error"});
            })
        }

        invoke(getData);
    })

    return <Form<TrikratADost>
        disableFocusForSaveButton={true}
        blockSaveUntilChange={false}
        simpleLabel
        data={data}
        localization={t("FormLocalization", {returnObjects:true})}>
        <KilometrovnikHlaseniFormLayout/>
    </Form>
}

const KilometrovnikHlaseniFormLayout = () => {
    const {t} = useTranslation();
    const {data} = useForm<TrikratADost>();
    const {locale} = useLocale();

    const getCastky = () => {
        const castky = [];
        if (data.celkemCastky?.CZK) castky.push(`${data.celkemCastky?.CZK?.toLocaleString(locale, {minimumFractionDigits: 2})} CZK`);
        if (data.celkemCastky?.EUR) castky.push(`${data.celkemCastky?.EUR?.toLocaleString(locale, {minimumFractionDigits: 2})} EUR`);
        if (data.celkemCastky?.SKK) castky.push(`${data.celkemCastky?.SKK?.toLocaleString(locale, {minimumFractionDigits: 2})} SKK`);

        return castky.join(', ')
    }

    const getNadpripady = () => {
        let str = t("TrikratADost.NadpripadyTotal").replace('{0}', Number(data.nadpripady?.OTEVRENY ?? 0).toString())
        str = str.replace('{1}', Number(data.nadpripady?.UZAVRENY ?? 0).toString())
        str = str.replace('{2}', Number(data.nadpripady?.UZAVRENY_VYPOVED ?? 0).toString())
        return str
    }

    return <>
        <Grid container direction={"row"} spacing={2}>
            <FormSectionCard title={t("TrikratADost.Title").replace('{0}', moment().format('DD.MM.YYYY'))} avatar={<Avatar style={{backgroundColor: green[400]}}><FontAwesomeIcon icon={faIcon.faUsersSlash} size="sm"/></Avatar>}>
                <Grid item lg={12} sm={12} xs={12}>
                    <Typography variant={"caption"}> {t("TrikratADost.celkemPripadu").replace('{0}', Number(data.celkemPripadu ?? 0).toString())}</Typography>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <Typography variant={"caption"}>{t("TrikratADost.celkemCastky").replace('{0}', getCastky())}</Typography>
                </Grid>
                <Grid item lg={12} sm={12} xs={12} style={{marginBottom: 10}}>
                    <Typography variant={"caption"}>{getNadpripady()}</Typography>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <DataGrid columns={[
                        { field: 'stav', headerName: t('TrikratADost.Stav'), width: 200, valueFormatter: (params) => t(`Enumerations.DluhPripadStav.${DluhPripadStav[params.value as DluhPripadStav]}`)},
                        { field: 'castka', headerName: t('TrikratADost.Castka'), width: 200, valueFormatter: (params) => Number(params.value ?? 0).toLocaleString(locale, {minimumFractionDigits: 2})},
                        { field: 'currencyCode', headerName: t('TrikratADost.CurrencyCode'), width: 100},
                        { field: 'pocetPripad', headerName: t('TrikratADost.PocetPripad'), width: 200}

                    ]}
                              disableColumnFilter={true}
                              disableColumnMenu={true}
                              hideFooter={true}
                              rows={
                                  data.pripady?.map((p, i) => ({
                                      id: i,
                                      stav: p.stav,
                                      castka: p.castka,
                                      currencyCode: p.currencyCode,
                                      pocetPripad: p.pocetPripad
                                  })) ?? []
                              }
                              density={GridDensityTypes.Compact}
                              disableSelectionOnClick
                              style={{minHeight: '50vh'}}
                              hideFooterPagination={true}
                    />
                </Grid>
            </FormSectionCard>
        </Grid>
    </>
}
