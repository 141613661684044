import React, {PropsWithChildren, useMemo, useState} from "react";
import {LoadablePartProps, LoadablePartState, useLoadablePart} from "../../../../common/component/hooks/SharedHooks";
import {DiagReturn, SubsystemStatus} from "../../../model/Diag";
import {FetchConfig, useFetchCustom} from "../../../../common/utils/HttpUtils";
import {Loading} from "../../../../common/component/Loading";
import {exist} from "../../../../common/utils/Util";
import {Alert} from "@material-ui/lab";
import {DataGrid, GridDensityTypes, GridRowData} from "@material-ui/data-grid";
import {useTranslation} from "react-i18next";
import {Button, Grid, Popper, styled, TextareaAutosize, Tooltip, Typography} from "@material-ui/core";
import {Error, Favorite, SmsFailed} from "@material-ui/icons";
import {useStyleContext} from "../../../context/ThemeModeContext";
import numeral from "numeral";

interface DiagPageItemProps {
    data: DiagReturn;
}

interface StringsTooltipProps {
    messages?: string[];
}

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    "& .MuiTooltip-tooltip": {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 700,
        fontSize: theme.typography.pxToRem(12),
        fontFamily: "monospace",
        border: '1px solid #dadde9',
    },
}));


const StringsTooltip = (props: PropsWithChildren<StringsTooltipProps>) => {
    return <HtmlTooltip title={<>{props.messages?.map((e: string, i: number) => <p key={`${i}`}>{e}</p>) ?? ""}</>}>{props.children}</HtmlTooltip>;
}

const ShowInPopper = (props: PropsWithChildren<StringsTooltipProps>) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event: any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    return <>
            <Popper onDoubleClick={handleClick} style={{zIndex: 100000, width: 600}} open={anchorEl!=null} anchorEl={anchorEl}><TextareaAutosize style={{width: 600}} maxRows={20} value={props.messages.join("\r\n")}/></Popper>
            <div onClick={handleClick}>
                {props.children}
            </div>
    </>
}

function jobsWarnings(m?: GridRowData): string[] {
    const jobsWarnings: string[] = [];
    if(exist(m?.nodeDiag?.scheduler) && m.nodeDiag.scheduler.schedulerThreads <= m.nodeDiag.scheduler.maxCalculationJobs)
        jobsWarnings.push("MAX_CALCULATION_JOBS_PER_NODE >= org.quartz.threadPool.threadCount");

    if(exist(m?.nodeDiag?.scheduler) && m.nodeDiag.scheduler.schedulerThreads >= m.nodeDiag.databasePool.write)
        jobsWarnings.push("org.quartz.threadPool.threadCount >= spring.write-datasource.maximumPoolSize");
    return jobsWarnings;
}

export function DiagPageMemberGrid(props: DiagPageItemProps) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();

    return <DataGrid
        autoHeight
        pageSize={10}
        columns={[
            { field: 'address.host', headerName: t('Diag.host'), width: 150, valueGetter: params => params.row.address?.host, headerAlign: "center", renderCell: params => <ShowInPopper messages={[JSON.stringify(params.row, undefined, 2)]}><div>{params.value}</div></ShowInPopper>},
            { field: 'address.serverPort', headerName: t('Diag.serverPort'), width: 120, valueGetter: params => params.row.address?.serverPort, headerAlign: "center", align: "center"},
            { field: 'healthy', headerName: t('Diag.healthy'), width: 120, renderCell: params =>
                <>
                    {params.row.healthy===true ? <Favorite htmlColor={"green"}/> : <Error htmlColor={"red"}/>}
                    {(params.row.fetchErrors?.length ?? 0) > 0 ? <HtmlTooltip title={
                        <>{params.row.fetchErrors.map((e: string, i: number) => <p key={`${i}`}>{e}</p>)}</>
                    }><SmsFailed htmlColor={"red"}/></HtmlTooltip> : undefined}
                </>

                , headerAlign: "center", align: "center"
            },
            { field: 'status', flex: 1, headerName:' ',
                renderCell: params => <>
                    {exist(params.row?.nodeDiag?.missingProperties) && params.row?.nodeDiag?.missingProperties.length>0 ? <StringsTooltip key="missing-properties" messages={params.row.nodeDiag.missingProperties}><div className={classes.rightMargin1}>props</div></StringsTooltip> : undefined}
                    {jobsWarnings(params.row).length>0 ? <StringsTooltip key="jobs" messages={jobsWarnings(params.row)}><div className={classes.rightMargin1}>jobs</div></StringsTooltip> : undefined}
                    {params.row?.subsystemStatuses?.filter((v:any) => v?.up!==true)?.map((v: SubsystemStatus, i: number) => {
                    return <StringsTooltip key={`subsystem-${i}`} messages={v.details?.filter(v => v.detailName==="error")?.map((v) => v.detailValue)}><div>{v.name}</div></StringsTooltip>
                })}
                </>
            },

        ]}
        getRowClassName={params => (params.row.fetchErrors?.length ?? 0) > 0 ? classes.inactive : undefined}
        getRowId={row => row.uuid}
        rows={props.data?.hazelcastInstance?.cluster?.members}
        density={GridDensityTypes.Compact}
        disableSelectionOnClick
        disableColumnFilter={true}
        disableColumnMenu={true}
        hideFooter={true}
        hideFooterPagination={true}
    />;
}

export function DiagPageReplicationGrid(props: DiagPageItemProps) {
    return <DataGrid
        autoHeight
        pageSize={10}
        columns={[
            { field: 'useName', headerAlign: "center", width: 120},
            { field: 'applicationName', headerAlign: "center", width: 190},
            { field: 'clientAddr', headerAlign: "center", width: 150},
            { field: 'backendStart', headerAlign: "center", width: 180, valueGetter: params => params.row.backendStart?.format("L LT")},
            { field: 'state', headerAlign: "center", width: 150, align: "center"},
            { field: 'syncState', headerAlign: "center", width: 150, align: "center"}
        ]}
        getRowId={row => row.row}
        rows={props.data?.replicationInfos?.map((value, index) => ({...value, row: index}))}
        density={GridDensityTypes.Compact}
        disableSelectionOnClick
        disableColumnFilter={true}
        disableColumnMenu={true}
        hideFooter={true}
        hideFooterPagination={true}
    />;
}

export function DiagPageResources(props: DiagPageItemProps) {
    const {t} = useTranslation();
    const {data} = props;

    const rDbC = data.hazelcastInstance?.cluster?.members?.map(value => value.nodeDiag?.databasePool?.read?.totalConnections ?? 0).reduce((previousValue, currentValue) => previousValue + currentValue)
    const wDbC = data.hazelcastInstance?.cluster?.members?.map(value => value.nodeDiag?.databasePool?.write?.totalConnections ?? 0).reduce((previousValue, currentValue) => previousValue + currentValue)

    const rDbCp = data.hazelcastInstance?.cluster?.members?.map(value => (value.nodeDiag?.database?.read?.maxConnections ?? 0) - (value.nodeDiag?.database?.read?.reservedConnections ?? 0)).reduce((previousValue, currentValue) => Math.max(previousValue, currentValue))
    const wDbCp = data.hazelcastInstance?.cluster?.members?.map(value => (value.nodeDiag?.database?.write?.maxConnections ?? 0) - (value.nodeDiag?.database?.write?.reservedConnections ?? 0)).reduce((previousValue, currentValue) => Math.max(previousValue, currentValue))

    const replicated = exist(data.replicationInfos) && data.replicationInfos.length > 0;
    const rDbCpMultiply = exist(data.replicationInfos) ? Math.max(data.replicationInfos.length, 1) * rDbC : '-';

    const warningDbConnections = replicated ? wDbC > wDbCp || rDbC > rDbCpMultiply : (rDbC + wDbC) > wDbCp;

    return <Grid container direction="column">
        <Grid item>{t("Diag.requiredDbConnectionsRead")}: {rDbC}</Grid>
        <Grid item>{t("Diag.requiredDbConnectionsWrite")}: {wDbC}</Grid>
        {exist(data.replicationInfos) ? <>
                {replicated ?
                    <>
                        <Grid item>{t("Diag.availableDbConnectionsRead")}: {rDbCp}</Grid>
                        <Grid item>{t("Diag.availableDbConnectionsWrite")}: {wDbCp}</Grid>
                    </> :
                    <>
                        <Grid item>{t("Diag.availableDbConnections")}: {rDbCp}</Grid>
                    </>
                }
            {warningDbConnections ? <Typography color="error">{t("Diag.dbConnectionsNotEnough")}</Typography> : undefined}
            </> : undefined
        }
    </Grid>
}

export function DiagPageTriggers(props: DiagPageItemProps) {
    return <DataGrid
        autoHeight
        pageSize={10}
        columns={[
            { field: 'schedName', headerAlign: "center", width: 200},
            { field: 'state', headerAlign: "center", width: 150, align: "center"},
            { field: 'triggerType', headerAlign: "center", width: 150, align: "center"},
            { field: 'count', headerAlign: "center", width: 150, align: "center"}
        ]}
        getRowId={row => row.row}
        rows={props.data?.jobsDiag?.triggers?.map((value, index) => ({...value, row: index}))}
        density={GridDensityTypes.Compact}
        disableSelectionOnClick
        disableColumnFilter={true}
        disableColumnMenu={true}
        hideFooter={true}
        hideFooterPagination={true}
    />;
}

export function DiagPageFiredTriggers(props: DiagPageItemProps) {
    return <DataGrid
        autoHeight
        pageSize={10}
        columns={[
            { field: 'schedName', headerAlign: "center", width: 200},
            { field: 'state', headerAlign: "center", width: 150, align: "center"},
            { field: 'count', headerAlign: "center", width: 150, align: "center"}
        ]}
        getRowId={row => row.row}
        rows={props.data?.jobsDiag?.firedTriggers?.map((value, index) => ({...value, row: index}))}
        density={GridDensityTypes.Compact}
        disableSelectionOnClick
        disableColumnFilter={true}
        disableColumnMenu={true}
        hideFooter={true}
        hideFooterPagination={true}
    />;
}

export function DiagPageRateLimit(props: DiagPageItemProps) {
    const rows = props.data?.hazelcastInstance?.cluster?.members?.flatMap(v => v.nodeDiag?.rateLimitSnapshot?.map( (r, i) => ({address: v.address, ...r, row: i}))).filter(i => exist(i));

    return <DataGrid
        autoHeight
        pageSize={10}
        columns={[
            { field: 'host', headerAlign: "center", width: 150, valueGetter: params => params.row.address?.host},
            { field: 'port', headerAlign: "center", width: 150, align: "center", valueGetter: params => params.row.address?.serverPort},
            { field: 'name', headerAlign: "center", width: 150, align: "center"},
            { field: 'runningCount', headerAlign: "center", width: 200, align: "center"},
            { field: 'latency', headerAlign: "center", width: 200, align: "center", valueFormatter: ({value}) => exist(value) ? numeral(value).format("#") : undefined}
        ]}
        getRowId={row => row.row}
        rows={rows}
        density={GridDensityTypes.Compact}
        disableSelectionOnClick
        disableColumnFilter={true}
        disableColumnMenu={true}
        hideFooter={true}
        hideFooterPagination={true}
    />;
}

export function DiagPage() {
    const {t} = useTranslation();
    const [waitTimeout, setWaitTimeout] = useState(() => 30000);
    const config = useMemo<FetchConfig<null>>(() => ({endpoint: "admin/diag", timeout: waitTimeout}), [waitTimeout]);
    const {fetch} = useFetchCustom<DiagReturn>(config, undefined, DiagReturn)
    const loadFunc = useMemo<LoadablePartProps<DiagReturn>>(() => ({
        loadFun: () => {
                return fetch();
            },
        loadOnInit: true
    }), [fetch])

    const loadablePart = useLoadablePart<DiagReturn>(loadFunc);
    const data = loadablePart.data;

    return <><Loading show={loadablePart.loadableState === LoadablePartState.LOADING && !exist(data)} fullscreen/>
    {
        loadablePart.loadableState === LoadablePartState.ERROR ?
            <Grid container spacing={2} direction="column">
                <Grid item><Alert severity="error">{loadablePart.lastError?.errorMessage}</Alert></Grid>
                {loadablePart.lastError?.error?.name==="AbortError" ?
                <Grid item><Button variant="contained" color="secondary" onClick={ () => {
                    setWaitTimeout(waitTimeout * 2);
                }}>{t("Buttons.ReloadWait")}</Button></Grid> : undefined}
            </Grid>
            : exist(data) ? <>
                <Grid container direction={"row"} spacing={2}>
                    <Grid item md={12} lg={12} sm={12}>
                        <ShowInPopper messages={[JSON.stringify(data, undefined, 2)]}><Typography variant="h5">{t("Diag.instances")}</Typography></ShowInPopper>
                        <DiagPageMemberGrid data={data}/>
                    </Grid>

                    <Grid item md={12} lg={12} sm={12}>
                        <Typography variant="h5">{t("Diag.dbReplicas")}</Typography>
                        {!exist(data.replicationInfos) ? t("Diag.couldNotLoad") :
                            data.replicationInfos.length===0 ? t("Diag.replicationNotActive") :
                            <DiagPageReplicationGrid data={data}/>
                        }
                    </Grid>

                    <Grid item md={12} lg={12} sm={12}>
                        <Typography variant="h5">{t("Diag.resources")}</Typography>
                        <DiagPageResources data={data}/>
                    </Grid>

                    <Grid item md={12} lg={12} sm={12}>
                        <Typography variant="h5">{t("Diag.jobs")} - triggers</Typography>
                        <DiagPageTriggers data={data}/>
                    </Grid>

                    <Grid item md={12} lg={12} sm={12}>
                        <Typography variant="h5">{t("Diag.jobs")} - fired triggers</Typography>
                        <DiagPageFiredTriggers data={data}/>
                    </Grid>

                    <Grid item md={12} lg={12} sm={12}>
                        <Typography variant="h5">{t("Diag.rateLimit")}</Typography>
                        <DiagPageRateLimit data={data}/>
                    </Grid>
                </Grid>
                </>: undefined
    }
    </>
}
