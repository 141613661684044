import React, {
    forwardRef,
    PropsWithChildren,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react";
// import {Map, MapEvents, TileLayer} from "react-leaflet";
import {TileLayer} from "react-leaflet";
import {default as Leaflet} from "leaflet";

type StyleSize = string|number;
type Size = {
    value:number,
    relative?:boolean
} | StyleSize
type Dimensions = {
    width:Size
    height:Size
}

export interface MapOSMProps {
    dimensions?: Dimensions
}

export interface Props extends MapOSMProps, Leaflet.MapOptions, Leaflet.LocateOptions, Leaflet.FitBoundsOptions, PropsWithChildren<any> {
// export interface Props extends MapOSMProps, MapEvents, Leaflet.MapOptions, Leaflet.LocateOptions, Leaflet.FitBoundsOptions, PropsWithChildren<any> {


}

/***
 * hook ktery meni dimensi na mape podle toho jak se hybe s oknem
 */
type DimensionState = {height:StyleSize, width:StyleSize}
const useDynamicDimensions = (defaults?:Dimensions):[DimensionState, VoidFunction] => {
    const createDimension = useCallback(() => {
        let width:StyleSize = "100%";
        let height:StyleSize = window.innerHeight;
        if((defaults || null) !== null) {
            //width
            if(typeof defaults.width === "object") {
                if(defaults.width.relative) {
                    width = `${defaults.width}%`;
                } else {
                    height = defaults.width.value;
                }
            } else {
                width = defaults.width;
            }

            //height
            if(typeof defaults.height === "object") {
                if(defaults.height.relative) {
                    height = window.innerHeight * (defaults.height.value as number / 100);
                } else {
                    height = defaults.height.value;
                }
            } else {
                height = defaults.height;
            }
        }
        return {height, width};
    }, [defaults]);
    const [dimension, setDimension] = useState<DimensionState>(createDimension());
    const updateDimensions = useCallback(() => {
        setDimension(createDimension());
    }, [createDimension]);
    useEffect(()=>{
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [updateDimensions]);
    return [dimension, updateDimensions];
};
let TilesUrl = "";
export function setTilesUrl(url:string) {
    TilesUrl = url;
}

/**
 * Komponenta k vyrenderovani
 */
export interface MapOSMFunctionsExposed {
    updateFrame():void
    leaflet():Leaflet.Map
}

export const MapOSM = forwardRef<MapOSMFunctionsExposed, Props>((props, ref) => {
    const position = useMemo(()=>props.latLng, [props]);
    const [{width, height}, updateFrame] = useDynamicDimensions(props.dimensions);
    // const mapRef = useRef<Map>();
    // useImperativeHandle(ref, useCallback(() => {
    //     return {
    //         updateFrame,
    //         leaflet:()=>mapRef.current.leafletElement
    //     }
    // }, [updateFrame]));
    return (
        <div style={props.inlineMap ? {width: "100%", height: props.mapHeight} : {width:width, height:height}}>
            {/*<Map ref={mapRef} center={position} zoom={props.zoom} style={{width: "100%", height: "100%"}} {...props}>*/}
            {/*    {props.children}*/}
            {/*    <TileLayer*/}
            {/*        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'*/}
            {/*        url={TilesUrl}*/}
            {/*    />*/}
            {/*</Map>*/}
        </div>
    );
});

MapOSM.defaultProps = {
    zoom: 6
};
