import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Grid, LinearProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {FormField, FormInputType, useForm, useFormData} from "../../../../raal_components/form/Form";
import {FormMap, FormMapOptions} from "../../../../../common/component/form/FormMap";
import {mapDataConverterWaypoints} from "../../../../raal_components/SharedConfig";
import {Waypoint} from "../../../../model/Waypoint";
import {TrasaDetail, TrasaState} from "../../../../model/Trasa";
import {exist} from "../../../../../common/utils/Util";
import {Alert, AlertTitle} from "@material-ui/lab";
import {useCodeBookControllerContext,} from "../../../../raal_components/controller/CodeBookController";
import {CrudUpdate} from "../../../../model/CrudUpdate";
import {useStompSubscribe} from "../../../../../common/utils/Websocket";
import {Mapper} from "../../../../../common/utils/objectmapper/Mapper";
import {useAppContext} from "../../../../context/AppContext";
import {extractRouteFromOsrmData} from "../../../../../common/utils/osrm-utils";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import _ from "lodash";

export enum TrasaFormViewType {ROUTE, OFFER, POINT}

export interface TrasaFormProps {
    viewType?: TrasaFormViewType,
    setData?: (data: CrudUpdate) => void,
    stompTopic?: string,
    routeOnly?: boolean
}

export function TrasaForm({viewType = TrasaFormViewType.ROUTE, setData: setDataProp, stompTopic, routeOnly} : TrasaFormProps) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const trasa = useFormData<TrasaDetail>();
    const trasaForm = useForm<TrasaDetail>();
    const [progress, setProgress] = useState(0);
    const [f, forceUpdate] = useState(false)
    const {setDataChanged} = useAppContext()
    const codeBookControllerContext = useCodeBookControllerContext<TrasaDetail>();

    const stompCallback = useCallback((data: CrudUpdate) => {
        if(exist(setDataProp)) {
            setDataProp(data);
        } else {
            const updatedData = new Mapper<TrasaDetail>({constructor:TrasaDetail}).readValue(data.entity);
            if(exist(codeBookControllerContext) && updatedData.calculationResult?.progress===100) {
                codeBookControllerContext.reload();
            } else {
                const orig = trasaForm.data
                if(orig.calculationResult?.osrmData && !updatedData.calculationResult?.osrmData) {
                    if(!updatedData.calculationResult) {
                        updatedData.calculationResult = orig.calculationResult;
                    } else {
                        updatedData.calculationResult.osrmData = orig.calculationResult.osrmData;
                    }
                }
                trasaForm.data = updatedData;
                setProgress(updatedData?.calculationResult?.progress ?? 0);
            }
        }
        // eslint-disable-next-line
    }, [stompTopic]);

    const stompCallbackOptions = useMemo(() => ({clazz: CrudUpdate, callback: stompCallback, userOnly: true}), [stompCallback]);
    useStompSubscribe<CrudUpdate>(stompTopic, stompCallbackOptions)

    useEffect(() => {
        if (exist(trasa.state) && (trasa.state !== TrasaState.ERROR)) {
         setTimeout(() => {
             setDataChanged(false);
         }, 1000)
        }
    }, [trasa.state, setDataChanged])

    useEffect(() =>{
        if (exist(trasa.state) && (trasa.state === TrasaState.CALCULATING || trasa.state === TrasaState.WAITING) && f === false) {
             setTimeout(() => forceUpdate(!f), 1000)
         }
        // eslint-disable-next-line
    }, [])

    return <>
    {exist(trasa.state) && trasa.state !== TrasaState.CALCULATED ?
        <Grid container direction={"column"} spacing={1} style={{padding: 0, marginBottom: '1rem'}}>
            {(trasa.state===TrasaState.WAITING || trasa.state===TrasaState.CALCULATING) ? <Grid item><LinearProgress className={classes.linearProgress} value={progress} variant="determinate"/></Grid> : undefined}
            <Grid item className={trasa?.state===TrasaState.ERROR ? classes.dialogAlert : classes.dialogInfo}>
                <Alert severity={trasa.state===TrasaState.ERROR ? "error" : "info"}>
                    <AlertTitle>{t(`Enumerations.TrasaState.${trasa.state}`)}</AlertTitle>
                    {trasa.state===TrasaState.ERROR && exist(trasa.calculationResult?.status) ? <Grid item>{t(`Enumerations.RouteCalculationStatus.${trasa.calculationResult?.status}`)}</Grid> : null}
                </Alert>
            </Grid>
        </Grid> : undefined}
        <Grid container id={"drawer-container"} style={{ overflow: "hidden", position:"relative" }}>
        <Grid container>
            <FormField<FormMapOptions, Waypoint[]>
                name={"waypointy"}
                title={t("Default.Map")}
                type={FormInputType.Custom}
                showIcon={false}
                customComponent={FormMap}
                isTabbedContainer={true}
                customComponentOptions={{
                    converters: mapDataConverterWaypoints,
                    updateMapOnOpen: true,
                    inlineMap: true,
                    mapHeight: '65vh',
                    route: (i) => trasa.calculationResult?.osrmData && extractRouteFromOsrmData(JSON.parse(trasa.calculationResult.osrmData)),
                    viewType: viewType,
                    extraRoutes: (i) => trasa.extraRoutes,
                    extraWaypoints: (i) => trasa.extraWaypoints,
                    routeOnly: routeOnly
                }}
                setValue={(data, fieldName, value) => {
                    if (!_.isEqual(data.waypointy, value)) {
                        data.waypointy = value;
                    }
                }}
            />
        </Grid>
    </Grid>
    </>
}
