import * as React from "react";
import {MutableRefObject, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {cloneClassObject, exist, removeEmptyValues, useDataStore} from "../../../../../common/utils/Util";
import {SbernaSluzbaZadani} from "../../../../model/PrepravaVozidlo";
import {Box, Button, Card, CardContent, Grid, LinearProgress, Typography} from "@material-ui/core";
import {SbernaSluzbaZadaniForm} from "./SbernaSluzbaZadani";
import {useTranslation} from "react-i18next";
import {
    LoadablePartProps,
    LoadablePartState,
    useDidMount,
    useLoadablePart,
    useNetworkAction
} from "../../../../../common/component/hooks/SharedHooks";
import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {Mapper} from "../../../../../common/utils/objectmapper/Mapper";
import {Add, Refresh, Search} from "@material-ui/icons";
import {ModalSbernaSluzbaFilter, ModalSbernaSluzbaFilterExposed} from "./ModalSbernaSluzbaFilter";
import {ModalTrasaFormProps} from "./ModalTrasaForm";
import {KilometrovnikLinkType} from "./KilometrovnikLinkPart";
import {CrudUpdate} from "../../../../model/CrudUpdate";
import {useStompSubscribe} from "../../../../../common/utils/Websocket";
import {Loading} from "../../../../../common/component/Loading";
import {Alert, AlertTitle} from "@material-ui/lab";
import {TFunction} from "i18next";
import {RouteCalculationStatus, SbernaSluzbaTrasa, TrasaState} from "../../../../model/Trasa";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {SbernaSluzbaPrepravyDial} from "./SbernaSluzbaPrepravyDial";
import {ModalSbernaSluzbaDokladky} from "./ModalSbernaSluzbaDokladky";
import {SimpleValueBoolean} from "../../../../model/SimpleValue";
import _ from "lodash";
import {invoke} from "../../../../../common/utils/Invoke";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import {useKilometrovnikShared} from "./KilometrovnikShared";
import {useCiselnikValues} from "../../../../context/DataContext";
import {CISELNIK_DRUH} from "../prohlizeni/PrepravyCiselnikView";
import DataStorage from "../../../../../common/DataStorage";
import {useLocation} from "react-router";
import {useHistoryCustom} from "../../../../raal_components/controller/NavigationHelper";


function createRequestParams(sbernaSluzbaZadani: SbernaSluzbaZadani, params: any) {
    const mapper = new Mapper<SbernaSluzbaZadani>({constructor: SbernaSluzbaZadani});
    const mapped = mapper.writeValueAsJson(sbernaSluzbaZadani, {springSupport: true});
    mapped.nakladka = sbernaSluzbaZadani.nakladka?.koordinat && JSON.stringify(sbernaSluzbaZadani.nakladka.koordinat);
    mapped.vykladka = sbernaSluzbaZadani.vykladka?.koordinat && JSON.stringify(sbernaSluzbaZadani.vykladka.koordinat);
    mapped.recalculate = params?.recalculate === true
    if(exist(params))
        _.merge(mapped, params);

    return removeEmptyValues(mapped);
}


async function customSbernaSluzba(t: TFunction, sbernaSluzbaZadani: SbernaSluzbaZadani, sbernaSluzbaAsync: SbernaSluzbaTrasa): Promise<SbernaSluzbaTrasa> {
    const sbernaSluzba = sbernaSluzbaAsync;

    if(exist(sbernaSluzba?.waypointy) && sbernaSluzba.waypointy.length>0) {
        sbernaSluzba.waypointy[0].nazevMista = sbernaSluzbaZadani.nakladka?.nazevMista;
        if(exist(sbernaSluzbaZadani.vykladka)) {
            sbernaSluzba.waypointy[sbernaSluzba.waypointy.length - 1].nazevMista = sbernaSluzbaZadani.vykladka?.nazevMista;
        }
    }
    return sbernaSluzba;
}

interface AddPrepravaFormData {
    sbernaSluzbaId: number
    prepravaId: number
}

interface FilterExposed {
    reload: VoidFunction
    reloadNoAction: VoidFunction
    showDialog: VoidFunction
}

interface ButtonsExposed {
    setData: (sbernaSluzbaZadani:SbernaSluzbaZadani, data: SbernaSluzbaTrasa) => void
}

interface DataGridExposed {
    setData: (sbernaSluzbaZadani:SbernaSluzbaZadani, data: SbernaSluzbaTrasa) => void
    reloadDataGrid: () => void
}

interface ButtonsProps {
    componentRef: MutableRefObject<ButtonsExposed>
    reload: () => void
}

interface DataGridProps {
    componentRef: MutableRefObject<DataGridExposed>
    reloadNoAction: VoidFunction
    onPrepravyCleared?: VoidFunction
}

const SbernaSluzbaFilter = ({onDataChanged, componentRef, reloadDataGrid}: {reloadDataGrid: () => void, onDataChanged: (data: SbernaSluzbaTrasa, sbernaSluzbaZadani: SbernaSluzbaZadani) => void, componentRef: MutableRefObject<FilterExposed>}) => {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const storageKey = "SbernaSluzbaZadani";
    const [loadZadani, storeZadani] = useDataStore(SbernaSluzbaZadani, storageKey, true, "session")
    const [sbernaSluzbaZadani, setSbernaSluzbaZadani] = useState(() => new SbernaSluzbaZadani());
    const [first, setFirst] = useState(true);
    const ref = useRef<ModalSbernaSluzbaFilterExposed>()
    const refDokladky = useRef<ModalSbernaSluzbaFilterExposed>()
    const {fetch: fetchSbernaSluzba} = useFetchCustom<SbernaSluzbaTrasa>({ endpoint: 'user/sberna-sluzba'}, undefined, SbernaSluzbaTrasa);
    const {fetch: fetchAddPreprava} = useFetchCustom<SimpleValueBoolean, AddPrepravaFormData>({ endpoint: (arg) => `user/sberna-sluzba/${arg?.sbernaSluzbaId}/prepravy/${arg?.prepravaId}`, init: {method: "POST"}}, undefined, SimpleValueBoolean);
    const {fetch: clearPrepravy} = useFetchCustom<SimpleValueBoolean, AddPrepravaFormData>({ endpoint: (arg) => `user/sberna-sluzba/${arg?.sbernaSluzbaId}/prepravy/${arg?.prepravaId}`, init: {method: "DELETE"}}, undefined, SimpleValueBoolean);
    const [newData, setNewData] = useState<SbernaSluzbaTrasa>(null)
    const sbernaSluzbaMapper = new Mapper<SbernaSluzbaTrasa>({constructor:SbernaSluzbaTrasa});
    const [showConfirm] = useConfirmDialog();
    const {protectedCall} = useNetworkAction();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const {KilometrovnikFilterDataItem, getNSJ} = useKilometrovnikShared();
    const {pathname, state} = useLocation<any>();
    const {replace} = useHistoryCustom();

    useImperativeHandle(componentRef, () => ({
        reload,
        reloadNoAction,
        showDialog
    }))

    const loadFunc = useMemo<LoadablePartProps<SbernaSluzbaTrasa>>(() => ({
        loadFun: async (params) => {
            return customSbernaSluzba(t, sbernaSluzbaZadani, await fetchSbernaSluzba({params: createRequestParams(sbernaSluzbaZadani, params)}));
        },
        loadOnInit: false
        // eslint-disable-next-line
    }), [sbernaSluzbaZadani,fetchSbernaSluzba])

    const loadablePart = useLoadablePart<SbernaSluzbaTrasa>(loadFunc);
    const {data} = loadablePart;
    const sbernaSluzbaId = data && data.id;

    const changeFilterData = useCallback(() => {
        const zadaniObj = loadZadani();
        if(exist(zadaniObj)) {
            setSbernaSluzbaZadani(zadaniObj);
            setFirst(false);
        }
        // eslint-disable-next-line
    }, [setSbernaSluzbaZadani, loadZadani])

    useDidMount(()=>{
        if (state?.clearFilter === true) {
            DataStorage.clear(storageKey, true, 'session');
            replace(pathname, { clearFilter : false })
        } else {
            setFirst(false);
        }
        changeFilterData();
    });

    const reloadData = useCallback((filterUpdated: Boolean = false) => {
        if(exist(sbernaSluzbaZadani) && sbernaSluzbaZadani.isFilled()) {
            loadablePart.reload().then((sbernaSluzbaPojo) => {
                const sbernaSluzba = sbernaSluzbaMapper.readValue(sbernaSluzbaPojo);
                if (loadablePart.lastError) {
                    showSnack({title: loadablePart.lastError?.errorMessage, severity: "error"});
                } else {
                    setFirst(false);
                    if(!exist(sbernaSluzba.prepravy) || sbernaSluzba.prepravy.length === 0) {
                        showConfirm({
                            title: t("SbernaSluzba.nalezeniPreprav"),
                            body: t("SbernaSluzba.neobsahujePrepravy"),
                            onConfirm: () => {
                                loadablePart.reload({recalculate: true, routeOnly: true})
                            },
                            onCancel: () => {
                                refDokladky.current.setModalVisibility();
                            }
                        });
                    }
                    if(filterUpdated && !sbernaSluzba.isProcessing() && exist(sbernaSluzba.prepravy) && sbernaSluzba.prepravy.length > 0) {
                        showConfirm({
                            title: t("SbernaSluzba.noveZadani"),
                            body: t("SbernaSluzba.noveZadaniBody"),
                            onConfirm: async () => {
                                await protectedCall(async () => {
                                    await clearPrepravy({
                                        arg: {
                                            sbernaSluzbaId: sbernaSluzba.id,
                                            prepravaId: sbernaSluzba.prepravy[0].id
                                        }
                                    });

                                    return undefined;
                                });

                                reloadData();
                            }
                        });
                    }
                }
            });
        }
        // eslint-disable-next-line
    }, [loadablePart, sbernaSluzbaZadani]);

    const showDialog = useCallback(() => {
        reloadData();
        // eslint-disable-next-line
    }, [reloadData, loadablePart, sbernaSluzbaZadani]);

    const reload = () => loadablePart.reload({recalculate: true, routeOnly: false, keepPrepravy: true})

    const reloadNoAction = useCallback(() => {
        loadablePart.reload().then(() => {})
    }, [loadablePart]);

    useEffect(() => {
        reloadData(first);
        // eslint-disable-next-line
    }, [sbernaSluzbaZadani]);

    useEffect(() => {
        if(exist(newData) && newData.id===data.id) {
            data?.calculationResult?.forEach((value, index) => {
                const cr = newData.getCalculationResultAtIndex(index);
                if (exist(cr) && !exist(cr?.osrmData) && exist(value.osrmData))
                    cr.osrmData = value.osrmData;
            });
            loadablePart.updateData(newData)
        }
        // eslint-disable-next-line
    }, [data, newData]);

    useEffect(() => {
        onDataChanged(data, sbernaSluzbaZadani)
        // eslint-disable-next-line
    }, [data, newData, sbernaSluzbaZadani])

    useEffect(() => {
        if (data?.state === TrasaState.CALCULATED) {
            reloadDataGrid();
        }
        // eslint-disable-next-line
    }, [data?.state])

    const stompCallback = useCallback((data: CrudUpdate) => {
        const updatedData = sbernaSluzbaMapper.readValue(data.entity);
        customSbernaSluzba(t, sbernaSluzbaZadani, updatedData).then(value => setNewData(value));
        // eslint-disable-next-line
    }, [sbernaSluzbaZadani]);

    // eslint-disable-next-line
    const stompCallbackOptions = useMemo(() => ({clazz: CrudUpdate, callback: stompCallback, userOnly: true}), [stompCallback, sbernaSluzbaId]);

    useStompSubscribe<CrudUpdate>(exist(data?.id) ? `/sberna-sluzba-trasa/${data.id}` : undefined, stompCallbackOptions);

    const getFilterData = () => {
        if (sbernaSluzbaZadani) {
            const druhy = druhyJoined(sbernaSluzbaZadani.druhySbernaSluzba);
            const nsj = getNSJ(sbernaSluzbaZadani)
            return (<Grid container>
                {KilometrovnikFilterDataItem(t("Dokladky.nakladka"), sbernaSluzbaZadani.nakladka?.nazevMista??'')}
                {KilometrovnikFilterDataItem(t("Dokladky.vykladka"), sbernaSluzbaZadani.vykladka?.nazevMista??'')}
                {KilometrovnikFilterDataItem(t('Preprava.nsj'), nsj??'-')}
                {KilometrovnikFilterDataItem(t("Preprava.delka"), sbernaSluzbaZadani.delka??'-')}
                {KilometrovnikFilterDataItem(t("Preprava.vaha"), sbernaSluzbaZadani.vaha??'-')}
                {KilometrovnikFilterDataItem(t("Preprava.druhy"), druhy?.length !== 0 ? druhy : '-')}
                {KilometrovnikFilterDataItem(t("Dokladky.datumDokladkyOd"), sbernaSluzbaZadani.datumOd?.format("DD.MM.YYYY")??'-')}
                {KilometrovnikFilterDataItem(t("Dokladky.datumDokladkyDo"), sbernaSluzbaZadani.datumDo?.format("DD.MM.YYYY")??'-')}
                {KilometrovnikFilterDataItem(t("Currency.Title"), sbernaSluzbaZadani.currency?.currencyCode??'-')}
                {KilometrovnikFilterDataItem(t("Trasa.profilVozidla"), sbernaSluzbaZadani.profilVozidla?.nazev??'-')}
                {KilometrovnikFilterDataItem(t("SbernaSluzba.maxPrejezd"), sbernaSluzbaZadani.maxPrejezd??'-')}
                {KilometrovnikFilterDataItem(t("SbernaSluzba.maxZajizdka"), sbernaSluzbaZadani.maxZajizdka??'-')}
                {KilometrovnikFilterDataItem(t("SbernaSluzba.maxDelka"), sbernaSluzbaZadani.maxDelka??'-')}
                {KilometrovnikFilterDataItem(t("SbernaSluzba.maxVaha"), sbernaSluzbaZadani.maxVaha??'-', false)}
            </Grid>)
        }

        return null;
    }

    return <>
        <Loading show={loadablePart.loadableState === LoadablePartState.LOADING} fullscreen/>
        {loadablePart.loadableState === LoadablePartState.ERROR ? <Alert severity="error">{loadablePart.lastError?.errorMessage}</Alert> :
            exist(data) && data.state !== TrasaState.CALCULATED ?
                <Grid item container direction={"column"} spacing={1} style={{padding: 0, marginBottom: '1rem'}}>
                    {data?.isProcessing() ? <Grid item><LinearProgress className={classes.linearProgress} value={data?.progress ?? 0} variant="determinate"/></Grid> : undefined}
                    <Grid item className={data?.state===TrasaState.ERROR ? classes.dialogAlert : classes.dialogInfo}>
                        <Alert severity={data?.state===TrasaState.ERROR ? "error" : "info"}>
                            <AlertTitle>{t(`Enumerations.TrasaState.${data.state}`)}</AlertTitle>
                            {
                                data?.state===TrasaState.ERROR && data?.status===RouteCalculationStatus.NO_ROUTE && data.waypointy.length<3 ? <Grid item>{t("SbernaSluzba.noTransportsFound")}</Grid> :
                                data?.state===TrasaState.ERROR && exist(data?.status) && <Grid item>{t(`Enumerations.RouteCalculationStatus.${data?.status}`)}</Grid>
                            }
                        </Alert>
                    </Grid>
                </Grid> : undefined}
        {first || !sbernaSluzbaZadani.isFilled() ?
            <Card elevation={0} className={classes.dialogBackground}>
                <CardContent>
                    <Box marginBottom={2}>
                        <Typography variant="h6">{t("SbernaSluzba.sbernaSluzba")}</Typography>
                    </Box>
                    <Box marginLeft={1} marginTop={3} marginRight={1} marginBottom={0}>
                        <SbernaSluzbaZadaniForm sbernaSluzbaZadani={sbernaSluzbaZadani} commit={result => {
                            storeZadani(result);
                            setSbernaSluzbaZadani(cloneClassObject(result));
                        }}/>
                    </Box>
                </CardContent>
            </Card> : <>
                <ModalSbernaSluzbaFilter
                    modalRef={ref}
                    sbernaSluzbaZadani={sbernaSluzbaZadani}
                    onSend={() => {
                        storeZadani(sbernaSluzbaZadani);
                        reloadData(true);
                    }}
                />

                <ModalSbernaSluzbaDokladky sbernaSluzbaTrasa={data} modalRef={refDokladky} onSelect={async preprava => {
                    await fetchAddPreprava({
                        arg: {
                            sbernaSluzbaId: data.id,
                            prepravaId: preprava.id
                        }
                    });
                    refDokladky.current.setModalVisibility();
                }
                }/>

                <Grid container item spacing={1} xs style={{marginBottom: 10}}>
                    <Grid item>
                        <Button
                            key={"sbernaSluzbaSearch"}
                            variant={"contained"}
                            color="primary"
                            onClick={() => ref.current.setModalVisibility()}
                        >
                            <Search style={{marginRight: 10}}/>
                            {t("SbernaSluzba.search")}
                        </Button>
                    </Grid>
                    {exist(data) && [TrasaState.CALCULATED, TrasaState.ERROR].indexOf(data.state) >-1 &&  <Grid item>
                        <Button
                            key={"trasaCalculate"}
                            variant={"contained"}
                            color="primary"
                            onClick={() => loadablePart.reload({recalculate: true})}
                        >
                            <Refresh style={{marginRight: 10}}/>
                            {t("SbernaSluzba.doplnitPrepravy")}
                        </Button>
                    </Grid>}
                    {exist(data) && [TrasaState.CALCULATED, TrasaState.ERROR].indexOf(data.state) >-1 &&  <Grid item>
                        <Button
                            key={"trasaAddOnePreprava"}
                            variant={"contained"}
                            color="primary"
                            onClick={() => refDokladky.current.setModalVisibility()}
                        >
                            <Add style={{marginRight: 10}}/>
                            {t("SbernaSluzba.pridatPrepravu")}
                        </Button>
                    </Grid>}
                </Grid>
                <Grid container style={{marginBottom: '1rem'}}>
                    <Grid item>
                        <Card style={{padding: '0.3rem'}}>{getFilterData()}</Card>
                    </Grid>
                </Grid>
            </>
        }
    </>
}

const DataGrid = (props: DataGridProps) => {
    const [d, setD] = useState({sbernaSluzbaZadani: null, data: null});
    const [r, forceReload] = useState(false);
    const [processing, setProcessing] = useState(false);
    useImperativeHandle(props.componentRef, () => ({
        setData,
        reloadDataGrid
    }))

    const reloadDataGrid = () => {
        forceReload(!r);
    }

    const setData = (sbernaSluzbaZadani: SbernaSluzbaZadani, data: SbernaSluzbaTrasa) => {
        if (!d.data?.id || (d.data?.id !== data?.id) || !sbernaSluzbaZadani.isFilled()) setD(prevState => ({...prevState, sbernaSluzbaZadani: sbernaSluzbaZadani, data: data}))
        const p = data?.isProcessing && data.isProcessing();
        if(p !== processing)
            setProcessing(p);
    }

    return <>
        {d.data?.id && d.sbernaSluzbaZadani.isFilled() ? <Grid item xs><SbernaSluzbaPrepravyDial
            sbernaSluzbaTrasa={d.data}
            onSuccess={props.reloadNoAction}
            prepravyCleared={props.onPrepravyCleared}
            processing={processing}
        /></Grid> : null }
    </>
}

const MapButtons = (props: ButtonsProps) => {
    const [modalState, setModalState] = useState({open: false, sbernaSluzbaZadani: null, data: null});
    const {t} = useTranslation();

    useImperativeHandle(props.componentRef, () => ({
        setData
    }))

    const setData = (sbernaSluzbaZadani:SbernaSluzbaZadani, data: SbernaSluzbaTrasa) => {
        setModalState(prevState => ({...prevState, data: data, sbernaSluzbaZadani: sbernaSluzbaZadani}));
    }

    const getTrasa = () => {
        const storageKey = `kilometrovnik-trasa`;
        const trasaData : ModalTrasaFormProps = {
            profilVozidla: modalState?.sbernaSluzbaZadani.profilVozidla,
            currency: modalState?.sbernaSluzbaZadani.currency,
            routeOnly: false,
            linkType: KilometrovnikLinkType.PREPRAVA,
            offerId: 1,
            prejezd: null,
            dokladka: null,
            data: modalState?.data
        };
        DataStorage.set(storageKey, JSON.stringify(trasaData),  true, 'session');
        DataStorage.redirectWithTargetBlank(`/kilometrovnik/trasa`);
        DataStorage.clear(storageKey, true, 'session');
    }

    return <>
        <Grid container item spacing={1} xs style={{marginTop: 10}}>
            {modalState?.sbernaSluzbaZadani?.isFilled() && exist(modalState?.data?.calculationResult) && exist(modalState?.data.waypointy) &&
            (modalState?.data.waypointy.length > 2) &&
            <Grid item>
                <Button
                key={"sbernaSluzbaShowMapButton"}
                variant={"contained"}
                color="primary"
                onClick={() => getTrasa()}
                >{t("SbernaSluzba.map")}
                </Button>
            </Grid>}
            {
                modalState?.sbernaSluzbaZadani?.isFilled() &&
                exist(modalState?.data?.calculationResult) &&
                exist(modalState?.data?.waypointy) &&
                (modalState?.data?.waypointy?.length > 2) &&
                !exist(modalState?.data?.getCalculationResultAtIndex(0)?.toll) &&
                !modalState?.data.isProcessing() &&
                <Grid item>
                    <Button
                        key={"sbernaSluzbaCalculateTollButton"}
                        variant={"contained"}
                        color="primary"
                        onClick={() => {
                            invoke(props.reload);
                        }}>{t("SbernaSluzba.calculateToll")}
                    </Button>
                </Grid>
            }
        </Grid>
    </>
}

export function SbernaSluzbaPage() {
    const filterRef = useRef<FilterExposed>();
    const dataGridRef = useRef<DataGridExposed>();
    const mapButtonsRef = useRef<ButtonsExposed>();

    return <>
        <Grid container direction={"column"}>
            <SbernaSluzbaFilter
                onDataChanged={(data, sbernaSluzbaZadani) => {
                    dataGridRef.current?.setData(sbernaSluzbaZadani, data);
                    mapButtonsRef.current?.setData(sbernaSluzbaZadani, data);
                }}
                reloadDataGrid={() => dataGridRef.current?.reloadDataGrid()}
                componentRef={filterRef} />
            <DataGrid componentRef={dataGridRef} reloadNoAction={() => filterRef.current?.reloadNoAction()} onPrepravyCleared={() => {
                filterRef.current?.showDialog();
            }}/>
            <MapButtons componentRef={mapButtonsRef} reload={() => filterRef.current?.reload()} />
        </Grid>
    </>
}
