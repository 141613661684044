import {Geometry} from "../model/Geometry";
import {MapDataConverters} from "../../common/component/form/FormMap";
import {Waypoint} from "../model/Waypoint";

export const mapDataConverterPoints = ():MapDataConverters<Array<Geometry>> => {
    return [
        data=>data?.map(geo=> {
            return {
                address: "",
                latLng: geo.getLatLng()
            }
        }),
        formData => formData?.map(mp=>{
            const geo = new Geometry();
            geo.setPointAsLatLng(mp.latLng);
            return geo;
        })
    ];
};

export const mapDataConverterPoint = ():MapDataConverters<Geometry> => {
    return [
        (data)=>{
            const latLng = data?.getLatLng();
            return latLng ? [{address: null, latLng: latLng}] : []
        },
        formData => {
            if(formData.length > 0) {
                const geo = new Geometry();
                geo.setPointAsLatLng(formData[0].latLng);
                return geo;
            }
            return null;
        }
    ];
};

export const mapDataConverterWaypoints = ():MapDataConverters<Waypoint[]> => {
    return [
        (data) => {
            return data?.map(i => {return {
                nazevMista: i.nazevMista,
                latLng: i.koordinat?.getLatLng(),
                countryCode: i.countryCode
            }}) ?? []
        },
        formData => {
            return formData?.map(i => {
                const w = new Waypoint();
                w.nazevMista = i.nazevMista;
                w.koordinat = new Geometry();
                w.koordinat.setPointAsLatLng(i.latLng);
                w.countryCode = i.countryCode;
                return w;
            })
        }
    ];
};
