
type LocaleAccessor = () => string

let get:LocaleAccessor = () => "cs";

export const setLocaleAccessor = (accessor:LocaleAccessor) => {
    get = accessor;
};

export const currentLocale = () => {
    return get();
};