import React from "react";
import {Grid} from "@material-ui/core";
import {FormField, FormInputType, useForm} from "../../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {useCurrencySelectObj, useEnumeratedSelect} from "../../../../raal_components/SelectOptions";
import {decimalNumberFormat, shortNumberConstraint} from "../../../../../common/common-constraints";
import {CenyMytaDial} from "./CenyMytaDial";
import {ProfilVozidla} from "../../../../model/ProfilVozidla";
import {FormPartBox} from "../prohlizeni/FormPartBox";
import {useTheme} from "@material-ui/core/styles";
import {CustomFieldComponentProps} from "../../../../../common/component/form/FormFieldInterface";
import {exist} from "../../../../../common/utils/Util";
import {OsrmProfile, OsrmProfileData} from "../../../../model/OsrmProfile";
import {ComponentMode} from "../../../../routes";

function ProfilVozidlaDetailInfo(props: CustomFieldComponentProps<OsrmProfile, any>) {
    const {t} = useTranslation();

    return <Grid container>
        <Grid item xs>
            <FormPartBox title={t("ProfilVozidla.properties")}>
                <Grid container>
                    <Grid container spacing={1}>
                        <Grid item>
                            {t("ProfilVozidla.maxWeight")}:
                        </Grid>
                        <Grid item>
                            {exist(props.value) ? OsrmProfileData.get(props.value).vaha : "-"}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item>
                            {t("ProfilVozidla.maxHeight")}:
                        </Grid>
                        <Grid item>
                            {exist(props.value) ? OsrmProfileData.get(props.value).vyska : "-"}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item>
                            {t("ProfilVozidla.maxWidth")}:
                        </Grid>
                        <Grid item>
                            {exist(props.value) ? OsrmProfileData.get(props.value).sirka : "-"}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item>
                            {t("ProfilVozidla.maxLength")}:
                        </Grid>
                        <Grid item>
                            {exist(props.value) ? OsrmProfileData.get(props.value).delka : "-"}
                        </Grid>
                    </Grid>
                </Grid>
            </FormPartBox>
        </Grid>
    </Grid>
}

export function ProfilVozidlaForm() {
    const {t} = useTranslation();
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const profileSelect = useEnumeratedSelect(OsrmProfile, "Enumerations.OsrmProfile", "number", {isClearable:true});
    const form = useForm<ProfilVozidla>();
    const theme = useTheme();

    return <Grid container spacing={2} direction="column">
        <Grid container item spacing={1}>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField name={"nazev"} title={t("ProfilVozidla.nazev")} type={"text"} required textFieldProps={{inputProps: {maxLength: 20}}}/>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField name={"osrmProfile"} forceUpdateWhenDataChanged={["properties"]} autoSelectFirstValueOnTab title={t("ProfilVozidla.osrmProfile")} type={FormInputType.AutoComplete} selectProps={profileSelect} required/>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField title={t("Currency.Title")} name='currency' type='select' autoSelectFirstValueOnTab required selectProps={currencySelectProps}/>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField title={t("ProfilVozidla.casovyNakladH")} name='casovyNakladH' type='number' dataTip={t("ProfilVozidla.casovyNakladHTooltip")} numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}}/>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField title={t("ProfilVozidla.fixniNaklad")} name='fixniNaklad' type='number' dataTip={t("ProfilVozidla.fixniNakladTooltip")} numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}}/>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField title={t("ProfilVozidla.spotreba")} name='spotreba' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}}/>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField title={t("ProfilVozidla.cenaPaliva")} name='cenaPaliva' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}}/>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField title={t("ProfilVozidla.delka")} name='delka' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}}/>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField title={t("ProfilVozidla.vaha")} name='vaha' type='number' numberProps={{format:decimalNumberFormat, constraint:shortNumberConstraint}}/>
            </Grid>
            <Grid item xs={12} style={{marginBottom: theme.spacing(1)}}>
                <FormField name='properties' getValue={data => data.osrmProfile} title={t("ProfilVozidla.osrmProfile")} type={FormInputType.Custom} customComponent={ProfilVozidlaDetailInfo}/>
            </Grid>
        </Grid>

        <Grid container item spacing={1}>
            <Grid item xs={12} sm={6} lg={3}>
                <FormField title={t("ProfilVozidla.hazmat")} name="hazmat" type="checkbox"/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <FormField title={t("ProfilVozidla.hazmatWater")} name="hazmatWater" type="checkbox"/>
            </Grid>
            {/*<Grid item xs={12} sm={6} lg={3}>
                <FormControlLabel control={
                    <Checkbox checked={true} disabled color="secondary"/>
                }
                label={t("ProfilVozidla.switzerland")}
      />
            </Grid>
            // Skryto viz požadavek #4252
            */}
        </Grid>

        <Grid container item spacing={1} style={form.data.id ? {} : {pointerEvents: 'none', opacity: 0.4}}>
            <Grid item xs={12} sm={12} lg={12}>
                <CenyMytaDial data={form.data} mode={ComponentMode.GridMode} onFocusedChildDataGridRow={() => form.isChildDataGridFocused = true} onBlurChildDataGridRow={() => form.isChildDataGridFocused = false} />
            </Grid>
        </Grid>

        <Grid container item spacing={1}>
            {/*<Grid item xs={12} sm={6} lg={3}>
                <FormField forceUpdateWhenDataChanged={["defaultPreprava"]} title={t("ProfilVozidla.displayPreprava")}
                           name={"displayPreprava"} type={"checkbox"} setValue={(data, fieldName, value) => {
                    data.displayPreprava = value;
                    if (!value)
                        data.defaultPreprava = false;
                }} serverValidation={{translate: t}}/>
            </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                <FormField forceUpdateWhenDataChanged={["defaultVozidlo"]} title={t("ProfilVozidla.displayVozidlo")} name={"displayVozidlo"} type={"checkbox"} setValue={(data, fieldName, value) => {
                data.displayVozidlo = value;
                if(!value)
                data.defaultVozidlo = false;
            }} serverValidation={{translate: t}}/>
                </Grid>
                // Skryto viz požadavek #4329
            */}
            <Grid item xs={12} sm={6} lg={3}>
                <FormField title={t("ProfilVozidla.defaultPreprava")} name={"defaultPreprava"} type={"checkbox"} disabled={() => !form.data.displayPreprava}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <FormField title={t("ProfilVozidla.defaultVozidlo")} name={"defaultVozidlo"} type={"checkbox"} disabled={() => !form.data.displayVozidlo}/>
            </Grid>
        </Grid>
    </Grid>
}
