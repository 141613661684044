import {IDClass} from "../../model/CommonTypes";
import {useFetchCustom} from "../../../common/utils/HttpUtils";
import {LockChangeResult} from "../../model/LockResult";
import {Props} from "../grid/DataGrid.d";
import {showSnack, SnackProps} from "../../../common/component/SnackContainer";
import {useRef} from "react";

export enum LockStatus {
    UNKNOWN,
    LOCKED,
    LOCKED_EXT,
    UNLOCKED,
}

export function useDetailLocker<T extends IDClass>(props: Props<T>) : DetailLocker {
    const lockChangeResult = useRef(null as LockChangeResult);
    const lockStatus = useRef(LockStatus.UNKNOWN);

    const {fetch: lockHttpCall} = useFetchCustom<LockChangeResult, string>(
        {endpoint: id => `${props.endpointDetail ?? props.endpoint}/${id}/lock`}
    );
    const lockRecord = async (id: string, enabled: boolean, errProps?: SnackProps) : Promise<LockChangeResult>  => {
        if (id === 'new' || !props.lockSupport?.enabled) return {value: true};
        try {
            lockStatus.current = enabled ? LockStatus.LOCKED : LockStatus.UNLOCKED;
            lockChangeResult.current = await (lockHttpCall({arg: id, init: {method: enabled ? 'POST': 'DELETE'}}));
            if(enabled && !lockChangeResult.current.value){
                lockStatus.current = LockStatus.LOCKED_EXT;
            }
        } catch(e) {
            lockStatus.current = LockStatus.UNKNOWN;
            lockChangeResult.current = {value: false};
            console.error(e);
        }
        if(!lockChangeResult.current?.value && errProps) showSnack(errProps);
        return lockChangeResult.current;
    }

    const lock = async (id: string, errProps?: SnackProps) => lockRecord(id, true, errProps);
    const unlock = async (id: string, errProps?: SnackProps) => lockRecord(id, false, errProps);
    const getLockStatus = () => lockStatus.current;

    return {lock, unlock, getLockStatus}
}

export type DetailLocker = {
    /**
     * Locks the record detail, to prevent users to simultaneous edits.
     * Function will not be processed, if the id=="new" or props.lockSupport == false (the value in the result will be always true)
     * @param id - record id or id url part (eg.: /archive/123)
     * @param errProps - if not empty: the specified error will be propagated through showSnack
     * @return LockChangeResult
     */
    lock: (id: string, errProps?: SnackProps) => Promise<LockChangeResult>

    /**
     * Unlocks the record detail without permission checks.
     * Function will not be processed, if the id=="new" or props.lockSupport == false (the value in the result will be always true)
     * @param id - record id or id url part (eg.: /archive/123)
     * @param errProps - if not empty: the specified error will be propagated through showSnack
     * @return LockChangeResult
     */
    unlock: (id: string, errProps?: SnackProps) => Promise<LockChangeResult>

    /**
     * Gets the last set LockStatus using this hook
     * @return LockStatus
     */
    getLockStatus: () => LockStatus
}