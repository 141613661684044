import React, {useEffect, useRef} from "react";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ExtraRoute} from "../FunctionalMap";
import {RouteData, RouteFromOsrmData} from "../../../utils/osrm-utils";
import {TrasaType} from "../../../../web/page/Dials/user/kilometrovnik/ModalTrasaForm";

export interface Instruction {
    type?: string
    modifier?: string
    text?: string
    distance?: string
    location?: string
}

export interface HighlightedTextInstruction {
    route: number [][]
    color?: string
}

const useStyles = makeStyles(() => ({
    instructions: {
        fontSize: "9pt",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: "#b1b1b129"
        }
    },
    iconText: {
        fontSize: "18pt",
        fontWeight: 800,
        display: "block",
        width: 40,
        height: 40,
        textAlign: "center"
    }
}))

interface OSRMTextInstructionsProps {
    route: RouteFromOsrmData
    extraRoutes: ExtraRoute[]
    showRouteOnly?: boolean
    showExtraRouteOnly?: boolean
    highlightRoute?: (instruction: HighlightedTextInstruction) => void
    onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void
    trasaType?: TrasaType
    calcTabNum?: number
}

enum InstructionsType {
    Default,
    Dokladka
}

interface InstructionRowProps {
    routeData: RouteData[]
    departText?: string
    arriveText?: string
    color?: string
    type?: InstructionsType
}
export const OSRMTextInstructions = (props: OSRMTextInstructionsProps) => {
    const classes = useStyles();
    const departCount = useRef(0);
    const arriveCount = useRef(1);

    const getIconClass = (type?: string, modifier?: string): string => {
        if (type === 'depart' || type === 'arrive') return `leaflet-routing-icon leaflet-routing-icon-${type}`;
        return `leaflet-routing-icon leaflet-routing-icon${modifier ? '' : `-${type}`}${modifier ? `-${modifier.replace(' ', '-')}` : ''}`;
    }

    const getDistance = (distance?: number): string => {
        return distance <= 1000 ? `${distance.toFixed(0)} m` : `${(distance / 1000).toFixed(1)} km`;
    }

    useEffect(() => {
        departCount.current = 0;
        arriveCount.current = 1;
    }, [props.route, props.extraRoutes])


    const RoutePartTitle = ({p, data, isEndPoint}:{p: InstructionRowProps, data: RouteData, isEndPoint: boolean}) => {
        // Text pro dokládku
        if (p.type === InstructionsType.Dokladka) {
            if (p.departText && data.instruction?.type === 'depart') {
                if (isEndPoint) {
                    departCount.current = 0;
                    arriveCount.current = 0;
                    return <span className={classes.iconText}>{p.departText}</span>;
                } else {
                    departCount.current++;
                    return <span className={classes.iconText}>{departCount.current}</span>;
                }
            }
            if (p.arriveText && data.instruction?.type === 'arrive') {
                if (isEndPoint) {
                    return <span className={classes.iconText}>{p.arriveText}</span>;
                } else {
                    arriveCount.current++;
                    return <span className={classes.iconText}>{arriveCount.current}</span>;
                }

            }
        }

        if (p.departText && data.instruction?.type === 'depart') {
            if (isEndPoint) {
                departCount.current = 0;
                arriveCount.current = 1;
            }
            if (p.departText === ' ') departCount.current++;
            return <span className={classes.iconText}>{p.departText !== ' ' ? p.departText : departCount.current}</span>;
        }
        if (p.arriveText && data.instruction?.type === 'arrive') {
            if (p.arriveText === ' ') arriveCount.current++;
            return <span className={classes.iconText}>{p.arriveText !== ' ' ? p.arriveText : arriveCount.current}</span>;
        }

        return <div className={getIconClass(data.instruction.type, data.instruction.modifier)}></div>
    }

    const TextInstructionRow = (p:InstructionRowProps) => {
        return <>
            {p.routeData.map((data, index = 0) => {
                return <Grid container
                             direction={'row'}
                             key={index}
                             spacing={2}
                             className={classes.instructions}
                             onMouseLeave={props.onMouseLeave}
                             onMouseEnter={() => props.highlightRoute({route: data.route ?? [], color: p.color})}>
                    <Grid container item lg={2} sm={2} xs={2} alignItems={"center"}>
                        <RoutePartTitle p={p} data={data} isEndPoint={index === 0 || p.routeData.length - 1 === index} />
                    </Grid>
                    <Grid container item lg={8} sm={8} xs={8} alignItems={"center"}>{data.instruction.text ?? ''}</Grid>
                    <Grid container item lg={2} sm={2} xs={2} alignItems={"center"}>{data.instruction.distance ? getDistance(Number(data.instruction.distance)) : ''}</Grid>
                </Grid>
            })}</>

    }

    const getRouteText = (routeType: 'depart' | 'arrive') => {
        if (props.trasaType === TrasaType.PREJEZD) {
            if (props.calcTabNum !== 1) return routeType === "depart" ? "P1" : "P2";
            return " ";
        }

        if (props.trasaType === TrasaType.DOKLADKA) {
            if (props.calcTabNum !== 1) return routeType === "depart" ? "N" : "V";
            return " ";
        }

        return " ";
    }

    return  <Grid style={{paddingBottom: '20px'}}>
        {
            props.trasaType === TrasaType.PREJEZD && props.extraRoutes?.length === 3 ? <>
                <TextInstructionRow routeData={props.extraRoutes[1].route?.routeData??[]} color={props.extraRoutes[1].color} departText={"P1"} arriveText={"P1"} />
                <TextInstructionRow routeData={props.extraRoutes[0].route?.routeData??[]} color={props.extraRoutes[0].color}  departText={" "} arriveText={" "} />
                <TextInstructionRow routeData={props.extraRoutes[2].route?.routeData??[]} color={props.extraRoutes[2].color}  departText={"P2"} arriveText={"P2"} />
                </>
                : null
        }
        {
            (props.trasaType === TrasaType.NORMAL || !props.extraRoutes) && props.route ?
                <TextInstructionRow routeData={props.route.routeData??[]} departText={getRouteText("depart")} arriveText={getRouteText("arrive")} />
                : null
        }
        {
            props.trasaType === TrasaType.DOKLADKA && props.extraRoutes?.length === 2 ? <>
                    <TextInstructionRow routeData={props.extraRoutes[1].route?.routeData??[]} color={props.extraRoutes[1].color} departText={"N"} arriveText={"V"} type={InstructionsType.Dokladka} />
                </>
                : null
        }
    </Grid>

}
