import {User} from "../../model/User";
import {useTranslation} from "react-i18next";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@material-ui/core";
import {TextTuple} from "../../../common/component/form/TextTuple";
import React from "react";
import {useHistory, useLocation} from 'react-router';
import {Business, Fingerprint} from '@material-ui/icons';
import {FlagIcon} from '../../../common/component/FlagIcon';
import {useStyleContext} from "../../context/ThemeModeContext";

export function ZadavatelHeaderInfoView(props:{userData:User, id?: number, admin?: boolean}) {
    const {classes} = useStyleContext();
    const {t} = useTranslation();
    const {push} = useHistory();
    const {pathname} = useLocation();
    return (
        <>
            <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom:4}}>
                <Grid item lg={12} sm={12} xs={12}>
                    <Card style={{height: "100%"}} className={classes.sectionBackground}>
                        <CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
                            <Box marginBottom={2}>
                                <Typography variant="h6" >{t("User.View")}</Typography>
                            </Box>
                            <Grid container spacing={1}>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item lg={6} sm={6} xs={12}>
                                        <ListItem>
                                            <ListItemAvatar><Avatar className={"avatar-company"}><Fingerprint/></Avatar></ListItemAvatar>
                                            <ListItemText>
                                                <TextTuple title={t("Provozovna.KodProvozovny")} content={`
                                                ${props.userData.provozovna && props.userData.provozovna.kod}
                                                `} onClick={() => push(`${props.admin ? '/admin/provozovny' : '/prohlizet/provozovna'}/${props.userData.provozovna.id}`, {previousPage:`${pathname}/${props.id}`})} />
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                    <Grid item lg={6} sm={6} xs={12}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                    {props.userData.provozovna.pStat?
                                                    <Avatar className={"avatar-flag"}><FlagIcon mpz={props.userData.provozovna.pStat}/></Avatar>:
                                                    <Avatar className={"avatar-company"}><Business/></Avatar>}
                                            </ListItemAvatar>
                                            <ListItemText>
                                                <TextTuple title={t("Provozovna.Nazev")} content={`
                                                    ${props.userData.provozovna && props.userData.provozovna.pNazev?props.userData.provozovna.pNazev:' -'}
                                                    `} />
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
