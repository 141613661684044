import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {MomentConverters} from "./Converters";
import moment, {Moment} from "moment";
import {UserBasic} from "./User";
import {Currency} from "./Currency";
import {exist} from "../../common/utils/Util";
import {Provozovna, Stav} from "./Provozovna";
import {DateRange} from "../../common/component/form/FormDateRange";
import {ReportLanguage} from "./ReportText";
import {TypOdesilatele} from "../page/Dials/admin/stiznosti/LetterComponent";


export enum DluhPripadStav {
    // POZOR: KE_SCHVALENI === NEODESLANY
    KE_SCHVALENI = 0,
    NEPOTVRZENY = 1,
    NEUHRAZENY = 2,
    UZAVRENY = 3,
    VYRESENY_BEZ_UHRAD = 4,
    UZAVRENY_VYPOVEDI = 5,
    UZAVRENY_39 = 6,
    PROMLCENO = 7,
    ZAMITNUTO = 8}

export enum DluhNadpripadStav {OTEVRENY, UZAVRENY}

export enum DluhFakturaStav {NEODESLANA, NEPOTVRZENA, NEUHRAZENA, UHRAZENA, VYRESENO_BEZ_UHRAD, NEUHRAZENA_VYPOVED, NEUHRAZENA_39, PROMLCENO, ZAMITNUTO}


export class WithUserBase {
    id: number;
    @JsonProperty({converters: MomentConverters})
    datCas: Moment;
    @JsonProperty({type:{clazz:UserBasic}})
    uzivatel: UserBasic;
}

export class WithFileBase extends WithUserBase {
    popis?: string;
    soubor?: string;
    mimeType?: string;
    @JsonProperty({
        type: {clazz: Blob},
        converters: {
            fromJson(value: any): any {
                return value
            },
            toJson(value: any): any {
                return exist(value) ? value : undefined;
            }
        }
    })
    novySoubor?: File;
}

export class DluhNepohOsoba extends WithUserBase {
    prijmeni?: string;
    jmeno?: string;
    rodcis?: string;
    adresa?: string;
    ica?: string;
    poznamka?: string;
    @JsonProperty({type:{clazz: Provozovna, isArray: true}})
    provozovny: Provozovna[]
}


export class DluhNepohOsobaFilter {
    prijmeni?: string;
    jmeno?: string;
    rodcis?: string;
    adresa?: string;
    ica?: string;
    poznamka?: string;
    provozovny?: string;
}

export class DluhPripad extends WithUserBase {
    nadpripadId?: number;
    nadpripad?: DluhNadpripad
    icoDluznika?: boolean;
    dicDluznika?: boolean;
    regNazevDluznika?: string;
    @JsonProperty({type:{clazz: Provozovna}})
    provozovnaDluznika?: Provozovna;
    provozovnyDluznika?: Provozovna[]
    zodpOsobaDluz?: string;
    zodpTelDluz?: string;
    zodpMailDluz?: string;
    icoVer?: string;
    dicVer?: string;
    regNazevVer?: string;
    @JsonProperty({type:{clazz: Provozovna}})
    provozovnaVer?: Provozovna;
    provozovnyVeritele?: Provozovna[]
    zodpOsobaVer?: string;
    zodpTelVer?: string;
    zodpMailVer?: string;
    @JsonProperty({converters: MomentConverters})
    kdyZaloz?: Moment;
    @JsonProperty({type:{clazz: UserBasic}})
    kdoZaloz: UserBasic;
    @JsonProperty({type:{enum: DluhPripadStav}})
    stav: DluhPripadStav;
    active?: boolean
    @JsonProperty({converters: MomentConverters})
    aktKeDni: Moment;
    faktury?: DluhFakt[]
}

export class DluhPripadFilter  {
    nadpripadId?: number;
    icoDluznika?: boolean;
    dicDluznika?: boolean;
    regNazevDluznika?: string;
    icoVer?: string;
    dicVer?: string;
    regNazevVer?: string;
    @JsonProperty({type:{enum: DluhPripadStav}})
    stav: DluhPripadStav;
    active?: boolean
    provozovnaKod?: string;
    kdoZaloz?: string;
    datCasRange?: DateRange;
    @JsonProperty({type:{enum: Stav}})
    provozovnaDluznikaStav?: Stav
    @JsonProperty({type:{enum: Stav}})
    provozovnaVerStav?: Stav
}

export class DluhNadpripad extends WithUserBase {
    @JsonProperty({type:{enum: DluhNadpripadStav}})
    stav?: DluhNadpripadStav;
    @JsonProperty({converters: MomentConverters})
    kdyPozastavit?: Moment;
    vypoved?: boolean;
    bod39?: boolean;
    @JsonProperty({converters: MomentConverters})
    upozorneni?: Moment;
    @JsonProperty({type:{clazz: DluhPripad, isArray: true}})
    pripady: DluhPripad[];
    @JsonProperty({type:{clazz: Provozovna}})
    provozovnaDluznika?: Provozovna
    zodpMailDluz?: string
    provozovnyDluznika?: Provozovna[]
}

export class DluhNadpripadFilter {
    @JsonProperty({type:{enum: DluhNadpripadStav}})
    stav?: DluhNadpripadStav;
    kdyPozastavit?: DateRange;
    vypoved?: boolean;
    bod39?: boolean;
    upozorneni?: DateRange;
    @JsonProperty({type:{enum: Stav}})
    provozovnaDluznikaStav?: Stav
}

export class DluhFakt extends WithUserBase {
    cislo?: string;
    castka?: number;
    @JsonProperty({type:{clazz:Currency}})
    currency?: Currency
    @JsonProperty({converters: MomentConverters})
    datSplat?: Moment;
    @JsonProperty({converters: MomentConverters})
    kdyUhradil?: Moment;
    @JsonProperty({type:{clazz:UserBasic}})
    kdoUhradil?: UserBasic;
    @JsonProperty({converters: MomentConverters})
    kdyBezUhrady?: Moment;
    @JsonProperty({type:{clazz:UserBasic}})
    kdoBezUhrady?: UserBasic;
    @JsonProperty({type:{enum: DluhFakturaStav}})
    stav?: DluhFakturaStav;
    ohlasUhr?: boolean;
    duvodZamitnuti?: string;
    poznamka?: string;

    static of(): DluhFakt {
        const f = new DluhFakt();
        f.ohlasUhr = false;
        return f;
    }
}

export class DluhFaktFilter {
    cislo?: string;
    duvodZamitnuti?: string;
    poznamka?: string;
}

export class DluhEmail {
    language: ReportLanguage
    body: string
    subject: string
    to: string
    typOdesilatele?: TypOdesilatele
    @JsonProperty({converters: {
            toJson(value: any): any {
                if(exist(value)) {
                    const m = moment(value);
                    if(!m.isValid()) {
                        return null;
                    }
                    return m.format('YYYY-MM-DD');
                }
                return null;
            }
    }})
    datumUzavreniSmlouvy?: Moment
    @JsonProperty({converters: {
            toJson(value: any): any {
                if(exist(value)) {
                    const m = moment(value);
                    if(!m.isValid()) {
                        return null;
                    }
                    return m.format('YYYY-MM-DD');
                }
                return null;
            }
    }})
    datumPozastaveniSmlouvy?: Moment
}
