import React from "react";
import {FormInputType} from "../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {UzivatelCiselnik} from "../admin/UzivatelCiselnik";
import {RouteComponentProps} from "../../../routes";
import {UserRoleFilter} from "../../../model/User";


export function UzivatelCiselnikMasterRole(props: RouteComponentProps) {
    const {t} = useTranslation();
    return <UzivatelCiselnik
        mode={props.mode}
        filtering={{
            extendedFilter:false
        }}
        // TODO udelat to jinak nez admin/master/user
        admin={false}
        master={true}
        hideAddNewActions
        url={"master/uzivatel"}
        excludeRoles={[UserRoleFilter.ROLE_ADMIN]}
        formFieldOverrides={
        [
            {
                name:"provozovna",
                disabled:true
            },
            {
                name:"roles",
                disabled:true
            },
        ]}
        additionalColumns={[
        {
            field: "poznamka", title: t("Default.NoteMaster"),
            filterProps:() => ({type: FormInputType.Text})
        }
        ]}
    />;
}
