import {Misto} from "../../web/model/PrepravaVozidlo";
import {User} from "../../web/model/User";
import {exist} from "../utils/Util";
import {
    CZ_COUNTRIES,
    CZSK_COUNTRIES,
    EU_COUNTRIES,
    LicenseType,
    Region,
    SK_COUNTRIES
} from "../../web/model/CommonTypes";
import {OsmPlace} from "../component/map/Nominatim";


export const getCountries = (region: Region): string[] => {
    switch (region) {
        case Region.CZ:
            return CZ_COUNTRIES;
        case Region.EU:
            return EU_COUNTRIES;
        case Region.GLOBAL:
            return [];
        case Region.SK:
            return SK_COUNTRIES;
        case Region.CZSK:
            return CZSK_COUNTRIES;
        default:
            return [];
    }
}

export const countryCodesForRequest = (user: User, checkUserProvozovna?: boolean, countriesOnly?: boolean, extendedCountries: string[] = []) => {
    if (!exist(user)) return '';
    const region = checkUserProvozovna ? user.provozovna?.typ : user.region;
    if (countriesOnly) return [...getCountries(region), ...extendedCountries].join(',');
    return getCountries(region)?.length !== 0 ? '&countrycodes=' + [...getCountries(region), ...extendedCountries].join(',') : '';
}

export function hasLicense(user: User, mista: Misto[], checkUserProvozovna?: boolean): boolean {
    const licence = checkUserProvozovna ? user.provozovna : user;
    if (!exist(licence.typLicence) || licence.typLicence === LicenseType.WITHOUTKM)
        return false;

    if (!exist(mista) || mista.length === 0)
        return true;

    for (let i = 0; i < mista.length; i++) {
        const region = checkUserProvozovna ? user.provozovna?.typ : user.region;
        const misto = mista[i];
        const countryCode = misto.countryCode
        const countries = getCountries(region);
        const hasAccess = region===Region.GLOBAL || countries.indexOf(countryCode)>-1;
        if(!hasAccess) {
            if(region!==Region.EU || !(
                (i===0 && countryCode==="CZ" && (mista.length===1 || mista[mista.length - 1].countryCode!=="CZ")) ||
                (i===0 && countryCode!=="CZ" && hasAccess && (mista.length===1 || mista[mista.length - 1].countryCode==="CZ")) ||
                (i===mista.length - 1 && countryCode!=="CZ" && hasAccess && mista[0].countryCode==="CZ") ||
                (i===mista.length - 1 && countryCode==="CZ" && mista[0].countryCode!=="CZ") ||
                (i>0 && i<mista.length - 1)))
            return false;
        }
    }

    return true;
}

export const hasLicenseForPlace = (user: User, misto: OsmPlace): boolean => {

    if (!exist(user.typLicence) || user.typLicence === LicenseType.WITHOUTKM)
        return false;

    if (!exist(misto))
        return true;

    const countries = getCountries(user.region);

    return user.region===Region.GLOBAL || countries.indexOf(misto?.address?.country_code?.toUpperCase())>-1;
}
