export enum OsrmProfile {N1, N2, N3}

export type OsrmProfileDataItem = {
    vaha: number,
    sirka: number,
    delka: number,
    vyska: number
}
export const OsrmProfileData = new Map<OsrmProfile, OsrmProfileDataItem>([
    [
        OsrmProfile.N1,
        {
            vaha: 3.5,
            sirka: 2.3,
            delka: 7.4,
            vyska: 3.3
        }
    ],
    [
        OsrmProfile.N2,
        {
            vaha: 12,
            sirka: 2.55,
            delka: 11.5,
            vyska: 3.8
        }
    ],
    [
        OsrmProfile.N3,
        {
            vaha: 40,
            sirka: 2.55,
            delka: 18.75,
            vyska: 4
        }
    ]
]);