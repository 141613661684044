import {useAppContext} from "../../context/AppContext";
import {useTranslation} from "react-i18next";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React from "react";
import {HeaderButtonProps, IconButtonWithText} from "./HeaderAction";

export function HeaderLogoutButton({showText}:HeaderButtonProps) {
    const {logout} = useAppContext();
    const {t} = useTranslation();
    const {checkDataChanged} = useAppContext();
    let tooltip = t("SignedOut");
    return (
        <IconButtonWithText icon={<ExitToAppIcon />} onClick={(event) => checkDataChanged(() => {
            event.preventDefault();
            logout();
        })
        } tooltip={tooltip} text={t("Buttons.Logout")} showText={showText}/>
    );
}
