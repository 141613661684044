import { useAppContext } from "../../context/AppContext";
import {getActions, MTToolbarButton, MTToolbarProps} from "./MTToolbar";
import {useTranslation} from "react-i18next";
import React, {MutableRefObject, useEffect, useImperativeHandle, useRef, useState} from "react";
import {IconButton, Tooltip} from "@material-ui/core";
import {ExportMenu} from "./MTExportContainer";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { ToggleButton } from "@mui/material";

export type MTMultiColumnActionButtonsExposed = {
    selectedCountChanged:(count: number) => void
}

export const MultiColumnActionButtons = (props:MTToolbarProps&{selectActionsRef: MutableRefObject<MTMultiColumnActionButtonsExposed>}) => {
    const {checkDataChanged} = useAppContext();
    const {t} = useTranslation();
    const menuRef = useRef<MTMultiColumnActionButtonsExposed>(null)
    const [allowMultiAction, setAllowMultiAction] = useState(false)

    useImperativeHandle(props.selectActionsRef, () => ({
        selectedCountChanged
    }));

    const selectedCountChanged = (count: number) => {
        menuRef.current?.selectedCountChanged(count);
        setAllowMultiAction(count !== 0);
    }

    const actions = props?.getCustomMultiColumnActions?.(props.showExport)||[];

    useEffect(() => {
        const onKey = (e:KeyboardEvent) => {
            if(props.selectedRows.length > 0){
                actions.forEach(action=> {
                    const keys = Array.isArray(action.keyMappings) ? action.keyMappings : [action.keyMappings];
                    for (const key of keys) {
                        if(key.toLowerCase() === e.key.toLowerCase()){
                            action.onClick(e, props.selectedRows);
                            e.preventDefault();
                        }
                    }
                });
            }
        }
        window.addEventListener('keydown', onKey);
        return () => {
            window.removeEventListener('keydown', onKey)
        }
    }, [props.selectedRows])

    return props.exportConfig?.exportable && !props.exportConfig?.exportDetailOnly ? <>
        <MTToolbarButton
            icon={<LibraryAddIcon fontSize="small" />}
            tooltip={props.showSelect ? t("MaterialTable.closeMultipleAction") : t("MaterialTable.openMultipleAction")}
            color={props.showSelect ? "default" : "primary"}
            onClick={() =>
                checkDataChanged(() => {
                    props.showExport && props.showExport();
                })
            }
        />
        <ExportMenu menuRef={menuRef} {...props} />
        {props.exportConfig?.exportAll ? <ExportMenu {...props} exportAll={true}/> : null}
        {props.showSelect && getActions(actions || [], props.selectedRows, "primary", !allowMultiAction)}
    </> : null
}