import {useTranslation} from "react-i18next";
import React, {Ref, useImperativeHandle, useState} from "react";
import {MuiModal} from "../../../../../common/component/MuiModal";
import {Grid} from "@mui/material";
import {CodeBookForm} from "../../../../raal_components/controller/CodebookForm";
import {FormButton, FormField, FormInputType} from "../../../../raal_components/form/Form";
import Divider from "@material-ui/core/Divider";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import {INZERAT_VIEW_ENDPOINT, PREPRAVA_VIEW_ENDPOINT, VOZIDLO_VIEW_ENDPOINT} from "../../../../../common/constants";
import {Preprava} from "../../../../model/Preprava";
import {Vozidlo} from "../../../../model/Vozidlo";

export type ModalArchiveViewRecordExposed = {
    setModalVisibility: () => void
}

export class ArchiveRecord {
    typ: string;
    poznamka: string;
}

export function ArchiveViewFormContent() {
    const {t} = useTranslation();
    const {classes: cls} = useStyleContext();

    return <><Grid container spacing={1}>
        <Grid item xs={12}>
            <FormField title={t("ArchiveRecord.poznamka")} type={FormInputType.Text} name={"poznamka"} textFieldProps={{inputProps: {maxLength: 510}}}/>
        </Grid>
    </Grid>

    <Divider className={cls.hrClass}/>

    <div className="modal-buttons">
        <FormButton skipBlock forceTabClick key="archiveViewRecord" type="archiveViewRecord" onSend={() =>
            ({
                requestInit: {
                    method: "POST"
                }
            })}>{t("ArchiveRecordShort")}</FormButton>
    </div></>;
}

export interface ArchiveViewFormProps {
    id: number;
    url: string;
    archived: VoidFunction;
}

function ArchiveViewForm(props: ArchiveViewFormProps) {
    const [data] = useState(new ArchiveRecord());

    return <CodeBookForm
        excludeFieldsForDirtyCheck={Object.keys(data)}
        data={data}
        clazz={ArchiveRecord}
        url={props.url}
        hideNewButtonOnEdit
        hideSaveButton
        onSuccess={() => props.archived()}
        render={() => <ArchiveViewFormContent />}
    />
}

export interface ModalArchiveViewRecordProps {
    data: any;
    title: string
    modalRef?: Ref<ModalArchiveViewRecordExposed>
}

export function ModalArchiveViewRecord(props: ModalArchiveViewRecordProps) {
    const {title, modalRef} = props;
    const [open, setOpen] = useState(() => false);
    const urlBase = props.data instanceof Preprava ? PREPRAVA_VIEW_ENDPOINT : props.data instanceof Vozidlo ? VOZIDLO_VIEW_ENDPOINT : INZERAT_VIEW_ENDPOINT;
    const url = `${urlBase}/${props.data.id}/archive`;

    useImperativeHandle(modalRef, () => ({
        setModalVisibility
    }));

    const setModalVisibility = () => {
        setOpen(!open)
    }

    return (
        <MuiModal
            title={title}
            maxWidth={"lg"}
            fullScreen={false}
            open={open}
            onClose={() => {
                setOpen(false);
            }}>
            {open ? <ArchiveViewForm id={props.data.id} url={url} archived={() => setOpen(false)}/> : undefined }
        </MuiModal>
    )
}
