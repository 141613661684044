import {Provozovna} from "../../../model/Provozovna";
import DataStorage from "../../../../common/DataStorage";
import {exist} from "../../../../common/utils/Util";
import {TFunction} from "i18next";
import {dispatchModal, globalContext, ModalActionType} from "../../../../common/component/ModalContainer";
import {Grid, List, ListItem, ListItemText} from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import {useDidMount} from "../../../../common/component/hooks/SharedHooks";
import {useHashId} from "../../../raal_components/controller/CodeBookController";
import {useTranslation} from "react-i18next";
import {showSnack} from "../../../../common/component/SnackContainer";
import {useFetchCustom} from "../../../../common/utils/HttpUtils";
import {useConfirmDialog} from "../../../raal_components/ConfirmDialog";
import {useHistory, useLocation} from "react-router";
import {Mapper} from "../../../../common/utils/objectmapper/Mapper";
import * as H from 'history';
import {FormButtonClickEvent} from "../../../raal_components/form/FormButton";
import {FormHttpResponse} from "../../../raal_components/form/Form.d";

class ProvozovnaList {
    provozovnaKod: string;
    provozovny: Provozovna[];

    constructor(provozovnaKod: string, provozovny: Provozovna[]) {
        this.provozovnaKod = provozovnaKod;
        this.provozovny = provozovny;
    }
}

export function showProvozovnyModal(t: TFunction, provozovnaList: ProvozovnaList, history: H.History) {
    dispatchModal({type:ModalActionType.Show, title:t("Provozovna.ProcessProvozovny"), body:(
            <List>{provozovnaList.provozovny.filter(p => p.kod !== provozovnaList.provozovnaKod).sort((a, b) => moment(a.kdyZmena).isBefore(moment(b.kdyZmena)) ? 1 : -1 ).map((p: any) => {
                return  <ListItem button component="a" href={`/admin/provozovny/${p.id}`} onClick={(e: React.MouseEvent<HTMLLIElement> | React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
                    e.preventDefault();
                    history.push(`/admin/provozovny/${p.id}`, {forcePreviousPage: `${history.location.pathname}${history.location.hash}`});
                }
                }>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <ListItemText>{p.kod}</ListItemText>
                        </Grid>
                        <Grid item>
                            <ListItemText>{t(`Enumerations.Stav.${p.stav}`)}</ListItemText>
                        </Grid>
                        <Grid item>
                            <ListItemText>{t(`Enumerations.Podstav.${p.podstav}`)}</ListItemText>
                        </Grid>
                        <Grid item>
                            <ListItemText>{moment(p.kdyZmena)?.format("L LT") ?? ''}</ListItemText>
                        </Grid>
                    </Grid>
                </ListItem>;
            })}
            </List>
        ),
        onClose: () => {
            DataStorage.clear("provozovna-process-modal", true, "session");
        }
    })
}

type BlockedProvozovna = {
    id:number,
    kod: string
}

export function useProvozovnaCiselnikLogic() {
    const provozovnaList = DataStorage.get("provozovna-process-modal", true, "session");
    const modalContext = globalContext;
    const id = useHashId();
    const {t} = useTranslation();
    const {fetch: fetchCountPozastavena} = useFetchCustom<BlockedProvozovna[]>({endpoint: () => `admin/provozovna-action/search-pozastavene-provozovny`})
    const [showConfirm] = useConfirmDialog();
    const l = useLocation()
    const history = useHistory()

    const openFunc = () => {
        if (!exist(id) && exist(provozovnaList)) {
            showProvozovnyModal(t, JSON.parse(provozovnaList), history);
        }
    }

    //check initial state
    useDidMount(() => {
        openFunc();
    });

    if ((!exist(id) || l.pathname.indexOf("provozovny") === -1) && exist(provozovnaList) && modalContext.state && !modalContext.state.show)
        openFunc();

    //provided functions
    const onSuccess = (data: Provozovna, event: FormButtonClickEvent, result: FormHttpResponse<Provozovna>) => {
        if (result.extra && result.extra.provozovny) {
            const provozovnaList = DataStorage.get("provozovna-process-modal", true, "session");
            let kod = data.kod;
            const alert = result.extra.alert;

            if (exist(provozovnaList) || alert) {
                if (exist(provozovnaList)) {
                    kod = JSON.parse(provozovnaList).provozovnaKod;
                }

                const pList = new ProvozovnaList(kod, result.extra.provozovny);
                DataStorage.set("provozovna-process-modal", JSON.stringify(pList), true, "session");
                showProvozovnyModal(t, pList, history);
            }
        }
    }

    const handleSend = (continueAction: () => Promise<any>, progressFunction: (showProgress: boolean) => void, data: Provozovna) => {
        progressFunction(true);
        fetchCountPozastavena({params: {kod: data.kod, telcisla: data.telCislaKontaktu}}).then((cnt) => {
            progressFunction(false);
            if (cnt.length > 0) {
                const provoznovny = cnt.map(p => `<a onclick="window.open(this.href,'_blank');return false;" href='./${p.id.toString()}'>${p.kod}</a>`).join(',');
                showConfirm({
                    htmlBody: t("Provozovna.ContainsNumberOfBlocked").replace("{0}", provoznovny),
                    onConfirm: () => {
                        continueAction().then();
                    }
                })
            } else {
                continueAction().then();
            }
        }).catch(() => {
            progressFunction(false);
            showSnack({title: t("UnexpectedError"), severity: "error"});
        });
    }

    const saveResponseSetter = (result: FormHttpResponse<Provozovna>, mapper: Mapper<Provozovna>, json: any) => {
        result.data = mapper.readValue(json.provozovna);
        result.extra = json.extra;
    }

    return {onSuccess, handleSend, saveResponseSetter};
}
