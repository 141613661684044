
export const decimalNumberFormat = "0,0.[000]";

export const shortNumberConstraint = {
    precision:7,
    scale:2
};

export const rateNumberConstraint = {
    precision:7,
    scale:3
};