import React, {FC, MutableRefObject, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import AdapterMoment from "@mui/lab/AdapterMoment";
import {Fade, Grid, Popper} from "@mui/material";
import {
    DatePicker,
    DatePickerProps,
    DateTimePicker,
    DateTimePickerProps,
    LocalizationProvider,
    StaticDatePicker,
    StaticDatePickerProps,
    StaticDateTimePicker,
    StaticDateTimePickerProps,
    StaticTimePicker,
    StaticTimePickerProps,
    TimePicker,
    TimePickerProps
} from "@mui/lab";
import {Theme} from "@material-ui/core/styles";
import {DateTimePickerView} from "@mui/lab/DateTimePicker/shared";
import {CustomDateTimePickerProps} from "../CustomDateTimePicker";
import {InputAdornment, TextField, TextFieldProps, useMediaQuery} from "@material-ui/core";
import {useLocale} from "../../../web/context/LocaleContext";
import moment, {Moment} from "moment";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {useDidMount, useMountedEffect} from "../hooks/SharedHooks";
import {invoke} from "../../utils/Invoke";
import {exist} from "../../utils/Util";
import {BaseDateTimePickerProps} from "@material-ui/pickers";
import {useTranslation} from "react-i18next";

type CalendarComponentExposed = {
    handleOpen: (element: HTMLElement) => void
}

interface CalendarComponentProps {
    value: Date
    handleDateChange: (value: any, keyboardInput?: string) => void
    calendarRef: MutableRefObject<CalendarComponentExposed>
    dateFormat?: boolean
    timeFormat?: boolean
    onClose?: () => void
    onOpen?: () => void
    disablePast?: boolean
    onViewChanged: (view: DateTimePickerView) => void
    onMouseDown?:(e: React.MouseEvent<any>) => void
    onMouseUp?:(e: React.MouseEvent<any>) => void
    disableFuture?: boolean
}

interface StateProps {
    value: Date
    triggerChangeFunction: boolean
}

export const FormDateTimePicker = ({
                                value,
                                onChange,
                                timeFormat = true,
                                dateFormat = true,
                                dateTimePickerProps,
                                textFieldProps,
                                customInputProps,
                                datePattern,
                                timePattern,
                                ...props2
                            }: CustomDateTimePickerProps, ref:Ref<any>) => {

    const resolveState = (value: Date, trigger: boolean): StateProps  => {
        return {value: value ?? null, triggerChangeFunction: trigger};
    };
    const [state, setState] = useState<StateProps>(() => resolveState(value && moment(value).isValid() ? moment(value).toDate() : null, false));
    const {locale} = useLocale();
    const {t} = useTranslation();
    const [invalidError, setInvalidError] = useState<string>();
    const prevError = useRef<string>(null);
    const mountedError = useRef<boolean>(false);
    const firstFocus = useRef(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const calendarRef = useRef<CalendarComponentExposed>();
    const hasFocus = useRef(false);
    const dp = datePattern ?? moment.localeData().longDateFormat('L');
    const tp = timePattern ?? "HH:mm";
    //@ts-ignore
    const {onMouseLeave, onMouseOver, onKeyDown, onTouchStart, title,  ...props} = props2;
    const isDesktopType = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const id = 'simple-popper';
    const keyboardPattern = useRef(`${dp} ${tp}`);
    const shortDate = dp.toLowerCase().indexOf('y') === -1;
    const maskPattern = useRef(`${dp.replace(/([a-zA-Z ])/g, "_")} ${tp.replace(/([a-zA-Z ])/g, "_")}`);
    const hourIndex = useRef(keyboardPattern.current?.toLowerCase().indexOf('hh')??0);
    const minuteIndex = useRef(keyboardPattern.current?.indexOf('mm')??0);
    const blurTimer = useRef(null);
    const isChanging = useRef(false);
    let Component: FC<DateTimePickerProps> | FC<TimePickerProps> | FC<DatePickerProps> = DateTimePicker;

    useImperativeHandle(props.componentRef, () => {
        return {
            focusTextField: () => {
                if (isDesktopType)
                    inputRef.current?.select()
            }
        }
    });

    // Call after internal value changed
    useMountedEffect(() => {
        if (state.triggerChangeFunction) {
            const m = moment(state.value);
            if ((m.isValid() && !prevError.current) || state.value == null || props.triggerChangeIfInvalid) {
                onChange && onChange(m);
            }
            /*else {
                Odstraněno na základě tasku 4261 - nevyprazdňovat pole při chybě validace
               if (value)
                    onChange && onChange(null);

            }*/
            prevError.current = null;
        }
    }, [state]);

    useMountedEffect(() => {
        setState(() => resolveState(props.disabled && props.clearIfDisabled ? null : value?.toDate(), false));
        if (!value && prevError.current) prevError.current = null;
    }, [value, props.disabled]);

    // Mounted
    useDidMount(() => {
        if (!timeFormat) {
            keyboardPattern.current = dp;
            maskPattern.current  = dp.replace(/([a-zA-Z ])/g, "_");
            hourIndex.current = 0;
            minuteIndex.current = 3;
        }
        if (!dateFormat) {
            keyboardPattern.current  = tp;
            maskPattern.current  = tp.replace(/([a-zA-Z ])/g, "_");
            hourIndex.current = 0;
            minuteIndex.current = 0;
        }
        if (props.defaultValue)
            setState(() => resolveState(props.defaultValue.toDate(), true));

        if (value)
            mountedError.current = dateTimePickerProps?.disablePast ? value.isBefore(props.dateForCompare ?? moment(), "minute") : false;

        if (props.error && props.localization && props.localization.hasOwnProperty(props.error)) {
            // @ts-ignore
            setInvalidError(props.localization[props.error]);
        }

    })

    if (!timeFormat) {
        // eslint-disable-next-line
        Component = DatePicker;
    }
    if (!dateFormat) {
        // eslint-disable-next-line
        Component = TimePicker;
    }

    // Date changed
    const handleDateChange = (date?: any, keyboardInput?: string) => {
        if (keyboardInput === undefined) {
            setState(() => resolveState(null, true));
            return;
        }
        if (!keyboardInput) {
            calendarDateChanged(date);
        } else {
            let d = date
            // @ts-ignore
            const fill = keyboardInput !== 'tab' && keyboardInput !== 'mobile' && shortDate && date?._i && date?._i?.trim()?.length === 5 && date?._i?.indexOf(':') === -1
            if (fill && timeFormat) {
                inputRef.current?.blur();
                let dateSplit = date?._i.trim().split('.');
                if (dateSplit.length === 1) dateSplit = date?._i.trim().split('/');
                const dd = dp.toLowerCase().indexOf('dd');
                const dayIndex = dd === 0 ? 0 : 1;
                const monthIndex = dayIndex === 0 ? 1 : 0;
                d = props.addEndOfDay ?
                    moment(`${moment().year()}-${dateSplit[monthIndex]}-${dateSplit[dayIndex]}`).endOf('day') :
                    moment(`${moment().year()}-${dateSplit[monthIndex]}-${dateSplit[dayIndex]}`).startOf('day');
            }
            if (d?.isValid()) {
                let tempDate = (d as Moment).toDate();
                if (shortDate) {
                    const isPast = dateTimePickerProps?.disablePast ? moment(tempDate).isBefore(props.dateForCompare ?? moment(), "day") : false;
                    const shouldDisableDate = dateTimePickerProps?.shouldDisableDate(moment(tempDate).add(1, 'year').toDate());
                    if (isPast && !shouldDisableDate) {
                        tempDate = moment(tempDate).add(1, 'year').toDate();
                    }
                }
                setState(() => resolveState(tempDate, true));
                if (fill && timeFormat) {
                    setTimeout(() => {
                        inputRef.current?.focus();
                        inputRef.current?.setSelectionRange(hourIndex.current, hourIndex.current + 2);
                    }, 1)
                } else {
                    if (keyboardInput !== 'tab' && keyboardInput !== 'mobile') inputRef.current?.focus();
                }
            } else {
                setState(() => resolveState(date?.toDate(), true));
            }
        }
    }

    const calendarDateChanged = (date?: Moment) => {
        const prevValue = state.value;
        const isPast = dateTimePickerProps?.disablePast ? prevValue && moment(prevValue).isBefore(props.dateForCompare ?? moment(), "day") : false;
        if (isPast && mountedError.current) {
            mountedError.current = false
            return;
        }

        let setDate: Date;
        let dayChanged: boolean;
        let minChanged: boolean;
        let hoursChanged: boolean;

        if (prevValue && date) {
            dayChanged = date.dayOfYear() !== moment(prevValue).dayOfYear();
            minChanged = date.minute() !== moment(prevValue).minute();
            hoursChanged = date.hour() !== moment(prevValue).hour();
        }

        if (!date) {
            setDate = null;
        } else if (!prevValue) {
            setDate = !props.addEndOfDay ? date.startOf("day").toDate() : date.endOf("day").toDate();
        } else {
           setDate = !dayChanged ? date.toDate() : !props.addEndOfDay ? date.startOf("day").toDate() : date.endOf("day").toDate();
        }

        if (props.autoSelectFirstValueOnTab && isPast && date) {
            setDate = !props.addEndOfDay ? date.startOf("day").toDate() : date.endOf("day").toDate();
        }

        if (hoursChanged) setDate = date.set({minute: 0}).toDate();

        setState(() => resolveState(setDate, true));

        if (!props.focusNext) {
            inputRef.current?.blur();
            if (timeFormat) {
                setTimeout(() => {
                        if (minChanged || hoursChanged) {
                            inputRef.current?.focus();
                            inputRef.current?.setSelectionRange(minuteIndex.current, -1);
                        }
                }, 50)
            } else {
                setTimeout(() => {
                    inputRef.current?.focus();
                    inputRef.current?.select();
                }, 500)
            }
        }
    }

    // Blur
    const onBlur = useCallback((e: any) => {
        clearTimeout(blurTimer.current);
        blurTimer.current = setTimeout(() => {
            if (!hasFocus.current && !isChanging.current) {
                mountedError.current = state.value ? moment(state.value).isBefore(props.dateForCompare ?? moment(), "minute") : false;
                customInputProps?.InputProps?.onBlur && customInputProps.InputProps.onBlur(e);
                props.onBlur && props.onBlur();
                firstFocus.current = false;
                calendarRef.current?.handleOpen(null);
            }
        },400);
        // eslint-disable-next-line
    }, [hasFocus.current ,isChanging.current])

    // Changed view in Calendar
    const onViewChanged = (view: DateTimePickerView) => {
        inputRef.current?.blur();
        setTimeout(() => {
            if (view === "hours" ) {
                inputRef.current?.focus();
                inputRef.current?.setSelectionRange(hourIndex.current, hourIndex.current + 2);
            } else if (view === "minutes"){
                inputRef.current?.focus();
                inputRef.current?.setSelectionRange(minuteIndex.current, -1);
            } else {
                inputRef.current?.focus();
                inputRef.current.select();
            }
        }, 50);
    }

    const getErrorMessage = () => {
        if (props.skipValidation) return null;
        if (dateTimePickerProps?.minDate && state.value && moment(state.value).isBefore(props.dateForCompare ?? moment(), "minute")) {
            return t("FormLocalization.DateTimePicker.minDateTimeMessage");
        }
        if (invalidError) return invalidError;
        if (prevError.current) return prevError.current;
        if (props.error) return props.error;

        return null;
    }

    return <LocalizationProvider dateAdapter={AdapterMoment} locale={locale}>
        <>
            <DateTimePicker
                value={state.value}
                label={props.label}
                inputFormat={keyboardPattern.current}
                mask={maskPattern.current}
                ampm={false}
                disableOpenPicker={!isDesktopType}
                className={props.inputClassName}
                onAccept={(value) => handleDateChange(value, 'mobile')}
                onOpen={() => {
                    if (!state.value && !isDesktopType) {
                        const setDate = !props.addEndOfDay ? moment().startOf("day").toDate() : moment().endOf("day").toDate();
                        setState(() => resolveState(setDate, true));
                    }
                }}
                onChange={(value: any, keyboardInput: any) => {
                    if (isDesktopType){
                        handleDateChange(value, keyboardInput)
                    }
                }}
                renderInput={(textProps) =>
                    <TextField
                        {...(textProps as TextFieldProps)}
                        variant={textFieldProps.inputVariant??"outlined"}
                        size={textFieldProps.size??"small"}
                        aria-describedby={id}
                        fullWidth={textFieldProps.fullWidth??true}
                        helperText={getErrorMessage()}
                        error={exist(getErrorMessage())}
                        onFocus={(e) => {
                            if (!isDesktopType) return;
                            hasFocus.current = true;
                            if (!firstFocus.current) {
                                customInputProps?.InputProps?.onFocus && customInputProps.InputProps.onFocus(e);
                                props.onFocus && props.onFocus();
                                firstFocus.current = true;
                                setTimeout(() => inputRef.current?.select(), 50);
                            }
                        }}
                        onBlur={(e) => {
                            if (!isDesktopType) return;
                            hasFocus.current = false;
                            onBlur(e);
                        }}
                        InputProps={{
                            inputProps: inputRef.current?.tabIndex ? {
                                tabIndex: inputRef.current.tabIndex,
                                ...(textProps as TextFieldProps).inputProps,
                            } : {
                                ...(textProps as TextFieldProps).inputProps,
                            },
                            endAdornment: <InputAdornment position="end">
                                <CalendarTodayIcon
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        if (!isDesktopType) return;
                                        if (!props.disabled && !props.readOnly) {
                                            props.onFocus && props.onFocus(true);
                                            calendarRef.current?.handleOpen(inputRef.current);
                                            setTimeout(() => inputRef.current.select(), 50);
                                        }
                                    }} />
                            </InputAdornment>
                        }}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (!isDesktopType) return;
                            if (props.autoSelectFirstValueOnTab && (e.key === 'Tab' || e.key === 'Enter')) {
                                //TAB -not used || (state.value && moment(state.value).dayOfYear() < moment().dayOfYear())
                                if (!state.value ) {
                                    // @ts-ignore
                                    e.target.blur(); // As you suggested
                                    e.preventDefault(); // This does the trick
                                    let date = !props.addEndOfDay ?
                                        moment().startOf("day") :
                                        moment().endOf("day");
                                    if (dateTimePickerProps?.minDate) {
                                        date = !props.addEndOfDay ?
                                            moment(dateTimePickerProps.minDate).startOf("day") :
                                            moment(dateTimePickerProps.minDate).endOf("day");
                                    }
                                    handleDateChange(date, 'tab');
                                    invoke(props.onDisableActionKeys, false, true);
                                }
                            } else {
                                onKeyDown&&onKeyDown(e)
                            }


                        }}
                        onMouseDown={props.onMouseDown}
                        onMouseUp={props.onMouseUp}
                    />}
                disabled={props.disabled}
                inputRef={inputRef}
                readOnly={props.readOnly}
                clearable={props.clearable}
                cancelText={props?.localization?.cancelLabel}
                clearText={props?.localization?.clearLabel}
                okText={props?.localization?.okLabel}
                shouldDisableDate={(day) => dateTimePickerProps?.shouldDisableDate&&dateTimePickerProps.shouldDisableDate((day as Moment).toDate())}
                minDate={dateTimePickerProps?.minDate ? moment(dateTimePickerProps?.minDate) : moment('1900-01-01')}
                maxDate={dateTimePickerProps?.maxDate ? moment(dateTimePickerProps?.maxDate) : moment('2100-12-31')}
                disablePast={dateTimePickerProps?.disablePast??false}
                disableFuture={dateTimePickerProps?.disableFuture??false}
                onError={(error) => {
                    if ((error && !prevError.current) || error !== prevError.current){
                        if (props.localization && props.localization.hasOwnProperty(error)) {
                            // @ts-ignore
                            setInvalidError(props.localization[error]);
                            // @ts-ignore
                            prevError.current = props.localization[error];
                        }
                        props.onError && props.onError(error as string);
                    }
                    if (!error) {
                        if (state.value) prevError.current = null;
                        setInvalidError(undefined);
                        props.onError && props.onError(null);
                    }
                }}
            />
            {isDesktopType ? <CalendarComponent
                {...textFieldProps}
                {...dateTimePickerProps}
                calendarRef={calendarRef}
                onViewChanged={onViewChanged}
                value={state.value}
                dateFormat={dateFormat}
                onOpen={() => invoke(props.onInteractStart)}
                onClose={() => invoke(props.onInteractEnd)}
                timeFormat={timeFormat}
                onMouseDown={() => isChanging.current = true}
                onMouseUp={() => isChanging.current = false}
                handleDateChange={(v) =>  handleDateChange(v, null)}
            /> : null}
        </>
    </LocalizationProvider>
}

const CalendarComponent = (props: BaseDateTimePickerProps&CalendarComponentProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const views = useRef<string[]>(["day"]);
    let Component: FC<StaticDateTimePickerProps> | FC<StaticTimePickerProps> | FC<StaticDatePickerProps> = StaticDateTimePicker;
    useImperativeHandle(props.calendarRef, () => ({
        handleOpen
    }));

    const handleOpen = (element: HTMLElement) => {
        setAnchorEl(!anchorEl && element ? element : null);
    }

    useEffect(() => {
        if (!anchorEl) {
            invoke(props.onClose);
        } else {
            invoke(props.onOpen);
        }
        // eslint-disable-next-line
    },[anchorEl])

    if (!props.timeFormat) {
        Component = StaticDatePicker;
    } else {
        views.current = ['day', 'hours', 'minutes'];
    }

    if (!props.dateFormat) {
        Component = StaticTimePicker;
    }
    return <Popper open={exist(anchorEl)} id={anchorEl ? 'simple-popper' : undefined} anchorEl={anchorEl} placement={'bottom-end'} style={{zIndex: 5000}} transition>
        {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
                <Grid item style={{marginTop: '1.5rem',marginBottom: '0.7rem', background: '#fff'}} onMouseDown={props.onMouseDown} onMouseUp={props.onMouseUp}>
                    {exist(anchorEl) ? <Component
                        value={props.value}
                        displayStaticWrapperAs="desktop"
                        ampm={false}
                        allowSameDateSelection={true}
                        showDaysOutsideCurrentMonth={true}
                        onViewChange={(view) => props.onViewChanged(view)}
                        onMonthChange={() => props.onViewChanged("month")}
                        onYearChange={() => props.onViewChanged("year")}
                        onChange={(value: any) => props.handleDateChange(value)}
                        renderInput={(params) => <TextField {...(params as TextFieldProps)} />}
                        shouldDisableDate={(day) => props?.shouldDisableDate&&props.shouldDisableDate((day as Moment).toDate())}
                        minDate={props?.minDate ? moment(props.minDate) : moment('1900-01-01')}
                        maxDate={props?.maxDate ? moment(props.maxDate) : moment('2100-12-31')}
                        disablePast={props?.disablePast??false}
                        disableFuture={props?.disableFuture??false}
                        // @ts-ignore
                        views={views.current}
                    /> : null}
                </Grid>
            </Fade>
        )}
    </Popper>
}
