import {useTranslation} from "react-i18next";
import {Dial} from "../../../../raal_components/dial/Dial";
import {DluhNadpripad, DluhNadpripadFilter, DluhNadpripadStav} from "../../../../model/Stiznosti";
import {stiznost_dluh_nadpripad, stiznost_dluh_nadpripad_soubor} from "../../../../raal_components/Endpoints";
import {FormInputType} from "../../../../raal_components/form/Form";
import * as React from "react";
import {exist} from "../../../../../common/utils/Util";
import {useEnumeratedSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {FormDateRange} from "../../../../../common/component/form/FormDateRange";
import {CheckMark} from "../../../../../common/component/CheckMark";
import {DluhNadpripadLayoutForm} from "./DluhNadpripadLayoutForm";
import {DluhPripadDial} from "./DluhPripadDial";
import {DluhSoubDial} from "./DlouSoubDial";
import {Stav} from "../../../../model/Provozovna";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {ComponentMode, RouteComponentProps} from "../../../../routes";
import {User} from "../../../../model/User";

export function DluhNadpripadDial(props: RouteComponentProps) {
    const {t} = useTranslation();
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const stavSelectProps = useEnumeratedSelect(DluhNadpripadStav, "Enumerations.DluhNadpripadStav", "number", {isClearable: true})
    const stavProvozovnySelect = useEnumeratedSelect(Stav, "Enumerations.Stav", "string", {isClearable:true});

    return <Dial<DluhNadpripad, DluhNadpripadFilter>
        excludeFieldsForDirtyCheck={['datCas', 'stav', 'vypoved', 'bod39', 'uzivatel', 'pripady']}
        mode={props.mode}
        config={{
            clazz: DluhNadpripad,
            tableTitle: t("Dials.Nadpripady"),
            endpoint: stiznost_dluh_nadpripad,
            filtering: true,
            hideAddNewActions: true,
            columns: [
                {
                    title: t("WithUserBase.Id"),
                    field: 'id',
                    filterProps:() => ({type:FormInputType.Number}),
                },
                {
                    title: t("DluhPripad.RegNazevDluznika"),
                    field: 'nazevDluznika',
                    sorting: false,
                    filterProps:() => ({type:FormInputType.Text}),
                    render: data => data.pripady && data.pripady[0]?.regNazevDluznika
                },
                {
                    title: t("DluhPripad.ProvozovnaDluznika"),
                    field: 'provozovnaDluznika',
                    sorting: false,
                    filterProps:() => ({type:FormInputType.Text}),
                    render: data => data.pripady && data.pripady[0]?.provozovnaDluznika?.kod
                },
                {
                    title: t("DluhPripad.StavProvozovnyDluznika"),
                    field: 'provozovnaDluznikaStav',
                    sorting: false,
                    render: (data)=> (data.pripady && data.pripady[0]?.provozovnaDluznika!=null) ? t(`Enumerations.Stav.${data.pripady[0]?.provozovnaDluznika?.stav}`) : null,
                    filterProps: () => ({type: FormInputType.Select, selectProps: stavProvozovnySelect})
                },
                {
                    title: t("DluhNadpripad.Stav"),
                    field: 'stav',
                    render: (row)=> t(`Enumerations.DluhNadpripadStav.${row.stav}`),
                    filterProps: () => ({type: FormInputType.Select, selectProps: stavSelectProps})
                },
                {
                    title: t("WithUserBase.DatCas"),
                    field: 'datCas',
                    defaultSort: "desc",
                    render: data => data.datCas?.format("L LT"),
                    filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, name:"datCasRange", customComponentOptions:{timeFormat: false}}),
                },
                {
                    title: t("WithUserBase.Uzivatel"),
                    field: 'uzivatel',
                    render: data => data.uzivatel?.displayName(),
                    filterProps:() => ({type:FormInputType.Text}),
                },
                {
                    title: t("DluhNadpripad.KdyPozastavit"),
                    field: 'kdyPozastavit',
                    render: data => data.datCas?.format("L LT"),
                    filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, name:"kdyPozastavitRange", customComponentOptions:{timeFormat: false}}),

                },
                {
                    title:t("DluhNadpripad.Vypoved"),
                    field:"vypoved",
                    render:(data: DluhNadpripad) => <CheckMark checked={data.vypoved}/>,
                    filterProps: () => ({type: FormInputType.Select, selectProps:yesNoSelect}),
                },
                {
                    title:t("DluhNadpripad.Bod39"),
                    field:"bod39",
                    render:(data: DluhNadpripad) => <CheckMark checked={data.bod39}/>,
                    filterProps: () => ({type: FormInputType.Select, selectProps:yesNoSelect}),
                },
                {
                    title: t("DluhNadpripad.Upozorneni"),
                    field: 'upozorneni',
                    render: data => data.upozorneni?.format("L LT"),
                    filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, name:"upozorneniRange", customComponentOptions:{timeFormat: false}}),
                },
            ]
        }}
        hideNewButtonOnEdit
        focusFieldWhenMounted
        layoutForm={() => <DluhNadpripadLayoutForm/>}
        crudConfig={{removeEnabled: false, addEnabled:false}}
        reloadData={true}
        tabs={[
            {
                title: t("Dials.Pripady"),
                render: () => <TabHelperNew<DluhNadpripad> render={(data, edited) =>
                    edited&&<DluhPripadDial dluhNadpripad={data} isInTab={true} mode={ComponentMode.GridMode}/>
                }/>,
                disabled: data => !exist(data?.id)
            },
            {
                title: t("DluhSoub.Title"),
                render: () => <TabHelperNew<User> render={(data, edited) =>
                    edited&&<DluhSoubDial url={stiznost_dluh_nadpripad_soubor.replace("{nadPripadId}", data.id.toString())} mode={ComponentMode.GridMode}/>
                }/>,
                disabled: data => !exist(data?.id)
            }
        ]}
        />
}
