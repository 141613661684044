import * as React from "react";
import {forwardRef, useCallback, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {httpEndpoint, httpEndpointArray} from "../../../common/utils/HttpUtils";
import {Provozovna, Stav} from "../../model/Provozovna";
import {TextTuple, TextTupleType} from '../../../common/component/form/TextTuple';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@material-ui/core";
import {invoke} from "../../../common/utils/Invoke";
import {Dispecer} from "../../model/Dispecer";
import {useDidMount} from "../../../common/component/hooks/SharedHooks";
import {useEvaluateFieldsVisibility} from "../../../common/logic/visibility-logic";
import {Show} from "../../../common/component/form/Show";
import {RejstrikLinksComponent} from '../../raal_components/parts/RejstrikLinksComponent';
import {dispatchModal, ModalActionType} from '../../../common/component/ModalContainer';
import {getRejstrikLinks} from '../../../common/utils/LinkUtils';
import {
    AccountBalance,
    Business,
    Comment,
    Commute,
    Email,
    Error,
    Fingerprint,
    HeadsetMic,
    Language,
    Person,
    Phone,
    Public,
    Today,
    Web
} from '@material-ui/icons';
import {FlagIcon} from '../../../common/component/FlagIcon';
import {EmailClient} from '../../../common/component/EmailClient';
import {PhoneNumberDial} from '../../../common/component/PhoneNumberDial';
import {FormField} from "../../raal_components/form/Form";
import {KlasifikaceColor} from "../../model/Klasifikace";
import {useKlasifikaceSelect} from "../../raal_components/SelectOptions";
import {useCodeBookControllerContext} from "../../raal_components/controller/CodeBookController";
import {useStyleContext} from "../../context/ThemeModeContext";
import {FormPartBox} from "./user/prohlizeni/FormPartBox";
import {ExportButtons} from "../../raal_components/grid/MTExportContainer";
import {useCodeBookDetailContext} from "../../raal_components/controller/CodeBookDetail";

export type ProvozovnaViewExposed = {
    setProvozovna:(provozovna:string)=>void
}

export const UserProvozovnaFirmaDetailView = forwardRef<ProvozovnaViewExposed, {provozovnaId?:string, adminView?: boolean}>((props, ref) => {
    const [provozovna, setProvozovna] = useState(props.provozovnaId);
    useImperativeHandle(ref, ()=>{
        return {
            setProvozovna: setProvozovna
        }
    }, []);
    return provozovna ? <ProvozovnaDetail key={provozovna} provozovnaId={provozovna} adminView={props.adminView}/> : <></>;
});

function ProvozovnaDetail(props: {provozovnaId:string, adminView: boolean}) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const [state, setState] = useState<{provozovna:Provozovna, dispeceri:Dispecer[]}>({provozovna:null, dispeceri:null} );
    const {provozovna:data, dispeceri} = state;
    const {data: provozovnaData} = useCodeBookDetailContext<Provozovna>()
    const endpoint = props.adminView ? 'admin/provozovna' : 'user/provozovna';
    const klasifikaceSelect = useKlasifikaceSelect({isClearable: true})

    const reloadData = useCallback(async () => {
        const dResult = await httpEndpointArray<Dispecer>(Dispecer, `${props.adminView ? 'admin' : 'user'}/dispecer?provozovna=${props.provozovnaId}`);
        const pResult = await httpEndpoint<Provozovna>(Provozovna, `${endpoint}/${props.provozovnaId}`);
        setState({provozovna:pResult.data, dispeceri:dResult.data});
    }, [endpoint, props.provozovnaId, props.adminView]);

    useDidMount(() => {
        invoke(reloadData);
    });

    const linksIco = getRejstrikLinks(data?.firma.ico, undefined, data?.firma.r1Stat);
    const linksDic = getRejstrikLinks(undefined, data?.firma.dic, data?.firma.r1Stat);

    const rejstrikFunIco = linksIco.length===0 ? undefined : () => {
            dispatchModal({type:ModalActionType.Show, title:t("Buttons.Rejstriky"), body:(
                <RejstrikLinksComponent links={linksIco}/>
            )})
    };

    const rejstrikFunDic = linksDic.length===0 ? undefined : () => {
        dispatchModal({type:ModalActionType.Show, title:t("Buttons.Rejstriky"), body:(
                <RejstrikLinksComponent links={linksDic}/>
            )})
    };

    const ev = useEvaluateFieldsVisibility(data, data?.firma, props.adminView);
    const stav = data?.stav ? t(`Enumerations.Stav.${data?.stav}`) : '';
    const podstav = data?.podstav ? `(${t(`Enumerations.Podstav.${data?.podstav}`)})` : '';
    const {exportConfig} = useCodeBookControllerContext<Provozovna>();
    return ev&&data&&(
        <>
        <Show visible={!data?.isZkraceneInformace()}>
            <Card style={{height: "100%"}} className={classes.sectionBackground}>
                <CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
                    <Box marginBottom={2}>
                        <Typography variant="h6" >{t("Firma.UserLabel")}</Typography>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} spacing={3}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar>
                                            {data.firma.r1Stat?
                                            <Avatar className={"avatar-flag"}><FlagIcon mpz={data.firma.r1Stat}/></Avatar>:
                                            <Avatar className={"avatar-company"}><Business/></Avatar>}
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple visible={ev(["firma.r1Nazev", "firma.r1Ulice", "firma.r1CisloPopisne", "firma.r1Psc", "firma.r1Obec", "firma.r1CastObce", "firma.r1Stat"])} title={t("Provozovna.CompanyContactLabel")} content={
                                        <>
                                        <Show visible={ev("firma.r1Nazev")}>{data.firma.r1Nazev}<br /></Show>
                                        <Show visible={ev("firma.r1Ulice")}>{data.firma.r1Ulice}</Show> <Show visible={ev("firma.r1CisloPopisne")}>{data.firma.r1CisloPopisne}</Show>
                                        <Show visible={ev(["firma.r1Ulice", "firma.r1CisloPopisne"])}><br /></Show>
                                        <Show visible={ev("firma.r1Psc")}>{data.firma.r1Psc}</Show> <Show visible={ev("firma.r1Obec")}>{data.firma.r1Obec}</Show><Show visible={ev("firma.r1CastObce")}>{`${data.firma.r1CastObce ? ` - ${data.firma.r1CastObce}` : ""}`}</Show> <Show visible={ev("firma.r1Stat")}>{`${data.firma.r1Stat ? `${data.firma.r1Stat}` : ""}`}</Show>
                                        </>
                                        } />
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                            <Show visible={ev("firma.ico")}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <ListItem>
                                        <ListItemAvatar><Avatar className={"avatar-company"}><Fingerprint/></Avatar></ListItemAvatar>
                                        <ListItemText>
                                            <TextTuple title={t("Firma.ico")} content={data.firma.ico} onClick={rejstrikFunIco}/>
                                        </ListItemText>
                                    </ListItem>
                                </Grid>
                            </Show>
                            <Show visible={ev("firma.dic")}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <ListItem>
                                        <ListItemAvatar><Avatar className={"avatar-company"}><Fingerprint/></Avatar></ListItemAvatar>
                                        <ListItemText>
                                            <TextTuple title={t("Firma.dic")} content={data.firma.dic} onClick={rejstrikFunDic}/>
                                        </ListItemText>
                                    </ListItem>
                                </Grid>
                            </Show>
                            <Show visible={ev("firma.dic1")}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <ListItem>
                                        <ListItemAvatar><Avatar className={"avatar-company"}><Fingerprint/></Avatar></ListItemAvatar>
                                        <ListItemText>
                                            <TextTuple title={t("Firma.dic1")} content={data.firma.dic1}/>
                                        </ListItemText>
                                    </ListItem>
                                </Grid>
                            </Show>
                            <Show visible={ev("firma.regDatum")}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <ListItem>
                                        <ListItemAvatar><Avatar className={"avatar-company"}><Today/></Avatar></ListItemAvatar>
                                        <ListItemText>
                                            <TextTuple title={t("Firma.RegDatum")} content={data.firma.regDatum} type={TextTupleType.DateTime}/>
                                        </ListItemText>
                                    </ListItem>
                                </Grid>
                            </Show>
                            <Show visible={ev("firma.datSmlouvy")}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <ListItem>
                                        <ListItemAvatar><Avatar className={"avatar-company"}><Today/></Avatar></ListItemAvatar>
                                        <ListItemText>
                                            <TextTuple title={t("Firma.datSmlouvy")} content={data.firma.datSmlouvy} type={TextTupleType.DateTime}/>
                                        </ListItemText>
                                    </ListItem>
                                </Grid>
                            </Show>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Show>
        <Grid container item xs={12} lg={12} style={{marginBottom: 15}}/>
        <Card style={{height: "100%"}} className={classes.sectionBackground}>
            <CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
                <Box marginBottom={2}>
                    <Typography variant="h6" >{t("Provozovna.Title")}</Typography>
                </Box>
                <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={3}>
                        <Show visible={ev("provozovna.kod")}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Fingerprint/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Provozovna.KodProvozovny")} content={data.kod}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar>
                                        {data.pStat?
                                        <Avatar className={"avatar-flag"}><FlagIcon mpz={data.pStat}/></Avatar>:
                                        <Avatar className={"avatar-company"}><Business/></Avatar>}
                                </ListItemAvatar>
                                <ListItemText>
                                    <TextTuple visible={ev(["provozovna.pNazev", "provozovna.pUlice", "provozovna.pCisloPopisne", "provozovna.pPsc", "provozovna.pObec", "provozovna.pCastObce", "provozovna.pStat"])} title={t("Provozovna.UserContactLabel")} content={
                                        <>
                                        <Show visible={ev("provozovna.pNazev")}>{data.pNazev}<br /></Show>
                                        <Show visible={ev("provozovna.pUlice")}>{data.pUlice}</Show> <Show visible={ev("provozovna.pCisloPopisne")}>{data.pCisloPopisne}</Show>
                                        <Show visible={ev(["provozovna.pUlice", "provozovna.pCisloPopisne"])}><br /></Show>
                                        <Show visible={ev("provozovna.pPsc")}>{data.pPsc}</Show> <Show visible={ev("provozovna.pObec")}>{data.pObec}</Show><Show visible={ev("provozovna.pCastObce")}>{`${data.pCastObce ? ` - ${data.pCastObce}` : ""}`}</Show> <Show visible={ev("provozovna.pStat")}>{`${data.pStat ? `${data.pStat}` : ""}`}</Show>
                                        </>
                                        } />
                                </ListItemText>
                            </ListItem>
                        </Grid>
                        <Show visible={ev(["provozovna.kNazev", "provozovna.kUlice", "provozovna.kCisloPopisne", "provozovna.kPsc", "provozovna.kObec", "provozovna.kCastObce", "provozovna.kStat"])}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar>
                                            {data.kStat?
                                            <Avatar className={"avatar-flag"}><FlagIcon mpz={data.kStat}/></Avatar>:
                                            <Avatar className={"avatar-company"}><Business/></Avatar>}
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Provozovna.UserCorespondLabel")} content={
                                            <>
                                            <Show visible={ev("provozovna.kNazev")}>{data.kNazev ? data.kNazev : "-"}</Show> <Show visible={ev("provozovna.kAdresat")}>{`${data.kAdresat ? `- ${data.kAdresat}` : ""}`}</Show>
                                            <Show visible={ev(["provozovna.kNazev", "provozovna.kAdresat"])}><br /></Show>
                                            <Show visible={ev("provozovna.kUlice")}>{data.kUlice}</Show> <Show visible={ev("provozovna.kCisloPopisne")}>{data.kCisloPopisne}</Show>
                                            <Show visible={ev(["provozovna.kUlice", "provozovna.kCisloPopisne"])}><br /></Show>
                                            <Show visible={ev("provozovna.kPsc")}>{data.kPsc}</Show> <Show visible={ev("provozovna.kObec")}>{data.kObec}</Show><Show visible={ev("provozovna.kCastObce")}>{`${data.kCastObce ? ` - ${data.kCastObce}` : ""}`}</Show> <Show visible={ev("provozovna.kStat")}>{`${data.kStat ? `${data.kStat}` : ""}`}</Show>
                                            </>
                                        } />
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={data?.isZkraceneInformace()}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-admin"}><Error/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Provozovna.Stav")} content={<div style={{fontWeight: 'bold', color: 'red'}}>{`${stav} ${podstav}`}</div>}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={data?.isZkraceneInformace()}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-admin"}><Today/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Provozovna.datzmenstav")} content={data.datzmenstav} type={TextTupleType.DateTime}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={ev("provozovna.datovaSchranka")}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Email/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Provozovna.DatovaSchranka")} content={data.datovaSchranka}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={ev("firma.http")}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Web/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Firma.http")} content={data.firma.http}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={ev("provozovna.spStat")}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Public/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Provozovna.SpStat")} content={data.spStat}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={ev("provozovna.jazyky")}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Language/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Provozovna.jazyky")} content={data.jazyky}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={ev("provozovna.auta")}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Commute/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Provozovna.Auta")} content={data.auta}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={ev("provozovna.not1")}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Comment/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Default.Note")} content={data.not1}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={ev("firma.pojisteni")}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><AccountBalance/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Provozovna.PojisteniNakladu")} content={data.firma.pojisteni}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={ev("provozovna.uOsobaUser")}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Person/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Provozovna.uOsobaUser")} content={data.uOsobaUser}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={ev("provozovna.uTelefonUser")}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar>{
                                        data.uTelefonUser?
                                        <PhoneNumberDial phoneNumber={data.uTelefonUser} hidePhoneNumber={true}/>:
                                        <ListItemAvatar><Avatar className={"avatar-company"}><Phone/></Avatar></ListItemAvatar>
                                        }</ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Provozovna.uTelefonUser")} content={data.uTelefonUser}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={ev("provozovna.uEmailUser")}>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar>{
                                    data.uEmailUser?
                                    <EmailClient emailAddress={data.uEmailUser} hideEmail={true}/>:
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Email/></Avatar></ListItemAvatar>
                                    }</ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Provozovna.uEmailUser")} content={data.uEmailUser}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show visible={ev("provozovna.email")}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Box>
                                    <Typography variant="h6" >{t("Provozovna.emaily")}</Typography>
                                </Box>
                            </Grid>
                            {data.emaily?.join(", ") ?
                                data?.emaily?.filter(e=>e).map((e, i) =>
                            <Grid item lg={3} sm={6} xs={12} key={i * 10 + 2}>
                                <ListItem key={i}>
                                    <EmailClient emailAddress={e}/>
                                </ListItem>
                            </Grid>
                            ) :
                            <Grid item lg={6} sm={12} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-contact"}><Email/></Avatar></ListItemAvatar>
                                    <ListItemText primary={"-"}/>
                                    <EmailClient showDials={false}/>
                                </ListItem>
                            </Grid>
                            }
                        </Show>
                        <Show visible={ev("provozovna.telCislaKontaktu")}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Box>
                                    <Typography variant="h6" >{t("Provozovna.telCislaKontaktu")}</Typography>
                                </Box>
                            </Grid>
                            {data.telCislaKontaktu?.map(it=>it).join(", ") ?
                            data?.telCislaKontaktu?.filter(n=>n).map((n, i)=>
                            <Grid item lg={3} sm={6} xs={12} key={i}>
                                <ListItem>
                                    <PhoneNumberDial phoneNumber={n}/>
                                </ListItem>
                            </Grid>
                            ) :
                            <Grid item lg={6} sm={12} xs={12}>
                                <ListItem>
                                    <PhoneNumberDial showDials={false}/>
                                </ListItem>
                            </Grid>
                            }
                        </Show>
                        <Show visible={ev("provozovna.dispeceri") && dispeceri?.length !== 0}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Box>
                                    <Typography variant="h6" >{t("Provozovna.dispeceri")}</Typography>
                                </Box>
                            </Grid>
                            {dispeceri ?
                            dispeceri.map((d, i)=>
                            <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                                <Grid container alignItems={"center"} spacing={1}>
                                    <ListItem>
                                        <ListItemAvatar><Avatar className={"avatar-company"}><HeadsetMic/></Avatar></ListItemAvatar>
                                        <ListItemText primary={d.jmeno} />
                                    </ListItem>
                                    {d.phoneNumbers?.map((pn, index) => <ListItem key={index}>
                                        <PhoneNumberDial phoneNumber={pn}/>
                                    </ListItem>)}
                                    {d.email && <ListItem><EmailClient emailAddress={d.email}/></ListItem>}
                                </Grid>
                            </Grid>
                            ) :
                            <Grid item lg={6} sm={12} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-contact"}><HeadsetMic/></Avatar></ListItemAvatar>
                                    <ListItemText primary={"-"}/>
                                </ListItem>
                            </Grid>
                            }
                        </Show>
                        <Show visible={!props.adminView}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Box>
                                    <Typography variant="h6" >{t("Klasifikace.klasifikace")}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3} lg={3} style={{paddingLeft: 30}}>
                                <FormField forceUpdateWhenDataChanged={[]} title={t("Klasifikace.klasifikace")} name={"klasifikace.klasifikace"} type={"select"} selectProps={klasifikaceSelect} setValue={(data, fieldName, value) => {
                                    provozovnaData.klasifikace =  {
                                        klasifikace: value,
                                        barva: value ? KlasifikaceColor[parseInt(value)] : null,
                                        nezobrazovat: false,
                                        poznamka: data.klasifikace?.poznamka ?? null
                                    }
                                }}/>
                            </Grid>
                            <Grid item xs={2} sm={1} lg={1} style={{paddingLeft: 30}}>
                                <FormField type={"Color"} name={"klasifikace.barva"} getColor={()=> provozovnaData.klasifikace?.barva } />
                            </Grid>
                            <Grid item xs={10} sm={8} lg={8} style={{paddingLeft: 30}}>
                                <FormField name={"klasifikace.nezobrazovat"} title={t("Klasifikace.nezobrazovat")} type={"checkbox"} disabled={() => provozovnaData.klasifikace?.klasifikace !== "3" }/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} style={{paddingLeft: 30}}>
                                <FormField name={"klasifikace.poznamka"} title={t("Klasifikace.poznamka")} type={"textarea"} textFieldProps={{inputProps: {maxLength: 510}}}/>
                            </Grid>
                        </Show>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        {exportConfig && state.provozovna?.stav === Stav.JEDE ? <>
                <Grid container item xs={12} lg={12} style={{marginBottom: 15}}/>
                <FormPartBox title={t("Actions")}>
                    <Grid item container spacing={1}>
                        <ExportButtons data={{...state.provozovna, dispeceri: [...state.dispeceri]}} exportConfig={exportConfig}/>
                    </Grid>
                </FormPartBox>
            </>
            : null}
        </>
    );
}
