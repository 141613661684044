import {useTranslation} from "react-i18next";
import {httpEndpoint, httpEndpointArray} from "../../common/utils/HttpUtils";
import {DataTemplate, DataTemplateForm} from "../model/DataTemplate";
import DataStorage from "../../common/DataStorage";
import React, {useState} from "react";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import {StandaloneField} from "../../common/component/form/StandaloneField";
import {FormInputType} from "./form/Form";
import {dispatchModal, ModalActionType} from "../../common/component/ModalContainer";
import i18n from "../i18n/i18n";
import DividerWithText from "./DividerWithText";
import {isDeepEqualNullIgnored, jsonToFormUrlEncoded} from "../../common/utils/Util";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import {
    FormSelectPopupItem,
    FormSelectPopupOptions,
    useShowSelectPopup
} from "../../common/component/form/FormSelectPopup";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {useHistory, useLocation} from "react-router";
import _ from "lodash";
import {showSnack} from "../../common/component/SnackContainer";
import {MTToolbarButton} from "./grid/MTToolbar";

export const DATA_TEMPLATE_URL = 'user/data-template';
export const DATA_TEMPLATE_NULLED_VALUES: {datOd: any, datDo: any, datum: any} = {datOd: null , datDo: null, datum: null};

export const DataTemplateToolbarButtons = () => {
    const {t} = useTranslation();
    const {push} = useHistory();
    const {pathname} = useLocation();
    const dataTemplate = useDataTemplateActions();
    const dataTemplateStorage = useDataTemplateStorage();

    const removeTemplate = (item: FormSelectPopupItem) => {
        dispatchModal({
            type: ModalActionType.Show, title: t("Buttons.Delete"), body: (
                <Box  display="flex" flexDirection="row">
                    <Box mr={2}>
                        <Typography variant={"h6"}>
                            {t("DataTemplate.DeleteConfirmationText")}
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <Button variant={"contained"} color={"primary"} onClick={async (e:React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            dataTemplate.removeTemplate( item.value).then((data) => {
                                data ? showSnack({title: t("DataTemplate.Removed"), severity: "success"})
                                    : showSnack({title: t("DataTemplate.RemoveFailed"), severity: "warning", duration: 10e3});
                                showPopup(null).then();
                            });
                            dispatchModal({type:ModalActionType.Hide});
                        }}>{t("Buttons.Delete")}</Button>
                    </Box>
                </Box>
            )
        });
    }

    const getTemplates = async () => {
        const templates = await dataTemplate.getTemplates(pathname)
        return templates.map(template => ({
            label: template.nazev,
            value: template.id as any,
            typDat: template.typDat,
            data: template.data,
        }));
    }

    const formSelectPopupOptions: FormSelectPopupOptions = {
        getSelectData: getTemplates,
        modalTitle: t("DataTemplate.Select"),
        noDataText: t("DataTemplate.NoData"),
        itemActions: [
            {icon: <DeleteOutlineIcon/>, tooltip: t("DataTemplate.RemoveTemplate"), onClick: removeTemplate}
        ],
    };

    const onSelectedValue = (value: any) => {
        let dataTemplate: DataTemplate = {
            id: value.value,
            nazev: value.label,
            typDat: value.typDat,
            data: value.data,
        };
        dataTemplateStorage.storeTemplate(dataTemplate);
        push(pathname + '/new');
    }

    const showPopup = useShowSelectPopup(formSelectPopupOptions, onSelectedValue);

    return <>
        <MTToolbarButton
            icon={<PlaylistAddIcon/>}
            tooltip={t("DataTemplate.Add")}
            onClick={() => {
                dataTemplateStorage.storeTemplate({});
                push(pathname + '/new');
            }}
        />
        <MTToolbarButton
            icon={<PlaylistAddCheckIcon/>}
            tooltip={t("DataTemplate.SavedTemplates")}
            onClick={() => {showPopup(null).then();}}
        />
    </>
}

export const ModalSaveDataTemplate = <D,>(props: {data: D, saveButtonText?: string, saveNewOnly?: boolean, callback:()=>void}) => {
    const dataTemplateStorage = useDataTemplateStorage();
    const template = dataTemplateStorage.getStoredTemplate(true);
    const selectedTemplate = props.saveNewOnly ? "" : template?.nazev ?? "";
    const isFilterSet = selectedTemplate.trim().length > 0 && !props.saveNewOnly;
    const [templateName, setTemplateName] = useState(selectedTemplate);
    const [error, setError] = useState(undefined);
    const {t} = useTranslation();

    const dataTemplate = useDataTemplateActions();

    const saveTemplate = () => {
        if (templateName?.trim().length > 0) {
            const newTemplateData = JSON.stringify(
                _.pickBy({...props.data, ...DATA_TEMPLATE_NULLED_VALUES} as any, _.identity));

            if(isFilterSet){
                template.data = newTemplateData;
                dataTemplate.saveTemplate(template).then((data) => {
                    data ? showSnack({title: t("DataTemplate.Edited"), severity: "success"})
                        : showSnack({title: t("DataTemplate.EditFailed"), severity: "warning", duration: 10e3});
                    props.callback();
                });
                dataTemplateStorage.storeTemplate(template, true);

            } else {
                let dataTemplateForm: DataTemplateForm = {
                    nazev: templateName,
                    typDat: dataTemplateStorage.getCurrentType(true),
                    data: newTemplateData,
                }
                dataTemplate.createTemplate(dataTemplateForm).then((data) => {
                    if(data){
                        showSnack({title: t("DataTemplate.Saved"), severity: "success"});
                        dataTemplateStorage.storeTemplate(data, true);
                        props.callback();
                    } else {
                        showSnack({title: t("DataTemplate.SaveFailed"), severity: "warning", duration: 10e3});
                    }
                });
            }
            dispatchModal({type: ModalActionType.Hide});
        } else {
            setError(t("FormLocalization.FieldMessages.FieldIsRequired"));
        }
    }

    return (
        <><Box display="flex" flexDirection="row">
            <Box mr={2}>
                <Grid container direction="column">
                    <Grid item>
                        <StandaloneField
                            disabled={isFilterSet} focused={true} type={FormInputType.Text}
                            onKeyDown={(e)=>{
                                if(e.key === "Enter") {
                                    e.preventDefault()
                                    saveTemplate();
                                }
                            }}
                            onValueChanged={value => {
                                setTemplateName(value);
                            }}
                            value={templateName} variant={"outlined"} title={t("DataTemplate.Name")} error={error} />
                    </Grid>
                </Grid>
            </Box>
            <Box mb={2} style={{flexGrow: 1}}>
                <Button variant={"contained"} color={"primary"} style={{width: "100%"}} onClick={(e) => {e.preventDefault(); saveTemplate();}}>{isFilterSet ? t("Buttons.SaveAsCurrent") : props.saveButtonText ?? i18n.t("Buttons.Save")}</Button>
            </Box>
        </Box>
            {isFilterSet && <Grid container direction="column">
                <Grid container direction="row" style={{marginBottom: "20px", textTransform: "uppercase", fontSize: "1.5em"}}>
                    <DividerWithText>{t("Default.Or")}</DividerWithText>
                </Grid>
                <ModalSaveDataTemplate {...props} saveNewOnly={true} saveButtonText={t("Buttons.SaveAsNew")} />
            </Grid>}
        </>
    );
}

export function useDataTemplateStorage() {
    const {pathname} = useLocation();

    const getCurrentType = (isDetailOpen: boolean = false) => {
        return isDetailOpen ? pathname.split('/').slice(0, -1).join('/') : pathname;
    }

    const storeTemplate = (template: DataTemplate | {}, applied: boolean = false) => {
        DataStorage.set(applied ? "appliedDataTemplate" : "selectedDataTemplate",
            JSON.stringify(template), false, "session");
    }

    const getStoredTemplate = (applied: boolean = false): null | DataTemplate => {
        return JSON.parse(DataStorage.get(applied ? "appliedDataTemplate" : "selectedDataTemplate",
            false, "session")) as DataTemplate
    }

    const applyTemplate = () => {
        let dataTemplate: DataTemplate | null = null;
        try {
            let template = JSON.parse(DataStorage.get("selectedDataTemplate", false, "session"));
            if(template.data) dataTemplate = JSON.parse(template.data);
            if(template?.typDat != null && getCurrentType(true) !== template.typDat) throw null;
            storeTemplate(template, true);
        } catch (e) {
            DataStorage.clear("appliedDataTemplate", false, "session");
        }
        DataStorage.clear("selectedDataTemplate", false, "session");
        return dataTemplate;
    }

    const hasDataChanged = (formData: any) => {
        const dataTemplate = getStoredTemplate(true);
        const data = dataTemplate?.data != null ? JSON.parse(dataTemplate?.data) : {};
        return !isDeepEqualNullIgnored(
            {...formData, ...DATA_TEMPLATE_NULLED_VALUES},
            {...data, ...DATA_TEMPLATE_NULLED_VALUES}, true);
    }

    const isTemplateCreation = () => {
        const template = getStoredTemplate(true);
        return template != null && Object.keys(template).length === 0;
    }

    return {getCurrentType, storeTemplate, getStoredTemplate, applyTemplate, hasDataChanged, isTemplateCreation};
}
export function useDataTemplateActions() {
    const getTemplateById = async (id: number): Promise<DataTemplate | null> => {
        try {
            return (await httpEndpoint<DataTemplate>(DataTemplate,
                `${DATA_TEMPLATE_URL}/${id}`,{method: "GET"}))?.data;
        } catch (e) { return null; }
    }

    const getTemplates = async (typ: string): Promise<DataTemplate[] | null> => {
        try {
            return (await httpEndpointArray<DataTemplate>(DataTemplate,
                `${DATA_TEMPLATE_URL}/all?type=${encodeURIComponent(typ)}`,{method: "GET"}))?.data;
        } catch (e) { return null; }
    }

    const createTemplate = async (dataTemplateForm: DataTemplateForm): Promise<DataTemplate | null> => {
        try {
            return (await httpEndpoint<DataTemplate>(DataTemplate,
                `${DATA_TEMPLATE_URL}`,{
                    method: 'POST',
                    body: jsonToFormUrlEncoded({...dataTemplateForm}),
                    headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'},
                }))?.data;
        } catch (e) { return null; }
    }

    const saveTemplate = async (dataTemplate: DataTemplate): Promise<boolean> => {
        try {
            return (await httpEndpoint<DataTemplate>(DataTemplate,
                `${DATA_TEMPLATE_URL}/${dataTemplate.id}`,{
                    method: 'PUT',
                    body: jsonToFormUrlEncoded({...dataTemplate}),
                    headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'},
                }))?.response.status === 200;
        } catch (e) { return false; }
    }

    const removeTemplate = async (id: number): Promise<boolean> => {
        try {
            return (await httpEndpoint<DataTemplate>(DataTemplate,
                `${DATA_TEMPLATE_URL}/${id}`,{
                    method: 'DELETE',
                    body: null,
                }))?.response.status === 200;
        } catch (e) { return false; }
    }

    return {getTemplates, createTemplate, saveTemplate, removeTemplate, getTemplateById}
}