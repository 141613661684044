import {useTranslation} from "react-i18next";
import {Dial} from "../../../raal_components/dial/Dial";
import {ComponentMode} from "../../../routes";
import {PravniDokumentace} from "../../../model/PravniDokumentace";
import React, {useRef} from "react";
import {downloadFileSimple} from "../../../../common/utils/DownloadFile";
import {Link} from "@material-ui/core";
import {useErrorTranslator} from "../../../../common/utils/error-utils";
import {useConfirmDialog} from "../../../raal_components/ConfirmDialog";
import {DataGridExposed} from "../../../raal_components/grid/DataGrid";
import {httpEndpointCustom} from "../../../../common/utils/HttpUtils";
import {showSnack} from "../../../../common/component/SnackContainer";
import { Gesture } from "@material-ui/icons";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useAppContext } from '../../../context/AppContext';

export function PravniDokumentaceCiselnik({url= "master/pravni-dokumentace/podepsana", admin = false, kod = ""}) {
    const errorTranslator = useErrorTranslator();
    const [showConfirm] = useConfirmDialog();
    const dtGrid = useRef<DataGridExposed<PravniDokumentace>>();
    const { t } = useTranslation();
	const {user} = useAppContext();

	const getKod = () => {
		if (kod != null && kod !== "") {
			return kod;
		}
		return user?.provozovna?.kod;
	}

    const signContracts = () => {
        if(needsSigning()){
            showConfirm({
                body: t("PravniDokumentace.SignConfirm"),
                onConfirm: async () => {
                    try {
                        dtGrid.current.table().setLoading(true);
                        let result = await httpEndpointCustom(`${url}/signAll`, {method: "POST"});
                        if(result.response.status === 409){
                            showSnack({title: t("PravniDokumentace.AlreadySigned"), severity: "warning"});
                        } else if (!result.response.ok) {
                            showSnack({title: t("PravniDokumentace.SignFailed"), severity: "error"});
                            dtGrid.current.table().setLoading(false);
                            return;
                        } else {
                            showSnack({title: t("PravniDokumentace.SignSuccess"), severity: "success"});
                        }
                        await dtGrid.current.table().refresh();
                    }  finally {
                        dtGrid.current.table().setLoading(false);
                    }
                }
            })
        } else {
            showSnack({title: t("PravniDokumentace.AlreadySigned"), severity: "warning"});
        }
    };

    const deleteContracts = () => {
        if(canBeDeleted()){
            showConfirm({
                body: t("PravniDokumentace.DeleteConfirm"),
                onConfirm: async () => {
                    try {
                        dtGrid.current.table().setLoading(true);
                        let result = await httpEndpointCustom(`${url}/removeAll`, {method: "POST"});
                        if(result.response.status === 409){
                            showSnack({title: t("PravniDokumentace.AlreadyDeleted"), severity: "warning"});
                        } else if (!result.response.ok) {
                            showSnack({title: t("PravniDokumentace.DeleteFailed"), severity: "error"});
                            dtGrid.current.table().setLoading(false);
                            return;
                        } else {
                            showSnack({title: t("PravniDokumentace.DeleteSuccess"), severity: "success"});
                        }
                        await dtGrid.current.table().refresh();
                    }  finally {
                        dtGrid.current.table().setLoading(false);
                    }
                }
            })
        } else {
            showSnack({title: t("PravniDokumentace.AlreadyDeleted"), severity: "warning"});
        }
    };

    const canBeDeleted = () => {
        return (dtGrid?.current?.table()?.getData()?.data ?? []).length > 0;
    }

    const needsSigning = () => {
        for(const data of dtGrid?.current?.table()?.getData()?.data ?? []){
            if(data.nutnyPodpisRaal && data.datumPopisuRaal == null){
                return true;
            }
        }
        return false;
    }

    return (
        <>
            <Dial<PravniDokumentace>
                gridRef={dtGrid}
                mode={ComponentMode.GridMode}
                crudConfig={{addEnabled: false, editEnabled: false, removeEnabled: false}}
                hideEditButton={true}
                config={{
                    id: "master/pravni-dokumentace",
                    filtering: false,
                    tableTitle: t("Dials.Smlouvy"),
                    clazz: PravniDokumentace,
                    endpoint: url,
                    hideAddNewActions: true,
                    disableRowClick: true,
                    alternativeButtons: !admin ? undefined : [{
                        icon:()=><DeleteForeverIcon />,
                        tooltip:t("PravniDokumentace.Delete"),
                        onClick: () => deleteContracts()
                    },
                    {
                        icon:()=><Gesture />,
                        tooltip:t("PravniDokumentace.Sign"),
                        onClick: () => signContracts()
                    }],
                    columns: [
                        {
                            title: t("PravniDokumentace.TypSmlouvy"),
                            field: "id.pravniDokumentace.typ",
                            render: data =>  t(`Enumerations.TypPravniDokumentace.${data.typ}`),
                            cellStyle: {minWidth: 300}
                        },
                        {
                            title: t("PravniDokumentace.Soubor"),
                            field: "id.pravniDokumentace.nazevSouboru",
                            render: data => {
                                return <Link onClick={() => downloadFileSimple(`${url}/${data.jazyk}/${data.typ}/${getKod()}`, errorTranslator)}>
									{getKod() ? `${getKod()}-` : ""}{data.nazevSouboru}
                                </Link>;
                            },
                        },
                        {
                            title: t("PravniDokumentace.DatumVytvoreni"),
                            field: "id.pravniDokumentace.datumVytvoreni",
                            render: data => data.datumVytvoreni?.format("L LT"),
                        },
                        {
                            title: t("PravniDokumentace.DatumPodepsaniPrijemce"),
                            field: "datumPopisuPrijemce",
                            render: data => data.datumPopisuPrijemce?.format("L LT"),
                        },
                        {
                            title: t("PravniDokumentace.DatumPodepsaniRall"),
                            field: "datumPopisuRaal",
                            render: data=> data.nutnyPodpisRaal ? data.datumPopisuRaal?.format("L LT") : ""
                        },
                    ]
                }}></Dial>
        </>
    );
}
