import {ExtraRoute, ExtraWaypoint, FunctionalMap, FunctionMapFunctionsExposed, MapData} from "../map/FunctionalMap";
import React, {useRef, useState} from "react";
import {CustomFieldComponentProps} from "./FormFieldInterface";
import {LatLng} from "leaflet";
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import {useMountedEffect} from "../hooks/SharedHooks";
import {MuiDetailTabModal} from "../MuiDetailTabModal";
import _ from "lodash";
import {TrasaFormViewType} from "../../../web/page/Dials/user/kilometrovnik/TrasaForm";
import {RouteFromOsrmData} from "../../utils/osrm-utils";
import {TrasaType} from "../../../web/page/Dials/user/kilometrovnik/ModalTrasaForm";
import {Waypoint} from "../../../web/model/Waypoint";

type FormDataToMapData<T> = (data:T)=>MapData
type MapDataToFormData<T> = (formData:MapData)=>T
export type MapDataConverters<T> = [FormDataToMapData<T>, MapDataToFormData<T>]

const defaultZoom = 10;
const defaultPosition = new LatLng(50.0596288, 14.446459273258);

export type FormMapOptions<T = any> = {
    latLng?:LatLng,
    zoom?:number,
    onChanged?(data:T):void,
    onClose?(data:T):void,
    maxPoints?:number,
    converters?():MapDataConverters<T>,
    valuePassObject?: boolean,
    updateMapOnOpen?: boolean,
    inlineMap?: boolean,
    mapHeight?: any,
    route?: (i: number) => RouteFromOsrmData,
    viewType?: TrasaFormViewType,
    waypoints?: (i: number) => Waypoint[],
    extraRoutes?: (i: number) => ExtraRoute[],
    extraWaypoints?: (i: number) => ExtraWaypoint[],
    routeOnly?: boolean,
    hideRoute?: (i: number) => boolean,
    trasaType?: TrasaType
    enableOSRM?: boolean
}

export function useConverters<T extends any = MapData>(options:FormMapOptions<T>):MapDataConverters<T> {
    if((options || null) !== null && options.converters) {
        return options.converters();
    }
    return [
        data => data as unknown as MapData,
        mapData => mapData as unknown as T
    ];
}

export function FormMap<T>(props:CustomFieldComponentProps<T, FormMapOptions<T>>) {
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const [hidden, setHidden] = useState(true);
    const [toMapData, toFormData] = useConverters<T>(options);
    const [formPoints, setFormPoints] = useState(toFormData(toMapData(options.valuePassObject ? props.data : props.value)));
    const {t} = useTranslation();
    const onClose = options.onClose || (() => {});
    //ref for getting leaflet object
    const mapRef = useRef<FunctionMapFunctionsExposed>();
    const tempPoints = useRef<T>(null)
    useMountedEffect(() => {
        if (options.viewType === TrasaFormViewType.POINT && !_.isEqual(tempPoints?.current, formPoints)) {
            props.onValueChanged(formPoints);
            options.onChanged && options.onChanged(formPoints);
            tempPoints.current = formPoints
        }
        if (options.viewType !== TrasaFormViewType.POINT) {
            props.onValueChanged(formPoints);
            options.onChanged && options.onChanged(formPoints);
        }
    }, [formPoints]);


    useMountedEffect(() => {
        setFormPoints(toFormData(toMapData(options.valuePassObject ? props.data : props.value)));
    }, [props.value]);

    const points = toMapData(formPoints)

    return (
        options.inlineMap ?
            <FunctionalMap latLng={options.latLng || defaultPosition}
                           ref={mapRef}
                           points={points}
                           onPointsChanged={points=>{
                                const newFormPoints:T = toFormData(points);
                                if(!_.isEqual(formPoints, newFormPoints)) {
                                    setFormPoints(newFormPoints);
                                }
                            }}
                           zoom={options.zoom || defaultZoom}
                           maxPoints={options.maxPoints}
                           inlineMap
                           mapHeight={options.mapHeight}
                           disabled={props.disabled}
                           options={options}
                           viewType={options.viewType}
                           route={options.route}
                           enableOSRM={options.enableOSRM}
                           overrideWaypoints={options.waypoints}
                           extraRoutes={options.extraRoutes}
                           extraWaypoints={options.extraWaypoints}
                           routeOnly={options.routeOnly}
                           hideRoute={options.hideRoute}
                           trasaType={options.trasaType}
                           onDisableActionKeys={props.onDisableActionKeys}
                           onFocusNextField={() => props.onFocusNextField&&props.onFocusNextField(true)}
                           onFocusPrevField={(index) => props.onFocusNextField&&props.onFocusPrevField(index)}
                           resetFormFocus={props.resetFormFocus}
            />
                :
        <>
            <Button disabled={options.viewType && options.viewType === TrasaFormViewType.POINT ? props.disabled : false} fullWidth variant={"contained"} type={"button"}  onClick={() => {
                if(hidden && options.updateMapOnOpen) {
                    mapRef.current && mapRef.current.setPoints(points);
                    points && points[0] && mapRef.current && mapRef.current.leafletFunctions().leaflet().flyTo(points[0].latLng);
                }
                setHidden(!hidden)
            }
            }>{t(`ShowHideMap.${hidden}`)}</Button>
            <MuiDetailTabModal
                open={!hidden}
                onClose={() => {
                    setHidden(true);
                    onClose(formPoints);
                }}
                fullScreen={true}
                title={typeof props.title === "string" ? props.title : null}
            >
                <FunctionalMap
                    latLng={options.latLng || defaultPosition}
                    ref={mapRef}
                    points={points}
                    onPointsChanged={points=>{
                        const formPoints:T = toFormData(points);
                        setFormPoints(formPoints);
                    }}
                    enableOSRM={options.enableOSRM}
                    zoom={options.zoom || defaultZoom}
                    maxPoints={options.maxPoints}
                    route={options.route}
                    disabled={props.disabled}
                    viewType={options.viewType}
                />
            </MuiDetailTabModal>
        </>
    );
}

FormMap.defaultProps = {
  options:{}
};
