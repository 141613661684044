import React, {ReactNode} from "react";
import {AppBar, createStyles, Tab, Tabs} from "@material-ui/core";
import {MuiTabPanel} from "./MuiTabs";
import {clamp} from "../utils/Util";
import {FormComponent} from "../../web/raal_components/form/Form";
import {makeStyles} from "@material-ui/core/styles";

export type TabConfig = {
    title:ReactNode
    body:ReactNode
}
// eslint-disable-next-line
export interface FormTabsProps<T> {
    tabs:TabConfig[]
}

export const useStyles = makeStyles(() =>
    createStyles({
        invertTabs: {
            backgroundColor: "transparent !important"
        }
    }));


/**
 * Slouzi jenom pro taby ve formularich, pokud je potreba udelat taby nekde jinde, tak tohle nepouzivat
 * @constructor
 */
export class FormTabsComponent<CustomOptions = any> extends React.Component<FormTabsProps<CustomOptions>&{getForm():FormComponent<any>, className: string}> {

    state = {tab: 0};

    componentDidMount(): void {
        const form = this.props.getForm();
        form.tabs.push(this);
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({tab: newValue});
    }

    switchTab = (reset: boolean = false, invertDirection: boolean = false) => {
        if (reset){
            this.setState({tab: 0});
        }else{
            // @ts-ignore
            this.setState(prevState =>  ({tab: (invertDirection ? prevState.tab - 1 : prevState.tab + 1)}));
        }

    }

    render() {
        return (
            <>
                <AppBar position="static" style={{boxShadow: 'none', marginTop: 0, marginBottom: 20}} className={this.props.className}>
                    <Tabs value={clamp(this.state.tab, 0, (this.props.tabs.length - 1))} onChange={this.handleChange} indicatorColor="primary" textColor="primary">
                        {this.props.tabs.map((t, i)=><Tab key={i} label={t.title} />)}
                    </Tabs>
                </AppBar>
                {this.props.tabs.map((t, i)=><MuiTabPanel value={clamp(this.state.tab, 0, (this.props.tabs.length - 1))} index={i} key={i} persistContent>{t.body}</MuiTabPanel>)}
            </>
        )
    }
}
