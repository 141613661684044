import * as React from "react";
import {cleanName, FormFieldInterface, FormFieldInterfaceProps} from "./FormFieldInterface";
import {exist} from "../../utils/Util";
import {InputAdornment, TextField, TextFieldProps, Tooltip} from "@material-ui/core";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DialpadIcon from '@material-ui/icons/Dialpad';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import {DynamicInputFontSize} from "../DynamicFontSize";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {IconButton} from "@mui/material";
import {isMobile} from "react-device-detect";


export function ToLowerCase(value:string) {
    return value?.toLowerCase();
}
export function ToUpperCase(value:string) {
    return value?.toUpperCase();
}
export interface FormInputProps extends FormFieldInterfaceProps<any> {
    type:string,
    textFieldProps?: TextFieldProps,
    wrappingFunction?:(value:string)=>any,
    useDynamicFontSize?:boolean,
    togglePasswordVisibility?: () => void,
    passwordVisible?: boolean,
	setDom?: (dom: any) => void,
}



export class FormInput extends React.Component<FormInputProps, {value:string, backupTooltip?:string, isEmpty?:boolean, useDynamicFontSize?:boolean}> implements FormFieldInterface {
    dom:HTMLInputElement;
    inputRef = React.createRef<HTMLInputElement>();

    state = {
        value:this.props.value,
        backupTooltip: this.props.dataTip,
        isEmpty: false
    };

    userClick = false;
    static defaultProps = {
        showAdornment:true,
    };

    componentDidMount(): void {
        this.props.enableFocusSupport&&this.props.enableFocusSupport();
        if(this.props.focused) {
            this.dom.focus();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<FormInputProps>, nextContext: any): void {
        const triggerFocus = nextProps.focused !== this.props.focused && nextProps.focused;
        this.setState({value:nextProps.value}, ()=>{
            if(triggerFocus && !isMobile) {
                this.dom.focus();
            }
        });
    }

    onValueChange(value:any) {
        this.setState({
            value: value === "" ? null : value,
            isEmpty: value !== ""
        }, ()=>{
            this.props.onValueChanged(this.state.value);
        });
    }

    render() {
        const tProps:TextFieldProps = {...{variant:"outlined"}, ...this.props.textFieldProps};
        const _type = this.props.type === "number" ? "text": this.props.type;
        const {wrappingFunction=v=>v} = this.props;
        return (
            <>
                <Tooltip title={this.props.dataTip||""} disableHoverListener={!this.props.dataTip} disableFocusListener={!this.props.dataTip} disableTouchListener={!this.props.dataTip}>
                <TextField
                    {...tProps}
                    size={"small"}
                    inputRef={(input) => {
						this.dom = input;
						this.props.setDom && this.props.setDom(this.dom);
					}}
                    ref={this.inputRef}
                    id={cleanName(this.props.name)}
                    name={cleanName(this.props.name)}
                    type={_type}
                    autoComplete={"off"}
                    hiddenLabel={!Boolean(this.props.title)}
                    label={this.props.title}
                    value={!exist(this.state.value)?"":this.state.value}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                    onBlur={() => {
                        // Odebrat mezery na konci pokud existují
                        if (this.state.value?.slice(-1) === ' ') {
                            this.onValueChange(wrappingFunction(this.state.value?.trimEnd()));
                        }
                        this.props.onBlur&&this.props.onBlur();
                    }}
                    onKeyDownCapture={(e) => {
                        if (e.key === 'Enter' && this.state.value?.slice(-1) === ' ') {
                            this.onValueChange(wrappingFunction(this.state.value?.trimEnd()));
                        }
                    }}
                    onChange={e => {
                        const value = e.target.value;
                        this.onValueChange(wrappingFunction(value?.trimStart()));
                    }}
                    onFocus={() => {
                        this.props.onFocus&&this.props.onFocus(this.userClick);
                        this.dom.select();
                    }}

                    onMouseDown={()=>this.userClick = true}
                    onMouseUp={()=>this.userClick = false}
                    onKeyDown={this.props.onKeyDown}
                    onKeyUp={this.props.onKeyUp}
                    fullWidth
                    error={typeof this.props.error !== 'undefined'}
                    helperText={this.props.error}
                    InputProps={{
                        className: exist(this.state.value) ? this.props.inputClassName : '',
                        endAdornment: this.props.showAdornment ? (
                            <InputAdornment position="end">
                                {
                                    (this.props.type === "text" && !this.props.passwordVisible) && <TextFieldsIcon />
                                }
                                {
                                    (this.props.type === "text" && this.props.passwordVisible) && <IconButton color="inherit" style={{ padding: "0" }} onClick={this.props.togglePasswordVisibility}>
                                        <VisibilityOffIcon />
                                    </IconButton>
                                }
                                {
                                this.props.type === "password" && <IconButton color="inherit" style={{ padding: "0" }} onClick={this.props.togglePasswordVisibility}>
                                    <VisibilityIcon />
                                </IconButton>
                                }
                                {
                                    this.props.type === "date" && <CalendarTodayIcon/>
                                }
                                {
                                    this.props.type === "number" && <DialpadIcon/>
                                }
                                {
                                    this.props.type === "email" && <AlternateEmailIcon/>
                                }
                            </InputAdornment>
                        ) : null,
                        startAdornment: this.props.startAdornment ? <InputAdornment position="start">{this.props.startAdornment}</InputAdornment> : null
                    }}
                />
                </Tooltip>
                {this.props.useDynamicFontSize&&<DynamicInputFontSize text={this.state?.value} target={this.inputRef} />}
            </>
        );
    }

}
