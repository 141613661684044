import * as React from "react";
import {MutableRefObject, useCallback, useContext, useEffect, useRef, useState} from "react";
import {User, UserRole} from "../model/User";
import {LoginPage} from "../page/LoginPage";
import {Token} from "../model/Token";
import TokenStore from "../TokenStore";
import {exist, GetFingerPrint, useHashParams} from "../../common/utils/Util";
import {invoke} from "../../common/utils/Invoke";
import {httpEndpoint, JsonWrapper, setHttpConfig, useFetch, useFetchCustom} from "../../common/utils/HttpUtils";
import {DataContext} from "./DataContext";
import {setLoading} from "../../common/component/LoadingContainer";
import {useStompSubscribe, WebsocketContext} from "../../common/utils/Websocket";
import {Loading} from "../../common/component/Loading";
import {ConnectionError} from "../../common/component/ConnectionError";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {useConfirmDialog} from "../raal_components/ConfirmDialog";
import {dispatchModal, ModalActionType} from "../../common/component/ModalContainer";
import {useDidMount} from "../../common/component/hooks/SharedHooks";
import moment from "moment";
import {useAccounts} from "../Accounts";
import DataStorage from "../../common/DataStorage";
import MTableEditRow from "../raal_components/grid/MTDataGridEditRow";
import {PromptCustom} from "../../common/component/PromptCustom";
import {PubSub} from "use-pubsub-js";
import {useHistoryCustom} from "../raal_components/controller/NavigationHelper";
import {useCmsOznameni} from "../raal_components/CmsOznameniPopup";
import { receiveFCMToken, saveFCMTokenToUser } from '../MobileApp';
import {Template} from "../model/Template";
import {TitlePosition} from "../raal_components/DynamicTitle";
import {AppCache, useAppCache} from "../CacheProvider";

type DataState = {
    user:User,
    initialTemplates: Template[],
    dynamicTitle: MutableRefObject<{position: TitlePosition, value: string, data?: {id: number | string, value: string}[]}[]>,
    setInitialTemplates: (element: Template[]) => void,
    hasUserRole:(roles:UserRole[] | UserRole)=>boolean,
    serverOffset: number,
    setDataChanged: (isChanged: boolean) => void,
    checkDataChanged: (onConfirm?: () => void, onCancel?: () => void, runCallbacks?: boolean) => boolean
    setCurrentPosition:(position: number[]) => void
    setEditRow:(element: any) => void
    getCurrentPosition:() => number[]
    addCallback:(newAsyncClearFunc: AsyncClearFunction) => void
    removeCallback:(id: string) => void
    isDataChanged: MutableRefObject<boolean>
    asyncClearFunctions: () => Promise<void>
}
type AppState = {
    logout: (localOnly?: boolean) => Promise<void>
} & DataState & AppCache

export type AsyncClearFunction = {
    name: string
    id: string
    args: any
}

const Context = React.createContext({} as AppState);

export function useAppContext() {
    return useContext(Context);
}

function UserContext({children, setUser, setInitialTemplates}:React.PropsWithChildren<{setUser:(user:User)=>void, setInitialTemplates:(templates:Template[])=>void}>) {
    const {user, logout, setDataChanged} = useAppContext();
    const [error, setError] = useState(false);
    const {t, i18n} = useTranslation();
    const [showConfirm] = useConfirmDialog();
    const {removeAccount} = useAccounts();
    const {fetch:fetchValidate} = useFetch<User>(User, "user-validate");
    const {fetch:fetchTemplates} = useFetchCustom<Template[]>({ endpoint: "user/filter-template/initial"});
    const validate = async () => {
        setError(false);
        setLoading(true);
        try {
			const user = await fetchValidate()
            setUser(user);
			saveFCMTokenToUser(user);
            if(user) {
                fetchTemplates().then(templates => setInitialTemplates(templates));
                cmsOznameni.processPageLoad();
            }
        } catch (e) {
            setError(true);
        }
        setLoading(false);
    };

    useStompSubscribe("/user-updated", {
        callback:() => {
            showConfirm({
                delay:1000,
                title:t("UserModified.Title"),
                body:t("UserModified.Body"),
                buttons: {
                    confirm:t("Buttons.Logout")
                },
                onConfirm:() => invoke(logout)
            });
        },
        userOnly:true
    });

    useStompSubscribe("/logout", {
        callback:(value: any) => {
            logout(true).then(() => {
                if(value.bySystem===true) {
                    setDataChanged(false);
                    removeAccount(TokenStore.getToken(), true);
                    dispatchModal({type:ModalActionType.Show, title: t("HttpLocalization.SignedOut"), body: t("HttpLocalization.LogoutBySystem")});
                }
            });
        },
        userOnly:true
    });

    const cmsOznameni = useCmsOznameni()
    useStompSubscribe(`/cms-oznameni-${i18n.language}`, {
        callback: cmsOznameni.processOznameni,
        userOnly: false
    });

    useDidMount(() => {
        invoke(validate);
    });
    return (
        <>
            {
                user ? (
                    children
                ) : null
            }
            <Loading show={error}>
                <ConnectionError reconnect={validate} />
            </Loading>
        </>
    );
}

export function AppContext({children}:React.PropsWithChildren<{}>) {
    const [token, setTokenLocally] = useState(()=>TokenStore.get());
    const [user, setUser] = useState(null as User);
    const [initialTemplates, setInitialTemplates] = useState([] as Template[]);
    const [serverOffset, setServerOffset] = useState(0)
    const [fingerPrint, setFingerPrint] = useState(null as string);
    const {replace} = useHistoryCustom();
    const {fetch} = useFetchCustom<Date>({endpoint:`test/test-query?vozidlo=5`});
    const isDataChanged = useRef(false);
    const dynamicTitle = useRef([]);
    const currentPosition = useRef([] as number[]);
    const editRowRef = useRef<MutableRefObject<MTableEditRow>>(null);
    const {addAccount} = useAccounts();
    const [showConfirm] = useConfirmDialog();
    const hashParams = useHashParams();
    const {t} = useTranslation();
    const {push} = useHistory();
    const { getCache, setCache } = useAppCache();

    useEffect(()=>{
        setLoading(true);
        const fp = async() => {
            setLoading(false);
            setFingerPrint(await GetFingerPrint());
        };
		window.receiveFCMToken = receiveFCMToken
        invoke(fp);
    }, []);

    useEffect(() => {
        if (hashParams.get("account") === "add") setTokenLocally(null);

        if (hashParams.get("account") !== "add" && !DataStorage.length("session")) {
            const activeTabToken = DataStorage.get("activeTabToken", false)
            if (activeTabToken) {
                TokenStore.setToken(activeTabToken);
                setTokenLocally(() => TokenStore.get());
            }
        }
        window.addEventListener('visibilitychange', setCurrentToken)
        window.addEventListener('keydown', onKey)
        return () => {
            window.removeEventListener('visibilitychange', setCurrentToken)
            window.removeEventListener('keydown', onKey)
        }
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(token === null) {
            const u = user?.id??0;
            setUser(null);
            TokenStore.reset();
            DataStorage.clearAll(u, "session")
        } else {
            TokenStore.set(token);
        }
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        if (token && user) {
			addAccount(user);
			window.user = user;
		}
    }, [token, user, addAccount]);

    const logoutLocally = async(skipClear: boolean = false) => {
        if (!skipClear) await asyncClearFunctions();
        PubSub.publish('userLoggedOut');
        PubSub.publish('forceCloseSnackBars', true);
        setTokenLocally(null);
    };

    const clearAndLogoutLocalOn401 = () => {
        invoke(async() => {
            DataStorage.set("activeTabToken", null,  false)
            await logoutLocally()
        });
    }

    const setCurrentToken = () =>{
        DataStorage.set("activeTabToken", TokenStore.getToken(),  false)
    }

    const logout = async (localOnly: boolean = false) => {
        if (!localOnly) {
            try {
                await asyncClearFunctions();
                setLoading(true);
                await httpEndpoint<JsonWrapper<String>>(JsonWrapper, "logout", {method: "POST"}, true);
            } finally {
                setLoading(false);
                await logoutLocally(true);
                replace("/", {skipHide: true});
            }
        } else {
            await logoutLocally();
        }
    };
    // eslint-disable-next-line
    const setServerTime = () => {
        invoke(async() => {
            const serverDate = await fetch({init:{method:"GET"}});
            setServerOffset(moment(serverDate).diff(moment(),"minutes"));
        })
    }

    const hasUserRole = (roles:UserRole[] | UserRole) => {
        const requiredRoles:UserRole[] = !Array.isArray(roles) ? [roles] : roles;
        if (requiredRoles.length === 0) return true;
        for (let i in requiredRoles) {
            if (user.roles.includes(requiredRoles[i])) return true
        }
        return false;
    };

    const onKey = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            if (!isDataChanged.current) push('/');
        }
    }

    const setToken = (token:Token) => setTokenLocally(token);

    const setCurrentPosition = (position: number[]) => {
        currentPosition.current = position
    }

    const getCurrentPosition = useCallback((): number[] => {
        return currentPosition.current;
    }, [currentPosition])

    const setDataChanged = (isChanged: boolean) => {
        isDataChanged.current = isChanged;
    };

    const setEditRow = (element: any) => {
        editRowRef.current = element
    }

    const checkDataChanged = useCallback((onConfirm?: () => void, onCancel?: () => void) => {
        if (onConfirm && isDataChanged.current === true) {
            showConfirm({
                body: t("DataChangedDialog.Body"),
                onConfirm: () => {
                    setDataChanged(false);
                    onConfirm && onConfirm();
                },
                onCancel: () => {
                    onCancel && onCancel();
                    //@ts-ignore
                    editRowRef?.current&&editRowRef.current?.moveFocusToCurrentField();
                },
                title: t("DataChangedDialog.Title"),
                buttons: {
                    cancel: t("Default.No"),
                    confirm:t("Default.Yes")
                }
            });
        }
        else if (onConfirm && isDataChanged.current !== true) {
            onConfirm();
        }
        return isDataChanged.current;
    }, [showConfirm, t]);

    const on403Error = () => {
        push('/error#code=403');
    }

    const checkNullAsyncClearFunc = (asyncClearFunc?: string) => {
        return !(!exist(asyncClearFunc) || asyncClearFunc === 'null');
    }

    const addCallback = (newAsyncClearFunc: AsyncClearFunction) => {
        const asyncClearFunc = DataStorage.get("asyncClearFunc", true, "session");
        const funcArray: AsyncClearFunction[] = checkNullAsyncClearFunc(asyncClearFunc) ? JSON.parse(asyncClearFunc) : [];
        if (funcArray && !funcArray.some(fa =>fa.id === newAsyncClearFunc?.id && fa.name === newAsyncClearFunc?.name))
            DataStorage.set("asyncClearFunc",  JSON.stringify(exist(newAsyncClearFunc) ? [...funcArray, newAsyncClearFunc] : [...funcArray]), true, "session")
    }

    const removeCallback = (id: string) => {
        const asyncClearFunc = DataStorage.get("asyncClearFunc", true, "session");
        const funcArray: AsyncClearFunction[] = checkNullAsyncClearFunc(asyncClearFunc) ? JSON.parse(asyncClearFunc) : [];
        const index = funcArray?.findIndex((c) => c.id === id);
        if (index !== -1) funcArray?.splice(index, 1)
        if (funcArray && funcArray.length !== 0) {
            DataStorage.set("asyncClearFunc", JSON.stringify([...funcArray]), true, "session")
        } else {
            DataStorage.clear("asyncClearFunc", true, "session")
        }
    }

    const asyncClearFunctions = async() => {
    }

    //set token
    setHttpConfig({token: token?token.accessToken:null, fingerprint: fingerPrint, on401:clearAndLogoutLocalOn401, on403: on403Error});
    return (
        <Context.Provider value={{user, initialTemplates, dynamicTitle, setInitialTemplates, logout, hasUserRole, serverOffset, checkDataChanged, setDataChanged, getCurrentPosition, setCurrentPosition, addCallback, removeCallback, isDataChanged, asyncClearFunctions, setEditRow, getCache, setCache}}>
            {fingerPrint?(
                token?(
                    <WebsocketContext token={token}>
                        <UserContext setUser={setUser} setInitialTemplates={setInitialTemplates}>
                            <PromptCustom />
                            <DataContext>
                                {children}
                            </DataContext>
                        </UserContext>
                    </WebsocketContext>
                ):(
                    <LoginPage setToken={setToken}/>
                )
            ):null}
        </Context.Provider>
    );
}
