import {useTranslation} from "react-i18next";
import {Dial} from "../../../../raal_components/dial/Dial";
import {SbernaSluzbaPreprava, SbernaSluzbaPrepravaSelectionListItem, VPFilter} from "../../../../model/PrepravaVozidlo";
import {Preprava} from "../../../../model/Preprava";
import {ExtendedView} from "../_vp/VPExtendedView";
import {CISELNIK_DRUH} from "../prohlizeni/PrepravyCiselnikView";
import * as React from "react";
import {SbernaSluzbaTrasa} from "../../../../model/Trasa";
import Check from "@material-ui/icons/Check";
import {TabHelper} from "../../../../raal_components/controller/TabHelper";
import {useViewColumns} from "./SbernaSluzbaDefault";

export interface SbernaSluzbaDokladkyDialProps {
    sbernaSluzbaTrasa: SbernaSluzbaTrasa;
    onSelect: (preprava: SbernaSluzbaPrepravaSelectionListItem) => void;
}

export function SbernaSluzbaDokladkyDial(props: SbernaSluzbaDokladkyDialProps) {
    const {t} = useTranslation();
    const [cols] = useViewColumns();
    const {sbernaSluzbaTrasa} = props;

    return <Dial<SbernaSluzbaPrepravaSelectionListItem, VPFilter, Preprava>
        logActivity
        config={{
            lockSupport: {enabled: false},
            overflowHidden: true,
            tableTitle: t("Dials.Prepravy"),
            endpoint: `user/sberna-sluzba/${sbernaSluzbaTrasa.id}/dokladky`,
            endpointDetail: `user/prepravaview`,
            clazz: SbernaSluzbaPreprava,
            filtering: false,
            hideAddNewActions: true,
            columns: cols(),
            hideDefaultDummyAction: true,
            initialPageSize: 10,
            rowFiltering: false,
            options: {
                rowStyle: (data: any) => {
                    if (data.klasifikace?.barva) {
                        return {backgroundColor: data.klasifikace?.barva}
                    }
                }
            },
            actions: [
                (rowData) => ({
                    icon: () => <Check/>,
                    onClick: async () => {
                        props.onSelect(rowData);
                    }
                })
            ]
        }}
        isDetailReadOnly={true}
        isModal
        hideSaveButton
        hideNewButtonOnEdit
        hideEditButton
        clazzDetail={Preprava}
        crudConfig={{editEnabled: true, removeEnabled: false, addEnabled: false}}
        layoutDetail={() => <TabHelper<Preprava> render={(data) =>
            <>
                <ExtendedView ciselnikTyp={CISELNIK_DRUH} data={data} admin={false} archive={false}/>
            </>}/>}
        modalHeaderName={() => t("Dials.Prepravy")}
        filterClazz={VPFilter}
    />;
}
