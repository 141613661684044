import {DokladkaList} from "../../../../model/PrepravaVozidlo";
import {Preprava} from "../../../../model/Preprava";
import {ExtendedViewNew} from "../_vp/VPExtendedView";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {Waypoint} from "../../../../model/Waypoint";
import * as React from "react";
import {useEffect, useState} from "react";
import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import {JsonList} from "../../../../../common/utils/Util";

export function DokladkaDetail({data, nakladka, vykladka}:{data: Preprava, nakladka: Waypoint, vykladka: Waypoint}) {

    const {fetch: fetchDokladka} = useFetchCustom<JsonList<DokladkaList>, string>({ endpoint: `user/dokladka/`}, undefined);
    const [dokladka, setDokladka] = useState<DokladkaList>(null);

    useEffect(() => {
        if(nakladka && vykladka){
            fetchDokladka({
                params: {
                    id: data.id,
                    "okoliOdkud.koordinat": JSON.stringify(nakladka.koordinat),
                    "okoliKam.koordinat": JSON.stringify(vykladka.koordinat),
                }
            }).then(d => {setDokladka(d.list[0])});
        }
    }, [])

    return <>
        <ExtendedViewNew ciselnikTyp={CiselnikTyp.P} data={data} admin={false} archive={false} dokladkaProps={
            {
                trasa: data.getMista().map(value => value.koordinat),
                nakladka: nakladka, vykladka: vykladka, dokladka: dokladka
            }
        }/>

    </>;
}