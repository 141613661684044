import * as React from "react";
import {useEffect, useState} from "react";
import {Snackbar, Typography} from "@material-ui/core";
import {Alert, Color} from "@material-ui/lab";
import {SnackbarOrigin} from "@material-ui/core/Snackbar/Snackbar";
import {Button} from "@mui/material";
import {useSubscribe} from "use-pubsub-js";

type SnackButton = {
    onClick?: () => void
    title: string
    style?: React.CSSProperties
}

export type SnackProps = {
    title: string
    severity: Color
    duration?:number
    anchorOrigin?: SnackbarOrigin
    action?: SnackButton[]
    closeText?: string
    preventAutoHide?: boolean
}
let setSnackBarHolder:(props:SnackProps)=>void = null;
export function showSnack(props:SnackProps) {
    setSnackBarHolder&&setSnackBarHolder(props);
}

const multilineBody = (title?: string) => {
    if (!title) return null;
    const bodyArray = title.split('\n');
    return bodyArray.map((title, index) => <Typography key={index} style={bodyArray.length>1 && index===0 ? {textDecoration: 'underline'} : undefined}>{title}</Typography>);
}

export function SnackbarContainer() {
    const [state, setState] = useState({} as SnackProps);
    const [open, setOpen] = useState(false);
    setSnackBarHolder = props => {
        setOpen(false);
        setTimeout(()=>{
            setState({anchorOrigin:{ vertical: 'top', horizontal: 'right' }, ...props});
            setOpen(true);
        }, 1);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handler = (token?:string | symbol) => {
        if (token === 'forceCloseSnackBars') handleClose();
    };

    const { unsubscribe, resubscribe } = useSubscribe({ token: 'forceCloseSnackBars', handler })

    useEffect(() => {
        resubscribe();
        return () => {
            unsubscribe();
        }
        // eslint-disable-next-line
    }, [])

        return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={state.preventAutoHide ? null : state.duration || 2000}
                onClose={(event, reason) => {
                    if (reason !== "clickaway") handleClose();
                }}
                anchorOrigin={state.anchorOrigin}
            >
                <Alert
                    onClose={handleClose}
                    severity={state.severity}
                    variant="filled"
                    action={state.action ? [...state.action.map((a,i) => {
                        return <Button
                            color="inherit"
                            size="small"
                            style={a.style}
                            key={i}
                            onClick={() => {
                                a.onClick && a.onClick();
                                setOpen(false);
                            }}
                        >
                            {a.title}
                        </Button>
                    })] : null}
                >
                    {multilineBody(state.title)}
                </Alert>
            </Snackbar>
        </>
    );
}
