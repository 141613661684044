import * as React from "react";
import {createContext, PropsWithChildren, useContext, useState} from "react";
import {Loading} from "../../common/component/Loading";

type LoadingState = {
    setLoading(state:boolean):void
}

// The standard way to create context. It takes an initial value object
const LoadingContext = createContext(null as LoadingState);

export function useLocalLoading() {
    return useContext(LoadingContext);
}
// eslint-disable-next-line
export default function ({children}:PropsWithChildren<{}>) {
    const [loading, setLoading] = useState(false);
    return (
        <LoadingContext.Provider value={{setLoading}}>
            {
                children
            }
            {
                loading&&<Loading show />
            }
        </LoadingContext.Provider>
    );
}
