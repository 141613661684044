import i18n from "i18next";
import cs from "./cs";
import en from "./en";
import de from "./de";
import pl from "./pl";
import sk from "./sk";
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {invoke} from "../../common/utils/Invoke";

const initi18n = async () => {
    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            // we init with resources
            resources: {
                cs: {
                    translations: cs
                },
                en: {
                    translations: en
                },
                de: {
                    translations: de
                },
                pl: {
                    translations: pl
                },
                sk: {
                    translations: sk
                }
            },
            fallbackLng: "cs",
            debug: true,
            // have a common namespace used around the full app
            ns: ["translations"],
            defaultNS: "translations",

            keySeparator: ".", // we use content as keys

            interpolation: {
                escapeValue: false, // not needed for react!!
                formatSeparator: ","
            },
            react: {
                //wait: true, - not needed for new version
                useSuspense: true
            }
        })
};
invoke(initi18n);
export default i18n;
