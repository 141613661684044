import {JsonProperty} from "../../utils/objectmapper/Mapper";
import {exist} from "../../utils/Util";
import {FormFieldComponent} from "../../../web/raal_components/form/FormField";

export class FieldError {
    name: string;
    message: string;
    localize?: boolean;
    localError:boolean = false;
    @JsonProperty({type:{isArray: true}})
    codes?:string[];
    formField?: FormFieldComponent;
    static Create(message:string, localize:boolean = false, name:string=null):FieldError {
        const fe = new FieldError();
        fe.message = message;
        fe.localize = localize;
        fe.name = name;
        return fe;
    }

    static CreateWithField(message:string, formField: FormFieldComponent):FieldError {
        const fe = new FieldError();
        fe.message = message;
        fe.formField = formField;
        return fe;
    }

    getErrorMessage(): string {
        return this.name!=="entity" && exist(this.formField) ? this.formField.getErrorMessage(this) : this.message ?? (this.codes && this.codes.length>0 && this.codes.reverse()[0]);
    }
}

export function createValidationError(message: string): ValidationError {
    const v = new ValidationError();
    v.message = message;
    return v;
}


export class ValidationError {
    code: number;
    message: string;
    @JsonProperty({type:{clazz: FieldError, isArray: true}})
    errors: Array<FieldError> = new Array<FieldError>();

    getOneErrorMessage(): string {
        const e = this.errors?.find((e) => e.name==="entity")
        if(exist(e)) {
            return e.message ?? (e.codes && e.codes.length>0 && e.codes[e.codes.length-1]);
        } else {
            return this.message === "Form.errors" && exist(this.errors) && this.errors.length>0 ? this.errors[this.errors.length-1].getErrorMessage() : this.message;
        }
    }
}
