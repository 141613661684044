import "reflect-metadata";
import _ from 'lodash';
import {exist} from "../Util";
import {GenericMap} from "../../../index.d";
import {Converters, FieldType, LocalizedProperty, ProvidedField, WriteOptions} from "./Mapper.d";


export interface MapperConfig<T> {
    constructor:{new(): T};
    texts?:GenericMap;
    dataProviders?:(root:T)=>GenericMap<GenericMap>;
}

/**
 * Name = alias pro ukaldani i nacitani
 * loadName = alias pouze pro nacitani, ci-li uklada se jako fieldName
 * saveName = alias pouze pro ukladani, ci-li nacita se jako fieldName
 */
export interface JsonPropertyMetaData<T> {
    name?: string,
    loadName?:string|string[],
    saveName?:string,
    type?: FieldType<T>,
    converters?:Converters
}
// eslint-disable-next-line
export interface JsonClassMetaData<T> {
    converters?:Converters,
    idFieldName?:string
}
// eslint-disable-next-line
export interface JsonIgnoreMetaData<T> {
    ignoreSet?:boolean,
    ignoreGet?:boolean
}
export const JSON_PROPERTY_META_DATA_KEY = "JsonProperty";
export const JSON_CLASS_META_DATA_KEY = "JsonClass";
export const JSON_IGNORE_META_DATA_KEY = "JsonIgnore";

export function JsonClass<T>(metadata?: JsonClassMetaData<T>): (target: Object)=> void {

    let decoratorMetaData: JsonClassMetaData<T>;
    if(metadata === null || typeof metadata === 'undefined') {
        decoratorMetaData = {} as JsonClassMetaData<T>;
    } else if (typeof metadata === 'object') {
        decoratorMetaData = metadata as JsonClassMetaData<T>;
    }
    // @ts-ignore
    return Reflect.metadata(JSON_CLASS_META_DATA_KEY, decoratorMetaData);
}

export function JsonProperty<T>(metadata?: JsonPropertyMetaData<T>|string): (target: Object, targetKey: string | symbol)=> void {
    let decoratorMetaData: JsonPropertyMetaData<T>;
    if(metadata === null || typeof metadata === 'undefined') {
        decoratorMetaData = {};
    } else if (typeof metadata === 'object') {
        decoratorMetaData = metadata as JsonPropertyMetaData<T>;
    }
    return Reflect.metadata(JSON_PROPERTY_META_DATA_KEY, decoratorMetaData);
}
export function JsonIgnore<T>(metadata?: JsonIgnoreMetaData<T> |string): (target: Object, targetKey: string | symbol)=> void {
    let decoratorMetaData: JsonIgnoreMetaData<T>;
    if(metadata === null || typeof metadata === 'undefined') {
        decoratorMetaData = {ignoreGet:true, ignoreSet:true};
    } else if (typeof metadata === 'object') {
        decoratorMetaData = metadata as JsonIgnoreMetaData<T>;
    }
    return Reflect.metadata(JSON_IGNORE_META_DATA_KEY, decoratorMetaData);
}

export interface Mapped extends GenericMap {
    finish?():void
}

const defaultConverter = (value: any) => value;



export function getJsonProperty<T>(target: any, propertyKey: string): JsonPropertyMetaData<T> {
    return Reflect.getMetadata(JSON_PROPERTY_META_DATA_KEY, target, propertyKey);
}
export function getJsonIgnore<T>(target: any, propertyKey: string): JsonIgnoreMetaData<T> {
    return Reflect.getMetadata(JSON_IGNORE_META_DATA_KEY, target, propertyKey);
}
export function getJsonClass<T>(target: any): JsonClassMetaData<T> {
    // @ts-ignore
    return Reflect.getMetadata(JSON_CLASS_META_DATA_KEY, target);
}

const defaultWriteConfig = Object.freeze({springSupport:true});


/**
 * Slouzi k mapovani json do classes a obracene, podporuje spring
 */

export class Mapper<A extends Mapped> {
    config:MapperConfig<A>;
    constructor(cfg:MapperConfig<A>) {
        this.config = cfg;
    }
    getJsonProperty<T>(target: any, propertyKey: string): JsonPropertyMetaData<T> {
        return getJsonProperty(target, propertyKey);
    }
    getJsonIgnore<T>(target: any, propertyKey: string): JsonIgnoreMetaData<T> {
        return getJsonIgnore(target, propertyKey);
    }
    getJsonClass<T>(target: any): JsonClassMetaData<T> {
        // @ts-ignore
        return getJsonClass(target);
    }
    readValueAsArray(data:Array<any>):Array<A> {
        const array:Array<A> = [];
        data.forEach(i=>{
            array.push(this.readValueInternal(new this.config.constructor(), i));
        });
        return array;
    }

    readValue(data:GenericMap|string):A {
        let jsonObject = data;
        if(typeof jsonObject === 'string') {
            jsonObject = JSON.parse(data as string);
        }
        return this.readValueInternal( new this.config.constructor(), jsonObject);
    }


    private readValueInternal(instance:A, data:any):A {
        const providedFields = new Array<ProvidedField<any>>();
        const result = this.readValueInternalRecursive(instance, data, providedFields);
        if(this.config.dataProviders) {
            const providers = this.config.dataProviders(instance);
            providedFields.forEach(({type, value, field, target})=>{
                const provider = providers[type.dataProvider];
                if(provider) {
                    const setValue = (newValue?:any) => target[field] = newValue;
                    const getProviderValue = (refKey:string):any => provider[refKey] || null;
                    if(type.isArray) {
                        const array:Array<any> = [];
                        value.forEach((item:any)=>array.push(getProviderValue(item)));
                        setValue(array.filter(i=>i!==null));
                    } else {
                        setValue(getProviderValue(value));
                    }
                }
            });
        }

        return result;
    }


    private readValueInternalRecursive(instance:A, data:any, providedFields:ProvidedField<any>[]):A {

        if(!exist(data)) {
            return instance;
        }
        const jsonClass = this.getJsonClass(instance.constructor);

        if(jsonClass && jsonClass.converters?.fromJson) {
            return jsonClass.converters?.fromJson(data);
        }
        Object.keys(instance).forEach((oKey: string) => {
            let meta = this.getJsonProperty<any>(instance, oKey) || {};
            let ignore = this.getJsonIgnore(instance, oKey);
            if(ignore && ignore.ignoreGet) {
                return;
            }
            function getValue():any {
                const key = meta.loadName || meta.name || oKey;
                function get(k:string) {
                    let value = data[k];
                    if(!exist(value)) {
                        value = instance[k];
                    }
                    return value;
                }
                if(Array.isArray(key)) {
                    for(let i in key) {
                        let value = get(key[i]);
                        if(exist(value)) {
                            return value;
                        }
                    }
                } else {
                    return get(key);
                }
                return null;
            }
            let value = getValue();
            if(!exist(value)) {
                value = null;
            }
            const type = meta.type || {};
            const {fromJson} = _.merge({fromJson: defaultConverter}, (meta.converters || ({})));
            const setValue = (value?:any, ignoreConverter = false) => {
                (instance as GenericMap)[oKey] = ignoreConverter ? value : fromJson(value);
            };
            //set null value without converters
            setValue(null, true);
            const valueExist = exist(value);
            if(type.localize !== undefined) {
                const cfg:LocalizedProperty = Object.assign({textsProvider:"texts"}, type.localize);
                const texts = this.config.texts;
                if(texts === null || typeof texts === 'undefined') {
                    throw new Error(`Texts provider named ${cfg.textsProvider} is not provided!!! cannot use LocalizedProperty`);
                }
                const getLocalizedValue = (locKey:string):string => {
                    return texts[`${locKey}${cfg.suffix ? `_${cfg.suffix}` : ""}`];
                };
                if(type.isArray) {
                    if(valueExist) {
                        const array:Array<any> = [];
                        value.forEach((item:any)=>array.push(getLocalizedValue(item)));
                        setValue(array.filter(i=>i!==null));
                    }
                } else {
                    let locKey = value;
                    if(cfg.referenceKey) {
                        locKey = data[cfg.referenceKey];
                    }
                    locKey = locKey||cfg.defaultKey;
                    if(locKey){
                        setValue(getLocalizedValue(locKey));
                    }
                }
            } else if(type.dataProvider && valueExist) {
                providedFields.push({field:oKey, target: instance, value: value, type: type})
            } else if(type.enum) {

                const getEnumValue = (enumValue:any):any => {
                    if (typeof enumValue === 'number') {
                        //in the enum there are keys for Enum names but there are number keys too and their values as keys of string names
                        return getEnumValue(type.enum[enumValue]);
                    } else {
                        return type.enum[enumValue];
                    }
                };

                if(Array.isArray(value)) {
                    const array:Array<any> = [];
                    value.forEach(item=>{
                        array.push(getEnumValue(item));
                    });
                    setValue(array);
                } else {
                    setValue(getEnumValue(value));
                }
            } else if(type.clazz && valueExist) {
                if(type.isArray) {
                    const array:Array<any> = [];
                    value.forEach((item:any)=>{
                        array.push(this.readValueInternalRecursive(new type.clazz(), item, providedFields));
                    });
                    setValue(array);
                } else {
                    setValue(this.readValueInternalRecursive(new type.clazz(), value, providedFields))
                }
            } else if(valueExist) {
                setValue(value)
            }
        });
        if(instance.finish) {
            instance.finish();
        }
        return instance;
    }



    writeValueAsJson(instance:A, options?:WriteOptions):GenericMap {
        const writeOptions = {...defaultWriteConfig, ...options};
        const jsonClass = this.getJsonClass(instance.constructor);
        if(jsonClass?.converters?.toJson && !writeOptions.skipJsonClass) {
            const value = jsonClass.converters?.toJson(instance, writeOptions);
            if(value) {
                return value;
            }
        }
        const json = {} as GenericMap;
        Object.keys(instance).forEach(oKey=>{
            let value = instance[oKey];
            let meta = this.getJsonProperty<any>(instance, oKey) || {};
            let ignore = this.getJsonIgnore(instance, oKey);
            const key = meta.saveName || meta.name || oKey;
            if(ignore && ignore.ignoreSet) {
                return;
            }
            const {toJson} = _.merge({toJson: defaultConverter}, (meta.converters || ({})));

            const getValue = (_value:any):any => {
                let newValue = toJson(_value, writeOptions);
                if(writeOptions.springSupport) {
                    let idFieldName = "id";
                    if(_value) {
                        const _jc = this.getJsonClass(_value.constructor);
                        if(_jc && _jc.idFieldName) {
                            idFieldName = _jc.idFieldName;
                        }
                    }
                    //zde dodelavat spring support converse na objektech
                    if(newValue && (newValue as GenericMap)[idFieldName]) {
                        newValue = (newValue as GenericMap)[idFieldName];
                    }
                }
                if(newValue && typeof newValue === "object" && !(newValue instanceof File)) {
                    return this.writeValueAsJson(newValue, options);
                } else {
                    return newValue;
                }
            };

            if(meta.type&&meta.type.isArray) {
                if(value&&Array.isArray(value)) {
                    json[key] = value.map(arrayValue => getValue(arrayValue));
                }
            } else if(meta?.type?.clazz===Blob && !exist(getValue(value))) {
              //skip
            } else {
                json[key] = getValue(value);
            }

            return value;
        });
        return json;
    }
    writeValueAsString(obj:A, options?:WriteOptions) {
        return JSON.stringify(this.writeValueAsJson(obj, options));
    }

}
