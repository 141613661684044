import * as React from "react";
import {MutableRefObject, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {useEnumeratedSelect} from "../../../../raal_components/SelectOptions";
import {Grid} from "@mui/material";
import {FormButton, FormField, FormInputType, useForm, useFormData} from "../../../../raal_components/form/Form";
import {MuiModal} from "../../../../../common/component/MuiModal";
import {DluhEmail, DluhNadpripad, DluhPripad} from "../../../../model/Stiznosti";
import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import {useDidMount} from "../../../../../common/component/hooks/SharedHooks";
import {invoke} from "../../../../../common/utils/Invoke";
import {ReportLanguage} from "../../../../model/ReportText";
import {CodeBookForm} from "../../../../raal_components/controller/CodebookForm";
import moment from "moment";
import {Provozovna} from "../../../../model/Provozovna";
import {useCodeBookDetailContextNew} from "../../../../raal_components/controller/CodeBookDetail";

export enum LetterTypeEnum {
    Dluznik, Veritel, DluznikNadpripad
}

export type LetterComponentExposed = {
    showModal?: (letterType: LetterTypeEnum) => void
}

export interface DluhProps {
    entity: DluhPripad | DluhNadpripad;
    onSend?: VoidFunction;
    modalRef?: MutableRefObject<LetterComponentExposed>
    changeMainFocus?: (state: boolean) => void
}

export enum TypOdesilatele {FYZICKA_OSOBA, PRAVNICKA_OSOBA, SLOVENSKO}

export function LetterComponent(props: DluhProps) {
    const {t} = useTranslation();
    const [data, setData] = useState<DluhEmail>(null);
    const [provozovna, setProvozovna] = useState<Provozovna>(null);
    const [letterType, setLetterType] = useState<LetterTypeEnum>(null);
    const [show, setShow] = useState(false);
    const {fetch} = useFetchCustom<any>();
    const [texts, setTexts] = useState([]);
    const {fetchData} = useCodeBookDetailContextNew();

    useImperativeHandle(props.modalRef, () => ({
        showModal
    }))

    const getLanguage = (prov?: Provozovna) => {
        if (!prov?.pStat) return null;
        const stat = prov.pStat.toLowerCase() === 'cz' ? 'cs' : prov.pStat.toLowerCase();
        return Object.values<string>(ReportLanguage).includes(stat) ? stat as ReportLanguage : null;
    }

    const showModal = (lt: LetterTypeEnum) => {
        let prov: Provozovna = null;
        let email: string = null;
        if (props.entity instanceof DluhPripad ) {
            prov = lt === LetterTypeEnum.Veritel ? props.entity?.provozovnaVer : props.entity?.provozovnaDluznika;
            email = lt === LetterTypeEnum.Veritel ? props.entity?.zodpMailVer : props.entity?.zodpMailDluz;
        } else if (props.entity instanceof DluhNadpripad) {
            prov = props.entity?.pripady?.length !== 0 ? props.entity?.pripady[0].provozovnaDluznika : null;
            email = props.entity?.zodpMailDluz;
        }

        const emailDefault = new DluhEmail();
        emailDefault.to = email ? email : prov?.emaily?.length !== 0 ? prov?.emaily[0] : null;
        emailDefault.language = getLanguage(prov);
        setData(emailDefault);
        setProvozovna(prov);
        setLetterType(lt)
        setShow(true);
    }

    useEffect(() => {
        props.changeMainFocus(show)
        // eslint-disable-next-line
    }, [show])

    const closeModal = () => {
        setShow(false);
        setData(null);
        setLetterType(null);
    }

    useDidMount(() => {
        const getTexts = async() => {
            await fetch({endpoint:`admin/report/text`}).then((response) => {
                setTexts(response.list)
            }).catch(()=>{})
        }

        invoke(getTexts);
    })

    const getUrl = () => {
        if (letterType === LetterTypeEnum.DluznikNadpripad) {
            return `admin/stiznost/dluh-nadpripad/${props.entity.id}/action/vygenerovatAOdeslatDopisDluznikovi`
        } else if (letterType === LetterTypeEnum.Dluznik) {
            return `admin/stiznost/dluh-pripad/${props.entity.id}/action/vygenerovatAOdeslatDopisDluznikovi`
        } else if (letterType === LetterTypeEnum.Veritel) {
            return `admin/stiznost/dluh-pripad/${props.entity.id}/action/vygenerovatAOdeslatDopisVeriteli`
        } else {
            return 'UNSUPPORTED-letterType-' + letterType
        }
    }

    return show&&<MuiModal
        maxWidth={"md"}
        open={show}
        onClose={() => closeModal()}
        title={letterType === LetterTypeEnum.Veritel ?  t("DluhPripad.GenerovatDopisVer") : t("DluhPripad.GenerovatDopisDluznik")}>
        <CodeBookForm<DluhEmail>
            hideNewButtonOnEdit
            excludeFieldsForDirtyCheck={['subject', 'body', 'language', 'to', 'typOdesilatele', 'datumUzavreniSmlouvy', 'datumPozastaveniSmlouvy']}
            hideSaveButton
            data={data}
            clazz={DluhEmail}
            onSuccess={() => {
                closeModal();
                fetchData();
            }}
            url={getUrl()}
            focusFieldWhenMounted
            childForm={true}
            customButtons={() => [
                <FormButton
                    main
                    key={'email'}
                    skipBlock
                    onSend={() => {
                        return {
                            requestInit: {
                                method: "POST"
                            }
                        }
                    }}>
                    {t("WithUserBase.Generovat")}
                </FormButton>
            ]}
            localization={t("FormLocalization", {returnObjects: true})}
            render={() =>
                <LetterForm {...props} provozovna={provozovna} texts={texts} letterType={letterType} entity={props.entity}  />}
        />
    </MuiModal>
}

type LetterFormProps = {
    provozovna: Provozovna
    letterType: LetterTypeEnum
    entity: DluhPripad | DluhNadpripad
    texts: any[]
}

const LetterForm = (props:LetterFormProps) => {
    const langSelectProps = useEnumeratedSelect(ReportLanguage, "Enumerations.ReportLanguage", "string");
    const typOdesilateleSelectProps = useEnumeratedSelect(TypOdesilatele, "Enumerations.TypOdesilatele", "string");
    const {t} = useTranslation();
    const data = useFormData<DluhEmail>();
    const form = useForm<DluhEmail>();


    const changeLanguage = (language: ReportLanguage) => {
        const textKey = props.letterType === LetterTypeEnum.Dluznik ? 'pripad.dluznik' : props.letterType === LetterTypeEnum.Veritel ? 'pripad.veritel' : 'nadpripad.dluznik';
        const obsah = props.texts.find(t =>t.textKey === `email.${textKey}.obsah`);
        const predmet = props.texts.find(t =>t.textKey === `email.${textKey}.predmet`);
        const obsahLang = obsah?.texts.find((t: any) => t.language === language.toLowerCase())??obsah?.texts.find((t: any) => t.language === 'default');
        const predmetLang = predmet?.texts.find((t: any) => t.language === language.toLowerCase())??predmet?.texts.find((t: any) => t.language === 'default');
        data.language = language;
        data.subject = predmetLang.textValue;
        data.body = obsahLang.textValue;
    }

    useDidMount(() => {
        if (data.language) {
            changeLanguage(data.language);
            form.onRefreshFieldValue('language');
        }
    })

    return <Grid container spacing={1}>
        <Grid item xs={12} sm={12} lg={12}>
            <FormField name={"language"}
                       type={FormInputType.AutoComplete}
                       forceUpdateWhenDataChanged={['body', 'to', 'subject']}
                       onWillChange={(field, newValue) => changeLanguage(newValue)}
                       title={t("Report.Language")}
                       selectProps={{...langSelectProps, autoOpenDisabled: true}}
                       required
            />
        </Grid>
        {props.letterType === LetterTypeEnum.DluznikNadpripad ? <>
            <Grid item xs={12} sm={12} lg={12}>
                <FormField
                    name={"typOdesilatele"}
                    title={t("EmailForm.Sender")}
                    forceUpdateWhenDataChanged={['datumUzavreniSmlouvy', 'body']}
                    onWillChange={() => {
                        data.datumUzavreniSmlouvy = props.letterType === LetterTypeEnum.DluznikNadpripad ? moment(props.provozovna?.firma?.regDatum) : null;
                    }}
                    type={FormInputType.AutoComplete}
                    selectProps={{...typOdesilateleSelectProps, autoOpenDisabled: true}}
                    required/>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <FormField
                    name={"datumUzavreniSmlouvy"}
                    title={t("EmailStiznostiForm.smlouva")}
                    type={"datetime"}
                    dateTimeOptions={{timeFormat: false,
                        dateTimePickerProps:{
                            disableFuture: true
                        }
                    }}
                    required />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <FormField
                    name={"datumPozastaveniSmlouvy"}
                    title={t("EmailStiznostiForm.pozastaveno")}
                    type={"datetime"}
                    dateTimeOptions={{
                        timeFormat: false,
                        dateTimePickerProps:{
                            disablePast: true
                        }
                    }}
                    required  />
            </Grid>
        </> : null
        }
        <Grid item xs={12} sm={12} lg={12}>
            <FormField name={"to"} title={t("EmailForm.Receiver")} type={"text"} required/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
            <FormField name={"subject"} title={t("EmailForm.Subject")} type={"text"} required/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
            <FormField name={"body"}
                       title={t("EmailForm.Body")}
                       type={"editor"}
                       textFieldProps={{rows:10}}
                       customComponentOptions={{
                           options: ['inline'],
                           inline: ['bold', 'italic', 'underline']
                       }}
                       required/>
        </Grid>
    </Grid>
}
