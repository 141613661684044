import * as polyline from "@mapbox/polyline";
import {exist} from "./Util";
import DataStorage from "../DataStorage";
import {localeKey} from "../../web/context/LocaleContext";
import {Instruction} from "../component/map/osrmTextInstructions/OSRMTextInstructions";

const version = 'v5';
const osrmTextInstructions = require('../component/map/osrmTextInstructions/index')(version);

export interface RouteFromOsrmData {
    extraRouteType?: ExtraRouteType
    routeData: RouteData[]

}

export interface RouteData {
    route: number[][]
    instruction?: Instruction
}

export enum ExtraRouteType {PREJEZD_OD, PREJEZD_KAM, DOKLADKA}


export function extractRouteFromOsrmData(osrmData: any, extraRouteType?: ExtraRouteType) : RouteFromOsrmData {
    const route: RouteData[] = [];

    if(!exist(osrmData?.routes))
        return undefined;

    for(const l of osrmData.routes[0].legs) {
        if(l.steps) {
            const subRoute = l.steps.map((s: any) => {
                const instruction = osrmTextInstructions.compile(DataStorage.get(localeKey, false), s, {});
                return {
                    instruction: {
                        type: s.maneuver?.type?.toString(),
                        modifier: s.maneuver?.modifier?.toString(),
                        text: instruction.toString(),
                        distance: s.distance,
                        location: s.maneuver?.location
                    },
                    route : polyline.decode(s.geometry)
                }
            });
            for(const sr of subRoute) {
                route.push(sr);
            }
        }
    }

    return {routeData: route, extraRouteType: extraRouteType};
}
