import {VPList} from "../../../../model/PrepravaVozidlo";
import {useTranslation} from "react-i18next";
import {useCiselnikValues} from "../../../../context/DataContext";
import {CISELNIK_DRUH} from "../prohlizeni/PrepravyCiselnikView";
import numeral from "numeral";
import {sizeIntegerFormat, sizeViewFormat} from "../_vp/PrepravaAVozidlaShared";
import {CheckMark} from "../../../../../common/component/CheckMark";
import {DateCell} from "../_vp/VPColumns";
import {PhoneNumberDial} from "../../../../../common/component/PhoneNumberDial";
import {formatPrice, truncateString} from "../../../../../common/utils/Util";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import * as React from "react";

export function useViewColumns<T extends VPList>() {
    const {t} = useTranslation();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);

    return [() => [
        {
            title: t("Preprava.odkud"),
            field: "odkud",
            render: (data: T) => data.odkudHelper,
            sorting: false
        },
        {
            title: t("Preprava.kam"),
            field: "kam",
            render: (data: T) => data.kamHelper,
            sorting: false
        },
        {
            title: t("Preprava.AirDistanceShort"),
            field: "airDistance",
            render: (data: T) => data.airDistance && numeral(data.airDistance).format(sizeIntegerFormat),
            cellStyle: {minWidth: 70},
            headerStyle: {minWidth: 70},
            sorting: false
        },
        {
            title: t("RAALKratkyKody.N"),
            field: "naves",
            render: (data: T) => <CheckMark checked={data.naves}/>,
            sorting: false
        },
        {
            title: t("RAALKratkyKody.S"),
            field: "souprava",
            render: (data: T) => <CheckMark checked={data.souprava}/>,
            sorting: false
        },
        {
            title: t("RAALKratkyKody.J"),
            field: "jine",
            render: (data: T) => <CheckMark checked={data.jine}/>,
            sorting: false
        },
        {
            title: t("Preprava.delkaShort"),
            field: "delka",
            render: (data: T) => data.delka && numeral(data.delka).format(sizeViewFormat),
            cellStyle: {minWidth: 70},
            headerStyle: {minWidth: 70},
            sorting: false
        },
        {
            title: t("Preprava.vahaShort"),
            field: "vaha",
            render: (data: T) => data.vaha && numeral(data.vaha).format(sizeViewFormat),
            cellStyle: {minWidth: 70},
            headerStyle: {minWidth: 70},
            sorting: false
        },
        {
            title: t("Preprava.druhy"),
            field: 'druhy',
            render: (data: T) => druhyJoined(data.druhy),
            cellStyle: {minWidth: 120},
            headerStyle: {minWidth: 120},
            filterStyle: {minWidth: 120},
            sorting: false
        },
        {
            title: t("Preprava.adr"),
            field: 'adr',
            render: (data: T) => <CheckMark checked={data.adr}/>,
            sorting: false
        },
        {
            title: t("Preprava.verejnaPozn"),
            field: 'verejnaPozn',
            sorting: false
        },
        {
            title: t("Default.Datum"),
            field: "datum",
            cellStyle: {minWidth: 120},
            headerStyle: {minWidth: 120},
            render: (data: T) => <DateCell data={data}/>,
            sorting: false
        },
        {
            title: t("Default.Telefon"),
            field: "rychlyKontakt",
            render: (data: T) => <PhoneNumberDial hideIcon phoneNumber={data.rychlyKontakt}/>,
            sorting: false
        },
        {
            title: t("Preprava.cena"),
            field: "cena",
            render: (data: T) => data.cena && data.currency.currencyCode ? formatPrice(data.cena, data.currency.currencyCode) : data.cena ?? '',
            cellStyle: {textAlign: "right"},
            sorting: false
        },
        {
            title: t("Provozovna.Title"),
            field: 'provozovna',
            render: (data: VPList) => truncateString(`${data.provozovna} (${data.firma})`, 20),
            sorting: false
        },
        {
            title: t("PVI.Modified"),
            field: 'modifiedOn',
            render: (data: T) => data.modifiedOn?.format("L LT"),
            sorting: false
        },
        {
            title: t("Preprava.paletyShort"),
            field: 'palety',
            cellStyle: {minWidth: 80},
            headerStyle: {minWidth: 80},
            sorting: false
        }
    ].filter(i => Boolean(i)) as Column<VPList<any>>[]];
}
