import {JsonProperty} from "../../common/utils/objectmapper/Mapper";

export enum CrudOperationType { CREATE, UPDATE, REMOVE}

export class CrudUpdate {
    static of(data: any, crudOperationType: CrudOperationType, tabId: string) {
        const r = new CrudUpdate();
        r.entity = data;
        r.crudOperationType = crudOperationType;
        r.tabId = tabId;
        return r;
    }

    entity: any;
    @JsonProperty({type: {enum: CrudOperationType}})
    crudOperationType: CrudOperationType;
    tabId: string;
}

export class CrudUpdateEvent {
    static of(crudUpdate?: CrudUpdate, stompProcessingOnly?:boolean) {
        const e = new CrudUpdateEvent();
        e.entity = crudUpdate?.entity;
        e.crudOperationType = crudUpdate?.crudOperationType;
        e.tabId = crudUpdate?.tabId;
        e.stompProcessingOnly = stompProcessingOnly;
        return e;
    }

    entity: any;
    @JsonProperty({type: {enum: CrudOperationType}})
    crudOperationType: CrudOperationType;
    tabId: string;

    //helper flag indicating only this message should be processed and no further calls done
    //pokud je false - není zaručeno že přijdou všechny eventy ze stomp, pokud jich přijde hodně najednou!
    stompProcessingOnly?: boolean;
}

export enum MultiEditError {
    NOT_FOUND= "NOT_FOUND",
    LOCKED = "LOCKED",
    MAX_EDITS_REACHED = "MAX_EDITS_REACHED",
    EXPIRED_OFFER = "EXPIRED_OFFER"
}
export class NabidkaMultiEditResult {
    modifiedIds: number[];
    errors: { [key in MultiEditError]?: number[] };
}
