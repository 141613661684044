import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {MultiEditError, NabidkaMultiEditResult} from "../../../../model/CrudUpdate";
import CachedIcon from "@mui/icons-material/Cached";
import {IDClass, InvalDuvodPreprava, InvalDuvodVozidlo} from "../../../../model/CommonTypes";
import DeleteIconOutline from "@material-ui/icons/DeleteOutline";
import {PrepravyList} from "../../../../model/Preprava";
import {VozidlaList} from "../../../../model/Vozidlo";
import {jsonToFormUrlEncoded} from "../../../../../common/utils/Util";
import {dispatchModal, ModalActionType} from "../../../../../common/component/ModalContainer";
import i18n from "../../../../i18n/i18n";
import {Box, Button} from "@material-ui/core";
import {StandaloneField} from "../../../../../common/component/form/StandaloneField";
import {FormInputType} from "../../../../raal_components/form/Form";
import {useState} from "react";
import moment from "moment/moment";
import {
    useDateLogic,
    VozidloPrepravaSharedFields
} from "./PrepravaAVozidlaShared";
import {SystemParamKey} from "../../../../model/SystemParameter";

export function useNabidkaMultiCRUD<T extends IDClass>({endpoint, typParametru, defaultRange , typAdvance, defaultAdvance}: {endpoint: string, typParametru?:SystemParamKey, defaultRange?:number, typAdvance?:SystemParamKey, defaultAdvance?:number}){
    const {fetch: nabidkaMultiAction} = useFetchCustom<NabidkaMultiEditResult, {action: string, ids: number[]}>(
        {endpoint: ({action, ids})=> `${endpoint}/${action}/?id=${ids.join(',')}`}
    );
    const [showConfirm] = useConfirmDialog();
    const {t} = useTranslation();
    const [, initialFrom, initialTo, shouldDisabledDate, , , setMaxDate, createHandlerPicker] = useDateLogic<any>(typParametru, defaultRange , typAdvance, defaultAdvance);

    const nabidkaPerformAction = async (action: string, ids: number[], data: VozidloPrepravaSharedFields = null) => {
        return nabidkaMultiAction({
            arg: {
                action: action,
                ids: ids
            }, init: {
                method: action === 'delete' ? 'DELETE' : 'PUT',
                ...(data !== null ? {
                    body: jsonToFormUrlEncoded({
                        datumOd: data.datOd.toISOStringWithMillis(),
                        datumDo: data.datDo.toISOStringWithMillis()
                    }),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                    },
                } : {}),
            },
        })
    }

    const getErrors = (result: NabidkaMultiEditResult, msgMapping: { [key in MultiEditError]?: string }[]) : string => {
        let errors = "";
        msgMapping.forEach((mapping) => {
            Object.entries(mapping).forEach(([errorKey, messageKey]) => {
                const errorCount = result.errors[errorKey as unknown as MultiEditError]?.length || 0;
                if (errorCount > 0) {
                    errors += t(messageKey).replace('{0}', errorCount.toString())  + '\n';
                }
            });
        });
        return errors.trim();
    }

    const hasOfferWithType = (selectedItems: T[], invalDuvPreprava: InvalDuvodPreprava, invalDuvVozidlo: InvalDuvodVozidlo) => {
        for (const item of selectedItems) {
            if((item instanceof PrepravyList && item.invalDuv === invalDuvPreprava)
                || (item instanceof VozidlaList && item.invalDuv === invalDuvVozidlo)){
                return true
            }
        }
        return false;
    }

    const hasExpiredOffer = (selectedItems: T[]): boolean => {
        return hasOfferWithType(selectedItems, InvalDuvodPreprava.DATUM_V_MINULOSTI, InvalDuvodVozidlo.DATUM_V_MINULOSTI)
    }

    const hasValidOffer = (selectedItems: T[]): boolean => {
        return hasOfferWithType(selectedItems, InvalDuvodPreprava.OK, InvalDuvodVozidlo.OK)
    }

    const getRefreshAction = (showMultiAction: (state: boolean) => void) => {
        return {
            icon: () => <CachedIcon/>,
            keyMappings: ['r', 'o'],
            tooltip: t("NabidkaMultiCRUD.RefreshTooltip"),
            onClick: (event: any, selectedItems: T[]) => {
                event.preventDefault();
                if(hasExpiredOffer(selectedItems)){
                    dispatchModal({
                        type: ModalActionType.Show, title: t("ConfirmDialog.Title"), body: (
                            <ModalDispatchModalRefresh
                                selectedItems={selectedItems}
                                onConfirm={() => {
                                    showMultiAction(false);
                                }}
                            />
                        )
                    });
                } else {
                    showConfirm({
                        body: t("NabidkaMultiCRUD.RefreshConfirm"),
                        onConfirm: async () => {
                            await sendRefreshAction(selectedItems);
                            showMultiAction(false);
                        }
                    })
                }
            }
        }
    }

    const ModalDispatchModalRefresh = (props: {selectedItems: T[], onConfirm?: () => void}) => {
        const [data, setData] =
            useState({
                datOd: moment().startOf('day'),
                datDo: setMaxDate({datOd: moment().startOf('day')}).endOf('day')
            } as VozidloPrepravaSharedFields);

        return (
            <Box display="flex" flexDirection="column" style={{maxWidth: "600px"}}>
                <Box p={1}>
                    <div style={{fontSize: '13pt', marginBottom: '2px'}}><strong>{t("NabidkaMultiCRUD.RefreshConfirm")}</strong></div>
                    <hr/>
                    <i style={{fontSize: '11pt', textTransform: "uppercase"}}>{t("NabidkaMultiCRUD.InvalidOffersDateChangeInfo")}</i>
                </Box>
                <Box p={1}>
                    <StandaloneField
                        title={t("NabidkaMultiCRUD.DateFrom")}
                        value={data.datOd}
                        type={FormInputType.DateTime}
                        onValueChanged={value => createHandlerPicker({...data, datOd: value}, setData)("datOd")}
                        dateTimeOptions={{
                            triggerChangeIfInvalid: true,
                            initialValue:initialFrom(data),
                            timeFormat: false,
                            datePattern: moment.localeData().longDateFormat('L').substr(0, 5),
                            timePattern: 'HH:mm',
                            clearIfDisabled: true,
                            skipValidation: false,
                            dateTimePickerProps:{
                                disablePast:true,
                                shouldDisableDate:day => {
                                    return shouldDisabledDate(data, moment(day),"datOd");
                                }
                            }
                        }}/>
                </Box>
                <Box p={1}>
                    <StandaloneField
                        title={t("NabidkaMultiCRUD.DateTo")}
                        value={data.datDo}
                        type={FormInputType.DateTime}
                        onValueChanged={value => createHandlerPicker({...data, datDo: value}, setData)("datDo")}
                        dateTimeOptions={{
                            triggerChangeIfInvalid: true,
                            initialValue:initialTo(data),
                            timeFormat: false,
                            addEndOfDay: true,
                            datePattern: moment.localeData().longDateFormat('L').substr(0, 5),
                            timePattern: 'HH:mm',
                            dateForCompare: data?.datOd,
                            skipValidation: false,
                            dateTimePickerProps:{
                                disablePast:true,
                                shouldDisableDate:() => {
                                    return false;
                                },
                                minDate: data?.datOd,
                                maxDate: setMaxDate(data)
                            }
                        }}/>
                </Box>
                <Box p={1}>
                    <i style={{fontSize: '11pt', textTransform: "uppercase"}}>{hasValidOffer(props.selectedItems) && t("NabidkaMultiCRUD.ValidOffersDateChangeInfo")}</i>
                </Box>
                <Box>
                    <Box display="flex" flexDirection="row" justifyContent={"flex-end"}>
                        <Box p={1}>
                            <Button variant={"contained"} color={"default"} onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                dispatchModal({type:ModalActionType.Hide});
                            }}>{i18n.t("Buttons.Cancel")}</Button>
                        </Box>
                        <Box p={1}>
                            <Button
                                autoFocus
                                variant={"contained"}
                                color={"primary"}
                                disabled={!data?.datOd.isValid() || !data?.datDo.isValid()}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    e.preventDefault();
                                    sendRefreshAction(props.selectedItems, data).then(() => {
                                    props.onConfirm?.();
                                });
                                dispatchModal({type:ModalActionType.Hide});
                            }}>{i18n.t("Buttons.Confirm")}</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    const sendRefreshAction = async (selectedItems: T[], data: VozidloPrepravaSharedFields = null) => {
        await nabidkaPerformAction('refresh', selectedItems.map(it => it.id), data).then(result => {
            let errors = getErrors(result, [
                {[MultiEditError.LOCKED]: "NabidkaMultiCRUD.LockedRecords"},
                {[MultiEditError.MAX_EDITS_REACHED]: "NabidkaMultiCRUD.MaxEditsReachedRecords"},
                {[MultiEditError.NOT_FOUND]: "NabidkaMultiCRUD.AlreadyDeletedRecords"},
                {[MultiEditError.EXPIRED_OFFER]: "NabidkaMultiCRUD.ExpiredOfferRecords"}
            ]);

            if(errors === ''){
                showSnack({title: t('NabidkaMultiCRUD.RefreshSuccess'), severity: "success"});
            } else {
                showSnack({title: t('NabidkaMultiCRUD.UnableToRefresh') +  '\n' + errors, severity: "warning", duration: 10e3 });
            }
        })
    }


    const getRemoveAction = (showMultiAction: (state: boolean) => void) => {
        return {
            icon: () => <DeleteIconOutline/>,
            tooltip: t("NabidkaMultiCRUD.DeleteTooltip"),
            keyMappings: ['Delete', 'd'],
            onClick: (event: any, selectedItems: T[]) => {
                showConfirm({
                    body: t("NabidkaMultiCRUD.DeleteConfirm"),
                    onConfirm: async () => {
                        nabidkaPerformAction('delete', selectedItems.map(it => it.id)).then(result => {
                            let errors = getErrors(result, [
                                {[MultiEditError.LOCKED]: "NabidkaMultiCRUD.LockedRecords"},
                                {[MultiEditError.NOT_FOUND]: "NabidkaMultiCRUD.AlreadyDeletedRecords"}
                            ]);

                            if(errors === ''){
                                showSnack({title: t('NabidkaMultiCRUD.DeleteSuccess'), severity: "success"});
                            } else {
                                showSnack({title: t('NabidkaMultiCRUD.UnableToDelete') +  '\n' + errors, severity: "warning", duration: 10e3 });
                            }
                            showMultiAction(false);
                        })
                    }
                })
            }
        }
    }

    return {getRemoveAction, getRefreshAction}
}