import React, {useEffect, useState} from "react";
import {useSubscribe} from "use-pubsub-js";

export const DataGridBackdrop = ({id, filtersOpened}:{id: string, filtersOpened: boolean}) => {
    const [blocked, setBlocked] = useState(false);

    const handler  = (token?:string | symbol, message?: string) => {
        if (token === 'filterStateChanged' + id) {
            setBlocked(message ? Boolean(message) : false);
        }
    }
    const { unsubscribe, resubscribe } = useSubscribe({ token: 'filterStateChanged' + id, handler });

    useEffect(() => {
        resubscribe();
        return () => {
            unsubscribe();
        }
        // eslint-disable-next-line
    }, [])

    return <>
        {filtersOpened || blocked ? <div style={{
            width: '100%',
            height: '100%',
            background: '#00000063',
            position: 'absolute',
            zIndex: 100,
            borderRadius: 4}}>&nbsp;</div> : null}
    </>
}
