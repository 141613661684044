import {useHistory, useLocation} from "react-router";
import {Props} from "../grid/DataGrid.d";
import {IDClass} from "../../model/CommonTypes";

export const useHistoryCustom = () => {
    const {push:pushUrl, replace: replaceUrl} = useHistory();
    const {state: locState} = useLocation<any>();

    const push = (url: string, state?: any) => {
        pushUrl(url, state ? {...state} : locState)
    }

    const replace = (url: string, state?: any) => {
        replaceUrl(url, state ? {...state} : locState)
    }

    return {push, replace}
}

function isStringEmpty(inputStr : string | null | undefined): boolean {
    if(!inputStr || inputStr === 'undefined' || inputStr === 'null')
        return true;
    if(!/\S/.test(inputStr))
        return true;
    return false;
}

export const getDetailId = <T extends IDClass>(props: Props<T>, data: T): string => {
    if(data == null) return "new";
    let id = props.getDetailIdUrlPart?.(data) ?? data.id;
    return isStringEmpty(id) ? "new" : id;
};

export const getDetailEndpoint = <T extends IDClass>(props: Props<T>, data: T): string => {
    let endpoint = props.endpointDetail ?? props.endpoint;
    if(!endpoint.endsWith('/')) endpoint = endpoint + '/';
    return endpoint + getDetailId(props, data);
};