import {useLocale} from "../../context/LocaleContext";
import {useTranslation} from "react-i18next";
import {lang as LangConfig} from "../../i18n/lang";
import Typography from "@material-ui/core/Typography";
import {Menu, MenuItem} from "@material-ui/core";
import React, { useEffect } from 'react';
import {HeaderButtonProps, HeaderDropDown} from "./HeaderAction";
import {useAppContext} from "../../context/AppContext";

export function HeaderLanguageButton({showText, langChangeCallBack}:HeaderButtonProps) {
    const {locale, setLocale} = useLocale();
    const {t} = useTranslation();
    const {checkDataChanged} = useAppContext();
    const lc = LangConfig[locale];
    let tooltip = t("Default.Jazyky");

	useEffect(() => {
		if (langChangeCallBack) {
			langChangeCallBack(locale);
		}
	}, [locale]);

    return (
        <HeaderDropDown tooltip={tooltip} icon={(
            <>
                <Typography variant={"body2"}><img src={lc.icon} alt={lc.name} /></Typography>
            </>
        )} text={lc.name} render={(handleClose) => (
            Object.keys(LangConfig).map(key=>(
                <MenuItem key={key} onClick={() => checkDataChanged(()=>{
                    setLocale(key);
                    handleClose();
                })}>
                    <img src={LangConfig[key].icon} alt={LangConfig[key].name} style={{ paddingRight: 10 }} />{LangConfig[key].name}
                </MenuItem>
            ))
        )} popupComponent={Menu} showText={showText}/>
    );
}
