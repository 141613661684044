import * as React from "react";
import {forwardRef, useCallback, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {httpEndpoint, httpEndpointArray} from "../../../common/utils/HttpUtils";
import {Provozovna} from "../../model/Provozovna";
import {TextTuple, TextTupleType} from "../../../common/component/form/TextTuple";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@material-ui/core";
import {invoke} from "../../../common/utils/Invoke";
import {Dispecer} from "../../model/Dispecer";
import {useDidMount} from "../../../common/component/hooks/SharedHooks";
import {Show} from '../../../common/component/form/Show';
import {
    AccountBalance,
    Business,
    Comment,
    Commute,
    Email,
    Error,
    Fingerprint,
    HeadsetMic,
    Language,
    Person,
    Phone,
    Public,
    Today,
    Web
} from '@material-ui/icons';
import {FlagIcon} from '../../../common/component/FlagIcon';
import {PhoneNumberDial} from '../../../common/component/PhoneNumberDial';
import {EmailClient} from '../../../common/component/EmailClient';
import { useStyleContext } from "../../context/ThemeModeContext";

export type ProvozovnaViewExposed = {
    setProvozovna:(provozovna:string)=>void
}

export const ProvozovnaDetailView = forwardRef<ProvozovnaViewExposed, {provozovnaId?:string, adminView?: boolean, master?: boolean}>((props, ref) => {
    const [provozovna, setProvozovna] = useState(props.provozovnaId);
    const {classes} = useStyleContext();
    useImperativeHandle(ref, ()=>{
        return {
            setProvozovna: setProvozovna
        }
    }, []);
    return provozovna ?
        <Card style={{height: "100%"}} className={classes.sectionBackground}>
            <CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
                <ProvozovnaDetail key={provozovna} provozovnaId={provozovna} adminView={props.adminView} master={props.master}/>
            </CardContent>
        </Card> : <></>;
});

export const ProvozovnaDetail = (props: {provozovnaId:string, adminView: boolean, master?: boolean}) => {
    const {t} = useTranslation();
    const [state, setState] = useState<{provozovna:Provozovna, dispeceri:Dispecer[]}>({provozovna:null, dispeceri:null} );
    const {provozovna:data, dispeceri} = state;
    const endpoint = props.adminView ? 'admin/provozovna' : props.master ? 'master/provozovna' : 'user/provozovna';

    const reloadData = useCallback(async () => {
        const pResult = await httpEndpoint<Provozovna>(Provozovna, `${endpoint}/${props.provozovnaId}`);
        const dResult = await httpEndpointArray<Dispecer>(Dispecer, `${props.adminView ? 'admin' : props.master ? 'master' : 'user'}/dispecer?provozovna=${props.provozovnaId}`);
        setState({provozovna:pResult.data, dispeceri:dResult.data});
    }, [endpoint, props.provozovnaId, props.adminView]);

    useDidMount(() => {
        invoke(reloadData);
    });

    return data&&(
        <>
            <Box marginBottom={2}>
                <Typography variant="h6" >{t("Provozovna.Title")}</Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-company"}><Fingerprint/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Provozovna.KodProvozovny")} content={data.kod}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Grid item lg={3} sm={6} xs={12}>
                        <ListItem>
                            <ListItemAvatar>
                                {data.pStat?
                                    <Avatar className={"avatar-flag"}><FlagIcon mpz={data.pStat}/></Avatar>:
                                    <Avatar className={"avatar-company"}><Business/></Avatar>}
                            </ListItemAvatar>
                            <ListItemText>
                                <TextTuple title={t("Provozovna.UserContactLabel")} content={
                                    <>
                                        <Show>{data.pNazev}<br /></Show>
                                        <Show>{data.pUlice}</Show> <Show>{data.pCisloPopisne}</Show>
                                        <Show><br /></Show>
                                        <Show>{data.pPsc}</Show> <Show>{data.pObec}</Show><Show>{`${data.pCastObce ? ` - ${data.pCastObce}` : ""}`}</Show> <Show>{`${data.pStat ? `${data.pStat}` : ""}`}</Show>
                                    </>
                                } />
                            </ListItemText>
                        </ListItem>
                    </Grid>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar>
                                    {data.kStat?
                                        <Avatar className={"avatar-flag"}><FlagIcon mpz={data.kStat}/></Avatar>:
                                        <Avatar className={"avatar-company"}><Business/></Avatar>}
                                </ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Provozovna.UserCorespondLabel")} content={
                                        <>
                                            <Show>{data.kNazev ? data.kNazev : "-"}</Show> <Show>{`${data.kAdresat ? `- ${data.kAdresat}` : ""}`}</Show>
                                            <Show><br /></Show>
                                            <Show>{data.kUlice}</Show> <Show>{data.kCisloPopisne}</Show>
                                            <Show><br /></Show>
                                            <Show>{data.kPsc}</Show> <Show>{data.kObec}</Show><Show>{`${data.kCastObce ? ` - ${data.kCastObce}` : ""}`}</Show> <Show>{`${data.kStat ? `${data.kStat}` : ""}`}</Show>
                                        </>
                                    } />
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-admin"}><Today/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Default.region")} content={t(`Enumerations.Region.${data.typ}`)}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-admin"}><Error/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Provozovna.Stav")} content={`${t(`Enumerations.Stav.${data.stav}`)} (${t(`Enumerations.Podstav.${data.podstav}`)})`}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-admin"}><Today/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Provozovna.datzmenstav")} content={data.datzmenstav} type={TextTupleType.DateTime}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-company"}><Email/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Provozovna.DatovaSchranka")} content={data.datovaSchranka}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-company"}><Web/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Firma.http")} content={data.firma.http}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-company"}><Public/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Provozovna.SpStat")} content={data.spStat}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-company"}><Language/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Provozovna.jazyky")} content={data.jazyky}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-company"}><Commute/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Provozovna.Auta")} content={data.auta}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-company"}><Comment/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Default.Note")} content={data.not1}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-company"}><AccountBalance/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Provozovna.PojisteniNakladu")} content={data.firma.pojisteni}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-company"}><Person/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Provozovna.uOsobaUser")} content={data.uOsobaUser}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-company"}><Phone/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Provozovna.uTelefonUser")} content={data.uTelefonUser}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar><Avatar className={"avatar-company"}><Email/></Avatar></ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={t("Provozovna.uEmailUser")} content={data.uEmailUser}/>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Show>
                    <Show>
                        <Grid item lg={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant="h6" >{t("Provozovna.emaily")}</Typography>
                            </Box>
                        </Grid>
                        {data.emaily?.join(", ") ?
                            data?.emaily?.filter(e=>e).map((e, i) =>
                                <Grid item lg={3} sm={6} xs={12} key={i * 10 + 2}>
                                    <ListItem key={i}>
                                        <EmailClient emailAddress={e}/>
                                    </ListItem>
                                </Grid>
                            ) :
                            <Grid item lg={6} sm={12} xs={12}>
                                <ListItem>
                                    <EmailClient showDials={false}/>
                                </ListItem>
                            </Grid>
                        }
                    </Show>
                    <Show>
                        <Grid item lg={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant="h6" >{t("Provozovna.telCislaKontaktu")}</Typography>
                            </Box>
                        </Grid>
                        {data.telCislaKontaktu?.join(", ") ?
                            data?.telCislaKontaktu?.filter(n=>n).map((n, i)=>
                                <Grid item lg={3} sm={6} xs={12} key={i * 10 + 2}>
                                    <ListItem key={i}>
                                        <PhoneNumberDial phoneNumber={n}/>
                                    </ListItem>
                                </Grid>
                            ) :
                            <Grid item lg={6} sm={12} xs={12}>
                                <ListItem>
                                    <PhoneNumberDial showDials={false}/>
                                </ListItem>
                            </Grid>
                        }
                    </Show>
                    <Show>
                        <Grid item lg={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant="h6" >{t("Dials.Dispeceri")}</Typography>
                            </Box>
                        </Grid>
                        {dispeceri?.map(it =>it.formatDisplayName()).join(", ") ?
                            dispeceri?.filter(n=>n.formatDisplayName()).map((n, i)=>
                                <Grid item lg={6} sm={12} xs={12} key={i * 10 + 3}>
                                    <ListItem key={i}>
                                        <ListItemAvatar><Avatar className={"avatar-contact"}><HeadsetMic/></Avatar></ListItemAvatar>
                                        <ListItemText primary={n.formatDisplayName()} style={props.adminView && n.enabled===false ? {textDecoration: 'line-through'} : undefined}/>
                                    </ListItem>
                                </Grid>
                            ) :
                            <Grid item lg={6} sm={12} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-contact"}><HeadsetMic/></Avatar></ListItemAvatar>
                                    <ListItemText primary={"-"}/>
                                </ListItem>
                            </Grid>
                        }
                    </Show>
                </Grid>
            </Grid>
        </>
    );
}