import {globalStyles, useThemeContext} from "../../../context/ThemeModeContext";
import {useFetchCustom} from "../../../../common/utils/HttpUtils";
import {LockChangeResult} from "../../../model/LockResult";
import {useTranslation} from "react-i18next";
import {useConfirmDialog} from "../../../raal_components/ConfirmDialog";
import {FindReplace} from "@material-ui/icons";
import * as React from "react";

export enum OznaceniTyp {
    OZNACENO = "OZNACENO",
    ZOBRAZENO = "ZOBRAZENO",
}

export enum ZaznamOznaceniTyp {
    PROVOZOVNA = "PROVOZOVNA",
    ARCHIV_ZADANI_PREPRAVA = "ARCHIV_ZADANI_PREPRAVA",
    ARCHIV_ZADANI_VOZY = "ARCHIV_ZADANI_VOZY",
    ARCHIV_ZADANI_INZERCE = "ARCHIV_ZADANI_INZERCE",
    ARCHIV_PROHLIZENI_PREPRAVA = "ARCHIV_PROHLIZENI_PREPRAVA",
    ARCHIV_PROHLIZENI_VOZY = "ARCHIV_PROHLIZENI_VOZY",
    ARCHIV_PROHLIZENI_INZERCE = "ARCHIV_PROHLIZENI_INZERCE",
    BLESKOVKY = "BLESKOVKY",
}

export function useZaznamOznaceniStyle(typ: string){
    const {mode} = useThemeContext();
    const getStyle = (data: any) => {
        if(data?.uzivatelOznaceni?.includes(OznaceniTyp.ZOBRAZENO)){
            return mode === "light" ? globalStyles.rowStyleViewedLight : globalStyles.rowStyleViewedDark;
        } else {
            return null;
        }
    }

    return { getStyle };
}

export function useZaznamOznaceni(baseEndpoint: string, zaznamOznaceniTyp?: ZaznamOznaceniTyp) : ZaznamOznaceniType {
    const {fetch: oznaceniHttpCall} = useFetchCustom<LockChangeResult, {id?: string, oznaceniTyp: OznaceniTyp}>(
        {endpoint: ({id, oznaceniTyp})=>
                `${baseEndpoint}/oznaceni${zaznamOznaceniTyp ? `/${zaznamOznaceniTyp}` : ``}${id ? `/${id}` : ``}/${oznaceniTyp}`
        }
    );

    const markZaznam = async (oznaceniTyp: OznaceniTyp, zaznamId?: string, remove: boolean = false) => {
        await oznaceniHttpCall({
            arg: {id: zaznamId, oznaceniTyp: oznaceniTyp},
            init: {method: remove ? 'DELETE' : 'PUT'},
        })
    }
    const mark = async (zaznamId: string, oznaceniTyp: OznaceniTyp) => {
        await markZaznam(oznaceniTyp, zaznamId, false);
    }

    const unmark = async (zaznamId: string, oznaceniTyp: OznaceniTyp) => {
        await markZaznam(oznaceniTyp, zaznamId, true);
    }

    const unmarkAll = async (oznaceniTyp: OznaceniTyp) => {
        await markZaznam(oznaceniTyp, null,true);
    }

    return {mark, unmark, unmarkAll}
}

export type ZaznamOznaceniType = {
    mark: (zaznamId: string, oznaceniTyp: OznaceniTyp) => void
    unmark: (zaznamId: string, oznaceniTyp: OznaceniTyp) => void
    unmarkAll: (oznaceniTyp: OznaceniTyp) => void
}

export const usePrepravaOznaceni = (() => useZaznamOznaceni("user/preprava"));
export const useVozidloOznaceni = (() => useZaznamOznaceni("user/vozidlo"));
export const useInzeratOznaceni = (() => useZaznamOznaceni("user/inzerat"));


export function useClearZaznamOznaceni(zaznamOznaceni: ZaznamOznaceniType, onClear?: () => void){
    const {t} = useTranslation();
    const [showConfirm] = useConfirmDialog();

    const clearDeleteViewRecords = () => {
        showConfirm({
            body: t("Oznaceni.ViewRecordsClearConfirm"),
            onConfirm: async () => {
                await zaznamOznaceni.unmarkAll(OznaceniTyp.ZOBRAZENO);
                if(typeof onClear === 'function') onClear()
            }
        })
    };

    return {
        icon:()=><FindReplace />,
        tooltip:t("Oznaceni.DeleteViewRecords"),
        onClick: () => clearDeleteViewRecords()
    }
}

export function useClearPrepravaViewRecords(onClear?: () => void){
    return useClearZaznamOznaceni(usePrepravaOznaceni(), onClear);
}

export function useClearVozidloViewRecords(onClear?: () => void){
    return useClearZaznamOznaceni(useVozidloOznaceni(), onClear);
}

export function useClearInzeratViewRecords(onClear?: () => void){
    return useClearZaznamOznaceni(useInzeratOznaceni(), onClear);
}