import * as React from "react";
import {cleanName, FormFieldInterface, FormFieldInterfaceProps} from "./FormFieldInterface";
import {exist} from "../../utils/Util";
import {TextField, TextFieldProps, Tooltip} from "@material-ui/core";
import {FormInputProps} from "./FormInput";

export interface FormInputFileProps extends FormFieldInterfaceProps<any> {
    textFieldProps?: TextFieldProps,
    useDynamicFontSize?:boolean
}


export class FormInputFile extends React.Component<FormInputFileProps, {value: File, backupTooltip?:string, isEmpty?:boolean, useDynamicFontSize?:boolean}> implements FormFieldInterface {
    state = {
        value: this.props.value,
        backupTooltip: this.props.dataTip,
        isEmpty: false
    };

    userClick = false;
    static defaultProps = {
        showAdornment:true,
    };

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<FormInputProps>, nextContext: any): void {
        this.setState({value:nextProps.value});
    }

    onValueChange(value: File) {
        this.setState({
            value: value,
            isEmpty: !exist(value)
        },  ()=> {
                this.props.onValueChanged(this.state.value)
            }
        )
    }

    render() {
        const tProps:TextFieldProps = {...{variant:"outlined"}, ...this.props.textFieldProps};
        const _type = "file";
        return (
            <>
                <Tooltip title={this.props.dataTip||""} disableHoverListener={!this.props.dataTip} disableFocusListener={!this.props.dataTip} disableTouchListener={!this.props.dataTip}>
                <TextField
                    {...tProps}
                    size={"medium"}
                    id={cleanName(this.props.name)}
                    name={cleanName(this.props.name)}
                    type={_type}
                    autoComplete={"off"}
                    hiddenLabel={!Boolean(this.props.title)}
                    label={this.props.title}
                    disabled={this.props.disabled}
                    onBlur={this.props.onBlur}
                    onChange={e => {
                            // @ts-ignore
                            const f: File = e.target.files[0];
                            this.onValueChange(new File([f], f.name));
                    }}
                    onFocus={() => {
                        this.props.onFocus&&this.props.onFocus(this.userClick);
                    }}
                    InputLabelProps={{shrink: true}}
                    onMouseDown={()=>this.userClick = true}
                    onMouseUp={()=>this.userClick = false}
                    onKeyDown={this.props.onKeyDown}
                    onKeyUp={this.props.onKeyUp}
                    fullWidth
                    error={typeof this.props.error !== 'undefined'}
                    helperText={this.props.error}
                />
                </Tooltip>
            </>
        );
    }

}