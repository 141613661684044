import {ProfilVozidlaSelect} from "./ProfilVozidla";
import {Waypoint} from "./Waypoint";
import {JsonIgnore, JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {Currency} from "./Currency";
import {RoadType} from "./Preprava";
import {ExtraRoute, ExtraWaypoint} from "../../common/component/map/FunctionalMap";
import {SbernaSluzbaPreprava} from "./PrepravaVozidlo";
import {exist} from "../../common/utils/Util";
import {Geometry} from "./Geometry";
import {MomentConverters} from "./Converters";
import {Moment} from "moment";

export enum TrasaState {WAITING, CALCULATING, CALCULATED, ERROR}
export enum RouteCalculationStatus {OK, SERVER_ERROR, NO_ROUTE, COST_CALCULATION_ERROR, MISSING_CONFIG_DATA}

export class TrasaCalculationResultItem {
    key: string;
    trasaCalculationResult: TrasaCalculationResultBase;
}

export class CalculationResultToll {
    mpz: string;
    @JsonProperty({type:{enum:RoadType}})
    roadType: RoadType;
    distancePaid: number;
    distanceUnpaid: number;
}

export class TrasaCalculationResultBase {
    @JsonIgnore({ignoreSet: true})
    osrmData: string;
    toll: number;
    routeDistance: number;
    routeDuration: number;
    @JsonProperty({type:{clazz:CalculationResultToll, isArray: true}})
    tollTable: CalculationResultToll[];
}

export class TransportTrasaCalculationResult extends TrasaCalculationResultBase {
}

export class TrasaCalculationResult extends TrasaCalculationResultBase {
    @JsonProperty({type: {enum: RouteCalculationStatus}})
    status: RouteCalculationStatus;
    progress: number;
}

export class TrasaBase {
    @JsonProperty({type:{clazz:ProfilVozidlaSelect}})
    profilVozidla: ProfilVozidlaSelect;
    @JsonProperty({type:{clazz:Currency}})
    currency: Currency;
    @JsonProperty({type: {enum: TrasaState}})
    state: TrasaState;
    @JsonProperty({type:{clazz:Waypoint, isArray:true}})
    waypointy: Waypoint[];
    @JsonProperty({converters: MomentConverters})
    modifiedOn: Moment;

    getCalculationResult(): TrasaCalculationResultBase[] {
        return undefined;
    }

    getCalculationResults(): TrasaCalculationResultItem[] {
        return this.getCalculationResult()?.map((value, index) => ({
          key: index.toString(),
          trasaCalculationResult: value
        }));
    }

    isProcessing() {
        return [TrasaState.WAITING,TrasaState.CALCULATING].indexOf(this.state)>-1;
    }

    getCalculationResultAtIndex(i: number): TrasaCalculationResultBase {
        return exist(this.getCalculationResult()) && this.getCalculationResult().length>i ? this.getCalculationResult()[i] : undefined;
    }

    getCalculationResultsAtIndex(i: number): TrasaCalculationResultItem {
        const res = this.getCalculationResults();
        return exist(res) && res.length > i ? res[i] : undefined;
    }
}

export class TransportTrasa extends TrasaBase {
    @JsonProperty({type: {enum: RouteCalculationStatus}})
    status: RouteCalculationStatus;
    progress: number;


    @JsonProperty({type:{clazz:TransportTrasaCalculationResult, isArray: true}})
    calculationResult: TransportTrasaCalculationResult[]

    getCalculationResult(): TrasaCalculationResultBase[] {
        return this.calculationResult;
    }

    static calculating(profilVozidla?: ProfilVozidlaSelect, currency?: Currency): TransportTrasa {
        const r = new TransportTrasa();
        r.state = TrasaState.CALCULATING;
        r.profilVozidla = profilVozidla;
        r.currency = currency;
        return r;
    }
}

export class Trasa extends TrasaBase {
    id: number;
    nazev: string;
    recalculate?: boolean;
}

export class TrasaCalculationResultStatus {
    @JsonProperty({type: {enum: RouteCalculationStatus}})
    status: RouteCalculationStatus;
}

export class TrasaWithStatus extends Trasa {
    @JsonProperty({type:{clazz:TrasaCalculationResultStatus}})
    calculationResult: TrasaCalculationResultStatus
}

export class TrasaDetail extends Trasa {
    extraRoutes?: ExtraRoute[];
    extraWaypoints?: ExtraWaypoint[];

    @JsonProperty({type:{clazz:TrasaCalculationResult}})
    calculationResult: TrasaCalculationResult

    getCalculationResult(): TrasaCalculationResultBase[] {
        return this.calculationResult ? [this.calculationResult] : undefined;
    }
}

export class TrasaFilter {
     nazev: string;
}

export class TransportPrejezdTrasa extends TransportTrasa {
    extraRoutes?: ExtraRoute[];

    extraWaypoints?: ExtraWaypoint[];

    @JsonProperty({type:{clazz:Geometry}})
    prejezdOd: Geometry;

    @JsonProperty({type:{clazz:Geometry}})
    prejezdKam: Geometry;

    getCalculationResults(): TrasaCalculationResultItem[] {
        return [
            {
                key: "Prejezdy.prejezd",
                trasaCalculationResult: this.getCalculationResultAtIndex(0)
            },
            {
                key: "Prejezdy.found",
                trasaCalculationResult: this.getCalculationResultAtIndex(1)
            },
            {
                key: "Prejezdy.original",
                trasaCalculationResult: this.getCalculationResultAtIndex(2)
            }
        ]
    }
}

export class TransportDokladkaTrasa extends TransportTrasa {
    nakladka: Waypoint;
    vykladka: Waypoint;

    getCalculationResult(): TrasaCalculationResultBase[] {
        return this.calculationResult;
    }

    getCalculationResults(): TrasaCalculationResultItem[] {
        return [
            {
                key: "Dokladky.dokladka",
                trasaCalculationResult: this.getCalculationResultAtIndex(0)
            },
            {
                key: "Prejezdy.found",
                trasaCalculationResult: this.getCalculationResultAtIndex(1)
            },
            {
                key: "Dokladky.original",
                trasaCalculationResult: this.getCalculationResultAtIndex(2)
            }
        ]
    }
}

export class SbernaSluzbaTrasa extends TransportTrasa {
    id: number;
    nakladka: Waypoint;
    vykladka: Waypoint;
    @JsonProperty({type:{clazz: SbernaSluzbaPreprava, isArray: true}})
    prepravy: SbernaSluzbaPreprava[]
}
