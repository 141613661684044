import React from "react";
import {Dial} from "../../../../raal_components/dial/Dial";
import {RouteCalculationStatus, TrasaDetail, TrasaFilter, TrasaState, TrasaWithStatus} from "../../../../model/Trasa";
import {FormInputType} from "../../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {TrasaForm, TrasaFormViewType} from "./TrasaForm";
import {exist} from "../../../../../common/utils/Util";
import {RouteComponentProps} from "../../../../routes";

export function TrasaDial(props: RouteComponentProps) {
    const {t} = useTranslation();

    return <Dial<TrasaWithStatus, TrasaFilter, TrasaDetail>
        excludeFieldsForDirtyCheck={["state", "calculationResult"]}
        mode={props.mode}
        config={
            {
                id: "trasy",
                clazz: TrasaWithStatus,
                endpoint: "user/trasa",
                tableTitle: t("Trasa.title"),
                columns: [
                    {
                        title: t("Trasa.nazev"),
                        field: 'nazev',
                        defaultSort:"asc",
                        filterProps:() => ({type:FormInputType.Text})
                    },
                    {
                        title: t("Trasa.state"),
                        field: 'state',
                        render: (row) => {
                            const text = `${t(`Enumerations.TrasaState.${row.state}`)}${row.state === TrasaState.ERROR && exist(row.calculationResult?.status) && row.calculationResult.status !== RouteCalculationStatus.OK ? " (" + t(`Enumerations.RouteCalculationStatus.${row.calculationResult.status}`) + ")" : ""}`
                            return row.recalculate ? <i>{text}</i> : text;
                        }
                    },
                    {
                        title: t("Trasa.modifiedOn"),
                        field: 'modifiedOn',
                        render: (data) => data.modifiedOn?.format("L LT"),
                    }
                ],
                stomp: {
                    topic: "/trasa",
                    userOnly: true
                }
            }
        }
        clazzDetail={TrasaDetail}
        hideNewButtonOnEdit={true}
        preventCloseAfterSave
        crudConfig={{editEnabled: true, removeEnabled:true, addEnabled:false}}
        layoutForm={(edited, data) => {
            return <TrasaForm stompTopic={`/trasa/${data.id}`} viewType={TrasaFormViewType.ROUTE}/>
        }}
        formDisabled={(edited, data) => data.state===TrasaState.WAITING || data.state===TrasaState.CALCULATING}
        buttonSaveDisabledFunc={(data) => {
            return (data.state === TrasaState.ERROR && !exist(data.calculationResult)) || (data.state === TrasaState.ERROR && data.calculationResult?.status === RouteCalculationStatus.SERVER_ERROR) || ((data.state===TrasaState.ERROR || data.state===TrasaState.CALCULATED) && data.recalculate)
        }}
        localization={{
            UpdateRecord: t("Trasa.CalculateAndSave")
        }}
    />
}
