import SockJS from 'sockjs-client';
import Stomp, { Client, Frame, Subscription } from 'stompjs';
import * as React from 'react';
import { PropsWithChildren, useCallback, useContext, useEffect, useRef } from 'react';
import { getConfig } from '../../Config';
// @ts-ignore
import AbstractXHRObject from 'sockjs-client/lib/transport/browser/abstract-xhr';
import { Mapper } from './objectmapper/Mapper';
import { Token } from '../../web/model/Token';
import { useDidMount } from '../component/hooks/SharedHooks';
import { useSubscribe } from 'use-pubsub-js';
import { useCmsOznameni } from '../../web/raal_components/CmsOznameniPopup';

export type StompHandler<Type> = (json: Type) => void;

AbstractXHRObject.supportsCORS = false;

export interface StompListener {
    onWebsocketConnecting?: (service:StompService)=>void;

    onWebsocketConnected?: (service:StompService)=>void;

    onWebsocketReconnectFailed?: (service:StompService)=>void;

    onWebsocketDisconnected?:(service:StompService) => void
}

class StompServiceProps {
    token?:string;
    reconnectAttempts?:number = 20;
    onReconnect?: () => void
}

export class Message {
    message?: string
}

type SubscriptionDefinition<Type=any> = {
    clazz:{new(): Type} | {},
    subscribe: string,
    handler: StompHandler<Type>,
    userOnly: boolean,
    isType: boolean
}

type LivingSubscription ={
    subscription:Subscription;
    definintion:SubscriptionDefinition;
}

export class StompService {
    attempts: number = 0;
    counter: number = 0;
    connectionListeners: Array<StompListener> = [];
    connected = false;
    socket: any;
    stompClient: Client;
    props?:StompServiceProps;
    subscriptionDefinitions:SubscriptionDefinition[] = [];
    livingSubscriptions:LivingSubscription[] = [];
    timeout: number = 5000;
    webSockets: Array<WebSocket> = [];
    lastConnectionAttempt: Date = new Date();
    connectTimeout: any;

    constructor(props?:StompServiceProps) {
        this.props = Object.assign(new StompServiceProps(), props||{});
    }
    connect = () => {
        this.attempts = 0;
        this.counter = 0;
        this.connectToStomp();
    };

    onClientConnected = () => {
        console.debug("Stomp connected.")
        if(this.attempts > 0) this.props.onReconnect()
        this.attempts = 0;
        this.counter = 0;
        this.connected = true;
        this.resubscribe();
        this.connectionListeners.forEach(listener=>listener.onWebsocketConnected&&listener.onWebsocketConnected(this));
    }

    onClientError = (err: Frame | string) => {
        this.unsubscribeLivingSubscriptions();

        if(this.connected){
            console.debug("Connection to stomp lost! Error:");
            console.debug(err);

            //Pošli event disconnected (ikonka ws)
            this.connectionListeners.forEach(listener=>listener.onWebsocketDisconnected&&listener.onWebsocketDisconnected(this));
        } else {
            console.debug(err);
            console.debug("Failed connecting to the stomp!");
        }

        this.connected = false;
        this.attempts++;
        this.counter++;


        if (this.attempts > this.props.reconnectAttempts) {
            this.connectionListeners.forEach(listener=>listener.onWebsocketReconnectFailed&&listener.onWebsocketReconnectFailed(this));
        } else {
            this.reconnectStompWithTimeout();
        }
    }

    reconnectStompWithTimeout = () => {
        const connectedDiff = new Date().getTime() - this.lastConnectionAttempt.getTime();
        if (this.counter === 1 && connectedDiff < 30e3) {
            //Odpojeno 30s po připojení na stomp, zřejmě nějaký problém
            console.debug("Stomp disconnected few seconds!");
            this.counter = 10 //Začne s timeoutem 10s
        }

        //1e3 = 1s, 30e3 = 30s
        this.timeout = Math.min(this.attempts * 1e3, 30e3);
        if (this.counter > 100) this.counter = 1; //Abychom tam neměli zbytečně velké čísla

        console.debug("Connection will be retried in " + this.timeout + " ms");
        this.connectTimeout = setTimeout(this.connectToStomp, this.timeout);
    }

    private connectToStomp = () => {
        if(this.stompClient && this.stompClient.connected){
            console.debug("The stomp is already connected!")
            return;
        }

        if(document.visibilityState === 'visible' || this.attempts === 0){
            this.socket = new SockJS(`${getConfig().backendUrl}/stomp/notify-sockjs?__token=${this.props.token||""}`);
            this.webSockets.push(this.socket);
            this.stompClient = Stomp.over(this.socket);
            this.stompClient.debug = null;
            this.connectionListeners.forEach(listener=>listener.onWebsocketConnecting&&listener.onWebsocketConnecting(this));
            this.stompClient.heartbeat.incoming=300000;
            this.stompClient.heartbeat.outgoing=5000;
            this.lastConnectionAttempt = new Date();
            console.debug("Connecting to the stomp...")
            this.stompClient.connect({}, this.onClientConnected, this.onClientError);
        } else {
            console.debug("The window is not active, skipping connection.")
            this.reconnectStompWithTimeout();
        }
    };

    removeConnectionListener(listener:StompListener) {
        this.connectionListeners = this.connectionListeners.filter(i=>i!==listener);
    }

    addConnectionListener(listener: StompListener) {
        this.connectionListeners.push(listener);
    }

    subscribe<Type>(clazz:{new(): Type} | {}, subscribe: string, handler: StompHandler<Type>, userOnly: boolean = false, isType: boolean = false): SubscriptionDefinition<Type> {
        const subcription = {clazz, subscribe, handler, userOnly, isType};
        this.subscriptionDefinitions.push(subcription);
        if(this.connected) {
            this.subscribeInternal(subcription);
        }
        return subcription;
    }

    private unsubscribeLivingSubscriptions() {
        this.livingSubscriptions.forEach(f=>f.subscription.unsubscribe());
        this.livingSubscriptions = [];
    }

    private resubscribe() {
        this.subscriptionDefinitions.forEach(f=>this.subscribeInternal(f));
    }

    unsubscribe<Type>(s:SubscriptionDefinition<Type>) {
        this.subscriptionDefinitions = this.subscriptionDefinitions.filter(f=>f!==s);
        this.livingSubscriptions = this.livingSubscriptions.filter(f=>{
            if(f.definintion===s){
                f.subscription.unsubscribe();
                return false;
            }
            return true;
        })
    }

    private subscribeInternal<Type>(def:SubscriptionDefinition<Type>) {
        let path = "/topic" + def.subscribe;
        if (def.userOnly) {
            path = "/user" + path;
        }
        const subscription = this.stompClient.subscribe(path, function (message: any) {
            let json = message.body;
            if(def.clazz) {
                const mapper = new Mapper<Type>({constructor:def.clazz as {new(): Type}});
                json = mapper.readValue(json);
            } else if(def.isType) {
                json = JSON.parse(json)
            }
            def.handler(json);
        });
        this.livingSubscriptions.push({definintion:def, subscription})
    }

    disconnect() {
        clearTimeout(this.connectTimeout);
        if(this.stompClient.connected){
            this.stompClient.disconnect(() => {
                console.debug("Stomp disconnected manually");
            });
        }
    }

    disconnectAfterLogout() {
        this.unsubscribeLivingSubscriptions();
        this.disconnect();
    }

}

const Context = React.createContext({} as {service:StompService});
export const useWebsocketContext = () => useContext(Context);

type StompSubscribeOptions<Type> = {callback?:StompHandler<Type>, clazz?:{new(): Type}, userOnly?:boolean, isType?:boolean, wait?:number}

export function useStompSubscribe<Type>(topic?:string, options?:StompSubscribeOptions<Type>) {
    const {clazz = null, userOnly = false, isType = true, callback} = options ?? {};

    const subRef = useRef(null as SubscriptionDefinition<Type>);
    const callbackRef = useRef<StompHandler<Type>>();
    const paused = useRef(false);

    const callbackLocal = useCallback((data:Type) => {
        if(!paused.current) {
            if(options.wait && options.wait > 0) {
                paused.current = true;
                setTimeout(() => {
                    paused.current = false;
                }, options.wait);
            }
            if(callbackRef.current) {
                callbackRef.current(data);
            } else if (callback)  {
                callback(data)
            }
        }
    }, [options, callback]);
    const {service} = useWebsocketContext();
    useEffect(() => {
        if(topic) {
            subRef.current = service.subscribe<Type>(clazz, topic, callbackLocal, userOnly, isType);
            return ()=>{
                service.unsubscribe(subRef.current);
            }
        }
    }, [clazz, topic, callbackLocal, userOnly, isType, service]);

    return [(stompCallback?:StompHandler<Type>)=>{
        callbackRef.current = stompCallback;
    }];
}


export function WebsocketContext({children, token}:PropsWithChildren<{token:Token}>) {
    const cmsOznameni = useCmsOznameni()
    const service = useRef(new StompService(
        {token:token?.accessToken, onReconnect: cmsOznameni.processReconnect}
    ));
    const handler = () => {
        service.current.disconnectAfterLogout();
    }
    const { unsubscribe, resubscribe } = useSubscribe({ token: 'userLoggedOut', handler})

    useDidMount(()=>{
        service.current.connect();
    });

    useEffect(() => {
        resubscribe();
        return () => {
            unsubscribe();
            service.current.disconnectAfterLogout();
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Context.Provider value={{service: service.current}}>
            {children}
        </Context.Provider>
    );
}

