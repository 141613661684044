import {Box, List, TextField, Tooltip} from "@material-ui/core";
import {CustomFieldComponentProps} from "./FormFieldInterface";
import React, {useEffect, useState} from "react";
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import {dispatchModal, ModalActionType} from "../ModalContainer";
import { ListItemButton } from "@mui/material";
import ListItemText from "@material-ui/core/ListItemText";
import { setLoading } from "../LoadingContainer";
import {useMountedEffect} from "../hooks/SharedHooks";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {SvgIconComponent} from "@material-ui/icons";

export type FormSelectPopupItem = {label: string, value: any, data: any}

export type FormSelectPopupOptions = {
    getSelectData: () => Promise<FormSelectPopupItem[]>
    modalTitle: string
    noDataText?: string
    itemActions?: {
        icon: React.ReactElement | SvgIconComponent,
        tooltip?: string,
        onClick: (item: FormSelectPopupItem) => void
    }[]
}

export type FormSelectPopupComponentOptions = FormSelectPopupOptions & {
    onEmptyAdornment?: React.ReactNode
    onFilledAdornment?: React.ReactNode
    hideClearButton?: boolean
    textStyle?: React.CSSProperties
}

const useStyles = makeStyles(theme => ({
    modal: {
        width: "calc(100vw - 150px)",
        maxWidth: "350px",
        boxSizing: "border-box",
        margin: "auto",
    },
    listItem: {
        '&:first-child': {
            borderWidth: "2px 0 1px 0!important"
        },
        '&:last-child': {
            borderWidth: "1px 0 2px 0!important"
        },
        borderWidth: "1px 0 !important",
        borderStyle: "solid !important",
        borderColor: (theme.palette.type !== 'dark' ? "#00000030" : "#ffffff40") + "!important",
        '&:hover': {
            backgroundColor: (theme.palette.type !== 'dark' ? "#00000030" : "#ffffff40") + "!important",
        },
        padding: 0 + "!important",
    },
    selectedItem: {
        backgroundColor: theme.palette.primary.main + "!important",
        '&:hover': {
            backgroundColor: theme.palette.primary.dark + "!important",
        },
        color: theme.palette.primary.contrastText + "!important",
    },
    tooltip: {
        textTransform: "uppercase",
        fontSize: "10pt"
    },
    listItemText: {
        margin: 0,
        padding: "5px 7px",
        '& > *': {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
        }
    },
}))

export const useShowSelectPopup = (options: FormSelectPopupOptions, setSelectedValue: (value: {label: string, value: any, data: any}) => void) => {
    const classes = useStyles();

    return async (selectedValue: any) => {
        setLoading(true);
        const selectData = await options.getSelectData();
        setLoading(false);

        dispatchModal({
            onClose: () => {},
            type: ModalActionType.Show,
            title: options.modalTitle,
            body: (<Box className={classes.modal}>
                <Box>
                    <List component="nav" aria-label="secondary mailbox folder">
                        {selectData.map(selectVal => {
                            const isSelected = selectedValue == selectVal.value;
                            return <ListItemButton
                                className={isSelected ? `${classes.listItem} ${classes.selectedItem}` : classes.listItem}
                                key={selectVal.value}
                                selected={isSelected}
                                onClick={(event) => {
                                    setSelectedValue(selectVal);
                                    dispatchModal({type:ModalActionType.Hide});
                                }}
                            >
                                <ListItemText className={classes.listItemText} primary={<>
                                    <span>{selectVal.label}</span>
                                    <div>
                                        {options.itemActions != null && options.itemActions.map(actionVal => {
                                            return <Tooltip key={`action_${actionVal.tooltip}_${selectVal.value}`} title={<b className={classes.tooltip}>{actionVal.tooltip}</b>}>
                                                <span>
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            actionVal.onClick({
                                                                value: selectVal.value,
                                                                label: selectVal.label,
                                                                data: selectVal
                                                            });
                                                        }}
                                                        style={{padding: "5px"}}
                                                        color="inherit">
                                                        {actionVal.icon}
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        })}
                                    </div>
                                </>}/>
                            </ListItemButton>
                        })}
                        {options.noDataText && selectData.length === 0 && <i>{options.noDataText}</i>}
                    </List>
                </Box>
            </Box>)
        });
    }
}

export function FormSelectPopup(props:CustomFieldComponentProps<{id: any, name: string}, FormSelectPopupComponentOptions>) {
    const [selectedValue, setSelectedValue] = useState({label: props.value.name, value: props.value.id});
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const {t} = useTranslation();

    useMountedEffect(() => {
        if (props.onValueChanged) props.onValueChanged(selectedValue?.value);
    }, [selectedValue]);

    useEffect(() => {
        if(props.value.id == null && selectedValue?.value != null){
            setSelectedValue({label: null, value: null});
        }
    }, [props.value]);

    const showPopup = useShowSelectPopup(options, setSelectedValue);

    return <>
        <TextField
            onClick={() => showPopup(selectedValue?.value)}
            size={"small"}
            style={{...{width: "100%"}, ...options.textStyle}}
            variant="outlined"
            id="outlined-disabled"
            label={props.title}
            value={selectedValue?.label ?? ""}
            InputProps={{
                readOnly: true,
                ...(selectedValue?.value && {
                    endAdornment: <>
                        {selectedValue?.value && options.onFilledAdornment}
                    </>
                }),
                ...(!selectedValue?.value && {
                    endAdornment: <>
                        {!selectedValue?.value && options.onEmptyAdornment}
                    </>
                }),
                ...(selectedValue?.value && !options.hideClearButton && {
                    startAdornment: <>
                        {selectedValue?.value &&
                            <Tooltip title={t("DialDefaults.ClearField")}>
                                <IconButton style={{padding:"5px"}} onClick={(e) => {e.stopPropagation(); setSelectedValue(null)}}> <ClearIcon fontSize={"small"} /> </IconButton>
                            </Tooltip>
                        }
                    </>
                })
            }}
        />
    </>;
}