import React, {
    Dispatch,
    forwardRef,
    MutableRefObject,
    SetStateAction,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react";
import L, {DragEndEvent, LatLng, LeafletMouseEvent} from "leaflet";
import {MarkerProps} from "react-leaflet";
import {MapOSMFunctionsExposed, MapOSMProps} from "./MapOSM";
import {useTranslation} from "react-i18next";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Fab,
    Grid,
    IconButton,
    Tab,
    Tooltip,
    Typography
} from "@material-ui/core";
import {StandaloneField} from "../form/StandaloneField";
import {FormField, FormInputType, useFormData} from "../../../web/raal_components/form/Form";
import {formatForRaalUsers, FormNominatim, FormNominatimOptions} from "../form/FormNominatim";
import {OsmPlace, reverse} from "./Nominatim";
import {useDidMount, useMountedEffect} from "../hooks/SharedHooks";
import {useMpzResolver} from "../../utils/MpzUtils";
import {DrawerContainer} from "../DrawerContainer";
import InfoIcon from "@material-ui/icons/Info";
import {useCurrencySelectObj, useProfilVozidlaSelect} from "../../../web/raal_components/SelectOptions";
import {arrayReplaceOrAdd, exist, formatNumber, secondsToMinDuration} from "../../utils/Util";
import {CalculationResultToll, TrasaBase, TrasaCalculationResultBase} from "../../../web/model/Trasa";
import {SortableContainer, SortableElement, SortableHandle, SortEnd, SortEvent} from "react-sortable-hoc";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Delete from "@material-ui/icons/Delete";
import {DataGrid, GridDensityTypes} from "@material-ui/data-grid";
import {TrasaFormViewType} from "../../../web/page/Dials/user/kilometrovnik/TrasaForm";
import {useStyleContext} from "../../../web/context/ThemeModeContext";
import Menu from "@material-ui/icons/Menu";
import {useAppContext} from "../../../web/context/AppContext";
import {hasLicense} from "../../logic/permissions-logic";
import {showSnack} from "../SnackContainer";
import {Waypoint} from "../../../web/model/Waypoint";
import {AttachMoney, ExpandMore, MoneyOff} from '@material-ui/icons';
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {Misto} from "../../../web/model/PrepravaVozidlo";
import {Geometry} from "../../../web/model/Geometry";
import {OSRMTextInstructions} from "./osrmTextInstructions/OSRMTextInstructions";
import {RouteFromOsrmData} from "../../utils/osrm-utils";
import {MapContainer, MapContainerExposed} from "./MapContainer";
import {GeoError, useErrorTranslator} from "../../utils/error-utils";
import {TrasaType} from "../../../web/page/Dials/user/kilometrovnik/ModalTrasaForm";
import {mapDataConverterWaypoints} from "../../../web/raal_components/SharedConfig";

//types
interface MouseEventFunction {
    (event:LeafletMouseEvent, index: number):void;
}

export type MapPoint = {
    nazevMista?: string,
    latLng: LatLng,
    countryCode?: string;
}

function toMisto(mapPoint: MapPoint): Misto {
    const r = new Misto();
    r.countryCode = mapPoint.countryCode;
    r.nazevMista = mapPoint.nazevMista;
    r.koordinat = new Geometry(mapPoint.latLng.lat, mapPoint.latLng.lng);
    return r;
}

function toMapPoint(event:LeafletMouseEvent, place: OsmPlace, mpzResolver: (isoCode: string) => string): MapPoint {
    return {nazevMista: formatForRaalUsers(place, mpzResolver), latLng: event.latlng, countryCode: place?.address?.country_code?.toUpperCase()}
}

export type MapData = Array<MapPoint>

interface MarkerDragEnd {
    (e:L.DragEndEvent, index:number):void
}
interface PlaceMarker {
    (nazevMista: string, lat:number, lng:number, index: number, countryCode: string):void
}
type _useMarkers = (state:PointState) => [MarkerProps[], MarkerDragEnd, PlaceMarker];

const getPlace = async (lat: number, lng: number) : Promise<OsmPlace | string> => {
    try {
        const resGeo: OsmPlace = await reverse(new LatLng(lat, lng));

        if (resGeo) {
            return resGeo;
        } else {
            return 'ConnectionError';
        }
    } catch (e) {
        if(e instanceof GeoError)
            return e.message;

        return 'ConnectionError';
    }
}

type PointState = {points:MapData, setPoints:Dispatch<SetStateAction<MapData>>, maxPoints:number, viewType:  TrasaFormViewType }
const useWayPoints = ({points, setPoints, maxPoints,viewType}:PointState):[MouseEventFunction, VoidFunction] => {
    const [resolveMpz] = useMpzResolver();
    const {user} = useAppContext();
    const {t} = useTranslation();
    const et = useErrorTranslator();

    const addWayPoint = async (event:LeafletMouseEvent, index: number) => {
        if (index === -1 && viewType !== TrasaFormViewType.POINT) return;

        const tempIndex = viewType === TrasaFormViewType.POINT ? 0 : index;

        let newPoints = [...points];
        const place = await getPlace(event.latlng.lat, event.latlng.lng);

        if (typeof place === "string") {
            showSnack({title: et(place), severity: "error"});
            return;
        }
        if (place) {
            const newPoint = toMapPoint(event, place, resolveMpz);

            if ((maxPoints === 0 || tempIndex < maxPoints) && tempIndex > newPoints.length - 1) {
                newPoints.push(newPoint);
            } else {
                newPoints[tempIndex] = newPoint;
            }
            if(!hasLicense(user, newPoints?.map(value => toMisto(value))) && viewType !== TrasaFormViewType.POINT) {
                showSnack({title: t('Map.noLicense'), severity: "error"});
                return;
            }

            setPoints(newPoints);
        }
    };
    const clearWayPoints = () => {
        setPoints([]);
    };
    return [addWayPoint, clearWayPoints];
};



const useMarkers:_useMarkers = ({points, setPoints, viewType}) => {
    const [resolveMpz] = useMpzResolver();
    const {user} = useAppContext();
    const {t} = useTranslation();
    const et = useErrorTranslator();

    const mapDataToMarkers = useCallback(():MarkerProps[] => {
        const markers = [] as MarkerProps[];

        points?.map((p) => markers.push({position:p.latLng}))

        return markers;
    }, [points]);

    const [markerData, setMarkerData] = useState(mapDataToMarkers());

    useEffect(()=>{
        setMarkerData(mapDataToMarkers());
    }, [points, mapDataToMarkers]);//when data change renew markers


    const onMarkerDragged = async (e: DragEndEvent, index: number) => {
        const newPoints = [...points];

        const place = await getPlace(e.target._latlng.lat, e.target._latlng.lng);
        if (typeof place === "string") {
            showSnack({title: et(place), severity: "error"});
            return;
        }
        if (place) {
            const nazevMista = formatForRaalUsers(place, resolveMpz);
            newPoints[index] = {nazevMista: nazevMista, latLng: e.target._latlng, countryCode: place?.address?.country_code?.toUpperCase()};

            if(!hasLicense(user, newPoints?.map(value => toMisto(value))) && viewType !== TrasaFormViewType.POINT) {
                showSnack({title: t('Map.noLicense'), severity: "error"});
                return;
            }
            setPoints(newPoints);
        }
    }

    const placeMarker = (nazevMista: string, lat: number, lng: number, index: number, countryCode: string) => {
        const p = [...points]
        const newWaypoint = {nazevMista: nazevMista, latLng: new LatLng(lat, lng), countryCode: countryCode}
        arrayReplaceOrAdd(p, newWaypoint, index);
        setPoints(p);
    }

    return [markerData, onMarkerDragged, placeMarker];
};

interface FunctionMapDelegate {
    onPointsChanged?(points?:MapData):void
}

export interface ExtraRoute {
    route: RouteFromOsrmData;
    color: string;
    dashArray?: string;
}

export interface ExtraWaypoint {
    waypoint: Waypoint;
    label: string;
}

export interface FunctionMapProps extends MapOSMProps, FunctionMapDelegate {
    points?:MapData,
    latLng:LatLng,
    zoom?:number,
    maxPoints:number,
    inlineMap?: boolean,
    mapHeight?: any,
    route?: (i: number) => RouteFromOsrmData,
    disabled?: boolean,
    routes?: any[],
    options?: any,
    viewType?: TrasaFormViewType,
    extraRoutes?: (i: number) => ExtraRoute[],
    extraWaypoints?: (i: number) => ExtraWaypoint[],
    overrideWaypoints?: (i: number) => Waypoint[],
    routeOnly?: boolean,
    hideRoute?: (i: number) => boolean,
    trasaType?: TrasaType
    onDisableActionKeys?:(isDisabled: boolean, focusNext?: boolean)=>void
    onFocusNextField?:() =>void
    onFocusPrevField?:(index?: number) => void
    resetFormFocus?:()=>void
    enableOSRM?: boolean
}

/**
 * Komponenta k vyrenderovani
 */
export interface FunctionMapFunctionsExposed {
    leafletFunctions():MapOSMFunctionsExposed,
    setPoints(points:MapData):void
}

export const FunctionalMap = forwardRef<FunctionMapFunctionsExposed, FunctionMapProps>((props, ref) => {
    const onPointsChanged = props.onPointsChanged || (()=>{});
    const [points, setPoints] = useState(props.points || []);
    const [resolveMpz] = useMpzResolver();
    const maxPoints = props.maxPoints || 0;
    const hasWayPoints = (maxPoints>1 || maxPoints < 1);
    const viewType = props.viewType;
    const [markers, onMarkerDragged, placeMarker] = useMarkers({points, setPoints, maxPoints, viewType});
    const [addWayPoint, clearWayPoints] = useWayPoints({points, setPoints, maxPoints, viewType});
    const {t} = useTranslation();
    const wait = useRef(-1);
    const mapContainerRef = useRef<MapContainerExposed>();
    const profilVozidlaSelectProps = useProfilVozidlaSelect({isClearable: true});
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const trasa = useFormData<TrasaBase>();
    const [routeChanged, setRoutChanged] = useState(false);
    const {user} = useAppContext();
    const [f, forceUpdate] = useState(false);
    const [instructionsOpen, setIstructionsOpen] = useState(false);
    const trasy = trasa?.getCalculationResults&&trasa.getCalculationResults();
    const multipleResults = exist(trasy) && trasy.length > 1;
    const [calcTab, setCalcTab] = useState(() => "0");
    const calcTabNum = Number(calcTab);
    const currentCalculationResult = trasa?.getCalculationResultAtIndex&&trasa?.getCalculationResultAtIndex(calcTabNum);
    const [toMapData] = mapDataConverterWaypoints();
    // eslint-disable-next-line
    const overrideWaypoints = useMemo(() => props.overrideWaypoints && props.overrideWaypoints(calcTabNum) && toMapData(props.overrideWaypoints(calcTabNum)), [calcTabNum]);
    const [overrideMarkers] = useMarkers({points: overrideWaypoints, setPoints, maxPoints, viewType});
    const sortablePointsRef = useRef<SortablePointsComponentExposed>(null);

    useMountedEffect(() => {
        onPointsChanged(points);
        fitBounds();
    }, [points]);

    //ref for getting leaflet object
    const osmMapRef = useRef<MapOSMFunctionsExposed>();
    const fitBounds = () => {
        //when first loaded and points are presented then map fit bounds to presented points
        const allPoints = props.extraWaypoints ? [...(points ?? []), ...(props.extraWaypoints(calcTabNum)?.flatMap(value => ({latLng: value.waypoint.koordinat.getLatLng()} as MapPoint)) ?? [])] : [...(points ?? [])];
        if(allPoints.length > 1) {
            osmMapRef.current.leaflet().fitBounds(L.latLngBounds(allPoints.map(p => p.latLng)), {animate: false, paddingTopLeft: [500, 0]});
            osmMapRef.current.leaflet().zoomOut(1, {animate: false});
        } else if (points.length > 0) {
            osmMapRef.current.leaflet().setView(points[0].latLng, props.zoom);
        }
    };

    useDidMount(() => {
        fitBounds();
    });

    useEffect(() => {
        window.addEventListener("mousedown", onMouseDown);
        return () => {
            window.removeEventListener("mousedown", onMouseDown);
        }
    }, [])

    const onMouseDown = (event: MouseEvent) => {
        if (event?.target &&
            event.target instanceof HTMLDivElement && event.target.className?.indexOf('leaflet') === -1) {
            wait.current = -1
        }

        if (event?.target &&
            (event.target instanceof HTMLSpanElement || event.target instanceof HTMLButtonElement ) &&
            event.target.className.toLowerCase().indexOf('button') !== -1) {
            sortablePointsRef.current?.resetFocus();
        }
    }

    useImperativeHandle(ref, () => {
        return {
            leafletFunctions: () => osmMapRef.current,
            setPoints: (points) => setPoints(points)
        }
    });
    const onSearchPicked = (place:OsmPlace, index: number, skipSetView: boolean = false) => {
        if (!place.address) return;

        const nazevMista = formatForRaalUsers(place, resolveMpz);
        const newWaypoint = {nazevMista: nazevMista, latLng: new LatLng(place.lat, place.lon), countryCode: place?.address?.country_code?.toUpperCase()}

        let newWaypoints = [...points]
        arrayReplaceOrAdd(newWaypoints, newWaypoint, index);

        if (hasLicense(user, newWaypoints.map(value => toMisto(value))) || viewType === TrasaFormViewType.POINT) {
            if (place && place.lat && place.lon) {
                placeMarker(nazevMista, place.lat, place.lon, index, place?.address?.country_code?.toUpperCase());
                if (!skipSetView) osmMapRef.current.leaflet().setView(new LatLng(place.lat, place.lon), props.zoom);
            }
        } else {
            showSnack({title: t("Map.noLicense"), severity:"error"});
            forceUpdate(!f);
        }
    };

    const onDragEnd = () => forceUpdate(!f);

    const removePoint = useCallback((index: number) => {
        const newPoints = [...points];
        newPoints.splice(index, 1)
        setPoints([...newPoints]);
        setRoutChanged(true);
    }, [points, setPoints])

    const onSortEnd = useCallback(({oldIndex, newIndex}:{oldIndex: number, newIndex: number}) => {
        const value = points[oldIndex];
        const tempLi = [...points];
        tempLi.splice(oldIndex, 1);
        tempLi.splice(newIndex, 0, value)
        if(hasLicense(user, tempLi.map(value => toMisto(value)))) {
            setPoints([...tempLi])
            if (!routeChanged) setRoutChanged(true);
        } else {
            showSnack({title: t('Map.noLicense'), severity: "error"});
        }
        // eslint-disable-next-line
    }, [points, setPoints])

    const Route = useCallback(() => {
        return <>
            {exist(viewType) && (viewType === TrasaFormViewType.ROUTE || viewType === TrasaFormViewType.OFFER)?
            <Grid item lg={12} sm={12} md={12}>
                <FormField
                    name={"nazev"}
                    title={t("Trasa.nazev")}
                    type={"text"}
                    required
                    textFieldProps={{inputProps: {maxLength: 50}}}
                />
            </Grid> : null}
            <Grid item lg={12} sm={12} md={12} style={{marginTop: '0.5rem'}}>
                <FormField
                    title={t("Trasa.profilVozidla")}
                    name='profilVozidla'
                    type='select'
                    selectProps={profilVozidlaSelectProps}
                    required
                    disabled={props.disabled && viewType === TrasaFormViewType.OFFER} />
            </Grid>
            {props.routeOnly ? undefined : <Grid item lg={12} sm={12} md={12} style={{marginTop: '0.5rem'}}>
                <Grid container alignItems={"center"}>
                    <Grid item lg={10} sm={10} md={10}>
                        <FormField
                            title={t("Trasa.currency")}
                            name='currency'
                            type='select'
                            selectProps={currencySelectProps}
                            required
                            preventFocusNext={true}
                            onKeyDown={(e) => {
                                if (e.key === 'Tab' || e.key === 'Enter') {
                                    sortablePointsRef.current?.setFocus();
                                }
                            }}
                            disabled={props.disabled && viewType === TrasaFormViewType.OFFER}/>
                    </Grid>
                    <Grid container item lg={1} sm={1} md={1} justifyContent={"center"}>
                        <Tooltip title={t("Trasa.currencyTooltip")}><InfoIcon color="secondary"/></Tooltip>
                    </Grid>
                </Grid>
            </Grid>}
        </>
        // eslint-disable-next-line
    },[props.viewType, props.routeOnly, viewType, props.disabled])

    const RouteSummary = useCallback(() => {
        if (!trasa?.getCalculationResult()) return null;

        const tabLay = (table: CalculationResultToll[]) => exist(table) && table.length>0 ? <Grid item style={{height: 250}} md={12} lg={12} sm={12}>
            <DataGrid columns={[
                { field: 'mpz', headerName: t('Trasa.mpz'), width: 90},
                { field: 'roadType', headerName: t('CenaMyta.typSilnice'), width: 200},
                { field: 'distancePaid', headerName: t('Trasa.distancePaid'), width: 90,  renderHeader: () => <Tooltip title={t('Trasa.distancePaid')} placement={"top-start"}><AttachMoney /></Tooltip>, valueFormatter: (params) => formatNumber(params.value as number, "###,###.#")},
                { field: 'distanceUnpaid', headerName: t('Trasa.distanceUnpaid'), width: 90, renderHeader: () => <Tooltip title={t('Trasa.distanceUnpaid')} placement={"top-start"}><MoneyOff /></Tooltip>, valueFormatter: (params) => formatNumber(params.value as number, "###,###.#")}

            ]}
                      disableColumnFilter={true}
                      disableColumnMenu={true}
                      hideFooter={true}
                      rows={
                          table.map((tr, i) => ({
                              id: i,
                              mpz: tr.mpz,
                              roadType: t(`Enumerations.RoadType.${tr.roadType}`),
                              distancePaid: tr.distancePaid / 1000,
                              distanceUnpaid: tr.distanceUnpaid / 1000
                          }))
                      }
                      density={GridDensityTypes.Compact}
                      disableSelectionOnClick
                      hideFooterPagination={true}
            />
        </Grid> : undefined;

        if(multipleResults) {
            return <TabContext value={calcTab}>
                <TabList onChange={(event, value) => setCalcTab(value)}>
                    {trasy.map((tri, i) => <Tab label={t(tri.key)} key={i} value={i.toString()}/>)}
                </TabList>
                {trasy.map((tri, i) => <TabPanel key={i} value={i.toString()}>
                    <Grid container style={{marginTop: 10}}>
                        <CalculationResult cr={tri.trasaCalculationResult} routeOnly={props.routeOnly} currencyName={trasa.currency?.name} />
                    </Grid>
                    <Grid container style={{marginTop: 10}}>
                        {tabLay(tri?.trasaCalculationResult?.tollTable)}
                    </Grid>
                </TabPanel>)}
            </TabContext>;
        } else {
            return <>
                <Grid container style={{marginTop: 10}}>
                    <CalculationResult cr={currentCalculationResult} routeOnly={props.routeOnly} currencyName={trasa.currency?.name} />
                </Grid>
                <Grid container style={{marginTop: 10}}>
                    {tabLay(currentCalculationResult?.tollTable)}
                </Grid></>;
            }
        // eslint-disable-next-line
        }, [trasa, calcTab, trasy, props.routeOnly, multipleResults])

    return <Grid container spacing={2}>
        {viewType !== TrasaFormViewType.POINT ? <DrawerContainer container={"drawer-container"} anchor={"left"} openOnMounted={true}
             openButton={
                 <Fab size={"small"}>
                     <Menu/>
                 </Fab>}>
                <Grid container style={{maxWidth: '500px'}}>
                    <Grid container style={{maxWidth: '450px'}}>
                        <Route key={"routeKey"} />
                    </Grid>
                    <Grid container style={{maxWidth: '450px', pointerEvents: !props.disabled ? "visible" : "none"}}>
                        <SortablePointsComponent
                            {...props}
                            calcTabNum={calcTabNum}
                            componentRef={sortablePointsRef}
                            displayPoints={overrideWaypoints ?? points}
                            points={points}
                            setWait={(value) => wait.current = value}
                            removePoint={(value) => removePoint(value)}
                            onChange={(osmPlace, value) => {
                                onSearchPicked(osmPlace, value, true);
                                if (!routeChanged) setRoutChanged(true);
                            }}
                            onSortEnd={onSortEnd}
                            maxPoints={maxPoints}
                        />
                    </Grid>
                    {!routeChanged && trasa?.getCalculationResult() ?
                         <>
                             <RouteSummary/>
                             <Grid item style={{minWidth: 250,marginTop: 10}} md={12} lg={12} sm={12}>
                                 <Accordion expanded={instructionsOpen}
                                            onChange={(e, expanded) => setIstructionsOpen(expanded)}
                                            style={{opacity: 0.7, boxShadow: 'none'}}>
                                     <AccordionSummary
                                         expandIcon={<ExpandMore />}
                                     >
                                         <Typography>{t('Map.navigation')}</Typography>
                                     </AccordionSummary>
                                     <AccordionDetails>
                                         <OSRMTextInstructions
                                             key={"instr"}
                                             route={props.route(calcTabNum)}
                                             trasaType={props.trasaType}
                                             calcTabNum={calcTabNum}
                                             extraRoutes={props.extraRoutes && props.extraRoutes(calcTabNum)}
                                             highlightRoute={(route) => {
                                                 mapContainerRef.current.highlightRoute(route);
                                             }}
                                             onMouseLeave={() => mapContainerRef.current.highlightRoute(null)}
                                         />
                                     </AccordionDetails>
                                 </Accordion>
                             </Grid>
                             <Grid container style={{marginTop: 10}}>
                                 {hasWayPoints && !props.disabled && <Grid item xs={12}>
                                     <Button variant={"contained"} color={"primary"}
                                             onClick={() => {
                                                 setRoutChanged(true);
                                                 clearWayPoints();
                                             }} type={"button"}>{t("Map.ClearWayPoints")}</Button>
                                 </Grid>}
                             </Grid>
                         </> : null
                    }
                </Grid>
        </DrawerContainer> : null }
        <Grid item md={12} lg={12} sm={12}>
            {viewType === TrasaFormViewType.POINT ?
                <StandaloneField<FormNominatimOptions>
                    type={FormInputType.Custom}
                    customComponent={FormNominatim}
                    disabled={props.disabled}
                    customComponentOptions={{
                        disableClearable: true,
                        autofillValue: true,
                        enableOSRM: props.enableOSRM,
                        onChange: (osmPlace) => onSearchPicked(osmPlace, 0)}}
                    title={t("Map.SearchPlace")}/> : null}
        </Grid>
        <MapContainer {...props}
                      mapRef={mapContainerRef}
                      osmMapRef={osmMapRef}
                      markers={exist(overrideWaypoints) ? overrideMarkers : markers}
                      route={props.route&&props.route(calcTabNum)}
                      calcTabNum={calcTabNum}
                      trasaType={props.trasaType}
                      hideRoute={props.hideRoute && props.hideRoute(calcTabNum)}
                      extraRoutes={props.extraRoutes && props.extraRoutes(calcTabNum)}
                      extraWaypoints={props.extraWaypoints && props.extraWaypoints(calcTabNum)}
                      routeChanged={routeChanged}
                      points={overrideWaypoints ?? points}
                      onRouteChanged={(state) => setRoutChanged(state)}
                      onClick={async (event: LeafletMouseEvent) => {
                          if (wait.current === -1 && viewType !== TrasaFormViewType.POINT) return;
                          sortablePointsRef.current?.showLoading(true);
                          await addWayPoint(event, wait.current);
                          sortablePointsRef.current?.showLoading(false);}
                      }
                      onMarkerDragged={onMarkerDragged}
                      onDragEnd={onDragEnd}
                      hasWayPoints={hasWayPoints} />
    </Grid>;
});

interface SortablePointsComponentExposed {
    showLoading: (show: boolean) => void
    resetFocus: () => void
    setFocus: (index?: number) => void
}

type SortablePointsComponentProps = {
    calcTabNum: number
    componentRef: MutableRefObject<SortablePointsComponentExposed>
    setWait: (value: any) => void
    displayPoints: MapData
    removePoint: (value: any) => void
    onChange: (osmPlace: OsmPlace, value: any) => void
    onSortEnd: (sortEnd: SortEnd, eventSort: SortEvent) => void
    maxPoints: number
}&FunctionMapProps

const SortablePointsComponent = (props: SortablePointsComponentProps) => {
    const {classes} = useStyleContext();
    const {calcTabNum, displayPoints, maxPoints} = props;
    const extraWaypoints = props.extraWaypoints && props.extraWaypoints(calcTabNum);
    const [loading, setLoading] = useState(false);
    const [focusedElement, setFocusElement] = useState<{index?: number}>({index: null});
    const {t} = useTranslation();
    const isFieldFocused = useRef(false);
    const blurTimer = useRef(null);
    const pointChanged = useRef(false);

    const waitForPointOnBlur = (): boolean => {
        return true;
    }

    useImperativeHandle(props.componentRef, () => ({
        showLoading,
        resetFocus,
        setFocus
    }))

    const showLoading = (show: boolean) => {
        setLoading(show);
    }

    const resetFocus = () => {
        props.onDisableActionKeys(false);
        setFocusElement({index: null});
    }

    const setFocus = (index: number = 0) => {
        props.onDisableActionKeys(true);
        setFocusElement({index: index});
        props.resetFormFocus();
    }

    const DragHandle = SortableHandle(() => <DragHandleIcon />);

    // @ts-ignore
    const SortableItem = SortableElement(({value, trasaType}) =>
        <Grid container justifyContent={"center"} alignItems={"center"} >
            <Grid item container lg={1} md={1} sm={1} justifyContent={"center"} alignItems={"center"} style={{padding:0, textAlign: "center"}}>{displayPoints && displayPoints[value]?.nazevMista && !props.disabled ? <DragHandle />: null}</Grid>
            <Grid item lg={1} md={1} sm={1} style={{padding:0, textAlign: "center"}}>
                {calcTabNum === 2 ?
                    `${!trasaType || trasaType === TrasaType.NORMAL ? '' : props.trasaType === TrasaType.PREJEZD ? 'P' : value === 0 ? 'N' : 'V'}${props.trasaType !== TrasaType.DOKLADKA ? value + 1 : ''}` :
                    value + 1
                }
            </Grid>
            <Grid item lg={9} md={9} sm={9} style={{padding:0, textAlign: "center"}}>
                <StandaloneField<FormNominatimOptions>
                    value={displayPoints && displayPoints[value]?.nazevMista} type={FormInputType.Custom}
                    customComponent={FormNominatim}
                    disabled={props.disabled}
                    onFocus={() => {
                        props.setWait(value);
                        props.onDisableActionKeys(true);
                        isFieldFocused.current = true;
                        pointChanged.current = false;
                        if (focusedElement.index !== value) {
                            setFocusElement({index: value});
                        }
                    }}
                    onBlur={() => {
                        isFieldFocused.current = false
                        clearTimeout(blurTimer.current);
                        blurTimer.current = setTimeout(() => {
                            if (!isFieldFocused.current && !loading) {
                                setFocusElement({index: null});
                                props.setWait(-1);
                            }
                        }, 1000);
                        props.onDisableActionKeys(false);
                    }}
                    onKeyDown={(e) => {
                        if (e.shiftKey && (e.key === 'Tab' || e.key === 'Enter')) {
                            if (value > 0) {
                                setFocusElement({index: value - 1});
                            } else {
                                props.onFocusPrevField(2);
                            }
                        }
                        if (!e.shiftKey && (e.key === 'Tab' || e.key === 'Enter')) {
                            e.preventDefault();
                            if (displayPoints.length !== value) {
                                setFocusElement({index: value + 1});
                            } else {
                                if (!pointChanged.current) {
                                    setFocusElement({index: null});
                                    props.onDisableActionKeys(false);
                                    props.onFocusNextField();
                                }
                            }
                        }
                    }}
                    focused={focusedElement.index === value}
                    customComponentOptions={{
                        disableClearable: true,
                        autofillValue: true,
                        onChange: (osmPlace) => {
                            pointChanged.current = true
                            props.onChange(osmPlace, value)
                        },
                        onBlur: () => waitForPointOnBlur()
                    }}
                    title={!displayPoints[value]?.nazevMista ? t("Map.SearchPlace") : ''}/>
            </Grid>
            <Grid item lg={1} md={1} sm={1} style={{padding:0, textAlign: "center"}}>
                {displayPoints && displayPoints[value]?.nazevMista && !props.disabled ? <IconButton size={"small"} onClick={() => {
                    props.removePoint(value);
                    setFocusElement({index: 0});
                }}>
                    <Delete/>
                </IconButton>: null}
            </Grid>
        </Grid>
    )

    // @ts-ignore
    const ExtraItem = ({value}: {value: number}) => {
        return <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item lg={1} md={1} sm={1} style={{padding: 0, textAlign: "center"}}>
                {props.trasaType === TrasaType.PREJEZD ? `P${value + 1}` : null}
                {props.trasaType === TrasaType.DOKLADKA ? value === 0 ? "N" : "V" : null}
            </Grid>
            <Grid item lg={9} md={9} sm={9} style={{padding: 0, textAlign: "center"}}>
                <StandaloneField<FormNominatimOptions>
                    value={props.extraWaypoints(calcTabNum) && props.extraWaypoints(calcTabNum).length > value && props.extraWaypoints(calcTabNum)[value].waypoint?.nazevMista}
                    type={FormInputType.Custom}
                    customComponent={FormNominatim}
                    disabled={true}/>
            </Grid>
        </Grid>
    }

    // @ts-ignore
    const SortablePointsContainer = SortableContainer(({children}) => {
        return  <Grid container style={{marginTop: 10}} >{children}</Grid>;
    })

    return <SortablePointsContainer onSortEnd={(sort, event) => props.onSortEnd(sort, event)} helperClass={classes.hocSorting} useDragHandle={ true }>
        {exist(props.trasaType) && props.trasaType !== TrasaType.NORMAL && extraWaypoints ? <ExtraItem value={0} /> : null}
        {displayPoints.map((p, index) => <SortableItem key={index} index={index} value={index} trasaType={props.trasaType} />)}
        {exist(props.trasaType) && props.trasaType !== TrasaType.NORMAL && extraWaypoints ? <ExtraItem value={1} /> : null}
        <Grid container style={{marginTop: 20}}>
            {loading ?
                <Grid container justifyContent={"center"}>
                    <CircularProgress color="inherit" size={25} />
                </Grid> :
                (maxPoints > displayPoints.length || maxPoints === 0) && !props.disabled ?
                    <SortableItem key={displayPoints.length} index={displayPoints.length} value={displayPoints.length} trasaType={null} /> : null
            }
        </Grid>
    </SortablePointsContainer>
}


interface CalculationResultProps {
    cr: TrasaCalculationResultBase
    currencyName: string
    routeOnly: boolean
}

const CalculationResult = (props: CalculationResultProps) => {
    const {t} = useTranslation();
    const {cr} = props;
    return <Grid item container spacing={2}>
        <Grid item>
            <Card>
                <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                        {t("Trasa.distance")}
                    </Typography>
                    <Typography variant="h6" component="h2">
                        {cr?.routeDistance ? `${formatNumber(cr?.routeDistance / 1000, "###,###.#")} km` : "-"}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
        <Grid item>
            <Card>
                <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                        {t("Trasa.duration")}
                    </Typography>
                    <Typography variant="h6" component="h2">
                        {cr?.routeDuration ? `${formatNumber(secondsToMinDuration(cr?.routeDuration)?.hours, "###,###")} h ${formatNumber(secondsToMinDuration(cr?.routeDuration)?.minutes, "###,###")} min` : "-"}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
        {props.routeOnly ? undefined :<Grid item>
            <Card>
                <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                        {t("Trasa.cost")}
                    </Typography>
                    <Typography variant="h6" component="h2">
                        {cr?.toll ? formatNumber(cr?.toll, "###,###.#") : "-"} {cr?.toll ? props.currencyName : ""}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>}
    </Grid>
}
