import moment from "moment";
import {IDClass} from "./CommonTypes";
import {exist} from "../../common/utils/Util";
import {Converters} from "../../common/utils/objectmapper/Mapper.d";

export const MomentConverters = {
    toJson(value: any): any {
        if(exist(value)) {
            const m = moment(value);
            if(!m.isValid()) {
                return null;
            }
            return m.toISOStringWithMillis();
        }
        return null;
    },
    fromJson(value: string): any {
        try {
            const m = moment(value);
            if (m.isValid()) {
                return m;
            }
        } catch (e) {
            //also not valid
        }
        return null;
    }
} as Converters;

export const SpringEntityReferenceSupport = {
    toJson(value: any): any {
        if((value as IDClass).id) {
            return (value as IDClass).id;
        } else {
            return value;
        }
    },
    fromJson(value: any): any {
        return value;
    }
} as Converters;