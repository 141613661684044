import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import Drawer, {DrawerProps} from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import {Omit} from '@material-ui/types';
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router";
import {Route, useCurrentRoute, useProtectedRoutes} from "./routes";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar, {AppBarProps} from "@material-ui/core/AppBar";
import moment from "moment"
import {Box, Grid, Hidden, Link, useMediaQuery, useTheme} from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {AssetCache} from "../AssetCache";
import {Header} from "./Header";
import {useStyleContext} from "./context/ThemeModeContext";
import {useMountedEffect} from "../common/component/hooks/SharedHooks";
import {Theme} from "@material-ui/core/styles";
import {useAppContext} from "./context/AppContext";
import DataStorage from "../common/DataStorage";
import {isMobile} from "react-device-detect";

export interface FlexibleDrawerProps extends Omit<DrawerProps, 'classes'> {}
export interface FlexibleAppBarProps extends Omit<AppBarProps, 'classes'> {
    handleDrawerToggle?:VoidFunction
}

type ListLinkProps = Route&{nested?:boolean};
const SidebarContext = React.createContext<{
    desktopDrawerOpen:boolean,
    mobileDrawerOpen: boolean,
    setMobileDrawerOpen:(status:boolean)=>void,
    setDesktopDrawerOpen:(status:boolean)=>void,
    closeAllItems:()=>void,
    itemsOpen:boolean}>(null);

const useClose = (defaultValue:boolean):[boolean, (value:boolean)=>void] => {
    const {itemsOpen} = useContext(SidebarContext);
    const [opened, setOpened] = useState(defaultValue);
    useMountedEffect(()=>{
        if(itemsOpen !== null) {
            setOpened(defaultValue);
        }
    }, [itemsOpen, defaultValue]);
    return [opened, setOpened];
};

function collectHref(route: Route) : string {
    return route.parent ? collectHref(route.parent)  + route.href : route.href;
}

function extractUrlPart(level: number, url: string) {
    const arr =  url.startsWith("/") ? url.substring(1).split("/") : url.split("/");
    if(level>=arr.length)
        return undefined;
    return arr[level];
}

const useStyles = makeStyles({
	upperMenuItem: { backgroundColor: '#1976d2' },
	lowerMenuItem: { backgroundColor: '#73b9ff' },
	boldMenuItem: {
		'& .MuiTypography-root.MuiListItemText-primary': {
			fontWeight: 'bold',
		},
	},
});

function CategoryList({parent, component, level = 1}:{parent:Route, component:React.ComponentType<ListLinkProps>, level?: number}) {
    const ListLink = component;
    const routes = parent.children;
    const {t} = useTranslation();
    const theme = useTheme();
    const {classes} = useStyleContext();
    const {pathname} = useLocation();
    const route = useCurrentRoute();
    const [open, setOpen] = useClose(parent.autoOpen || parent.href===`/${extractUrlPart(level-1, route.href)}`);
    const parentHref = collectHref(parent);

    routes.forEach(c => {c.parent = parent});

	const nClasses = useStyles();
	const boldMenuItem = parent.bold ? nClasses.boldMenuItem : "";

    return (
        <>
            <ListItem button
                      onClick={()=>setOpen(!open)}
                      className={clsx(pathname.startsWith(`${parentHref}/`) ? (classes.activeLink, nClasses.upperMenuItem) : undefined, classes.drawerItem)}
                      style={{
                          paddingLeft: theme.spacing(level * 2),
						  textTransform: 'uppercase',
                      }}
			>
                <ListItemIcon style={{minWidth: '42px'}}>{parent.icon}</ListItemIcon>
                <ListItemText className={boldMenuItem} primary={t(parent.id)} /> {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {
                open&&
                routes.map((child, index) => (
                    (!child.children && <Box key={index}
                        style={{
                            paddingLeft: theme.spacing(level * 2),
							textTransform: 'uppercase',
                        }}
                    ><ListLink {...child} href={collectHref(child)} key={child.id} nested newWindow={child.newWindow}
                    /></Box>) ||
                    (child.children && <CategoryList key={index} level={level + 1} parent={child} component={ListLink} />)
                ))
            }
        </>
    );
}

const CurrentTime = () =>{
    const getTime = () => {
        setTime(moment().format("DD.MM.YYYY HH:mm:ss Z"));
    }
    const [time, setTime] = useState(moment().format("DD.MM.YYYY:ss HH:mm Z"))

    useEffect(() => {
        const time = setInterval(() => getTime(), 1000);

        return () => {
            clearInterval(time);
        }
    }, [])

    return <span style={{display: "block", textAlign: "center"}}>{time}</span>
}

function FlexibleDrawer(props:FlexibleDrawerProps) {
    const routes = useProtectedRoutes(r => !r.menuExcluded);
    const {checkDataChanged, setDataChanged, } = useAppContext();
    const {classes} = useStyleContext();
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const {push} = useHistory();
    const {closeAllItems, setMobileDrawerOpen} = useContext(SidebarContext);
    const navigate = (href: string, newWindow?: boolean, routeDefaultState?: any) => {
        setDataChanged(false);
        if(newWindow) {
            window.open(href);
        } else {
            setMobileDrawerOpen(false);
            push(`${href}`, {...routeDefaultState});
        }
    }

	const navigatorClasses = useStyles();

    const ListLink = ({id: childId, icon, href, nested=false, newWindow, routeDefaultState}:ListLinkProps) => {
        return (
            <ListItem
                className={clsx(nested ? classes.nested : undefined, `${href}`===pathname ? (classes.activeLink, navigatorClasses.lowerMenuItem) : undefined, classes.drawerItem)}
                key={childId}
                button
                component="a"
                onClick={(e:any)=>{
                    e.preventDefault();
                    checkDataChanged( () => navigate(href, newWindow, routeDefaultState), null, true)
                }}
                href={href}
				style={{
					textTransform: 'uppercase'
				}}
            >
                <ListItemIcon style={{minWidth: '42px'}}>{icon}</ListItemIcon>
                <ListItemText
					primary={t(childId)}
				/>
            </ListItem>
        );
    };

    return (
        <Drawer
            variant="permanent"
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper,
            }}
            {...props}
        >

            <div className={clsx(classes.menuToolbar, classes.toolbar)}>
                <Link href={"#"} onClick={(e:any)=>{
                    e.preventDefault();
                    closeAllItems();
                    push("/dashboard");
                }}>
                    <img src={AssetCache.Image.Logo} alt={"logo"} style={{ width: 200 }}/>
                </Link>
            </div>
            <div style={{overflowY: "auto", marginBottom: 30, textTransform: 'uppercase'}}>
            {routes.map((parent) => (
                <React.Fragment key={parent.id}>
                    {
                        !parent.children&&(
                            <ListLink {...parent} href={parent.href} key={parent.id} newWindow={parent.newWindow} />
                        )
                    }
                    {parent.children&&<CategoryList parent={parent} component={ListLink} />}
                    <Divider style={{marginBottom: 0, marginTop: 0}}/>
                </React.Fragment>
            ))}
            </div>
        </Drawer>
    );
}

function FlexibleAppBar({handleDrawerToggle, ...props}:FlexibleAppBarProps) {
    const {classes} = useStyleContext();
    return (
        <AppBar position="fixed" {...props}>
            <Toolbar variant={"regular"}>
                <IconButton
                    color="inherit"
                    edge="start"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={handleDrawerToggle}>
                    <MenuIcon />
                </IconButton>
                <div className={classes.flexGrow}/>
                <Header />
            </Toolbar>
        </AppBar>
    );
}

export default function Navigator() {
    const {classes} = useStyleContext();
    const [itemsOpen, setItemsOpen] = useState(false);
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
    const [desktopDrawerOpen, setDesktopDrawerOpen] = useState(JSON.parse(DataStorage.get("desktopDrawerOpen", false, "session")) ?? !isMobile);
    const [isDesktop, setIsDesktop] = useState(!(window.matchMedia("(orientation: portrait)").matches));
    const isDesktopType = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const handleDesktopDrawerToggle = () => {
        DataStorage.set("desktopDrawerOpen", !desktopDrawerOpen, false, "session");
        setDesktopDrawerOpen(!desktopDrawerOpen);
    };
    const handleMobileDrawerToggle = () => {
        setMobileDrawerOpen(!mobileDrawerOpen);
    };
    const setScreenOrientation = () => {
        setIsDesktop(!(window.matchMedia("(orientation: portrait)").matches));
    }

    useEffect(() => {
        window.addEventListener("resize", setScreenOrientation);
        return () => {
            window.removeEventListener("resize", setScreenOrientation);
        }
    }, [])

    return (
        <>
            <SidebarContext.Provider value={{ closeAllItems:()=>{setItemsOpen(!itemsOpen)}, itemsOpen, mobileDrawerOpen, desktopDrawerOpen, setDesktopDrawerOpen, setMobileDrawerOpen }}>
                <Hidden smUp implementation="js">
                    <FlexibleAppBar className={clsx(classes.appBar)} handleDrawerToggle={handleMobileDrawerToggle} />
                    <FlexibleDrawer
                        variant={"temporary"}
                        open={mobileDrawerOpen}
                        onClose={handleMobileDrawerToggle}
                    />
                </Hidden>
                <Hidden xsDown implementation="css">
                    <FlexibleAppBar className={clsx(classes.appBar, {
                        [classes.appBarShift]: desktopDrawerOpen,
                    })} handleDrawerToggle={handleDesktopDrawerToggle} />
                    <FlexibleDrawer
                        variant={"persistent"}
                        open={desktopDrawerOpen}
                        onClose={handleDesktopDrawerToggle}
                    />
                </Hidden>
                <div className={clsx(classes.contentIndent, {
                    [classes.contentIndentShift]: desktopDrawerOpen, [classes.contentNoLeftMargin]: !isDesktop && !isDesktopType
                })}/>
            </SidebarContext.Provider>
        </>
    );
}
