import {useTranslation} from "react-i18next";
import React, {Ref, useImperativeHandle, useState} from "react";
import {MuiModal} from "../../../../../common/component/MuiModal";
import {DokladkaFilter, DokladkaZadani} from "../../../../model/PrepravaVozidlo";
import DataStorage from "../../../../../common/DataStorage";
import {useDataStore} from "../../../../../common/utils/Util";
import {invoke} from "../../../../../common/utils/Invoke";
import {DokladkyZadaniForm} from "./DokladkyDefault";
import {useHistoryCustom} from "../../../../raal_components/controller/NavigationHelper";

export type ModalDokladkaFilterExposed = {
    setModalVisibility: () => void
}

type ModalDokladkaFilterProps = {
    modalRef?: Ref<ModalDokladkaFilterExposed>
    dokladkaZadani: DokladkaZadani
    onSend?: () => void
    onClose?: () => void
    redirectAfterSend?: boolean
}

export function ModalDokladkaFilter(props:ModalDokladkaFilterProps) {
    const {t} = useTranslation();
    const {dokladkaZadani, modalRef} = props;
    const [zadaniOpen, setZadaniOpen] = useState(() => false);
    const storageKey = "DokladkaZadani"
    const [,storeZadani] = useDataStore(DokladkaZadani, storageKey, true, "session")
    const [,storeFilter] = useDataStore(DokladkaFilter, "DokladkaFilter", true, "session")
    const {replace} = useHistoryCustom();

    useImperativeHandle(modalRef, () => ({
        setModalVisibility
    }));

    const setModalVisibility = () => {
        setZadaniOpen(!zadaniOpen)
    }

    return (
        <MuiModal
            title={t("Dokladky.zadaniDokladky")}
            maxWidth={"md"}
            fullScreen={false}
            open={zadaniOpen}
            onClose={() => {
                setZadaniOpen(false);
                invoke(props.onClose)
            }}>
            {zadaniOpen ? <DokladkyZadaniForm
                dokladkaZadani={dokladkaZadani}
                dokladkaFilter={null}
                isModal={true}
                commit={(result, filter) => {
                    if(filter){
                        DataStorage.set(`DokladkaZadani-filter`, JSON.stringify(filter), true, 'session');
                    } else {
                        DataStorage.clear(`DokladkaZadani-filter`, true, 'session');
                    }

                    setZadaniOpen(false);
                    invoke(props.onClose)
                    result instanceof DokladkaFilter ? storeFilter(result) : storeZadani(result);
                    if (!props.redirectAfterSend) {
                        replace(`/dokladky`);
                        invoke(props.onSend)
                    } else {
                        DataStorage.redirectWithTargetBlank(`/dokladky`);
                        DataStorage.clear(storageKey, true, 'session');
                    }
                }}/> : null}
        </MuiModal>
    )
}
