import {httpEndpointCustom} from "./HttpUtils";
import {jsFileDownload} from "./js-file-download";
import { showSnack } from '../component/SnackContainer';

export async function downloadFile(url: string) {
    const result = await httpEndpointCustom(url, {
        method: "GET"
    }, undefined, undefined, true);

    if(!result.response.ok) {
        throw result;
    }
    const fileName = result.response.headers.get("Content-Disposition")?.split("=")[1].replaceAll("\"", "")
    jsFileDownload(result.json, fileName, result.response.headers.get("Content-Type"))
}

export async function downloadFileSimple(url: string, errorTranslator: (e: any) => string) {
	try {
		await downloadFile(url);
	} catch (e) {
		console.error(e);
		showSnack({ title: errorTranslator(e), severity: 'error' });
	}
}

export async function downloadUrl(url: string, mime: string, setStateAction: React.Dispatch<React.SetStateAction<string>>) {
	try {
		const result = await httpEndpointCustom(url, {
			method: 'GET',
		}, undefined, undefined, true);
		const blob = new Blob([result.json], { type: mime });
		const objectUrl = URL.createObjectURL(blob);
		setStateAction(objectUrl);
	} catch (error) {
		console.error('An error occurred:', error);
	}
}