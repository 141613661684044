interface AppConfig {
	backendUrl: string | null;
	backendClientId: string | null;
	tilesUrl: string | null;
	nominatimUrl: string | null;
	overpassUrl: string | null;
	logsUrl: string | null;
	geoEnabled: boolean | null;
	hereEnabled: boolean | null;
	environmentName: string | null;
	environmentColor: string | null;
}

export var config: AppConfig = {
	backendUrl: "",
	backendClientId: "cmFhbDpyYWFs",
	tilesUrl: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
	nominatimUrl: "https://nominatim.openstreetmap.org/",
	overpassUrl: "http://overpass-api.de/api/interpreter",
	logsUrl: "http://10.110.110.41:9000",
	geoEnabled: false,
	hereEnabled: false,
	environmentName: "",
	environmentColor: "",
};

export var defaultConfig: AppConfig = {
	backendUrl: "",
	backendClientId: "cmFhbDpyYWFs",
	tilesUrl: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
	nominatimUrl: "https://nominatim.openstreetmap.org/",
	overpassUrl: "http://overpass-api.de/api/interpreter",
	logsUrl: "http://10.110.110.41:9000",
	geoEnabled: false,
	hereEnabled: false,
	environmentName: "",
	environmentColor: "",
};

export function setConfig(conf: AppConfig) {
	config = conf;
}

export function getConfig() {
	return config;
}