import {IDClass} from "../../model/CommonTypes";
import {GenericMap} from "../../../index.d";
import {ListDetailHolder, Props} from "./CodeBookController.d";
import {default as React, Ref, useEffect} from "react";
import DataGrid, {DataGridExposed, DGExposed, useCRUDEditableActions} from "../grid/DataGrid";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import {DataGridLocationState, ToolbarAction} from "../grid/DataGrid.d";
import {useAppContext} from "../../context/AppContext";
import {AppActivitySubType} from "../../model/Aktivita";
import {evaluateBooleanOrFunction} from "../../../common/utils/Util";
import {getCurrentTabId} from "../../../common/utils/unque-tab-id";
import AddIcon from "@material-ui/icons/PostAdd";
import {invoke} from "../../../common/utils/Invoke";
import {useCodeBookControllerContext, useCodeBookControllerContextNew} from "./CodeBookController";
import {getDetailId} from "./NavigationHelper";

/**
 * Implementace data gridu s logikou pro nacitani detailu, je zde nacitani detailu ze serveru pro inline editaci (prop:getDetail, v inline editaci musi byt cely objekt, v seznamu ovsem byva jen cast objektu)
 * @param props
 * @constructor
 */
export function CodeBookGrid<Row extends IDClass, Filter = GenericMap, Detail extends IDClass = Row>(props:Props<Row, Filter, Detail>&{gridRef?:Ref<DataGridExposed<Row, Filter>>, dataGridRef?:Ref<DGExposed> }){
    const {isDetailMode, fetchDetail, showDetail, useCurrentPage, detailLocker, dataGridRef} = useCodeBookControllerContext<Detail, Row>();
    const crudOperations = useCRUDEditableActions<Row>({getModificationDate: props.getModificationDate ? (data) => props.getModificationDate(data as unknown as Detail) : undefined, removeEnabled:true, addEnabled:true, editEnabled:true, detailLocker: detailLocker, ...props.crudConfig});
    const {t} = useTranslation();
    const {state: locationState} = useLocation<DataGridLocationState>();
    const {checkDataChanged} = useAppContext();
    const getDetail = async(data:Row) => {
        const holder = data as unknown as ListDetailHolder<Detail>;
        if (!holder.detail) {
            holder.detail = await fetchDetail(AppActivitySubType.Row, getDetailId(props.config, data));
        }
        return holder.detail;
    };

    useEffect(() => {
        console.log("CodeBookGrid mounted");
    }, [])

    const setDetail = async (data:Row, origin:Row, merge:boolean) => {
        const allowUnlock = true /*exist(data.lockUserInfo) && data.lockUserInfo.login===user.login*/;
        const compareTabId = data.tabId === getCurrentTabId();
        showDetail(getDetailId(props.config, data), {origin, data, merge}, allowUnlock, compareTabId);
    };

    const getToolbarActions = (hideNewButton = false) => {
        const toolbarActions = []

        if(!hideNewButton){
            toolbarActions.push({
                icon: () => <AddIcon/>,
                tooltip: t("Buttons.AddNewRecord"),
                onClick: () => {
                    checkDataChanged(() => {
                        dataGridRef.current?.onDetailOpen();
                        showDetail("new")
                    });
                }
            });
        }

        if (props?.config?.alternativeButtons) {
            toolbarActions.push(...props?.config?.alternativeButtons)
        }

        return toolbarActions;
    }

    return !isDetailMode && (
        <DataGrid<Row, Filter>
            onMount={() => props.onGridMount&&props.onGridMount()}
            {...props.config}
            getDetail={(data)=>getDetail(data)}
            excludeFieldsForIntegerCheck={props.excludeFieldsForIntegerCheck}
            lockSupport={props.config?.lockSupport}
            layoutFilter={props.layoutFilter}
            filterClazz={props.filterClazz}
            tabDisabled={props.tabDisabled}
            pagingDisabled={props.pagingDisabled}
            onFilterChanged={props.onFilterChanged}
            createNewObject={Boolean(props.createInstance)?()=>(props.createInstance() as unknown as Row):null}
            extendedButtonClicked={props.extendedButtonClicked}
            dataGridRef={props.dataGridRef}
            dgRef={props.gridRef}
            lastBrowsedDateKey={props.lastBrowsedDateKey}
            showHideConnectedCols={props.showHideConnectedCols}
            customFilterHeaderButtons={props.customFilterHeaderButtons}
            onFocusedChildDataGridRow={() => invoke(props.onFocusedChildDataGridRow)}
            onBlurChildDataGridRow={() => invoke(props.onBlurChildDataGridRow)}
            useCurrentPage={useCurrentPage || locationState?.useCurrentPage}
            onRowClick={!evaluateBooleanOrFunction(props.config.disableRowClick, null) ?
                props.config.onRowClick ? (a, b, c)=> checkDataChanged(() =>props.config.onRowClick(a, b, c))
                    :
                    (a, b, c)=> !c ? checkDataChanged(() => setDetail(a, b, c)) : setDetail(a, b, c)
                :
                () => {}}
            editable={crudOperations}
            isDetailReadOnly={props.isDetailReadOnly}
            isDetailLock={props.isDetailLock}
            beforeSend={props.beforeSend}
            customReadOnlyTooltip={props.customReadOnlyTooltip}
            //@ts-ignore
            toolbarActions={getToolbarActions(props.config.hideAddNewActions)}
        />
    )
}

export function CodeBookGridNew<Row extends IDClass, Filter = GenericMap, Detail extends IDClass = Row>(props:Props<Row, Filter, Detail>&{gridRef?:Ref<DataGridExposed<Row, Filter>>, dataGridRef?:Ref<DGExposed> }){
    const {fetchDetail, useCurrentPage, detailLocker, dataGridRef, setDetail} = useCodeBookControllerContextNew<Detail, Row>();
    const crudOperations = useCRUDEditableActions<Row>({getModificationDate: props.getModificationDate ? (data) => props.getModificationDate(data as unknown as Detail) : undefined, removeEnabled:true, addEnabled:true, editEnabled:true, detailLocker: detailLocker, ...props.crudConfig});
    const {t} = useTranslation();
    const {state: locationState} = useLocation<DataGridLocationState>();
    const {checkDataChanged} = useAppContext();

    useEffect(() => {
        console.log("CodeBookGridNew mounted");
    }, [])

    const getDetail = async(data:Row) => {
        const holder = data as unknown as ListDetailHolder<Detail>;
        if (!holder.detail) {
            holder.detail = await fetchDetail(AppActivitySubType.Row, getDetailId(props.config, data));
        }
        return holder.detail;
    };

    const getToolbarActions = (hideNewButton = false) => {
        const toolbarActions = []

        if(!hideNewButton){
            toolbarActions.push({
                icon: () => <AddIcon/>,
                tooltip: t("Buttons.AddNewRecord"),
                onClick: () => {
                    checkDataChanged(() => {
                        dataGridRef.current?.onDetailOpen();
                        setDetail(getDetailId(props.config, null));
                    });
                }
            });
        }

        if (props?.config?.alternativeButtons) {
            toolbarActions.push(...props?.config?.alternativeButtons)
        }

        return toolbarActions;
    }

    return <DataGrid<Row, Filter>
        onMount={() => props.onGridMount&&props.onGridMount()}
        {...props.config}
        getDetail={(data)=>getDetail(data)}
        excludeFieldsForIntegerCheck={props.excludeFieldsForIntegerCheck}
        lockSupport={props.config?.lockSupport}
        layoutFilter={props.layoutFilter}
        filterClazz={props.filterClazz}
        tabDisabled={props.tabDisabled}
        pagingDisabled={props.pagingDisabled}
        onFilterChanged={props.onFilterChanged}
        createNewObject={Boolean(props.createInstance)?()=>(props.createInstance() as unknown as Row):null}
        extendedButtonClicked={props.extendedButtonClicked}
        dataGridRef={props.dataGridRef}
        dgRef={props.gridRef}
        lastBrowsedDateKey={props.lastBrowsedDateKey}
        showHideConnectedCols={props.showHideConnectedCols}
        customFilterHeaderButtons={props.customFilterHeaderButtons}
        onFocusedChildDataGridRow={() => invoke(props.onFocusedChildDataGridRow)}
        onBlurChildDataGridRow={() => invoke(props.onBlurChildDataGridRow)}
        useCurrentPage={useCurrentPage || locationState?.useCurrentPage}
        onRowClick={!evaluateBooleanOrFunction(props.config.disableRowClick, null) ?
            props.config.onRowClick ? (a, b, c)=> checkDataChanged(() =>props.config.onRowClick(a, b, c))
                :
                //@ts-ignore
                (a, b, c)=> !c ? checkDataChanged(() => setDetail(getDetailId(props.config, a), a, b, c)) : setDetail(getDetailId(props.config, a), a, b, c)
            //(a, b, c)=> !c ? checkDataChanged(() => setDetail(getId(a), a, b, c)) : setDetail(getId(a), a, b, c)
            :
            () => {}}
        editable={crudOperations}
        isDetailReadOnly={props.isDetailReadOnly}
        isDetailLock={props.isDetailLock}
        beforeSend={props.beforeSend}
        customReadOnlyTooltip={props.customReadOnlyTooltip}
        toolbarActions={getToolbarActions(props.config?.hideAddNewActions) as unknown as ToolbarAction[]}
    />
}
