import {DluhPripadStav} from "./Stiznosti";

export class TrikratADostCurrency {
    CZK: number
    EUR: number
    SKK: number
}

export class TrikratADostNadpripady {
    OTEVRENY: number
    UZAVRENY: number
    UZAVRENY_VYPOVED: number
}

export class TrikratADostPripad {
    stav: DluhPripadStav
    castka: number
    currencyCode: string
    pocetPripad: number
}

export class TrikratADost {
    celkemPripadu: number
    celkemCastky: TrikratADostCurrency
    nadpripady: TrikratADostNadpripady
    pripady: TrikratADostPripad[]
}
