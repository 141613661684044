import {useTranslation} from "react-i18next";
import {HttpResultSimple} from "./HttpUtils";

export class GeoError extends Error {
    // eslint-disable-next-line
    constructor(msg: string) {
        super(msg);
    }
}

export function useErrorTranslator(): (e: any) => string {
    const {t} = useTranslation();

    return (e: any) => {
        if(e instanceof HttpResultSimple) {
            switch (e.response.status) {
                case 404: return t("ServerErrors.404");
                case 500: return t("ServerErrors.500");
                default: return t("DialDefaults.ServerErrorMessage");
            }
        }
        if(e instanceof TypeError) {
            if(e.message==="Failed to fetch") {
                return t("ServerErrors.FailedToFetch");
            }
            if((e.message?.indexOf("NetworkError") ?? -1) > -1) {
                return t("ServerErrors.NetworkError");
            }
        }
        if(e instanceof DOMException) {
            const key = `DOMException.${e.name}`;
            const translated = t(key);
            if(translated!==key) {
                return translated;
            }
        }
        switch (e?.response?.status) {
            case 404: return t("ServerErrors.404");
            case 500: return t("ServerErrors.500");
        }
        if(typeof e === "string") {
            const translated = t(e);
            if(translated===e) {
                console.error("non translated error: ", e);
                return t("DialDefaults.ServerErrorMessage");
            } else
                return translated;
        } else {
            return t("DialDefaults.ServerErrorMessage");
        }
    }
}
