import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {FormFieldInterfaceProps} from "./FormFieldInterface";
import {createStyles, FormHelperText, TextFieldProps, Tooltip} from "@material-ui/core";
import {useDidMount, useMountedEffect} from "../hooks/SharedHooks";
import {TFunction} from "i18next";
import {makeStyles} from "@material-ui/core/styles";
import ReactQuill from "react-quill";

export interface FormTextareaProps extends FormFieldInterfaceProps<any> {
    textFieldProps?: TextFieldProps
    preventEmptyLines?: boolean
    customComponentOptions?: any | (()=>any);
    defaultValue?: string;
}

export const FormEditor = (props: FormTextareaProps) => {
    const [f, setFocus] = useState(false);
    const [editorValue, setEditorValue] = useState(props.value);
    const compRef = useRef<HTMLInputElement>(null);

    const useStyles = makeStyles((theme) =>
        createStyles({
            editor: {
                border: props.error ? "1px solid red" : f ? theme.palette.type === 'dark' ?
                    "2px solid #73b9ff" :
                    "2px solid #1976d2" :
                    theme.palette.type === 'dark' ?
                        "1px solid #666666" :
                        "1px solid #999999",
                borderRadius: 4,
                "&:hover": !props.error && {
                    border: !f ? theme.palette.type === 'dark' ?
                        "1px solid #fff" :
                        "1px solid #000" :
                        theme.palette.type === 'dark' ?
                            "2px solid #73b9ff" :
                            "2px solid #1976d2"
                },
            },
            legend: {
                color: props.error ? "red" : f ? theme.palette.type === 'dark' ? "#73b9ff" : "#1976d2" : theme.palette.type === 'dark' ? "#fff" : "#999999",
                fontSize: '9pt'
            },
            error: {
                color: 'red',
                marginLeft: 14
            }
        }),
    );

    const classes = useStyles();

    useDidMount(() => {
        if (props.defaultValue) setEditorValue(props.defaultValue)
        props.enableFocusSupport();
    })

    useMountedEffect(() => {
        setEditorValue(props.value);
    }, [props.value])

    useEffect(() => {
        if (props.focused) {
            compRef.current?.focus();
        }
    }, [props.focused])



    const onEditorValueChange = (data: any) => {
        setEditorValue(data);
        if(!props.disabled) props.onValueChanged(data);
    }

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['link', 'image', 'video'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        // @ts-ignore
        [{ 'color': [] }, { 'background': [] }], [{ 'font': [] }], [{ 'align': [] }],
        ['clean']
    ];
    return (
        <Tooltip title={props.dataTip||""} disableHoverListener={!props.dataTip} disableFocusListener={!props.dataTip} disableTouchListener={!props.dataTip}>
            <>
                <fieldset className={classes.editor}>
                    <legend className={classes.legend}>{props.title}</legend>
                    <ReactQuill
                        modules={{toolbar: toolbarOptions}}
                        theme="snow"
                        readOnly={props.disabled}
                        value={editorValue}
                        onChange={onEditorValueChange}
                        style={{backgroundColor: "white", color: "black"}}
                        onFocus={()=>{
                            if(!props.disabled){
                                setFocus(true);
                                props.onFocus&&props.onFocus(false);
                            }
                        }}
                        onBlur={()=>{
                            setFocus(false);
                        }}
                    />
                </fieldset>
                <FormHelperText className={classes.error}>{props.error}</FormHelperText>
            </>
        </Tooltip>
    );
}

const editorLabels = (t: TFunction) => {
    return {
        // Generic
        'generic.add': t('FormEditor.add'),
        'generic.cancel': t('FormEditor.cancel'),

        // BlockType
        'components.controls.blocktype.h1': t('FormEditor.h').replace('{0}',  '1'),
        'components.controls.blocktype.h2': t('FormEditor.h').replace('{0}',  '2'),
        'components.controls.blocktype.h3': t('FormEditor.h').replace('{0}',  '3'),
        'components.controls.blocktype.h4': t('FormEditor.h').replace('{0}',  '4'),
        'components.controls.blocktype.h5': t('FormEditor.h').replace('{0}',  '5'),
        'components.controls.blocktype.h6': t('FormEditor.h').replace('{0}',  '6'),
        'components.controls.blocktype.blockquote': t('FormEditor.blockquote'),
        'components.controls.blocktype.code': t('FormEditor.code'),
        'components.controls.blocktype.blocktype': t('FormEditor.blocktype'),
        'components.controls.blocktype.normal': t('FormEditor.normal'),

        // Color Picker
        'components.controls.colorpicker.colorpicker': t('FormEditor.colorpicker'),
        'components.controls.colorpicker.text': t('FormEditor.text'),
        'components.controls.colorpicker.background': t('FormEditor.background'),

        // Embedded
        'components.controls.embedded.embedded': t('FormEditor.embedded'),
        'components.controls.embedded.embeddedlink': t('FormEditor.embeddedlink'),
        'components.controls.embedded.enterlink': t('FormEditor.enterlink'),

        // Emoji
        'components.controls.emoji.emoji': t('FormEditor.emoji'),

        // FontFamily
        'components.controls.fontfamily.fontfamily': t('FormEditor.fontfamily'),

        // FontSize
        'components.controls.fontsize.fontsize': t('FormEditor.fontsize'),

        // History
        'components.controls.history.history': t('FormEditor.history'),
        'components.controls.history.undo': t('FormEditor.undo'),
        'components.controls.history.redo': t('FormEditor.redo'),

        // Image
        'components.controls.image.image': t('FormEditor.image'),
        'components.controls.image.fileUpload': t('FormEditor.fileUpload'),
        'components.controls.image.byURL': t('FormEditor.byURL'),
        'components.controls.image.dropFileText': t('FormEditor.dropFileText'),

        // Inline
        'components.controls.inline.bold': t('FormEditor.bold'),
        'components.controls.inline.italic': t('FormEditor.italic'),
        'components.controls.inline.underline': t('FormEditor.underline'),
        'components.controls.inline.strikethrough': t('FormEditor.strikethrough'),
        'components.controls.inline.monospace': t('FormEditor.monospace'),
        'components.controls.inline.superscript': t('FormEditor.superscript'),
        'components.controls.inline.subscript': t('FormEditor.subscript'),

        // Link
        'components.controls.link.linkTitle': t('FormEditor.linkTitle'),
        'components.controls.link.linkTarget': t('FormEditor.linkTarget'),
        'components.controls.link.linkTargetOption': t('FormEditor.linkTargetOption'),
        'components.controls.link.link': t('FormEditor.link'),
        'components.controls.link.unlink': t('FormEditor.unlink'),

        // List
        'components.controls.list.list': t('FormEditor.list'),
        'components.controls.list.unordered': t('FormEditor.unordered'),
        'components.controls.list.ordered': t('FormEditor.ordered'),
        'components.controls.list.indent': t('FormEditor.indent'),
        'components.controls.list.outdent': t('FormEditor.outdent'),

        // Remove
        'components.controls.remove.remove': t('FormEditor.remove'),

        // TextAlign
        'components.controls.textalign.textalign': t('FormEditor.textalign'),
        'components.controls.textalign.left': t('FormEditor.left'),
        'components.controls.textalign.center': t('FormEditor.center'),
        'components.controls.textalign.right': t('FormEditor.right'),
        'components.controls.textalign.justify': t('FormEditor.justify'),
    }
}
