import {Preprava} from "../../../../model/Preprava";
import {Vozidlo} from "../../../../model/Vozidlo";
import {Inzerat, InzeratViewDetail} from "../../../../model/Inzerat";
import {OfferDataType} from "../prohlizeni/OfferArchiveActionsPart";

export function isPreprava(offer: OfferDataType): offer is Preprava {
    return offer instanceof Preprava;
}

export function isVozidlo(offer: OfferDataType): offer is Vozidlo {
    return offer instanceof Vozidlo;
}

export function isInzeratViewDetail(offer: OfferDataType): offer is InzeratViewDetail {
    return offer instanceof InzeratViewDetail;
}

export function isInzerat(offer: OfferDataType): offer is Inzerat {
    return offer instanceof Inzerat;
}

export function isArray<T>(data: T | T[]): data is T[] {
    return Array.isArray(data);
}
