import {cleanName, FormFieldInterfaceProps} from "./FormFieldInterface";
import React, {MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import {InputAdornment, TextField, TextFieldProps, Tooltip} from "@material-ui/core";
import numeral, {NumeralJSLocale} from 'numeral';
import {useLocale} from "../../../web/context/LocaleContext";
import {clamp, exist, isNumber} from "../../utils/Util";
import DialpadIcon from "@material-ui/icons/Dialpad";
import {Getter, Setter} from "../../../index.d";
import {useFocusable, useFocusTypeDetection} from "./FocusHook";
import {useMountedEffect} from "../hooks/SharedHooks";
import {DynamicInputFontSize} from "../DynamicFontSize";

type NumberRange = {
    max:number,
    min:number,
}
export type NumberProps = {
    format: string,
    constraint?:{
        precision:number,
        scale:number,
        allowNegative?: boolean
    }
    range?: NumberRange,
    useDynamicFontSize?:boolean,
    selectDelay?: number
}
export const telefonFormat = "0,0";

export const JavaIntegerRange = {min:-2147483647, max:2147483647};

export interface FormNumberProps extends FormFieldInterfaceProps<number> {
    numberProps?: NumberProps,
    textFieldProps?: TextFieldProps,
    useDynamicFontSize?: boolean
}


function useCaret(dom:MutableRefObject<HTMLInputElement>):[Setter<number>, Getter<number>] {
    return [
        (caretPos:number) => {
            dom.current.setSelectionRange(caretPos, caretPos);
        },
        ()=> {
            return dom.current.selectionStart;
        }
    ];
}

function useNumeralFormatter(numberProps: NumberProps): [(value: any) => string, NumeralJSLocale] {
    const {locale} = useLocale();
    const data = numeral.localeData(locale);
    const normalize = (value:string)=>{
        if (typeof(value) === 'string' && (value.toLowerCase().indexOf('b') > -1 || value.toLowerCase().indexOf('t') > -1))
            value = value.slice(0, -1)
        const tempValue = numeral(value).value();
        let fl = tempValue && tempValue < 0 && !numberProps.constraint?.allowNegative ? -tempValue : tempValue;
        const oldNumberArray:string[] = `${fl}`.split(".");
        const newNumberArray:string[] = [];
        if(numberProps?.constraint) {
            const precision = oldNumberArray[0].length;
            const precisionOverflow = precision > numberProps.constraint.precision - numberProps.constraint.scale;
            let scaleOverflow = false;
            newNumberArray[0] = oldNumberArray[0].substr(0, numberProps.constraint.precision - numberProps.constraint.scale);

            if(oldNumberArray.length === 2) {
                const scale = oldNumberArray[1].length;
                scaleOverflow = scale > numberProps.constraint.scale;
                newNumberArray[1] = oldNumberArray[1].substr(0, numberProps.constraint.scale);
            }
            if(precisionOverflow || scaleOverflow) {
                fl = parseFloat(newNumberArray.join("."));
            }
        }
        if(numberProps?.range) {
            fl = clamp(fl, numberProps.range.min, numberProps.range.max);
        }
        return fl;
    };
    return [(value: any) => {
        if (exist(value)) {
            // Allow negative value
            if (numberProps?.constraint && numberProps.constraint.allowNegative && value.length === 1 && value === '-') return value;
            // Prevent non-numeric value
            if (value.length === 1 && !isNumber(value)) return null;
            // Allow comma separator
            if (value.length > 1 && value.slice(-1) === ',' && value.split(",").length - 1 === 1) return value;
            // Allow dot separator
            if (value.length > 1 && value.slice(-1) === '.' && value.split(".").length - 1 === 1) return value;
            // Allow 0 in decimals
            if (value.length > 2 && (value.includes(',') || value.includes('.')) && value.slice(-1) === '0') return value;
            // Normalize
            return numeral(normalize(value)).format(numberProps?.format);
        }
        return value;
    }, data];
}

export function FormNumber({numberProps, variant = "outlined", showAdornment=true, ...props}: FormNumberProps) {
    const [format, numeralData] = useNumeralFormatter(numberProps);
    const [formattedValue, setFormattedValue] = useState<string>(format(props.value));
    const [inputValue, setInputValue] = useState(formattedValue);
    const dom = useRef<HTMLInputElement>();
    const [setCaret, getCaret] = useCaret(dom);
    const {onMouseDown, onMouseUp, userClick} = useFocusTypeDetection();
    const inputRef = useRef<HTMLInputElement>();
    useFocusable(dom, props);
    useMountedEffect(()=>{
        setFormattedValue(format(props.value));
    }, [props.value]);

    useMountedEffect(() => {
        if(exist(formattedValue)) {
            props.onValueChanged && props.onValueChanged(
                formattedValue.slice(-1) === ',' || formattedValue.slice(-1) === '.' ||
                formattedValue.slice(-2) === ',0' || formattedValue.slice(-2) === '.0' ?
                    formattedValue
                    :
                    numeral(formattedValue)?.value());
        } else {
            props.onValueChanged && props.onValueChanged(null);
        }

    }, [formattedValue]);

    useMountedEffect(() => {
        if (inputValue?.indexOf(numeralData.delimiters.decimal) === inputValue?.length - 1) {
            //protoze jinak mi numeral odstrani desetinny delimitr a neni pak mozne napsat desetinna mista
            setFormattedValue(inputValue);
        } else if(numeralData.delimiters.decimal === ',' && inputValue?.indexOf('.') > 0 && inputValue?.indexOf(',') === -1) {
            // úprava hlavně pro softwarové klávesnice Samsung
            setFormattedValue(inputValue.replace('.', ','));
        } else {
            setFormattedValue(format(inputValue));
        }
    }, [inputValue]);

    const tProps:TextFieldProps = {...{variant:"outlined"}, ...props.textFieldProps};

    useEffect(() => {
       if (!exist(props.value) && exist(tProps?.inputProps?.defaultValue)) setFormattedValue(tProps?.inputProps?.defaultValue?.toString());
        // eslint-disable-next-line
    }, []);

    const checkValueOnBlur = useCallback(() => {
        if(exist(formattedValue) && (formattedValue.slice(-1) === ',' || formattedValue.slice(-1) === '.')) {
            setFormattedValue(formattedValue.slice(0, -1));
        }
        if(exist(formattedValue) && (formattedValue.slice(-2) === ',0' || formattedValue.slice(-2) === '.0')) {
            setFormattedValue(formattedValue.slice(0, -2));
        }
    }, [formattedValue, setFormattedValue])

    return (
        <>
            <Tooltip title={props.dataTip || ""} disableHoverListener={!props.dataTip}
                     disableFocusListener={!props.dataTip} disableTouchListener={!props.dataTip}>
                <TextField
                    {...tProps}
                    size={"small"}
                    inputRef={instance => dom.current = instance}
                    ref={inputRef}
                    id={cleanName(props.name)}
                    name={cleanName(props.name)}
                    type={"text"}
                    label={props.title}
                    value={formattedValue ?? ""}
                    disabled={props.disabled}
                    autoCapitalize={"off"}
                    autoComplete={"off"}
                    placeholder={props.placeholder}
                    onBlur={() => {
                        checkValueOnBlur();
                        props.onBlur&&props.onBlur();
                    }}
                    onChange={e => {
                        const caretPosition = getCaret();
                        setInputValue(e.target.value);
                        if(caretPosition < e.target.value?.length) {
                            setTimeout(()=> {
                                setCaret(caretPosition);
                            }, 0);
                        }
                    }}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    onFocus={e => {
                        if (numberProps?.selectDelay) {
                            setTimeout(() => dom.current.select(), numberProps.selectDelay);
                        } else {
                            dom.current.select()
                        }
                        props.onFocus&&props.onFocus(userClick.current);
                        setInputValue(e.target.value);
                    }}
                    onKeyDown={props.onKeyDown}
                    onKeyUp={props.onKeyUp}
                    fullWidth
                    error={typeof props.error !== 'undefined'}
                    helperText={props.error}
                    InputProps={showAdornment ? {
                        className: formattedValue ? props.inputClassName : "",
                        endAdornment: (
                            <InputAdornment position="end">
                                <DialpadIcon/>
                            </InputAdornment>
                        )
                    } : null}
                    inputProps={{inputMode:numberProps?.format?.includes(".")?"decimal":"numeric"}}
                />
            </Tooltip>
            {props.useDynamicFontSize&&<DynamicInputFontSize text={formattedValue} target={inputRef}/>}
        </>
    );
}
