import {Box, Button, Typography} from "@material-ui/core";
import * as React from "react";
import {useAppContext} from "../../web/context/AppContext";
import {useTranslation} from "react-i18next";

export function ConnectionError({reconnect}:{reconnect:()=>void}) {
    const {logout} = useAppContext();
    const {t} = useTranslation();
    return (
        <>
            <Box mb={5}>
                <Typography variant={"h5"}> {t("ConnectionError")}</Typography>
            </Box>
            <Box display="flex" flexDirection="row">
                <Box mr={1}>
                    <Button variant={"contained"} color={"default"} onClick={reconnect}>{t("TryConnectAgain")}</Button>
                </Box>
                <Box>
                    <Button variant={"contained"} color={"primary"} onClick={()=>logout(true)}>{t("Buttons.Logout")}</Button>
                </Box>
            </Box>
        </>
    );
}
