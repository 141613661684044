import * as React from "react";
import {useRef} from "react";
import {FormButton, FormDialog, FormField} from "../../../../raal_components/form/Form";
import {Dial} from "../../../../raal_components/dial/Dial";
import {useTranslation} from "react-i18next";
import {VozidlaList, Vozidlo} from "../../../../model/Vozidlo";
import {useAppContext} from "../../../../context/AppContext";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {SystemParameter, SystemParamKey} from "../../../../model/SystemParameter";
import {exportableFields, getFilterVersion, useValidation, useVPPdfLayout} from "../_vp/PrepravaAVozidlaShared";
import {VPFilter} from "../../../../model/PrepravaVozidlo";
import {useInputColumns} from "../_vp/VPColumns";
import {FilterForm} from "../_vp/VPFilter";
import {DetailFormNew} from "../_vp/VPDetailForm";
import {Grid} from "@material-ui/core";
import {globalStyles} from "../../../../context/ThemeModeContext";
import {InvalDuvodVozidlo} from "../../../../model/CommonTypes";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {useCiselnikValues, useData, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {exist, isNumber} from "../../../../../common/utils/Util";
import {getCurrentTabId} from "../../../../../common/utils/unque-tab-id";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {VOZIDLO_CRUD_ENDPOINT} from "../../../../../common/constants";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {useHashId} from "../../../../raal_components/controller/CodeBookController";
import {VolneVozyViewHistoryPartNew} from "../prohlizeni/VolneVozyViewHistoryPart";
import {ComponentMode} from "../../../../routes";
import {useNabidkaMultiCRUD} from "../_vp/NabidkaShared";
import {MultiColumnToolbarAction} from "../../../../raal_components/grid/DataGrid.d";

interface VlastniVozyCiselnikProps {
    autofocus?:boolean
    isTabbed?:boolean
    mode?:ComponentMode
    /**
     * Defines custom base path.
     */
    basePath?: string
    minimumTableHeight?: number
    cacheGroup?: string
}

const CISELNIK_DRUH = CiselnikTyp.R;
const PARAM_RANGE_KEY = SystemParamKey.VOZIDLA_DATE_RANGE;
const PARAM_ADVANCE_KEY = SystemParamKey.VOZIDLO_DATE_ADVANCE_MAX;
const PARAM_MAX_DRUHU_KEY = SystemParamKey.VOZIDLA_DRUHY_MAX;
const MAX_OFFER_EDITS = SystemParamKey.MAX_OFFER_EDITS;
const DEFAULT_RANGE_VALUE = 3;
const DEFAULT_ADVANCE_VALUE = 15;

/**
 * TODO - Přepsáno do New, ale uvnitř není stejná logika jako v ExtendedViewNew, bude třeba ještě projít a sloučit.
 * @param props
 * @constructor
 */
export function VlastniVozyCiselnik(props: VlastniVozyCiselnikProps = {autofocus: false}) {
    const {user} = useAppContext();
    const {t} = useTranslation();
    const realtimeErrorsDatOd = useRef<string[]>([]);
    const realtimeErrorsDatDo = useRef<string[]>([]);
    const {inlineValidation} = useValidation({preprava:false, realtimeErrorsOd:realtimeErrorsDatOd, realtimeErrorsDo:realtimeErrorsDatDo, maxDruhyKey:PARAM_MAX_DRUHU_KEY});
    const [showConfirm] = useConfirmDialog()
    const dialog: FormDialog = {
        body: t("Default.DeleteText"),
        title: t("Default.DeleteTitle"),
        buttons: { confirm: t("Buttons.Delete") }
    }
    const {pdfLayout} = useVPPdfLayout(user, false);
    const [createColumns] = useInputColumns({defaultRange:DEFAULT_RANGE_VALUE, typParametru: PARAM_RANGE_KEY, typCiselniku: CISELNIK_DRUH, realtimeErrorsDatOd, realtimeErrorsDatDo, preprava:false, typAdvance: PARAM_ADVANCE_KEY, defaultAdvance: DEFAULT_ADVANCE_VALUE, maxDruhyKey: PARAM_MAX_DRUHU_KEY});
    const dateKey = "vozy-insert-browse-date";
    const dtGrid = useRef<DataGridExposed<VozidlaList, VPFilter>>();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const [maxOfferEdits] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === MAX_OFFER_EDITS));
    const getEditCounter = (pocetEditaci?: number) => {
        if (!isNumber(maxOfferEdits.value)) return 0;
        return Math.max(Number.parseInt(maxOfferEdits.value) - (pocetEditaci??0),0 );
    }
    const editCounterDisabled = Number.parseInt(maxOfferEdits.value) < 0;
    const requiredFields = ["odkud", "kam", "odkudHelper", "kamHelper", "naves", "souprava", "jine", "delka", "vaha", "druhy", "datOd", "datDo"]
    const id = useHashId();
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.V);
    const nabidkaMultiCRUD = useNabidkaMultiCRUD<Vozidlo>({
        endpoint: VOZIDLO_CRUD_ENDPOINT,
        typParametru: PARAM_RANGE_KEY,
        defaultRange: DEFAULT_RANGE_VALUE ,
        typAdvance: PARAM_ADVANCE_KEY,
        defaultAdvance: DEFAULT_ADVANCE_VALUE
    });

    return <Dial<VozidlaList, VPFilter, Vozidlo>
        mode={props.mode}
        isTabbed={props.isTabbed}
        tabDetailUrl= {props.basePath ? props.basePath : undefined}
        isModal={!exist(id)}
        lastBrowsedDateKey={dateKey}
        getModificationDate={data => data.modifiedOn}
        focusFieldWhenMounted
        focusedFieldWhenMounted={exist(id) ? 'okoliOdkud' : null}
        excludeFieldsForDirtyCheck={["waypointy", "odkudTouched", "kamTouched", "datum", "profilVozidla", "currency", "offerId", "linkType" ]}
        config={{
            cache: {group: props.cacheGroup},
            getToolbarMultiActions: (showMultiAction: (state: boolean) => void) => {
                return [nabidkaMultiCRUD.getRefreshAction(showMultiAction), nabidkaMultiCRUD.getRemoveAction(showMultiAction)] as unknown as MultiColumnToolbarAction<any>[]
            },
            disableExtendedFilterButtons: true,
            minimumTableHeight: props.minimumTableHeight,
            lockSupport: {enabled: true, stompPath: "Vozidlo"},
            defaultHiddenColumns: ["jednotka", "uzivatel", "invalDuv", "modifiedOn", "palety", "verejnaPozn", "dispecer", "sirka", "vyska", "lozPlocha", "objem", "neverPozn"],
            templatesEnabled: true,
            exportConfig: {
                exportable: true,
                endpoint: VOZIDLO_CRUD_ENDPOINT,
                fileName: "export_volne_vozy",
                translationPrefix: ['Preprava', 'User'],
                formats: ["pdf", "xls", "csv", "xml"],
                exportableProps: exportableFields,
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod
                            }
                        }
                    }
                 ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "druhy",
                        format: (data) => {
                            return druhyJoined(data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalDuvodPreprava", InvalDuvodVozidlo, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            options: {
                rowStyle: (data: any) => {
                    if (exist(data.lockUserInfo) && (data.lockUserInfo.login !== user.login || data.tabId !== getCurrentTabId())) return globalStyles.rowStyleLocked;

                    if (data.invalDuv === InvalDuvodVozidlo.DUPLICITA) return globalStyles.rowStyleDuplicated;

                    if (data.invalDuv === InvalDuvodVozidlo.OK) return undefined;

                    return globalStyles.rowStyleAlert;
                }
            },
            autofocus: props.autofocus,
            stomp:{
                topic: `/provozovna/${user.provozovna.kod}/crud-vozidlo`,
                toggleable: true,
                allowStompUiUpdates: true
            },
            version:getFilterVersion(),
            watchChanges: true,
            tableTitle: t("Dials.VolneVozy"), endpoint: VOZIDLO_CRUD_ENDPOINT, clazz: VozidlaList, filtering: true,
            defaultQueryParameters: {"userid": user.id},
            validate: inlineValidation,
            hideAddNewActions: false,
            columns: createColumns(),
            rowFiltering:false,
            disableRowClick: data => data && (data.invalDuv===InvalDuvodVozidlo.DUPLICITA || data.invalDuv===InvalDuvodVozidlo.ZNEPLATNENO_OPERATOREM || (!editCounterDisabled && getEditCounter(data.pocetEditaci) < 1) || (exist(data.lockUserInfo) && (data.lockUserInfo!.login!==user.login || data.tabId!==getCurrentTabId())))
        }}
        gridRef={dtGrid}
        hideEditButton={true}
        crudConfig={{addEnabled:true, editEnabled:true, removeEnabled: true, refreshEnabled: true}}
        hideNewButtonOnEdit={true}
        clazzDetail={Vozidlo}
        modalHeaderName={() => t("Dials.VolneVozy")}
        filterClazz={VPFilter}
        beforeSend={(data) => {
            if (data?.dispecer && Number(data.dispecer.id) === user.id) data.dispecer = null;
        }}
        isDetailReadOnly={data => data && (data.invalDuv===InvalDuvodVozidlo.DUPLICITA || data.invalDuv===InvalDuvodVozidlo.ZNEPLATNENO_OPERATOREM || (exist(data.lockUserInfo) && (data.lockUserInfo!.login!==user.login || data.tabId!==getCurrentTabId())))}
        isDetailLock={data => data && (exist(data.lockUserInfo) && (data.lockUserInfo!.login!==user.login || data.tabId!==getCurrentTabId()))}
        formDisabled={(edited, data) => data && (data.invalDuv===InvalDuvodVozidlo.DUPLICITA || data.invalDuv===InvalDuvodVozidlo.ZNEPLATNENO_OPERATOREM)}
        createInstance={() => {
            const vozidlo = new Vozidlo();
            vozidlo.initialize();
            return vozidlo;
        }}
        extendedButtonClicked={(data: Vozidlo) => {
            data.initialize();
        }}
        hideCustomButtons={'None'}
        saveAndNextButton={!id}
        showHideConnectedCols={[{cols: ["currency", "cena"]}]}
        customButtons={(edited, data, changed, disabled, changedFields, origin) => edited &&
                [!disabled&&<FormButton
	                skipBlock
                    main={false}
                    type={"new"}
                    key={"createNew"}
                    enabledForRequiredFields={requiredFields}
                    // disabled={
                    //     changed &&
                    //     ((changedFields().includes("datOd") && origin?.datOd && data?.datOd && data.datOd.diff(origin.datOd, 'day') === 0) ||
                    //         (changedFields().includes("datDo") && origin?.datDo && data?.datDo && data.datDo.diff(origin.datDo, 'day') === 0)) &&
                    //     changedFields().filter(f => f !== 'datOd' && f !== 'datDo').length === 0
                    // }
                    onSend={() => {
                        return {
                            modifyUrl: (url: string) => `${url}/new`
                        };
                    }}>

                    {t("DialDefaults.CreateRecordAsNew")}
                </FormButton>,
                !disabled&&<FormButton key={"update"}
                            skipBlock
                            type={"update"}
                            main={true}
                            tooltip={!editCounterDisabled && getEditCounter(data.pocetEditaci) < 1 ?
                                t("PVI.tooltip.UpdateDisabledButton"):
                                t("PVI.tooltip.UpdateButton")
                            }
                            disabled={((changedFields().length > 1 || !changedFields().includes("neverPozn")) && (!editCounterDisabled && getEditCounter(data.pocetEditaci) < 1)) || (!changed && data.invalDuv !== InvalDuvodVozidlo.OK && data.invalDuv !== InvalDuvodVozidlo.UZIVATEL_POZASTAVEN)}
                            onSend={() => {
                                return {
                                    modifyUrl: (url: string) => `${url}/${data.id}`,
                                    skipValidation: !changed,
                                    requestInit: !changed ? {
                                        method: "PUT",
                                        headers: {'obnova': 'true'},
                                        body: null
                                    } : null
                                }
                            }}>
                    {!changed ?
                        editCounterDisabled ? t("PVI.Refresh") : t("PVI.RefreshRecord", {value: getEditCounter(data.pocetEditaci)}) :
                        (changedFields().length === 1 && changedFields().includes("neverPozn")) || editCounterDisabled ? t("DialDefaults.UpdateRecord") :
                                t("PVI.UpdateRecord", {value: getEditCounter(data.pocetEditaci)})}
                </FormButton>,
                <FormButton key={"delete"}
                            skipBlock
                            type={"remove"}
                            confirmation={{showDialog: showConfirm, dialog: dialog}}
                            onSendWithConfirmation={() =>{
                                return {
                                    modifyUrl: (url: string) => `${url}/${data.id}`,
                                    skipValidation: true,
                                    requestInit: {
                                        method: "DELETE",
                                        body: null
                                    }
                                }
                            }}>
                    {t("Buttons.Delete")}
                </FormButton>
            ]}
        layoutFilter={() => (
            <>
                <FilterForm {...{lastBrowseDateDataKey: dateKey, typCiselniku:CISELNIK_DRUH, preprava: false, defaultRange:DEFAULT_RANGE_VALUE, typParametru:PARAM_RANGE_KEY, typAdvance: PARAM_ADVANCE_KEY, defaultAdvance: DEFAULT_ADVANCE_VALUE}}/>
                <Grid container spacing={1} style={{marginTop:4}}>
                  <Grid container item xs={12} spacing={1}>
                      <Grid item lg={12} sm={12} xs={12}>
                          <FormField title={t("Preprava.neverPozn")} name={"neverPozn"} type={"text"}/>
                      </Grid>
                  </Grid>
                </Grid>
            </>
        )}
        tabs={[{
            title: t("Preprava.History"),
            render: ()=><TabHelperNew<Vozidlo> render={(data, edited) => edited&&<VolneVozyViewHistoryPartNew vozidlo={data} zadani mode={ComponentMode.GridMode}/>}/>,
            disabled: (data, edited) => !edited
        }]}
        layoutForm={() => <DetailFormNew<Vozidlo> {...{typParametru: PARAM_RANGE_KEY, typCiselniku: CISELNIK_DRUH, preprava:false, defaultRange:DEFAULT_RANGE_VALUE, maxDruhyKey:PARAM_MAX_DRUHU_KEY, advanceParam: PARAM_ADVANCE_KEY, defaultAdvanceValue: DEFAULT_ADVANCE_VALUE}}/>}
    />;
}
