

export class Stat {

    id: number;

    mpz: string;

    // "nazevCz"
    nazevCz: string;

    // "nazevDe"
    nazevDe: string;

    // "nazevEn"
    nazevEn: string;

    delkaPsc: number;

    // "predvolba"
    predvolba: string;

    // "countryCode"
    countryCode: string;

    enabled: boolean;
}