import {Token} from "./model/Token";
import DataStorage from "../common/DataStorage";
import {Mapper} from "../common/utils/objectmapper/Mapper";

const key = "token";
const str = "session"
export class TokenStore {


    mapper = new Mapper<Token>({constructor:Token});

    clear() {
        DataStorage.clear(key, false, str);
    }

    reset() {
        DataStorage.reset(key, false, str);
    }

    get():Token {
        const d = DataStorage.get(key, false, str);
        if(d && d !== 'null'){
            return this.mapper.readValue(atob(d));
        }
        return null;
    }

    getToken(): string {
        return DataStorage.get(key, false, str);
    }

    set(token:Token) {
        DataStorage.set(key, btoa(this.mapper.writeValueAsString(token)),false, str);
    }

    setToken(token: string) {
        DataStorage.set(key, token, false, str);
    }
}
export default new TokenStore();