import {exist} from "../../utils/Util";
import {GenericMap} from "../../../index.d";
import {Moment} from "moment";
import {SystemParamKey} from "../../../web/model/SystemParameter";
import React from "react";

export type OptionsType<OptionType extends GenericMap> = ReadonlyArray<OptionType>;
export type OptionType = any;
export type OptionTypes = OptionType|OptionsType<OptionType>;

export type SelectOptionType<T = any> = {label:any, value:T, group?:any, color?: string};
interface AjaxOptions<T> {
    url:string;
    searchKey?:string;
    pageKey?:string;
    params?:GenericMap<string>;
    clazz?:{new():T};
    paginable?:boolean;
}
export class SelectProps<T = any> {
    options?:Array<OptionType>;
    formatOption?(value:OptionType):SelectOptionType<T>;
    formatValue?(value:SelectOptionType<T>):OptionType;
    labelKey?:string = null;
    valueKey?:string = null;
    isMulti?:boolean = false;
    ajax?:AjaxOptions<T> | ((row?: any) => AjaxOptions<T>);
    isClearable?:boolean;
    sort?:(options:T[])=>T[];
    getOptionSelected?:(option: SelectOptionType<T>, value: SelectOptionType<T>) => boolean;
    noDynamicFontSize?:boolean;
    maxValuesCount?: number;
    autoOpenDisabled?: boolean;
    computedTextFieldForMultiSelect?: boolean;
    selectAll?:boolean;
    inputMode?: 'decimal' | 'search' | 'text' | 'numeric';
    inputType?: 'text' | 'number' | 'date' | 'phone';
    cacheOptions?: boolean;
    minWidth?: number;
    inputStyle?: (value:OptionType) => {};
    doNotFilterByLabel?: boolean;
    formatExcludeList?: (options:T[])=>any
    customOptionStyle?: (option: SelectOptionType<T>) => React.CSSProperties
    autoSelectLastValueOnError?: boolean
}
// eslint-disable-next-line
export class SelectDateProps<T = any> extends SelectProps {
    dateType?:string;
    startDate?:Moment;
    typAdvance?: SystemParamKey;
    defaultAdvanceValue?: number;
}

export const formatValue = (value:OptionType, props:SelectProps<any>):SelectOptionType|Array<SelectOptionType> => {
    if(!exist(value)) {
        if(props.isMulti) {
            return [];
        }
        return null;
    }
    if(Array.isArray(value)) {
        return formatOptions(value, props);
    }
    return formatOption(value, props);
};

export const formatOptions = (array:Array<OptionType>, props:SelectProps<any>):Array<SelectOptionType> => {
    const newArray = new Array<SelectOptionType>();
    array&&array.forEach(item=>newArray.push(formatOption(item, props)));
    return newArray;
};

export const formatOption = (value:OptionType, props:SelectProps<any>):SelectOptionType => {
    if(props.formatOption){
        return props.formatOption(value);
    }
    return {
        value: props.valueKey ? value[props.valueKey] : value,
        label: props.labelKey ? value[props.labelKey] : value
    };
};
