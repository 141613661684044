import * as React from "react";
import {useRef, useState} from "react";

import {useTranslation} from "react-i18next";

import {Divider, Grid, Typography} from "@material-ui/core";
import {useStatSelectAsAutocomplete, useYesNoSelect} from "../../../raal_components/SelectOptions";
import {FormButton, FormField, FormInputType, useFormData} from "../../../raal_components/form/Form";
import {Firma, FirmaFilter} from "../../../model/Firma";
import {useStyleContext} from "../../../context/ThemeModeContext";
import {FormLinkButton} from "../../../../common/component/form/FormLinkButton";
import {ToUpperCase} from "../../../../common/component/form/FormInput";
import {getRejstrikLinks} from "../../../../common/utils/LinkUtils";
import {dispatchModal, ModalActionType} from "../../../../common/component/ModalContainer";
import {RejstrikLinksComponent} from "../../../raal_components/parts/RejstrikLinksComponent";
import {FormMap, FormMapOptions} from "../../../../common/component/form/FormMap";
import {mapDataConverterPoint} from "../../../raal_components/SharedConfig";
import {Dial} from "../../../raal_components/dial/Dial";
import {ProvozovnaCiselnikZFirmy} from "./Taby/ProvozovnaCiselnikZFirmy";
import {UzivatelCiselnikTabbed} from "./Taby/UzivatelCiselnikTabbed";
import {useLocation} from 'react-router';
import * as Endpoint from "../../../raal_components/Endpoints";
import {CheckMark} from "../../../../common/component/CheckMark";
import {CodeBookControllerExposed} from "../../../raal_components/controller/CodeBookController.d";
import {FormDateRange, FormDateRangeOptions} from "../../../../common/component/form/FormDateRange";
import {TrasaFormViewType} from "../user/kilometrovnik/TrasaForm";
import {GoogleMapsGeometryButton} from "../../../raal_components/parts/GoogleMapsComponents";
import {SeznamGeometryButton} from "../../../raal_components/parts/SeznamComponents";
import {LatLng} from "leaflet";
import {exist} from "../../../../common/utils/Util";
import {Link, useParams} from "react-router-dom";
import {Column} from "../../../raal_components/grid/DataGrid.d";
import moment from "moment";
import {UserRole} from "../../../model/User";
import {ComponentMode, RouteComponentProps} from "../../../routes";
import {IDClass} from "../../../model/CommonTypes";
import {useHistoryCustom} from "../../../raal_components/controller/NavigationHelper";
import {FieldError} from "../../../../common/component/form/ValidationError";
import {GenericMap} from "../../../../index.d";

export function FirmaFilterForm() {
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const statSelectAutocompleteFilter = useStatSelectAsAutocomplete({isClearable:true});
    const {t} = useTranslation();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"ico"} title={t("Firma.ico")} type={"text"} textFieldProps={{inputProps: {maxLength: 15}}} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"dic"} title={t("Firma.dic")} type={"text"} textFieldProps={{inputProps: {maxLength: 15}}} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"dic1"} title={t("Firma.dic1")} type={"text"} textFieldProps={{inputProps: {maxLength: 15}}} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"gdpr"} title={t("Firma.gdpr")} type={"select"} selectProps={yesNoSelect} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"http"} title={t("Firma.http")} type={"text"} textFieldProps={{inputProps: {maxLength: 50}}}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={8}>
                <FormField name={"fPoznamka"} title={t("Firma.fPoznamka")} type={"text"}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"r1Nazev"} title={t("Firma.R1Nazev")} type={"text"} textFieldProps={{inputProps: {maxLength: 120}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"r1Ulice"} title={t("Firma.R1Ulice")} type={"text"} textFieldProps={{inputProps: {maxLength: 60}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"r1CisloPopisne"} title={t("Firma.R1CisloPopisne")} type={"text"} textFieldProps={{inputProps: {maxLength: 10}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"r1Obec"} title={t("Firma.R1Obec")} type={"text"} textFieldProps={{inputProps: {maxLength: 40}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"r1CastObce"} title={t("Firma.R1CastObce")} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"r1Psc"} title={t("Firma.R1Psc")} type={"text"} textFieldProps={{inputProps: {maxLength: 10}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"r1Stat"} title={t("Firma.R1Stat")} type={"select"} selectProps={statSelectAutocompleteFilter}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"nezadouciZakaznik"} title={t("Firma.NezadouciZakaznik")} type={"select"} selectProps={yesNoSelect}  />
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Firma.RegDatum")} name={"regDatumRange"} type={"Custom"} customComponent={FormDateRange} customComponentOptions={{spacing: 1, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Firma.datSmlouvy")} name={"datSmlouvyRange"} type={"Custom"} customComponent={FormDateRange} customComponentOptions={{spacing: 1, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Firma.datPojist")} name={"datPojistRange"} type={"Custom"} customComponent={FormDateRange} customComponentOptions={{spacing: 1, timeFormat: false}}/>
            </Grid>
        </Grid>
    );
}

export function FirmaLayoutForm() {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const statSelect = useStatSelectAsAutocomplete();
    const data = useFormData<Firma>();
    const [f, setF] = useState(false);
    return (
        <>
            <Grid container spacing={2}>
                <Divider className={classes.hrClass} style={{marginBottom: 0, marginTop: 0}}/>
                <Grid item xs={12} sm={12} lg={12}>
                    <Typography className={classes.sectionLabelUser} color="textSecondary" display="block" variant="caption">{t("Firma.UserIdentityLabel")}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField forceUpdateWhenDataChanged={["link"]} name={"ico"} title={t("Firma.ico")} type={"text"} textFieldProps={{inputProps: {maxLength: 15}}} serverValidation={{translate:t}}  wrappingFunction={ToUpperCase}
                               validate={(data) => {
                                   if (!data.ico && !data.dic && !data.dic1) {
                                       return [FieldError.Create(t("javax.validation.firma.requiredIdFields"), false, "ico")];
                                   }
                                   return null;
                               }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField forceUpdateWhenDataChanged={["link"]} name={"dic"} title={t("Firma.dic")} type={"text"}  textFieldProps={{inputProps: {maxLength: 15}}} serverValidation={{translate:t}}  wrappingFunction={ToUpperCase}
                               validate={(data) => {
                                   if (!data.ico && !data.dic && !data.dic1) {
                                       return [FieldError.Create(t("javax.validation.firma.requiredIdFields"), false, "dic")];
                                   }
                                   return null;
                               }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField name={"dic1"} title={t("Firma.dic1")} type={"text"} serverValidation={{translate:t}}  wrappingFunction={ToUpperCase}
                               validate={(data) => {
                                   if (!data.ico && !data.dic && !data.dic1) {
                                       return [FieldError.Create(t("javax.validation.firma.requiredIdFields"), false, "dic1")];
                                   }
                                   return null;
                               }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField name={"datSmlouvy"} title={t("Firma.datSmlouvy")} type={"datetime"}  dateTimeOptions={{timeFormat: false}} />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField name={"http"} title={t("Firma.http")} type={"text"} textFieldProps={{inputProps: {maxLength: 50}}}/>
                </Grid>
                <Grid item xs={12} sm={3} lg={2}>
                    <FormField name={"gdpr"} title={t("Firma.gdpr")} type={"checkbox"} />
                </Grid>
                <Grid item xs={12} sm={3} lg={2}>
                    <FormField<any, Firma> name="link" type={FormInputType.Custom} title={t("Buttons.Rejstriky")} dataTip={t("Firma.TooltipRejstrikyVeFirme")}
                                           customComponent={FormLinkButton}
                                           customComponentOptions={
                                               {
                                                   disabled: (data: Firma) => getRejstrikLinks(data.ico, data.dic, data.r1Stat).length===0,
                                                   onClick: (data: Firma) => {
                                                       const links = getRejstrikLinks(data.ico, data.dic, data.r1Stat);
                                                       dispatchModal({type:ModalActionType.Show, title:t("Buttons.Rejstriky"), body:(
                                                               <RejstrikLinksComponent links={links}/>
                                                           )})
                                                   }
                                               }
                                           }
                                           getValue={(data) => data}
                    />
                </Grid>
                <Divider className={classes.hrClass} style={{marginBottom: 0}}/>
                <Grid item xs={12} sm={12} lg={12}>
                    <Typography className={classes.sectionLabelUser} color="textSecondary" display="block" variant="caption">{t("Firma.UserContactLabel")}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name={"r1Nazev"} title={t("Firma.R1Nazev")} type={"text"}  required textFieldProps={{inputProps: {maxLength: 120}}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name={"regDatum"} title={t("Firma.RegDatum")} type={"datetime"}  dateTimeOptions={{timeFormat: false}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name={"r1Ulice"} title={t("Firma.R1Ulice")} type={"text"} textFieldProps={{inputProps: {maxLength: 60}}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name={"r1CisloPopisne"} title={t("Firma.R1CisloPopisne")} type={"text"} textFieldProps={{inputProps: {maxLength: 10}}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name={"r1Obec"} title={t("Firma.R1Obec")} type={"text"} textFieldProps={{inputProps: {maxLength: 40}}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name={"r1CastObce"} title={t("Firma.R1CastObce")} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name={"r1Psc"} title={t("Firma.R1Psc")} type={"text"} textFieldProps={{inputProps: {maxLength: 10}}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField forceUpdateWhenDataChanged={["link"]} name={"r1Stat"} title={t("Firma.R1Stat")} type={"select"} required selectProps={statSelect} textFieldProps={{inputProps: {maxLength: 3}}} serverValidation={{translate:t}}/>
                </Grid>
                <Divider className={classes.hrClass} style={{marginBottom: 0}}/>
                <Grid item xs={12} sm={12} lg={12}>
                    <Typography className={classes.sectionLabelUser} color="textSecondary" display="block" variant="caption">{t("Firma.UserNextLabel")}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <FormField name={"fPoznamka"} title={t("Firma.fPoznamka")} type={"textarea"} textFieldProps={{inputProps: {maxLength: 510}}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name={"pojisteni"} title={t("Firma.Pojisteni")} type={"text"} textFieldProps={{inputProps: {maxLength: 13}}} disabled />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name={"datPojist"} title={t("Firma.datPojist")} type={"datetime"}  dateTimeOptions={{timeFormat: false}} />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name={"zmenaRejstrikDatum"} title={t("Firma.DatumZmenyRejstrik")} type={"datetime"} disabled />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField name={"poznamkaRejstrik"} title={t("Firma.PoznamkaRejstrik")} type={"text"} disabled />
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField name={"nadpripad"} title={t("Firma.Nadpripad")} type={"checkbox"} disabled />
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField name={"nezadouciZakaznik"} title={t("Firma.NezadouciZakaznik")} type={"checkbox"}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField<FormMapOptions>
                        name={"misto"}
                        title={t("Default.MapDefine")}
                        type={FormInputType.Custom}
                        customComponent={FormMap}
                        showIcon={false}
                        onChanged={() => setF(!f)}
                        customComponentOptions={{
                            latLng: data?.misto ? new LatLng(data?.misto.getLatLng().lat, data?.misto.getLatLng().lng) : null,
                            zoom: data?.misto ? 18 : null,
                            maxPoints: 1,
                            enableOSRM: true,
                            converters: mapDataConverterPoint,
                            viewType: TrasaFormViewType.POINT}}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    {<GoogleMapsGeometryButton gps={data?.misto}/>}
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    {<SeznamGeometryButton gps={data?.misto}/>}
                </Grid>
            </Grid>
        </>
    );
}

type FirmaCiselnikProps = {
    historyTab?: boolean
    url?: string
    forceHistoryView?: boolean
    mode?: ComponentMode
}


export function FirmaHistoryCiselnik(props: RouteComponentProps) {
    return <FirmaCiselnik url={Endpoint.admin_firma_history} mode={props.mode} forceHistoryView/>
}

export function FirmaCiselnik(props: FirmaCiselnikProps) {
    const {t} = useTranslation();
    const dialRef = useRef<CodeBookControllerExposed>();
    const {replace} = useHistoryCustom();
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const {pathname} = useLocation();
    const isHistory = props.forceHistoryView || props.historyTab;
    const {id} = useParams<IDClass>();
    const defaultQueryParameters : GenericMap = props.historyTab ? {
        parentId: id
    } : undefined;

    return <Dial<Firma, FirmaFilter>
        mode={props.mode}
        dialRef={dialRef}
        localization={{CreateRecord: t("Firma.VytvoritFirmu")}}
        config={{
            clazz: Firma,
            tableTitle: t("Dials.Companies"),
            endpoint: props.url ?? Endpoint.admin_firma,
            hideAddNewActions: isHistory,
            filtering: true,
            defaultQueryParameters: defaultQueryParameters,
            exportConfig: {
                exportable: true,
                exportAll: true,
                endpoint: props.url ?? Endpoint.admin_firma,
                fileName: "export_firmy",
                translationPrefix: 'Firma',
                formats: ["csv"],
                exportableProps: [
                    {
                        type: ["csv"],
                        fields: ["id", "ico", "dic", "dic1", "r1Nazev", "r1Ulice", "r1CisloPopisne", "r1Obec", "r1Psc", "r1Stat", "datSmlouvy", "regDatum", "http", "gdpr", "pojisteni", "datPojist", "nezadouciZakaznik", "fPoznamka"]
                    }
                ],
                formattedProps: [
                    {
                        type: ["csv"],
                        field: "regDatum",
                        format: (data) => {
                            return data ? moment(data).format('DD.MM.YY'): null;
                        }
                    },
                    {
                        type: ["csv"],
                        field: "datPojist",
                        format: (data) => {
                            return data ? moment(data).format('DD.MM.YY'): null;
                        }
                    },
                    {
                        type: ["csv"],
                        field: "datSmlouvy",
                        format: (data) => {
                            return data ? moment(data).format('DD.MM.YY'): null;
                        }
                    },
                    {
                        type: ["csv"],
                        field: "datZkus",
                        format: (data) => {
                            return data ? moment(data).format('DD.MM.YY'): null;
                        }
                    },
                    {
                        type: ["csv"],
                        field: "poznamky",
                        format: (data) => {
                            return data ? data.join(', ') : null;
                        }
                    },
                ],
                defaultQueryParameters: defaultQueryParameters
            },
            columns: [
                !props.historyTab && isHistory ? {
                    title: t("Firma.Id"),
                    field: 'parentId',
                    filterProps: () => ({type: FormInputType.Number, numberProps: {format: '0.[00]'}}),
                    render: (data: Firma) => data.firmaId ? <Link to={{
                        pathname: `/admin/firmy/${data.firmaId}`,
                        state: {
                            forcePreviousPage: pathname
                        }
                    }
                    } onClick={(e) => {e.stopPropagation();}}>{data.firmaId}</Link> : data.firmaId,
                } : undefined,
                {
                    title: t("Firma.R1Nazev"),
                    field: 'r1Nazev',
                    defaultSort: "asc",
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Firma.ico"),
                    field: 'ico',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Firma.dic"),
                    field: 'dic',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Firma.dic1"),
                    field: 'dic1',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Firma.R1Ulice"),
                    field: 'r1Ulice',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Firma.R1CisloPopisneKratke"),
                    field: 'r1CisloPopisne',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Firma.R1Psc"),
                    field: 'r1Psc',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Firma.R1Obec"),
                    field: 'r1Obec',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Firma.R1Stat"),
                    field: 'r1Stat',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Firma.NezadouciZakaznikShort"),
                    field: 'nezadouciZakaznik',
                    render:(data:Firma)=> <CheckMark checked={data.nezadouciZakaznik}/>,
                    filterProps: () => ({type: FormInputType.Select, selectProps:yesNoSelect}),
                }
            ].filter(i => exist(i)) as Column<Firma>[]
        }}
        customButtons={(edited) => !edited && [<FormButton key={"focusProvozovna"} type={"focusProvozovna"} onSend={() => {
            return {
                modifyUrl: (url: string) => `${url}/new`
            };
        }}>{t("Firma.CreateFirmaAndProvozovna")}</FormButton>]}
        onSuccess={(firma, event) => {
            if (event.type === "focusProvozovna") {
                replace(`/admin/provozovny/new`, {tabDetailData: {firma: firma?.id, lockFirma: true}});
                return true;
            }
        }}
        crudConfig={{editEnabled: true, removeEnabled: false, addEnabled: false}}
        filterClazz={FirmaFilter}
        modalHeaderName={() => t("Default.ModalZakladni")}
        hideNewButtonOnEdit={true}
        layoutFilter={() => <FirmaFilterForm/>}
        layoutForm={() => <FirmaLayoutForm />}
        reloadData={true}
        tabs={isHistory ? undefined : [
			{
				title: t("CommonGrid.History"),
				countEndPoint: "admin/firma-history",
				params: (data) => {return {parentId: data?.id}},
				render:()=><FirmaCiselnik mode={ComponentMode.GridMode} historyTab url={Endpoint.admin_firma_history}/>,
				disabled: (data, edited) => !edited
			},
            {
                title: t("Firma.TabProvozovny"),
                countEndPoint: "admin/provozovna",
                params: (data) => {return {firma: data?.id}},
                render: () => <ProvozovnaCiselnikZFirmy adminView mode={ComponentMode.GridMode} />,
                disabled: (data, edited) => !edited
            },
            {
                title: t("Firma.TabUzivatele"),
                countEndPoint: "admin/user",
                params: (data) => {return {firma: data?.id}},
                countFce: (data: any) => {
                    if (data?.list?.length !== 0) {
                        const users = data.list.filter((d: any) => d.roles && d.roles.indexOf(UserRole.ROLE_USER) > -1).length;
                        return users.toString();
                    }
                    return '0';
                },
                render: ()=><UzivatelCiselnikTabbed adminView defaultQueryParameters={{"firma": id}} mode={ComponentMode.GridMode} />,
                disabled: (data, edited) => !edited
            }
        ]}
        isDetailReadOnly={isHistory}
        formDisabled={() => isHistory}
        tabDetailUrl={props.historyTab ? '/admin/historie/firmy' : null }
    />
}
