import {Firma} from "./Firma";
import {Geometry} from "./Geometry";
import {BasicUserData, LicenseType, Region} from "./CommonTypes";
import {JsonIgnore, JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {MomentConverters} from "./Converters";
import {Moment} from "moment";
import {Klasifikace} from "./Klasifikace";
import {exist} from "../../common/utils/Util";
import {OznaceniTyp} from "../page/Dials/user/Oznaceni";

export enum InvoiceFrequency {
    MONTHLY, QUARTERLY, HALFYEARLY, YEARLY
}

export enum Stav {
    JEDE, POZASTAVIL
}

export enum Podstav {
    BEZ_PODSTAVU, X3_A_DOST, PLATBY, POZASTAVEN_VLASTNI_ZADOST, PODEZRELY, PORUSENI_SMLOUVY, VYPOVED, PROBLEM, ORIGINAL
}

export enum SmlouvaS {
    NONE="NONE",
    FOHK="FOHK",
    FONITRA="FONITRA",
    RAALHK="RAALHK",
    RAALNITRA="RAALNITRA",
    RAALHKNOVA="RAALHKNOVA",
    FONITRANOVA="FONITRANOVA",
    FOHKNOVA="FOHKNOVA",
    RAAL_AS_2023="RAAL_AS_2023",
    RAAL_AS_2024="RAAL_AS_2024",
    RAAL_SRO_2023="RAAL_SRO_2023",
    RAAL_SRO_2024="RAAL_SRO_2024"
}

export class Provozovna {

    id:string;

    @JsonProperty({type:{enum: Region}})
    typ: Region;

    fax: string;

    email: string;

    @JsonProperty({type:{clazz:Geometry}})
    koordinat: Geometry;

    kod: string;

    @JsonProperty({type:{isArray:true}})
    emaily: string[];

    @JsonProperty({type:{enum:InvoiceFrequency}})
    frekvenceFaktur: InvoiceFrequency;

    @JsonProperty({loadName: ["pnazev", "pNazev"]})
    pNazev: string;

    @JsonProperty({loadName: ["pulice", "pUlice"]})
    pUlice: string;

    @JsonProperty({loadName: ["pcisloPopisne", "pCisloPopisne"]})
    pCisloPopisne: string;

    @JsonProperty({loadName: ["pobec", "pObec"]})
    pObec: string;

    @JsonProperty({loadName: ["pcastObce", "pCastObce"]})
    pCastObce: string;

    @JsonProperty({loadName: ["ppsc", "pPsc"]})
    pPsc: string;

    @JsonProperty({loadName: ["pstat", "pStat"]})
    pStat: string;

    @JsonProperty({loadName: ["knazev", "kNazev"]})
    kNazev: string;

    @JsonProperty({loadName: ["kadresat", "kAdresat"]})
    kAdresat: string;

    @JsonProperty({loadName: ["kulice", "kUlice"]})
    kUlice: string;

    @JsonProperty({loadName: ["kcisloPopisne", "kCisloPopisne"]})
    kCisloPopisne: string;

    @JsonProperty({loadName: ["kobec", "kObec"]})
    kObec: string;

    @JsonProperty({loadName: ["kcastObce", "kCastObce"]})
    kCastObce: string;

    @JsonProperty({loadName: ["kpsc", "kPsc"]})
    kPsc: string;

    @JsonProperty({loadName: ["kstat", "kStat"]})
    kStat: string;

    @JsonProperty({type:{clazz:Firma}})
    firma: Firma;

    @JsonProperty({type:{enum: Stav}})
    stav: Stav;

    @JsonProperty({type:{enum: Podstav}})
    podstav: Podstav;

    @JsonProperty({type:{isArray:true}})
    telCislaKontaktu: string[];

    @JsonProperty({converters: MomentConverters})
    @JsonIgnore({ignoreSet: true, ignoreGet:false})
    poslPripoj: Moment;

    @JsonIgnore({ignoreSet: true, ignoreGet:false})
    pocPripoj: number;

    @JsonProperty({converters: MomentConverters})
    zalozen: Moment;

    @JsonProperty({converters: MomentConverters})
    datzmenstav: Moment;

    spStat: string;

    jazyky: string;

    auta: string;

    not1: string;

    poznamka?: string;

    potlacInz: boolean;

    potlacPrepravy: boolean;

    potlacVv: boolean;

    jine: boolean;

    raalJmeno1: string;

    raalJmeno2: string;

    raalJmeno3: string;

    @JsonProperty({type:{clazz:BasicUserData}})
    kdoZmenil: BasicUserData;

    @JsonProperty({converters: MomentConverters})
    kdyZmena: Moment;

    bKdoZmenil: boolean;

    @JsonProperty({converters: MomentConverters})
    potlacInzDatum: Moment;

    @JsonProperty({converters: MomentConverters})
    potlacPrepravyDatum: Moment;

    @JsonProperty({converters: MomentConverters})
    potlacVvDatum: Moment;

    zasilatReport: boolean;

    enabled: boolean;

    @JsonProperty({converters:MomentConverters})
    datSmlouvyZmena: Moment;

    @JsonProperty({type:{isArray:true}})
    emailProfa: string[];

    @JsonProperty({type:{isArray:true}})
    smsUpominky: string[];

    posta: boolean;

    nezobrazovat: boolean;

    omezeneInformace: boolean;

    pozastavena?: boolean;

    nonGdprInfo?: boolean;

    zkraceneInformace?: boolean;

    smlouvaS: SmlouvaS;

    @JsonProperty({loadName: ["uosoba", "uOsoba"]})
    uOsoba: string;

    @JsonProperty({loadName: ["utelefon", "uTelefon"]})
    uTelefon: string;

    @JsonProperty({loadName: ["uemail", "uEmail"]})
    uEmail: string;

    souhrnne: string;

    email1: boolean;

    odlisadr: boolean;

    @JsonProperty({converters: MomentConverters})
    datzkus: Moment;

    datovaSchranka: string;

    ipDll: number;

    @JsonProperty({loadName: ["uosobaUser", "uOsobaUser"]})
    uOsobaUser: string;

    @JsonProperty({loadName: ["utelefonUser", "uTelefonUser"]})
    uTelefonUser: string;

    @JsonProperty({loadName: ["uemailUser", "uEmailUser"]})
    uEmailUser: string;

    zkusebniDoba: boolean;

    @JsonProperty({type:{enum: LicenseType}})
    typLicence: LicenseType;

    vs: number;

    users?: number;

    //present only when editing user
    enabledUsersId?: number[];

    //pouze v detailu provozovny admin
    pocetUzivatelu: number;

    @JsonProperty({type:{clazz:Klasifikace}})
    klasifikace?: Klasifikace;

    //only when filtering via okoli
    airDistance: number

    //historie
    provozovnaId?: number

    uzivatelOznaceni?: [OznaceniTyp]

    provozovnaOznaceni?: [OznaceniTyp]

    assignFirma(firma: Firma) {
        this.firma = firma;
        this.pUlice = firma.r1Ulice;
        this.pCisloPopisne = firma.r1CisloPopisne;
        this.pObec = firma.r1Obec;
        this.pCastObce = firma.r1CastObce;
        this.pPsc = firma.r1Psc;
        this.pStat = firma.r1Stat;
        this.pNazev = firma.r1Nazev;
        this.users = 1
    }

    isZkraceneInformace() {
        return this.stav === Stav.POZASTAVIL && this.podstav !== Podstav.BEZ_PODSTAVU && this.podstav !== Podstav.PROBLEM;
    }

    isNezobrazovatCondition() {
        return (this.stav === Stav.POZASTAVIL && (this.podstav === Podstav.BEZ_PODSTAVU || this.podstav === Podstav.PROBLEM || this.podstav===Podstav.VYPOVED));
    }

    equals(other: any) {
        if(exist(other.id))
            return this.id===other.id;
        else
            return false;
    }
}

export class FirmaPart {
    @JsonProperty({converters: MomentConverters})
    datSmlouvy?: Moment;

    gdpr?: boolean;

    http?: string;

    @JsonProperty({converters:MomentConverters})
    datPojist: Moment;

    constructor(datSmlouvy: Moment, gdpr: boolean, http: string, datPojist: Moment) {
        this.datSmlouvy = datSmlouvy;
        this.gdpr = gdpr;
        this.http = http;
        this.datPojist = datPojist;
    }
}

export class ProvozovnaFirmaFields extends Provozovna {
    firmaUpdate?: FirmaPart
}