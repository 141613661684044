import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {useDidMount} from "./hooks/SharedHooks";

/**
 * Automaticky skaluje velikost pisma v autocomplete dle delky retezce, tak aby se cely retezec vesel do onoho autocomplete
 * @param text
 * @param target
 * @param minFontSize
 * @constructor
 */

export function DynamicFontSize({text, target, minFontSize=8}:{text:string, target:MutableRefObject<HTMLElement>, minFontSize?:number}) {
    const canvas = useRef<HTMLCanvasElement>();
    const iterativeValue = 0.5;
    const originFontSize = useRef<number>();
    const originFontFamily = useRef<string>();
    const originPaddingLeft = useRef<string>();
    const originDimension = useRef<{width:string, height:string}>();
    const [size, setSize] = useState([0, 0]);

    useDidMount(()=>{
        originFontSize.current = parseFloat(window.getComputedStyle(target.current, null).getPropertyValue('font-size'));
        originFontFamily.current = window.getComputedStyle(target.current, null).getPropertyValue('font-family');
        originPaddingLeft.current = window.getComputedStyle(target.current, null).getPropertyValue('padding-left');
        originDimension.current = {
            height:window.getComputedStyle(target.current, null).getPropertyValue('height'),
            width:window.getComputedStyle(target.current, null).getPropertyValue('width')
        }
    });

    useEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [])

    const updateSize = () => {
        setSize([window.innerWidth, window.innerHeight]);
    }

    useEffect(()=>{
        let width = target.current.clientWidth;
        const family = originFontFamily.current;
        const ctx = canvas.current.getContext("2d");
        let fontSize = originFontSize.current;
        ctx.font = `${fontSize}px ${family}`;
        const resize = ctx.measureText(text).width > width
        if (width !== 0 && resize) {
            while(ctx.measureText(text).width > width) {
                fontSize = fontSize - iterativeValue;
                ctx.font = `${fontSize}px ${family}`;
            }
            fontSize = fontSize - 1;
        }

        target.current.style.fontSize = `${fontSize<minFontSize?minFontSize:fontSize}px`;

        target.current.style.width = `100%`;
        target.current.style.height = originDimension.current.height;
    }, [text, target, minFontSize, size]);



    return <canvas ref={canvas} style={{position:"absolute", visibility:"hidden", display: "none"}}/>
}

/**
 * Automaticky skaluje velikost pisma v inputu dle delky retezce, tak aby se cely retezec vesel do onoho inputu
 * @param text
 * @param target
 * @param minFontSize
 * @constructor
 */

export function DynamicInputFontSize({text, target, minFontSize=8}:{text:string, target:MutableRefObject<HTMLElement>, minFontSize?:number}) {
    const canvas = useRef<HTMLCanvasElement>();
    const iterativeValue = 0.5;
    const originFontSize = useRef<number>();
    const originFontFamily = useRef<string>();
    const originDimension = useRef<{width:string, height:string}>();
    const [size, setSize] = useState([0, 0]);
    useDidMount(()=>{
        const inputTarget = target.current.querySelector("input");
        originFontSize.current = parseFloat(window.getComputedStyle(inputTarget, null).getPropertyValue('font-size'));
        originFontFamily.current = window.getComputedStyle(inputTarget, null).getPropertyValue('font-family');
        originDimension.current = {
            height: window.getComputedStyle(inputTarget, null).getPropertyValue('height'),
            width: window.getComputedStyle(inputTarget, null).getPropertyValue('width')
        }
    });

    useEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [])

    const updateSize = () => {
        setSize([window.innerWidth, window.innerHeight]);
    }

    useEffect(()=>{
        const inputTarget = target.current.querySelector("input");
        let width =  inputTarget.clientWidth;
        const family = originFontFamily.current;
        const ctx = canvas.current.getContext("2d");
        let fontSize = originFontSize.current;
        ctx.font = `${fontSize}px ${family}`;
        const resize = ctx.measureText(text).width > width;
        if (width !== 0 && resize) {
            while (ctx.measureText(text).width > width) {
                fontSize = fontSize - iterativeValue;
                ctx.font = `${fontSize}px ${family}`;
            }
            fontSize = fontSize - 1;
        }
        inputTarget.style.fontSize = `${fontSize < minFontSize ? minFontSize : fontSize}px`;
        inputTarget.style.width = `100%`;
        inputTarget.style.height = originDimension.current.height;
    }, [text, target, minFontSize, size]);



    return <canvas ref={canvas} style={{position:"absolute", visibility:"hidden", display: "none"}}/>
}