import React, {useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Collapse,
    Grid,
    IconButton,
    TablePagination,
    Typography
} from "@mui/material";
import {httpEndpointCustom, useFetchCustom} from "../utils/HttpUtils";
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Loading} from "./Loading";
import {useDidMount} from "./hooks/SharedHooks";
import {invoke} from "../utils/Invoke";
import AddIcon from "@material-ui/icons/PostAdd";
import {useTranslation} from "react-i18next";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {Form, FormButton, FormComponent, FormField} from "../../web/raal_components/form/Form";
import {Paper} from "@material-ui/core";
import {showSnack} from "./SnackContainer";
import {jsonToFormUrlEncoded} from "../utils/Util";
import ClearIcon from "@material-ui/icons/Clear";
import {TypZpravy} from "../../web/model/Zprava";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useHistory} from "react-router";

type Message = {
    clanek: string,
    predmet: string,
    typZpravy?: string
}

export type MessageComponentProps = {
    admin: boolean
    title?: string
    typZpravy: TypZpravy
}

const useStyles = makeStyles(theme => ({
    containerStyle: {
        borderRadius: '4px',
        padding: '0.5rem'
    },
    header: {
        paddingLeft: '1.5rem',
        display: "flex",
        alignItems: "center"
    },
    pagination: {
        color: theme.palette.type === 'dark' ? '#fff !important' : '#000'
    },
    body: {
        color: theme.palette.type === 'dark' ? '#fff !important' : '#000',
        padding: '0 1rem',
        background: 'transparent !important',
        border: theme.palette.type === 'dark' ? '1px solid #fff' : '1px solid none'
    },
    boxStyle: {
        color: theme.palette.type === 'dark' ? '#fff !important' : '#000',
        background: 'transparent !important',
        border: theme.palette.type === 'dark' ? '1px solid #fff' : '1px solid none'
    },
    expandIcon: {
        color: theme.palette.type === 'dark' ? '#fff !important' : '#000',
    },
    tablePagination: {
        color: theme.palette.type === 'dark' ? '#fff !important' : '#000',
    }
}))
export const MessageComponent = (props: MessageComponentProps) => {
    const {fetch} = useFetchCustom<any>({ endpoint: `zprava`}, undefined);
    const classes = useStyles();
    const {t} = useTranslation();
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [showNewForm, setShowNewForm] = useState(false)
    const history = useHistory();

    const loadData = async(pageSize?: number, page?: number) => {
        setLoading(true);
        try {
            const data = await fetch({
                params: {
                    typZpravy: props.typZpravy,
                    page: page ?? 1,
                    'order[0].name': 'datum',
                    'order[0].dir': 'desc',
                    pageSize: pageSize ?? 15
                }
            });
            setData(data);
            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    useDidMount(() => {
        invoke(loadData);
    })

    const deleteItem = async (id?: string) => {
        if (!props.admin) return;
        const result = await httpEndpointCustom(`admin/zprava/${id}`,
            {
                method: "DELETE"
            });

        if (result?.response.status !== 200)
            showSnack({title: "Error", severity: "error"});

        loadData(data.pageSize, data.page)
    };

    const getItems = () => {
        const items = [];
        if (data?.list) {
            for (let i = 0; i < data.list.length; i++) {
                if (data.list[i])
                    items.push(<Grid item mb={1} key={i} style={{maxWidth: "100%"}}>
                        <RecordItem itemData={data.list[i]} />
                    </Grid>);
            }
        }

        return items;
    }

    const RecordItem = ({itemData}:{itemData?: any}) => {
        const classes = useStyles();
        const [show, setShow] = useState(false);

        return <Accordion className={classes.boxStyle}>
            <AccordionSummary
                className={classes.boxStyle}
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {props.admin ? <>
                    <IconButton
                        aria-label="delete"
                        color="inherit"
                        onClick={(e) =>{
                            e.stopPropagation();
                            deleteItem(itemData.id);
                        }}>
                        <DeleteIcon />
                    </IconButton>
                </> : null}
                {!show ? <div style={{display: 'flex', alignItems: "center", width: '100%'}}>
                    <Typography sx={{ width: '80%', flexShrink: 0 }}>{itemData.predmet}</Typography>
                    <Typography sx={{ width: '20%', flexShrink: 0, color: 'inherit' }}>{itemData?.datum ? moment(itemData.datum).format('DD.MM.YYYY HH:mm') : null}</Typography>
                </div> : null}
            </AccordionSummary>
            <AccordionDetails>
                {props.admin ? <IconButton color="inherit"
                    aria-label="edit"
                    style={{marginBottom: '1rem'}}
                    onClick={(e) =>{
                        e.stopPropagation();
                        setShow(!show)
                    }}>
                    {!show ? <EditIcon /> : <ClearIcon />}
                </IconButton> : null}
                {!show ? <Card className={classes.body}><div className={"ql-editor"} dangerouslySetInnerHTML={{__html: itemData.clanek}}/></Card> : null}
                {props.admin ? <Collapse in={show}><MessageEditForm
                        {...props}
                            data={itemData}
                            loadData={() => {
                            setShowNewForm(false);
                            loadData(data.pageSize, data.page);
                        }}
                    /></Collapse> : null
                }
            </AccordionDetails>
        </Accordion>
    }

    const getLabelDisplayedRows = (data: any) => {
        const label = t('MaterialTable.pagination.labelDisplayedRows');
        let tempLabel = label.replace('{overall}', `${data.overallCount !== 0 ? data.overallCount : data.count}`);
        tempLabel = tempLabel.replace('{from}', `${data.from}`);
        tempLabel = tempLabel.replace('{to}', `${data.to}`);
        tempLabel = tempLabel.replace('{count}', `${data.count}`);
        return tempLabel;
    }

    const defaultLabelDisplayedRows = ({from, to, count}:{from: any, to: any, count: any}) => {
        return getLabelDisplayedRows({from, to, count, overallCount: data?.overallCount??0});
    }

    const onRowsPerPageChange = async (count: number) => {
        await loadData(count)
    }

    const onPageChange = async (page: number) => {
        await loadData(data?.objectsPerPage, page + 1)
    }

    return <Paper>
        {
            loading ? <Loading show={loading} fullscreen/> :
                data ? <Grid container className={classes.containerStyle} direction={"column"}>
                            <Grid item container justifyContent={"space-between"} className={classes.header}>
                                <Grid item xs={4} sm={4} lg={4}>
                                    <IconButton edge="start" color="inherit" onClick={() => history.goBack()}>
                                        <ArrowBackIcon/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={4} sm={4} lg={4} textAlign={"center"}>
                                    <Typography variant={"h6"}>{props.title}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} lg={4} textAlign={"right"}>
                                    {props.admin ? <IconButton edge="start" color="inherit" onClick={() => setShowNewForm(!showNewForm)}>
                                        {!showNewForm ? <AddIcon/> : <ClearIcon/>}
                                    </IconButton> : null}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Collapse in={showNewForm}>
                                    <MessageEditForm {...props} loadData={() => {
                                        setShowNewForm(false);
                                        loadData(data.pageSize, data.page);
                                    }}/>
                                </Collapse>
                            </Grid>
                            {getItems()}
                            <Grid item>
                                <TablePagination
                                    component="div"
                                    count={data?.total}
                                    page={data?.page - 1}
                                    onPageChange={(event, page) => onPageChange(page)}
                                    rowsPerPageOptions={[10, 15, 20]}
                                    labelRowsPerPage={t('MaterialTable.pagination.labelRowsCount')}
                                    labelDisplayedRows={defaultLabelDisplayedRows}
                                    rowsPerPage={data?.objectsPerPage}
                                    onRowsPerPageChange={(event) => onRowsPerPageChange(Number(event.target.value))}
                                    showFirstButton={true}
                                    showLastButton={true}
                                    variant={"footer"}
                                    classes={{
                                        root: classes.tablePagination,
                                        selectIcon: classes.tablePagination,
                                        select: classes.tablePagination,
                                        actions: classes.tablePagination,
                                    }}
                                />
                            </Grid>
                        </Grid> : null
        }
    </Paper>
}

type MessageEditFormProps = {
    loadData: () => void
    data?: any
}&MessageComponentProps

const MessageEditForm = (props: MessageEditFormProps) => {
    const {t} = useTranslation();
    const [formData, setFormData] = useState<any>({});

    useEffect(() => {
        if (props.data) setFormData(props.data)
    }, [props.data])

    const save = async(form:Message): Promise<any> => {
        if (!props.admin) return null;
        const id = props.data?.id;
        const result = await httpEndpointCustom(`admin/zprava/${id ? id : ''}`,
            {
                method: id ? "PUT" : "POST",
                body: jsonToFormUrlEncoded({
                    ...form,
                    typZpravy: props.typZpravy,
                    platiDo: moment().toISOStringWithMillis()
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
                }
            });

        if (result?.response.status > 201) {
            showSnack({title: "Error", severity: "error"});
        } else {
            props.loadData();
        }

        return null
    };

    return <Grid container>
        <Grid item style={{marginBottom: '1rem'}} lg={12}>
            <Card style={{background: 'transparent', overflow: 'visible'}}>
                <Grid item style={{margin: '0.5rem'}}>
                    <Form<Message> disableFocusForSaveButton={true}
                                   blockSaveUntilChange={false}
                                   data={formData}
                                   simpleLabel
                                   onSend={async(form:FormComponent<Message>) => {
                                        const r = await save(form.data);
                                        return r;
                                   }}
                                   localization={t("FormLocalization", {returnObjects:true})}>

                        <FormField title={t("ThunderNews.Predmet")} name={"predmet"} type={"text"} required />
                        <FormField title={t("Default.Text")} name={"clanek"} type={"editor"} required />

                        <div className={"modal-buttons"} style={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: '0.5rem'}}>
                            <FormButton>{t("Buttons.Save")}</FormButton>
                        </div>
                    </Form>
                </Grid>
            </Card>
        </Grid>
    </Grid>
}
