import {Button, Grid} from "@mui/material";
import * as React from "react";
import {FormField, FormInputType} from "../../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {WithFileBase} from "../../../../model/Stiznosti";
import {downloadFile} from "../../../../../common/utils/DownloadFile";
import {useErrorTranslator} from "../../../../../common/utils/error-utils";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {useCodeBookDetailContextNew} from "../../../../raal_components/controller/CodeBookDetail";

export function DluhSoubLayoutForm(props: {url: string}) {
    const {t} = useTranslation();
    const {data} = useCodeBookDetailContextNew<WithFileBase>();
    const url = props.url;
    const errorTranslator = useErrorTranslator();

    return <Grid container spacing={1}>
        <Grid item xs={12}>
            <FormField name={"popis"} title={t("DluhSoub.Popis")} type={"textarea"} required textFieldProps={{inputProps: {maxLength: 510}}} serverValidation={{translate: t}}/>
        </Grid>

        <Grid item container spacing={2} alignItems="center">
            {data.soubor && <Grid item>
                <Button variant="contained" onClick={async () => {
                    try {
                        await downloadFile(`${url}/${data.id}/download`);
                    } catch (e) {
                        console.error(e);
                        showSnack({title: errorTranslator(e), severity: "error"});
                    }
                }}
                >{t("DluhSoub.Stazeni")}</Button>
            </Grid>}

            <Grid item xs={12} sm={6} lg={4} alignSelf={"center"}>
                <FormField name={"novySoubor"} title={t("DluhSoub.NovySoubor")} type={"file"} serverValidation={{translate: t}} additionalServerErrorKeys={["soubor"]}/>
            </Grid>
            <Grid item xs={12} sm={4} lg={2}>
                <FormField name={"datCas"} title={t("WithUserBase.DatCas")} type={FormInputType.DateTime} disabled/>
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
                <FormField name={"uzivatel"} title={t("WithUserBase.Uzivatel")} type={FormInputType.Text} disabled getValue={data => data.uzivatel?.displayName()}/>
            </Grid>
        </Grid>
    </Grid>
}
