import React from 'react';
import {ComponentMode, useFlatRoutes} from "./routes";
import {Redirect, Route, Switch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppContext} from "./context/AppContext";
import Navigator from "./Navigator";
import {useStyleContext} from "./context/ThemeModeContext";
import {Consent} from "./page/Dials/master/Consent";
import {DynamicTitle} from "./raal_components/DynamicTitle";


export function Content() {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const routes = useFlatRoutes();
    const {classes} = useStyleContext();
    const getTitle = (routeId: string, parentId?: string) => {
        if (!routeId) return null;
        const title = parentId ? `${t(parentId)} - ${t(routeId)}` : t(routeId);
        return user ? `${title} (${user.provozovna?.kod ?? '-'}/${user.login})` : title;
    }

    return (
        <Switch>
            {routes.map(route=>[
                <Route
                    key={route.href}
                    path={`${route.href}`}
                    exact={true}
                    render={()=>(
                        <>
                            <DynamicTitle title={getTitle(route.id, route.parentId)} />
                            <Navigator/>
                            <main className={classes.content}>
                                <div className={classes.toolbar} />
                                <route.component {...route.routeProps} mode={ComponentMode.GridMode}  />
                            </main>
                        </>
                    )}
                />,
                <Route
                    key={route.href + 'detail'}
                    path={`${route.href}/:id*`}
                    render={()=>(
                        <>
                            <DynamicTitle title={getTitle(route.id, route.parentId)} />
                            <route.component {...route.routeProps} mode={ComponentMode.DetailMode} />
                        </>

                    )}
                />
            ])}
            <Route path="/error" />
            <Route
                path="/consent"
                render={()=>(
                    <Consent />
                )}
            />
            <Redirect to={`/dashboard`} />
        </Switch>
    );
}
