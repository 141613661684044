import {useEffect, useState} from "react";
import {CustomFieldComponentProps} from "./FormFieldInterface";
import {Setter} from "../../../index.d";
import {exist} from "../../utils/Util";
import {useMountedEffect} from "../hooks/SharedHooks";
import {FormDateRangeSelectOptions} from "../../../web/raal_components/FormTwoDatesSelect";

export type FormRange<T> = {
    min?:T;
    max?:T;
}
export type FormRangeOptions<T> = {
    minTitle?:string,
    maxTitle?:string,
    maxWidth?:number,
    spacing?:number
    mantinel?:FormRange<T>
}
// eslint-disable-next-line
export type FormRangeDateOptions<T> = {
    minTitle?:string,
    maxTitle?:string,
    maxWidth?:number,
    spacing?:number
}

export function useRangeFocusable<T, O>(props:CustomFieldComponentProps<T, O>, getCountAs:()=>number=()=>2):[number, (position:number) =>void] {
    const {focused:pfocused, focusedIndex:pFocusedIndex = 0, currentFocused:pCurrentFocused} = props;
    const [focused, setFocused] = useState(pFocusedIndex);

    const {enableFocusSupport:pEnableFocusSupport, onFocus:pOnFocus} = props;
    useEffect(()=>{
        pEnableFocusSupport&&pEnableFocusSupport(getCountAs(), focused);
    });
    useEffect(()=>{
        if(focused >=1) {
            pOnFocus(true, focused);
        }
    }, [focused, pEnableFocusSupport, pOnFocus]);

    useEffect(() => {
        if(pfocused) {
            if(pFocusedIndex) {
                if (pCurrentFocused === 0) {
                    setFocused(0);
                }else {
                    setFocused(pFocusedIndex);
                }
            } else {
                setFocused(f=>f+1);
            }
        } else if(focused >= getCountAs()) {
            setFocused(0);
        }
        // eslint-disable-next-line
    }, [pfocused, pFocusedIndex, pCurrentFocused]);
    return [focused, setFocused];
}

export function useListRangeFocusable<T, O>(props:CustomFieldComponentProps<T, O>, getCountAs:()=>number=()=>2):[number, (position:number) =>void] {
    const {focused:pfocused, focusedIndex:pFocusedIndex = 0, currentFocused:pCurrentFocused, enableFocusSupport:pEnableFocusSupport, onFocus:pOnFocus} = props;
    const [focused, setFocused] = useState(pFocusedIndex);

    useEffect(()=>{
        pEnableFocusSupport&&pEnableFocusSupport(getCountAs(), focused);
    });
    useEffect(()=>{
        if(focused >=1) {
            pOnFocus(true, focused);
        }
    }, [focused, pEnableFocusSupport, pOnFocus]);

    useEffect(() => {
        if(pfocused) {
            if(pFocusedIndex) {
                if (pCurrentFocused === 0) {
                    setFocused(0);
                }else {
                    setFocused(pFocusedIndex);
                }
            } else {
                setFocused(f=>f+1);
            }
        } else if(focused > getCountAs()) {
            setFocused(0);
        }
        // eslint-disable-next-line
    }, [pfocused, pFocusedIndex, pCurrentFocused]);
    return [focused, setFocused];
}

export function useFormRange<T>(props:CustomFieldComponentProps<FormRange<T>, FormRangeOptions<T>>):[FormRange<T>, Setter<FormRange<T>>, ()=>void, FormRangeOptions<T>] {
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const [range, setRange] = useState(props.value ?? {min:null, max:null});
    let title = props.title;
    if(typeof title === "string") {
        title = title.trim() === "" ? null : title;
    }
    const {maxWidth=500, minTitle=title ? `${title} (min)`: undefined, maxTitle=title ? `${title} (max)`: undefined, spacing=0, mantinel} = options ?? {};
    useMountedEffect(()=>{
        if(range) {
            rangeControlCheck({range, setRange, mantinel});
        }

    }, [range]);

    useMountedEffect(()=>{
        setRange(props.value ?? {min:null, max:null});
    }, [props.value]);

    return [range, (range:FormRange<T>) => {
        setRange(range);
    }, ()=>{
        if(exist(range?.max) && exist(range?.min) && range.max < range.min) {
            //setRange({max:range.min, min:range.max});
        }
    },{maxWidth, minTitle, maxTitle, spacing}];
}

export function rangeControlCheck<T>({range, setRange, mantinel}:{range:FormRange<T>, setRange:Setter<FormRange<T>>, mantinel?:FormRange<T>}) {
    let newRange:FormRange<T> = {...range};
    let touched = false;
    const setNewRange = (rng:FormRange<T>) => {
        newRange = rng;
        touched = true;
    };
    if(mantinel) {
        if(exist(newRange.min) && exist(mantinel.min) && newRange.min < mantinel.min) {
            setNewRange({...newRange, min:mantinel.min});
        }
        if(exist(newRange.max) && exist(mantinel.min) && newRange.max < mantinel.min) {
            setNewRange({...newRange, max:mantinel.min});
        }
        if(exist(newRange.max) && exist(mantinel.max)  && newRange.max > mantinel.max) {
            setNewRange({...newRange, max:mantinel.max});
        }
        if(exist(newRange.min) && exist(mantinel.max) && newRange.min > mantinel.max) {
            setNewRange({...newRange, min:mantinel.max});
        }
    }
    if(touched) {
        setRange(newRange);
    }
}

export function useFormDateRange<T>(props: CustomFieldComponentProps<FormRange<T>, FormDateRangeSelectOptions>):[FormRange<T>, Setter<FormRange<T>>, (() => void), FormRangeDateOptions<T>] {
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const [range, setRange] = useState(props.value ?? {min:null, max:null});
    let title = props.title;
    if(typeof title === "string") {
        title = title.trim() === "" ? null : title;
    }
    const {maxWidth=500, minTitle=title ? `${title} (od)`: undefined, maxTitle=title ? `${title} (do)`: undefined, spacing=0} = options ?? {};

    useMountedEffect(()=>{
        setRange(props.value ?? {min:null, max:null});
    }, [props.value]);

    return [range, (range:FormRange<T>) => {
        setRange(range);
    }, ()=>{
        if(exist(range?.max) && exist(range?.min) && range.max < range.min) {
            //setRange({do:range.od, od:range.do});
        }
    },{maxWidth, minTitle, maxTitle, spacing}];
}
