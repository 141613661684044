import * as momentNS from 'moment';
import moment from 'moment';
import { User } from '../../web/model/User';

const FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSSZ';
declare module 'moment' {
    export interface Moment {
        toISOStringWithMillis(): string;
        fromISOStringWithMillis(): this;
        toDateFormat(): string;
    }
}

(momentNS.fn as any).toISOStringWithMillis = function () {
    return this.format(FORMAT);
};
(momentNS.fn as any).toDateFormat = function () {
    return this.format("L");
};
declare global {
    interface String {
        fromISOStringWithMillis() : momentNS.Moment;
    }
	interface Window {
		receiveFCMToken: (token: string) => void;
		user: User;
	}
}
// eslint-disable-next-line
String.prototype.fromISOStringWithMillis = function (this : string) {
    return moment(this, FORMAT);
};