import {CustomFieldComponentProps} from "./FormFieldInterface";
import {FormInputType} from "../../../web/raal_components/form/Form";
import {useRangeFocusable} from "./FormRange";
import React, {useEffect, useImperativeHandle, useState} from "react";
import {Box, Grid} from "@material-ui/core";
import {StandaloneField} from "./StandaloneField";
import {formatForRaalUsers, FormNominatim} from "./FormNominatim";
import {Waypoint} from "../../../web/model/Waypoint";
import {invoke} from "../../utils/Invoke";
import _ from "lodash";
import {ChildComponentProps, Vicinity, VicinityOptions} from "./FormVicinityContainer";
import {exist} from "../../utils/Util";
import {useMpzResolver} from "../../utils/MpzUtils";
import {useMountedEffect} from "../hooks/SharedHooks";

type FVExtension  = {
    customComponentOptions?: {
        okoliDisabled?: boolean
    }
}

export const FormVicinity = (props:CustomFieldComponentProps<Vicinity, VicinityOptions>&ChildComponentProps & FVExtension) => {
    const textFieldProps = {style:{maxWidth:'100%'}};
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const [focused, onFieldFocused] = useRangeFocusable(props);
    const [value, setValue] = useState<Vicinity>(null);
    const [resolveMpz] = useMpzResolver();
    const defaultValue: Vicinity = {
        psc: null,
        mpz: null,
        nazevMista: null,
    }

    useEffect(() => {
        if (props.getComponentState && props.getComponentState()) setValue(props.value);
        // eslint-disable-next-line
    }, [props.value])

    const onChange = (v?: Vicinity) => {
        setValue(v);
        invoke(options.onChange, v);
    }

    useMountedEffect(() => {
        if (props.getComponentState && props.getComponentState()) props.onValueChanged(value);
    }, [value]);

    useImperativeHandle(props.componentRef, () => {
        return {
            selectFirstField: () => onFieldFocused(1),
            onChange
        }
    })

    return (
        <>
            <Grid item lg={12} sm={12} xs={12}>
                <Box style={{width:'100%'}}>
                    <StandaloneField
                        title={options?.showTitle&&options?.titleOkoli}
                        placeholder={options?.titleOkoli}
                        type={"Custom"}
                        customComponent={FormNominatim}
                        focused={focused===1}
                        customComponentOptions={{
                            checkLicense: true,
                            countryCodeForCompare: options?.countryCodeForCompare,
                            autofillValue: true,
                            onChange: (osmPlace: any) => {
                                const waypoint = osmPlace?.name ? Waypoint.fromOsmPlace(osmPlace) : null;
                                const tempValue: Vicinity = waypoint ? {
                                    ...value,
                                    ...defaultValue,
                                    osmPlace: formatForRaalUsers(osmPlace, () => resolveMpz(osmPlace.address?.country_code)),
                                    countryCode: waypoint?.countryCode,
                                    koordinat: _.isEmpty(waypoint.koordinat) ? null : waypoint.koordinat,
                                    radius: null
                                } : null
                                onChange(tempValue);
                            }
                        }}
                        onBlur={()=>{
                            invoke(props.onBlur);
                        }}
                        variant={props.variant ?? "outlined"}
                        onKeyDown={props.onKeyDown}
                        showAdornment={props.showAdornment}
                        inputClassName={props.inputClassName}
                        textFieldProps={textFieldProps}
                        value={value?.osmPlace}
                        onFocus={(o, userFocus) => {
                            if(userFocus) {
                                onFieldFocused(1);
                            }
                        }}
                    />
                </Box>
            </Grid>
            <div style={{height: options?.verticalSpace ?? 8}}/>
            <Grid item lg={12} sm={12} xs={12}>
                <Box style={{width:'100%'}}>
                    <StandaloneField
                        focused={focused===2}
                        numberProps={options?.numberProps}
                        onBlur={()=>{
                            invoke(props.onBlur);
                        }}
                        variant={props.variant ?? "outlined"}
                        onKeyDown={(e) => {
                            if (!exist(value?.koordinat)) {
                                e.preventDefault();
                                return;
                            }
                            props.onKeyDown&&props.onKeyDown(e);
                        }}
                        type={FormInputType.Number}
                        showAdornment={props.showAdornment}
                        inputClassName={props.inputClassName}
                        value={value?.radius}
                        onValueChanged={v => {
                            const tempValue: Vicinity = exist(value?.koordinat) ? {
                                ...value,
                                ...defaultValue,
                                radius: v
                            } : {...value}
                            onChange(tempValue);
                        }}
                        disabled={props.customComponentOptions?.okoliDisabled}
                        title={options?.showTitle&&options?.titleRadius}
                        placeholder={props.customComponentOptions?.okoliDisabled ? '' : options?.titleRadius}
                        textFieldProps={textFieldProps}
                        onFocus={(o, userFocus) => {
                            if(userFocus) {
                                onFieldFocused(2);
                            }
                        }}
                    />
                </Box>
            </Grid>
        </>
    );
}
