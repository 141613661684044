import React from 'react';
import {useData} from "../../web/context/DataContext";
import {Box, useTheme} from "@material-ui/core";

// @ts-ignore
import Flag from 'react-world-flags';
import {Business} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';

export const FlagIcon = ({mpz, phone}:{mpz?:string, phone?:string}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {staty} = useData()
    const getStatCountryCode = (mpz?: string, phone?:string) => {
        if (mpz) {
            const stat = staty.find(s => s.mpz === mpz);
            return stat?.countryCode
        }
        if (phone) {
            let stat = staty.find(s => s.predvolba === phone?.substr(1, 2));
            stat = !stat ? staty.find(s => s.predvolba === phone?.substr(1, 3)) : stat;
            stat = !stat ? staty.find(s => s.predvolba === phone?.substr(1, 4)) : stat;
            return stat?.countryCode
        }
    }
    const countryCode = getStatCountryCode(mpz, phone)

    if (mpz && countryCode ) {
        return  <Box title={mpz??countryCode} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0.1rem 0'}}>
                    <Flag code={countryCode} fallback={countryCode} height={20} key={"asd"} />
                </Box>
    }
    if (phone && countryCode && phone.length > 1 && phone.indexOf('+') > -1 ) {
        return  <Box title={mpz??countryCode} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}>
            <Flag code={countryCode} fallback={countryCode} height={20} key={"asd"} />
        </Box>
    }
    else {
        return  <Box textAlign={"center"} title={t("Stat.Flag")} style={{paddingLeft: 10, paddingRight: 10, color: theme.palette.type === 'dark' ? "#FFFFFF" : "#000000"}}>
                    <Business/>
                </Box>
    }
}
