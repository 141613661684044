import {Column, DataGridFieldProps} from "./DataGrid.d";
import {SvgIcon, TableCell, TableRow, Tooltip} from "@material-ui/core";
import React, {MutableRefObject, useEffect, useImperativeHandle, useRef, useState} from "react";
import {StandaloneField} from "../../../common/component/form/StandaloneField";
import {GenericMap} from "../../../index.d";
import {cloneClassObject} from "../../../common/utils/Util";
import {useDidMount, useMountedEffect} from "../../../common/component/hooks/SharedHooks";
import _ from "lodash";
import {useSubscribe} from "use-pubsub-js";
import {Button} from "@material-ui/core";
import * as MaterialIcon from '@material-ui/icons';
import {useTranslation} from "react-i18next";
import {isMobile} from "react-device-detect";

export type MTDataGridFilterRowExposed = {
    setTabIndexes: (colsChanged?: boolean) => void
}

type FRowProps<Filter> = {
    defaultFilters:Filter
    columns:GenericMap&Column<any>[]
    onFilterChanged:(id:string, value:any)=>void
    hasActions: boolean
    actionsColumnIndex: number
    selection: boolean
    localization: {dateTimePickerLocalization: undefined}
    hasDetailPanel: boolean
    isTreeData: number
    filterCellStyle: undefined
    hideFilterIcons: boolean
    onEditingStart:()=>void,
    onEditingEnd:(data?: Filter)=>void,
    indexOffset?: number
    lastTabIndex?: number
    setLastTabIndex?:(index: number) => void
    autoFocus?: boolean
    fRowRef?: MutableRefObject<MTDataGridFilterRowExposed>
    checkAutorefreshState?:() => boolean
}&{
    onFilterClick(data?:Filter):void
    onFilterDataChanged():void
};


const useColumns = <Filter extends any>(props:FRowProps<Filter>) => {
    const [data, setData] = useState(cloneClassObject(props.defaultFilters));
    const [r, reload] = useState(false)
    const {t} = useTranslation();
    useDidMount(() => {
        return () => props.onEditingEnd();
    });
    const resolveEditingEnd = ()=> {
        props.onEditingEnd(data);
    };
    const SendFilterAction = ()=>(
        <>
            <TableCell style={{padding:"0 4px", paddingRight: "6px"}}>
                <div style={{display: "flex", flexWrap: "wrap", rowGap: "4px", columnGap: "2px"}}>
                    <Tooltip title={t("Filter.DoFilter")}>
                        <Button size={"small"} style={{height:"26px", width: "26px", minWidth: "26px"}} onClick={()=>props.onFilterClick(data)} color="primary" variant="contained">
                            <SvgIcon component={MaterialIcon.Search}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title={t("Filter.ClearFilter")}>
                        <Button size={"small"} style={{height:"26px", width: "26px", minWidth: "26px"}} onClick={()=>props.onFilterClick()} color="primary" variant="contained">
                            <SvgIcon component={MaterialIcon.Clear}/>
                        </Button>
                    </Tooltip>
                </div>
            </TableCell>
        </>
    );

    const renderField = (filterProps:DataGridFieldProps, columnDef:Column<any>&GenericMap) => {
        const fieldName = filterProps?.name ?? columnDef.field;
        return <StandaloneField
            {...filterProps}
            showIcon={false}
            onValueChanged={v=>{
                _.set(data, fieldName, v);
                if (filterProps.onChange) {
                    filterProps.onChange(v, data, setData, fieldName);
                    reload(!r);
                }
                setData(data);
            }}
            value={_.get(data, fieldName)}
            onKeyDown={e => {
                if(e.key === "Enter") {
                    setTimeout(() => props.onFilterClick(data), 100);
                }
            }}
            variant={"standard"}
            showAdornment={false}
            onFocus={()=>props.onEditingStart()}
            onBlur={resolveEditingEnd}
            onInteractStart={()=>props.onEditingStart()}
            onInteractEnd={resolveEditingEnd}
            useDynamicFontSize={!columnDef.disableDynamicFontSize}
            useCustomPopperElement={true}
        />
    }

    const columns = props.columns
        .filter((columnDef:GenericMap) => !columnDef.hidden && !(columnDef.tableData.groupOrder > -1))
        .sort((a:GenericMap, b:GenericMap) => a.tableData.columnOrder - b.tableData.columnOrder)
        .map((columnDef:Column<any>&GenericMap, index) => (
            <TableCell padding="none" key={columnDef.tableData.id} style={{ ...columnDef.filterCellStyle, verticalAlign:"bottom", ...(columnDef.cellStyle.maxWidth) && {maxWidth: columnDef.cellStyle.maxWidth} }}>
                {
                    columnDef.filterProps && (
                        renderField(columnDef.filterProps(), columnDef)
                    )
                }
            </TableCell>
        ));

    if (props.hasActions) {
        if (props.actionsColumnIndex === -1) {
            columns.push(<SendFilterAction key="key-action-column" />);
        } else {
            let endPos = 0;
            if (props.selection) {
                endPos = 1;
            }
            columns.splice(props.actionsColumnIndex + endPos, 0, <SendFilterAction key="key-action-column" />);
        }
    } else {
        columns.splice(props.actionsColumnIndex, 0, <SendFilterAction key="key-action-column" />);
    }

    if (props.hasDetailPanel) {
        columns.splice(0, 0, <TableCell padding="none" key="key-detail-panel-column" />);
    }

    if (props.isTreeData > 0) {
        columns.splice(0, 0,
            <TableCell
                padding="none"
                key={"key-tree-data-filter"}
            />
        );
    }

    return [
      columns, data
  ];
};

// eslint-disable-next-line
export function MTDataGridFilterRow<T extends object, Filter>(props:FRowProps<Filter>) {
    const [columns, data] = useColumns(props);
    const [disabled, setDisabled] = useState(false);
    const formRef = useRef();
    const configuredTabIndexes = useRef(0);

    useImperativeHandle(props.fRowRef, () => ({
        setTabIndexes
    }))

    const handler = (token?:string | symbol, message?: string) => {
        if (token === 'autorefreshChanged') {
            setDisabled(Boolean(message))
        }
    }

    useMountedEffect(() =>{
        setTimeout(() => {
            if (!disabled) {
                autofocusFirstField();
            } else {
                findElementByTabIndex(1)?.focus();
            }
        }, 5)
    }, [disabled])

    const { unsubscribe, resubscribe } = useSubscribe({ token: 'autorefreshChanged', handler })

    const autofocusFirstField = () => {
        if(props.lastTabIndex === 2){
            moveFocusToTabIndex(2);
        }
    }

    const findElementByTabIndex = (tabIndex: number) => {
        // @ts-ignore
        return formRef?.current?.querySelector(`[tabindex="${tabIndex}"]`);
    }

    const moveFocusToTabIndex = (tabIndex: number) => {
        if (isMobile) {
            return
        }
        findElementByTabIndex(tabIndex)?.focus();
    }

    const handleClickEvent = (event: MouseEvent) => {
        // @ts-ignore
        const tabIndex = parseInt(event.target.getAttribute('tabindex'));
        props.setLastTabIndex&&props.setLastTabIndex(tabIndex);
    }

    const handleKeyDownEvent = (event: KeyboardEvent) => {
        if (props.checkAutorefreshState()) {
            event.stopPropagation();
            event.preventDefault();
            return;
        }
        // @ts-ignore
        const tabIndex = parseInt(event.target.getAttribute('tabindex'));
        props.setLastTabIndex&&props.setLastTabIndex(tabIndex);

        if(event.shiftKey && event.key === 'Tab'){
            event.preventDefault()
            if (tabIndex > 0 ) {
                moveBack(tabIndex);
            }

        }else if(event.key === 'Tab'){ //should probably make sure there is no other modifier key pressed.
            event.preventDefault()
            if (configuredTabIndexes.current < tabIndex) {
                findData();
            }else{
                moveNext(tabIndex);
            }
        }
    }

    const findData = () => {
        props.setLastTabIndex&&props.setLastTabIndex(0);
        setTimeout(() => props.onFilterClick(data), 10);
    }

    const moveNext = (tabIndex: number) => {
        let offset = 1;
        for (let i = tabIndex + offset; i <= configuredTabIndexes.current; i++) {
            const el = findElementByTabIndex(i)
            if (el?.disabled) {
                offset++;
            } else {
                break;
            }
        }
        if (configuredTabIndexes.current + 1 >= (tabIndex + offset)) {
            moveFocusToTabIndex(tabIndex + offset);
        } else {
            findData();
        }
    }

    const moveBack = (tabIndex: number) => {
        let offset = 1;
        for (let i = tabIndex - offset; i >= 0; i--) {
            const el = findElementByTabIndex(i)
            if (el?.disabled) {
                offset++;
            } else {
                break;
            }
        }
        moveFocusToTabIndex((tabIndex - offset) >= 0 ? (tabIndex - offset) : 0);
    }

    const focusableElements = () => {
        // @ts-ignore
        const data = formRef.current?.querySelectorAll(
            'a, input, textarea, select, details, [tabindex]:not([tabindex="-1"]):not([type="hidden"]):not([type="button"]):not([disabled])'
        )
        return formRef?.current ? [...data] : [];
    }

    const setTabIndexes = (colsChanged?: boolean) => {
        let count = 0
        if((!configuredTabIndexes.current || colsChanged) && formRef.current) {
            focusableElements().forEach((el, index) => {
                const tabIndex= index + (props.indexOffset??0) + 1;
                el.setAttribute('tabindex', tabIndex.toString())
                count++;
            });
            configuredTabIndexes.current = count;
        }
    }
    const resizeObserver = new ResizeObserver(entries => setStickyTop());
    const setStickyTop = () => {
        const row : HTMLElement = formRef?.current;
        const tableBody = row?.parentElement;
        const table = tableBody?.parentElement;
        if(row && table && tableBody) {
            row.style.top = (tableBody.getBoundingClientRect().top - table.getBoundingClientRect().top) + "px";
        }
    }

    useEffect(() => {
        resubscribe();
        setTabIndexes();

        const table = (formRef?.current as HTMLElement)?.parentElement?.parentElement;
        if(table) resizeObserver.observe(table);

        // @ts-ignore
        formRef.current?.addEventListener('keydown', handleKeyDownEvent);
        // @ts-ignore
        formRef?.current?.addEventListener('click', handleClickEvent);

        if (props.autoFocus) {
            setTimeout(() => {
                autofocusFirstField();
            }, 1)
        }

        const removeListeners = () => {
            // @ts-ignore
            formRef?.current?.removeEventListener('keydown', handleKeyDownEvent);
            // @ts-ignore
            formRef?.current?.removeEventListener('click', handleClickEvent);
            unsubscribe();
            resizeObserver.disconnect();
        }

        return () => removeListeners();
        // eslint-disable-next-line
    }, [])

    return (
        <TableRow ref={formRef} className={disabled === true? "ChildrenOpacity" : undefined} style={disabled === true ? {pointerEvents: "none"/*, opacity: 0.6*/, position: "sticky", backgroundColor: "inherit", zIndex: 10} : {position: "sticky", backgroundColor: "inherit", zIndex: 10}}>
            {columns}
        </TableRow>
    );
}


