import {makeStyles} from "@material-ui/core/styles";
import {Badge, createStyles, IconButton, Tooltip} from "@material-ui/core";
import React, {ReactNode, useState} from "react";
import {useHistory} from "react-router";
import Link from "@material-ui/core/Link";
import clsx from "clsx";

export type HeaderButtonProps = {
    showText:boolean,
	langChangeCallBack?: (locale:string) => void
}
interface DropdownProps {
    icon:ReactNode,
    text?:string,
    tooltip?:string,
    showText?:boolean,
    popupComponent:React.ComponentType<any>,
    render:(handleClose:VoidFunction)=>ReactNode[]|ReactNode
}
const useButtonStyles = (color:string) => makeStyles((theme) =>
    createStyles({
        button: {
            color:color,
            "&:disabled": {
                color: color
            }
        },
        hover: {
            color:color,
            cursor: "pointer"
        },
        disabledHover:{
            "&:hover": {
                color: `${color} !important`,
                cursor: "default !important"
            }

        }
    }),
);
type IconButtonWithTextProps = {href?:string, badge?:string,tooltip?:string,disabled?:boolean, color?:string,icon:ReactNode,text?:string, onClick?:(event: React.MouseEvent<HTMLElement>)=>void, showText?:boolean};
export function IconButtonWithText({href, badge, tooltip, icon, text, onClick, showText=false, disabled=false, color="inherit"}: IconButtonWithTextProps) {
    const classes = useButtonStyles(color)();
    const {push} = useHistory();
    return (
        <div style={{
            display: 'inline-flex',
            verticalAlign: 'text-bottom',
            boxSizing: 'inherit',
            textAlign: 'center',
            alignItems: 'center'
        }}>
            <Tooltip title={tooltip??""} disableFocusListener={tooltip === undefined} disableHoverListener={tooltip === undefined} disableTouchListener={tooltip === undefined}>
                <Link href={href} className={clsx(disabled?classes.disabledHover:undefined, classes.hover)} onClick={
                    (e:React.MouseEvent<HTMLElement>)=>
                    {
                        e.preventDefault();
                        e.stopPropagation();
                        if(!disabled) {
                            if(onClick){
                                onClick(e);
                            } else if(href) {
                                push(href);
                            }
                        }
                    }
                } variant="body2">
                    <IconButton disabled={disabled} className={classes.button}>
                        <Badge badgeContent={badge} hidden={badge === undefined} color="secondary">
                            {icon}
                        </Badge>
                    </IconButton>
                    {showText&&text}
                </Link>
            </Tooltip>
        </div>
    );
}
export function HeaderDropDown({icon, render, popupComponent, text, showText, tooltip}:DropdownProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const Component = popupComponent;
    return (
        <div>
            <IconButtonWithText href={"#"} icon={icon} text={text} tooltip={tooltip} onClick={handleMenu} showText={showText}/>
            <Component
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                {render(handleClose)}
            </Component>
        </div>
    );
}
