import {Dial} from "../../../raal_components/dial/Dial";
import {
    KilomentrovnikHlaseniAdminDetail,
    KilomentrovnikHlaseniAdminList,
    KilomentrovnikHlaseniBase,
    KilomentrovnikHlaseniFilter,
    KilometrovnikHlaseniStatus
} from "../../../model/KilomentrovnikHlaseni";
import React from "react";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {FormButton, FormField, FormInputType} from "../../../raal_components/form/Form";
import {useEnumeratedSelect, useUserBasicSelect} from "../../../raal_components/SelectOptions";
import {Firma} from "../../../model/Firma";
import {FormLinkButton} from "../../../../common/component/form/FormLinkButton";
import {getOsrmEditUrl} from "../../../../common/utils/LinkUtils";
import {MapReport} from "../../../../common/component/map/MapReport";
import {LatLng} from "leaflet";
import {RouteComponentProps} from "../../../routes";
import {useCodeBookDetailContextNew} from "../../../raal_components/controller/CodeBookDetail";

export function KilometrovnikHlaseniDial(props: RouteComponentProps) {
    const {t} = useTranslation();
    const stavSelectProps = useEnumeratedSelect(KilometrovnikHlaseniStatus, "Enumerations.KilometrovnikHlaseniStatus", "number", {isClearable:true, autoOpenDisabled: true});

    return <Dial<KilomentrovnikHlaseniAdminList, KilomentrovnikHlaseniFilter, KilomentrovnikHlaseniAdminDetail>
         mode={props.mode}
         config={{
             id: "kilomentrovnik-hlaseni",
             clazz: KilomentrovnikHlaseniAdminList,
             endpoint: "admin/kilometrovnik-hlaseni",
             tableTitle: t("KilometrovnikHlaseni.title"),
             columns: [
                 {
                     title: t("KilometrovnikHlaseni.osrmId"),
                     field: 'osrmId',
                 },
                 {
                     title: t("KilometrovnikHlaseni.uzivatel"),
                     field: 'uzivatel',
                     render: (data: KilomentrovnikHlaseniAdminList) => `${data.uzivatel && data.uzivatel.login}${(data?.uzivatel?.jmeno && ` (${data.uzivatel.jmeno})`) || ""}`
                 },
                 {
                     title: t("KilometrovnikHlaseni.status"),
                     field: 'status',
                     render: (row) => t(`Enumerations.KilometrovnikHlaseniStatus.${row.status}`),
                     filterProps: () => ({type: FormInputType.Select, selectProps: stavSelectProps})
                 },
                 {
                     field:"datIns",
                     title:t("KilometrovnikHlaseni.datIns"),
                     render:data=>data.datIns?.format("L LT")
                 },
                 {
                     field:"datUzavreni",
                     title:t("KilometrovnikHlaseni.datUzavreni"),
                     render:data=>data.datUzavreni?.format("L LT")
                 },
             ],
             hideAddNewActions: true,
         }}
         clazzDetail={KilomentrovnikHlaseniAdminDetail}
         hideEditButton={true}
         hideNewButtonOnEdit={true}
         crudConfig={{editEnabled: true, removeEnabled:false, addEnabled:false}}
         layoutForm={() => <KilometrovnikHlaseniDetail/>}
         customButtons={(edited, data) => [
             data.status===KilometrovnikHlaseniStatus.NEW && <FormButton key={"update"} type={"update"} skipBlock
                    onSend={(form) => {
                        form.data.status=KilometrovnikHlaseniStatus.PROCESSED;
                        return {
                            modifyUrl: (url: string) => `${url}/${form.data.id}`,
                            type: "update"
                        }
                    }}>
                 {t("KilometrovnikHlaseni.process")}
             </FormButton>
         ]}
    />;
}

export function KilometrovnikHlaseniDetail() {
    const {t} = useTranslation();
    const stavSelectProps = useEnumeratedSelect(KilometrovnikHlaseniStatus, "Enumerations.KilometrovnikHlaseniStatus", "number");
    const userSelectProps = useUserBasicSelect({isClearable:true});
    const {data} = useCodeBookDetailContextNew<KilomentrovnikHlaseniBase>();

    return <Grid container spacing={2} direction="column">
        <Grid item container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField title={t("KilometrovnikHlaseni.status")} type={FormInputType.Select} name="status" selectProps={stavSelectProps} disabled/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField title={t("KilometrovnikHlaseni.uzivatel")} type={FormInputType.Select} name="uzivatel" selectProps={userSelectProps} disabled/>
            </Grid>
        </Grid>
        <Grid item container spacing={2}>
            <Grid item xs>
                <FormField title={t("KilometrovnikHlaseni.osrmId")} type={FormInputType.Text} name="osrmId" disabled/>
            </Grid>
            <Grid item xs>
                <FormField<any, Firma> name="osrmId" type={FormInputType.Custom} title={t("KilometrovnikHlaseni.osrmEdit")}
                                       customComponent={FormLinkButton}
                                       customComponentOptions={
                                           {
                                               onClick: (data: KilomentrovnikHlaseniAdminDetail) => {
                                                   window.open(getOsrmEditUrl(data.osrmId))
                                               }
                                           }
                                       }
                                       getValue={(data) => data}
                />
            </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={12} style={{marginLeft: 10, marginRight: 10, marginBottom: 10, marginTop: 5}}>
            <MapReport disabled id={data.osrmId} defaultPosition={new LatLng(50.0596288, 14.446459273258)} defaultZoom={18} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
            <FormField title={t("KilometrovnikHlaseni.report")} type={FormInputType.TextArea} name="report" disabled/>
        </Grid>
    </Grid>
}
