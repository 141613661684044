import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, Polyline, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import 'leaflet/dist/leaflet.css';
import L, { LatLng } from 'leaflet';
import H from '@here/maps-api-for-javascript';
import { RouteResponse, Section } from './HereResponse';
import { OsmPlace, reverse } from '../../../../../common/component/map/Nominatim';
import { carPlates, countryCodesIso2ToIso3 } from '../../../../model/HereProfilVozidla';
import { ViaItem } from './HereViaPoints';
import { Geometry } from '../../../../model/Geometry';
import { ButtonType } from './KilometrovnikLinkPart';
import { useTranslation } from 'react-i18next';

const LocationOnDivIcon = L.divIcon({
	className: 'custom-div-icon',
	html: `
    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 0 24 24" width="48">
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" fill="red"/>
    </svg>
  `,
	iconSize: [24, 24], // size of the icon
	iconAnchor: [12, 24], // point of the icon which will correspond to marker's location
});

L.Icon.Default.mergeOptions({
	iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
	iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
	shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

export class OSMLocationSelectorComponentProps {
	routing?: RouteResponse;
	tolls?: RouteResponse;
	viaItems: any[];
	setViaItems: React.Dispatch<React.SetStateAction<any>>;
	polyline: number[][];
	getHereTrasa: (buttonType: ButtonType) => Promise<void>;
}

export function OSMLocationSelectorComponent(props: OSMLocationSelectorComponentProps) {
	const {t} = useTranslation();
	const [open, setOpen] = useState(false);
	const [position, setPosition] = useState<LatLng | null>(null);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const getPolylines = (): number[][] => {
		let sections: Section[] = null;
		if (props.routing?.routes && props.routing.routes[0]) {
			sections = props.routing.routes[0].sections;
		}
		if (props.tolls?.routes && props.tolls.routes[0]) {
			sections = props.tolls.routes[0].sections;
		}
		let result: number[][] = [];
		sections?.forEach((section) => {
			// const sectionLatLngAltArray =  H.geo.LineString.fromFlexiblePolyline(section.polyline).getLatLngAltArray();
			// @ts-ignore
			const sectionLatLngAltArray = convertArrayToCoordinates(H.geo.LineString.fromFlexiblePolyline(section.polyline).T);
			result = [...result, ...sectionLatLngAltArray];
		});

		if (result.length == 0) {
			return props.polyline;
		} else {
			return result;
		}
	}

	const convertArrayToCoordinates = (inputArray: number[]) => {
		let result = [];
		for (let i = 0; i < inputArray.length; i += 3) {
			if (inputArray[i + 2] === 0) {  // Check if the third item is 0, which it should be according to your pattern
				result.push([inputArray[i], inputArray[i + 1]]);
			}
		}
		return result;
	}

	return (
		<>
			<Button type={"button"} variant="contained" color={"primary"} onClick={handleClickOpen}>
				{t("Here.TrasaDetail.VyberZMapy")}
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
				<DialogTitle>{t('Here.TrasaDetail.KliknutimVybertePrujedniBod')}</DialogTitle>
				<DialogContent style={{ padding: 0, height: '80vh' }}>
					{/*polyline={getPolylines()}*/}
					<MapComponentLeaflet position={position} setPosition={setPosition}
										 viaItems={props.viaItems} setViaItems={props.setViaItems} handleClose={handleClose}
										 polyline={getPolylines()} getHereTrasa={props.getHereTrasa} />
				</DialogContent>
				{/*<DialogActions>*/}
				{/*	<Button onClick={handleClose} color="primary">*/}
				{/*		Disagree*/}
				{/*	</Button>*/}
				{/*	<Button onClick={handleClose} color="primary" autoFocus>*/}
				{/*		Agree*/}
				{/*	</Button>*/}
				{/*</DialogActions>*/}
			</Dialog>
		</>);
}

interface LocationMarkerProps {
	position?: L.LatLng,
	setPosition?: React.Dispatch<React.SetStateAction<any>>,
	viaItems: any[],
	setViaItems: React.Dispatch<React.SetStateAction<any>>,
	handleClose: () => void,
	getHereTrasa: (buttonType: ButtonType) => Promise<void>,
}

export function LocationMarker({position = new L.LatLng(0, 0), setPosition = () => {}, ...props }: LocationMarkerProps) {
	useMapEvents({
		click(e) {
			setPosition(e.latlng);
			reverseGeo(e.latlng).then((viaItem) => {
				const itemExists = props.viaItems.some(existingItem => existingItem.id === viaItem.id);
				if (!itemExists) {
					props.setViaItems([...props.viaItems, viaItem.duplicate()]);
					// console.log('Item added:', viaItem);
				} else {
					// console.log('Item already exists:', viaItem);
				}
				props.handleClose();
			}).catch((e) => {
				console.log(e);
			})
		},
	});

	if (!position || !(position instanceof L.LatLng)) {
		return null;
	}

	return <Marker position={position} icon={LocationOnDivIcon}></Marker>;
}

interface MapComponentLeafletProps extends LocationMarkerProps {
	polyline: number[][];
	viaItems: any[];
	setViaItems: React.Dispatch<React.SetStateAction<any>>;
	handleClose: () => void;
	getHereTrasa: (buttonType: ButtonType) => Promise<void>;
}

export function MapComponentLeaflet(props: MapComponentLeafletProps) {
	return (
		<MapContainer center={[0, 0]} zoom={13} style={{ height: '100vh', width: '100%' }}>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
			/>
			{/*// @ts-ignore*/}
			<Polyline pathOptions={{ color: 'red' }} positions={props.polyline} />
			<SetupMap polyline={props.polyline} />
			<LocationMarker position={props.position} setPosition={props.setPosition} viaItems={props.viaItems} setViaItems={props.setViaItems}
							handleClose={props.handleClose} getHereTrasa={props.getHereTrasa} />
		</MapContainer>
	);
}

export function SetupMap({ polyline} : { polyline: number[][] }) {
	const map = useMap();

	useEffect(() => {
		if (polyline && polyline.length > 0) {
			// @ts-ignore
			const bounds = L.latLngBounds(polyline);
			map.fitBounds(bounds);
		}
	}, [map, polyline]);

	return (<></>);
}

const reverseGeo = async (latLng: LatLng): Promise<ViaItem> => {
	const resGeo: OsmPlace = await reverse(latLng);
	const koordinat = new Geometry(latLng.lat, latLng.lng);
	const item = {
		address: {
			postalCode: "",
			countryCode: "",
			city: "",
		},
		via: "",
		mistoKam: {
			koordinat: koordinat
		}
	};

	item.address.postalCode = resGeo.address.postcode ?? "*";
	item.address.countryCode = countryCodesIso2ToIso3[resGeo.address.country_code?.toUpperCase() ?? ""] ?? "";
	item.address.city = resGeo.address.city ?? resGeo.address.town ?? resGeo.address.municipality ?? resGeo.address.village ?? "";

	const mpz = carPlates[item.address.countryCode];
	const postalCode = item.address.postalCode?.toUpperCase().replace(/\s+/g, '');
	item.via = `${mpz}:${postalCode}:${item.address?.city?.toUpperCase()}`;

	return new ViaItem(item.via, item);
}