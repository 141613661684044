import {createStyles, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@material-ui/core";
import React, {PropsWithChildren} from "react";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import {exist} from "../utils/Util";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }),
);
type MuiModalProps = {
    open:boolean
    onClose:()=>void
    title?: React.ReactNode
    fullScreen?:boolean
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
	keepMounted?: boolean
}

export function MuiModal({open, children, onClose, title, keepMounted = true, ...props}:PropsWithChildren<MuiModalProps>) {
    const classes = useStyles();
    const handleClose = () => {
        onClose();
    };
    return (

        <Dialog
            open={open}
            keepMounted={keepMounted}
            fullScreen={props.fullScreen}
            maxWidth={exist(props.maxWidth) ? props.maxWidth : "lg"}

            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle disableTypography className={classes.root}>
                <Typography variant="h6" color={"inherit"}>{title}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={(e) => {
                        e.stopPropagation();
                        onClose&&onClose();
                    }}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    );
}
