import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {MomentConverters} from "./Converters";
import {Moment} from "moment";

export class DataTemplate {
    id: number;
    nazev: string;
    @JsonProperty({converters: MomentConverters})
    vytvoreno?: Moment;
    typDat: string;
    data: string;
}

export class DataTemplateForm {
    nazev: string;
    typDat: string;
    data: string;
}