import * as React from "react";
import {cleanName, FormFieldInterface, FormFieldInterfaceProps} from "./FormFieldInterface";
import {TextField, TextFieldProps, Tooltip} from "@material-ui/core";
import {exist} from "../../utils/Util";

export interface FormTextareaProps extends FormFieldInterfaceProps<any> {
    textFieldProps?: TextFieldProps,
    preventEmptyLines?: boolean
}

export class FormTextarea extends React.Component<FormTextareaProps> implements FormFieldInterface {
    dom:HTMLTextAreaElement;
    state = {value:this.props.value};
    userClick = false;

    componentDidMount(): void {
        this.props.enableFocusSupport();
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<FormTextareaProps>, nextContext: any): void {
        const triggerFocus = nextProps.focused !== this.props.focused && nextProps.focused;
        this.setState({value:nextProps.value}, ()=>{
            if(triggerFocus) {
                this.dom.focus();
            }
        });
    }

    onValueChange(value:any) {
        if (value && this.props.preventEmptyLines !== false)
            value = value.replace(/^\s*$(?:\r\n?|\n)/gm, "");

        this.setState({value:value}, ()=>{
            this.props.onValueChanged(this.state.value);
        });
    }

    render() {
        return (
            <Tooltip title={this.props.dataTip||""} disableHoverListener={!this.props.dataTip} disableFocusListener={!this.props.dataTip} disableTouchListener={!this.props.dataTip}>
                <TextField
                    inputProps={this.props.textFieldProps?.inputProps}
                    multiline
                    label={this.props.title}
                    hiddenLabel={!Boolean(this.props.title)}
                    minRows={this.props.textFieldProps?.rows ?? 4}
                    variant={"outlined"}
                    autoComplete={"off"}
                    inputRef={(input) => { this.dom = input; }}
                    id={cleanName(this.props.name)}
                    name={cleanName(this.props.name)}
                    value={!exist(this.state.value)?"":this.state.value}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                    onMouseDown={()=>this.userClick = true}
                    onMouseUp={()=>this.userClick = false}
                    onChange={e => {
                        this.onValueChange(e.target.value);
                    }}
                    onBlur={() => {
                        // Odebrat mezery na konci pokud existují
                        if (this.state.value?.slice(-1) === ' ') {
                            this.onValueChange(this.state.value?.trim());
                        }
                        this.props.onBlur&&this.props.onBlur();
                    }}
                    onFocus={()=>{
                        this.props.onFocus&&this.props.onFocus(this.userClick);
                    }}
                    onKeyDown={this.props.onKeyDown}
                    fullWidth
                    error={typeof this.props.error !== 'undefined'}
                    helperText={this.props.error}
                    InputProps={{
                        className: exist(this.state.value) ? this.props.inputClassName : ''
                    }}
                />
            </Tooltip>
        );
    }

}
