import {currentLocale} from "./LocaleAccessor";
import _ from 'lodash';
import Fingerprint2, {Options} from "fingerprintjs2";
import moment, {Moment} from "moment";
import {GenericMap} from "../../index.d";
import numeral from 'numeral';
import {Mapper} from "./objectmapper/Mapper";
import DataStorage from "../DataStorage";

export function clamp(v:number, min:number, max:number) { return v < min ? min : v > max ? max : v; }

export function loopNumber(v:number, min:number, max:number) { return v < min ? max : v > max ? min : v; }

export const formatPrice = (value:number, currency:string) => {
    return value.toLocaleString(currentLocale(), {style: 'currency', currency: currency});
};

export const formatNumber = (value:number, format:string=undefined) => {
    return numeral(value).format(format)
};

type jsonToFormDataType = {
    dataConverter?:(key:string, value:any)=>any
    skipNull?:boolean
};

export const jsonToFormUrlEncoded = (json:GenericMap, userOptions?:jsonToFormDataType) => {
    let formData = [] as string[];
    jsonTo({set:(name, value)=>{
            const encodedKey = encodeURIComponent(name);
            const encodedValue = encodeURIComponent(value);
            formData.push(encodedKey + "=" + encodedValue);
        }}, json, userOptions);
    return formData.join("&");
};
export const jsonToFormData = (json:GenericMap, userOptions?:jsonToFormDataType):FormData => {
    const formData = new FormData();
    jsonTo({set:(name, value)=>formData.set(name, value)}, json, userOptions);
    return formData;
};

export const jsonTo = ({set}:{set:(name:string, value:any)=>void}, json:GenericMap, userOptions?:jsonToFormDataType) => {
    const defaultOptions:jsonToFormDataType = {dataConverter:(key, value)=>value, skipNull:true};
    const {dataConverter, skipNull} = _.merge(defaultOptions, userOptions);
    Object.keys(json).forEach(e=>{
        const value = json[e];
        if(exist(value)) {
            const resultValue = dataConverter(e, value);
            if(resultValue !== null || !skipNull) {
                if(Array.isArray(resultValue)) {
                    for (let i = 0; i < resultValue.length; i++) {
                        let o = resultValue[i];
                        if(typeof o === "object") {
                            Object.keys(o).forEach(oe => {
                                set(`${e}[${i}].${oe}`, dataConverter(oe, o[oe]));
                            })
                        } else {
                            set(`${e}[${i}]`, o);
                        }
                    }
                    set(`${e}_length`, resultValue.length.toString())
                } else if(resultValue instanceof File) {
                    set(e, resultValue);
                } else if(typeof resultValue === "object") {
                    jsonTo({set:(name, value) => {
                        set(`${e}.${name}`, value);
                    }}, resultValue);
                } else {
                    set(e, resultValue);
                }
            }
        } else {
            set(e, "");
        }
    });
};

export const exist = (value?:any) => value !== null && value !== undefined;

export const isNotBlank = (value?:string) => exist(value) && value.trim().length > 0;

export class JsonList<E> {
    list:Array<E>;
}

export class ScrollableList<A> extends JsonList<A> {
    page: number;
    objectsPerPage: number;
    total: number;
    overallCount: number;
    pages: number;
    timestamp: string;
}
export type Constructor<Type> = {new():Type}

export const deepEqualFields = <T extends any>(left:T, right:T, exclude:string[] = null, excludeFieldsForIntegerCheck: string[] = null, excludeFieldsForTimeCheck: string[] = null) => {
    let newLeft:T = Object.assign({}, left);
    let newRight:T = Object.assign({}, right);
    if(exclude !== null) {
        exclude.forEach(key=>{
            //@ts-ignore
            delete newLeft[key];
            //@ts-ignore
            delete newRight[key];
        })
    }
    const diff = difference(newLeft, newRight, excludeFieldsForIntegerCheck, excludeFieldsForTimeCheck);
    return Object.keys(diff);
};

export const deepEqual = <T extends any>(left:T, right:T, exclude:string[] = null, excludeFieldsForIntegerCheck: string[] = null, excludeFieldsForTimeCheck: string[] = null, showDiff: boolean = false) => {
    let newLeft:T = Object.assign({}, left);
    let newRight:T = Object.assign({}, right);
    if(exclude !== null) {
        exclude.forEach(key=>{
            //@ts-ignore
            delete newLeft[key];
            //@ts-ignore
            delete newRight[key];
        })
    }
    const diff = difference(newLeft, newRight, excludeFieldsForIntegerCheck, excludeFieldsForTimeCheck);
    // K zobrazení rozdílu nastavit showDiff = true v definici metody
    if (showDiff && Object.keys(diff).length !== 0) {
        console.log("Deep equal", diff)
        console.log("Origin:", newLeft)
        console.log("Data:", newRight)
    }
    return Object.keys(diff).length === 0;
};

export const isDeepEqualNullIgnored = (obj1: any, obj2: any, falseAsNull = false): boolean => {
    const normalizeEmpty = (value: any) => {
        if(value === undefined) return null;
        if(typeof value === 'string' && value.trim() === '') return null;
        if(Array.isArray(value) && value.length === 0) return null;
        if(falseAsNull && value === false) return null;
        return value;
    }
    const compareValues = (obj1: any, obj2: any): boolean => {
        if (normalizeEmpty(obj1) === normalizeEmpty(obj2)) return true;
        return (typeof obj1 === 'object' && !Array.isArray(obj1) && normalizeEmpty(obj2) == null)
            || (typeof obj2 === 'object' && !Array.isArray(obj2) && normalizeEmpty(obj1) == null);
    }
    const compareObjectFromLeft = (obj1: any, obj2: any): boolean => {
        if (obj1 != null && typeof obj1 === 'object') {
            for (const key of Object.keys(obj1)){
                let obj2Val = obj2 != null && typeof obj2 === 'object' ? obj2[key] : null;
                if (typeof obj1[key] === 'object') {
                    if(!compareObjectFromLeft(obj1[key], obj2Val)) return false;
                } else {
                    if(!compareValues(obj1[key], obj2Val)) return false;
                }
            }
            return true;
        } else {
            return compareValues(obj1, obj2)
        }
    }
    return compareObjectFromLeft(obj1, obj2) && compareObjectFromLeft(obj2, obj1);
};

function difference(object:any, base:any, excludeFieldsForIntegerCheck: string[] = null, excludeFieldsForTimeCheck: string[] = null) {
    function changes(object:any, base:any) {
        return _.transform(object, function(result:any, value:any, key:any) {
            // Compare only integers
            let leftValue = isNumber(value) && !excludeFieldsForIntegerCheck?.includes(key) ? toInteger(value.toString()) : value;
            let rightValue = isNumber(base[key]) && !excludeFieldsForIntegerCheck?.includes(key) ? toInteger(base[key].toString()) : base[key];

            if (excludeFieldsForTimeCheck?.includes(key)) {
                if (moment(leftValue).isValid()) leftValue = moment(leftValue).format('LL')
                if (moment(rightValue).isValid()) rightValue = moment(rightValue).format('LL')
            }

            function normalizeEmptyValue<Type>(value:Type, nullValueType:()=>undefined|null = () => null):Type {
                if(
                    (typeof value === "boolean" && !value) ||
                    !exist(value) || //global
                    (typeof value === 'string' && value.trim().length === 0) || //strings
                    (Array.isArray(value) && value.length === 0) //arrays
                ) {
                    return nullValueType();
                }
                return value;
            }

            function isNumber(value: any) {
                return exist(value) && (!isNaN(value.toString()) || (value.toString().length > 1 && !isNaN(value.toString().slice(0, -1))));
            }

            function toInteger(value: string) {
                if (!exist(value)) return null;
                if (value.indexOf('.') !== -1) return value.split('.')[0];
                if (value.indexOf(',') !== -1) return value.split(',')[0];

                return value;
            }

            if (!_.isEqual(normalizeEmptyValue(leftValue), normalizeEmptyValue(rightValue))) {
                result[key] = (_.isObject(leftValue) && _.isObject(rightValue)) ? (
                    ()=>{
                        return changes(leftValue, rightValue);
                    }
                )() : leftValue;
            }
        });
    }
    const changesReversed = changes(base, object);
    const changesNormal = changes(object, base);
    return _.merge(changesNormal, changesReversed);
}

export function GetFingerPrint():Promise<string> {
    const options: Options = {excludes: {
            pixelRatio: true,
            hardwareConcurrency: true,
            screenResolution: true,
            availableScreenResolution: true,
            enumerateDevices: true,
            fonts: true,
            fontsFlash: true,
            canvas: true,
            audio: true,
            webgl: true,
            webglVendorAndRenderer: true
    }}

    return new Promise<string>((resolve => {
        setTimeout(function () {
            Fingerprint2.getV18(options, function (result) {
                resolve(result);
            })
        }, 500);// podle dokumentace
    }))
}

export function formatInterval(moment1:Moment, moment2:Moment):string {
    if(moment1.format("LL") === moment2.format("LL")) {
        return formatDate(moment2);
    }
    return `${moment1.format('D')}-${moment2.format('D')}.${moment2.format('M')}.`;
}

export function formatDate(moment:Moment): string {
    return `${moment.format('D')}.${moment.format('M')}.`;
}

export function withEndOfDayTime(m: Moment): Moment {
    if(!exist(m))
        return m;

    const n = moment(m)
    n.set(
        {
            hours: 23,
            minutes: 59,
            seconds: 0,
            milliseconds: 0
        }
    )

    return n;
}

export function timeout(timeout:number) {
    return new Promise(resolve => setTimeout(resolve, timeout))
}

export function cloneClassObject(obj:any, overrideData?:any) {
    return Object.assign(Object.create(obj), overrideData ?? obj);
}

export function removeEmptyValues(obj:any): any {
    let r:any = undefined;
    if(exist(obj)) {
        r = {}
        Object.keys(obj).forEach(value => {
            if (exist(obj[value]))
                r[value] = obj[value];
        })
    }
    return r;
}

export function mergeClassObjects(obj1:any, obj2?:any) {
    return _.merge(obj1, obj2);
}

export const distinctArray = <T extends any = any>(array:T[], selector:(item:T) => any) => {
    const set = [...new Set(array.map(selector))];
    return set.map(s=>array.find(f=>selector(f) === s));
} ;

export function int2ip (signedIpInt?: number) {
    if(exist(signedIpInt)) {
        const ipInt = signedIpInt < 0 ? 4294967296 + signedIpInt : signedIpInt;
        /* eslint-disable */
        return (ipInt & 255) + '.' + (ipInt >> 8 & 255) + '.' + (ipInt >> 16 & 255) + '.' + (ipInt >>> 24);
        /* eslint-enable */
    } else {
        return undefined;
    }
}



export function isDevMode() {
    return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
}

export const useHashParams = () => new URLSearchParams(window.location.hash.slice(1));

export const useQueryParams = () => new URLSearchParams(window.location.search);

export function truncateString(str:string, n:number){
    return (str.length > n) ? str.substr(0, n-1).trim() + '...' : str;
}

export const isNumber = (value: any): boolean => {
    const reg = new RegExp('^-?\\d+\\.?\\d*$');
    return exist(value) && reg.test(value)
}

export const isStringArray = (value: string): boolean => {
    return exist(value) && value.indexOf('[') !==-1 && value.indexOf(']') !==-1;
}

export const isStringObject = (value: string): boolean => {
    return exist(value) && value.indexOf('{') !==-1 && value.indexOf('}') !==-1;
}

export const dateAllowedChars = (value?: string): boolean => {
    const reg = new RegExp('^[0-9.]');
    return exist(value) && value.length === 1 ? reg.test(value) || value === ' ' : true
}

export const phoneAllowedChars = (value?: string): boolean => {
    const reg = new RegExp('^[0-9+]');
    return exist(value) && value.length === 1 ? reg.test(value) || value === ' ' : true
}

export const numbersOnly = (length:number) => textFieldRegexp(new RegExp(`^[0-9]{1,${length}}$`))

export const textFieldRegexp = (regExp:RegExp) => (value: string)=> {
    return regExp.test(value) ? value : value.slice(0, -1)
}

export const deepSearchInString = (object: any, key: string, predicate: any) => {
    let data = null;
    let splitKey = key.split('.');
    for (let i = 0; i < splitKey.length; i++) {
         data = deepSearch(object, splitKey[i], predicate)
    }
    return data;
};

export const deepSearch = (object: any, key: string, predicate: any) => {
    if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) return object

    for (let i = 0; i < Object.keys(object).length; i++) {
        let value = object[Object.keys(object)[i]];
        if (typeof value === "object" && value != null) {

            let o: any = deepSearch(object[Object.keys(object)[i]], key, predicate)
            if (o != null) return o
        }
    }
    return null
};

export const isObjectEmpty = (object: any): boolean => {
    if (!object) return true;

    const values = Object.values(object);
    const objectValues = values.filter(v => (exist(v) && typeof v === "object"));
    const propsValues = values.filter((v: any) => (exist(v) && typeof v !== "object" && v !== '' && v !== false));
    for (let i = 0; i < objectValues.length; i++){
        if (Object.values(objectValues[i]).some((o: any) => exist(o) && o !== '' && o !== false)) return false
    }
    return (_.isEmpty(propsValues));
}

export function evaluateBooleanOrFunction<T>(booleanOrFunction: boolean | ((data: T) => boolean), functionInputData: T): boolean {
    return exist(booleanOrFunction) && ((typeof booleanOrFunction !== 'function' && booleanOrFunction) || (typeof booleanOrFunction === 'function' && booleanOrFunction(functionInputData)))
}

export function evaluateStringOrFunction<T>(booleanOrFunction: string | ((data: T) => string), functionInputData: T): string {
    return exist(booleanOrFunction) && ((typeof booleanOrFunction!== 'function' && booleanOrFunction) || (typeof booleanOrFunction=== 'function' && booleanOrFunction(functionInputData)))
}

export interface MinDuration {
    hours: number;
    minutes: number;
}

export function secondsToMinDuration(seconds: number) : MinDuration {
    const round = Math.round(seconds / 60) * 60;
    return {
        hours: Math.trunc(round / 3600),
        minutes: Math.trunc(round / 60 % 60)
    }
}

export function evaluateObjectOrFunction<T>(objectOrFunction: T | (() => T)): T {
    // @ts-ignore
    return exist(objectOrFunction) && ((typeof objectOrFunction !== 'function' && objectOrFunction) || (typeof objectOrFunction === 'function' && objectOrFunction()))
}

export function objectToQueryParam(_map:GenericMap, prefix:string=""): string {
    return `${Object.keys(_map).filter(key => (exist(_map[key]))).map(key => {
        const value = _map[key];
        if(Array.isArray(value)) {
            //value.map((v, i) => objectToQueryParam(v, `${prefix}[${i}]`)).join("&");
            return value.map(m=>objectToQueryParam({[key]:m})).join("&");
        } else if(typeof value === "object") {
            return objectToQueryParam(value as GenericMap, `${prefix}${key}.`);
        } else {
            return `${encodeURI(prefix)}${encodeURI(key)}=${encodeURIComponent(`${value}`)}`;
        }
    }).filter(value=>value !== "").join("&")}`;
}

export function mergeNotEmptyData<T extends object>(oldData: T, newData: T, ...fields: string[]) {
    fields.forEach((value, index) => {
        const old = _.get(oldData, value);
        const n = _.get(newData, value);

        if(exist(old) && !exist(n)) {
            _.set(newData, value, old);
        }
    });

}

export function tryParse<A>(constructor: new() => A, input: string):A {
    if(!exist(input))
        return undefined;

    try {
        const o = JSON.parse(input);
        return Object.assign(new constructor(), o);
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

export function useDataStore<E>(constructor: new() => E, key: string, withToken: boolean = true, type: string = "local"): [() => E, (o: E) => void]  {
    const mapper = new Mapper<E>({constructor: constructor})
    const loadFunc = () => {
        const str = DataStorage.get(key, withToken, type);
        if(!exist(str))
            return undefined;
        return mapper.readValue(str);
    }
    const storeFunc = (obj: E) => {
        DataStorage.set(key, mapper.writeValueAsString(obj, {springSupport: false}), withToken, type);
    }

    return [loadFunc, storeFunc];
}

export function arrayReplaceOrAdd<T>(array: T[], item: T, index: number) {
    if (array.length > index) {
        array[index] = item
    }else {
        array.push(item)
    }
}

export function getBoolean(value: any){
    switch(value){
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}

export function capitalizeFirst(string?: string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
}

export const charsCount = (data: string, char: string) => {
    return [...data].reduce((a, c) => c === char ? ++a : a, 0);
}

export const deepCompareIsDate = (data: string) => {
    if (!moment(data, moment.ISO_8601, true).isValid()) return false;
    if (charsCount(data, '.') > 1) return true;
    if (charsCount(data, '/') > 1) return true;
    if (charsCount(data, '-') > 1) return true;
    return charsCount(data, ':') > 1;
}

export const findHashParam = (hash: string, param: string): string => {
    if (hash && hash.indexOf(param) !== -1){
        const tempHash = hash.replace('#', '').split('&');
        for (let i = 0; i < tempHash.length; i++) {
            const h = tempHash[i].split('=');
            if (h[0] === param) {
                return h[1];
            }
        }
        return null;
    }
}

export const isMobileDevice = () => {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
