import {Moment} from "moment";
import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {MomentConverters} from "./Converters";

export class ReplicationInfo {
 useName?: string;
 applicationName?: string;
 clientAddr?: string;
 @JsonProperty({converters: MomentConverters})
 backendStart?: Moment;
 state?: string;
 syncState?: string;
}

export class HazelcastInstance {
    name?: string;
    cluster?: Cluster;
}

export class Cluster {
    members?: Member[]
    clusterTime?: number
}

export class DetailStatusItem {
    detailName?: string;
    detailValue?: string;
}

export class SubsystemStatus {
    up?: boolean;
    name?: string;
    details?: DetailStatusItem[];
}

export class Member {
    localMember?: boolean;
    liteMember?: boolean;
    uuid?: string;
    address?: DiagAddress;
    healthy?: boolean;
    fetchErrors?: string[];
    nodeDiag?: NodeDiag;
    subsystemStatuses: SubsystemStatus[];
}

export class DiagAddress {
    host?: string;
    port?: number;
    serverPort?: number;
}

export class ReadWritePairDiag<T> {
    read?: T;
    write?: T;
}

export class QuartzTriggerItem {
    schedName?: string;
    state?: string;
    triggerType?: string;
    count?: number;
}

export class QuartzFiredTriggerItem {
    schedName?: string;
    state?: string;
    count?: number;
}

export class JobsDiag {
    triggers: QuartzTriggerItem[];
    firedTriggers: QuartzFiredTriggerItem[];
}

export class SchedulerStateDiag {
    schedulerThreads?: number;
    maxCalculationJobs?: number;
}

export class NodeDiag {
    databasePool?: ReadWritePairDiag<HikariPoolSettings>;
    database?: ReadWritePairDiag<DataSourceDiag>;
    caches: CacheDiag[];
    rateLimitSnapshot: RateLimitSnapshotItem[];
    missingProperties: string[];
    scheduler: SchedulerStateDiag;
}

export class HikariPoolSettings {
    maximumPoolSize?: number;
    minimumIdle?: number;
    totalConnections?: number;
    activeConnections?: number;
    idleConnections?: number;
}

export class DataSourceDiag {
    maxConnections?: number;
    connections?: number;
    reservedConnections?: number;
}

export class CacheDiag {
    cacheName?: string;
    size?: number;
}

export class RateLimitSnapshotItem {
    name?: string;
    runningCount?: number;
    latency?: number;
}

export class DiagReturn {
    @JsonProperty({type:{clazz:HazelcastInstance}})
    hazelcastInstance?: HazelcastInstance;

    @JsonProperty({type:{clazz:ReplicationInfo, isArray:true}})
    replicationInfos: ReplicationInfo[];

    @JsonProperty({type:{clazz:JobsDiag}})
    jobsDiag?: JobsDiag
}
