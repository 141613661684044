import {useTranslation} from "react-i18next";
import {isNumber} from "../../common/utils/Util";

export enum CiselnikTyp {
    I="I",
    P="P",
    V="V",
    R="R"
}

enum CiselnikTypTexts {
    I="InvalidDuvodInzerat",
    P="InvalDuvodPreprava",
    V="InvalDuvodVozidlo",
    R="Druh"
}

export type Ciselnik = {
    kod: number;
    hodnota: string;
}

export const useCiselnik = (typ: CiselnikTyp): Ciselnik[] => {
    const {i18n} = useTranslation();
    let ciselnik = i18n.getResource(i18n.language, "translations", `Enumerations.${CiselnikTypTexts[typ]}`);
    // Pokud není překlad
    if (!ciselnik) ciselnik = i18n.getResource("cs", "translations", `Enumerations.${CiselnikTypTexts[typ]}`);

    return ciselnik ? Object.entries(ciselnik).map(([c, v]) => {
        return {kod: isNumber(c) ? Number(c) : -1, hodnota: v.toString()}
    }).filter(d => d.kod !== -1) : [];
}