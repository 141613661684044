import {Grid} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";

export const useKilometrovnikShared = () => {
    const {t} = useTranslation();

    const KilometrovnikFilterDataItem = (title: string, data?: any, separator: boolean = true) => {
        return <Grid item style={separator ? {borderRight: '1px solid black', marginBottom: 5} : {marginBottom: 5}}>
            <Grid container direction={"column"} alignItems={"center"} style={{padding: '0 5px'}}>
                <Grid item style={{textAlign: "center"}} xs={12}>
                    <span>{title ? `${title}` : null}</span>
                </Grid>
                <Grid item style={{textAlign: "center"}}>
                    <span style={{fontWeight: 700}}>{data}</span>
                </Grid>
            </Grid>
        </Grid>
    }


    const getNSJ = (data?: any) => {

        const nsj = []
        if (data?.naves) nsj.push(t("Preprava.naves"))
        if (data?.souprava) nsj.push(t("Preprava.souprava"))
        if (data?.jine) nsj.push(t("Preprava.jine"))
        return nsj.length !== 0 ? nsj.join(', ') : null
    }

    return {KilometrovnikFilterDataItem, getNSJ}
}
