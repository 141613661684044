import { useState, useEffect } from 'react';

function useConfig() {
	const [config, setConfig] = useState({
		environmentName: '',
		environmentColor: '',
	});

	useEffect(() => {
		fetch('/config.json')
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				setConfig(data);
			})
			.catch((error) => {
				// swallow
				// console.error('Error:', error);
			});
	}, []);

	return config;
}

export default useConfig;