
export class SimpleValue {
    value: string
}

export class SimpleValueBoolean {
    value: boolean

    static of(value:boolean): SimpleValueBoolean {
        const v = new SimpleValueBoolean();
        v.value = value;
        return v;
    }
}