import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {UserBasic} from "./User";
import {MomentConverters} from "./Converters";
import {Moment} from "moment";

export class NadpripadPoznamka {
    id: number;

    poznamka: string;

    @JsonProperty({type:{clazz:UserBasic}})
    uzivatel: UserBasic;

    @JsonProperty({converters:MomentConverters})
    datIns: Moment;
}
