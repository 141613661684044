import {Firma} from "../../web/model/Firma";
import {Podstav, Provozovna, Stav} from "../../web/model/Provozovna";
import {useCallback, useEffect, useState} from "react";

const zkraceneInformace =
    ["provozovna.kod", "provozovna.pNazev", "provozovna.pStat", "provozovna.datZmenStav", "provozovna.podstav",
        "firma.r1Nazev", "firma.r1Stat"];

const nonGdprInformace = 
    ["provozovna.kod", "provozovna.pNazev", "provozovna.pObec", "provozovna.pStat",
    "firma.r1Nazev", "firma.r1Obec", "firma.r1Stat", "firma.regDatum", "firma.datSmlouvy"];

const omezeneInformace = [...nonGdprInformace, "provozovna.datovaSchranka", "firma.http", "provozovna.email", "provozovna.spStat", "provozovna.jazyky", "provozovna.auta", "provozovna.not1", "firma.pojisteni", "provozovna.uOsobaUser", "provozovna.uTelefonUser", "provozovna.uEmailUser", "provozovna.telCislaKontaktu"];

export function useEvaluateFieldsVisibility(p: Provozovna, f: Firma, admin: boolean) : (path: string | string[]) => boolean {
    const [zkracene, setZkracene] = useState(false);
    const [omezene, setOmezene] = useState(false);
    const [nonGdpr, setNonGdpr] = useState(false);

    useEffect(() => {
            setZkracene(false);
            setOmezene(false);
            setNonGdpr(false);

            if(!admin && p!=null) {
                if(p) {
                    if (p.stav === Stav.POZASTAVIL && (p.podstav !== Podstav.BEZ_PODSTAVU && p.podstav !== Podstav.PROBLEM)) {
                        setZkracene(true);
                    }
                }
                if(f) {
                    if (f.gdpr) {
                        setOmezene(p.omezeneInformace);
                    } else {
                        setNonGdpr(true);
                    }
                }
            }
        }
        , [p, f, admin]);

    return useCallback((path: string | string[]) => {
            const arg: string[] = typeof (path) === 'string' ? [path] : path;
            let r = false;
            arg.forEach((k) => {
                let v = true;
                if (zkracene)
                    v = v && zkraceneInformace.indexOf(k) > -1;
                if (omezene)
                    v = v && omezeneInformace.indexOf(k) > -1;
                if (nonGdpr)
                    v = v && nonGdprInformace.indexOf(k) > -1;
                r = r || v;
            });
            return r;
        }, [zkracene, omezene, nonGdpr]
    );
}

export const evaluateDataVisibility = (p: Provozovna, f: Firma, path:string | string[], admin: boolean) => {
    let zkracene = false;
    let omezene = false;
    let nonGdpr = false;

    if(!admin && p!=null) {
        if(p) {
            if (p.stav === Stav.POZASTAVIL && (p.podstav !== Podstav.BEZ_PODSTAVU && p.podstav !== Podstav.PROBLEM)) {
                zkracene = true
            }
        }
        if(f) {
            if (f.gdpr) {
                omezene = p.omezeneInformace;
            } else {
                nonGdpr = true;
            }
        }
    }
    const arg: string[] = typeof (path) === 'string' ? [path] : path;
    let r = false;
    arg.forEach((k) => {
        let v = true;
        if (zkracene)
            v = v && zkraceneInformace.indexOf(k) > -1;
        if (omezene)
            v = v && omezeneInformace.indexOf(k) > -1;
        if (nonGdpr)
            v = v && nonGdprInformace.indexOf(k) > -1;
        r = r || v;
    });
    return r;
}