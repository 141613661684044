import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {PrejezdFilter, PrejezdFilterZadani, VPFilter, VPList} from "../../../../model/PrepravaVozidlo";
import * as React from "react";
import {useState} from "react";
import {ModalPrejezdFilterForm} from "./ModalPrejezdFilter";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import {useTranslation} from "react-i18next";
import {Box, Card, CardContent, Typography} from "@material-ui/core";
import {KilometrovnikLinkType} from "./KilometrovnikLinkPart";
import {Dial} from "../../../../raal_components/dial/Dial";
import {Preprava, PrepravyList} from "../../../../model/Preprava";
import {getFilterVersion} from "../_vp/PrepravaAVozidlaShared";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {ExtendedViewNew} from "../_vp/VPExtendedView";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {VozidlaList, Vozidlo} from "../../../../model/Vozidlo";
import {ComponentMode} from "../../../../routes";

export type DefaultVP = {
    columns: Column<VPList>[]
    filterData: PrejezdFilter
    id: number
}

export type FilterLayoutProps = {
    first: boolean
    filterData: PrejezdFilterZadani
    setFirst: (state: boolean) => void
    type: KilometrovnikLinkType
    title: string
    redirectAfterSend?: boolean
}

export const FilterLayout = ({first, filterData, setFirst, type, title, redirectAfterSend}:FilterLayoutProps) => {
    const {classes} = useStyleContext();
    return first ?
        <Card elevation={0} className={classes.dialogBackground}>
            <CardContent>
                <Box marginBottom={2}>
                    <Typography variant="h6">{title}</Typography>
                </Box>
                <Box marginLeft={1} marginTop={3} marginRight={1} marginBottom={0}>
                    <ModalPrejezdFilterForm type={type} filter={filterData} onSend={() => setFirst(false)} redirectAfterSend={redirectAfterSend ?? true} />
                </Box>
            </CardContent>
        </Card> : null
}

export const DefaultPreprava = ({columns, filterData, id}: DefaultVP) => {
    const {t} = useTranslation();
    const [colsDefault] = useState(columns);
    const endpointPreprava = "user/prepravaview";

    return <div style={{marginBottom: '3rem'}}>
        <Dial<PrepravyList, VPFilter, Preprava>
            mode={ComponentMode.GridMode}
            config={{
                id: "prejezd-preprava-default",
                lockSupport: {enabled: false},
                overflowHidden: true,
                watchChanges: true,
                hideItemAge: true,
                version:getFilterVersion(),
                tableTitle: t("Prejezdy.Preprava"),
                endpoint: endpointPreprava,
                clazz: PrepravyList,
                filtering: false,
                hideAddNewActions: true,
                columns: colsDefault,
                hideDefaultDummyAction:true,
                extendedFilter: false,
                defaultQueryParameters: {id: id},
                defaultQueryParametersClz: VPFilter,
                stomp:{
                    topic: `/crud-preprava`,
                    toggleable: true,
                    allowStompUiUpdates: true
                },
            }}
            logActivity
            clazzDetail={Preprava}
            layoutDetail={()=><TabHelperNew<Preprava> render={(data)=> {
                return <>
                    <ExtendedViewNew ciselnikTyp={CiselnikTyp.P} data={data} admin={false} archive={false} prejezdProps={
                        {
                            trasa: data.getMista().map(value => value.koordinat),
                            prejezdOd: filterData.prejezdOdWaypoint,
                            prejezdKam: filterData.prejezdKamWaypoint
                        }
                    }/>
                </>;
            }}/>}
            modalHeaderName={() => t("Prejezdy.Preprava")}
            pagingDisabled
            crudConfig={{editEnabled: false, removeEnabled: false, addEnabled: false}}
            filterClazz={VPFilter}
            tabDetailUrl={'/prejezdy/prepravy'}
        />
    </div>
}

export const DefaultVozidlo = ({columns, filterData, id}: DefaultVP) => {
    const {t} = useTranslation();
    const [colsDefault] = useState(columns);
    const endpointVozidlo = "user/vozidloview";

    return <div style={{marginBottom: '3rem'}}>
        <Dial<VozidlaList, VPFilter, Vozidlo>
            mode={ComponentMode.GridMode}
            config={{
                id: "prejezd-vozidlo-default",
                lockSupport: {enabled: false},
                overflowHidden: true,
                version:getFilterVersion(),
                watchChanges: true,
                hideItemAge: true,
                tableTitle: t("Prejezdy.Vozidlo"),
                endpoint: endpointVozidlo,
                clazz: VozidlaList,
                filtering: false,
                hideAddNewActions: true,
                columns: colsDefault,
                hideDefaultDummyAction:true,
                extendedFilter: false,
                defaultQueryParameters: {id: id },
                defaultQueryParametersClz: VPFilter,
                stomp:{
                    topic: `/crud-vozidlo`,
                    toggleable: true,
                    allowStompUiUpdates: true
                },
            }}
            logActivity
            clazzDetail={Vozidlo}
            layoutDetail={()=><TabHelperNew<Vozidlo> render={(data)=> {
                return <>
                    <ExtendedViewNew ciselnikTyp={CiselnikTyp.P} data={data} admin={false} archive={false} prejezdProps={
                        {
                            trasa: data.getMista().map(value => value.koordinat),
                            prejezdOd: filterData.prejezdOdWaypoint,
                            prejezdKam: filterData.prejezdKamWaypoint
                        }
                    }/>
                </>;
            }}/>}
            modalHeaderName={() => t("Prejezdy.Vozidlo")}
            pagingDisabled
            crudConfig={{editEnabled: false, removeEnabled: false, addEnabled: false}}
            filterClazz={VPFilter}
            tabDetailUrl={'/prejezdy/volnevozy'}
        />
    </div>
}

export const convertFilterData = (filter?: PrejezdFilterZadani, setMin?: boolean): PrejezdFilter => {
    if (!filter) return null;
    // @ts-ignore
    const prejezdFilter = {
        okoliOdkud: filter.prejezdOdWaypoint && {
            osmPlace: filter.prejezdOdWaypoint.nazevMista,
            countryCode: filter.prejezdOdWaypoint.countryCode,
            koordinat: filter.prejezdOdWaypoint.koordinat
        },
        okoliKam: filter.prejezdKamWaypoint && {
            osmPlace: filter.prejezdKamWaypoint.nazevMista,
            countryCode: filter.prejezdKamWaypoint.countryCode,
            koordinat: filter.prejezdKamWaypoint.koordinat
        },
        excludedNabidkaId: filter.excludedNabidkaId,
        delkaRange: filter.delka && setMin ? {min: filter.delka} : {max: filter.delka},
        vahaRange: filter.vaha && setMin ? {min: filter.vaha} : {max: filter.vaha},
        naves: filter.naves,
        souprava: filter.souprava,
        jine: filter.jine,
        druhy: filter.druhyPrejezd && [...filter.druhyPrejezd],
        vpRange: filter.vpRange,
        nabidkaId: filter.nabidkaId,
        prejezdRange: filter.dPrejezd && {max: filter.dPrejezd * 1000},
        prejezdOdRange: filter.dPrejezdOd && {max: filter.dPrejezdOd * 1000},
        prejezdKamRange: filter.dPrejezdKam && {max: filter.dPrejezdKam * 1000},
        prejezdOdWaypoint: filter.prejezdOdWaypoint,
        prejezdKamWaypoint: filter.prejezdKamWaypoint
    }

    return prejezdFilter as PrejezdFilter;
}

export const convertFilterZadaniData = (prejezdFilter: PrejezdFilter): PrejezdFilterZadani => {
    if (!prejezdFilter) return null;
    return {
        prejezdOdWaypoint: prejezdFilter.prejezdOdWaypoint,
        prejezdKamWaypoint: prejezdFilter.prejezdKamWaypoint,
        excludedNabidkaId: prejezdFilter.excludedNabidkaId,
        delka: prejezdFilter.delkaRange?.min || prejezdFilter.delkaRange?.max,
        vaha: prejezdFilter.vahaRange?.min || prejezdFilter.vahaRange?.max,
        naves: prejezdFilter.naves,
        souprava: prejezdFilter.souprava,
        jine: prejezdFilter.jine,
        druhyPrejezd: prejezdFilter.druhy,
        vpRange: prejezdFilter.vpRange,
        nabidkaId: prejezdFilter.nabidkaId,
        dPrejezd: prejezdFilter.prejezdRange ? prejezdFilter.prejezdRange.max / 1000 : undefined,
        dPrejezdOd: prejezdFilter.prejezdOdRange ? prejezdFilter.prejezdOdRange.max / 1000 : undefined,
        dPrejezdKam: prejezdFilter.prejezdKamRange ? prejezdFilter.prejezdKamRange.max / 1000 : undefined,
    } as PrejezdFilterZadani;
};