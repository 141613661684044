import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {MomentConverters} from "./Converters";
import {Moment} from "moment/moment";

export enum TypOznameniCms {
    PO_PRIHLASENI="PO_PRIHLASENI",
    OKAMZITE="OKAMZITE"
}

export class CmsClanek {
    id: number;
    @JsonProperty({converters: MomentConverters})
    datumVytvoreni?: Moment;
    titulek: string;
    obsah: string;
}
export class CmsClanekOznameni extends CmsClanek {
    kategorie: string;
    oznameniId: number;
}
