const cs = {
  "MobileApp": {
    "AppLoading": {
      "CompanyInfo": "První databanka nákladů a volných vozů od roku 1992."
    },
    "Buttons": {
      "Contact": "Kontaktovat"
    },
    "Login": {
      "InvalidCredentials": "Neplatné přihlašovací údaje"
    },
    "ConfigureParameters": {
      "NavigationTitle": "Upravit zobrazení",
      "SectionTitle": "Vyberte údaje k zobrazení na kartě nabídky"
    },
    "SortParameters": {
      "NavigationTitle": "Seřadit seznam podle",
      "SortSectionTitle": "Vyberte směr řazení:",
      "ParameterSectionTitle": "Vyberte parametr, podle kterého seznam seřadit:",
      "Descending": "Sestupně",
      "Ascending": "Vzestupně"
    },
    "FilterParameters": {
      "CancelFilters": "Zrušit filtry",
      "Filter": "Filtrovat"
    },
    "Cargos": {
      "FilterCargos": "Filtrovat přepravy",
      "AdditionalInfo": "Doplňující informace o přepravě",
      "ChooseKinds": "Vyberte druhy",
      "All": "Vše",
      "NewCargo": "Nová přeprava",
      "EditCargo": "Editace přeprava",
      "FillBasicInfo": "Vyplňte základní informace o přepravě",
      "CargoDetail": "Detail přepravy",
      "Distance": "Vzdálenost"
    },
    "Lorries": {
      "FilterLorry": "Filtrovat vozy",
      "NewLorry": "Nový vůz",
      "FillBasicInfo": "Vyplňte základní informace o voze",
      "AdditionalInfo": "Doplňující informace o vozu",
      "LorryDetail": "Detail vozu",
      "EditLorry": "Upravit vůz"
    },
    "Advertising": {
      "FilterAdvertising": "Filtrovat inzeráty",
      "NewAdvertising": "Nový inzerát",
      "EditAdvertising": "Editace inzerát",
      "AdditionalInfo": "Doplňující informace",
      "FillBasicInfo": "Vyplňte základní informace",
      "AdvertisingDetail": "Detail inzerátu"
    },
    "Premises": {
      "FilterPremises": "Filtrovat provozovny",
      "StreetNumber": "Ulice + ČP",
      "Registration": "Registrace",
      "ContractDate": "Smlouva /V RAAL od",
      "DetailInfo": "Detailní informace",
      "Accountants": "Účetní"
    },
    "Tabs": {
      "Settings": "Nastavení",
      "Cargos": "Přepravy",
      "Lorries": "Vozy",
      "Advertising": "Inzerce"
    },
    "Settings": {
      "AppLanguage": "Jazyk aplikace",
      "AppAppearance": "Vzhled aplikace",
      "AppSupport": "Uživatelská podpora",
      "AppSupportDescription": "V případě potíží nás nevahejte kontaktovat telefonicky nebo  prostřednictvím emailu.",
      "AppSupportHtmlContent": "<p>email.: <a href=\"mailto:support@raal.cz\"  target=\"_self\">support@raal.cz</a>&nbsp;</p>                                                                                                                                 <p>mob.: +420 604 105 758</p>                                                                                                                                 <p>tel.: +420 495 217 281</p>                                                                                                                                 <p>tel.: +420 495 217 282</p>",
      "AppVersion": "Verze {0}",
      "ChooseAppLanguage": "Vyberte jazyk aplikace",
      "ChooseAppAppearance": "Vyberte vzhled aplikace",
      "DarkMode": "Tmavý",
      "LightMode": "Světlý",
      "SystemMode": "Systémové nastavení"
    }
  },
  "AppTitle": "Raaltrans",
  "AppActivityType": {
    "View": "Prohlížení"
  },
  "AppActivitySubType": {
    "Detail": "Detail",
    "Row": "Řádek"
  },
  "AutoRefresh": {
    "true": "Automatické načítání ZAPNUTO (CTRL+Mezerník)",
    "false": "Automatické načítání VYPNUTO (CTRL+Mezerník)",
    "title": "Auto-načítání",
    "NewData": "Nová data"
  },
  "RefreshAlert": {
    "TooltipOn": "Zvukové upozornění na nové nabídky ZAPNUTO",
    "TooltipOff": "Zvukové upozornění na nové nabídky VYPNUTO",
    "TooltipInactive": "Zvukové upozornění POZASTAVENO (pro obnovení je na stránce provést nějakou akci)"
  },
  "Buttons": {
    "Rejstriky": "Rejstříky",
    "MakeInvalid": "Zneplatnit",
    "MakeValid": "Obnovit",
    "Help": "Nápověda",
    "Login": "Přihlásit",
    "Logout": "Odhlásit",
    "Save": "Uložit",
    "SaveAsNew": "Uložit jako nový",
    "SaveAsCurrent": "Uložit jako stávající",
    "Delete": "Smazat",
    "Refresh": "Obnovit",
    "AddNewRecord": "Přidat nový záznam",
    "Cancel": "Zrušit",
    "Confirm": "Potvrdit",
    "Add": "Přidat",
    "ReloadWait": "Načíst znovu s delším čekáním",
    "Close": "Zavřít",
    "Continue": "Pokračovat",
    "Cancellation": "Storno",
    "Agree": "Souhlasím",
    "SendTheFirstAccessData": "Odeslat 1. přístupové údaje",
    "Check": "Zkontrolovat"
  },
  "ConfirmDialog": {
    "Title": "Potvrzení",
    "Body": "Opravdu chcete akci provést?"
  },
  "DataChangedDialog": {
    "Title": "Rozpracovaný formulář",
    "Body": "Máte rozpracovaný formulář, opravdu chcete opustit stránku?\nVšechna rozpracovaná data budou ztracena."
  },
  "Currency": {
    "Title": "Měna",
    "name": "Název",
    "currencyCode": "Kód",
    "kurz": "Kurz (Kč)",
    "datum": "Synchronizováno"
  },
  "DataContextChanged": {
    "title": "Došlo ke změně dat. Načtěte nová data pomocí F5, nebo stiskem tlačítka.\nUpozornění: Všechna rozpracovaná data budou ztracena."
  },
  "Default": {
    "Id": "ID",
    "Enabled": "Povolený",
    "Telefon": "Telefon",
    "QuickContact": "Rychlý kontakt",
    "PhoneNumbers": "Telefonní č.",
    "PhoneNumbersKratke": "Tel. č.",
    "FillCorrectPhone": "Vyplňte správné telefonní číslo",
    "Email": "Email",
    "Polozka": "Položka",
    "Text": "Text",
    "Key": "Klíč",
    "Note": "Poznámka",
    "NoteAdmin": "Administrátorská poznámka",
    "NoteMaster": "Masterova poznámka",
    "Slovo": "Slovo",
    "Name": "Jméno",
    "DataName": "Název",
    "Map": "Mapa",
    "Mpz": "MPZ",
    "MapDefine": "Zadání souřadnic",
    "Loading": "Načítání",
    "ModalZakladni": "Detail záznamu",
    "TabZakladni": "Základní údaje",
    "region": "Typ nabídek",
    "typ": "Typ nabídek",
    "typLicence": "Kilometrovník",
    "Datum": "Datum",
    "DatCas": "Čas poslední změny",
    "Jazyky": "Jazyky",
    "Yes": "Ano",
    "No": "Ne",
    "Or": "Nebo",
    "LoadingDetail": "Načítám detail",
    "LoadingDetailFailed": "Načítání detailu selhalo",
    "NotFound": "Záznam nebyl nalezen",
    "DeleteText": "Opravdu chcete smazat tento záznam?",
    "DeleteTitle": "Smazání záznamu",
    "Account": "Uživatelské účty",
    "AccountAdd": "Přidat účet",
    "UnlockText": "Opravdu si přejete odemknout uzamčený \n detail na tomto tabu?",
    "UnlockTitle": "Odemknutí záznamu",
    "PdfHeader": "Nabídka {0} číslo {1} a její zadavatel",
    "PdfFirma": "Úplné informace o provozovně {0}",
    "PdfExportedUser": "Exportoval",
    "PdfExportedDate": "Datum",
    "SendTheFirstAccessDataText2023": "Tato akce je primárně určena pro provozovny, které byly aktivní (Stav JEDE) před koncem roku 2023. Opravdu si přejete pokračovat? ",
    "SendTheFirstAccessDataTitle2023": "Odeslání prvních přístupových údajů (2023)",
    "SendTheFirstAccessDataText2024": "Tato akce je primárně určena pro provozovny, které budou aktivovány až od začátku roku 2024. Opravdu si přejete pokračovat? ",
    "SendTheFirstAccessDataTitle2024": "Odeslání prvních přístupových údajů (2024)",
    "Jednotka": "Jednotka",
    "JednotkaShort": "MJ"
  },
  "Dials": {
    "Users": "Uživatelé",
    "Companies": "Firmy",
    "Places": "Provozovny",
    "Countries": "Státy",
    "Currencies": "Měny a kurzy",
    "Types": "Druhy",
    "Params": "Parametry",
    "Jobs": "Joby",
    "ZakazanaSlova": "Zakázaná slova",
    "NevhodnaSlovaFirma": "Hlídaná slova u firem",
    "HlidanaSlova": "Hlídaná slova",
    "VolneVozy": "Volné vozy",
    "Dispeceri": "Dispečeři",
    "Prepravy": "Přepravy",
    "Advertising": "Inzerce",
    "Messages": "Zprávy",
    "ThunderMessages": "Bleskovky",
    "HlidanaSlovaKonfiguraceFirem": "Konfigurace firem",
    "Pvi": "Vlastní PVI",
    "PviShort": "PVI",
    "Firma": "Firma",
    "Provozovna": "Provozovna",
    "HistoryPrepravy": "Přepravy",
    "HistoryAdvertising": "Inzerce",
    "HistoryVolneVozy": "Volné vozy",
    "Kilometrovnik": "Místa",
    "ProfilyVozidel": "Seznam vozidel",
    "Trasy": "Trasy",
    "PrepravaTrasa": "Trasa - Přeprava",
    "VozidloTrasa": "Trasa - Vozidlo",
    "KilometrovnikHlaseni": "Hlášení chyb",
    "KilometrovnikHlaseniAdmin": "Hlášené chyby",
    "PrejezdyPreprav": "Přejezdy přeprav",
    "PrejezdyVozidel": "Přejezdy vozidel",
    "Dokladky": "Dokládky",
    "DiagPage": "Diagnostika",
    "Notes": "Poznámky",
    "NepohodlneOsoby": "Nepohodlné osoby",
    "Pripady": "Případy",
    "Nadpripady": "Nadpřípady",
    "Faktury": "Faktury",
    "ReportTexts": "Překlady textů",
    "TriADost": "3x a dost",
    "Smlouvy": "Smlouvy"
  },
  "DialDefaults": {
    "CreateRecord": "Vytvořit záznam",
    "CreateRecordAndNext": "Vytvořit a další",
    "CreateRecordAsNew": "Vytvořit záznam jako nový",
    "UpdateRecord": "Uložit změny",
    "DeleteRecord": "Smazat",
    "ClearField": "Vyprázdnit",
    "UnlockRecord": "Odemknout záznam",
    "ServerError": "Chyba serveru",
    "ServerErrorMessage": "Neočekávaná chyba serveru",
    "ServerTimeoutMessage": "Vypršel časový limit"
  },
  "Prejezdy": {
    "odkud": "Přejezd odkud",
    "kam": "Přejezd kam",
    "nabidkaOdkud": "Nabídka - odkud",
    "nabidkaKam": "Nabídka - kam",
    "radiusNabidkaOdkud": "Nabídka - odkud [km]",
    "radiusNabidkaKam": "Nabídka - kam [km]",
    "prejezdy": "Přejezdy",
    "volneVozy": "Volné vozy",
    "prepravy": "Přepravy",
    "prejezd": "S přejezdem",
    "prejezdOnly": "Pouze přejezd",
    "original": "Původní",
    "found": "Nalezená",
    "prejezdCelkem": "P-Celkem",
    "prejezdOd": "P-Odkud",
    "prejezdKam": "P-Kam",
    "radiusPrejezd": "Maximální přejezd celkem [km]",
    "radiusPrejezdOd": "Maximální přejezd odkud [km]",
    "radiusPrejezdKam": "Maximální přejezd kam [km]",
    "kilometrovnikPrejezd": "Kilometrovník přejezd",
    "vyhledaniPrepravy": "Přejezdy, vyhledání přepravy",
    "vyhledaniVozu": "Přejezdy, vyhledání volného vozu",
    "calculate": "Hledat přejezdy",
    "Preprava": "Výchozí přeprava",
    "Vozidlo": "Výchozí vozidlo",
    "trasa": "Trasa",
    "vozidlo": "Vozidlo",
    "max": "Maximální přejezd",
    "info": "Nápověda",
    "datum": "Datum",
    "datumOd": "Datum od",
    "datumDo": "Datum do"
  },
  "Dispecer": {
    "Username": "Jméno dispečera",
    "DatCas": "Čas poslední změny"
  },
  "Enumerations": {
    "CiselnikTyp": {
      "I": "I_Inzerce",
      "P": "P_Přepravy",
      "V": "V_Volné_vozy",
      "R": "R_Druh",
      "D": "D_Doplněk",
      "G": "G_Registr firem",
      "S": "S_Stav"
    },
    "Druh": {
      "1": "Plachta",
      "2": "Plato",
      "3": "Sklápěč",
      "4": "Skříň",
      "5": "Frigo",
      "6": "Izotermická skříň",
      "7": "Tautliner",
      "8": "Skříň na zav. šatstvo",
      "9": "Cisterna",
      "10": "Mulda",
      "11": "Walkingfloor",
      "12": "Double decker",
      "13": "Podvalník",
      "14": "Valník",
      "15": "Kontejner. podvozek",
      "16": "Klanice",
      "17": "Mega",
      "18": "Jumbo",
      "19": "Solo",
      "20": "Dodávka-plachta",
      "21": "Dodávka-skříň",
      "22": "Pick-up",
      "23": "Autopřepravník",
      "250": "Jiný"
    },
    "Duvod": {
      "NABIDKA": "Nabídka",
      "POPTAVKA": "Poptávka"
    },
    "InvalDuvodPreprava": {
      "0": "OK",
      "1": "Datum již leží v minulosti",
      "2": "Přeprava obsahuje zakázaná slova",
      "3": "Zneplatněno operátorem",
      "4": "Uživatel má zakázáno zveřejňovat přepravy",
      "5": "Provozovna pozastavena",
      "6": "Datum je ode dneška příliš vzdálené",
      "7": "Velké rozmezí mezi datem od a datem do",
      "8": "Obsahuje telefonní číslo neuvedené v kontaktech",
      "9": "Duplicita",
      "10": "Uživatel pozastaven",
      "11": "Uživatel bez telefonu",
      "12": "Odebrání práv administrátorem",
      "OK": "Ok",
      "NEZNAME_TELC": "Neznámé telefonní číslo"
    },
    "InvalDuvodVozidlo": {
      "0": "OK",
      "1": "Datum již leží v minulosti",
      "2": "Volný vůz obsahuje zakázaná slova",
      "3": "Zneplatněno operátorem",
      "4": "Uživatel má zakázáno zveřejňovat volné vozy",
      "5": "Provozovna pozastavena",
      "6": "Datum je ode dneška příliš vzdálené",
      "7": "Velké rozmezí mezi datem od a datem do",
      "8": "Obsahuje telefonní číslo neuvedené v kontaktech",
      "9": "Duplicita",
      "10": "Uživatel pozastaven",
      "11": "Uživatel bez telefonu",
      "12": "Odebrání práv administrátorem",
      "OK": "Ok",
      "NEZNAME_TELC": "Neznámé telefonní číslo"
    },
    "InvalidDuvodInzerat": {
      "0": "OK",
      "1": "Inzerát je příliš starý",
      "2": "Inzerát obsahuje zakázaná slova",
      "3": "Zneplatněno operátorem",
      "4": "Uživatel má zákázáno zveřejňovat inzeráty",
      "5": "Provozovna pozastavena",
      "8": "Obsahuje telefonní číslo neuvedené v kontaktech",
      "9": "Duplicita",
      "10": "Uživatel pozastaven",
      "11": "Uživatel bez telefonu",
      "12": "Odebrání práv administrátorem",
      "OK": "Ok",
      "INZERAT_ZNEPLATNEN": "Inzerát zneplatněn"
    },
    "InvoiceFrequency": {
      "0": "Měsíčně",
      "1": "Čtvrtletně",
      "2": "Pololetně",
      "3": "Ročně",
      "MONTHLY": "Měsíčně",
      "QUARTERLY": "Čtvrtletně",
      "HALFYEARLY": "Pololetně",
      "YEARLY": "Ročně"
    },
    "KlasifikaceColor": {
      "0": "Žádná",
      "1": "Zelená",
      "2": "Oranžová",
      "3": "Červená"
    },
    "KlasifikaceValue": {
      "0": "Žádná",
      "1": "1",
      "2": "2",
      "3": "3"
    },
    "LicenceTyp": {
      "1": "Ne",
      "2": "Ano",
      "WITHKM": "Ano",
      "WITHOUTKM": "Ne"
    },
    "Podstav": {
      "0": "Bez podstavu",
      "1": "Akce 3x a dost",
      "2": "Paušální platby",
      "3": "Vlastní žádost",
      "4": "Neuhrazený SW",
      "5": "Porušení smlouvy",
      "6": "Výpověď",
      "7": "Problém",
      "8": "Originální dokumenty",
      "BEZ_PODSTAVU": "Bez podstavu",
      "X3_A_DOST": "Akce 3x a dost",
      "PLATBY": "Paušální platby",
      "POZASTAVEN_VLASTNI_ZADOST": "Vlastní žádost",
      "PODEZRELY": "Neuhrazený SW",
      "PORUSENI_SMLOUVY": "Porušení smlouvy",
      "VYPOVED": "Výpověď",
      "PROBLEM": "Problém",
      "ORIGINAL": "Originální dokumenty"
    },
    "Predmet": {
      "NAKLADNI_AUTA": "Nákladní auta",
      "OSOBNI_AUTA": "Osobní auta",
      "PRIVESY_NAVESY": "Přívěsy/Návěsy",
      "NAHRADNI_DILY": "Náhradní díly",
      "JINE": "Jiné"
    },
    "Region": {
      "0": "CZ",
      "1": "EU",
      "2": "GLOBAL",
      "3": "SK",
      "4": "CZSK"
    },
    "Roles": {
      "ROLE_ADMIN": "Administrátor",
      "ROLE_MASTER": "Master",
      "ROLE_USER": "Uživatel",
      "ROLE_NONE": "Bez role"
    },
    "TypOdesilatele": {
      "FYZICKA_OSOBA": "FO (fyzická osoba)",
      "PRAVNICKA_OSOBA": "PO (právnická osoba)",
      "SLOVENSKO": "SK (Slovensko)"
    },
    "SmlouvaS": {
      "NONE": "Bez smlouvy",
      "FOHK": "FO HK",
      "FONITRA": "FO Nitra",
      "RAALHK": "RAALTRANS a.s. HK",
      "RAALNITRA": "RAALTRANS a.s. Nitra",
      "RAALHKNOVA": "Nová RAALTRANS a.s. HK",
      "FONITRANOVA": "Nová FO Nitra",
      "FOHKNOVA": "Nová FO HK",
      "RAAL_AS_2023": "RAALTRANS a.s. 2023",
      "RAAL_AS_2024": "RAALTRANS a.s. 2024",
      "RAAL_SRO_2023": "RAALTRANS s.r.o 2023",
      "RAAL_SRO_2024": "RAALTRANS s.r.o 2024"
    },
    "Stav": {
      "0": "Jede",
      "1": "Pozastavena",
      "JEDE": "Jede",
      "POZASTAVIL": "Pozastavena"
    },
    "SystemParamKey": {
      "PREPRAVA_DATE_RANGE": "Maximální datové rozpětí u přeprav (ve dnech)",
      "VOZIDLA_DATE_RANGE": "Maximální datové rozpětí u volných vozů (ve dnech)",
      "INZERAT_MAX_AGE": "Maximální stáří inzerátu (ve dnech)",
      "PREPRAVY_DRUHY_MAX": "Maximální počet druhů u přeprav",
      "VOZIDLA_DRUHY_MAX": "Maximální počet druhů u volných vozů",
      "PREPRAVA_DATE_ADVANCE_MAX": "Maximální datový předstih u přeprav (ve dnech)",
      "VOZIDLO_DATE_ADVANCE_MAX": "Maximální datový předstih u volných vozů (ve dnech)",
      "PREPRAVA_LENGTH": "Ložný metr u přeprav",
      "PREPRAVA_WEIGHT": "Hmotnost u přeprav",
      "VOZIDLO_LENGTH": "Ložný metr u volných vozů",
      "VOZIDLO_WEIGHT": "Hmotnost u volných vozů",
      "PROVOZOVNA_CANCEL_PERIOD": "Nastavení příznaku zrušeno po ukončení smlouvy (ve dnech)",
      "MAX_OFFER_EDITS": "Maximální počet obnovení / editací nabídky (-1 = neomezeně)",
      "OFFER_DELETE_DUPLICATES": "Mazat s nabídkou i duplicity",
      "MAX_CALCULATION_JOBS_PER_NODE": "Maximální počet počítáných tras vztažených na jeden server",
      "UZIVATEL_KONTROLA_PODSTAVU": "Seznam podstavů provozoven pro kontrolu IP přihlášení uživatele",
      "ARCHIVE_RETENTION_PERIOD": "Vymazání nabídek z archivu zadání (ve dnech do vložení do archivu)",
      "VIEW_ARCHIVE_RETENTION_PERIOD": "Vymazání nabídek z archivu prohlížení (ve dnech do vložení do archivu)",
      "ARCHIVE_PERIOD": "Přesun nabídek do archivu zadání (ve dnech od zneplatnění)",
      "HIDE_INZERCE": "Skrytí inzerce",
      "IGNORE_NOTIFICATION_PROVOZOVNA_KOD": "Nehlídané provozovny"
    },
    "TypZpravy": {
      "B": "Bleskovka",
      "C": "C",
      "I": "I",
      "R": "R",
      "S": "S",
      "T": "Školení",
      "H": "H",
      "V": "Popis systému"
    },
    "RoadType": {
      "0": "Dálnice",
      "1": "Silnice pro motorová vozidla",
      "2": "Silnice I. třídy",
      "3": "Silnice II. třídy",
      "4": "Silnice III. třídy",
      "5": "Jiné"
    },
    "TrasaState": {
      "0": "Čeká na výpočet",
      "1": "Probíhá výpočet",
      "2": "Vypočítáno",
      "3": "Chyba"
    },
    "OsrmProfile": {
      "0": "N1 (do 3,5 tuny)",
      "1": "N2 (do 12 tun)",
      "2": "N3 (do 40 tun)"
    },
    "RouteCalculationStatus": {
      "0": "OK",
      "1": "Chyba serveru",
      "2": "Trasa nenalezena",
      "3": "Nelze vypočítat náklady",
      "4": "Neúplná konfigurace - ceny mýta, kurzy, ..."
    },
    "KilometrovnikHlaseniStatus": {
      "0": "Nový",
      "1": "Uzavřený"
    },
    "DluhNadpripadStav": {
      "0": "Otevřený",
      "1": "Uzavřený"
    },
    "DluhPripadStav": {
      "0": "Neodeslaný",
      "1": "Nepotvrzený",
      "2": "Neuhrazený",
      "3": "Uzavřený",
      "4": "Vyřešený bez úhrad",
      "5": "Uzavřený (výpověď)",
      "6": "Uzavřený (bod 3.9)",
      "7": "Promlčeno",
      "8": "Zamítnuto"
    },
    "DluhFakturaStav": {
      "0": "Neodeslaná",
      "1": "Nepotvrzená",
      "2": "Neuhrazená",
      "3": "Uhrazená",
      "4": "Vyřešeno bez úhrad",
      "5": "Neuhrazená (výpověď)",
      "6": "Neuhrazená (bod 3.9)",
      "7": "Promlčeno",
      "8": "Zamítnuto"
    },
    "ReportLanguage": {
      "cs": "Čeština",
      "en": "Angličtina",
      "de": "Němčina",
      "pl": "Polština",
      "sk": "Slovenčina"
    },
    "TypPravniDokumentace": {
      "VP": "Všeobecné podmínky",
      "LP": "Licenční podmínky",
      "GDPR": "Zásady ochrany osobních údajů",
      "PDI": "Poskytování dopravních informací",
      "VYPCZ": "Výpověď smlouvy - CZ",
      "VYPSK": "Výpověď smlouvy - SK"
    },
    "LastAction": {
      "PASSWORD_CHANGE": "Změna hesla"
    }
  },
  "ErrorComponent": {
    "title": "Chyba ",
    "homeButton": "Zpět na úvodní stránku",
    "redirectionMessage": "Za moment budete přesměrování na úvodní stránku.",
    "error403": "Přístup byl zamítnut."
  },
  "Export": {
    "detailHeader": "Detail"
  },
  "Filter": {
    "Templates": "Filtr pro vyhledávání",
    "TemplateName": "Název filtru",
    "SaveTemplate": "Nový",
    "UpdateTemplate": "Uložit",
    "RemoveTemplate": "Smazat",
    "SelectTemplate": "Vyberte filtr pro vyhledávání",
    "NoTemplates": "Nenalezen žádný filtr",
    "ClearFilter": "Vyčistit filtr",
    "DoFilter": "Hledat",
    "OpenFilter": "Filtry",
    "UseFilter": "Filtr",
    "TooltipForFilledFilter": "Filtr je aktivní",
    "TooltipForEmptyFilter": "Filtr je neaktivní",
    "DeleteConfirmationText": "Opravdu chcete filtr smazat?",
    "Vychozi": "Výchozí",
    "SaveFilter": "Uložit filtr",
    "SavedFilters": "Uložené filtry"
  },
  "Firma": {
    "Id": "ID firmy",
    "Title": "Firma",
    "TabProvozovny": "Provozovny",
    "TabUzivatele": "Uživatelé",
    "TabDispeceri": "Dispečeři",
    "Provozovna": "Firma (Kód provozovny)",
    "pozastavena": "Zrušeno",
    "gdpr": "Souhlas s GDPR",
    "UserIdentityLabel": "Identifikace firmy",
    "UserLabel": "Firma",
    "UserContactLabel": "Adresa registrace",
    "UserNextLabel": "Ostatní informace",
    "ContactLabel": "Adresy",
    "NextLabel": "Údaje",
    "R1Nazev": "Název",
    "R1Ulice": "Ulice",
    "R1CisloPopisne": "Číslo popisné",
    "R1CisloPopisneKratke": "Č.p.",
    "R1Obec": "Obec",
    "R1CastObce": "Část Obce",
    "R1Psc": "PSČ",
    "R1Stat": "Stát",
    "ico": "IČO",
    "dic": "EU DIČ / IČ DPH",
    "dic1": "DIČ",
    "vznik": "Vznik firmy",
    "NezadouciZakaznik": "Nežádoucí zákazník",
    "NezadouciZakaznikShort": "Nežádoucí",
    "CreateFirmaAndProvozovna": "Vytvořit firmu a provozovnu",
    "VytvoritFirmu": "Vytvořit firmu",
    "Pojisteni": "Pojištění",
    "datPojist": "Datum pojištění",
    "ChooseFirma": "Vyberte firmu",
    "FirmaMaSlovo": "Firma má slovo",
    "EnabledOnFirma": "Zapnuto na firmě",
    "MissingFirma": "!!! Chybí firma !!!",
    "RegDatum": "Datum registrace",
    "datSmlouvy": "Datum smlouvy / V RAALu od",
    "raalOd": "V RAALu od",
    "http": "WWW",
    "fPoznamka": "Administrátorská poznámka",
    "DatumZmenyRejstrik": "Datum změny rejstřík",
    "PoznamkaRejstrik": "Poznámka rejstřík",
    "AktualizaceRejstrik": "Aktualizace rejstřík",
    "Nadpripad": "Nadpřípad",
    "TooltipRejstrikyVeFirme": "Pro zobrazení rejstříků musí být vyplněný stát"
  },
  "FormLocalization": {
    "DataSaved": "Záznam byl úspěšně uložen",
    "DataSavedFailed": "Záznam se nepodařilo uložit",
    "DataRemoved": "Záznam byl úspěšně smazán",
    "DataWasRemoved": "Záznam byl odstraněn",
    "DataWasEdited": "Záznam byl změněn",
    "DataRemovedFailed": "Záznam se nepodařilo smazat",
    "ServerError": "Chyba serveru",
    "ValidationError": "Validační chyba",
    "FieldMessages": {
      "FieldIsRequired": "Není vyplněn povinný údaj",
      "FieldNotNull": "Nesmí být nulové",
      "FieldIsRequiredValue": "Není vyplněn povinný údaj {0}",
      "CoordinatesMissing": "Chybí souřadnice k poli {0}",
      "UserLicenceError": "Licence kilometrovníku nepokrývá zadanou adresu"
    },
    "DateTimePicker": {
      "invalidDate": "Datum není validní",
      "cancelLabel": "Zrušit",
      "clearLabel": "Vyčistit",
      "emptyLabel": "Prázdný",
      "invalidLabel": "Nesprávné datum",
      "okLabel": "Potvrdit",
      "todayLabel": "Dnes",
      "minDateMessage": "Datum nesmí být v minulosti",
      "minDateTimeMessage": "Datum a čas nesmí být v minulosti",
      "maxDateMessage": "Datum nesmí být v budoucnosti",
      "disablePast": "Datum nesmí být v minulosti",
      "shouldDisableDate": "Datum je mimo povolený rozsah",
      "disableFuture": "Datum nesmí být v budoucnosti",
      "maxDate": "Nesprávné datum",
      "minDate": "Datum nesmí být v minulosti"
    },
    "Vicinity": {
      "Address": "Přesně",
      "Range": "Přejezd",
      "NoKilom": "Nutná licence s kilometrovníkem"
    }
  },
  "FormEditor": {
    "add": "Přidat",
    "cancel": "Zrušit",
    "colorpicker": "Výběr barvy",
    "text": "Text",
    "background": "Pozadí",
    "h": "Nadpis {0}",
    "blockquote": "Odstavec",
    "code": "Kód",
    "blocktype": "Styly",
    "normal": "Normální",
    "embedded": "Vložené",
    "embeddedlink": "Vložený odkaz",
    "enterlink": "Otevřít odkaz",
    "emoji": "Emotikon",
    "fontfamily": "Písmo",
    "fontsize": "Velikost písma",
    "history": "Historie",
    "undo": "Zpět",
    "redo": "Znovu",
    "image": "Vložit obrázek",
    "fileUpload": "Nahrát soubor",
    "byURL": "URL",
    "dropFileText": "Přetáhněte sem soubor nebo klikněte pro výběr",
    "bold": "Tučné",
    "italic": "Kurzíva",
    "underline": "Podtržení",
    "strikethrough": "Přeškrtnuté",
    "monospace": "Kód",
    "superscript": "Horní index",
    "subscript": "Dolní index",
    "linkTitle": "Název odkazu",
    "linkTarget": "Cílová cesta",
    "linkTargetOption": "Otevřít odkaz v novém okně",
    "link": "Vložit odkaz",
    "unlink": "Odebrat odkaz",
    "list": "Seznam",
    "unordered": "Odrážky",
    "ordered": "Číslování",
    "indent": "Zvětšit odsazení",
    "outdent": "Zmenšit odsazení",
    "remove": "Vymazat formátování",
    "textalign": "Zarovnání textu",
    "left": "Zarovnat doleva",
    "center": "Zarovnat na střed",
    "right": "Zarovnat doprava",
    "justify": "Zarovnat do bloku"
  },
  "GeneratePassword": {
    "title": "Nové heslo",
    "tooltip": "Uživateli bude automaticky odesláno nové heslo na uvedenou e-mailovou adresu.",
    "success": "Heslo bylo odesláno uživateli.",
    "error": "Chyba při generování hesla."
  },
  "GoogleMaps": {
    "Title": "Mapa Google",
    "SearchPlaces": "Hledat místa"
  },
  "HttpLocalization": {
    "SignedOut": "Odhlášeno",
    "NetworkError": "Chyba sítě",
    "Error401FingerPrint": "Byli jste odhlášeni, protože jste se přihlásili v jiném prohlížeči, nebo vypršel přihlašovací token",
    "LogoutBySystem": "Byli jste odhlášeni systémem.",
    "Error401": "Přihlašovací token vypršel"
  },
  "Informations": {
    "Info": "Raal info",
    "System": "Popis systému",
    "TollRate": "Sazba mýtného",
    "ExchangeRates": "Kurzovní lístek",
    "ReleaseNotes": "Poznámky k vydání"
  },
  "Inzerce": {
    "Id": "ID Inzerátu",
    "InvalidInzeratConfirm": "Chcete vážně zneplatnit inzerát?",
    "ValidInzeratConfirm": "Chcete vážně zrušit zneplatnění inzerátu?",
    "CurrencyRequiredWhenPrice": "Musí být vyplněno společně s cenou",
    "Price": "Cena",
    "currency": "Měna",
    "popis": "Popis",
    "rychlyKontakt": "Rychlý kontakt",
    "datIns": "Datum vytvoření",
    "modifiedOn": "Datum změny",
    "valid": "valid",
    "invalDuv": "Důvod zneplatnění",
    "cena": "Cena",
    "Description": "Popis",
    "AdText": "Popis",
    "Dispecer": "Dispečer",
    "History": "Historie",
    "OfferDetail": "Nabídka",
    "ArchiveConfirm": "Chcete vážně archivovat inzerát?",
    "zadavatel": "Zadavatel"
  },
  "ItemAge": {
    "Title": "Stáří nabídky",
    "OldMax": "Staré maximálně",
    "FromLastBrowse": "Novější od posledního prohlížení",
    "ByDate": "Podle data",
    "Unknown": "Neznámo",
    "search": "Vyhledat"
  },
  "javax": {
    "validation": {
      "constraints": {
        "NotEmpty": {
          "message": "nesmí být prázdné"
        },
        "Email": {
          "message": "nesprávný formát emailové adresy"
        }
      },
      "user": {
        "adminWithoutProvozovna": "Administrátor nesmí mít přiřazenou provozovnu",
        "userWithProvozovna": "Uživatel musí mít přiřazenou provozovnu"
      },
      "dispatcher": {
        "licenseLimit": "Počet dispečerů dosáhl limitu počtu licencí"
      },
      "firma": {
        "requiredIdFields": "Povinná kombinace stát + IČO/DIČ/IČ DPH"
      },
      "phone": {
        "notValid": "neplatný formát telefonního čísla"
      },
      "offer": {
        "rychlyKontaktNotInProvozovna": "Telefonní číslo není v seznamu čísel provozovny"
      },
      "lock": {
        "couldNotLock": "Nepodařilo se uzamknout záznam"
      },
      "waypoint": {
        "noLicense": "Licence kilometrovníku nepokrývá zadanou adresu"
      }
    }
  },
  "Job": {
    "Stav": "Stav",
    "Action": "Akce"
  },
  "Licence": {
    "Id": "Id",
    "Title": "Licence",
    "GenerateUsers": "Generovat uživatele",
    "NumberGreaterThan": "Číslo musí být vetší než {{value}}"
  },
  "Links": {
    "ZivnostenskyCr": "Živnostenský ČR",
    "ZivnostenskySk": "Živnostenský SK",
    "ObchodniCr": "Obchodní ČR",
    "Ares": "ARES",
    "ObchodniSk": "Obchodní SK"
  },
  "Map": {
    "ClearWayPoints": "Vyčistit mapu",
    "SearchPlace": "Zvolte cíl nebo klikněte do mapy",
    "SearchNearPlace": "Zvolte cíl *",
    "noLicense": "Nemáte licenci",
    "noHighway": "Silnice nenalezena",
    "noHighwaySelected": "Nebyl vybrán žádný úsek",
    "navigation": "Popis trasy"
  },
  "MaterialTable": {
    "columns": {
      "showColumnsTitle": "Nastavit sloupce",
      "menuTitle": "Přidat nebo ubrat sloupce"
    },
    "body": {
      "emptyDataSourceMessage": "Žádná data nejsou k dispozici",
      "addTooltip": "Přidat nový záznam rychle",
      "deleteTooltip": "Smazat záznam",
      "removeTooltip": "Odebrat záznam",
      "updateTooltip": "Obnovit záznam",
      "noUpdateTooltip": "Záznam nelze obnovit",
      "editTooltip": "Editovat záznam",
      "dataChanged": "Data byla změněna",
      "dateTimePickerLocalization": {},
      "filterRow": {
        "filterTooltip": "filtr"
      },
      "editRow": {
        "saveTooltip": "Uložit",
        "cancelTooltip": "Storno",
        "mode": {
          "add": {
            "saveTooltip": "Uložit nový",
            "cancelTooltip": "Zrušit"
          },
          "update": {
            "saveTooltip": "Uložit změny",
            "cancelTooltip": "Zrušit změny"
          },
          "delete": {
            "saveTooltip": "Potvrdit smazání",
            "cancelTooltip": "Zrušit mazání"
          }
        },
        "deleteText": "Opravdu chcete smazat tento záznam?",
        "addTooltip": "Přejít na rozšířené zadání",
        "editTooltip": "Přejít na rozšířenou editaci",
        "readTooltip": "Přejít na náhled"
      }
    },
    "grouping": {
      "placeholder": "seskupit"
    },
    "pagination": {
      "labelDisplayedRows": "{from}-{to} z {count} ({overall} celkem)",
      "labelRowsSelect": "řádků",
      "labelRowsCount": "Počet řádků:",
      "labelRowsPerPage": "počet na stránku",
      "firstAriaLabel": "první",
      "firstTooltip": "první",
      "previousAriaLabel": "předchozí",
      "previousTooltip": "předchozí",
      "nextAriaLabel": "další",
      "nextTooltip": "další",
      "lastAriaLabel": "poslední",
      "lastTooltip": "poslední"
    },
    "toolbar": {
      "searchTooltip": "vyhledat",
      "searchPlaceholder": "vyhledat"
    },
    "header": {
      "actions": ""
    },
    "resetColumnOrder": "Obnovit sloupce",
    "resetColumnOrderQuestion": "Opravdu chcete vrátit sloupce tabulky do původní podoby?",
    "openMultipleAction": "Otevřít hromadnou akci",
    "closeMultipleAction": "Zavřít hromadnou akci",
    "export": "Export",
    "exportAll": "Exportovat vše",
    "exportFilter": "Exportovat filtr",
    "exportPdf": "Export do PDF",
    "exportXls": "Export do Excelu (XLS)",
    "exportCsv": "Export do CSV",
    "exportXml": "Export do XML"
  },
  "MuiAutocomplete": {
    "NoOption": "Žádné výsledky",
    "GPSCurrentPosition": "Načíst aktuální GPS pozici"
  },
  "MuiInput": {
    "MinTip": "Dolní mez pro filtrování záznamů",
    "MaxTip": "Horní mez pro filtrování záznamů"
  },
  "NevhodnaSlova": {
    "VsichniZakaznici": "Všechny firmy",
    "ZadniZakaznici": "Žádné firmy",
    "AllWords": "Všechna slova",
    "HlidaneSlovoFirmy": "Firmy používající toto slovo"
  },
  "Vozidlo": {
    "id": "ID Vozidla",
    "ArchiveConfirm": "Chcete vážně archivovat vozidlo?"
  },
  "TransportNabidka": {
    "pocetEditaci": "Počet editací"
  },
  "Preprava": {
    "id": "ID Přepravy",
    "zadavatel": "Zadavatel",
    "regOd": "Stát odkud",
    "pscOdkud": "PSČ odkud",
    "datOd": "Datum od",
    "mena": "Měna",
    "regKam": "Stát kam",
    "datDo": "Datum do",
    "datum": "datum",
    "pscKam": "PSČ kam",
    "cena": "Cena",
    "vahaShort": "M [t]",
    "paletyShort": "P [ks]",
    "objemShort": "V [m3]",
    "delkaShort": "L [m]",
    "sirkaShort": "W [m]",
    "vyskaShort": "H [m]",
    "lozPlochaShort": "S [m2]",
    "doplnujiciUdaje": "Upřesňující údaje",
    "vaha": "Váha [t]",
    "palety": "Palety [ks]",
    "objem": "Objem [m3]",
    "lozPlocha": "Ložná plocha [m2]",
    "lozPlochaSmaller": "L. plocha [m2]",
    "delka": "Délka [m]",
    "sirka": "Šířka [m]",
    "vyska": "Výška [m]",
    "datIns": "Datum vytvoření",
    "modifiedOn": "Datum změny",
    "valid": "valid",
    "invalDuv": "Důvod zneplatnění",
    "odkud": "ODKUD",
    "odkudKamToolTip": "Pro zadání více MPZ / PSČ použijte čárku jako oddělovač",
    "kam": "KAM",
    "naklProstorVcelku": "Nákladový prostor vcelku",
    "neverPozn": "Neveřejná poznámka",
    "zvedaciCelo": "Zvedací čelo",
    "druhyRidic": "Druhý řidič",
    "zakazDokladky": "Zákaz dokládky",
    "adr": "ADR",
    "hydraulickaRuka": "Hydraulická ruka",
    "zpusobNakladky": "Způsob nakládky",
    "datumZalozeni": "Datum založení",
    "datumArchivace": "Datum archivace",
    "nadrozmernyNaklad": "Nadrozměrný náklad",
    "nakladkaZezadu": "Nakládka zezadu",
    "nakladkaBokem": "Nakládka bokem",
    "nakladkaShora": "Nakládka shora",
    "nakladkaZezaduShort": "zezadu",
    "nakladkaBokemShort": "bokem",
    "nakladkaShoraShort": "shora",
    "doplnky": "Doplňky",
    "jine": "Jiné",
    "souprava": "Souprava",
    "naves": "Návěs",
    "druhy": "Druhy",
    "verejnaPozn": "Poznámka",
    "uzivatel": "Zadavatel",
    "dispecer": "Dispečer",
    "History": "Historie",
    "InvalidPrepravaConfirm": "Chcete vážně zneplatnit přepravu?",
    "ValidPrepravaConfirm": "Chcete vážně zrušit zneplatnění přepravy?",
    "InvalidVozidloConfirm": "Chcete vážně zneplatnit vozidlo?",
    "ValidVozidloConfirm": "Chcete vážně zrušit zneplatnění vozidla?",
    "Misto": "Místo",
    "NSJAnyMustBeChecked": "Vyberte alespoň jednu položku z NSJ",
    "NSJOneMustBeChecked": "Vyberte právě jednu položku z NSJ",
    "GeocodingCoordinates": "Získávám souřadnice z ručně zadané adresy",
    "DruhyOverflow": "Maximální počet {0}",
    "AirDistance": "Vzdálenost [km]",
    "AirDistanceShort": "KM",
    "AirDistanceToolTip": "Jedná se o vypočtenou vzdálenost vzdušnou čarou",
    "CurrencyRequiredWhenPrice": "Musí být vyplněno společně s cenou",
    "okoliOdkud": "Okolí odkud",
    "okoliKam": "Okolí kam",
    "radiusOdkud": "Okolí odkud do [km]",
    "radiusKam": "Okolí kam do [km]",
    "ArchiveConfirm": "Chcete vážně archivovat přepravu?",
    "rychlyKontakt": "Rychlý kontakt",
    "firma": "Firma",
    "airDistance": "Vzdálenost [km]",
    "currency": "Měna",
    "nsj": "N,S,J",
    "psc": "PSČ",
    "mpz": "MPZ"
  },
  "Provozovna": {
    "0": "Provozovna jede",
    "1": "Provozovna pozastavena",
    "Id": "ID Provozovny",
    "Title": "Provozovna",
    "Nazev": "Název provozovny",
    "stopped": "Pozastavena",
    "PayInfo": "Fakturace",
    "souhrnne": "Fakturováno souhrnně",
    "uOsobaUser": "Účetní osoba RAAL",
    "uTelefonUser": "Účetní telefon RAAL",
    "uEmailUser": "Účetní email RAAL",
    "uosobaUser": "Účetní osoba",
    "utelefonUser": "Účetní telefon",
    "uemailUser": "Účetní email",
    "uosoba": "Účetní osoba (fakturace)",
    "utelefon": "Účetní telefon (fakturace)",
    "uemail": "Účetní email (fakturace)",
    "OtherInfo": "Kontakty",
    "emaily": "Emaily",
    "telCislaKontaktu": "Telefonní čísla",
    "FilterInfo": "Použijte základní / rozšířený filtr pro možné vyhledání 30 relevantních provozoven",
    "UserIdentityLabel": "Identifikace provozovny",
    "CompanyContactLabel": "Adresa firmy",
    "UserContactLabel": "Adresa provozovny",
    "UserCorespondLabel": "Kontaktní adresa",
    "KodProvozovny": "Kód provozovny",
    "UserInDetail": "v detailu",
    "IcoDic": "IČO / EU DIČ / DIČ",
    "PrefStat": "Předvolba stát",
    "PNazev": "P Název",
    "PUlice": "P Ulice",
    "PCisloPopisne": "P Číslo popisné",
    "PCisloPopisneKratke": "P č.p.",
    "PUliceCisloPopisne": "P Ulice + č.p",
    "PObec": "P Obec",
    "PCastObce": "P Část Obce",
    "PPsc": "P PSČ",
    "PStat": "P Stát",
    "KNazev": "K Název",
    "KAdresat": "K Adresát",
    "KUlice": "K Ulice",
    "KCisloPopisne": "K Číslo popisné",
    "KObec": "K Obec",
    "KCastObce": "K Část Obce",
    "KPsc": "K PSČ",
    "KStat": "K Stát",
    "Kod": "Kód",
    "datSmlouvyZmena": "Nová smlouva",
    "RaalJmeno1": "Raal Kontakt 1",
    "RaalJmeno2": "Raal Kontakt 2",
    "RaalJmeno3": "Raal Kontakt 3",
    "KdoZmenil": "Kdo změnil",
    "KdyZmena": "Kdy změnil",
    "PotlacInzDatum": "Potlačit inzeráty - datum",
    "ZasilatReport": "Zasílat report",
    "PotlacInz": "Potlačit inzeráty",
    "PotlacPrepravy": "Potlačit přepravy",
    "PotlacVv": "Potlačit volné vozy",
    "PotlacPrepravyDatum": "Potlačit přepravy - datum",
    "PotlacVvDatum": "Potlačit volné vozy - datum",
    "VytvoritProvozovnu": "Vytvořit provozovnu",
    "ZkusebniDoba": "Zkušební doba",
    "datzkus": "Zkušební doba do",
    "ipDll": "IP adresa z objednávky",
    "DatovaSchranka": "Datová schránka",
    "SmlouvaS": "Smlouva s",
    "EmailProfa": "Emaily pro zasílání faktur a upomínek",
    "SmsUpominky": "Telefonní čísla pro SMS upomínky fakturace",
    "Pojisteni": "Pojištění",
    "PojisteniNakladu": "Pojištění nákladu",
    "ZpusobFakturace": "Způsob odesílání faktur",
    "email1": "Odesílat faktury emailem",
    "posta": "Odesílat faktury poštou",
    "odlisadr": "Odlišná adresa",
    "Nezobrazovat": "Nezobrazovat",
    "OmezeneInformace": "Omezené informace",
    "NonGdprInfo": "Non-GDPR informace",
    "ZkraceneInformace": "Zkrácené informace",
    "Fax": "Fax",
    "FrekvenceFaktur": "Frekvence faktur",
    "Stav": "Stav",
    "Podstav": "Podstav",
    "Dispecer": "Dispečer",
    "dispeceri": "Dispečeři",
    "EmailsFilter": "E-mail (prohledá Provozovny, Dispečery, Účetní)",
    "PhoneNumbersFilter": "Tel. č. (prohledá Provozovny, Dispečery, Účetní)",
    "TooltipZmenaVeFirme": "Změna se projeví v celé firmě",
    "ProcessProvozovny": "Zpracovat ostatní provozovny dané firmy?",
    "not1": "Poznámka",
    "poznamka": "Administrátorská poznámka",
    "Records": "Záznamy",
    "Uzavreno": "Uzavřeno",
    "DatumUzavreni": "Datum uzavření",
    "ManualDataFetch": "Použijte základní / rozšířený filtr pro možné vyhledání 30 relevantních provozoven",
    "PoslPripoj": "Poslední připojení",
    "PocPripoj": "Počet připojení",
    "PocetUzivatelu": "Počet uživatelů",
    "Zalozen": "Založen",
    "datzmenstav": "Změna stavu",
    "SpStat": "Specializace na státy",
    "jazyky": "Jazyky",
    "Auta": "Vlastní vozy",
    "vs": "Variabilní symbol",
    "GenerateUsers": "Generovat uživatele",
    "ToolTipGenerateUsers": "Zadejte, kolik má být automaticky vygenerováno uživatelů s licencemi",
    "ContainsNumberOfBlocked": "Provozovna obsahuje telefonní čísla blokovaných provozoven: {0}. Chcete uložit záznam?",
    "okoli": "Okolí",
    "radius": "Okolí [km]",
    "airDistance": "Vzdálenost [km]",
    "notEqualAddress": "Adresa firmy a provozovny nesouhlasí",
    "equalAddress": "Adresa firmy a provozovny souhlasí",
    "historieUzivatelu": "Historie uživatelů",
    "Smlouvy": "Smlouvy",
    "LastChange": "Posl. změna"
  },
  "PVI": {
    "RefreshRecord": "Obnovit (zbývá {{value}}x)",
    "Refresh": "Obnovit",
    "UpdateRecord": "Uložit změny (zbývá {{value}}x)",
    "Inserted": "Vloženo",
    "Modified": "Změna",
    "Creator": "Uživatel",
    "CreatedOn": "Datum vytvoření",
    "ModifiedOn": "Datum změny",
    "ListEmpty": "Seznam je prázdný",
    "Marked": "Označeno",
    "InvalDuv": "Důvod zneplatnění",
    "Valid": "Platný",
    "codes": {
      "P": "P",
      "V": "V",
      "I": "I",
      "All": "PVI",
      "S": "S",
      "T": "T",
      "H": "H",
      "Pr": "P",
      "U": "U",
      "F": "F"
    },
    "tooltip": {
      "P": "Přepravy",
      "V": "Volné vozy",
      "I": "Inzerce",
      "All": "PVI",
      "S": "Seznam vozidel",
      "T": "Trasy",
      "H": "Hlášení chyb",
      "Pr": "Provozovny",
      "U": "Uživatelé",
      "F": "Firmy",
      "UpdateButton": "Uložení / Obnova aktualizuje datum, podle kterého se řadí nabídky v prohlížení.",
      "UpdateDisabledButton": "Již byl vyčerpán maximální počet opakovaného uložení nabídky"
    }
  },
  "RAALKratkyKody": {
    "N": "N",
    "S": "S",
    "J": "J",
    "ADR": "ADR"
  },
  "Sections": {
    "Start": "Úvod",
    "Task": "Zadání",
    "Browse": "Prohlížení",
    "Company": "Provozovny",
    "Messages": "RAAL zprávy",
    "ArchiveTask": "Archiv zadání",
    "ArchiveBrowse": "Archiv prohlížení",
    "Pass": "Přejezdy",
    "Fill": "Dokládky",
    "CollectionService": "Sběrná služba",
    "Mileage": "Kilometrovník",
    "Assign": "Zadání",
    "View": "Prohlížení",
    "Master": "Správa",
    "Administration": "Administrace",
    "Logs": "Logy",
    "Help": "Nápověda",
    "Zpravy": "Zprávy",
    "Informations": "Informace",
    "Learning": "Školení",
    "Support": "Uživatelská podpora",
    "Stiznosti": {
      "Title": "Stížnosti",
      "Statistiky": "Statistiky"
    },
    "Admin": {
      "Offers": "Nabídky",
      "History": "Historie",
      "Archiv": "Archiv"
    },
    "History": "Historie",
    "User": {
      "Kilometrovnik": "Kilometrovník"
    },
    "Search": "Vyhledávání",
    "Soubory": "Soubory"
  },
  "ShowHidePhones": {
    "true": "Telefonní čísla",
    "false": "Skrýt"
  },
  "ShowHideMap": {
    "true": "Zadat souřadnice",
    "false": "Skrýt"
  },
  "Stat": {
    "Id": "Id",
    "nazevCz": "Název",
    "countryCode": "Kód",
    "Flag": "Vlajka",
    "TooltipKodZeme": "Vyplňte kód státu pro účely OpenStreetMap",
    "predvolba": "Předvolba"
  },
  "State": {
    "true": "aktivní",
    "false": "neaktivní"
  },
  "SystemParameter": {
    "Value": "Hodnota"
  },
  "Texty": {
    "Kod": "Kód",
    "Hodnota": "Hodnota ({0})",
    "TypTextu": "Typ"
  },
  "Theme": {
    "ThemeChange": "Světlé / Tmavé téma",
    "Title": "Téma",
    "Dark": "Tmavé",
    "Light": "Světlé"
  },
  "ThunderNews": {
    "Notifications": "Notifikace",
    "MessagesType": "Typ zprávy",
    "MessagesInstantLabel": "Bleskovka",
    "MessagesInstantUsers": "Upozornění: Pokud nezvolíte konkrétního Uživatele / Firmu / Provozovnu, blesková zpráva se odešle VŠEM uživatelům!",
    "MessagesValidTo": "Platnost do",
    "NoMessages": "Žádné zprávy",
    "AllMessages": "Všechny zprávy",
    "Predmet": "Předmět",
    "TypZpravy": "Typ zprávy",
    "Notification": "Máte novou zprávu",
    "Ok": "Skrýt",
    "Open": "Otevřít"
  },
  "TimeMeasures": {
    "minutes": {
      "plural": "minuty",
      "singular": "minuta",
      "genitivPlural": "minut"
    },
    "hours": {
      "plural": "hodiny",
      "singular": "hodina",
      "genitivPlural": "hodin"
    }
  },
  "User": {
    "Id": "ID uživatele",
    "Title": "Uživatel",
    "provozovna": "Provozovna",
    "login": "Přihlašovací jméno",
    "jmeno": "Jméno uživatele",
    "Password": "Heslo",
    "SignIn": "Přihlášení",
    "AddNew": "Přidání nového účtu",
    "ChangePassword": "Změna hesla",
    "Switch": "Přepnout",
    "Remove": "Odebrat",
    "roles": "Role",
    "IPAdresaPosledni": "Poslední IP adresa",
    "View": "Zadavatel",
    "ViewInfo": "Informace o zadavateli",
    "ToolTipGenerateUsers": "Generování uživatelů a licencí",
    "KdoZmenil": "Kdo změnil",
    "NoUserRoleError": "Jedná se o jednu licenci zastavte prosím celou provozovnu.",
    "lastAction": "Poslední akce"
  },
  "LoginPage": {
    "Info": "1. DATABANKA NÁKLADŮ A VOLNÝCH VOZŮ od roku 1992",
    "NewPassword": "Nové heslo",
    "ConfirmPassword": "Heslo znovu",
    "Captcha": "Kód z obrázku",
    "GenerateNewCaptcha": "Generovat nový obrázek",
    "RememberMe": "Zapamatovat si mě",
    "TroubleLoggingIn": "Máte potíže s přihlášením?",
    "RegularCustomersTitle": "Stálí zákazníci",
    "RegularCustomersInfo": "Přihlašovací údaje Vám byly zaslány na mail z adresy <a href=\"mailto:noreply@raaltrans.com\">noreply@raaltrans.com</a>. V případě potíží nás kontaktujte <span>(+420 495 217 481 – 2)</span> a my Vám rádi pomůžeme. Případně nás můžete kontaktovat i na <a href=\"mailto:support@raal.cz\">support@raal.cz</a>.",
    "NewCustomersTitle": "Noví zákazníci",
    "NewCustomersInfo": "Na našich webových stránkách <a href=\"https://www.raal.cz/\" target=\"_blank\" rel=\"noopener noreferrer\">www.raal.cz</a> naleznete objednávkový formulář. Po vyřízení objednávky Vás budeme kontaktovat."
  },
  "InputErrors": {
    "Password": {
      "ConfirmationNotMatch": "Hesla se neshodují.",
      "TooShort": "Heslo je příliš krátké, minimální délka je 6 znaků."
    }
  },
  "UserModified": {
    "Title": "Uživatelská data změněna",
    "Body": "Pro kompletní inicializaci změn Vašeho uživatelského účtu je potřeba se opakovaně přihlásit. Odhlásit nyní?"
  },
  "Validations": {
    "RychlyKontaktLengthConstraint": "Počet znaků musí být 9 až 15"
  },
  "Websocket": {
    "Connected": "Připojeno",
    "Disconnected": "Odpojeno",
    "Connecting": "Připojuji"
  },
  "YesNo": {
    "false": "Ne",
    "true": "Ano"
  },
  "Kilometrovnik": {
    "Psc": "PSČ",
    "Nazev": "Název",
    "Cc2": "Kód země",
    "Typ": "Typ",
    "Lat": "Zeměpisná šířka",
    "Lon": "Zeměpisná délka",
    "DisplayRoute": "Trasa a náklady",
    "DisplayRouteOnly": "Pouze trasa",
    "Vozidlo": "Vozidlo",
    "Note": "Do polí \"Přejezd odkud\" a \"Přejezd kam\" zadejte místa, která co nejvíce odpovídají Vaší trase. To může být např. místo, kde stojí nevytížené auto a místo, kam s ním chcete dojet, např. sídlo firmy. V polích \"Maximální přejezd odkud/kam/celkem\" lze nepovinně zadat, z jak velkého okolí od zadaných míst se mají nabídky zobrazovat. Necháte-li příslušné okolí nevyplněné, vyberou se nabídky bez ohledu na vzdálenost od zvoleného místa."
  },
  "Contacts": {
    "SendEmail": "Poslat email",
    "SipEmail": "Kontaktovat pomocí SIP protokolu (Teams, aj.)",
    "Call": "Volat",
    "SMS": "Poslat SMS",
    "WhatsApp": "WhatsApp",
    "Viber": "Viber"
  },
  "Klasifikace": {
    "klasifikace": "Klasifikace",
    "klasifikaceShort": "[K]",
    "nezobrazovat": "Nezobrazovat nabídky",
    "poznamka": "Uživatelská poznámka"
  },
  "ProfilVozidla": {
    "title": "Vozidla",
    "nazev": "Název",
    "casovyNakladH": "Časové náklady [cena/h]",
    "casovyNakladHTooltip": "(např. mzda řidiče)",
    "fixniNaklad": "Fixní náklady [cena/km]",
    "fixniNakladTooltip": "(odpisy)",
    "spotreba": "Spotřeba [l / 100 km]",
    "cenaPaliva": "Cena paliva [cena/l]",
    "tollPrice": "Ceny mýta",
    "osrmProfile": "Navigační profil",
    "displayPreprava": "Zobrazit v přepravách",
    "displayVozidlo": "Zobrazit ve volných vozech",
    "defaultPreprava": "Výchozí v přepravách",
    "defaultVozidlo": "Výchozí ve volných vozech",
    "properties": "Vlastnosti vozidla",
    "maxWeight": "Celková hmotnost vozidla [t]",
    "maxHeight": "Celková výška vozidla [m]",
    "maxWidth": "Celková šířka vozidla [m]",
    "maxLength": "Celková délka vozidla [m]",
    "hazmat": "převoz nebezpečných materiálů",
    "hazmatWater": "převoz materiálů kontaminujících vodu",
    "switzerland": "vyhýbání se Švýcarsku, pokud není v daném státě nakládka/vykládka",
    "delka": "Délka nákladu [m]",
    "vaha": "Váha nákladu [t]"
  },
  "CenaMyta": {
    "title": "Cena mýta",
    "kodZeme": "Stát",
    "sazba": "Sazba [cena/km]",
    "typSilnice": "Typ silnice",
    "CurrencyRequiredWhenPrice": "Musí být vyplněno společně se sazbou"
  },
  "Trasa": {
    "title": "Trasy",
    "Calculate": "Přepočítat",
    "CalculateAndSave": "Přepočítat a uložit",
    "nazev": "Název",
    "state": "Stav výpočtu",
    "profilVozidla": "Profil vozidla",
    "currency": "Měna výpočtu nákladů",
    "distance": "Vzdálenost",
    "duration": "Doba jízdy",
    "cost": "Náklady",
    "mpz": "Mpz",
    "distancePaid": "Placené úseky [km]",
    "distanceUnpaid": "Neplacené úseky [km]",
    "currencyTooltip": "Orientační výpočet dle kurzu ČNB",
    "modifiedOn": "Datum změny"
  },
  "KilometrovnikHlaseni": {
    "osrmId": "OSRM Id",
    "report": "Report",
    "status": "Stav",
    "uzivatel": "Uživatel",
    "osrmEdit": "Nahlásit na OSRM",
    "process": "Uzavřít",
    "reportSent": "Hlášení bylo odesláno",
    "title": "Hlášené chyby",
    "datIns": "Datum vytvoření",
    "datUzavreni": "Datum uzavření"
  },
  "Dokladky": {
    "kilometrovnik": "Kilometrovník dokládka",
    "nakladka": "Nakládka",
    "vykladka": "Vykládka",
    "odkud": "Přeprava - odkud",
    "kam": "Přeprava - kam",
    "vyhodnost": "Výhodnost",
    "dokladky": "Dokládky",
    "zajizdka": "Zajížďka [km]",
    "datumDokladkyOd": "Datum dokládky od",
    "datumDokladkyDo": "Datum dokládky do",
    "zadaniDokladky": "Dokládky přeprav",
    "calculate": "Hledat dokládky",
    "preprava": "Přeprava",
    "total": "Celkem",
    "original": "Původní",
    "dokladka": "S dokládkou",
    "Preprava": "Výchozí přeprava",
    "datum": "Datum"
  },
  "SbernaSluzba": {
    "sbernaSluzba": "Sběrná služba",
    "datumOd": "Přepravy od",
    "datumDo": "Přepravy do",
    "calculate": "Vypočítat",
    "trasa": "Trasa",
    "vozidlo": "Vozidlo",
    "datum": "Datum",
    "search": "Zadání sběrné služby",
    "prepravaOrder": "Pořadí",
    "map": "Zobrazit mapu",
    "noTransportsFound": "Žádné vhodné přepravy nebyly nalezeny",
    "profilVozidlaAtributy": "Profil vozidla musí mít vyplněno: měna, spotřeba, fixní náklady",
    "parametryVypoctu": "Parametry výpočtu",
    "maxPrejezd": "Maximání přejezd z místa nakládky [km]",
    "maxZajizdka": "Maximání zajížďky na trase [km]",
    "nalezeniPreprav": "Naplnění sběrné služby",
    "neobsahujePrepravy": "Doplnit přepravy automaticky?",
    "doplnitPrepravy": "Automaticky doplnit přepravy",
    "pridatPrepravu": "Přidat přepravu ručně",
    "pridaniPrepravy": "Přidat přepravy automaticky",
    "calculateToll": "Vypočítat náklady",
    "maxDelka": "Maximální délky dílčích nákladů [m]",
    "maxVaha": "Maximální váhy dílčích nákladů [t]",
    "PrepravaRemoveConfirm": "Skutečně chcete odstranit hlavní nabídku? Potvrzením této akce dojde k odstranění všech nabídek.",
    "noveZadani": "Nové zadání",
    "noveZadaniBody": "Zadání obsahuje přepravy, přejete si přepravy odstranit a začít znovu?",
    "processing": "Akci nelze provést, protože probíhá výpočet."
  },
  "ServerErrors": {
    "404": "Informace nebyla na serveru nalezena",
    "500": "Vnitřní chyba serveru",
    "FailedToFetch": "Nepodařilo se načíst data, zkontrolujte spojení k internetu.",
    "UnableToGeocode": "Nepodařilo se geokódovat souřadnici",
    "NetworkError": "Při vyřizování požadavku došlo k chybě sítě, zkontrolujte připojení k internetu."
  },
  "SeznamMaps": {
    "Title": "Mapa Seznam.cz"
  },
  "SearchEnum": {
    "entityType": "Typ záznamu",
    "Preprava": {
      "PREPRAVA": "N",
      "PREPRAVA_HISTORIE": "N (H)",
      "PREPRAVA_ARCHIV": "A",
      "PREPRAVA_HISTORIE_ARCHIV": "A (H)"
    },
    "VolneVozy": {
      "VOZIDLO": "N",
      "VOZIDLO_HISTORIE": "N (H)",
      "VOZIDLO_ARCHIV": "A",
      "VOZIDLO_HISTORIE_ARCHIV": "A (H)"
    },
    "Inzerat": {
      "INZERAT": "N",
      "INZERAT_HISTORIE": "N (H)",
      "INZERAT_ARCHIV": "A",
      "INZERAT_HISTORIE_ARCHIV": "A (H)"
    }
  },
  "TrikratADost": {
    "Title": "Statistika \"3x a dost\" ke dni {0}",
    "celkemPripadu": "Celkem {0} případů",
    "celkemCastky": "Celkem částky: {0}",
    "NadpripadyTotal": "Nadpřípady: {0} otevřených a {1} uzavřených, z toho {2} uzavřených výpovědí",
    "Stav": "Stav",
    "Castka": "Částka",
    "CurrencyCode": "Měna",
    "PocetPripad": "Počet případů"
  },
  "DOMException": {
    "AbortError": "Načítání dat bylo přerušeno (nebo server nestihl odpovědět v požadovaném čase)"
  },
  "Diag": {
    "host": "Host",
    "serverPort": "Port",
    "healthy": "Stav",
    "instances": "Instance",
    "dbReplicas": "Databázové repliky",
    "couldNotLoad": "Nebylo možné načíst data",
    "replicationNotActive": "Databáze není replikovaná",
    "resources": "Zdroje",
    "requiredDbConnectionsRead": "Požadovaných DB spojení pro čtení",
    "requiredDbConnectionsWrite": "Požadovaných DB spojení pro zápis",
    "availableDbConnectionsRead": "Dostupných DB spojení pro čtení (1 db)",
    "availableDbConnectionsWrite": "Dostupných DB spojení pro zápis",
    "availableDbConnections": "Dostupných DB spojení",
    "dbConnectionsNotEnough": "Počet databázových spojení nemusí stačit pro pokrytí požadavků serverů",
    "jobs": "Joby",
    "rateLimit": "Rate limit info"
  },
  "ArchiveRecord": {
    "typ": "Typ",
    "poznamka": "Poznámka",
    "owner": "Archivováno"
  },
  "Archive": {
    "Archive": "Archiv",
    "Archived": "Archivováno"
  },
  "Poznamka": {
    "poznamka": "Poznámka",
    "uzivatel": "Poslední autor",
    "datIns": "Datum vytvoření"
  },
  "CommonGrid": {
    "History": "Historie"
  },
  "DluhNepohOsoba": {
    "Prijmeni": "Příjmení",
    "Jmeno": "Jméno",
    "Rodcis": "Rodné číslo",
    "Adresa": "Adresa",
    "Ica": "Iča",
    "Poznamka": "Poznámka",
    "DatCas": "Datum",
    "Uzivatel": "Změnil",
    "Provozovny": "Provozovny"
  },
  "EmailForm": {
    "Sender": "Odesílatel",
    "Subject": "Předmět e-mailu",
    "Receiver": "Odeslat e-mail na adresu",
    "Body": "Obsah e-mailu"
  },
  "EmailStiznostiForm": {
    "smlouva": "Datum uzavření smlouvy",
    "pozastaveno": "Pozastavení přístupu ke dni"
  },
  "WithUserBase": {
    "Id": "Číslo",
    "DatCas": "Čas poslední změny",
    "Uzivatel": "Poslední změnil",
    "Zmena": "Poslední změna",
    "Generovat": "Generovat a odeslat",
    "ChooseLanguage": "Zvolit jazyk"
  },
  "DluhPripad": {
    "Dluznik": "Dlužník",
    "Veritel": "Věřitel",
    "ZodpOsoba": "Zodpovědná osoba",
    "IcoDluznika": "IČO dlužníka",
    "DicDluznika": "DIČ dlužníka",
    "RegNazevDluznika": "Název dlužníka",
    "RegUliceDluz": "Ulice dlužníka",
    "RegSidloDluz": "Sídlo dlužníka",
    "RegPscDluz": "Psč dlužníka",
    "RegStatDluz": "Stát dlužníka",
    "PrefStatDluz": "Předvolba dlužníka",
    "PrefMestoDluz": "Předvolba města",
    "FaxDluz": "Fax dlužníka",
    "TelDluz": "Telefon dlužníka",
    "MobilDluz": "Mobil dlužníka",
    "HttpDluz": "WWW dlužníka",
    "EmailDluz": "Email dlužníka",
    "IcqDluz": "Icq dlužníka",
    "SkypeIdDluz": "Skype dlužníka",
    "ProvozovnaDluznika": "Provozovna dlužníka",
    "ProvozovnaDluznikaStav": "Stav provozovny dlužníka",
    "zodpMailDluz": "Zodpovědná osoba dlužníka",
    "IcoVer": "IČO věřitele",
    "DicVer": "DIČ věřitele",
    "RegNazevVer": "Název věřitele",
    "RegUliceVer": "Ulice věřitele",
    "RegSidloVer": "Sídlo věřitele",
    "RegPscVer": "Psč věřitele",
    "RegStatVer": "Stát věřitele",
    "PrefStatVer": "Předvolba věřitele",
    "ProvozovnaVer": "Provozovna věřitele",
    "ProvozovnaVerStav": "Stav provozovny věřitele",
    "zodpMailVer": "Zodpovědná osoba věřitele",
    "PrefMestoVer": "Předvolba města věřitele",
    "FaxVer": "Fax věřitele",
    "TelVer": "Telefon věřitele",
    "MobilVer": "Mobil věřitele",
    "HttpVer": "WWW věřitele",
    "EmailVer": "Email věřitele",
    "IcqVer": "Icq věřitele",
    "SkypeIdVer": "Skype věřitele",
    "KdoZaloz": "Kdo založil",
    "DatCas": "Datum",
    "Stav": "Stav",
    "ZodpTel": "Telefon zodpovědné osoby",
    "ZodpEmail": "Email zodpovědné osoby",
    "Kontakty": "Kontakty",
    "ProvozovnyFirmy": "Provozovny nadřazené firmy",
    "AktKeDni": "Aktualizace ke dni",
    "AktKeDniButton": "Teď",
    "GenerovatDopisVer": "Generovat a odeslat dopis věřiteli",
    "GenerovatDopisDluznik": "Generovat a odeslat dopis dlužníkovi",
    "StavProvozovnyDluznika": "Stav provozovny dlužníka",
    "StavProvozovnyVer": "Stav provozovny věřitele",
    "StavAction": {
      "otevrit": "Otevřít",
      "uzavrit": "Uzavřít",
      "vyresitBezUhrady": "Vyřešit bez úhrady",
      "promlcet": "Promlčet",
      "zamitnout": "Zamítnout",
      "Confirmation": "Stav byl úspěšně změněn"
    },
    "PripadExist": "POZOR! S totožným dlužníkem i věřitelem již v systému existují aktivní případy: {pripadyIds}! Chcete pokračovat?",
    "FaktExist": "POZOR! Faktura s tímto číslem již v systému existuje v případech: {pripadyIds}! Chcete pokračovat?",
    "PripadExistTitle": "Potvrzení",
    "FaktExistTitle": "Potvrzení",
    "Active": "Aktivní"
  },
  "DluhNadpripad": {
    "NadpripadId": "Nadpřípad",
    "Stav": "Stav",
    "KdyPozastavit": "Kdy pozastavit",
    "Vypoved": "Výpověď",
    "Bod39": "Bod 3.9",
    "Upozorneni": "Upozornění",
    "Confirmation": "Potvrzení",
    "GenerovatDopisDluznik": "Generovat a odeslat dopis dlužníkovi",
    "StavAction": {
      "otevrit": "Otevřít",
      "uzavritSUhradou": "Uzavřít (s úhradou)",
      "uzavritBezUhrady": "Uzavřít (bez úhrady)",
      "uzavritVypoved": "Uzavřít (výpověď)",
      "uzavrit39": "Uzavřít (bod 3.9)",
      "New": "Opravdu chcete založit nadpřípad?",
      "CreateConfirmation": "Nadpřípad byl úspěšně vytvořen"
    },
    "Buttons": {
      "New": "Založit nadpřípad",
      "Navigate": "Zobrazit nadpřípad",
      "NavigateToPripad": "Zpět na případ"
    }
  },
  "DluhFakt": {
    "Title": "Faktury",
    "Cislo": "Číslo faktury",
    "Castka": "Částka",
    "DatSplat": "Datum splatnosti",
    "KdyUhradil": "Kdy uhradil",
    "KdoUhradil": "Kdo uhradil",
    "KdyBezUhrady": "Kdy bez úhrady",
    "KdoBezUhrady": "Kdo bez úhrady",
    "Stav": "Stav",
    "UdajeFaktury": "Údaje faktury",
    "OhlasUhr": "Ohlášení úhrady věřitelem",
    "DuvodZamitnuti": "Důvod zamítnutí",
    "Poznamka": "Poznámka",
    "Poznamky": "Poznámky",
    "StavAction": {
      "uhradit": "Uhradit",
      "vyresitBezUhrady": "Vyřešit bez úhrady",
      "promlcet": "Promlčet",
      "zamitnout": "Zamítnout",
      "otevrit": "Otevřít"
    }
  },
  "DluhSoub": {
    "Title": "Soubory",
    "Soubor": "Soubor",
    "NovySoubor": "Nový soubor",
    "Popis": "Popis",
    "Stazeni": "Stažení souboru"
  },
  "DluhStateActionConfirmDialog": {
    "Title": "Potvrzení",
    "Body": "Opravdu chcete provést akci {{action}}?"
  },
  "ReportText": {
    "TextKey": "Klíč",
    "Translation": "Překlad"
  },
  "Report": {
    "Language": "Jazyk"
  },
  "popis": "Popis",
  "NetworkError": "Chyba v síti",
  "UnexpectedError": "Při vyřizování požadavku došlo k neočekávané chybě",
  "Error401": "Odhlášeno",
  "Error401FingerPrint": "Byli jste odhlášeni, protože jste se přihlásili v jiném prohlížeči.",
  "SignedOut": "Odhlášení",
  "BadCredentials": "Špatné přihlašovací údaje",
  "RecordCannotBeSaved": "Záznam nelze uložit",
  "ModificationConcurrency": "Entita byla modifikována jiným uživatelem",
  "LoginFailed": "Přihlášení se nezdařilo",
  "LoginFailedGeneratedPwd": "Přihlášení se nezdařilo. Zkontrolujte prosím, zda máte zadané správné heslo pomocí tlačítka oka vedle hesla.",
  "PasswordChangeRequired": "Je vyžadována změna hesla",
  "TryConnectAgain": "Znovu zkusit připojit",
  "ConnectionError": "Chyba připojení. Buď jsou servery nedostupné nebo nemáte přístup k internetu.",
  "Unique": "Záznam je duplicitní",
  "ipDll": "Poslední IP",
  "DataFetchError": "Při načítaní dat došlo k chybě",
  "WebsocketConnecting": "Navazuji spojení",
  "Attempt": "Pokus",
  "ArchiveRecordShort": "Archivovat",
  "RestoreRecordShort": "Obnovit",
  "ArchiveSuccess": "Archivace proběhla úspěšně",
  "AppActivityTypeName": "Typ",
  "AppActivitySubTypeName": "Podtyp",
  "ActivityViewType": "Způsob prohlédnutí",
  "ArchiveView": "Archivace prohlížené nabídky",
  "Actions": "Akce",
  "Errors": {
    "CouldNotFetchCode": "Při načítání volného kódu provozovny došlo k chybě.",
    "CouldNotFetchCount": "Při načítání počtu záznamů došlo k chybě.",
    "CouldNotCancelEdit": "Nelze zrušit editaci",
    "CouldNotEdit": "Nelze editovat záznam",
    "CouldNotDelete": "Nelze smazat záznam",
    "CouldNotDeleteIsLocked": "Nelze vymazat záznam, záznam je uzamčen",
    "CouldNotArchiveIsLocked": "Nelze archivovat záznam, záznam je uzamčen",
    "LockLost": "Editování bylo zrušeno, protože bylo zrušeno uzamčení záznamu",
    "SystemOverload": "Stránku nelze zobrazit, protože je systém vytížen obsluhou jiných požadavků, zkuste stránku zobrazit později."
  },
  "Unable to geocode": "Nelze určit adresu umístění",
  "Consent": {
    "BasicInfo": "Základní informace",
    "Agreements": "Odsouhlasení právní dokumentace",
    "MinimalRequirements": "Minimální požadavky na systém",
    "VPAgreement": "Souhlasím se všeobecnými podmínkami",
    "LPAgreement": "Souhlasím s licenčními podmínkami",
    "GDPRAgreement": "Souhlasím se zásadami ochrany osobních údajů",
    "PDIAgreement": "Souhlasím se smlouvou o poskytování dopravních informací",
    "Download": "Stáhnout",
    "Open": "Otevřít"
  },
  "PravniDokumentace": {
    "TypSmlouvy": "Typ smlouvy",
    "Soubor": "Smlouva",
    "DatumPodepsaniRall": "Datum podpisu RAALTRANS",
    "DatumPodepsaniPrijemce": "Datum potvrzení příjemcem",
    "DatumVytvoreni": "Datum vytvoření",
    "Sign": "Podepsat smlouvy",
    "SignConfirm": "Opravdu si přejete veškeré smlouvy podepsat?",
    "AlreadySigned": "Smlouvy již byly podepsány",
    "SignFailed": "Podepsání smluv selhalo",
    "SignSuccess": "Smlouvy byly podepsány",
    "Delete": "Smazat smlouvy",
    "DeleteConfirm": "Opravdu si přejete veškeré smlouvy vymazat?",
    "AlreadyDeleted": "Smlouvy již byly smazány",
    "DeleteFailed": "Smazání smluv selhalo",
    "DeleteSuccess": "Smlouvy byly smazány"
  },
  "StaticLinks": {
    "Thumbnail": {
      "MasterTutorial": "/videos/thumbnail/master_tutorial_cz.jpg"
    }
  },
  "Cms": {
    "Oznameni": {
      "TitleAfterLogin": "Novinky od posledního přihlášení",
      "TitleStomp": "Důležité oznámení",
      "ReloadPage": "OBNOVIT STRÁNKU",
      "ReloadInfo": "Právě došlo k aktualizaci aplikace na novou verzi - pro správné fungování je potřeba stránku obnovit.",
      "ReloadDisclaimer": "V opačném případě Vám některá z funkcionalit nemusí fungovat správně."
    }
  },
  "Oznaceni": {
    "DeleteViewRecords": "Vymazat historii zobrazení",
    "ViewRecordsClearConfirm": "Opravdu si přejete vymazat historii zobrazení?"
  },
  "NabidkaMultiCRUD": {
    "RefreshTooltip": "Obnovit záznamy",
    "DeleteTooltip": "Smazat záznamy",
    "RefreshConfirm": "Opravdu si přejete obnovit všechny označené záznamy?",
    "DeleteConfirm": "Opravdu si přejete smazat všechny označené záznamy?",
    "RefreshSuccess": "Nabídky byly obnoveny",
    "DeleteSuccess": "Nabídky byly vymazány",
    "UnableToRefresh": "Některé nabídky se nepodařilo obnovit",
    "UnableToDelete": "Některé nabídky se nepodařilo vymazat",
    "AlreadyDeletedRecords": "Již neexistující: {0}x",
    "LockedRecords": "Uzamknuté: {0}x",
    "MaxEditsReachedRecords": "Vyčerpán počet obnovení: {0}x",
    "ExpiredOfferRecords": "Neplatné nabídky: {0}x",
    "InvalidOffersDateChangeInfo": "Vybrané nabídky obsahují datum, který již leží v minulosti. Pro možnost obnovy je nutné nastavit nový datum:",
    "ValidOffersDateChangeInfo": "Upozornění: výběr obsahuje i platné nabídky - u nich bude změněn pouze datum do.",
    "DateFrom": "Datum od",
    "DateTo": "Datum do"
  },
  "VlastniMisto": {
    "titulek": "Přidání nového místa",
    "Pridat": "Přidat nové místo",
    "mpz": "MPZ",
    "psc": "PSČ",
    "mesto": "Město",
    "notFound": "Lokace nebyla nalezena. Můžete ji použít, ale nebudou dopočítány kilometry vzdálenosti."
  },
  "Mail": {
    "KodZadavateleNabidky": "KÓD ZADAVATELE NABÍDKY",
    "PrepravaSubject": "Reakce na nabídku přepravy v RAALTRANS ({0})",
    "VozidloSubject": "Reakce na nabídku volného vozu v RAALTRANS ({0})",
    "InzeratSubject": "Reakce na nabídku inzerátu v RAALTRANS ({0})",
    "PrepravaBodyBegin": "Reagujeme na Vaši nabídku přepravy zveřejněnou v databance RAALTRANS (náš kód v RAALTRANS je {0}):",
    "VozidloBodyBegin": "Reagujeme na Vaši nabídku volného vozu zveřejněnou v databance RAALTRANS (náš kód v RAALTRANS je {0}):",
    "InzeratBodyBegin": "Reagujeme Váš inzerát zveřejněný v databance RAALTRANS (náš kód v RAALTRANS je {0}):"
  },
  "DataTemplate": {
    "Save": "Uložit šablonu",
    "Name": "Název šablony",
    "Add": "Přidat šablonu",
    "Select": "Vyberte šablonu pro založení nabídky",
    "SavedTemplates": "Uložené šablony",
    "NoData": "Nenalezena žádná šablona",
    "DeleteConfirmationText": "Opravdu chcete šablonu smazat?",
    "RemoveTemplate": "Smazat",
    "Saved": "Šablona byla uložena",
    "SaveFailed": "Šablonu se nepodařilo uložit, název je již přiřazen k jiné šabloně, nebo je název moc dlouhý",
    "Edited": "Šablona byla upravena",
    "EditFailed": "Šablonu se nepodařilo upravit",
    "Removed": "Šablona byla odstraněna",
    "RemoveFailed": "Šablonu se nepodařilo odstranit"
  },
  "Here": {
    "Dial": {
      "TitulekTabulky": "Vozidla",
      "Nazev": "Název",
      "Mena": "Měna",
      "Vyska": "Výška [m]",
      "Sirka": "Šířka [m]",
      "Delka": "Délka [m]",
      "CelkovaVaha": "Celková váha [t]"
    },
    "TrasaDetail": {
      "CelkovaCenaZaSekci": "Celková cena za sekci",
      "PoplatkyV": "Poplatky v",
      "Routing": "TRASA",
      "Sekce": "Sekce",
      "OdhadovanaDobaJizdy": "Odhadovaná doba jízdy",
      "Poplatky": "MÝTO",
      "PoplatkyCelkem": "Poplatky celkem",
      "DetailPoplatku": "Pro rozbalení / sbalení detailu mýta klikněte zde",
      "Table": {
        "IndexMyta": "Index mýta",
        "IndexPoplatku": "Index poplatku",
        "LokalniCena": "Lokální cena",
        "KovertovanaCena": "Kovertovaná cena",
        "Zeme": "Země",
        "MytnySystem": "Mýtný systém",
        "PlatebniMetody": "Platební metody",
        "PlatebniMetoda": {
          "cash": "Hotovost",
          "bankCard": "Bankovní karta",
          "creditCard": "Kreditní karta",
          "transponder": "Transpondér",
          "travelCard": "Cestovní karta",
          "passSubscription": "Předplatné jízdného",
          "videoToll": "Video mýtné"
        },
        "Predplatne": {
          "days": "Denní",
          "months": "Měsíční",
          "annual": "Roční"
        }
      },
      "HereError": "Pravděpodobně profil vozu obsahuje nepovolené kombinace.",
      "HereErrorNoRoutes": "Nebyla nalezená žádná trasa podle lokace a profilu vozidla. Důvodem může být například zakázaná země a trasa začínající nebo končící v dané zemi.",
      "Upozorneni": "Upozornění",
      "NotTruckTransportNotification": "Pozor: na trase je část přepravy jiná (trajekt/vlak) než po vlastní ose. Poplatky nemusí být úplné.",
      "NotTollsTransportNotification": "Pozor v této sekci nejsou uvedeny žádné poplatky. Neznamená to, že žádné nejsou. Je možné, že je systém nebyl schopen zjistit.",
      "ZobrazitMapu": "Pro rozbalení / sbalení mapy klikněte zde",
      "KliknutimVybertePrujedniBod": "Kliknutím do mapy vyberte průjezdní bod.",
      "VyberZMapy": "Výběr z mapy"
    },
    "Kilometrovnik": {
      "Button": {
        "Trasa": "TRASA",
        "Naklady": "MÝTO",
        "TrasaANaklady": "TRASA A MÝTO"
      },
      "ProfilVozidla": "Profil vozidla",
      "ViaInput": "Průjezdní bod",
      "AddViaToList": "Přidat do seznamu"
    },
    "Nazev": "Název profilu vozidla",
    "Currency": {
      "Title": "Náklady vrátit v měně",
      "TitleToolTip": "Náklady na vypočítanou trasu budou vráceny v této měně",
      "Shortcut": {
        "EUR": "Euro",
        "GBP": "Britská libra",
        "CHF": "Švýcarský frank",
        "NOK": "Norská koruna",
        "SEK": "Švédská koruna",
        "DKK": "Dánská koruna",
        "ISK": "Islandská koruna",
        "CZK": "Česká koruna",
        "PLN": "Polský zlotý",
        "HUF": "Maďarský forint",
        "RON": "Rumunský leu",
        "BGN": "Bulharský lev",
        "HRK": "Chorvatská kuna",
        "RSD": "Srbský dinár",
        "TRY": "Turecká lira",
        "BAM": "Bosensko-hercegovinská konvertibilní marka",
        "MKD": "Makedonský denár",
        "BYN": "Běloruský rubl",
        "MDL": "Moldavský leu",
        "UAH": "Ukrajinská hřivna",
        "RUB": "Ruský rubl",
        "GEL": "Gruzínské lari",
        "AZN": "Ázerbájdžánský manat",
        "AMD": "Arménský dram",
        "ALL": "Albánský lek"
      }
    },
    "Vehicle": {
      "Section": "Základní informace o vozidle",
      "PayloadCapacity": "Povolená nosnost včetně přívěsů [t]",
      "Type": "Typ nákladního auta",
      "TypeEnum": {
        "straightTruck": "Přímý nákladní vůz",
        "tractor": "Traktor"
      },
      "TunnelCategory": "Kategorie tunelu",
      "TunnelCategoryToolTip": "Kategorie tunelu sloužící k omezení přepravy konkrétního zboží",
      "TrailerAxleCount": "Celk. počet náprav (všechny přívěsy)",
      "TrailerAxleCountToolTip": "Celkový počet náprav napříč všemi přívěsy připojenými k vozidlu",
      "AxleCount": "Celkový počet náprav ve vozidle",
      "TrailerCount": "Počet přívěsů",
      "WeightPerAxleGroup": "Hmotnost na skupinu náprav [t]",
      "WeightPerAxle": "Váha na nápravu [t]",
      "GrossWeight": "Celková váha [t]",
      "Length": "Délka [m]",
      "Width": "Šířka [m]",
      "Height": "Výška [m]"
    },
    "TollEmission": {
      "Section": "Parametry pro výpočet mýta podle emisí",
      "TollEmissionType": "Emisní typ",
      "TollEmissionTypeCO2EmissionClass": "CO2 emisní třída",
      "Type": {
        "euro1": "EURO1",
        "euro2": "EURO2",
        "euro3": "EURO3",
        "euro4": "EURO4",
        "euro5": "EURO5",
        "euro6": "EURO6",
        "euroEev": "EUROEEV"
      }
    },
    "HazardousGoods": {
      "Section": "Seznam nebezpečného zboží přepravovaného ve vozidle",
      "HazardousGood": {
        "explosive": "Explozivní",
        "gas": "Plyn",
        "flammable": "Hořlavý",
        "combustible": "Hořlavý",
        "organic": "Organický",
        "poison": "Jed",
        "radioactive": "Radioaktivní",
        "corrosive": "Žíravý",
        "poisonousInhalation": "Jedovatý při inhalaci",
        "harmfulToWater": "Škodlivý pro vodu",
        "other": "Jiný"
      }
    },
    "AvoidFeatures": {
      "Section": "Vyhnutí se specifickým cestám",
      "Feature": {
        "tunnel": "Tunel",
        "ferry": "Trajekt"
      }
    },
    "ExcludeCountry": {
      "Section": "Vyhnout se následujícím zemím",
      "Country": {
        "ALB": "Albánie",
        "AND": "Andorra",
        "ARM": "Arménie",
        "AUT": "Rakousko",
        "BLR": "Bělorusko",
        "BEL": "Belgie",
        "BIH": "Bosna a Hercegovina",
        "BGR": "Bulharsko",
        "HRV": "Chorvatsko",
        "CYP": "Kypr",
        "CZE": "Česká Republika",
        "DNK": "Dánsko",
        "EST": "Estonsko",
        "FIN": "Finsko",
        "FRA": "Francie",
        "GEO": "Gruzie",
        "DEU": "Německo",
        "GRC": "Řecko",
        "HUN": "Maďarsko",
        "ISL": "Island",
        "IRL": "Irsko",
        "ITA": "Itálie",
        "XKX": "Kosovo",
        "LVA": "Lotyšsko",
        "LIE": "Lichtenštejnsko",
        "LTU": "Litva",
        "LUX": "Lucembursko",
        "MLT": "Malta",
        "MDA": "Moldavsko",
        "MCO": "Monako",
        "MNE": "Černá Hora",
        "NLD": "Nizozemsko",
        "MKD": "Severní Makedonie",
        "NOR": "Norsko",
        "POL": "Polsko",
        "PRT": "Portugalsko",
        "ROU": "Rumunsko",
        "RUS": "Rusko",
        "SMR": "San Marino",
        "SRB": "Srbsko",
        "SVK": "Slovensko",
        "SVN": "Slovinsko",
        "ESP": "Španělsko",
        "SWE": "Švédsko",
        "CHE": "Švýcarsko",
        "TUR": "Turecko",
        "UKR": "Ukrajina",
        "GBR": "Velká Británie",
        "VAT": "Vatikán"
      }
    },
    "DefaultPVSettings": {
      "Section": "Nastavení v přepravách a volných vozech",
      "Preprava": "Výchozí v přepravách",
      "Vozidlo": "Výchozí ve volných vozech"
    },
    "CustomNaklady": {
      "NaHodinu": "Náklady na hodinu",
      "NakladNaKm": "Náklady na km",
      "CelkemZaHodin": "Náklady na trasu (vzdálenost * hodinová sazba)",
      "CelkemZaKm": "Náklady na trasu (vzdálenost * cena za km)",
      "Celkem": "Náklady na trasu celkem",
      "Titulek": "VLASTNÍ NÁKLADY"
    }
  }
};
export default cs;