//@ts-nocheck
/* eslint-disable no-unused-vars */
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import * as React from 'react';
import {cloneClassObject} from "../../../common/utils/Util";
import {Chip, Tooltip} from "@material-ui/core";
import {globalStyles} from "../../context/ThemeModeContext";
import LockIcon from '@material-ui/icons/Lock';

/* eslint-enable no-unused-vars */

class MTableBody extends React.Component {
    ref = React.createRef<HTMLElement>();
    state = {data:this.props.renderData, showAddRow:this.props.alwayShowAdd, disableAddRow: false, hasEditRowError: false, disableEdit: false, selected: false, reloaded: []};
    componentDidMount(): void {
        const div = this.ref.current.parentElement.parentElement;
        const bodyEl = this.ref.current;
        if (this.props.arState) {
            for (let i = 1; i < bodyEl.childNodes.length; i++) {
                // NOT used
                //bodyEl.childNodes[i].addEventListener("mouseenter", this.mouseEnter.bind(this));
                //bodyEl.childNodes[i].addEventListener("mouseleave", this.mouseLeave.bind(this));
            }
        }
        div.addEventListener("scroll", e => this.props.onScrollX(e.target.scrollLeft));

        if(this.props.autofocus) {
            div.setAttribute("tabindex", 0);
            div.focus({preventScroll:this.props.preventScroll});
            div.scrollTo(this.props.leftPosition, 0);
        }

        if(this.props.disableTableScroll){
            const overflow = !this.props.overflowHidden ? this.props.renderData && this.props.renderData.length < 3 ? "hidden" : "auto" : "hidden";
            div.setAttribute("style",  "overflow-y:" + overflow);
            div.setAttribute("class",  "custom-scrollbar");
        } else {
            div.setAttribute("style",  "overflow-y: unset");
        }
    }

    componentWillUnmount() {
        const div = this.ref.current.parentElement.parentElement;
        const bodyEl = this.ref.current
        div.removeEventListener("scroll", e => this.props.onScrollX(e.target.scrollLeft));
        if (this.props.arState) {
            for (let i = 1; i < bodyEl.childNodes.length; i++) {
                // NOT used
               // bodyEl.childNodes[i].removeEventListener("mouseenter", this.mouseEnter.bind(this));
              //  bodyEl.childNodes[i].removeEventListener("mouseleave", this.mouseLeave.bind(this));
            }
        }
    }

    // NOT used
    mouseEnter() {

      /*if (!this.state.disableAddRow) {
          this.props.onMouseEnter && this.props.onMouseEnter();
          this.setState(prevState => ({...prevState, selected: true}));
      }*/
    }

    // NOT used
    mouseLeave() {
      /*  this.setState(prevState => ({...prevState, selected: false}));
        setTimeout(() => {
            if (!this.state.disableAddRow && !this.state.disableEdit && !this.state.selected)
                this.props.onMouseLeave&&this.props.onMouseLeave();
        }, 100)*/

    }

    confirmChangedData(id: number) {
        const data = _.clone(this.state.data);
        const row = data.find(d => d.id === id)
        row.changed = null;
        row.origin = _.clone(row);
        this.setState(prevState => ({...prevState, data: {...data}}));
    }

    renderEmpty(emptyRowCount, renderData) {
        const div = this.ref?.current?.parentElement.parentElement;
        if (this.state.hasEditRowError) {
            div&&div.setAttribute("style", `overflow-y:${this.props.disableTableScroll ? "auto" : "unset" }; min-height:${renderData.length === 1 ? '250' : '300'}px`);
        } else {
            const h = div&&div.getAttribute("style")?.indexOf('hidden');
            div&&div.setAttribute("style", `overflow-y:${this.props.disableTableScroll ? h === -1 ? 'auto' : 'hidden' : "unset" };`);
        }
        const rowHeight = this.props.options.padding === 'default' ? 49 : 36;
        const localization = { ...MTableBody.defaultProps.localization, ...this.props.localization };
        if (this.props.options.showEmptyDataSourceMessage && renderData.length === 0) {
            let addColumn = 0;
            if (this.props.options.selection) {
                addColumn++;
            }
            if (this.props.actions && this.props.actions.filter(a => a.position === "row" || typeof a === "function").length > 0) {
                addColumn++;
            }
            if (this.props.hasDetailPanel) {
                addColumn++;
            }
            if (this.props.isTreeData) {
                addColumn++;
            }
            return (
                <TableRow style={{ height: rowHeight * (this.props.options.paging && this.props.options.emptyRowsWhenPaging ? this.props.pageSize : 1) }} key={'empty-' + 0} >
                    <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'center' }} colSpan={this.props.columns.length + addColumn} key="empty-">
                        {localization.emptyDataSourceMessage}
                    </TableCell>
                </TableRow>
            );
        } else if (this.props.options.emptyRowsWhenPaging) {
            return (
                <React.Fragment>
                    {[...Array(emptyRowCount)].map((r, index) => <TableRow style={{ height: rowHeight }} key={'empty-' + index} />)}
                    {emptyRowCount > 0 && <TableRow style={{ height: 1 }} key={'empty-last1'} />}
                </React.Fragment>
            );
        }
    }

    renderUngroupedRows(renderData) {
        const getClass = (index: number) => {
            return (renderData.length > 3 && index === renderData.length - 2) || (renderData.length > 2 && index === renderData.length - 1) ? 'last-table-row' : ''
        }
        return renderData.map((data, index) => {
            if (data.tableData.editing) {
                return (
                    <this.props.components.EditRow
                        columns={this.props.columns.filter(columnDef => { return !columnDef.hidden })}
                        components={this.props.components}
                        data={cloneClassObject(data)}
                        icons={this.props.icons}
                        localization={{ ...MTableBody.defaultProps.localization.editRow, ...this.props.localization.editRow, dateTimePickerLocalization: this.props.localization.dateTimePickerLocalization }}
                        key={index}
                        mode={data.tableData.editing}
                        options={this.props.options}
                        isTreeData={this.props.isTreeData}
                        detailPanel={this.props.detailPanel}
                        onEditingCanceled={this.props.onEditingCanceled}
                        onEditingApproved={this.props.onEditingApproved}
                        getFieldValue={this.props.getFieldValue}
                        className={getClass(index)}
                    />
                );
            }
            else {
                return (
                    <React.Fragment key={"row-container-" + data.tableData.id}>
                        {data.lockUserInfo && !this.props.disableLockSupport ?
                            <tr key={"row-chip-container-" + data.tableData.id} style={globalStyles.chipLockContainer}><td><Tooltip title={`${data.lockUserInfo.jmeno ?? data.lockUserInfo.login} / ${data.tabId}`}><Chip icon={<LockIcon style={globalStyles.chipLockIcon} />} label={data.lockUserInfo.jmeno ?? data.lockUserInfo.login} style={globalStyles.chipLockRecord} size={"small"}/></Tooltip></td></tr>
                            : undefined}
                        <this.props.components.Row
                            components={this.props.components}
                            icons={this.props.icons}
                            data={data.origin && data.changed ? {...data.origin, changed: true} : data}
                            index={index}
                            key={"row-" + data.tableData.id}
                            level={0}
                            options={this.props.options}
                            localization={{ ...MTableBody.defaultProps.localization.editRow, ...this.props.localization.editRow }}
                            onRowSelected={this.props.onRowSelected}
                            actions={this.props.actions}
                            columns={this.props.columns}
                            getFieldValue={this.props.getFieldValue}
                            detailPanel={this.props.detailPanel}
                            path={[index + this.props.pageSize * this.props.currentPage]}
                            onToggleDetailPanel={this.props.onToggleDetailPanel}
                            onRowClick={this.state.disableEdit ? () => {} : (data: any, origin: any, merge: any) => this.props.onRowClick(data, origin, merge)}
                            isTreeData={this.props.isTreeData}
                            onTreeExpandChanged={this.props.onTreeExpandChanged}
                            onEditingCanceled={this.props.onEditingCanceled}
                            onEditingApproved={this.props.onEditingApproved}
                            hasAnyEditingRow={this.props.hasAnyEditingRow || this.state.disableEdit}
                            treeDataMaxLevel={this.props.treeDataMaxLevel}
                        />
                    </React.Fragment>
                );
            }
        });
    }

    renderGroupedRows(groups, renderData) {
        return renderData.map((groupData, index) => (
            <this.props.components.GroupRow
                actions={this.props.actions}
                key={groupData.value == null ? ('' + index) : groupData.value}
                columns={this.props.columns}
                components={this.props.components}
                detailPanel={this.props.detailPanel}
                getFieldValue={this.props.getFieldValue}
                groupData={groupData}
                groups={groups}
                icons={this.props.icons}
                level={0}
                path={[index + this.props.pageSize * this.props.currentPage]}
                onGroupExpandChanged={this.props.onGroupExpandChanged}
                onRowSelected={this.props.onRowSelected}
                onRowClick={this.props.onRowClick}
                onEditingCanceled={this.props.onEditingCanceled}
                onEditingApproved={this.props.onEditingApproved}
                onToggleDetailPanel={this.props.onToggleDetailPanel}
                onTreeExpandChanged={this.props.onTreeExpandChanged}
                options={this.props.options}
                isTreeData={this.props.isTreeData}
                hasAnyEditingRow={this.props.hasAnyEditingRow}
                localization={{ ...MTableBody.defaultProps.localization.editRow, ...this.props.localization.editRow }}
            />
        ));
    }

    render() {
        let renderData = this.state.data;
        const groups = this.props.columns
            .filter(col => col.tableData.groupOrder > -1)
            .sort((col1, col2) => col1.tableData.groupOrder - col2.tableData.groupOrder);

        let emptyRowCount = 0;
        if (this.props.options.paging) {
            emptyRowCount = this.props.pageSize - renderData.length;
        }
        return (
            <TableBody innerRef={this.ref}>
                {this.props.options.filtering &&
                <this.props.components.FilterRow
                    columns={this.props.columns.filter(columnDef => !columnDef.hidden)}
                    icons={this.props.icons}
                    hasActions={this.props.actions.filter(a => a.position === "row" || typeof a === "function").length > 0}
                    actionsColumnIndex={this.props.options.actionsColumnIndex}
                    onFilterChanged={this.props.onFilterChanged}
                    selection={this.props.options.selection}
                    localization={{ ...MTableBody.defaultProps.localization.filterRow, ...this.props.localization.filterRow, dateTimePickerLocalization: this.props.localization.dateTimePickerLocalization }}
                    hasDetailPanel={!!this.props.detailPanel}
                    isTreeData={this.props.isTreeData}
                    filterCellStyle={this.props.options.filterCellStyle}
                    hideFilterIcons={this.props.options.hideFilterIcons}
                />
                }

                {this.state.showAddRow && this.props.options.addRowPosition === "first" &&
                <this.props.components.EditRow
                    columns={this.props.columns.filter(columnDef => { return !columnDef.hidden })}
                    data={this.props.initialFormData}
                    components={this.props.components}
                    icons={this.props.icons}
                    key="key-add-row"
                    mode="add"
                    localization={{ ...MTableBody.defaultProps.localization.editRow, ...this.props.localization.editRow }}
                    options={this.props.options}
                    isTreeData={this.props.isTreeData}
                    detailPanel={this.props.detailPanel}
                    onEditingCanceled={this.props.onEditingCanceled}
                    onEditingApproved={this.props.onEditingApproved}
                    getFieldValue={this.props.getFieldValue}
                    disableRow={this.state.disableAddRow}
                />
                }

                {groups.length > 0 ?
                    this.renderGroupedRows(groups, renderData) :
                    this.renderUngroupedRows(renderData)
                }

                {this.props.showAddRow && this.props.options.addRowPosition === "last" &&
                <this.props.components.EditRow
                    columns={this.props.columns.filter(columnDef => { return !columnDef.hidden })}
                    data={this.props.initialFormData}
                    components={this.props.components}
                    icons={this.props.icons}
                    key="key-add-row"
                    mode="add"
                    localization={{ ...MTableBody.defaultProps.localization.editRow, ...this.props.localization.editRow }}
                    options={this.props.options}
                    isTreeData={this.props.isTreeData}
                    detailPanel={this.props.detailPanel}
                    onEditingCanceled={this.props.onEditingCanceled}
                    onEditingApproved={this.props.onEditingApproved}
                    getFieldValue={this.props.getFieldValue}
                    disableRow={this.state.disableAddRow}
                />
                }
                {this.renderEmpty(emptyRowCount, renderData)}
            </TableBody>
        );
    }
}

MTableBody.defaultProps = {
    actions: [],
    currentPage: 0,
    pageSize: 5,
    renderData: [],
    selection: false,
    localization: {
        emptyDataSourceMessage: 'No records to display',
        filterRow: {},
        editRow: {}
    }
};

MTableBody.propTypes = {
    actions: PropTypes.array,
    components: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    currentPage: PropTypes.number,
    detailPanel: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.func]))]),
    getFieldValue: PropTypes.func.isRequired,
    hasAnyEditingRow: PropTypes.bool,
    disableLockSupport: PropTypes.bool,
    hasDetailPanel: PropTypes.bool.isRequired,
    icons: PropTypes.object.isRequired,
    isTreeData: PropTypes.bool.isRequired,
    onRowSelected: PropTypes.func,
    options: PropTypes.object.isRequired,
    pageSize: PropTypes.number,
    renderData: PropTypes.array,
    initialFormData: PropTypes.object,
    selection: PropTypes.bool.isRequired,
    showAddRow: PropTypes.bool,
    disableAddRow: PropTypes.bool,
    disableEdit: PropTypes.bool,
    treeDataMaxLevel: PropTypes.number,
    localization: PropTypes.object,
    onFilterChanged: PropTypes.func,
    onGroupExpandChanged: PropTypes.func,
    onToggleDetailPanel: PropTypes.func.isRequired,
    onTreeExpandChanged: PropTypes.func.isRequired,
    onRowClick: PropTypes.func,
    onEditingCanceled: PropTypes.func,
    onEditingApproved: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

export default MTableBody;
