import React, {createRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {FormControl, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {FormFieldInterfaceProps} from "./FormFieldInterface";
import {httpEndpointCustom} from "../../utils/HttpUtils";
import qs from "qs";
import {Mapper} from "../../utils/objectmapper/Mapper";
import {invoke} from "../../utils/Invoke";
import {formatOptions, formatValue, OptionType, SelectOptionType, SelectProps} from "./FormSelect";
import {useDidMount, useMountedEffect, useSafeTimeout} from "../hooks/SharedHooks";
import {useTranslation} from "react-i18next";
import {GenericMap} from "../../../index.d";
import {useFocusable, useFocusTypeDetection} from "./FocusHook";
import {AutocompleteHighlightChangeReason} from "@material-ui/lab/useAutocomplete/useAutocomplete";
import _ from "lodash";
import {DynamicFontSize} from "../DynamicFontSize";
import {clamp, dateAllowedChars, exist, phoneAllowedChars} from "../../utils/Util";
import {StandaloneFieldDataContext} from "./StandaloneField";
import {Box, Popper} from "@mui/material";

export interface FormMuiAutocompleteProps<T> extends FormFieldInterfaceProps<T> {
    selectProps:SelectProps<T>
    autoSelectFirstValueOnTab?: boolean
    getOptionDisabled?:(option: SelectOptionType<T>) => boolean
    useCustomPopperElement?: boolean
}




type Search = (inputValue:string, page?:number, value?: any)=>void
function useSearch<T>({sort=o=>o, data, ...autocompleteProps}:SelectProps & {data: T}):[SelectOptionType[], Search, boolean, boolean] {
    const defaultOptions = autocompleteProps.options||[];
    const {ajax: pAjax, cacheOptions} = autocompleteProps;
    const [setTimeout] = useSafeTimeout(500);
    const [options, setOptions] = useState(defaultOptions);
    const [loading, setLoading] = useState(false);
    let search:Search;
    const ajax = typeof pAjax === 'function' ? pAjax(data) : pAjax;

    const filterByLabel = (array: SelectOptionType[], str: string) => {
        if (!str || autocompleteProps?.doNotFilterByLabel===true) return array;
        return array.filter((o)=> o.label.toLowerCase().includes(str.toLowerCase()));
    }
    if(ajax) {
        search = (inputValue:string, page:number=1, value?: any) => {
            const localExist = cacheOptions && options ? filterByLabel(formatOptions(options, autocompleteProps), inputValue).length !== 0 : false
            if (!localExist) {
                setLoading(true);
                setTimeout(async () => {
                    try {
                        const params: GenericMap = ajax.params || {};
                        if(exist(autocompleteProps.formatExcludeList) && exist(value)) {
                            const excludeList = autocompleteProps.formatExcludeList(value);
                            params["exclude"] = excludeList.join(",");
                        }
                        params[ajax.searchKey || "term"] = inputValue;
                        if (ajax.paginable) params[ajax.pageKey || "page"] = page;
                        const result = await httpEndpointCustom(`${ajax.url}?${qs.stringify(params)}`);
                        let data = result.json.list;
                        if (ajax.clazz) {
                            data = new Mapper({constructor: ajax.clazz}).readValueAsArray(data);
                        }
                        setOptions(sort(data));
                    } finally {
                        setLoading(false);
                    }
                });
            }
        };
    } else {
        search = () => {
        }
    }
    search = useCallback(search, [search]);
    return [formatOptions(ajax?options:defaultOptions, autocompleteProps), search, loading, Boolean(ajax)];
}

export function useHighlight<T>(options:T[]) {
    const highlightedOption = useRef<{option?:T, index?:number}>({});
    return [
        {
            autoHighlight:true,
            onHighlightChange: (
                event: React.ChangeEvent<{}>,
                option: T | null,
                reason: AutocompleteHighlightChangeReason
            ) => {
                highlightedOption.current = {
                    option:option,
                    index:options.indexOf(option)
                }
            }
        },
        highlightedOption
    ];
}

export function FormMuiAutocomplete<T>({selectProps={}, variant="outlined", ...props}:FormMuiAutocompleteProps<T>) {
    const {t} =  useTranslation();
    const [{value, trigger}, setData] = useState({trigger:false, value:formatValue(props.value, selectProps)});
    const row = useContext(StandaloneFieldDataContext);
    const [options, search, loading] = useSearch<T>({ data: row as T, ...selectProps});
    const input = createRef<HTMLInputElement>();
    const [highlightProps, highlighted] = useHighlight<SelectOptionType>(options);
    const {onMouseDown, onMouseUp, userClick} = useFocusTypeDetection();
    const selectedAll = useRef(false);
    const inputChangedReason = useRef(null);
    const componentRef = useRef(null);
    const firstFocus = useRef(false)
    const valueChanged = useRef(false)
    useFocusable(input, props);

    useImperativeHandle(props.fieldRef, () => {
        return {
            clearValue
        }
    })

    const clearValue = () => {
        setData({trigger:true, value: formatValue(null, selectProps)});
    }

    const {isMulti, computedTextFieldForMultiSelect} = selectProps;
    // check max values count
    const preventAddValue = useCallback((values: any)=> {
        return selectProps.isMulti && selectProps.maxValuesCount && values.length > selectProps.maxValuesCount;
    }, [selectProps.isMulti, selectProps.maxValuesCount]);

    const setValue = useCallback((d:SelectOptionType|Array<SelectOptionType>) => {
        if (isMulti && d && (d instanceof Array) && d.some(o => !exist(o))) return;
        !preventAddValue(d) && setData({value:isMulti?_.uniqWith(d as Array<SelectOptionType>, _.isEqual): d, trigger:true});
    }, [isMulti, preventAddValue]);

    const handleValue = () => {
        let values:any|Array<any> = {};
        const formatFn:(value:OptionType)=> any = (val:SelectOptionType) => {
            if(!Boolean(val)){
                return null;
            }
            let rv = val.value;
            if(selectProps.formatValue) {
                rv = selectProps.formatValue(val);
            }
            return rv;
        };
        if(Array.isArray(value)) {
            if(value.length === 0) {
                values = [];
            } else {
                values = new Array<any>();
                value.forEach(i=>values.push(formatFn(i)));
            }
        } else {
            values = formatFn(value);
        }
        if(trigger)
            props.onValueChanged(values);
    };
    //this method not serve for searching over array but re
    useMountedEffect(()=> {
        setData({trigger:false, value: formatValue(props.value, selectProps)});
    }, [props.value]);
    useMountedEffect(handleValue, [value]);
    useEffect(()=>{
        if(props.focused) {
            const pos = input.current?.value?.length??0;
            setTimeout(() => !valueChanged.current || !selectProps.ajax ? input.current?.select() : input.current?.setSelectionRange(pos, pos), 1);
            if (selectProps?.selectAll && input.current?.value && !selectedAll.current) {
                selectedAll.current = true;
                setTimeout(() => input.current?.select(), 1);
            }
        }
    });
    useDidMount(()=> {
        if (computedTextFieldForMultiSelect) input.current.style.minWidth = `1px`;
        props.enableFocusSupport();
    });

    // textfield callbacks
    const oninputchange = useCallback(async(event) => {
        await search(event.target.value as string, undefined, value);
        // eslint-disable-next-line
    }, [search, value]);
    const {onFocus:onFocusProps, focused, onInteractStart, onInteractEnd} = props;
    const oninputfocus = useCallback(() => {
        if(!focused && selectProps.autoOpenDisabled) {
            if (input.current?.value) setTimeout(() => input.current?.select(), 1);
            invoke(onFocusProps, true);
        }else {
            invoke(onFocusProps, userClick.current);
        }
        invoke(search, undefined, undefined, value)
        if (!firstFocus.current) firstFocus.current = true;
    }, [search, input, onFocusProps, userClick, focused, selectProps.autoOpenDisabled, value]);

    const {onKeyDown:onKeyDownProps} = props;
    // @ts-ignore
    const findLongest = v => Math.max(...(v.map(el => el.label?.length)));

    const onInputKeyDown = useCallback((e:React.KeyboardEvent<HTMLInputElement>)=>{
        if (!valueChanged.current) valueChanged.current = true;
        if (selectProps.inputType === 'date' && !dateAllowedChars(e.key)) {
            e.preventDefault();
            return;
        }
        if (selectProps.inputType === 'phone' && !phoneAllowedChars(e.key)) {
            e.preventDefault();
            return;
        }
        let charLength = 0
        if (computedTextFieldForMultiSelect && input.current) {
            if (input.current.value) charLength = input.current.value.length;
            input.current.style.minWidth = `${clamp(10 * charLength, 5, 100)}px`;
        }
        // @ts-ignore
        const option = highlighted.current?.option;
        let testArray: any[] = [];
        if (isMulti && value instanceof Array && componentRef.current && option) {
            testArray = [...value, option];
        }

        if(e.key === ' ' && option && !(e.ctrlKey && e.key === ' ')) {
            if (isMulti) setFieldWidth(testArray);
            e.preventDefault();
            let valueRemoved = false;
            if (isMulti && exist(value) && value instanceof Array) {
                const index = value.findIndex(v => v.value === option.value);
                if (index !== -1) {
                    value.splice(index, 1);
                    valueRemoved = true;
                    setValue([...value as []]);
                }
            }
            if (!valueRemoved) {
                setValue(isMulti ? [...value as [], option] : option);
            }
        }
        if(props.autoSelectFirstValueOnTab && (e.key === 'Tab' || e.key === 'Enter') && !e.shiftKey) {
            e.preventDefault();
            if (options?.length !== 0) {
                if (isMulti) setFieldWidth(testArray);
                if (!value || (value instanceof Array && value.length === 0))
                    if (inputChangedReason.current !== 'clear' && option) setValue(isMulti ? [...value as [], option] : option);
            }
            invoke(props.onFocusNextField);
        }
        onKeyDownProps&&onKeyDownProps(e);
        // eslint-disable-next-line
    }, [onKeyDownProps, highlighted, isMulti, setValue, value, options, props.onFocusNextField, props.autoSelectFirstValueOnTab, computedTextFieldForMultiSelect, inputChangedReason.current]);

    //autocomplete callbacks
    const onchange = useCallback(async (event:React.ChangeEvent<{}>, values:any|any[]) => {
        if (isMulti && values instanceof Array && componentRef.current) {
            setFieldWidth(values);
        }
        if(!onFocusProps)
            invoke(onFocusProps, true);

        if(event.nativeEvent instanceof KeyboardEvent) {
            if(isMulti && event.nativeEvent.key === "Enter") {
                return;
            }
        }
        setValue(values);
        invoke(onInteractEnd);
        // eslint-disable-next-line
    }, [onFocusProps, setValue, isMulti]);

    const setFieldWidth = useCallback((array: any[]) => {
        const longest = array ? findLongest(array) : 0;
        componentRef.current.style.minWidth = array.length !== 0 && longest > 7 ? `${clamp(longest * 13, selectProps?.minWidth + 40, 250)}px` : `${selectProps?.minWidth + 10??100}px`
        // eslint-disable-next-line
    }, [selectProps.minWidth])

    const onclose = useCallback(()=>{
        invoke(onInteractEnd);
    }, [onInteractEnd]);

    const onopen = useCallback(()=>{
        if(!focused) invoke(onFocusProps, true);
        invoke(onInteractStart);
        if (selectProps?.autoSelectLastValueOnError) {
            //@ts-ignore
            if (options && value?.value && !options.some(o => o.value === value.value)) {
                setValue(options[options.length - 1]);
            }
        }
        // eslint-disable-next-line
    }, [onInteractStart, focused, onFocusProps]);

    const dateInputProps = {
        pattern: selectProps.inputType === 'date' ? "[\\d\\.]" : null,
        inputMode: selectProps.inputType === 'date' ? selectProps.inputMode : 'text'
    }

    const PopperMy = (props: any) => {
        return <Popper {...props} style={{width: 'fit-content'}}/>
    }

    return (
        <FormControl fullWidth error={typeof props.error !== 'undefined'} style={{...props.style}}>
            {(
                <>
                    <Autocomplete<SelectOptionType, boolean, boolean, boolean>
                        options={options}
                        getOptionSelected={(option, value1) => selectProps.getOptionSelected ? selectProps.getOptionSelected(option, value1) : option?.value === value1?.value}
                        getOptionLabel={option => option?.label}
                        filterOptions={selectProps?.doNotFilterByLabel===true ? options => options : undefined}
                        style={{...props.style, color: 'red'}}
                        value={value}
                        loading={loading}
                        loadingText={t("Default.Loading")}
                        groupBy={option => option.group ?? null}
                        disabled={props.disabled}
                        getOptionDisabled={props.getOptionDisabled}
                        multiple={selectProps.isMulti}
                        openOnFocus={exist(selectProps.autoOpenDisabled) ? !selectProps.autoOpenDisabled : true}
                        noOptionsText={t("MuiAutocomplete.NoOption")}
                        disableClearable={!selectProps.isClearable}
                        {...highlightProps}
                        onChange={onchange}
                        onClose={onclose}
                        renderOption={(option) => (<Box style={selectProps.customOptionStyle ? selectProps.customOptionStyle(option) : {}}>{option.label}</Box>)}
                        onInputChange={(e, n, reason) => {
                            inputChangedReason.current = reason;
                        }}
                        onOpen={onopen}
                        PopperComponent={props.useCustomPopperElement ? PopperMy : undefined}
                        // @ts-ignore
                        renderInput={params => <TextField {...params}
                                                          InputProps={{...{...{
                                                              ...params.InputProps,
                                                              className: props.inputClassName && ((isMulti && (value instanceof Array) && value.length !== 0) || (!isMulti && value))
                                                                  ? props.inputClassName
                                                                  :
                                                                  params.InputProps?.className,
                                                                  style: selectProps.inputStyle ? {...selectProps.inputStyle(value)} : {}
                                                          }}}}
                                                          innerRef={componentRef}
                                                          inputRef={input}
                                                          hiddenLabel={!Boolean(props.title)}
                                                          value={(isMulti && value instanceof Array && value.length !== 0) || (!isMulti && value) ? ' ' : null}
                                                          label={props.title}
                                                          variant={variant}
                                                          style={{marginTop:0}}
                                                          disabled={props.disabled}
                                                          autoComplete={"off"}
                                                          error={typeof props.error !== 'undefined'}
                                                          helperText={props.error && props.error}
                                                          onKeyDown={onInputKeyDown}
                                                          onKeyUp={props.onKeyUp}
                                                          size={"small"}
                                                          onMouseDown={onMouseDown}
                                                          onMouseUp={onMouseUp}
                                                          onFocus={oninputfocus}
                                                          onMouseDownCapture={(e) => {
                                                              if (selectProps.autoOpenDisabled && !firstFocus.current) e.stopPropagation();
                                                          }}
                                                          onBlur={() => {
                                                              firstFocus.current = false;
                                                              valueChanged.current = false;
                                                              props.onBlur&&props.onBlur();
                                                              invoke(onInteractEnd);
                                                          }}
                                                          inputMode={selectProps.inputMode ?? 'text'}
                                                          onChange={oninputchange}
                                                          inputProps={{...{...{...params.inputProps, ...dateInputProps }}}}
                                                />}
                    />
                    {!selectProps.noDynamicFontSize&&!(value instanceof Array)&&<DynamicFontSize text={value?.label} target={input}/>}
                </>
            )}
        </FormControl>


    )
}
