import {useAppContext} from "../../../context/AppContext";
import {Box, Paper} from "@material-ui/core";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {Loading} from "../../../../common/component/Loading";
import {ProvozovnaDetail} from "../ProvozovnaDetailView";

export function ProvozovnaDetailMaster() {
    const {user} = useAppContext();
    const {t} = useTranslation();

    return !user ?
        <Paper>
            <div style={{position: "relative", height: '60vh'}}><Loading show={!user} title={t("Default.Loading")} /></div>
        </Paper>
        :
        <Paper>
            <Box p={5}>
                <ProvozovnaDetail
                    provozovnaId={user.provozovna.id}
                    master={true}
                    adminView={false}
                />
            </Box>
        </Paper>
}
