import {useTranslation} from "react-i18next";
import React, {Ref, useImperativeHandle, useState} from "react";
import {MuiModal} from "../../../../../common/component/MuiModal";
import {SbernaSluzbaZadani} from "../../../../model/PrepravaVozidlo";
import DataStorage from "../../../../../common/DataStorage";
import {useDataStore} from "../../../../../common/utils/Util";
import {invoke} from "../../../../../common/utils/Invoke";
import {SbernaSluzbaZadaniForm} from "./SbernaSluzbaZadani";

export type ModalSbernaSluzbaFilterExposed = {
    setModalVisibility: () => void
}

type ModalSbernaSluzbaFilterProps = {
    modalRef?: Ref<ModalSbernaSluzbaFilterExposed>
    sbernaSluzbaZadani: SbernaSluzbaZadani
    onSend?: () => void
    onClose?: () => void
    redirectAfterSend?: boolean
}

export function ModalSbernaSluzbaFilter(props:ModalSbernaSluzbaFilterProps) {
    const {t} = useTranslation();
    const {sbernaSluzbaZadani, modalRef} = props;
    const [zadaniOpen, setZadaniOpen] = useState(() => false);
    const [,storeZadani] = useDataStore(SbernaSluzbaZadani, "SbernaSluzbaZadani", true, "session")

    useImperativeHandle(modalRef, () => ({
        setModalVisibility
    }));

    const setModalVisibility = () => {
        setZadaniOpen(!zadaniOpen)
    }

    return (
        <MuiModal
            title={t("SbernaSluzba.search")}
            maxWidth={"md"}
            fullScreen={false}
            open={zadaniOpen}
            onClose={() => {
                setZadaniOpen(false);
                invoke(props.onClose)
            }}>
            {zadaniOpen ? <SbernaSluzbaZadaniForm
                sbernaSluzbaZadani={sbernaSluzbaZadani}
                commit={(result) => {
                setZadaniOpen(false);
                invoke(props.onClose)
                storeZadani(result);
                if (!props.redirectAfterSend) {
                    invoke(props.onSend)
                } else {
                    DataStorage.redirectWithTargetBlank(`/sberna-sluzba`);
                }
            }}/> : null}
        </MuiModal>
    )
}
