import React, {useRef} from 'react';
import {useTranslation} from "react-i18next";
import {Dial} from "../../../raal_components/dial/Dial";
import {Bleskovka} from "../../../model/Zprava";
import {Bold} from "../../../raal_components/Header/Bleskovky";
import {useMarkRead} from "../../../raal_components/BleskovkyService";
import {Grid, Typography} from "@mui/material";
import {RouteComponentProps} from "../../../routes";
import {useClearZaznamOznaceni, useZaznamOznaceni, useZaznamOznaceniStyle, ZaznamOznaceniTyp} from './Oznaceni';
import {DataGridExposed} from "../../../raal_components/grid/DataGrid";

const ThunderNewsForm = ({data}:{data: Bleskovka}) => {
    return <Grid container direction="column" spacing={1}>
        <Grid item>
            <Typography variant="h6">{data.zprava?.predmet}</Typography>
        </Grid>
        <Grid item>
            <div className={"ql-editor"} dangerouslySetInnerHTML={{__html:data.zprava?.clanek}}/>
        </Grid>
    </Grid>
}

export function ThunderNews(props: RouteComponentProps) {
    const {t} = useTranslation();
    const {call:markAsRead}  = useMarkRead();
    const dtGrid = useRef<DataGridExposed<Bleskovka>>();
    const zaznamOznaceni = useZaznamOznaceniStyle(ZaznamOznaceniTyp.BLESKOVKY);
    const clearZaznamOznaceni = useClearZaznamOznaceni(
        useZaznamOznaceni("user", ZaznamOznaceniTyp.BLESKOVKY),
        () => {dtGrid.current?.table()?.refresh()}
    );

    return (
        <>
            <Dial<Bleskovka>
                gridRef={dtGrid}
                mode={props.mode}
                config={{
                    cache: {disabled: true},
                    tableTitle : t("Dials.Messages"),
                    alternativeButtons: [clearZaznamOznaceni],
                    columns:[{
                        field:"zprava.datum",
                        title:t("Default.Datum"),
                        defaultSort:"desc",
                        render:data=><Bold bleskovka={data}>{data.zprava.datum.format("L LT")}</Bold>
                        },
                        {
                            field:"zprava.platiDo",
                            title:t("ThunderNews.MessagesValidTo"),
                            render:data=><Bold bleskovka={data}>{data.zprava.platiDo.format("L LT")}</Bold>
                        },
                        {
                            field:"zprava.predmet",
                            title:t("ThunderNews.Predmet"),
                            render:data=><Bold bleskovka={data}>{data.zprava.predmet}</Bold>
                        },
                        {
                            field:"zprava.typZpravy",
                            title:t("ThunderNews.MessagesType"),
                            render:data=><Bold bleskovka={data}>{t(`Enumerations.TypZpravy.${data.zprava.typZpravy}`)}</Bold>
                        }
                    ],
                    options: {
                        rowStyle: (data: any) => {
                            const oznaceniStyle = zaznamOznaceni.getStyle(data);
                            if(oznaceniStyle) return oznaceniStyle;
                        }
                    },
                    clazz:Bleskovka,
                    endpoint:"bleskova-zprava",
                    getDetailIdUrlPart: (data) => `${data.zprava.id}`,
                    filtering:false,
                    hideAddNewActions:true
                }}
                onDetailLoaded={data => {
                     markAsRead({config:{arg:data}});
                }}
                isDetailReadOnly
                formDisabled={() => true}
                crudConfig={{addEnabled:false, editEnabled: true, removeEnabled:false}}
                layoutForm={(edited, data)=><ThunderNewsForm data={data} />}
            />
        </>
    );
}
