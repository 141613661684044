import {AppBar, Box, createStyles, Dialog, Grid, IconButton, Tab, Tabs} from "@material-ui/core";
import React, {PropsWithChildren} from "react";
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from "@material-ui/core/Toolbar";
import {MuiTabPanel} from "./MuiTabs";
import LocalLoadingContext from "../../web/context/LocalLoadingContext";
import {useStyleContext} from "../../web/context/ThemeModeContext";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

export const useStylesModal = makeStyles(() =>
    createStyles({
        rightToolbar: {
            marginLeft: 'auto',
            marginRight: 0,
        }
    }),
);

type MuiDetailTabModalProps = {
    open:boolean,
    onClose:()=>void,
    title?: string,
    fullScreen?:boolean
}

export function MuiDetailTabModal({open, children, onClose, title, fullScreen}:PropsWithChildren<MuiDetailTabModalProps>) {
    const classes = useStylesModal();
    const {classes:cls} = useStyleContext();
    const {t} = useTranslation();
    const handleClose = () => {
        onClose();
    };
    return (

        <Dialog fullScreen={fullScreen} open={open}>
            <AppBar position="fixed" >
                <Toolbar variant={"regular"}>
                    <Tabs value={0} aria-label="simple tabs example">
                        <Tab label={title ? title : t("Default.TabZakladni")}/>
                    </Tabs>
                    <section className={classes.rightToolbar}>
                        <IconButton edge="end" color="inherit" onClick={(e) => {
                            e.stopPropagation();
                            handleClose&&handleClose();
                        }} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </section>
                </Toolbar>
            </AppBar>
            <div className={cls.toolbar} />
            <Box p={5}>
                <MuiTabPanel value={0} index={0}>
                    <LocalLoadingContext>
                        <Grid item>
                            {children}
                        </Grid>
                    </LocalLoadingContext>
                </MuiTabPanel>
            </Box>
        </Dialog>
    );
}
