import * as React from "react";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {Currency} from "../../../model/Currency";
import {FormField, FormInputType} from "../../../raal_components/form/Form";
import {decimalNumberFormat, rateNumberConstraint} from "../../../../common/common-constraints";
import {CheckMark} from "../../../../common/component/CheckMark";
import {useCodeBookDetailContextNew} from "../../../raal_components/controller/CodeBookDetail";
import {Dial} from "../../../raal_components/dial/Dial";
import {RouteComponentProps} from "../../../routes";

function CurrencyCiselnikForm(): React.ReactElement {
    const {edited} = useCodeBookDetailContextNew<Currency>();
    const {t} = useTranslation();
    return (
        <Grid container spacing={2}>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField name={"currencyCode"} title={t("Currency.currencyCode")} type={"text"} disabled={edited} required serverValidation={{translate:t, index:0}} textFieldProps={{inputProps: {maxLength: 3}}}/>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <FormField name={"name"} title={t("Currency.name")} type={"text"} required textFieldProps={{inputProps: {maxLength: 20}}}/>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={t("Currency.kurz")} name='kurz' type='number' numberProps={{format:decimalNumberFormat, constraint:rateNumberConstraint}}/>
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
                <FormField title={t("Default.Enabled")} name='enabled' type='checkbox'/>
            </Grid>
        </Grid>
    );
}

export function CurrencyCiselnik(props: RouteComponentProps) {
    const {t} = useTranslation();
    return <Dial<Currency>
        mode={props.mode}
        config={{
            tableTitle : t("Dials.Currencies"),
            endpoint:"admin/currency",
            clazz : Currency,
            filtering:true,
            getDetailIdUrlPart: (data) => `${data.currencyCode}`,
            exportConfig: {
                getExportDetailIdUrlPart: (data) =>
                    ({endpoint: "/", id: data.currencyCode}),
                exportable: true,
                exportAll: true,
                endpoint: "admin/currency",
                fileName: "export_meny",
                translationPrefix: ['Currency', 'Default'],
                formats: ["csv"],
            },
            columns: [
                {
                    title:t("Currency.currencyCode"),
                    defaultSort: "asc",
                    field:"currencyCode",
                    filterProps:() => ({type:FormInputType.Text})
                },
                {
                    title:t("Currency.name"),
                    field:"name",
                    filterProps:() => ({type:FormInputType.Text})
                },
                {
                    title:t("Currency.kurz"),
                    field:"kurz"
                },
                {
                    title:t("Currency.datum"),
                    field:"datum",
                    render: (data: Currency)=>data.datum?.format("L")
                },
                {
                    title:t("Default.Enabled"),
                    field:"enabled",
                    render: (data: Currency)=><CheckMark checked={data.enabled}/>
                }
            ]
        }}
       crudConfig={{editEnabled:true, removeEnabled:true, addEnabled:false}}
       hideNewButtonOnEdit
       createInstance={() => Currency.of()}
       modalHeaderName={()=>t("Default.ModalZakladni")}
       layoutForm={() => <CurrencyCiselnikForm />}
       excludeFieldsForIntegerCheck={["kurz"]}
    />;
}
