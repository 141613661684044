//@ts-nocheck
/* eslint-disable no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';

/* eslint-enable no-unused-vars */

class MTableActions extends React.Component {

    render() {
        const map = (action, index) => <this.props.components.Action action={action} key={"action-" + index} data={this.props.data} size={this.props.size} disabled={this.props.disabled || action.disableButton} />;
        if (this.props.actions && this.props.data) {
            return this.props.actions.filter((a, i) => {
                if(typeof a.action === "function") {
                    return !a.action().disabled;
                } else if(typeof a === "function") {
                    return !a().disabled;
                } else if (a.disabled) {
                    return false;
                }
                return true;
            }).map(map);
        }
        return null;
    }
}

MTableActions.defaultProps = {
    actions: [],
    data: {}
};

MTableActions.propTypes = {
    components: PropTypes.object.isRequired,
    actions: PropTypes.array.isRequired,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    disabled: PropTypes.bool,
    size: PropTypes.string,
};

export default MTableActions;