import {Geometry} from "./Geometry";
import {Moment} from "moment";
import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {MomentConverters} from "./Converters";
import {DateRange} from "../../common/component/form/FormDateRange";

export enum TypNabidek {
    GLOBAL, EU, CZSK, CZ, SK
}

export enum FirmaAktualizaceRejstrikState {
    NEZMENENO, CEKA_SCHVALENI, CEKA_OVERENI
}

export class Firma {

    id:number;

    ico: string;

    dic: string;

    @JsonProperty({type: {clazz:Geometry}})
    misto: Geometry;

    @JsonProperty({converters: MomentConverters})
    datSmlouvy: Moment;

    @JsonProperty({converters:MomentConverters})
    regDatum: Moment;

    http: string;

    @JsonProperty({converters:MomentConverters})
    zmenaRejstrikDatum: Moment;

    poznamkaRejstrik: string;

    nadpripad: boolean;

    pojisteni: string;

    @JsonProperty({converters:MomentConverters})
    datPojist: Moment;

    perioda: number;

    @JsonProperty({converters:MomentConverters})
    datZkus: Moment;

    pozastavena: boolean;

    @JsonProperty({type:{enum: FirmaAktualizaceRejstrikState}})
    aktualizaceRejstrik: FirmaAktualizaceRejstrikState;

    smlouvaS: number;

    r1Nazev: string;

    r1Ulice: string;

    r1CisloPopisne: string;

    r1Obec: string;

    r1CastObce: string;

    r1Psc: string;

    r1Stat: string;

    icDph: string;

    dic1: string;

    gdpr: boolean;

    @JsonProperty({name: "fPoznamka"})
    fPoznamka: string;

    nezadouciZakaznik: boolean;

    firmaId?: number;
}


export class FirmaFilter extends Firma {
    datPojistRange: DateRange;
    datSmlouvyRange: DateRange;
    regDatumRange: DateRange;
}