import React from "react";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    videoCard: {
        marginBottom:"30px",
        borderRadius: "13px",
        padding: "20px",
        backgroundColor: theme.palette.type === 'dark' ? "#252525" : "#e4e4e4"
    },
    video: {
        borderRadius: "11px",
        width: "100%",
        maxWidth: "450px"
    }
}));

export function DashboardVideo({videoURL, thumbnailTranslationUrl}: {videoURL: string, thumbnailTranslationUrl: string})  {
    const classes = useStyles();
    const {t} = useTranslation();

    const thumbnailUrl = t(thumbnailTranslationUrl);
    return (
        <Grid container className={classes.videoCard}
              alignItems={"center"} alignContent={"space-between"} justifyContent={"center"} direction="row">
            <video className={classes.video} controls muted poster={(thumbnailUrl.includes(".png") || thumbnailUrl.includes(".jpg")) ? thumbnailUrl : undefined} controlsList="nodownload">
                <source src={videoURL} type="video/mp4"/>
            </video>
        </Grid>
    );
}