import {useTranslation} from "react-i18next";
import HelpIcon from "@material-ui/icons/Help";
import React from "react";
import {HeaderButtonProps, IconButtonWithText} from "./HeaderAction";
import {useHistory} from "react-router";
import {useAppContext} from "../../context/AppContext";

export function HeaderHelpButton({showText}:HeaderButtonProps) {
    const {t} = useTranslation();
    const {push} = useHistory();
    const {checkDataChanged} = useAppContext();
    let tooltip = t("Buttons.Help");
    return (
        <IconButtonWithText onClick={() => checkDataChanged(() => push("/help"))} icon={<HelpIcon />} tooltip={tooltip} showText={showText} text={t("Buttons.Help")} />
    );
}