import React, {MutableRefObject, ReactElement, useImperativeHandle, useState} from "react";
import {Box, Button, Grid, SvgIcon, Tooltip, Typography} from "@material-ui/core";
import TablePagination, {TablePaginationProps} from "@material-ui/core/TablePagination";
import {makeStyles} from "@material-ui/core/styles";
import {FormButtonProps} from "../form/FormButton";
import * as MaterialIcon from "@material-ui/icons";
import {useTranslation} from "react-i18next";

export type MTPaginationExposed = {
    reload: () => void
}

type MTPaginationProps = {
    footerPagingText?:string,
    hidePaginationButtons?:boolean
    overallCount: number
    footerButtons?: () => ReactElement<FormButtonProps, any>
    componentRef?: MutableRefObject<MTPaginationExposed>
}&TablePaginationProps


function TablePaginationActions(props: any) {
    const { count, page, rowsPerPage, onPageChange } = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const labelDisplayedRows = () => {
        return props.labelDisplayedRows({
            from: props.page*props.rowsPerPage  + 1,
            to: (()=> {
                const to = props.rowsPerPage * (props.page + 1);
                return props.count < to ? props.count : to;
            })(),
            count: props.count,
            overall: props.overallCount,
            page: props.page
        })
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 1 }} className={classes.pagination} >
            <Tooltip title={<span className={classes.tooltip}>{t("MaterialTable.pagination.firstTooltip")}</span>}>
                <span><Button disabled={page === 0} size={"small"} color="primary" variant="contained"
                              onClick={(e)=>onPageChange(e, 0)}>
                    <SvgIcon component={MaterialIcon.FirstPage}/>
                </Button></span>
            </Tooltip>
            <Tooltip title={<span className={classes.tooltip}>{t("MaterialTable.pagination.previousTooltip")}</span>}>
                <span><Button disabled={page === 0} size={"small"} color="primary" variant="contained"
                              onClick={(e)=>onPageChange(e, page - 1)}>
                    <SvgIcon component={MaterialIcon.KeyboardArrowLeft}/>
                </Button></span>
            </Tooltip>
            <Typography color="inherit" variant="caption" style={{flexShrink: 0, marginRight:5}}>
                {labelDisplayedRows()}
            </Typography>
            <Tooltip title={<span className={classes.tooltip}>{t("MaterialTable.pagination.nextTooltip")}</span>}>
                <span><Button disabled={page >= Math.ceil(count / rowsPerPage) - 1} size={"small"} color="primary" variant="contained"
                              onClick={(e)=>onPageChange(e, page + 1)}>
                    <SvgIcon component={MaterialIcon.KeyboardArrowRight}/>
                </Button></span>
            </Tooltip>
            <Tooltip title={<span className={classes.tooltip}>{t("MaterialTable.pagination.lastTooltip")}</span>}>
                <span><Button disabled={page >= Math.ceil(count / rowsPerPage) - 1} size={"small"} color="primary" variant="contained"
                              onClick={(e)=>onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1))} >
                    <SvgIcon component={MaterialIcon.LastPage}/>
                </Button></span>
            </Tooltip>
        </Box>
    );
}

const NoPaginationComponent = (p: any) => <TablePagination {...p} ActionsComponent={()=>(
    <Typography color="inherit" variant="caption" style={{flexShrink: 0, marginRight:5, color:"rgba(0, 0, 0, 0.54)"}}>
        {p.labelDisplayedRows({
            from: p.page + 1,
            to: (()=> {
                const to = p.rowsPerPage * (p.page + 1);
                return p.count < to ? p.count : to;
            })(),
            count: p.count
        })}
    </Typography>
)}/>

const FooterTextComponent = (props: TablePaginationProps, footerPagingText: string, classes: any) => {
    const {
        ActionsComponent,
        onChangePage,
        onChangeRowsPerPage,
        ...tablePaginationProps
    } = props;
    return <TablePagination
                {...tablePaginationProps}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={(subprops) => {
                    const { ...actionsComponentProps } = subprops;
                    return (
                        <ActionsComponent
                            {...actionsComponentProps}
                            onChangePage={subprops.onPageChange}
                        />
                    );
                }}
                component={(p) =>
                 (<td style={{
                         width: '100%',
                         overflow: 'auto',
                         float: 'left'
                     }}>
                         <Grid container style={{overflow: 'auto'}} justifyContent={"space-between"}
                               alignItems={"center"} wrap={"wrap-reverse"}>
                             <Grid item className={classes.buttonPart} style={{maxWidth: '20%'}}>
                                 {p.footerButtons()}
                             </Grid>
                             <Grid item>
                                 <Typography color="inherit" variant="body2" style={{
                                     padding: 12,
                                     overflow: 'hidden',
                                     textOverflow: 'ellipsis',
                                     textAlign: 'center'
                                 }}>{footerPagingText}</Typography>
                             </Grid>
                             <Grid item>
                                 {p.children}
                             </Grid>

                         </Grid>
                 </td>)
                }
    />
}

const PaginationComponent = (props: TablePaginationProps, classes: any) => {
    const {
        ActionsComponent,
        onChangePage,
        onChangeRowsPerPage,
        ...tablePaginationProps
    } = props;

    return <TablePagination
        {...tablePaginationProps}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        ActionsComponent={
            (subprops) => {
                const { ...actionsComponentProps } = subprops;
                return (

                    <TablePaginationActions
                        {...props}
                        {...actionsComponentProps}
                        onChangePage={subprops.onPageChange}
                    />
                );
            }
        }
        component={(p) =>
        (<td style={{
         width: '100%',
         overflow: 'auto',
         float: 'left'
        }}>
            <Grid container style={{overflow: 'auto'}} justifyContent={"space-between"} alignItems={"center"} wrap={"wrap-reverse"}>
                <Grid item className={classes.buttonPart} style={{maxWidth: '50%'}}>
                    {p.footerButtons()}
                </Grid>
                <Grid item className={classes.listSize}>
                    {p.children}
                </Grid>
            </Grid>
        </td>
        )}
    />
}

const useStyles = makeStyles(theme => ({
    buttonPart: {
        marginLeft: '1rem',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    listSize: {
        "& .MuiSelect-root": {
            fontSize: "1.1em",
            minHeight: "auto",
        },
    },
    tooltip: {
        textTransform: "uppercase",
        fontWeight: 600,
        fontSize: "1.2em"
    },
    pagination: {
        "& button": {
            height:"30px",
            width: "30px",
            minWidth: "26px",
            padding: "2px",
            marginRight: "5px",
        },
        "& .MuiTypography-root": {
            fontSize: "1em",
        },
    }
}))

export function MTPagination({footerPagingText, hidePaginationButtons, ...props} : MTPaginationProps) {
    const classes = useStyles();
    const [r, setReload] = useState(false);
    useImperativeHandle(props.componentRef, () => ({
        reload
    }));

    const reload = () => {
        setReload(!r);
    }

    let tempProps = {...props}
    //delete tempProps['onChangeRowsPerPage'];
    if (hidePaginationButtons)
        return NoPaginationComponent(tempProps)
    else if (footerPagingText)
        return FooterTextComponent(tempProps, footerPagingText, classes)
    else
        return PaginationComponent(tempProps, classes)
}
