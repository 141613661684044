import {CustomFieldComponentProps} from "../../../common/component/form/FormFieldInterface";
import {useTranslation} from "react-i18next";
import React, {MouseEventHandler, ReactElement, useEffect, useRef, useState} from "react";
import {isDeepEqualNullIgnored} from "../../../common/utils/Util";
import {Grid, TextField, Theme, Tooltip, useMediaQuery} from "@material-ui/core";
import {useMountedEffect} from "../../../common/component/hooks/SharedHooks";
import {useFetchCustom} from "../../../common/utils/HttpUtils";
import {Template} from "../../model/Template";
import {showFilterHeaderDialog} from "../grid/table/FilterHeaderModal";
import {TemplateDialogType} from "../grid/DataGrid.d";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import {StandaloneField} from "../../../common/component/form/StandaloneField";
import {FormInputType} from "./Form";
import {
    FormSelectPopupItem,
    useShowSelectPopup
} from "../../../common/component/form/FormSelectPopup";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {TemplatesFunctions} from "../grid/MTOverrides";

const useFilterStyles = makeStyles((theme) =>
    createStyles({
        tooltip: {
            textTransform: "uppercase",
            fontSize: "10pt"
        }
    })
);

export type FormFilterOptions<Filter> = {
    templateFunctions: TemplatesFunctions
    filters:Filter
    filtersType: string
    isFilterChanged: boolean
    filterChanged?: (filterName: string, clear?: boolean) => void
    isDefault?: boolean
}

export type FilterSelectPopupOptions = {
    typ: string
    templateFunctions: TemplatesFunctions
    setSelectedValue: (value: FormSelectPopupItem) => void
}

export const useFilterSelectPopup = (options: FilterSelectPopupOptions) => {
    const {t} = useTranslation();
    const currentFilterId = useRef(null);

    const {fetch: templatesFetch} = useFetchCustom<Template[], string>({endpoint: (arg) => `user/filter-template/all?vychozi=true&typUlozenehoFiltru=${arg}`})

    const getTemplates = async () => {
        const templates = await templatesFetch({arg: options.typ});
        return templates.map(template => ({
            label: template.nazevFiltru,
            value: template.id as any,
            data: template
        }));

    }
    const removeTemplate = (item: FormSelectPopupItem) => {
        showFilterHeaderDialog(TemplateDialogType.REMOVE_CONFIRMATION_DIALOG, options.templateFunctions, () => {
            if(currentFilterId.current === item?.value) options.setSelectedValue(null);
            showPopup(currentFilterId.current).then();
        }, item?.value);
    }

    const showPopup = useShowSelectPopup({
        getSelectData: getTemplates,
        modalTitle: t("Filter.SelectTemplate"),
        noDataText: t("Filter.NoTemplates"),
        itemActions: [
            {icon: <DeleteOutlineIcon/>, tooltip: t("Filter.RemoveTemplate"), onClick: removeTemplate}
        ]
    }, options.setSelectedValue);

    return async (selectedValue: any) => {
        currentFilterId.current = selectedValue;
        return showPopup(selectedValue);
    }
}

export function FormFilter<Filter>(props:CustomFieldComponentProps<{id: any, name: string}, FormFilterOptions<Filter>>) {
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const classes = useFilterStyles();
    const {t} = useTranslation();
    const fns = options.templateFunctions;

    //Form values (selected, new, isDefault)
    const [newFilterName, setNewFilterName] = useState(null);
    const [isDefault, setIsDefault] = useState(options.isDefault ?? false);
    const [selectedValue, setSelectedValue] = useState({label: props.value.name, value: props.value.id});
    useEffect(() => {
        if(props.value.id == null && selectedValue?.value != null){
            setSelectedValue({label: null, value: null});
        }
    }, [props.value]);

    //Render conditions / state
    const isFilterEmpty = isDeepEqualNullIgnored(options.filters, {}, true);
    const canInput = (!selectedValue?.value && !isFilterEmpty) || (selectedValue?.value);
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const [isInputFocused, setIsInputFocused] = useState(false);
    useEffect(() => {
        if(!canInput && newFilterName != null) setNewFilterName(null);
    }, [canInput, newFilterName]);

    //Propagate value change to parent
    useMountedEffect(() => {
        if (props.onValueChanged) props.onValueChanged(selectedValue?.value);
    }, [selectedValue]);

    const showPopup = useFilterSelectPopup({
        typ: options.filtersType,
        templateFunctions: options.templateFunctions,
        setSelectedValue: setSelectedValue
    });

    const getAdornmentButton = (tooltip: string, icon: ReactElement, disabled: boolean, onClick?: MouseEventHandler) => {
        return <Tooltip title={<b className={classes.tooltip}>{tooltip}</b>}>
            <span>
                 <IconButton
                     disabled={disabled}
                     onClick={(e) => {
                         e.stopPropagation();
                         onClick?.(e)
                     }}
                     style={{padding: "5px"}}
                     color="inherit">
                    {icon}
                </IconButton>
            </span>
        </Tooltip>
    }

    const getSaveButton = () => {
        return getAdornmentButton(
            t(selectedValue?.value && newFilterName == null ? "Filter.UpdateTemplate" : "Filter.SaveTemplate"),
            <SaveIcon/>,
            !((!selectedValue?.value && !isFilterEmpty) || (selectedValue?.value && (options.isFilterChanged || newFilterName))),
            (e) => {
                if(newFilterName != null && newFilterName?.trim() !== ""){
                    fns.saveTemplate(newFilterName, isDefault, options.filtersType)
                        .then(() => {options.filterChanged?.(newFilterName)});
                } else {
                    showFilterHeaderDialog(TemplateDialogType.NAME_DIALOG, fns, () => {
                        options.filterChanged?.(null);
                    }, selectedValue?.value, options.filtersType, isDefault, selectedValue?.label);
                }
            }
        );
    }

    const getRemoveButton = () => {
        return getAdornmentButton(
            t("Filter.RemoveTemplate"),
            <DeleteOutlineIcon/>,
            !selectedValue?.value || newFilterName != null,
            (e) => {
                showFilterHeaderDialog(
                    TemplateDialogType.REMOVE_CONFIRMATION_DIALOG, fns,
                    () => options.filterChanged(null, true),
                    selectedValue?.value
                );
            }
        );
    }

    return <Grid container justifyContent={"space-between"} wrap={isDesktop ? "nowrap" : "wrap"} style={{gap: "5px"}}>
        <Grid item style={isDesktop ? {flexGrow: 1} : {width: "100%"}}>
            <TextField
                onClick={() => {if(!canInput) showPopup(selectedValue?.value).then()}}
                value={(newFilterName) ?? selectedValue?.label ?? ""}
                onChange={(e) => {
                    setNewFilterName(e.target.value === selectedValue.label ? null : e.target.value)
                }}
                onFocus={() => {setIsInputFocused(true)}}
                onBlur={() => {setIsInputFocused(false)}}
                size={"small"}
                style={{
                    width: "100%",
                    textDecoration: options.isFilterChanged && !isInputFocused && newFilterName == null
                        ? "line-through" : undefined
                }}
                variant="outlined"
                id="outlined-disabled"
                label={t("Filter.Templates")}
                InputLabelProps={canInput ?  undefined : {shrink: false}}
                autoComplete="off"
                InputProps={{
                    readOnly: !canInput,
                    ...(selectedValue?.value && { //If filter is selected
                        endAdornment: <>{getSaveButton()} {getRemoveButton()}</>
                    }),
                    ...(!selectedValue?.value && { //If NO filter is selected
                        endAdornment: getSaveButton()
                    })
                }}
            />
        </Grid>
        {selectedValue?.label ? <span style={{paddingRight: "10px"}}>
            <StandaloneField type={FormInputType.Checkbox}
                             onValueChanged={value => {
                                 setIsDefault(value);
                                 fns.saveTemplate(selectedValue?.label, value, options.filtersType, selectedValue?.value).then();
                             }}
                             value={isDefault}
                             variant={"outlined"}
                             title={<span style={{textTransform: "uppercase", fontWeight: 600, fontSize: "0.95em"}}>
                                 {t("Filter.Vychozi")}
                            </span>}
            />
        </span> : null}
    </Grid>;

}
