import * as React from "react";
import {useEffect, useState} from "react";
import {Firma} from "../../../model/Firma";
import {useTranslation} from "react-i18next";
import {httpEndpoint} from "../../../../common/utils/HttpUtils";
import {TextTuple, TextTupleType} from "../../../../common/component/form/TextTuple";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@material-ui/core";
import {getRejstrikLinks} from "../../../../common/utils/LinkUtils";
import {dispatchModal, ModalActionType} from "../../../../common/component/ModalContainer";
import {RejstrikLinksComponent} from "../../../raal_components/parts/RejstrikLinksComponent";
import {invoke} from "../../../../common/utils/Invoke";
import {FlagIcon} from '../../../../common/component/FlagIcon';
import {Show} from '../../../../common/component/form/Show';
import {Business, Fingerprint, Today} from '@material-ui/icons';
import {useStyleContext} from "../../../context/ThemeModeContext";

export function FirmaDetailView(props: {firmaId: string, master: boolean}) {
    const [data, setData] = useState({} as Firma);
    const reloadData = async (master: boolean) => {
        const result = await httpEndpoint<Firma>(Firma, props.master ? `master/firma/${props.firmaId}` : `admin/firma/${props.firmaId}`);
        if (result.response.status === 200) {
            setData(result.data);
            // setErrors([]);
        }
    };
    useEffect(() => {
        if (!data.id) invoke(reloadData);
    });
    return (
        <FirmaDetailViewContent data={data}/>
    );
}

interface FirmaDetailViewProps {
    data: Firma;
}

export function FirmaDetailViewContent(props:FirmaDetailViewProps) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();

    const linksIco = getRejstrikLinks(props.data.ico, undefined, props.data.r1Stat);
    const linksDic = getRejstrikLinks(undefined, props.data.dic, props.data.r1Stat);

    const rejstrikFunIco = linksIco.length===0 ? undefined : () => {
            dispatchModal({type:ModalActionType.Show, title:t("Buttons.Rejstriky"), body:(
                <RejstrikLinksComponent links={linksIco}/>
            )})
    };

    const rejstrikFunDic = linksDic.length===0 ? undefined : () => {
        dispatchModal({type:ModalActionType.Show, title:t("Buttons.Rejstriky"), body:(
                <RejstrikLinksComponent links={linksDic}/>
            )})
    };

    return (
        <Card style={{height: "100%"}} className={classes.sectionBackground}>
            <CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
                <Box marginBottom={2}>
                    <Typography variant="h6" >{t("Firma.UserLabel")}</Typography>
                </Box>
                <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={3}>
                        <Grid item lg={3} sm={6} xs={12}>
                            <ListItem>
                                <ListItemAvatar>
                                        {props.data.r1Stat?
                                        <Avatar className={"avatar-flag"}><FlagIcon mpz={props.data.r1Stat}/></Avatar>:
                                        <Avatar className={"avatar-company"}><Business/></Avatar>}
                                </ListItemAvatar>
                                <ListItemText>
                                    <TextTuple title={null} content={
                                        <>
                                        <div className="bold">{props.data.r1Nazev}</div>
                                        {props.data.r1Ulice} {props.data.r1CisloPopisne}
                                        <br />
                                        {props.data.r1Psc} {props.data.r1Obec} {`${props.data.r1CastObce ? `- ${props.data.r1CastObce}` : ""}`} {`${props.data.r1Stat ? `${props.data.r1Stat}` : ""}`}
                                        </>
                                        } />
                                </ListItemText>
                            </ListItem>
                        </Grid>
                        <Show>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Fingerprint/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Firma.ico")} content={props.data.ico} onClick={rejstrikFunIco}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Fingerprint/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Firma.dic")} content={props.data.dic} onClick={rejstrikFunDic}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Fingerprint/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Firma.dic1")} content={props.data.dic1}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Today/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Firma.RegDatum")} content={props.data.regDatum} type={TextTupleType.DateTime}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                        <Show>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-company"}><Today/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Firma.datSmlouvy")} content={props.data.datSmlouvy} type={TextTupleType.DateTime}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Show>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
