import {Preprava} from "../../../../model/Preprava";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useRef, useState} from "react";
import {useViewColumns} from "../_vp/VPColumns";
import {exist} from "../../../../../common/utils/Util";
import {NabidkaType} from "../../../../model/NabidkaType";
import {useEnumCiselnikSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {InvalDuvodPreprava} from "../../../../model/CommonTypes";
import {useCiselnikValues, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {useAppContext} from "../../../../context/AppContext";
import {exportableFieldsAdminView, getFilterVersion, useVPPdfLayout} from "../_vp/PrepravaAVozidlaShared";
import {Dial} from "../../../../raal_components/dial/Dial";
import {VPFilter, VPListPrepravaHistory} from "../../../../model/PrepravaVozidlo";
import {FilterForm} from "../_vp/VPFilter";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {ExtendedViewNew} from "../_vp/VPExtendedView";
import {PrepravaViewAdminPartNew} from "./PrepravaViewAdminPart";
import {
    CISELNIK_DRUH,
    DEFAULT_ADVANCE_VALUE,
    DEFAULT_RANGE_VALUE,
    PARAM_ADVANCE_KEY,
    PARAM_RANGE_KEY,
    prepareCols
} from "./PrepravyCiselnikView";
import {ComponentMode} from "../../../../routes";

type PrepravaViewHistoryPartProps = {
    preprava?: Preprava
    archive?: boolean
    admin?: boolean
    zadani?: boolean
    mode: ComponentMode
}

export function PrepravaHistoryViewPartNew({preprava, archive, admin, zadani, mode}: PrepravaViewHistoryPartProps) {
    const {t} = useTranslation();
    const refreshFun = useRef(() => {
    });
    let [createColumns] = useViewColumns(CISELNIK_DRUH, undefined, refreshFun, "user/preprava", ["datIns", exist(preprava) ? undefined : "parentId"].filter(i => exist(i)), PARAM_RANGE_KEY, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY, DEFAULT_ADVANCE_VALUE, true, {
        idColumnName: t('Preprava.id'),
        endpoint: '/admin/nabidky/prepravy',
        archive: archive,
        typ: NabidkaType.PREPRAVA,
        refactor: true
    }, true);
    const dateKey = "preprava-view-browse-date";
    const invalDuvSelect = useEnumCiselnikSelect({
        isClearable: true,
        ciselnikTyp: CiselnikTyp.P,
        enm: InvalDuvodPreprava,
        enmName: "Enumerations.InvalDuvodPreprava"
    });
    const yesNoSelect = useYesNoSelect({isClearable: true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const [cols] = useState(prepareCols(createColumns, true, archive, false, exist(preprava), t, yesNoSelect, locFun, invalDuvSelect));
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const accessUrl = admin ? 'admin' : 'user';
    const endpoint = archive ? `${accessUrl}/preprava-historie-archiv` : admin ? `${accessUrl}/prepravaview-history` : `${accessUrl}/preprava-history` ;
    const zadaniEndpoint = 'user/preprava-history';
    const {user} = useAppContext();
    const {pdfLayout} = useVPPdfLayout(user);

    return <Dial<VPListPrepravaHistory, VPFilter, Preprava>
        mode={mode}
        isTabbed
        lastBrowsedDateKey={dateKey}
        config={{
            lockSupport: {enabled: false},
            requiredColumns: ['odkud', 'kam', 'souprava', 'naves', 'jine', 'delka', 'vaha', 'druhy', 'druh', 'datum'],
            exportConfig: {
                exportable: true,
                endpoint: zadani ? zadaniEndpoint : endpoint,
                fileName: !archive ? "export_historie_prepravy" : "export_historie_archiv_prepravy",
                translationPrefix: ['Preprava', 'User'],
                formats: ["csv", "xls", "xml", "pdf"],
                exportableProps: exportableFieldsAdminView,
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod
                            }
                        }
                    }
                ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "druhy",
                        format: (data) => {
                            return druhyJoined(data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            overflowHidden: true,
            version: getFilterVersion(),
            hideDefaultDummyAction: true,
            tableTitle: t("Dials.Prepravy"),
            endpoint: zadani ? zadaniEndpoint : endpoint,
            clazz: VPListPrepravaHistory,
            filtering: true,
            hideAddNewActions: true,
            columns: cols,
            defaultQueryParameters: preprava && {parentId: preprava.id},
        }}
        hideSaveButton
        hideNewButtonOnEdit
        clazzDetail={Preprava}
        crudConfig={{editEnabled: false, removeEnabled: false, addEnabled: false}}
        layoutFilter={() => <FilterForm {...{
            lastBrowseDateDataKey: dateKey,
            typCiselniku: CISELNIK_DRUH,
            historyTab: true,
            showOznaceno: true,
            preprava: true,
            defaultRange: DEFAULT_RANGE_VALUE,
            typAdvance: PARAM_ADVANCE_KEY,
            defaultAdvance: DEFAULT_ADVANCE_VALUE,
            admin: true,
            history: true,
            singleView: exist(preprava)
        }}/>}
        layoutDetail={() => <TabHelperNew<Preprava> render={(data) =>
            <>
                <ExtendedViewNew ciselnikTyp={CISELNIK_DRUH} data={data} admin={admin} history/>
                {admin && <PrepravaViewAdminPartNew preprava={data} history/>}
            </>}/>
        }
        filterClazz={VPFilter}
        tabDetailUrl={`${admin ? `/${accessUrl}` : ''}${archive ? `/historie/prepravy/archiv` : `/historie/prepravy`}`}
    />;
}
