import {Grid} from "@mui/material";
import {Button, Divider, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {FormField, FormInputType, useForm} from "../../../../raal_components/form/Form";
import * as React from "react";
import {useRef} from "react";
import {WithUserBaseInfo} from "./WithUserBaseInfo";
import {useEnumeratedSelect} from "../../../../raal_components/SelectOptions";
import {DluhNadpripad, DluhNadpripadStav} from "../../../../model/Stiznosti";
import {LetterComponent, LetterComponentExposed, LetterTypeEnum} from "./LetterComponent";
import {FormPartBox} from "../../user/prohlizeni/FormPartBox";
import {ChangeEntityStateButton, useChangeStateAction} from "./state-change-action";
import {NadpripadActionEnum, useNadpripadActions} from "./nadpripad-actions";
import {exist} from "../../../../../common/utils/Util";
import {useLocation} from "react-router";
import {useCodeBookDetailContextNew} from "../../../../raal_components/controller/CodeBookDetail";
import {Provozovna} from "../../../../model/Provozovna";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import {DluhNadpripadPoznamkyDial} from "./DluhNadpripadPoznamkaDial";
import {ComponentMode} from "../../../../routes";

export function DluhNadpripadLayoutForm() {
    const {t} = useTranslation();
    const stavSelectProps = useEnumeratedSelect(DluhNadpripadStav, "Enumerations.DluhNadpripadStav", "number", {isClearable: false});
    const {data} = useCodeBookDetailContextNew<DluhNadpripad>();
    const modalRef = useRef<LetterComponentExposed>()
    const form = useForm<DluhNadpripad>();
    const {classes} = useStyleContext();
    const {navigateToPripad, NadpripadButton} = useNadpripadActions('admin/stiznost/dluh-nadpripad', data.id);
    const location = useLocation();
    // @ts-ignore
    const pripadIdNavigation = location.state?.pripadId;
    const changeNadPripadStateAction = useChangeStateAction(`admin/stiznost/dluh-nadpripad/${data.id}/action/`)
    const ChangeStateButton = (props: { action: string }) => <ChangeEntityStateButton {...props} changeStateAction={changeNadPripadStateAction} labelKeyPrefix="DluhNadpripad.StavAction."/>

    const provozovnyLink = (provozovny: Provozovna[]) => {
        const array = []
        array.push(...provozovny.map((p: Provozovna) => `<a style='color: ${p.stav.toString() === 'JEDE' ? 'green' : 'red'}' onclick="window.open(this.href,'_blank');return false;" href='/admin/provozovny/${p.id.toString()}'>${p.kod.toString()}</a>`));
        return array.join(', ');
    }

    return <Grid container spacing={1}>
        <LetterComponent modalRef={modalRef} entity={data} changeMainFocus={(state) => form.isChildFormFocused = state}/>
        <Grid item xs={12}>
            <FormPartBox title="">
                <Grid container justifyContent={"left"}>
                    <Grid item>
                        {exist(pripadIdNavigation) && <NadpripadButton
                            action={NadpripadActionEnum.NAVIGATETOPRIPAD}
                            labelKeyPrefix={"DluhNadpripad"}
                            changeStateAction={() => navigateToPripad(pripadIdNavigation)}/>
                        }
                        <Button variant={"contained"} color={"primary"} type={"button"} style={{ margin: '0.3rem' }} onClick={() => modalRef.current.showModal(LetterTypeEnum.DluznikNadpripad)}>{t("DluhNadpripad.GenerovatDopisDluznik")}</Button>
                        {data.stav === DluhNadpripadStav.UZAVRENY &&
                            <ChangeStateButton action="otevrit"/>
                        }
                        {data.stav === DluhNadpripadStav.OTEVRENY && <>
                            {/*<ChangeStateButton action="uzavritSUhradou"/>
                                <ChangeStateButton action="uzavritBezUhrady"/>*/}
                            <ChangeStateButton action="uzavritVypoved"/>
                            <ChangeStateButton action="uzavrit39"/>
                        </>}
                    </Grid>
                </Grid>
            </FormPartBox>
        </Grid>
        <Grid item xs={12} sm={12} lg={2}>
            <FormField name={"stav"} title={t("DluhNadpripad.Stav")} type={FormInputType.Select} selectProps={stavSelectProps} disabled required/>
        </Grid>
        <Grid item xs={12} sm={12} lg={3}>
            <FormField name={"kdyPozastavit"} title={t("DluhNadpripad.KdyPozastavit")} type={FormInputType.DateTime}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={3}>
            <FormField name={"upozorneni"} title={t("DluhNadpripad.Upozorneni")} type={FormInputType.DateTime}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={2}>
            <FormField name={"vypoved"} title={t("DluhNadpripad.Vypoved")} type={FormInputType.Checkbox} disabled/>
        </Grid>
        <Grid item xs={12} sm={12} lg={2}>
            <FormField name={"bod39"} title={t("DluhNadpripad.Bod39")} type={FormInputType.Checkbox} disabled/>
        </Grid>
        {data?.provozovnyDluznika ? <Grid item xs={12} sm={12} lg={12} marginTop={2}>
            <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 0}}/>
            <Grid item xs={12} lg={12}>
                <Typography className={classes.sectionLabel} color="textSecondary" display="block"
                            variant="caption">{t("DluhPripad.ProvozovnyFirmy")}</Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <div dangerouslySetInnerHTML={{__html: provozovnyLink(data.provozovnyDluznika)}}/>
            </Grid>
        </Grid>: null}
        {data?.id ? <Grid item  xs={12} sm={12} lg={12}>
            <DluhNadpripadPoznamkyDial id={data.id} mode={ComponentMode.GridMode}/>
        </Grid>: null}
        <WithUserBaseInfo/>
    </Grid>
}
