import {dispatchModal, ModalActionType} from "../../common/component/ModalContainer";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {CmsClanekOznameni, TypOznameniCms} from "../model/Cms";
import {useFetchCustom} from "../../common/utils/HttpUtils";
import moment, {Moment} from "moment";
import DataStorage from "../../common/DataStorage";
import {Button, Grid, Typography} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {Box, createStyles} from "@material-ui/core";
import {setLoading} from "../../common/component/LoadingContainer";
import RefreshIcon from "@material-ui/icons/Refresh";
import {useAppContext} from "../context/AppContext";


const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            maxWidth: '800px',
            paddingBottom: '30pt'
        },
        splitter: {
            margin: '20pt 0'
        },
        appUpdate: {
            textAlign: 'center'
        },
        reloadButton: {
            marginTop: '10pt!important',
        },
        reloadTitle: {
            fontSize: '12pt'
        }
    })
);

export function useCmsOznameni() : CmsOznameniType {
    const {t, i18n} = useTranslation();
    const cmsOznameniUrl = 'cms/oznameni';
    const classes = useStyles();
    const {user} = useAppContext();

    const {fetch: markAsReaded} = useFetchCustom<boolean, {ids: number[]}>(
        {endpoint: ({ids})=> `${cmsOznameniUrl}/precteno/${ids}`}
    );
    const {fetch: getUnreadOznameni} = useFetchCustom<CmsClanekOznameni[], {jazyk: string}>(
        {endpoint: ({jazyk})=> `${cmsOznameniUrl}/${jazyk}`}
    );
    const {fetch: getMissedOznameni} = useFetchCustom<CmsClanekOznameni[], {jazyk: string, posledniOznameni: Moment}>(
        {endpoint: ({jazyk, posledniOznameni})=>
                `${cmsOznameniUrl}/${jazyk}/${TypOznameniCms.OKAMZITE}/${posledniOznameni.toISOStringWithMillis()}`}
    );

    //Přijde ze stomp
    const processOznameni = (clanky: CmsClanekOznameni[]) => {
        if(clanky.length > 0) showPopup(clanky, t("Cms.Oznameni.TitleStomp"), true);
    }

    const setLastNotificationCheck = () =>
        DataStorage.set("lastNotificationCheck", moment().toISOStringWithMillis(), false, "session");

    const processReconnect = () => {
        if(user != null){
            getMissedOznameni({
                arg: {
                    jazyk: i18n.language,
                    posledniOznameni: moment(DataStorage.get("lastNotificationCheck", false, "session"))
                },
                init: {method: 'GET'},
            }).then(clanky => {
                if(clanky.length > 0) showPopup(clanky, t("Cms.Oznameni.TitleStomp"), true)
            })
        }
    }
    const processPageLoad = () => {
        setTimeout(() => {
            getUnreadOznameni({
                arg: {jazyk: i18n.language},
                init: {method: 'GET'},
            }).then(clanky => {
                setLastNotificationCheck();
                if(clanky?.length > 0) showPopup(clanky, t("Cms.Oznameni.TitleAfterLogin"))
            })
        }, 2000);
    }
    const onNotificationClose = async (data: CmsClanekOznameni[]) => {
        if(data.length > 0) {
            await markAsReaded({
                arg: {ids: data.map(a => a.oznameniId)},
                init: {method: 'PUT'},
            })
        }
    }

    const showPopup = (popupInfo: CmsClanekOznameni | CmsClanekOznameni[], modalTitle: string, stompNotification: boolean = false) => {
        const data = Array.isArray(popupInfo) ? popupInfo : [popupInfo];
        const isRelease = data.map(d => d.kategorie.toUpperCase()).includes("RELEASE")
        setLastNotificationCheck();

        dispatchModal({
            onClose: () => onNotificationClose(data),
            type:ModalActionType.Show,
            title:modalTitle,
            body:(
                <Box key={"oznameni"} className={classes.modal}>
                    {isRelease && stompNotification && <Box key={"reloadBox"} className={classes.appUpdate}>
                        <hr className={classes.splitter} style={{marginTop: 0}} />
                        <div>
                            <b className={classes.reloadTitle}>{t("Cms.Oznameni.ReloadInfo")}</b>
                            <div>{t("Cms.Oznameni.ReloadDisclaimer")}</div>
                        </div>
                        <Button
                            variant={"contained"} color={"primary"} type={"button"}
                            className={classes.reloadButton}
                            key={"reloadButton"}
                            onClick={() => {
                                dispatchModal({type:ModalActionType.Hide});
                                setLoading(true);
                                onNotificationClose(data).then(() => window.location.reload());
                            }}
                        >
                            <RefreshIcon style={{marginRight: 10}}/>
                            {t("Cms.Oznameni.ReloadPage")}
                        </Button>
                        <hr className={classes.splitter} />
                    </Box>}
                    {data.map((clanek, index) =>
                        <span key={index}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography variant="h6">{clanek.titulek}</Typography>
                                </Grid>
                                <Grid item>
                                    <div dangerouslySetInnerHTML={{__html:clanek.obsah}}/>
                                </Grid>
                            </Grid>
                            {(index === data.length -1) ? undefined : <hr className={classes.splitter} />}
                        </span>
                    )}
                </Box>
            )
        })
    }

    return {processOznameni, processReconnect, processPageLoad}
}

export type CmsOznameniType = {
    processOznameni: (oznameni: CmsClanekOznameni[]) => void
    processReconnect: () => void
    processPageLoad: () => void
}