import * as React from "react";
import {SystemParameter, SystemParamKey} from "../../../model/SystemParameter";
import {Dial} from "../../../raal_components/dial/Dial";
import {FormField, FormInputType} from "../../../raal_components/form/Form";
import {SelectProps} from "../../../../common/component/form/FormSelect";
import {useTranslation} from "react-i18next";
import {useEnumeratedSelect} from "../../../raal_components/SelectOptions";
import {Grid} from "@material-ui/core";
import {useCodeBookDetailContextNew} from "../../../raal_components/controller/CodeBookDetail";
import {RouteComponentProps} from "../../../routes";

function SystemParameterCiselnikForm({selectProps}: { selectProps: SelectProps }): React.ReactElement {
    const {edited} = useCodeBookDetailContextNew<SystemParameter>();
    const {t} = useTranslation();
    return (
        <Grid container spacing={2}>
            <Grid item lg={8} sm={8} xs={12}>
                <FormField name={"key"} title={t("Default.DataName")} type={"select"}  disabled={edited} required selectProps={{...selectProps, autoOpenDisabled: true}} />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <FormField name={"value"} title={t("SystemParameter.Value")} type={"text"} required />
            </Grid>
        </Grid>
    );
}

export function SystemParameterCiselnik(props: RouteComponentProps) {
    const selectProps = useEnumeratedSelect(SystemParamKey, "Enumerations.SystemParamKey", "string", {isClearable:true, autoOpenDisabled: true});
    const {t} = useTranslation();
    return <Dial<SystemParameter>
        mode={props.mode}
        config={{
            version:1,
            tableTitle : t("Dials.Params"),
            endpoint:"admin/systemParameter",
            getDetailIdUrlPart: (data) => `${data.key}`,
            clazz : SystemParameter,
            filtering:true,
            columns: [
                {   title:t("Default.Key"),
                    defaultSort: "asc",
                    field:"key",
                    render:data=>data.key
                },
                {   title:t("Default.DataName"),
                    defaultSort: "asc",
                    field:"dataName",
                    sorting: false,
                    render:data=>t(`Enumerations.SystemParamKey.${data.key}`),
                    filterProps:() => ({type:FormInputType.Select, selectProps:selectProps})
                },
                {   title:t("SystemParameter.Value"),
                    field:"value",
                    sorting: false,
                    filterProps:() => ({type:FormInputType.Text})
                },
            ]
        }}
        crudConfig={{editEnabled: true, removeEnabled:true, addEnabled:false}}
        hideNewButtonOnEdit
        modalHeaderName={()=>t("Default.ModalZakladni")}
        layoutForm={() => <SystemParameterCiselnikForm selectProps={selectProps} />}
    />;
}
