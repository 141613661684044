import * as React from "react";
import {Ref, useRef, useState} from "react";
import {FormFieldInterface, FormFieldInterfaceProps} from "./FormFieldInterface";
import {
    alpha,
    capitalize,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Tooltip
} from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SwitchBase from "@material-ui/core/internal/SwitchBase";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import {invoke} from "../../utils/Invoke";

export const useStyles =  makeStyles((theme) => createStyles({
    /* Styles applied to the root element. */
    root: {
        color: theme.palette.text.secondary,
    },
    /* Pseudo-class applied to the root element if `checked={true}`. */
    checked: {},
    /* Pseudo-class applied to the root element if `disabled={true}`. */
    disabled: {},
    /* Pseudo-class applied to the root element if `indeterminate={true}`. */
    indeterminate: {},
    /* Styles applied to the root element if `color="primary"`. */
    colorPrimary: {
        '&$checked': {
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
        '&$disabled': {
            color: theme.palette.action.disabled,
        },
    },
    /* Styles applied to the root element if `color="secondary"`. */
    colorSecondary: {
        '&$checked': {
            color: theme.palette.type === 'dark' ? '#fff' : '#000',
            '&:hover': {
                backgroundColor: theme.palette.type === 'dark' ? alpha('#fff', 0.2) : alpha('#a3a3a3', 0.2),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
        '&$disabled': {
            color: theme.palette.action.disabled,
        },
    },
    rippleChecked: {
        backgroundColor: theme.palette.type === 'dark' ? alpha('#fff', 0.3) : alpha('#a3a3a3', 0.3),
    },
    rippleNotChecked: {
        backgroundColor: theme.palette.type === 'dark' ? alpha('#fff', 0.3) : alpha('#a3a3a3', 0.3),
    },
    notFucused: {
        '&:hover': {
            backgroundColor: theme.palette.type === 'dark' ? alpha('#fff', 0.2) : alpha('#a3a3a3', 0.1),
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    }
}));
const defaultCheckedIcon = <CheckBoxIcon />;
const defaultIcon = <CheckBoxOutlineBlankIcon />;
export interface FormCheckboxProps extends FormFieldInterfaceProps<boolean> {
    size?:"small"|"medium"
    id?: string
}

export type CustomCheckboxProps = {
    onBlur?:()=>void
    onFocus?:()=>void
    onKeyUp?:(e: React.KeyboardEvent<HTMLInputElement>)=>void
    onKeyDown?:(e:  React.KeyboardEvent<HTMLInputElement>)=>void
    checked:boolean
    disabled:boolean
    inputRef:Ref<HTMLInputElement>
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>
    size?:"small"|"medium"
    id?: string
}

function CustomCheckbox({onFocus, onBlur, onKeyUp, onKeyDown, inputProps,inputRef, size="medium", ...props}: CustomCheckboxProps) {
    const ref = useRef<HTMLButtonElement>();
    const classes = useStyles();
    const color = 'secondary';
    const [focused, setFocused] = useState(false);

    return (
        <>
            <div className={focused ? (props.checked ? classes.rippleChecked : classes.rippleNotChecked) : classes.notFucused}
                 style={{
                     borderRadius: '50%'
                 }}>
            <SwitchBase
                size={size}
                type="checkbox"
                classes={{
                    // @ts-ignore
                    root: clsx(classes.root, classes[`color${capitalize(color)}`]),
                    checked: classes.checked,
                    disabled: classes.disabled,
                }}
                color={color}
                inputProps={{
                    ...inputProps,
                }}
                icon={React.cloneElement(defaultIcon, {
                    fontSize: size === 'small' ? 'small' : 'medium',
                })}
                checkedIcon={React.cloneElement(defaultCheckedIcon, {
                    fontSize: size === 'small' ? 'small' : 'medium',
                })}
                onFocus={() => {
                    setFocused(true);
                    onFocus&&onFocus();
                }}
                onBlur={() => {
                    setFocused(false);
                    onBlur&&onBlur();
                }}
                disableRipple={true}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                inputRef={inputRef}
                {...props}
                ref={ref}
            />
            </div>
        </>
    );
}
export class FormCheckbox extends React.Component<FormCheckboxProps> implements FormFieldInterface {
    dom:HTMLInputElement;
    state = {value:this.props.value};

    componentDidMount(): void {
        if(this.props.listeners)this.props.listeners.onLabelClick = this.onLabelClick;
        this.props.enableFocusSupport&&this.props.enableFocusSupport();
        if(this.props.focused) {
            this.dom.focus();
        }
    }

    onLabelClick = (e:React.MouseEvent) => {
        e.preventDefault();
        this.onValueChange(!this.state.value);
    };

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<FormCheckboxProps>, nextContext: any): void {
        const triggerFocus = nextProps.focused !== this.props.focused && nextProps.focused;
        this.setState({value:nextProps.value}, ()=>{
            if(triggerFocus) {
                this.dom.focus();
            }
        });
    }

    onValueChange(value:boolean) {
        this.setState({value:value}, () => {
            this.props.onValueChanged(this.state.value)
        });
    }


    render() {
        return (
            <FormControl required error={typeof this.props.error !== 'undefined'} component="fieldset" disabled={this.props.disabled}>
                <FormGroup>
                    <Tooltip title={this.props.dataTip||""} disableHoverListener={!this.props.dataTip} disableFocusListener={!this.props.dataTip} disableTouchListener={!this.props.dataTip}>
                        <FormControlLabel
                            value="top"
                            style={{margin:0}}
                            disabled={this.props.disabled}
                            control={<CustomCheckbox
                                onFocus={()=>invoke(this.props.onFocus, true)}
                                onBlur={()=>invoke(this.props.onBlur)}
                                onKeyDown={(e) => invoke(this.props.onKeyDown, e)}
                                onKeyUp={(e) => invoke(this.props.onKeyUp, e)}
                                size={this.props.size}
                                id={this.props.id}
                                disabled={this.props.disabled}
                                inputProps={{
                                    onChange:(ev)=>{
                                        this.onValueChange(ev.target.checked);
                                    }
                                }}
                                inputRef={(input) => { this.dom = input; }}
                                //eslint no-eval: 0
                                checked={this.state?.value && typeof this.state.value === 'string' ?
                                    this.state.value === 'true' : this.state.value||false}
                            />}
                            label={this.props.title}
                            labelPlacement="end"
                        />
                    </Tooltip>
                </FormGroup>
                <FormHelperText>{this.props.error}</FormHelperText>
            </FormControl>
        );
    }

}
