// @ts-nocheck
/* eslint-disable no-unused-vars */
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import * as React from 'react';
import AddIcon from '@material-ui/icons/PostAdd';
import {StandaloneFieldExposed} from "../../../common/component/form/StandaloneField";
import {FieldError} from "../../../common/component/form/ValidationError";
import {invoke} from "../../../common/utils/Invoke";
import {deepEqual, exist} from "../../../common/utils/Util";
import {Box, CircularProgress} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {LockStatus} from "../controller/DetailLock";

/* eslint-enable no-unused-vars */
export const byString = (o, s) => {
    if (!s) {
        return;
    }

    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var x = a[i];
        if (o && x in o) {
            o = o[x];
        } else {
            return;
        }
    }
    return o;
};

export const setByString = (obj, path, value) => {
    var schema = obj;  // a moving reference to internal objects within obj

    path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    path = path.replace(/^\./, '');           // strip a leading dot
    var pList = path.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i];
        if( !schema[elem] ) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pList[len-1]] = value;
};

export default class MTableEditRow extends React.Component<any, {data?:any, errors?:FieldError[], showLoading: boolean}> {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            data: this.createRowData(),
            showLoading: false
        };
    }
    fields:StandaloneFieldExposed[] = [];
    isActionKeysDisabled = false;
    originData = null;
    formRef = null;
    sending = false;
    extAdd = false;
    configuredTabIndexes = 0;
    startIndex = 0;
    hasErrors = false;
    currentFocusedField = 0;
    keyTimeStamp = null
    resizeObserver = null;

    setErrors(errors:FieldError[], preventMoveFocus?: boolean) {
        this.hasErrors = true;
        if (errors?.length > 0) {
            for (let i = 0; i < errors.length; i++) {
                errors[i].message = this.props.i18nFun.exists(errors[i].message) ? this.props.tFun(errors[i].message) : errors[i].message;
            }
        }
        this.setState({errors}, () => {
            if (errors?.length > 0) {
                let counter = this.startIndex;
                const supportedFields = this.fields.filter(i=>i.getData().supportTabFocus > 0);
                for (let i =0; i < supportedFields.length; i++) {
                    if (!supportedFields[i].getData().hasError) {
                        counter = counter + supportedFields[i].getData().supportTabFocus;
                    } else {
                        break;
                    }
                }
                invoke(this.props.onDataError,errors);
                if (!preventMoveFocus)
                    setTimeout(() => this.moveFocusToTabIndex(counter), 100)
            }
        })
    }

    createRowData() {
        this.originData = this.props.createNewObject();
        return this.props.createNewObject();
    }

    validateOnChange() {
        if (this.state.errors && this.state.errors?.length !== 0 && this.props.validateOnChange) {
            const errors = this.props.validateOnChange(this.state.data) ?? [];
            const prevErrors = errors.filter(e => this.state.errors.findIndex(se => se.name === e.name) !== -1);
            if (this.state.errors.length !== prevErrors.length) {
                setTimeout(() => this.setErrors(prevErrors, true), 0);
            }
        }
    }

    componentDidMount(): void {
        this.hasErrors = false
        this.startIndex = this.props.startIndex??1;
        this.props.editRows.current.push(this);
        if(this.props.mode === "update") {
            const finish = (data) => {
                this.originData = {...data}
                this.setState({data}, ()=>{
                    this.props.onUpdateBegin();
                    if(this.props.mode !== "add") {
                        this.props.onEditingStart&&this.props.onEditingStart();
                    }
                    window.addEventListener("keydown", this.globalKeyPressed);
                });
                this.props.setLoading(false);
                this.setState({showLoading: false});
                setTimeout(() => {
                    this.formRef?.current?.addEventListener('keydown', this.onKey);
                    this.formRef?.current?.addEventListener('click', this.handleClickEvent);
                    this.setTabIndexes();
                    if(this.props.autofocus) {
                        this.moveFocusToFirstField();
                    }
                }, 0)

            };
            if(this.props.getDetail) {
                this.setState({showLoading: true});
                const loadDetail = async() => {
                    this.props.setLoading(true);
                    if (exist(this.props.detailLocker)) {
                        this.props.detailLocker.lock(this.props.data.id,
                            {title: `${this.props.tFun("javax.validation.lock.couldNotLock")}`, severity: "error"}
                        ).then((result) => {
                            if(!result.value){
                                this.props.setLoading(false);
                                this.setState({showLoading: false});
                                this.onCancelClick();
                                return;
                            }
                        });
                    }
                    const data = await this.props.getDetail(this.props.data);
                    finish(data);
                };
                invoke(loadDetail);
            } else {
                finish(props.data);
            }
        }

        if(this.props.mode === "delete") {
            let scrollYPosition = window.scrollY;
            this.props.onBeginDelete();
            setTimeout(() => window.scrollTo(0, scrollYPosition), 0.5);
            window.addEventListener("keydown", this.globalKeyPressed);
        }

        if (this.props.mode === "add") {
            this.formRef?.current?.addEventListener('keydown', this.onKey);
            this.formRef?.current?.addEventListener('click', this.handleClickEvent);
            this.setTabIndexes();
            if(this.props.autofocus) {
                this.moveFocusToFirstField(10);
            }
        }
        this.resizeObserver = new ResizeObserver(entries => this.setStickyTop());
        const table = this.formRef?.current?.parentElement?.parentElement;
        if(table) this.resizeObserver.observe?.(table);
    }

    componentWillUnmount(): void {
        this.formRef?.current?.removeEventListener('keydown', this.onKey);
        this.formRef?.current?.removeEventListener('click', this.handleClickEvent);
        this.props.editRows.current = this.props.editRows.current.filter(f=>f!==this);
        if(this.props.mode === "update") {
            this.props.onUpdateEnd&&this.props.onUpdateEnd();
            window.removeEventListener("keydown", this.globalKeyPressed);
        }
        if (this.props.mode === "delete") {
            window.removeEventListener("keydown", this.globalKeyPressed);
        }
        this.props.onEditingEnd&&this.props.onEditingEnd();
        this.resizeObserver?.disconnect?.()
    }

    setTabIndexes = (colsChanged?: boolean) => {
        let count = this.props.indexOffset??0;

        if((!this.configuredTabIndexes || colsChanged) && this.formRef.current) {
            this.focusableElements().forEach((el, index) => {
                const tabIndex= index + (this.props.indexOffset??0) + 1
                el.setAttribute('tabindex', tabIndex.toString())
                count++;
            });
            this.configuredTabIndexes = count;
        }
    }

    globalKeyPressed = (e:KeyboardEvent) => {
        if(e.code === "Escape") {
            if (this.props.mode !== "delete"){
                if (!this.props.dataChanged(() => setTimeout(() => this.onCancelClick(), 100)))
                    this.onCancelClick();
            } else {
                this.onCancelClick()
            }
        }
    };

    renderColumns(readOnlyCells?: boolean) {
        return this.props.columns.filter(columnDef => !columnDef.hidden && !(columnDef.tableData.groupOrder > -1))
            .sort((a, b) => a.tableData.columnOrder - b.tableData.columnOrder)
            .map((columnDef, index) => {
                const value = (typeof this.state.data[columnDef.field] !== 'undefined' ? this.state.data[columnDef.field] : byString(this.state.data, columnDef.field));
                const getCellStyle = (columnDef, value) => {
                    let cellStyle = {
                        color: 'inherit'
                    };
                    if (typeof columnDef.cellStyle === 'function') {
                        cellStyle = {...cellStyle, ...columnDef.cellStyle(value, this.props.data)};
                    } else {
                        cellStyle = {...cellStyle, ...columnDef.cellStyle};
                    }
                    if (columnDef.disableClick) {
                        cellStyle.cursor = 'default';
                    }

                    return {...cellStyle};
                };
                const style = {};
                if (index === 0) {
                    style.paddingLeft = 24 + this.props.level * 20;
                }

                let allowEditing = false;

                if (columnDef.editable === undefined) {
                    allowEditing = true;
                }
                if (columnDef.editable === 'always') {
                    allowEditing = true;
                }
                if (columnDef.editable === 'onAdd' && this.props.mode === 'add') {
                    allowEditing = true;
                }
                if (columnDef.editable === 'onUpdate' && this.props.mode === 'update') {
                    allowEditing = true;
                }
                if (typeof columnDef.editable == 'function') {
                    allowEditing = columnDef.editable(columnDef, this.props.data);
                }
                if (!columnDef.field || !allowEditing || readOnlyCells) {
                    const readonlyValue = this.props.getFieldValue(readOnlyCells ? this.props.data : this.state.data, columnDef);
                    return (
                        <this.props.components.Cell
                            icons={this.props.icons}
                            columnDef={columnDef}
                            value={readonlyValue}
                            key={columnDef.tableData.id}
                            rowData={this.props.data}
                            style={getCellStyle(columnDef, value)}
                        />
                    );
                } else {
                    const {editComponent, ...cellProps} = columnDef;
                    const EditComponent = editComponent || this.props.components.EditField;

                    return (
                        <TableCell
                            key={columnDef.tableData.id}
                            align={['numeric'].indexOf(columnDef.type) !== -1 ? "right" : "left"}
                            style={{...getCellStyle(columnDef, value), verticalAlign:"top"}}
                        >
                            <EditComponent
                                fields={this.fields}
                                key={columnDef.tableData.id}
                                onEditingApproved={this.onEditingApproved.bind(this)}
                                setData={newData => {
                                    const data = newData;
                                    this.setState({data}, ()=>{
                                        if(this.props.mode === "add")
                                            this.props.onEditingStart&&this.props.onEditingStart();
                                    })
                                }}
                                columnDef={cellProps}
                                value={value}
                                locale={this.props.localization.dateTimePickerLocalization}
                                rowData={this.state.data}
                                onInteractStart={this.props.onEditingStart}
                                onChange={value => {
                                    const data = this.state.data;
                                    setByString(data, columnDef.field, value);
                                    this.setState({data}, ()=>{
                                        if(this.props.mode === "add")
                                            this.props.onEditingStart&&this.props.onEditingStart();
                                    });
                                }}
                                onError={(e)=>{
                                    if(e.length > 0) {
                                        console.log("error from onError")
                                        //this.focusField(o);
                                    }
                                }}
                                errors={this.state.errors}
                                index={index}
                                onFocus={() => {}}
                                onMount={()=>{

                                }}
                                onDisableActionKeys = {this.disableActionKeys.bind(this)}
                            />
                        </TableCell>
                    );
                }
            });
    }

    disableActionKeys = (isDisabled) => {
        this.isActionKeysDisabled = isDisabled
    }

    cleanData = (moveFocus: boolean = true) => {
        this.setState({
            data: this.props.data ? this.props.data : this.createRowData(),
            forceRowId: `${new Date()}`,
            errors: []
        }, () => {
            if (this.props.mode === "add") {
                this.props.onEditingEnd && this.props.onEditingEnd();
            }
            if (moveFocus) this.moveFocusToFirstField();
        });
    };

    onCancelClick = (lock?: boolean, moveFocus?: boolean, checkEqual?: boolean) => {
        const cont = () => {
            const isEqual = this.checkDeepEqual(this.originData, this.state.data, []);
            this.props.onEditingCanceled(this.props.mode, this.props.data);
            this.props.onEditingCanceled2 && this.props.onEditingCanceled2(this.props.mode, this.props.data);
            if (this.props.mode === "delete") this.props.onDeleteEnd();
            if (this.props.mode === "add") this.cleanData(moveFocus);
            this.props.onDataChanged(this.props.mode, !checkEqual ? true : isEqual);
        };

        if (exist(this.props.detailLocker) && this.props.detailLocker.getLockStatus() === LockStatus.LOCKED && exist(this.props.data)) {
            this.props.detailLocker.unlock(this.props.data.id,
                {title: `${this.props.tFun("Errors.CouldNotCancelEdit")}`, severity: "error"}
            ).then((result) => {
                if(result.value){
                    cont();
                }
            });
        } else {
            cont();
        }
    }

    prepareEditRow = () => {
        if (this.extAdd) {
            this.extAdd = false;
        }
        this.moveFocusToTabIndex(this.startIndex, 100);
    }

    checkDeepEqual = (origin: any, data: any, exclude: string[] = []) => {
        return deepEqual(this.originData, this.state.data, exclude, this.props.excludeFieldsForIntegerCheck??[])
    }

    clearFn() {
        const localization = { ...MTableEditRow.defaultProps.localization, ...this.props.localization };
        return {
                icon: this.props.icons.Clear,
                tooltip: localization.mode[this.props.mode].cancelTooltip,
                disableButton: this.props.mode === "add" && this.originData && this.checkDeepEqual(this.originData, this.state.data, []),
                onClick: () => {
                    this.props.dataChanged(
                        () => setTimeout(() => {
                            this.props.arState?.current?.enableAutorefresh(true);
                            this.onCancelClick()
                        }, 100),
                        () => {
                            this.props.setLastTabIndex&&this.props.setLastTabIndex(this.currentFocusedField);
                            this.moveFocusToTabIndex(this.currentFocusedField, 100)
                        })
            }
        }
    }

    renderActions() {
        const localization = { ...MTableEditRow.defaultProps.localization, ...this.props.localization };
        if (this.originData && ((!this.sending && !this.extAdd) || this.hasErrors)) {
            this.props.onDataChanged(this.props.mode, this.checkDeepEqual(this.originData, this.state.data, []))
        }
        const actions = [
            this.props.mode !== "delete" && {
                icon: this.props.mode === "add" ? AddIcon : this.props.icons.Edit,
                tooltip: this.props.mode === "add" ? localization.addTooltip :  localization.editTooltip,
                onClick: () => {
                    this.props.arState?.current?.disableAutorefresh(true);

                    const mode = this.props.mode;
                    this.props.onExtendedButtonClicked(this.state.data, this.props.data);
                    if (mode === "add") {
                        this.extAdd = true;
                        this.onCancelClick(true, false, true);
                    } else {
                        this.props.onEditingCanceled(mode, this.props.data);
                        this.props.onEditingCanceled2 && this.props.onEditingCanceled2(mode, this.props.data);
                        this.props.onDataChanged(this.props.mode, true);
                    }
                }
            },
            this.props.mode === "delete" &&  {
                icon: DeleteIcon,
                tooltip: '',
                disableButton: true
            },
            this.props.mode !== "delete" && this.clearFn(),
            {
                icon: this.props.icons.Check,
                tooltip: localization.mode[this.props.mode].saveTooltip,
                disableButton: this.props.mode !== "delete" && this.originData && this.checkDeepEqual(this.originData, this.state.data, []),
                onClick: () => {
                    this.props.arState?.current?.enableAutorefresh(true);
                    if(this.props.mode === "add") {
                        this.props.beforeSend&&this.props.beforeSend(this.state.data);
                        this.props.onEditingStart&&this.props.onEditingStart();
                    }
                    if(this.props.mode === "delete") {
                        this.props.onDeleteEnd(this.props.data.id);
                        this.props.onEditingApproved(this.props.mode, this.state.data, this.props.data);
                    }
                    if (this.props.mode !== "delete" && (!this.originData || !this.checkDeepEqual(this.originData, this.state.data))) {
                        this.sending = true;
                        this.props.beforeSend&&this.props.beforeSend(this.state.data);
                        this.props.onDataChanged(this.props.mode, true);
                        this.props.onEditingApproved(this.props.mode, this.state.data, this.props.data);
                    }
                }
            },
            this.props.mode === "delete" && this.clearFn()
        ].filter(i=> i);
        return (
            <TableCell padding="none" key="key-actions-column" style={{ width: 42 * actions.length, padding: '0 4px' }}>
                <div style={{ display: 'flex' }}>
                    <this.props.components.Actions data={this.props.data} actions={actions} components={this.props.components} size={"small"} />
                </div>
            </TableCell>
        );
    }

    onEditingApproved() {
        try {
            this.props.beforeSend&&this.props.beforeSend(this.state.data);
            this.props.onDataChanged(this.props.mode, true);
            this.props.onEditingApproved(this.props.mode, this.state.data, this.props.data);
        } finally {
            this.sending = false;
            if (!this.hasErrors) this.moveFocusToFirstField();
        }
    }

    getStyle() {
        const style = {
            // boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
           // borderBottom: '1px solid red',
            position: "sticky",
            backgroundColor: "inherit",
            zIndex: 10
        };

        const styleDisabled = {
            // boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
          //  borderBottom: '1px solid red',
            pointerEvents: 'none',
            position: "sticky",
            backgroundColor: "inherit",
            zIndex: 10
        };
        return this.props.disableRow ? styleDisabled : style;
    }

    moveNext = (tabIndex: number, timeStamp: number) => {
        let offset = 1

        const move = (el?: any, timeout?: number = 1) => {
            if (this.configuredTabIndexes >= tabIndex + offset && el?.disabled) {
                offset = 2
                for (let i = tabIndex + offset; i <= this.configuredTabIndexes; i++) {
                    el = this.findElementByTabIndex(i)
                    if (el?.disabled) offset++;
                }
            }
            if (this.configuredTabIndexes < tabIndex + offset) {
                if (!this.props.dataChanged()) {
                    this.moveFocusToFirstField();
                } else {
                    if (!this.keyTimeStamp || timeStamp - this.keyTimeStamp > 1000) {
                        this.keyTimeStamp = timeStamp
                        this.onEditingApproved();
                    }
                }
            } else {
                this.moveFocusToTabIndex(tabIndex + offset, timeout);
            }
        }

        let el = this.findElementByTabIndex(tabIndex + offset)
        setTimeout(() => move(el), 1);
    }

    moveBack = (tabIndex: number) => {
        let offset = 1
        const move = (el: any, timeout?: number = 1) => {
            if (el.disabled) {
                offset = 2
                for (let i = tabIndex - offset; i > 0; i--) {
                    el = this.findElementByTabIndex(i)
                    if (el?.disabled) offset--;
                }
            }
            if (tabIndex - offset >= this.startIndex) {
                this.moveFocusToTabIndex(tabIndex - offset, timeout);
            }
        }
        if (tabIndex > this.startIndex) {
            let el = this.findElementByTabIndex(tabIndex - offset)
            setTimeout(() => move(el), 1);
        }
    }

    onKey = (event:KeyboardEvent) => {
        const isEqual = this.checkDeepEqual(this.originData, this.state.data, []);
        if (this.props.tabDisabled){
            event.preventDefault();
            return;
        }

        const tabIndex = parseInt(event.target.getAttribute('tabindex'));
        this.props.setLastTabIndex&&this.props.setLastTabIndex(tabIndex);

        if(!this.isActionKeysDisabled && event.shiftKey && (event.key === 'Tab' || event.key === "Enter")){
            event.preventDefault();
            this.moveBack(tabIndex);
        }
        else if(!this.isActionKeysDisabled && (event.key === 'Tab' || event.key === "Enter")){
            event.preventDefault();
            this.moveNext(tabIndex, event.timeStamp);
        }
        else if (event.key === "Escape") {
            event.preventDefault();
            if (isEqual) {
                event.stopImmediatePropagation();
                if (this.props.mode === "add"){
                    this.moveFocusToFirstField();
                } else{
                    this.onCancelClick();
                }
                return;
            }
            this.props.dataChanged(
                () => setTimeout(() => this.onCancelClick(), 100),
                () => this.moveFocusToTabIndex(tabIndex));
        }
    }

    findTabIndexInDOM = (array: any[]) => {
        for (let i = 0; i < array.length; i++) {
            const index = parseInt(array[i].getAttribute('tabindex'));
            if (index > -1) {
                return index;
            }
        }
    }

    handleClickEvent = (event: MouseEvent) => {
        let tabIndex = parseInt(event.target.getAttribute('tabindex'));
        if (!tabIndex && event.target?.firstChild?.getAttribute)
            tabIndex = parseInt(event.target?.firstChild?.getAttribute('tabindex'));
        if (event.target && event.target.tagName === 'svg') {
            const array = [...event.target.parentElement?.parentElement?.parentElement?.parentElement?.children??[]];
            tabIndex = this.findTabIndexInDOM(array);
        }
        if (event.target && event.target.tagName === 'path') {
            const array = [...event.target?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.children??[]];
            tabIndex = this.findTabIndexInDOM(array);
        }
        if ((tabIndex && (tabIndex === -1 || isNaN(tabIndex))) || !tabIndex) {
            if (!this.hasErrors) this.moveFocusToTabIndex(this.currentFocusedField);
            return;
        }
        this.currentFocusedField = tabIndex;
        this.props.setLastTabIndex&&this.props.setLastTabIndex(tabIndex);
    }

    findElementByTabIndex = (tabIndex: number) => {
        return this.formRef.current?.querySelector(`[tabindex="${tabIndex}"]`);
    }

    moveFocusToTabIndex = (tabIndex: number, timeout = 1) => {
        const el = this.findElementByTabIndex(tabIndex);
        this.currentFocusedField = tabIndex;
        if (timeout) {
            setTimeout(() => el?.focus(), timeout);
        } else {
            el?.focus();
        }
    }

    moveFocusToCurrentField = () => {
        const el = this.findElementByTabIndex(this.currentFocusedField);
        setTimeout(() => el?.focus(), 100);
    }

	moveFocusToNextField = () => {
		this.currentFocusedField = this.currentFocusedField + 1;
		const el = this.findElementByTabIndex(this.currentFocusedField);
		setTimeout(() => el?.focus(), 100);
	}

    moveFocusToFirstField = (timeout?: number) => {
        this.moveFocusToTabIndex(this.startIndex, timeout);
    }

    focusableElements() {
        const data = this.formRef?.current?.querySelectorAll(
            'a, input, textarea, select, details, [tabindex]:not([tabindex="-1"]):not([type="hidden"]):not([type="button"])'
        )
        return this.formRef.current ? [...data] : [];
    }

    setStickyTop(){
        const row : HTMLElement = this.formRef?.current;
        const tableBody = row?.parentElement;
        const table = tableBody?.parentElement;
        if(row && table && tableBody) {
            row.style.top = (tableBody.getBoundingClientRect().top - table.getBoundingClientRect().top) + "px";
        }
    }
    
    render() {
        let columns;
        if (this.props.mode === "add" || this.props.mode === "update") {
            columns = this.renderColumns();
        }
        else if (this.props.mode === "delete") {
            columns = this.renderColumns(true);
        }

        if (this.props.options.selection) {
            columns.splice(0, 0, <TableCell padding="none" key="key-selection-cell" />);
        }
        if (this.props.isTreeData) {
            columns.splice(0, 0, <TableCell padding="none" key="key-tree-data-cell" />);
        }

        if (this.props.options.actionsColumnIndex === -1) {
            columns.push(this.renderActions());
        } else if (this.props.options.actionsColumnIndex >= 0) {
            let endPos = 0;
            if (this.props.options.selection) {
                endPos = 1;
            }
            if (this.props.isTreeData) {
                endPos = 1;
                if (this.props.options.selection) {
                    columns.splice(1, 1);
                }
            }
            columns.splice(this.props.options.actionsColumnIndex + endPos, 0, this.renderActions());
        }

        // Lastly we add detail panel icon
        if (this.props.detailPanel) {
            const aligment = this.props.options.detailPanelColumnAlignment;
            const index = aligment === "left" ? 0 : columns.length;
            columns.splice(index, 0, <TableCell padding="none" key="key-detail-panel-cell" />);
        }

        this.props.columns
            .filter(columnDef => columnDef.tableData.groupOrder > -1)
            .forEach(columnDef => {
                columns.splice(0, 0, <TableCell padding="none" key={"key-group-cell" + columnDef.tableData.id} />);
            });

        const {
            detailPanel,
            isTreeData,
            onRowClick,
            onRowSelected,
            onTreeExpandChanged,
            onToggleDetailPanel,
            onEditingApproved,
            onEditingCanceled,
            getFieldValue,
            components,
            icons,
            columns: columnsProp, // renamed to not conflict with definition above
            localization: localizationProp, // renamed to not conflict with definition above
            options,
            actions,
            onExtendedButtonClicked,
            createNewObject,
            onEditingStart, onEditingEnd, autofocus, onUpdateBegin, onUpdateEnd, editRows, setLoading, getDetail, onBeginDelete, onDeleteEnd, onDataChanged, dataChanged,
            tFun, detailLocker, onEditingCanceled2, excludeFieldsForIntegerCheck, indexOffset, lastTabIndex, setLastTabIndex, startIndex, i18nFun, tabDisabled, arState, beforeSend, disableRow,
            onDataError, validateOnChange, ...rowProps
        } = this.props;
        return (
            <>
                {this.state.showLoading === true ? <tr>
                        <td>
                            <Box>
                                <CircularProgress color="inherit" size={25} />
                            </Box>
                        </td>
                    </tr> :
                    <TableRow
                        ref={this.formRef}
                        id={this.state.forceRowId}
                        {...rowProps}
                        className={this.props.disableRow ? "ChildrenOpacity" : undefined}
                        style={this.getStyle()}
                    >
                        {columns}
                    </TableRow>}
            </>
        );
    }
}

MTableEditRow.defaultProps = {
    actions: [],
    index: 0,
    options: {},
    path: [],
    localization: {
        mode:{
            add:{
                saveTooltip: 'Save',
                cancelTooltip: 'Cancel',
            },
            update:{
                saveTooltip: 'Save',
                cancelTooltip: 'Cancel',
            },
            remove:{
                saveTooltip: 'Save',
                cancelTooltip: 'Cancel',
            }
        },
        deleteText: 'Are you sure you want to delete this row?',
        addTooltip: "addTooltip",
        editTooltip: "editTooltip",
        readTooltip: "readTooltip"
    }
};

MTableEditRow.propTypes = {
    actions: PropTypes.array,
    icons: PropTypes.any.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.object,
    detailPanel: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.func]))]),
    options: PropTypes.object.isRequired,
    onRowSelected: PropTypes.func,
    path: PropTypes.arrayOf(PropTypes.number),
    columns: PropTypes.array,
    onRowClick: PropTypes.func,
    onEditingApproved: PropTypes.func,
    onEditingCanceled: PropTypes.func,
    localization: PropTypes.object,
    getFieldValue: PropTypes.func,
    onExtendedButtonClicked: PropTypes.func,
    onDataChanged: PropTypes.func,
    dataChanged: PropTypes.func,
    createNewObject:PropTypes.func,
    tFun:PropTypes.func,
    i18nFun:PropTypes.object,
    excludeFieldsForIntegerCheck: PropTypes.array,
    validateOnChange: PropTypes.func
};
