import {CustomFieldComponentProps} from "./FormFieldInterface";
import {FormInputType} from "../../../web/raal_components/form/Form";
import {useRangeFocusable} from "./FormRange";
import React, {useEffect, useImperativeHandle, useState} from "react";
import {Box, Grid} from "@material-ui/core";
import {StandaloneField} from "./StandaloneField";
import {invoke} from "../../utils/Invoke";
import {ChildComponentProps, Vicinity, VicinityOptions} from "./FormVicinityContainer";
import {useMountedEffect} from "../hooks/SharedHooks";

export const FormVicinityAddress = (props:CustomFieldComponentProps<Vicinity, VicinityOptions>&ChildComponentProps) => {
    const textFieldProps = {style:{maxWidth:'100%'}};
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const [focused, onFieldFocused] = useRangeFocusable(props);
    const [value, setValue] = useState<Vicinity>(undefined);
    const defaultValue: Vicinity = {
        koordinat: null,
        osmPlace: null,
        radius: null,
        countryCode: null
    }

    useEffect(() => {
        if (props.getComponentState && props.getComponentState()) {
            if(typeof props.value?.mpz === 'string') props.value.mpz = [props.value?.mpz];
            if(typeof props.value?.psc === 'string') props.value.psc = [props.value?.psc];
            setValue(props.value);
        }
        // eslint-disable-next-line
    }, [props.value])

    const onChange = (v?: Vicinity) => {
        setValue(v);
        invoke(options.onChange, v);
    }

    useMountedEffect(() => {
        if (props.getComponentState && props.getComponentState()) props.onValueChanged(value);
    }, [value]);

    useImperativeHandle(props.componentRef, () => {
        return {
            selectFirstField: () => onFieldFocused(1),
            onChange
        }
    })
    const cols = !options?.filterForm ? 6 : 7;
    const colsPsc = !options?.filterForm ? 5 : 5;

    return (
        <>
            <Grid container spacing={!options?.filterForm ? 0 : 1}>
                <Grid item lg={cols} sm={cols} xs={cols}>
                    <Box style={{width:'100%'}}>
                        <StandaloneField
                            focused={focused===1}
                            onBlur={()=>{
                                invoke(props.onBlur)
                            }}
                            variant={props.variant ?? "outlined"}
                            onKeyDown={props.onKeyDown}
                            type={FormInputType.Text}
                            showAdornment={props.showAdornment}
                            inputClassName={props.inputClassName}
                            value={Array.isArray(value?.mpz) ? value?.mpz.join(",") : value?.mpz}
                            onValueChanged={v => {
                                const tempValue: Vicinity = {
                                    ...value,
                                    ...defaultValue,
                                    mpz: v?.toUpperCase().split(",")
                                }
                                onChange(tempValue);
                            }}
                            title={options?.showTitle&&options?.titleMpz}
                            placeholder={options?.titleMpz}
                            textFieldProps={{...textFieldProps, inputProps: {maxLength: 60}}}
                            onFocus={(o, userFocus) => {
                                if(userFocus) {
                                    onFieldFocused(1);
                                }
                            }}
                        />
                    </Box>
                </Grid>
                {!options?.filterForm ? <Grid item lg={1} sm={1} xs={1}></Grid> : undefined}
                <Grid item lg={colsPsc} sm={colsPsc} xs={colsPsc}>
                    <Box style={{width:'100%'}}>
                        <StandaloneField
                            focused={focused===2}
                            onBlur={()=>{
                                invoke(props.onBlur)
                            }}
                            variant={props.variant ?? "outlined"}
                            onKeyDown={props.onKeyDown}
                            type={FormInputType.Text}
                            showAdornment={props.showAdornment}
                            inputClassName={props.inputClassName}
                            value={Array.isArray(value?.psc) ? value?.psc.join(",") : value?.psc}
                            onValueChanged={v => {
                                const tempValue: Vicinity = {
                                    ...value,
                                    ...defaultValue,
                                    psc: v?.split(",")
                                }
                                onChange(tempValue);
                            }}
                            title={options?.showTitle&&options?.titlePsc}
                            placeholder={options?.titlePsc}
                            textFieldProps={{...textFieldProps, inputProps: {maxLength: 60}}}
                            onFocus={(o, userFocus) => {
                                if(userFocus) {
                                    onFieldFocused(2);
                                }
                            }}
                        />
                    </Box>
                </Grid>
                <div style={{height: options?.verticalSpace ?? 8}}/>
                <Grid item lg={12} sm={12} xs={12}>
                    <Box style={{width:'100%'}}>
                        <StandaloneField
                            focused={focused===3}
                            onBlur={()=>{
                                invoke(props.onBlur)
                            }}
                            variant={props.variant ?? "outlined"}
                            onKeyDown={props.onKeyDown}
                            type={FormInputType.Text}
                            showAdornment={props.showAdornment}
                            inputClassName={props.inputClassName}
                            value={value?.nazevMista}
                            onValueChanged={v => {
                                const tempValue: Vicinity = {
                                    ...value,
                                    ...defaultValue,
                                    nazevMista: v
                                }
                                onChange(tempValue);
                            }}
                            title={options?.showTitle&&options?.titleNazevMista}
                            placeholder={options?.titleNazevMista}
                            textFieldProps={textFieldProps}
                            onFocus={(o, userFocus) => {
                                if(userFocus) {
                                    onFieldFocused(3);
                                }
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
