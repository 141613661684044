export const AssetCache = {
  Image: {
      Logo: require('./assets/images/logo.png').default,
      Bg01: require('./assets/images/bg-title-01.jpg').default,
      Bg02: require('./assets/images/bg-title-02.jpg').default,
      Raal: require('./assets/images/raal.png').default,
      CZ: require('./assets/images/czech-republic.png').default,
      SK: require('./assets/images/slovakia.png').default,
      EN: require('./assets/images/united-kingdom.png').default,
      DE: require('./assets/images/germany.png').default,
      PL: require('./assets/images/poland.png').default,
      MarkerIcon: require('leaflet/dist/images/marker-icon-2x.png').default,
      LoginLogo: require('./assets/images/logo_RaalTrans_cmyk.jpg').default,
  }
};
