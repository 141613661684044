import MailIcon from '@material-ui/icons/Mail';
import DraftsIcon from '@material-ui/icons/Drafts';
import {useTranslation} from "react-i18next";
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {Bleskovka} from "../../model/Zprava";
import {useCountUnreadMessages, useFetchAllMessages, useMarkRead} from "../BleskovkyService";
import {useStompSubscribe} from "../../../common/utils/Websocket";
import {Badge, ListItemIcon, Menu, MenuItem} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import Typography from "@material-ui/core/Typography";
import {HeaderButtonProps, HeaderDropDown} from "./HeaderAction";
import {useHistory} from 'react-router';
import {truncateString} from "../../../common/utils/Util";
import {useDidMount} from "../../../common/component/hooks/SharedHooks";
import {useAppContext} from "../../context/AppContext";
import {showSnack} from "../../../common/component/SnackContainer";

export function Bold({children, bleskovka}:PropsWithChildren<{bleskovka:Bleskovka}>) {
    return <Typography variant="inherit" style={{fontWeight:bleskovka.precteno ? "inherit":"bolder"}}>{children}</Typography>
}
export function HeaderThunderNewsButton({showText}:HeaderButtonProps) {
    const {t} = useTranslation();
    let tooltip = t("Sections.Messages");
    const [count, setCount] = useState(0);
    const [messages, setMessages] = useState<Bleskovka[]>([]);
    const {call:fetchCount} = useCountUnreadMessages(setCount);
    const {call:fetchMessages} = useFetchAllMessages(setMessages);
    const {call:markAsRead} = useMarkRead();
    const {checkDataChanged} = useAppContext();
    const fetch = useCallback(()=>{
        fetchCount();
        fetchMessages();
    }, [fetchCount, fetchMessages]);
    useStompSubscribe("/bleskovka", {userOnly:true, callback:fetch});
    useStompSubscribe("/bleskovka-all", {callback:fetch});
    useDidMount(fetch);
    const {push} = useHistory();

    const sortBleskovky = (a:Bleskovka, b:Bleskovka) => {
        if(a.zprava.datum > b.zprava.datum) {
            return -1;
        }
        return 1;
    }

    useEffect(() => {
        if (messages?.length === 0) return;

        const unread = messages.slice().find(m => !m.precteno);
        if (unread) {
            showSnack({
                title: t("ThunderNews.Notification"),
                severity: "warning",
                preventAutoHide: true,
                closeText: t("ThunderNews.Ok"),
                action: [
                    {
                        title: t("ThunderNews.Open"),
                        onClick: () => push(`/zpravy/${unread.zprava.id}`),
                        style: {fontWeight: 700}
                    },
                    {
                        title: t("ThunderNews.Ok"),
                    }
                ]
            })
        }
        // eslint-disable-next-line
    }, [messages])

    return (
        <>
            <HeaderDropDown tooltip={tooltip} icon={(
                <Badge badgeContent={count} hidden={count > 0} color="secondary">
                    <EmailIcon />
                </Badge>
            )} render={(close)=>(
                messages ? [
                    ...messages.sort(sortBleskovky).map((message, i)=>(
                        <MenuItem key={i} onClick={()=> checkDataChanged(() => {
                            if(!Boolean(message.precteno)) {
                            markAsRead({config:{arg:message}});
                        }
                            push(`/zpravy/${message.zprava.id}`);
                            close();
                        })}>
                            <ListItemIcon>
                                {message.precteno?<DraftsIcon fontSize="small" />:<MailIcon fontSize="small" />}
                            </ListItemIcon>
                            <Bold bleskovka={message}>{truncateString(message.zprava?.predmet ?? '-', 20)}</Bold>
                        </MenuItem>
                    )),
                    messages.length === 0 && <MenuItem key={"NoMessages"}><Typography variant="inherit">{t("ThunderNews.NoMessages")}</Typography></MenuItem>,
                    <MenuItem key={"AllMessages"} onClick={()=>checkDataChanged(() => {push("/zpravy");close();})}>
                        <Typography variant="inherit">{t("ThunderNews.AllMessages")}</Typography>
                    </MenuItem>
                ].filter(f=>f) : []
            )} popupComponent={Menu}  showText={showText} text={t("ThunderNews.Notifications")}/>
        </>
    );
}
