import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        alignItems: "center",
        width: "100%"
    },
    border: {
        borderBottom: theme.palette.type === 'dark' ?  "2px solid rgba(255, 255, 255, 0.12)" : "2px solid rgba(0, 0, 0, 0.12)",
        width: "100%"
    },
    content: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    }
}));

const DividerWithText = ({ children } : { children: React.ReactNode }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.border} />
            <span className={classes.content}>{children}</span>
            <div className={classes.border} />
        </div>
    );
};
export default DividerWithText;