import {JsonProperty} from "../../common/utils/objectmapper/Mapper";

export class UserInfo {
    login?: string;
    jmeno?: string;
}

//returned upon locking/unlocking
export class LockChangeResult {
    value: boolean;
    @JsonProperty({type:{clazz:UserInfo}})
    userInfo?: UserInfo;
}

//delivered by websocket
export class LockDto {
    id?: number;
    locked?:boolean;
    @JsonProperty({type:{clazz:UserInfo}})
    uzivatel: UserInfo;
    tabId: string;
    unlockTabId: string;
}

//returned from query web api
export class LockableDto {
    id?: number;
    @JsonProperty({type:{clazz:UserInfo}})
    lockUserInfo: UserInfo;
    tabId: string;
}