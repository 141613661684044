import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {CircularProgress, IconButton} from "@material-ui/core";
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import {httpEndpointCustom} from "../utils/HttpUtils";
import {jsonToFormUrlEncoded} from "../utils/Util";
import {useDidMount} from "./hooks/SharedHooks";

export function EnableIndicator({url, method, checked, afterCommit}:{url: string, method: "PUT" | "POST", checked:boolean, afterCommit?:(value: boolean) => void}) {
    const [enabledState, setEnabledState] = useState(false);
    const [processing, setProcessing] = useState(false);
    const componentIsMounted = useRef(true);
    useEffect(() => () => {componentIsMounted.current = false}, []);
    useDidMount(() => {setEnabledState(checked)});

    async function sendEnable(newChecked: boolean) {
        try {
            setProcessing(true);
            await httpEndpointCustom(url, {method: method,  body: jsonToFormUrlEncoded({enabled: newChecked}), headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }});
            if(componentIsMounted)
                setEnabledState(newChecked);
        } finally {
            if(componentIsMounted)
                setProcessing(false);
        }

        if(afterCommit)
            afterCommit(enabledState);
    }

    return <IconButton size={"small"} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        const newValue = !enabledState;
        sendEnable(newValue);
    }}>
        {processing ? <CircularProgress size={24}/> : Boolean(enabledState) ? <CheckBox />  : <CheckBoxOutlineBlank/>}
    </IconButton>;
}