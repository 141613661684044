import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {Provozovna} from "./Provozovna";
import {LicenseType, Region} from "./CommonTypes";

export class LicenceRequest {
    @JsonProperty({type:{clazz:Provozovna}})
    provozovna: Provozovna;

    @JsonProperty({type:{enum: Region}})
    region: Region;

    @JsonProperty({type:{enum:LicenseType}})
    typLicence: LicenseType;

    users:number;
}