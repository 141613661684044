import {createTheme} from "@material-ui/core/styles";
import {PaletteType} from "@material-ui/core";

let theme = (pallete:PaletteType)=> createTheme({
    overrides: {
        MuiButton: {
            outlined: {
                /*color: 'white !important'*/
            },
            textPrimary: {
                color: pallete !== 'dark' ? "#ad0099" : "#e736ff",
            }
        },
        MuiCard: {
            root: {
                /*backgroundColor: pallete === 'dark' ? "#333333" : "#f5f5f5",*/
            }
        },
        MuiTab: {
            textColorPrimary: {
                "&$selected": {
                    color: pallete === 'dark' ? "#73b9ff" : "#d928f7"
                  }
            }
        },
        MuiTabs: {
            indicator: {
                backgroundColor: pallete === 'dark' ? "#73b9ff" : "#d928f7"
            }
        },
        MuiDivider: {
            root: {
                marginTop: 20,
                marginBottom: 20,
                width: "100%"
            }
        },
        MuiInputLabel: {
            root: {
              "&$focused": {
                color: pallete === 'dark' ? "#73b9ff" : "#1976d2"
              }
            }
        },
        MuiOutlinedInput: {
            root: {
                '& fieldset': {
                    borderColor: pallete === 'dark' ? "#666666" : "#999999",
                  },
                "&$focused $notchedOutline": {
                    borderColor: pallete === 'dark' ? "#73b9ff" : "#1976d2"
                },
                '&$disabled': {
                    color:pallete !== 'dark' ? "#000000" : "#aaaaaa",
                    backgroundColor:pallete !== 'dark' ? "rgba(0,0,0,0.06)" : "rgba(0,0,0,0.15)",
                },
                "&.filter-input, input.filter-input": {
                    backgroundColor: pallete === 'dark' ? "#bb19d226" : "#bb19d226",
                },
            }
        },
        MuiInput: {
            underline: {
              "&::after": {
                borderBottomColor: pallete === 'dark' ? "#73b9ff" : "#1976d2"
              }
            },
            formControl: {
               /* marginTop: "0 !important" */
            }
        },
        MuiLink: {
            button: {
                color:pallete !== 'dark' ? "#ad0099" : "#e736ff",
                textDecoration: 'underline'
            },
            root: {
                color:pallete !== 'dark' ? "#ad0099" : "#e736ff",
                textDecoration: 'underline',
                cursor: 'pointer',
                userSelect: "none"
            }
        },
        MuiTableCell: {
            root: {
                '& a': {
                    color:pallete !== 'dark' ? "#ad0099" : "#e736ff",
                    textDecoration: 'underline'
                }
            }
        },
        MuiTableBody:{
            root: {
                backgroundColor: pallete === 'dark' ? "#424242" : "#ffffff",
            }
        },
        MuiFab: {
            label: {
                '& span': {
                    color:pallete !== 'dark' ? "#ad0099" : "#e736ff",
                    textDecoration: 'underline'
                }
            },
			root: {
				minHeight: '10px',
			}
        },
        MuiTableRow: {
            footer: {
                /*backgroundColor: pallete === 'dark' ? "#333333" : "#F5F5F5",
                color: pallete === 'dark' ? "#FAFAFA" : "#212121"*/
            },
            hover: {
                '&:hover': {
                    backgroundColor: (pallete !== 'dark' ? "#00000030" : "#ffffff40") + "!important",
                }
            }
        },
        MuiDialogTitle: {
            root: {
                '& h6': {
                    paddingRight: '40px'
                }
            }
        },
        MuiInputBase: {
            root: {
                backgroundColor: pallete === 'dark' ? "#424242" : "#ffffff",
            }
        },
        MuiDialog: {
            paper: {
                backgroundColor: pallete === 'dark' ? "#424242" : "#ffffff",
            }
        },
        MuiTablePagination:{
            toolbar: {
                height: "35px",
                minHeight: "35px",
                overflow: "hidden",
            }
        },
        MuiToolbar:{
            dense: {
                minHeight: "40px",
                height: "40px",
            }
        },
        MuiCssBaseline: {
            "@global":{
                '*':{
                    scrollbarWidth: "auto",
                    scrollbarColor: pallete === 'dark' ? "#585859 #202022" : "#C1C1C1 #F1F1F1",

                }
            }
        }
    },
    palette: {
        primary: {
            main: pallete === 'dark' ? "#11467a" : "#1976d2",
        },
        secondary: {
            main: pallete === 'dark' ? "#882399" : "#d928f7",
        },
        type:pallete
    }
});


export default theme;
