import * as React from "react";
import {FormInputType} from "../../../../raal_components/form/Form";
import {Provozovna} from "../../../../model/Provozovna";

import {Dial} from "../../../../raal_components/dial/Dial";

import {useTranslation} from "react-i18next";
import {Firma} from "../../../../model/Firma";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {useProvozovnaTabs} from "../ProvozovnaCiselnik";
import * as Endpoint from "../../../../raal_components/Endpoints";
import {useCodeBookDetailContextNew} from "../../../../raal_components/controller/CodeBookDetail";
import {ComponentMode} from "../../../../routes";

interface ProvozovnaCiselnikUserProps {
    pozastavena?: boolean;
    adminView: boolean;
    mode: ComponentMode
}

export function ProvozovnaCiselnikZFirmy(props:ProvozovnaCiselnikUserProps) {
    const {t} = useTranslation();
    const tabs = useProvozovnaTabs();
    const {data:firma} = useCodeBookDetailContextNew<Firma>();

    const columns:Column<Provozovna>[] = [
        {
            title: t("Provozovna.Kod"),
            field: 'kod',
            defaultSort:"asc",
            filterProps:() => ({type:FormInputType.Text})
        },
        {
            title: t("Provozovna.PNazev"),
            field: 'pNazev',
            filterProps:() => ({type:FormInputType.Text})
        },
        {
            title: t("Provozovna.PUlice"),
            field: 'pUlice',
            filterProps:() => ({type:FormInputType.Text})
        },
        {
            title: t("Provozovna.PCisloPopisneKratke"),
            field: 'pCisloPopisne',
            filterProps:() => ({type:FormInputType.Text})
        },
        {
            title: t("Provozovna.PPsc"),
            field: 'pPsc',
            filterProps:() => ({type:FormInputType.Text})
        },
        {
            title: t("Provozovna.PObec"),
            field: 'pObec',
            filterProps:() => ({type:FormInputType.Text})
        },
        {
            title: t("Provozovna.PStat"),
            field: 'pStat',
            filterProps:() => ({type:FormInputType.Text})
        }
    ];
    const phoneColumn = {
        title: t("Default.PhoneNumbersKratke"),
        field: 'telCislaKontaktu',
        render(data: Provozovna) {
            return data.telCislaKontaktu.join(", ");
        }
    };
    if (props.pozastavena) columns.push(phoneColumn as any);

    return (
        <Dial<Provozovna>
            mode={props.mode}
            config={{
                id:"provozovny_z_firmy_user",
                clazz:Provozovna,
                tableTitle: t("Dials.Places"),
                endpoint: Endpoint.admin_provozovna,
                filtering:true,
                rowFiltering:false,
                defaultQueryParameters: {"firma": firma && firma.id},
                columns: columns
            }}
            crudConfig={{editEnabled: true, removeEnabled:false, addEnabled: false}}
            tabs={tabs}
            tabDetailUrl={'/admin/provozovny'}
            tabDetailData={{lockFirma: true, firma: firma?.id}}
        />
    );
}
