import {Dial} from "../../../../raal_components/dial/Dial";
import {WithFileBase} from "../../../../model/Stiznosti";
import {useTranslation} from "react-i18next";
import {FormInputType} from "../../../../raal_components/form/Form";
import {DluhSoubLayoutForm} from "./DlouSoubLayoutForm";
import {ComponentMode} from "../../../../routes";
import {useLocation} from "react-router";
import {useDidMount} from "../../../../../common/component/hooks/SharedHooks";
import {useHistoryCustom} from "../../../../raal_components/controller/NavigationHelper";

interface DluhSoubDialProps {
    url: string;
    mode?: ComponentMode
    master?: boolean
}


export function DluhSoubDial(props: DluhSoubDialProps) {
    const {t} = useTranslation();
    const {state} = useLocation<any>();
    const {push} = useHistoryCustom();
    const url = state?.tabDetailData?.url ?? props.url;
    console.log("props.master");
    console.log(props.master);
    useDidMount(() => {
        // ochrana před přístupem k datům mimo tab
        if (!url) push("/")
    })

    return <Dial<WithFileBase>
        mode={props.mode}
        config={{
            clazz: WithFileBase,
            tableTitle: t("DluhSoub.Title"),
            endpoint: url,
            filtering: false,
            columns: [
                {
                    title: t("DluhSoub.Soubor"),
                    field: 'soubor',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("DluhSoub.Popis"),
                    field: 'popis',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("WithUserBase.DatCas"),
                    field: 'datCas',
                    defaultSort: "desc",
                    render:(data)=> data.datCas?.format("L LT")
                }
            ]
        }}
        crudConfig={{addEnabled:false}}
        layoutForm={() => <DluhSoubLayoutForm url={state?.tabDetailData?.url}/>}
        tabDetailUrl={props.master ? '/master/soubory' : '/admin/soubory'}
        tabDetailData={{url: props.url}}
        />
}
