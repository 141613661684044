import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../context/AppContext";
import React, {useRef} from "react";
import {cenaFormat, inzerceAdminExportableFields, useInzercePdfLayout} from "../zadani/InzerceCiselnik";
import {cenaConstraint, getExportableProps} from "../_vp/PrepravaAVozidlaShared";
import {useInzeratInvalDuvSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import { formatPrice, ScrollableList, truncateString } from '../../../../../common/utils/Util';
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {
    InvalidDuvodInzerat,
    InzeratFilterOznaceno,
    InzeratView,
    InzeratViewAdmin,
    InzeratViewDetail
} from "../../../../model/Inzerat";
import {Dial} from "../../../../raal_components/dial/Dial";
import {globalStyles} from "../../../../context/ThemeModeContext";
import _ from "lodash";
import {FormInputType} from "../../../../raal_components/form/Form";
import {PhoneNumberDial} from "../../../../../common/component/PhoneNumberDial";
import {FormNumberRange} from "../../../../../common/component/form/FormNumberRange";
import {FormDateRange} from "../../../../../common/component/form/FormDateRange";
import {CheckMark} from "../../../../../common/component/CheckMark";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {InzeratViewDetailFormBasicPartNew} from "../_inzerce/InzerceCiselnikViewDetailFormBasicPart";
import {InzerceViewCiselnikFilter, InzerceViewCiselnikProps} from "../prohlizeni/InzerceViewCiselnik";
import InzerceHistoryViewPart from "../prohlizeni/InzerceHistoryViewPart";
import {ComponentMode} from "../../../../routes";
import {InzerceViewAdminPart} from "../prohlizeni/InzerceViewAdminPart";
import { httpEndpoint } from '../../../../../common/utils/HttpUtils';
import { ArchivPoznamka } from '../../../../model/ArchivPoznamka';
import moment from 'moment';
import {useClearZaznamOznaceni, useZaznamOznaceni, useZaznamOznaceniStyle, ZaznamOznaceniTyp} from "../Oznaceni";
import {InlineEmailClient} from "../../../../../common/component/EmailClient";

export const InzerceCiselnikArchiveView = (props: InzerceViewCiselnikProps) => {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const oznacenoFilter = useRef(false);
    const refreshFun = useRef(() => {});
    const rangeCenaSharedOptions = {numberProps: {format: cenaFormat, constraint: cenaConstraint}};
    const dateKey = "inzerat-view-browse-date";
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.I);
    const invalDuvSelect = useInzeratInvalDuvSelect({isClearable: true});
    const dtGrid = useRef<DataGridExposed<InzeratViewAdmin, InzeratFilterOznaceno>>();
    const {pdfLayout} = useInzercePdfLayout(user);
    const endpoint = props.admin ? "admin/inzerat-archiv" : 'user/inzerat-prohlizeni-archiv';
    const zaznamOznaceni = useZaznamOznaceniStyle(ZaznamOznaceniTyp.ARCHIV_PROHLIZENI_INZERCE);
    const clearZaznamOznaceni = useClearZaznamOznaceni(
        useZaznamOznaceni("user", ZaznamOznaceniTyp.ARCHIV_PROHLIZENI_INZERCE),
        () => {dtGrid.current?.table()?.refresh()}
    );

    return <>
        <Dial<InzeratViewAdmin, InzeratFilterOznaceno, InzeratViewDetail>
            mode={props.mode}
            lastBrowsedDateKey={dateKey}
            logActivity
            gridRef={dtGrid}
            onGridMount={()=>{
                refreshFun.current = () => dtGrid.current?.table()?.refresh();
                oznacenoFilter.current = dtGrid.current.table().getFilter().filters.oznaceno;
            }}

            onFilterChanged={(data) => {
                oznacenoFilter.current =data?.oznaceno ?? false;
            }}
            filterClazz={InzeratFilterOznaceno}
            clazzDetail={InzeratViewDetail}
            config={{
                lockSupport: {enabled: false},
                overflowHidden: true,
                requiredColumns: ['popis'],
                alternativeButtons: [clearZaznamOznaceni],
                exportConfig: {
                    exportable: true,
                    exportAll: props.admin,
                    exportDetailOnly: !props.admin,
                    endpoint: endpoint,
                    fileName: "export_archiv_inzerce",
                    translationPrefix: ['Inzerce', 'User', 'Preprava', 'Archive'],
                    formats: ["pdf", "xls", "csv", "xml"],
                    exportAllFormats: ["csv"],
					extendedDataEndpoint: (data) => {
						return httpEndpoint<ScrollableList<ArchivPoznamka>>(ScrollableList, `user/vozidlo-prohlizeni-archiv/${data.id}/poznamka?page=1&pageSize=15&overallCount=true`, {});
					},
					extendedDataMapper: (data) => {
						let headers : any = {};
						let values : any = {};
						data?.list?.map((poznamka: ArchivPoznamka, index: number) => {
							headers['poznamka' + index] = `${t('Poznamka.poznamka')} ${index}`;
							headers['jmeno' + index] = `${t('Poznamka.uzivatel')} ${index}`;
							headers['datIns' + index] = `${t('Poznamka.datIns')} ${index}`;

							values['poznamka' + index] = poznamka.poznamka;
							values['jmeno' + index] = poznamka.uzivatel?.jmeno;
							values['datIns' + index] = moment(poznamka.datIns).format('L LT');
						})
						return {headers, values};
					},
                    pdfLayout: (data, fields, pageBreak, index, origin, extendedData) => pdfLayout(data, fields, pageBreak, index, origin, extendedData),
                    exportableProps: getExportableProps(props.admin ? inzerceAdminExportableFields : [
                        {
                            type: ["csv", "xls", "pdf"],
                            fields: ["popis", "rychlyKontakt", "cena", "currency", "provozovna", "dispecer", "uzivatel", "modifiedOn"]
                        }
                    ], true),
                    excludedProps: [
                        {
                            type: ["csv", "xls"],
                            fields: ["id", "sekce"]
                        }
                    ],
                    extendedProps: [
                        {
                            type: ["csv", "xls"],
                            addExtendedProps: (data) => {
                                return {
                                    provozovna: data.uzivatel?.provozovna?.kod,
                                    ...{archived: data.deletedOn}
                                }
                            }
                        }
                    ],
                    formattedProps: [
                        {
                            type: ["csv", "xls", "pdf"],
                            field: "invalDuv",
                            format: (data) => {
                                return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data)
                            }
                        },
                        {
                            type: ["csv", "xls", "pdf"],
                            field: "rychlyKontakt",
                            format: (data) => {
                                return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                            }
                        }
                    ]
                },
                options: (props.admin) ? {
                    rowStyle: (data: any) => {
                        if (!data.valid)
                            return globalStyles.rowStyleAlert;
                        if(data.invalDuv === InvalidDuvodInzerat.DUPLICITA && props.admin){
                            return globalStyles.rowStyleDuplicated;
                        }
                        return undefined;
                    }
                } : {
                    rowStyle: (data: any) => {
                        if (data.klasifikace?.barva) {
                            return {backgroundColor: data.klasifikace?.barva}
                        }
                        const oznaceniStyle = zaznamOznaceni.getStyle(data);
                        if(oznaceniStyle) return oznaceniStyle;
                    }
                },
                clazz: InzeratViewAdmin,
                hideDefaultDummyAction: false,
                tableTitle: t("Dials.Advertising"),
                endpoint: endpoint,
                filtering: true,
                hideAddNewActions: true,
                defaultQueryParameters: _.merge(props.history ? {parentId: props.history.id}: {}, props.filter),
                columns: [
                    /* na základě #4073 {
                        title: t("Inzerce.Id"),
                        field: 'nabidkaId',
                        filterProps: () => ({type: FormInputType.Number, numberProps: {format: '0.[00]'}}),
                        render: (data: InzeratViewAdmin) => <Link to={{
                            pathname: `/prohlizet/inzerce/${data.nabidkaId}`,
                            state: {
                                forcePreviousPage: pathname
                            }
                        }
                        } onClick={(e) => {e.stopPropagation();}}>{data.nabidkaId}</Link>
                    },*/
                    {
                        title: t("Inzerce.AdText"),
                        field: 'popis',
                        filterProps:() => ({type:FormInputType.Text}),
                        cellStyle: {minWidth: 200},
                        headerStyle: {minWidth: 200}
                    },
                    {
                        title: t("Default.Telefon"),
                        field: 'rychlyKontakt',
                        render:(row:InzeratView)=><PhoneNumberDial hideIcon phoneNumber={row.rychlyKontakt} tableVariant={true}/>,
                        filterProps: () => ({type: FormInputType.Text})
                    },
                    {
                        title:t('Default.Email'),
                        field:"email",
                        filterProps: () => ({type: FormInputType.Text}),
                        sorting: false,
                        render:(data:InzeratView)=> <InlineEmailClient hideIcon emailAddress={data.email} rowData={data}/>,
                    },
                    {
                        title: t("Inzerce.Price"),
                        field: 'cena',
                        render:(data:InzeratView) => {
							const price = data.cena && data.currency?.currencyCode ? formatPrice(data.cena, data.currency.currencyCode) : data.cena ?? '';
							return data.jednotka ? price + "/" + data.jednotka : price;
						},
                        filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"cenaRange", customComponentOptions:rangeCenaSharedOptions}),
                        cellStyle: {textAlign:"right"}
                    },
                    {
                        title: t("Provozovna.Title"),
                        field: 'provozovna',
                        render:(data:InzeratView) => data.provozovna && data.firma ? truncateString(`${data.provozovna} (${data.firma})`, 20) : '',
                        filterProps: () => ({type: FormInputType.Text})
                    },
                    {
                        title: t("Inzerce.Dispecer"),
                        field: 'dispecer',
                        render:(row:InzeratView)=> `${row.dispecer?.jmeno ?? ''}`,
                        filterProps:() => ({type: FormInputType.Text, name: "dispecerText"}),
                        cellStyle: {width: 200, maxWidth: 200},
                        headerStyle: {width: 200, maxWidth: 200}
                    },
                    (props.admin) ?
                        {
                            title: t("PVI.Inserted"),
                            field: 'datIns',
                            render: (data:InzeratView) => data.datIns?.format("L LT")
                        } : undefined,
                    {
                        title: t("PVI.Modified"),
                        field: 'modifiedOn',
                        render: (data: any) => data.modifiedOn?.format("L LT"),
                        filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, customComponentOptions:{timeFormat: false},  name: "modifiedOnRange"}),
                    },
                    ...(props.admin ? [
                        {
                            title: t("PVI.Valid"),
                            field: 'valid',
                            render: (data: InzeratViewAdmin) => <CheckMark checked={data.valid}/>,
                            filterProps: () => ({type: FormInputType.Select, selectProps: yesNoSelect})
                        },
                        {
                            title: t("PVI.InvalDuv"),
                            field: 'invalDuv',
                            render: (data: InzeratViewAdmin) =>  {return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data.invalDuv)},
                            filterProps: () => ({type: FormInputType.Select, selectProps: invalDuvSelect}),
                            cellStyle: {minWidth: 180},
                            headerStyle: {minWidth: 180}
                        },
                        {
                            title: t("User.Title"),
                            field: 'uzivatelText',
                            render:(data:InzeratViewAdmin)=> data.uzivatel?.toString() ?? "",
                            filterProps: () => ({type: FormInputType.Text, name: "uzivatelText"}),
                            cellStyle: {minWidth: 180},
                            headerStyle: {minWidth: 180}
                        }
                    ] : []),
                    {
                        title: t("Archive.Archived"),
                        field: 'datIns',
                        defaultSort: "desc",
                        render:(data:InzeratViewAdmin) =>  (data.datIns)?.format("L LT") ?? "",
                        filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, customComponentOptions:{timeFormat: false},  name: 'datInsRange'}),
                    }
                ].filter(i => Boolean(i)) as Column<InzeratView>[]
            }}
            layoutDetail={()=><TabHelperNew<InzeratViewDetail> render={(data) =>
                <>
                    <InzeratViewDetailFormBasicPartNew data={data} admin={props.admin} archive viewArchive history={Boolean(props.history)}/>
                    {props.admin ? <InzerceViewAdminPart inzerat={data} archive/> : null}
                </>}/>}
            crudConfig={{addEnabled:false, editEnabled:false, removeEnabled: false}}
            layoutFilter={() =><InzerceViewCiselnikFilter {...{lastBrowseDateDataKey: dateKey, admin: props.admin, archive: true}}/>}
            hideSaveButton
            hideNewButtonOnEdit
            tabs={[
                ...(props.admin ? [
                    {
                        title: t("Inzerce.History"),
                        render: ()=><TabHelperNew<InzeratViewDetail> render={(data) => <InzerceHistoryViewPart inzerat={data} admin={props.admin} archive mode={ComponentMode.GridMode}/>}/>
                    }
                ].filter(i=>i) : [])
            ]}

        />
    </>
}
