import React from "react";
import {useHashParams} from "../common/utils/Util";
import {useHistory} from "react-router";
import {useDidMount} from "../common/component/hooks/SharedHooks";
import {Button, Card, CardActionArea, CardActions, CardContent, Grid, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const ErrorCode = ({code}:{code?: string}) => {
    const {push} = useHistory();
    const {t} = useTranslation();
    useDidMount(() => {
        setTimeout(() => push('/dashboard'), 5000);
    })
    return (
        <Grid container spacing={2} alignItems="stretch" alignContent={"space-between"} justifyContent={"center"} direction="row">
            <Grid item lg={6} sm={6} xs={6} style={{marginTop: 20}}>
                <Card>
                    <CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h1">
                                {t('ErrorComponent.title')} {code}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {code === '403' ? t('ErrorComponent.error403') : null}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {t('ErrorComponent.redirectionMessage')}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" color="primary" onClick={() => push('/dashboard')}>
                            {t('ErrorComponent.homeButton')}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}

export const ErrorComponent = () => {
    const hashParams = useHashParams();
    const {push} = useHistory();

    useDidMount(() => {
        if (!hashParams.get('code')) push('/dashboard')
    })

    const ErrorType = () => {
        const code = hashParams.get('code');
        return <ErrorCode code={code} />
    }

    return (
        <ErrorType />
    )
}
