import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {useAllUsersSelect, useFirmaSelect, useProvozovnaSelect} from "../../../raal_components/SelectOptions";

import {Dial} from "../../../raal_components/dial/Dial";
import {TypZpravy, Zprava, ZpravaDto} from "../../../model/Zprava";
import {FormField} from "../../../raal_components/form/Form";
import {useStyleContext} from '../../../context/ThemeModeContext';
import moment from "moment/moment";
import {useCodeBookDetailContextNew} from '../../../raal_components/controller/CodeBookDetail';
import {RouteComponentProps} from "../../../routes";


const ThunderNewsForm = () => {
    const {classes} = useStyleContext();
    const userProps = useAllUsersSelect({isMulti:true, autoOpenDisabled: true});
    const firmaProps = useFirmaSelect({isMulti:true});
    const provProps = useProvozovnaSelect({isMulti:true});
    const {edited} = useCodeBookDetailContextNew();
    const {t} = useTranslation();
    const startDate = moment().add(1, 'days').startOf('days');
    return <Grid container spacing={1}>
        {!edited&&<>
            <Grid item xs={12} sm={12} lg={12}>
                <FormField type="select" name={"uzivatele"} title={t("User.Title")} selectProps={userProps}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
            <FormField type="select" name={"firmy"}  title={t("Firma.Title")} selectProps={firmaProps} />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
            <FormField type="select" name={"provozovny"}  title={t("Provozovna.Title")} selectProps={provProps} />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}  style={{marginTop: 10, marginBottom: 0}}>
            <Typography className={classes.sectionLabelUser} color="textSecondary" display="block" variant="caption">{t("ThunderNews.MessagesInstantUsers")}</Typography>
            </Grid>
        </>}
        <Grid item xs={12} sm={12} lg={12}>
            <FormField type="datetime" name={"platiDo"} title={t("ThunderNews.MessagesValidTo")} required defaultValue={!edited && startDate}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
            <FormField type="text" name={"predmet"} title={t("ThunderNews.Predmet")} required textFieldProps={{rows:20}}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
            <FormField type="editor" name={"clanek"} title={t("Default.Text")} required textFieldProps={{rows:20}}/>
        </Grid>
    </Grid>
}


export function ThunderNewsAdmin(props: RouteComponentProps) {
    const {t} = useTranslation();

    return (
        <>
            <Dial<Zprava>
                mode={props.mode}
                excludeFieldsForDirtyCheck={["platiDo"]}
                config={{
                    tableTitle : t("Dials.ThunderMessages"),
                    columns:[{
                            field:"datum",
                            title:t("Default.Datum"),
                            defaultSort:"desc",
                            render:data=>data.datum?.format("L LT")
                        },
                        {
                            field:"platiDo",
                            title:t("ThunderNews.MessagesValidTo"),
                            render:data=>data.platiDo?.format("L LT")
                        },
                        {
                            field:"predmet",
                            title:t("ThunderNews.Predmet")
                        }
                    ],
                    clazz:Zprava,
                    endpoint:"admin/bleskova-zprava",
                    filtering:true
                }}
                isDetailReadOnly
                createInstance={() => {
                    const z = new ZpravaDto();
                    z.typZpravy = TypZpravy.B;
                    return z
                }}
                formDisabled={edited => edited}
                crudConfig={{addEnabled:false, editEnabled: true, removeEnabled:false}}
                layoutForm={()=><ThunderNewsForm />}
            />
        </>
    );
}
