import { useAppContext } from '../../../context/AppContext';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Link, Paper, Typography } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../../../common/component/Loading';
import { Form, FormButton, FormComponent, FormField } from '../../../raal_components/form/Form';
import { downloadFileSimple, downloadUrl } from '../../../../common/utils/DownloadFile';
import { showSnack } from '../../../../common/component/SnackContainer';
import { useErrorTranslator } from '../../../../common/utils/error-utils';
import { httpEndpointCustom, useFetchCustom } from '../../../../common/utils/HttpUtils';
import { jsonToFormUrlEncoded } from '../../../../common/utils/Util';
import { TextTuple } from '../../../../common/component/form/TextTuple';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MuiModal } from '../../../../common/component/MuiModal';
import { AssetCache } from '../../../../AssetCache';
import { HeaderLanguageButton } from '../../../raal_components/Header/LanguageButton';

type ConsentType = {
	termsAndConditions: boolean,
	licenceConditions: boolean,
	gdpr: boolean,
	pdi: boolean,
}

export function Consent() {
	const { user, logout } = useAppContext();
	const { t } = useTranslation();
	const [formData, setFormData] = useState<any>({});
	const errorTranslator = useErrorTranslator();
	const [submitDisabled, setSubmitDisabled] = React.useState(true);
	const [urlVP, setUrlVP] = useState<string | null>(null);
	const [urlLP, setUrlLP] = useState<string | null>(null);
	const [urlGDPR, setUrlGDPR] = useState<string | null>(null);
	const [urlPDI, setUrlPDI] = useState<string | null>(null);
	const [panel1, setPanel1] = React.useState(true);
	const [panel2, setPanel2] = React.useState(true);
	const [panel3, setPanel3] = React.useState(false);
	const [consentType, setConsentType] = useState<ConsentType | null>({ termsAndConditions: false, licenceConditions: false, gdpr: false, pdi: false });
	const [loading, setLoading] = useState(true);
	const [modalOpen, setModalOpen] = React.useState(false);
	const { fetch } = useFetchCustom<any>({ endpoint: `master/pravni-dokumentace/state/podepsana` }, null);
	const [locale, setLocale] = useState<string>();

	useEffect(() => {
		if (locale) {
			downloadUrl('master/pravni-dokumentace/VP', 'application/pdf', setUrlVP);
			downloadUrl('master/pravni-dokumentace/pravni-dokumentace-filled/LP', 'application/pdf', setUrlLP);
			downloadUrl('master/pravni-dokumentace/GDPR', 'application/pdf', setUrlGDPR);
			downloadUrl('master/pravni-dokumentace/pravni-dokumentace-filled/PDI', 'application/pdf', setUrlPDI);
			loadConsentType();
		}
	}, [locale]);

	const langChangeCallBack = (locale:string) => {
		setLocale(locale);
	};

	const loadConsentType = async () => {
		setLoading(true);
		try {
			const data = await fetch({});
			setConsentType(data);
			setFormData({ ...data });
			setLoading(false);
		} catch {
			setLoading(false);
		}
	};

	const save = async (form: ConsentType): Promise<any> => {
		const result = await httpEndpointCustom(`master/pravni-dokumentace/podepsana`,
			{
				method: 'PUT',
				body: jsonToFormUrlEncoded({
					...form,
				}),
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
				},
			});

		if (result?.response.status > 201) {
			showSnack({ title: 'Error', severity: 'error' });
		} else {
			window.location.href = '/';
		}
		return null;
	};

	return !user && !loading ?
		<Paper>
			<div style={{ position: 'relative', height: '60vh' }}><Loading show={!user} title={t('Default.Loading')}/>
			</div>
		</Paper>
		:
		<Grid container component="main" justifyContent="center">
			<Box m={2}>
				<Paper variant="elevation">
					<Box display="flex">
						<Box flexGrow={1} marginLeft="2rem" paddingTop="2rem">
							<img src={AssetCache.Image.LoginLogo} alt="Raaltrans logo" width="12%"/>
						</Box>
						<Box marginTop="2rem" marginRight="2rem">
							<HeaderLanguageButton showText langChangeCallBack={langChangeCallBack}/>
							<Button variant={'contained'} color={'primary'} onClick={() => logout(true)}>{t('Buttons.Logout')}</Button>
						</Box>
					</Box>
					<Box p={4}>
						<Form data={formData}
							  localization={t('FormLocalization', { returnObjects: true })}
							  onChange={(form) => setSubmitDisabled(!(form.data.termsAndConditions && form.data.licenceConditions && form.data.gdpr && form.data.pdi))}
							  onSend={async (form: FormComponent<ConsentType>) => {
								  const r = await save(form.data);
								  return r;
							  }}>

							<Grid container component="main" justifyContent="center">
								<Box width={1}>
									<Accordion expanded={panel1} onChange={() => setPanel1(!panel1)}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon/>}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<Typography variant="h6">{t('Consent.BasicInfo')}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Box ml={1}>
												<Grid container>
													<Grid item>
														<TextTuple title={t('Provozovna.Nazev')} content={user.provozovna.pNazev}/>
													</Grid>
													<Grid item>
														<Box pl={2}>
															<TextTuple title={t('Provozovna.KodProvozovny')} content={user.provozovna.kod}/>
														</Box>
													</Grid>
												</Grid>
												<TextTuple title={t('Provozovna.UserContactLabel')} content={`
													${user.provozovna.pUlice ? `${user.provozovna.pUlice}` : ''}
													${user.provozovna.pCisloPopisne ? ` ${user.provozovna.pCisloPopisne}` : ''}${user.provozovna.pUlice ? `, ` : ''}
													${user.provozovna.pCastObce ? `${user.provozovna.pCastObce}, ` : ''} 
													${user.provozovna.pObec}, ${user.provozovna.pPsc}, ${user.provozovna.pStat}`}/>
												<Grid container>
													<Grid item>
														<TextTuple title={t('User.login')} content={user.login}/>
													</Grid>
													<Grid item>
														<Box pl={2}>
															<TextTuple title={t('User.jmeno')} content={user.jmeno}/>
														</Box>
													</Grid>
												</Grid>
												<TextTuple title={t('Provozovna.emaily')} content={user.email}/>
												<TextTuple title={t('Provozovna.telCislaKontaktu')} content={user.provozovna.telCislaKontaktu?.join(', ')}/>
											</Box>
										</AccordionDetails>
									</Accordion>
									<Accordion expanded={panel2} onChange={() => setPanel2(!panel2)}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon/>}
											aria-controls="panel2a-content"
											id="panel2a-header"
										>
											<Typography variant="h6">{t('Consent.Agreements')}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Grid xs={12} item>
												<Grid>
													<FormField title={t('Consent.PDIAgreement')}
															   name={'pdi'} type={'checkbox'} forceUpdateWhenDataChanged={['pdi']}
															   disabled={consentType.pdi}/>
													<Box component="span" m={1} alignItems="center" display="inline-block">
														<Box component="span" m={1}><Link
															onClick={() => downloadFileSimple('master/pravni-dokumentace/pravni-dokumentace-filled/PDI', errorTranslator)}>{t('Consent.Download')}</Link></Box>
														{urlPDI && (<Box component="span" m={1}><Link href={urlPDI} target="_blank">{t('Consent.Open')}</Link></Box>)}
													</Box>
												</Grid>
												<Grid>
													<FormField title={t('Consent.LPAgreement')} name={'licenceConditions'}
															   type={'checkbox'} forceUpdateWhenDataChanged={['licenceConditions']}
															   disabled={consentType.licenceConditions}/>
													<Box component="span" m={1} alignItems="center" display="inline-block">
														<Box component="span" m={1}><Link
															onClick={() => downloadFileSimple('master/pravni-dokumentace/pravni-dokumentace-filled/LP', errorTranslator)}>{t('Consent.Download')}</Link></Box>
														{urlLP && (<Box component="span" m={1}><Link href={urlLP} target="_blank">{t('Consent.Open')}</Link></Box>)}
													</Box>
												</Grid>
												<Grid>
													<FormField title={t('Consent.VPAgreement')} name={'termsAndConditions'}
															   type={'checkbox'} forceUpdateWhenDataChanged={['termsAndConditions']}
															   disabled={consentType.termsAndConditions}/>
													<Box component="span" m={1} alignItems="center" display="inline-block">
														<Box component="span" m={1}><Link
															onClick={() => downloadFileSimple('master/pravni-dokumentace/VP', errorTranslator)}>{t('Consent.Download')}</Link></Box>
														{urlVP && (<Box component="span" m={1}><Link href={urlVP} target="_blank">{t('Consent.Open')}</Link></Box>)}
													</Box>
												</Grid>
												<Grid>
													<FormField title={t('Consent.GDPRAgreement')} name={'gdpr'}
															   type={'checkbox'} forceUpdateWhenDataChanged={['gdpr']}
															   disabled={consentType.gdpr}/>
													<Box component="span" m={1} alignItems="center" display="inline-block">
														<Box component="span" m={1}><Link
															onClick={() => downloadFileSimple('master/pravni-dokumentace/GDPR', errorTranslator)}>{t('Consent.Download')}</Link></Box>
														{urlGDPR && (<Box component="span" m={1}><Link href={urlGDPR} target="_blank">{t('Consent.Open')}</Link></Box>)}
													</Box>
												</Grid>
												<Grid item>
													<Box>
														<Typography variant={'caption'}>
															<div dangerouslySetInnerHTML={{ __html: t('consent.pdi.info') }}/>
														</Typography>
													</Box>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>
									<Accordion expanded={panel3} onChange={() => setPanel3(!panel3)}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon/>}
											aria-controls="panel3a-content"
											id="panel3a-header"
										>
											<Typography variant="h6">{t('Consent.MinimalRequirements')}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Grid xs={12}>
												<Typography>
													<div dangerouslySetInnerHTML={{ __html: t('menu.minimalRequirements') }}/>
												</Typography>
											</Grid>
										</AccordionDetails>
									</Accordion>
									<Box display="flex" justifyContent="center" mt={2}>
										<Button disabled={submitDisabled} variant="contained" color="primary" onClick={() => setModalOpen(true)}>
											{t('Buttons.Continue')}
										</Button>
									</Box>
								</Box>
							</Grid>
							<MuiModal
								title={t('ConfirmDialog.Title')}
								maxWidth={'md'}
								fullScreen={false}
								open={modalOpen}
								onClose={() => {
									setModalOpen(false);
								}}>
								{modalOpen && <>
									<Typography>
										<div dangerouslySetInnerHTML={{ __html: t('dialog.legalConsent') }}/>
									</Typography>
									<Grid container justifyContent={'center'}>
										<Box my={3}>
											<Button onClick={() => setModalOpen(false)} variant="contained">
												{t('Buttons.Cancellation')}
											</Button>
										</Box>
										<Box my={3}>
											<FormButton disabled={submitDisabled} main={true}>
												{t('Buttons.Agree')}
											</FormButton>
										</Box>
									</Grid>
								</>}
							</MuiModal>
						</Form>
					</Box>
				</Paper>
			</Box>
		</Grid>;
	;
}