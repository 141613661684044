import * as React from "react";
import {PropsWithChildren, useContext, useEffect, useState} from "react";
import {PaletteType, ThemeProvider} from "@material-ui/core";
import theme from "../../assets/Theme";
import DataStorage from "../../common/DataStorage";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const drawerWidth = 250;

export const globalStyles = {
    rowStyleAlert: {
        backgroundColor: "rgba(255,0,0,0.31)"
    },
    rowStyleDuplicated: {
        backgroundColor: "rgba(255,172,0,0.62)",
        color: 'grey',
    },
    rowStyleViewedLight: {
        backgroundColor: "#73b9ff",
    },
    rowStyleViewedDark: {
        backgroundColor: "rgba(0,149,255,0.4)",
    },
    rowStyleReadOnly: {
        backgroundColor: "rgba(195,193,189,0.62)",
        color: 'grey',
    },
    rowStyleLocked: {
        color: 'grey',
    },
    rowStyleDeleted: {
        color: 'grey',
        textDecoration: 'line-through',
        height: 31
    },
    chipLockContainer: {
        position: 'relative',
        visibility: 'collapse',
    },
    chipLockRecord: {
        position: 'absolute',
        visibility: 'visible',
        backgroundColor: 'green',
        opacity: 0.8,
        color: 'white',
        right: '0',
        marginTop: 3,
        marginRight: 5
    },
    chipChangedRecordIcon: {
        opacity: 0.8,
        color: 'orange',
    },
    chipLockIcon: {
        color: 'white'
    },
    rowStyleCase00Light: {
        backgroundColor: "rgba(253, 255, 129, 0.62)",
        color: 'black'
    },
    rowStyleCase00Dark: {
        backgroundColor: "rgba(214, 174, 0, 0.62)",
        color: 'black'
    },
    rowStyleCase01Light: {
        backgroundColor: "rgba(233, 192, 241, 0.62)",
        color: 'black'
    },
    rowStyleCase01Dark: {
        backgroundColor: "rgba(208, 1, 230, 0.62)",
        color: 'black'
    },
    rowStyleCase02Light: {
        backgroundColor: "rgba(251, 236, 236, 0.62)",
        color: 'black'
    },
    rowStyleCase02Dark: {
        backgroundColor: "rgba(235, 76, 72, 0.62)",
        color: 'black'
    },
    rowStyleCase03Light: {
        backgroundColor: "rgba(213, 255, 200, 0.62)",
        color: 'black'
    },
    rowStyleCase03Dark: {
        backgroundColor: "rgba(89, 208, 0, 0.62)",
        color: 'black'
    },
    rowStyleCase05Light: {
        backgroundColor: "rgba(128, 128, 128, 0.62)",
        color: 'black'
    },
    rowStyleCase05Dark: {
        backgroundColor: "rgba(0, 0, 0, 0.62)",
        color: 'black'
    },
    rowStyleCase06Light: {
        backgroundColor: "rgba(212, 143, 142, 0.62)",
        color: 'black'
    },
    rowStyleCase06Dark: {
        backgroundColor: "rgba(72, 0, 0, 0.62)",
        color: 'black'
    },
    rowStyleUserMaster: {
        fontWeight: 700,
        color: 'black'
    }
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            height: '100vh',
            backgroundColor: theme.palette.type === 'dark' ? "#222222" : "#cccccc",
        },
        appBarDesktop: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            zIndex: theme.zIndex.drawer + 1,
        },
        updateBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '9pt',
            fontWeight: 700,
            '& span': {
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
            }
        },
        updateIcon: {
            position: "absolute",
            fontSize: "24pt !important",
            marginBottom: "2px"
        },
        updateText: {
            width: "17.5pt",
            paddingBottom: "1px"
        },
        disabledUpdateBox: {
            pointerEvents: 'none',
            opacity: 0.3
        },
        flexGrow: {
            flexGrow: 1,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0
        },
        drawerPaper: {
            width: drawerWidth,
            zIndex: 2000
        },
        drawerItem: {
            minHeight: 48
        },
        toolbar: theme.mixins.toolbar,
        menuToolbar: {padding: "15px 20px"},
        nested: {
            paddingLeft: theme.spacing(2),
        },
        activeLink: {
            color: theme.palette.type === 'dark' ? "#6ab1f7" : "#0262c2",
        },
        headerLink: {
            color: theme.palette.primary.contrastText,
            '& a:hover': {
                color: "#90CAF9"
            }
        },
        invertTabs: {
            backgroundColor: "transparent !important"
        },
        tableHeader: {
            backgroundColor: theme.palette.type === 'dark' ? "#333333" : theme.palette.grey[100],
            color: theme.palette.type === 'dark' ? theme.palette.grey[50] : theme.palette.grey[900]
        },
        themeText: {
            color: theme.palette.type === 'dark' ? theme.palette.grey[50] : theme.palette.grey[900]
        },
        invertItem: {
            backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50]
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hrClass: {
            backgroundColor: theme.palette.type === 'dark' ? "#ffffff" : "#000000",
        },
        sectionLabel: {
            margin: `0 ${theme.spacing(2)}px 0 0`,
            color: theme.palette.type === 'dark' ? "#ffffff" : "#000000",
        },
        sectionLabelUser: {
            margin: `0 ${theme.spacing(2)}px 10px 0`,
            color: theme.palette.type === 'dark' ? "#ffffff" : "#000000",
        },
        coloredText: {
            color: theme.palette.type === 'dark' ? "#e736ff" : "#ad0099",
        },
        title: {
            flexGrow: 1,
        },
        hocSorting: {
            zIndex: theme.zIndex.modal + 100,
        },
        sectionDesktop: {
            marginTop: 8,
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)!important`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(2),
            overflow: "auto",
            display: "flex",
            flexFlow: "column"
        },
        contentIndent: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentIndentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        contentNoLeftMargin: {
            marginLeft: 0,
        },
        versionContainer: {
            position: "absolute",
            bottom: 0,
            fontSize: 9,
            padding: "0.5rem",
        },
        inactive: {
            color: "gray"
        },
        rightMargin1: {
            marginRight: theme.spacing(1)
        },
        marginTop1: {
            marginTop: theme.spacing(1)
        },
        marginBottom1: {
            marginBottom: theme.spacing(1)
        },
        marginTop2: {
            marginTop: theme.spacing(2)
        },
        marginBottom2: {
            marginBottom: theme.spacing(2)
        },
        sectionBackground: {
            /*backgroundColor: theme.palette.type === 'dark' ? "#1d274d" : "#6ab9ff59",*/
        },
        inputSectionBackground: {
            backgroundColor: theme.palette.type === 'dark' ? "#424242" : "#ffffff",
        },
        dialogBackground: {
            backgroundColor: theme.palette.type === 'dark' ? "#222222" : "#cccccc",
        },
        dialogAlert: {
            "& .MuiAlert-icon": {
                display: 'flex',
                alignItems: 'center'
            },
            "& .MuiAlert-root": theme.palette.type === 'dark' ? {
                backgroundColor: '#5e5c5c'
            } : {},
            "& .MuiAlert-message": {
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
                paddingTop: 3
            },
            "& .MuiAlert-message div": {
                display: 'flex',
                alignItems: 'center',
                marginBottom: 0
            }
        },
        dialogInfo: {
            "& .MuiAlert-icon": {
                display: 'flex',
                alignItems: 'center'
            },
            "& .MuiAlert-root": theme.palette.type === 'dark' ? {
                backgroundColor: '#5e5c5c'
            } : {},
            "& .MuiAlert-message": {
                display: 'flex',
                alignItems: 'center',
                padding: 0,
                paddingTop: 3
            },
            "& .MuiAlert-message div": {
                display: 'flex',
                alignItems: 'center',
                marginBottom: 0
            }
        },
        linearProgress: theme.palette.type === 'dark' ? {
            backgroundColor: '#14599d'
        } : {}
    }),
);




const ThemeContext = React.createContext({} as {toggleTheme:VoidFunction, mode:PaletteType});
const StyleContext = React.createContext({} as {classes:Record<any, any>});

export const useThemeContext = () => {
    return useContext(ThemeContext);
};

export const useStyleContext = () => {
    return useContext(StyleContext);
};

const MuiStyleContext = ({children}:PropsWithChildren<{}>) => {
    const classes = useStyles();
    return (
        <StyleContext.Provider value={{classes}}>
            {children}
        </StyleContext.Provider>
    );

};

export function MuiThemeContext({children}:PropsWithChildren<{}>) {
    const [mode, setMode] = useState<PaletteType>(    DataStorage.get("mode",  false) || "light");

    useEffect(()=>{
        DataStorage.set("mode", mode, false);
    }, [mode]);

    const toggleTheme = () => {
        setMode(mode === "light" ? "dark": "light")
    };
    return (
        <ThemeContext.Provider value={{toggleTheme, mode}}>
            <ThemeProvider theme={theme(mode)}>
                <MuiStyleContext>
                    {children}
                </MuiStyleContext>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
}
