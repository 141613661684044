import {useAppContext} from "../context/AppContext";
import React from "react";
import {PageTitle} from "../../common/component/PageTitle";

export enum TitlePosition {
    DEFAULT, FILTER_NAME, TAB
}

const raalTitleOrder = [TitlePosition.FILTER_NAME, TitlePosition.DEFAULT, TitlePosition.TAB];
const defaultTitle = "Raaltrans";

export interface CombinedTitle {
    start?: string,
    end?: string,
    value: string,
    appender?: string,
    id: number | string,
}

/**
 * This component dynamically manages and displays page titles within the application, based on the provided title and its position.
 * The logic ensures that titles at higher positions are cleared out, maintaining a coherent title hierarchy.
 * The order of title positions is managed by constant (`raalTitleOrder`), making the component specific for this application.
 *
 * Props:
 * - `title`: The title text to be displayed or updated at the specified position. Required if `combinedTitle` is not provided.
 * - `titlePosition` (optional): An enum value specifying the position for the title. Default is `TitlePosition.DEFAULT`.
 * - `combinedTitle` (optional): An object specifying a complex title structure for more granular control over title updates.
 *    This includes the title's id, value, and optional appenders/start/end for constructing combined titles.
 */
export function DynamicTitle({title, titlePosition = TitlePosition.DEFAULT, combinedTitle}:{title?:string, titlePosition?:TitlePosition, combinedTitle?:CombinedTitle}) {
    const {dynamicTitle} = useAppContext();

    if (combinedTitle) {
        dynamicTitle.current = dynamicTitle.current.filter(entry => entry.position <= titlePosition);
        let currentValues = dynamicTitle.current
            .find(entry => entry.position === titlePosition);

        if (currentValues && Array.isArray(currentValues.data)) {
            currentValues.data = currentValues.data
                .filter(entry => combinedTitle?.id !== entry?.id);
            currentValues.data.push({id: combinedTitle.id, value: combinedTitle.value});
        } else {
            currentValues = {
                position: titlePosition,
                value: "",
                data: combinedTitle?.id ? [{id: combinedTitle.id, value: combinedTitle.value}] : []
            };
            dynamicTitle.current.push(currentValues);
        }

        let joinedTitle = currentValues.data.map(entry => entry.value)
            .join(combinedTitle.appender ?? " ");
        currentValues.value = (combinedTitle.start ?? "") + joinedTitle + (combinedTitle.end ?? "");
    } else {
        dynamicTitle.current = dynamicTitle.current.filter(entry => entry.position < titlePosition);
        dynamicTitle.current.push({ position: titlePosition, value: title});
    }

    const titles = raalTitleOrder
        ?.map(pos=> {
            return dynamicTitle.current?.find(entry => entry.position === pos)?.value;
        });

    const finalTitle = titles?.filter(Boolean)?.join(" ").trim() || defaultTitle;

    return (
        <PageTitle title={finalTitle}/>
    );
}