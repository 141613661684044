import {CustomFieldComponentProps} from "../../common/component/form/FormFieldInterface";
import {FormInputType} from "./form/Form";
import React, {MutableRefObject, useCallback, useEffect, useState} from 'react';
import {StandaloneField} from "../../common/component/form/StandaloneField";
import {Box, Grid} from "@material-ui/core";
import moment, {Moment} from "moment";
import {GenericMap} from "../../index.d";
import {
    FormRange,
    FormRangeDateOptions,
    useFormDateRange,
    useRangeFocusable
} from "../../common/component/form/FormRange";
import {useDatesErrors} from "../../common/component/form/FormDateRange";
import {useDidMount, useMountedEffect} from "../../common/component/hooks/SharedHooks";
import {VozidloPrepravaSharedFields} from "../page/Dials/user/_vp/PrepravaAVozidlaShared";
import {SelectProps} from "../../common/component/form/FormSelect";
import {exist} from "../../common/utils/Util";
import {useData} from "../context/DataContext";
import {SystemParameter, SystemParamKey} from "../model/SystemParameter";
import {useTranslation} from "react-i18next";
import {invoke} from "../../common/utils/Invoke";

export type FormTwoDatesOptions<T> = {
    logicHandler?:(data: T, setData: (data: T) => void) => (field: string) => void
    spacing?:number,
    shouldDisableDate:(data:T, day:Moment, field:string)=>boolean,
    data?:T,
    width:number,
    setData?:(data:T)=>void
    realtimeErrorsDatOd:MutableRefObject<string[]>
    realtimeErrorsDatDo:MutableRefObject<string[]>
    onKeyUp:(data:T, setData: (data: T) => void, field:string)=>(e:React.FormEvent<HTMLInputElement>)=>void
    dateFromSelect?: SelectProps,
    typParametru?: SystemParamKey,
    defaultRange?: number
}

export function FormTwoDatesSelect<T extends VozidloPrepravaSharedFields>(props:CustomFieldComponentProps<void, FormTwoDatesOptions<T>>) {
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const {spacing=1, logicHandler, data, setData, realtimeErrorsDatOd, realtimeErrorsDatDo, dateFromSelect, typParametru, defaultRange} = options ?? {};
    const [dates, setDates] = useState({datOd:data?.datOd, datDo:data?.datDo});
    const [datesTo, setDatesTo] = useState([]);
    const [focused, onFieldFocused] = useRangeFocusable(props);
    const [disabledDateTo, setDisabledDateTo] = useState(true);
    const [errors] = useDatesErrors(props);
    const setDataDatesOnly = (data1:T) => {
        (data as GenericMap)['datOd'] = data1.datOd;
        (data as GenericMap)['datDo'] = data1.datDo;
        setDates({datOd:data1.datOd, datDo:data1.datDo});
    };
    const [param] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === typParametru));
    const maxRange = (param ? parseInt(param.value) : defaultRange) + 1;
    const onChange = (field:'datOd'|'datDo') => (value:Moment) => {
        (data as GenericMap)[field] = value;
        logicHandler(data, setDataDatesOnly)(field);
    };
    useMountedEffect(()=>{
        setData(data);
        setDisabledDateTo(!exist(data?.datOd));
    }, [dates]);
    useMountedEffect(()=>{
        setDates({datDo: data.datDo, datOd: data.datOd});
    }, [data.datDo, data.datOd]);
    useDidMount(()=> {
        getDateTo();
        setDisabledDateTo(!exist(data?.datOd));
    });
    useMountedEffect(()=>{
        getDateTo()
    }, [data.datOd]);

    const getDateTo = useCallback(() => {
        if (dateFromSelect?.options?.length > 0 && data?.datOd && moment(data.datOd).isSameOrAfter(moment(), 'date') ) {
            let dates: Moment[] = [];
            for(let d = 0; d < maxRange;d++){
                const date = moment(data.datOd).endOf('day').add(d, 'days');
                const lastDateFrom = dateFromSelect.options.slice(-1)[0]
                if (lastDateFrom.diff(date, "days") >= 0) dates.push(date);
            }
            setDatesTo(dates);
        }
    }, [setDatesTo, data, dateFromSelect,maxRange])
    return (
        <Box display="flex" flexDirection="row" style={{}}>
            <Box mr={spacing} style={{width:'50%'}}>
                <StandaloneField
                                 error={errors.datOd ?? realtimeErrorsDatOd.current.find((a, i)=>i===0)}
                                 focused={focused===1}
                                 useCustomPopperElement={true}
                                 value={dates.datOd}
                                 variant={props.variant ?? "outlined"}
                                 onKeyDown={(e) => {
                                     if (e.shiftKey && (e.key === "Tab" || e.key === "Enter")) onFieldFocused(0);
                                     if (e.key === "Escape") {
                                         e.preventDefault();
                                         onFieldFocused(0);
                                     }
                                     invoke(props.onKeyDown, e);
                                 }}
                                 type={FormInputType.Select}
                                 selectProps={{
                                     ...dateFromSelect,
                                     inputMode: "numeric",
                                     inputType: "date"
                                 }}
                                 showAdornment={props.showAdornment}
                                 onValueChanged={onChange("datOd")}
                                 onDisableActionKeys={props.onDisableActionKeys}
                                 autoSelectFirstValueOnTab={true}
                                 onError={e => {
                                     realtimeErrorsDatOd.current = e;
                                 }}
                                 onBlur={()=>{
                                    if (focused === 1) onFieldFocused(0);
                                 }}
                                 onFocus={(o, userFocus) => {
                                     if(userFocus) {
                                         onFieldFocused(1);
                                     }
                                 }}/>
            </Box>
            <Box style={{width:'50%'}}>
                <StandaloneField
                                 focused={focused===2}
                                 error={errors.datDo ?? realtimeErrorsDatDo.current.find((a, i)=>i===0)}
                                 value={dates.datDo}
                                 disabled={disabledDateTo}
                                 variant={props.variant ?? "outlined"}
                                 onKeyDown={(e) => {
                                     if (e.key === "Escape") {
                                         e.preventDefault();
                                         onFieldFocused(1);
                                     }
                                     invoke(props.onKeyDown, e);
                                 }}
                                 autoSelectFirstValueOnTab={true}
                                 type={FormInputType.Select}
                                 selectProps={{
                                     options: datesTo,
                                     noDynamicFontSize: true,
                                     inputMode: "numeric",
                                     inputType: "date",
                                     formatOption: (value: any) => {
                                         return {
                                             value: value,
                                             label: value.format("D.M.")
                                         }
                                     },
                                     getOptionSelected: ((option, value) => {
                                         return moment(option?.value).format("DD-MM") === moment(value?.value).format("DD-MM");
                                     })
                                 }}
                                 showAdornment={props.showAdornment}
                                 onDisableActionKeys={props.onDisableActionKeys}
                                 onValueChanged={onChange("datDo")}
                                 onError={e => {
                                     realtimeErrorsDatDo.current = e;
                                 }}
                                 onBlur={()=>{
                                     setDisabledDateTo(!exist(data?.datOd));
                                     onFieldFocused(0);
                                 }}
                                 onFocus={(o, userFocus) => {
                                     if(userFocus) {
                                         onFieldFocused(2);
                                     }
                                 }}/>
            </Box>
        </Box>
    );
}
export type FormDateRangeSelectOptions = {
        flexDirection?:"column"|"row",
        verticalSpace?:number,
        dateFromSelect?: SelectProps,
        typParametru?: SystemParamKey,
        defaultRange?: number,
        hideTitle?: boolean
        datOdRequired?: boolean
        datDoRequired?: boolean
        autoOpenDisabled?: boolean
    }
    &FormRangeDateOptions<string>
export type RDate = FormRange<string>
export function FormDateRangeSelect(props:CustomFieldComponentProps<RDate, FormDateRangeSelectOptions>) {
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const {t} = useTranslation();
    const [range, setRange, check, {maxWidth, spacing}] = useFormDateRange<string>(props);
    const {dateFromSelect, typParametru, defaultRange, verticalSpace, flexDirection, datOdRequired, datDoRequired} = options ?? {};
    const [focused, onFieldFocused] = useRangeFocusable(props);
    const textFieldProps = {style:{maxWidth:maxWidth}};
    const [param] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === typParametru));
    const maxRange = (param ? parseInt(param.value) : defaultRange) + 1;
    const [errors] = useDatesErrors(props);
    const [datesTo, setDatesTo] = useState([]);
    useMountedEffect(() => {
        if(!range.min && !range.max) {
            props.onValueChanged(null);
        } else {
            props.onValueChanged(range);
        }
    }, [range]);

    useEffect(() => {
      setRange({min: props.value?.min, max: props.value?.max});
        // eslint-disable-next-line
    }, [ props.value?.min, props.value?.max])

    useDidMount(() => {
        if (dateFromSelect?.options?.length > 0) {
            let dates: Moment[] = [];
            for (let d = 0; d < maxRange; d++) {
                const date = moment(range.min ? range.min : dateFromSelect?.options[0].value).endOf('day').add(d, 'days');
                const lastDateFrom = dateFromSelect.options.slice(-1)[0]
                if (lastDateFrom.diff(date, "days") >= 0) dates.push(date);
            }
            setDatesTo(dates);
        }
    })

    useMountedEffect(()=>{

        if (dateFromSelect?.options?.length > 0 && range.min) {
            let dates: Moment[] = [];
            for(let d = 0; d < maxRange;d++){
                const date = moment(range.min).endOf('day').add(d, 'days');
                const lastDateFrom = dateFromSelect.options.slice(-1)[0]
                if (lastDateFrom.diff(date, "days") >= 0) dates.push(date);
            }
            setDatesTo(dates);
        }
        else {
            if (dateFromSelect?.options?.length > 0) {
                let dates: Moment[] = [];
                for (let d = 0; d < maxRange; d++) {
                    const date = moment(dateFromSelect?.options[0].value).endOf('day').add(d, 'days');
                    const lastDateFrom = dateFromSelect.options.slice(-1)[0]
                    if (lastDateFrom.diff(date, "days") >= 0) dates.push(date);
                }
                setDatesTo(dates);
            }
        }
    }, [range.min]);

    useMountedEffect(() => {

    }, [range.min])

    const FieldsComponent = () => {
        return [<Grid item lg={flexDirection === 'row' ? 6 : 12} sm={12} xs={12} key={'od'}>
                <Box mr={spacing} mt={spacing} style={{width:'100%'}}>
                    <StandaloneField
                        onFocus={(o, userFocus) => {
                            if(userFocus) {
                                onFieldFocused(1);
                            }
                        }}
                        focused={focused===1}
                        variant={props.variant ?? "outlined"}
                        onKeyDown={(e) => {
                            if (e.shiftKey && (e.key === "Tab" || e.key === "Enter")) onFieldFocused(0);
                            if (e.key === "Tab") onFieldFocused(2)
                            if (e.key === "Escape") {
                                e.preventDefault();
                                onFieldFocused(0);
                            }
                            invoke(props.onKeyDown, e);
                        }}
                        type={FormInputType.Select}
                        selectProps={{...dateFromSelect, isClearable: true, inputMode: "numeric", inputType: "date", autoOpenDisabled: options.autoOpenDisabled}}
                        showAdornment={props.showAdornment}
                        inputClassName={props.inputClassName}
                        error={errors?.datOd}
                        onBlur={()=>{
                            if (focused === 1) onFieldFocused(0);
                        }}
                        value={range.min}
                        onValueChanged={value => {
                            setRange({...range, min: value, max: null});
                        }}
                        onInteractEnd={props.onInteractEnd}
                        title={!options.hideTitle&&(`${t("Preprava.datOd")}${datOdRequired ? ' *' : ''}`)} />
                </Box>
            </Grid>,
            <div style={{height: verticalSpace ?? 8}} key={'space'}/>,
            <Grid item lg={flexDirection === 'row' ? 6 : 12} sm={12} xs={12} key={'do'}>
                <Box style={{width:'100%', marginTop: 0}}>
                    <StandaloneField
                        onFocus={(o, userFocus) => {
                            if(userFocus) {
                                onFieldFocused(2);
                            }
                        }}
                        focused={focused===2}
                        variant={props.variant ?? "outlined"}
                        onKeyDown={props.onKeyDown}
                        type={FormInputType.Select}
                        selectProps={{
                            options: datesTo,
                            isClearable: true,
                            noDynamicFontSize: true,
                            inputMode: "numeric",
                            inputType: "date",
                            autoOpenDisabled: options.autoOpenDisabled,
                            formatOption: (value: any) => {
                                const d = moment(value).toISOStringWithMillis()
                                const lbl = d.replace('T', '-').split('-')
                                return {
                                    value: d,
                                    label: `${lbl[2]}.${lbl[1]}.`
                                }
                            }
                        }}
                        showAdornment={props.showAdornment}
                        inputClassName={props.inputClassName}
                        onBlur={() => onFieldFocused(0)}
                        value={range.max}
                        onValueChanged={value => setRange({...range, max: value})}
                        onInteractEnd={props.onInteractEnd}
                        title={!options.hideTitle&&(`${t("Preprava.datDo")}${datDoRequired ? ' *' : ''}`)}
                        textFieldProps={textFieldProps} />
                </Box>
            </Grid>
        ]
    }

    return (flexDirection ? <Grid container direction={flexDirection} >{FieldsComponent()}</Grid> : <>{FieldsComponent()}</>);
}
