import {useTranslation} from "react-i18next";
import {Box, Link, Typography} from "@material-ui/core";
import {default as React, useEffect} from "react";
import {showSnack} from "../../../common/component/SnackContainer";
import {useCodeBookControllerContext} from "./CodeBookController";

export function CodeBookDetailLoading() {
    const {t} = useTranslation();
    const {isDetailMode, loadingDetailFailed, hideDetail, response} = useCodeBookControllerContext();

    useEffect(() => {
        console.log("CodeBookDetailLoading mounted");
    }, [])

    function LoadingFailed() {
        return (
            <Box>
                <Typography variant={"h5"}>{response?.status===404 ? t("Default.NotFound") : t("Default.LoadingDetailFailed")}</Typography>
                <Link onClick={(e:any)=>{
                    e.preventDefault();
                    hideDetail();
                }}>{t("Back")}</Link>
            </Box>
        );
    }

    useEffect(() => {
        if (isDetailMode && loadingDetailFailed) {
            hideDetail();
            showSnack({title: t('Default.LoadingDetailFailed'), severity: "error"});
        }
        // eslint-disable-next-line
    }, [loadingDetailFailed])

    return (
        <>
            {isDetailMode&&(loadingDetailFailed ? <LoadingFailed /> : <Typography variant={"h5"}>{t("Default.LoadingDetail")}</Typography>)}
        </>
    );
}
