import {RejstrikLink} from "../../web/raal_components/parts/RejstrikLinksComponent";
import {Geometry} from "../../web/model/Geometry";


export function zivnostenskyRejstrikCzUrl(id: string, country: string) {
    switch (country) {
        case "CZ": return (id??"").length===8 ? `https://www.rzp.cz/verejne-udaje/cs/udaje/vyber-subjektu;ico=${id};roleSubjektu=P` : undefined;
        default:
            return undefined;
    }
}

export function obchodniRejstrikCzUrl(id: string, country: string) {
    switch (country) {
        case "CZ": return (id??"").length===8 ? `https://or.justice.cz/ias/ui/rejstrik-$firma?ico=${id}&jenPlatne=VSECHNY` : undefined;
        default:
            return undefined;
    }
}

export function aresUrl(id: string, country: string) {
    switch (country) {
        case "CZ": return (id??"").length===8 ? `https://ares.gov.cz/ekonomicke-subjekty?ico=${id}` : undefined;
        default:
            return undefined;
    }
}

export function zivnostenskyRejstrikSkUrl(id: string, country: string): RejstrikLink {
    switch (country) {
        case "SK": return (id??"").length===8 ? {titleKey: "Links.ZivnostenskySk", url: `http://www.zrsr.sk/index`, clipboardData: id} : undefined;
        default:
            return undefined;
    }
}

export function obchodniRejstrikSkUrl(id: string, country: string) {
    switch (country) {
        case "SK": return (id??"").length===8 ? ` http://www.orsr.sk/hladaj_ico.asp?ICO=${id}` : undefined;
        default:
            return undefined;
    }
}

export function dphRejstrikCzUrl(dic: string, country: string) {
    switch (country) {
        //nefunguje, asi se to musi posilat postem, a to zase cors.
        case "CZ": return (dic??"").length>2 ? ` https://adisreg.mfcr.cz/adistc/DphReg?id=1&pocet=1&fu=&OK=+Search+&ZPRAC=RDPHI1&dic=${dic}` : undefined;
        default:
            return undefined;
    }
}


export function euDicUrl(id: string, country: string) : RejstrikLink {
    switch (country) {
        case "A":
        case "B":
        case "BG":
        case "CY":
        case "CZ":
        case "D":
        case "DK":
        case "EST":
        case "GR":
        case "E":
        case "FIN":
        case "F":
        case "GB":
        case "HR":
        case "H":
        case "IRL":
        case "I":
        case "LT":
        case "L":
        case "LV":
        case "M":
        case "NL":
        case "PL":
        case "P":
        case "RO":
        case "S":
        case "SLO":
        case "SK":
            return id && id.length> 2 ? {titleKey: "Firma.dic", url: `https://ec.europa.eu/taxation_customs/vies/vatRequest.html`, clipboardData: id.substring(2)} : undefined;
        default:
            return undefined;
    }
}

export function getRejstrikLinks(id: string, vatId: string, country: string) : RejstrikLink[] {
    let links = [] as RejstrikLink[];

    const zCr = zivnostenskyRejstrikCzUrl(id, country);
    if(zCr)
        links.push({titleKey: "Links.ZivnostenskyCr", url: zCr});

    const oCr = obchodniRejstrikCzUrl(id, country);
    if(oCr)
        links.push({titleKey: "Links.ObchodniCr", url: oCr});

    const ares = aresUrl(id, country);
    if(ares)
        links.push({titleKey: "Links.Ares", url: ares});

    const zSk = zivnostenskyRejstrikSkUrl(id, country);
    if(zSk)
        links.push(zSk);

    const oSk = obchodniRejstrikSkUrl(id, country);
    if(oSk)
        links.push({titleKey: "Links.ObchodniSk", url: oSk});

    const euDic = euDicUrl(vatId, country);
    if(euDic)
        links.push(euDic);

    return links;
}

export function getGoogleMapsTraceUrl(from?:string, to?:string, waypoints?: string[]) {
    if (!from) return null;

    let t = !to ? from?.replace(':', ' ') : to.replace(':', ' ');
    let w = waypoints?.map(w => w.replace(':', ' '));
    if(from && t) {
        if (w) {
            return `https://www.google.com/maps/dir/${from}${w ? `/${w.join('/')}/` : ''}${t}`;
        } else {
            return `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${t}&origin=${from}`;
        }
    }
    return null;
}

export function getGoogleMapsPointUrl(gps: Geometry) {
    if (!gps) return null;

    return `https://www.google.com/maps/place/${gps.getLatLng().lat},${gps.getLatLng().lng}`;
}

export function getSeznamMapsPointUrl(gps: Geometry) {
    if (!gps) return null;

    return `https://mapy.cz/?source=coor&id=${gps.getLatLng().lng},${gps.getLatLng().lat}`;
}

export function getOsrmEditUrl(osrmId: number): string {
    return `https://www.openstreetmap.org/edit?way=${osrmId}`
}
