import {Preprava} from "../../../../model/Preprava";
import {useTranslation} from "react-i18next";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import {useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {useLoadingContext} from "../../../../../common/component/Loading";
import {
    useCodeBookControllerContext,
    useCodeBookControllerContextNew
} from "../../../../raal_components/controller/CodeBookController";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@material-ui/core";
import {InvalDuvodPreprava} from "../../../../model/CommonTypes";
import {invalidatePreprava, validatePreprava} from "../../../../../common/logic/preprava-logic";
import {Assignment, Visibility} from "@material-ui/icons";
import {TextTuple, TextTupleType} from "../../../../../common/component/form/TextTuple";
import * as React from "react";
import {useCodeBookDetailContextNew} from "../../../../raal_components/controller/CodeBookDetail";

/**
 * TODO stejná logika jako New, nutné nahradit všude novou novou
 * @constructor
 */
export function PrepravaViewAdminPart({
                                          preprava,
                                          history = false,
                                          archive = false
                                      }: { preprava: Preprava | any, history?: boolean, archive?: boolean }) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const {setLoading} = useLoadingContext();
    const {reload, formData} = useCodeBookControllerContext<Preprava>();
    return <Grid container spacing={2} alignItems="stretch" direction="row">
        <Grid item lg={12} sm={12} xs={12}>
            <Card style={{height: "100%"}} className={classes.sectionBackground}>
                <CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
                    <Box marginBottom={2}>
                        <Typography variant="h6">{t("Sections.Administration")}</Typography>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} spacing={3}>
                            {(history || archive) ? undefined : <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemText>
                                        <Box
                                            hidden={formData && formData.data.invalDuv === InvalDuvodPreprava.ZNEPLATNENO_OPERATOREM}>
                                            <Grid item>
                                                <Button variant={"contained"} color={"secondary"}
                                                        onClick={async () => {
                                                            setLoading(true);
                                                            await invalidatePreprava(preprava.id);
                                                            setLoading(false);
                                                            reload();
                                                        }}
                                                >{t("Buttons.MakeInvalid")}</Button>
                                            </Grid>
                                        </Box>
                                        <Box
                                            hidden={formData && formData.data.invalDuv !== InvalDuvodPreprava.ZNEPLATNENO_OPERATOREM}>
                                            <Grid item>
                                                <Button variant={"contained"} color={"secondary"}
                                                        onClick={async () => {
                                                            setLoading(true);
                                                            await validatePreprava(preprava.id);
                                                            setLoading(false);
                                                            reload();
                                                        }}>{t("Buttons.MakeValid")}</Button>
                                            </Grid>
                                        </Box>
                                    </ListItemText>
                                </ListItem>
                            </Grid>}
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar
                                        className={"avatar-admin"}><Visibility/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("PVI.Valid")} content={preprava.valid}
                                                   type={TextTupleType.Checkbox}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar
                                        className={"avatar-admin"}><Assignment/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("PVI.InvalDuv")}
                                                   content={locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, preprava.invalDuv)}
                                                   type={TextTupleType.Text}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}

export function PrepravaViewAdminPartNew({
                                             preprava,
                                             history = false,
                                             archive = false
                                         }: { preprava: Preprava | any, history?: boolean, archive?: boolean }) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const {replaceDetailForm} = useCodeBookControllerContextNew<Preprava>();
    const {data} = useCodeBookDetailContextNew<Preprava>();
    return <Grid container spacing={2} alignItems="stretch" direction="row">
        <Grid item lg={12} sm={12} xs={12}>
            <Card style={{height: "100%"}} className={classes.sectionBackground}>
                <CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
                    <Box marginBottom={2}>
                        <Typography variant="h6">{t("Sections.Administration")}</Typography>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} spacing={3}>
                            {(history || archive) ? undefined : <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemText>
                                        <Box hidden={data.invalDuv === InvalDuvodPreprava.ZNEPLATNENO_OPERATOREM}>
                                            <Grid item>
                                                <Button variant={"contained"} color={"secondary"}
                                                        onClick={async () => {
                                                            await invalidatePreprava(preprava.id);
                                                            replaceDetailForm();
                                                        }}
                                                >{t("Buttons.MakeInvalid")}</Button>
                                            </Grid>
                                        </Box>
                                        <Box hidden={data.invalDuv !== InvalDuvodPreprava.ZNEPLATNENO_OPERATOREM}>
                                            <Grid item>
                                                <Button variant={"contained"} color={"secondary"}
                                                        onClick={async () => {
                                                            await validatePreprava(preprava.id);
                                                            replaceDetailForm();
                                                        }}>{t("Buttons.MakeValid")}</Button>
                                            </Grid>
                                        </Box>
                                    </ListItemText>
                                </ListItem>
                            </Grid>}
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar
                                        className={"avatar-admin"}><Visibility/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("PVI.Valid")} content={preprava.valid}
                                                   type={TextTupleType.Checkbox}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar
                                        className={"avatar-admin"}><Assignment/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("PVI.InvalDuv")}
                                                   content={locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, preprava.invalDuv)}
                                                   type={TextTupleType.Text}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}
