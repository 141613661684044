import {ProfilVozidlaSelect} from "../../../../model/ProfilVozidla";
import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    TransportDokladkaTrasa,
    TransportPrejezdTrasa,
    TransportTrasa,
    TrasaBase,
    TrasaState
} from "../../../../model/Trasa";
import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import {CrudUpdate} from "../../../../model/CrudUpdate";
import {Mapper} from "../../../../../common/utils/objectmapper/Mapper";
import {useStompSubscribe} from "../../../../../common/utils/Websocket";
import {MuiDetailTabModal} from "../../../../../common/component/MuiDetailTabModal";
import {exist, mergeNotEmptyData} from "../../../../../common/utils/Util";
import {CodeBookForm} from "../../../../raal_components/controller/CodebookForm";
import {Grid, LinearProgress} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import {FormButton, FormField, FormInputType} from "../../../../raal_components/form/Form";
import {FormMap, FormMapOptions} from "../../../../../common/component/form/FormMap";
import {Waypoint} from "../../../../model/Waypoint";
import {mapDataConverterWaypoints} from "../../../../raal_components/SharedConfig";
import {TrasaFormViewType} from "./TrasaForm";
import {KilometrovnikLinkType} from "./KilometrovnikLinkPart";
import {Currency} from "../../../../model/Currency";
import {useDokladkaFetchRouteOnly, useFetchRouteOnly, usePrejezdFetchRouteOnly} from "./TrasaRouteFunctions";
import {LoadablePartProps, LoadablePartState, useLoadablePart} from "../../../../../common/component/hooks/SharedHooks";
import {Loading} from "../../../../../common/component/Loading";
import {GeoPosition} from "geo-position.ts";
import {extractRouteFromOsrmData} from "../../../../../common/utils/osrm-utils";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import DataStorage from "../../../../../common/DataStorage";
import {useHistoryCustom} from "../../../../raal_components/controller/NavigationHelper";
import {useAppContext} from "../../../../context/AppContext";


export enum TrasaType {NORMAL, PREJEZD, DOKLADKA}

type PrejezdProps = {
    prejezdOd: Waypoint;
    prejezdKam: Waypoint;
}

type DokladkaProps = {
    nakladka: Waypoint;
    vykladka: Waypoint;
}

export type ModalTrasaFormProps = {
    onSaved?: ()=> void
    onClose?: ()=> void
    open?:boolean
    profilVozidla?: ProfilVozidlaSelect
    currency: Currency
    offerId?: number
    linkType?: KilometrovnikLinkType
    routeOnly?: boolean,
    prejezd?: PrejezdProps,
    dokladka?: DokladkaProps,
    data?: TransportTrasa

}

function getEndpoint(trasaType: TrasaType, linkType: KilometrovnikLinkType) {
    switch (trasaType) {
        case TrasaType.NORMAL:
            return linkType===KilometrovnikLinkType.PREPRAVA ? 'user/preprava-trasa' : 'user/vozidlo-trasa'
        case TrasaType.PREJEZD:
            return linkType===KilometrovnikLinkType.PREPRAVA ? 'user/preprava-prejezd-trasa' : 'user/vozidlo-prejezd-trasa'
        case TrasaType.DOKLADKA:
            return 'user/dokladka-trasa'
    }
}

function getParams(trasaType: TrasaType, offerId: number, profilVozidla: ProfilVozidlaSelect, currency: Currency, props: ModalTrasaFormProps) {
    switch (trasaType) {
        case TrasaType.NORMAL:
            return {params: {id: offerId, profileId: profilVozidla.id, currencyCode: currency.currencyCode}}
        case TrasaType.PREJEZD:
            return {params: {id: offerId, profileId: profilVozidla.id, currencyCode: currency.currencyCode, prejezdOd: JSON.stringify(props.prejezd.prejezdOd.koordinat), prejezdKam: JSON.stringify(props.prejezd.prejezdKam.koordinat)}}
        case TrasaType.DOKLADKA:
            return {params: {id: offerId, profileId: profilVozidla.id, currencyCode: currency.currencyCode, nakladka: JSON.stringify(props.dokladka.nakladka.koordinat), vykladka: JSON.stringify(props.dokladka.vykladka.koordinat)}}
    }
}

function getStompTopic(trasaType: TrasaType, linkType: KilometrovnikLinkType, offerId: number, profilVozidla: ProfilVozidlaSelect, currency: Currency, props: ModalTrasaFormProps) {
    const mapper = new Mapper<Waypoint>({constructor: Waypoint});
    const prejezd: PrejezdProps = props.prejezd ? {
        prejezdKam: mapper.readValue(props.prejezd.prejezdKam),
        prejezdOd: mapper.readValue(props.prejezd.prejezdOd)
    } : null;
    const dokladka: DokladkaProps = props.dokladka ? {
        nakladka: mapper.readValue(props.dokladka.nakladka),
        vykladka: mapper.readValue(props.dokladka.vykladka)
    } : null;
    switch (trasaType) {
        case TrasaType.NORMAL:
            return `${linkType===KilometrovnikLinkType.PREPRAVA ? '/preprava-trasa' : '/vozidlo-trasa'}/${offerId}/${profilVozidla?.id}/${currency?.currencyCode}`;
        case TrasaType.PREJEZD:
            return `${linkType===KilometrovnikLinkType.PREPRAVA ? '/preprava-prejezd-trasa' : '/vozidlo-prejezd-trasa'}/${offerId}/${profilVozidla.id}/${currency.currencyCode}/${prejezd.prejezdOd.koordinat?.toSimpleString()}/${prejezd.prejezdKam.koordinat.toSimpleString()}`
        case TrasaType.DOKLADKA:
            return `/dokladka-trasa/${offerId}/${profilVozidla.id}/${currency.currencyCode}/${dokladka.nakladka.koordinat?.toSimpleString()}/${dokladka.vykladka.koordinat.toSimpleString()}`
    }
}

export function ModalTrasaForm(props: ModalTrasaFormProps) {
    const storageKey = "kilometrovnik-trasa";
    const storageProps = exist(props?.offerId) ? props : JSON.parse(DataStorage.get(storageKey,  true, 'session'));
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const {profilVozidla, currency, offerId, linkType, data: trasaDataTemp} = storageProps;
    const mapper = new Mapper<Waypoint>({constructor: Waypoint});
    const prejezd: PrejezdProps = storageProps.prejezd ? {
        prejezdKam: mapper.readValue(storageProps.prejezd.prejezdKam),
        prejezdOd: mapper.readValue(storageProps.prejezd.prejezdOd)
    } : null;
    const dokladka: DokladkaProps = storageProps.dokladka ? {
        nakladka: mapper.readValue(storageProps.dokladka.nakladka),
        vykladka: mapper.readValue(storageProps.dokladka.vykladka)
    } : null;
    const mapperTransport = new Mapper<TransportTrasa>({constructor: TransportTrasa});
    const trasaData: TransportTrasa = trasaDataTemp ? mapperTransport.readValue(trasaDataTemp) : null;

    const trasaType = exist(prejezd) ? TrasaType.PREJEZD : exist(dokladka) ? TrasaType.DOKLADKA : TrasaType.NORMAL;
    const href = getEndpoint(trasaType, linkType);
    const params = getParams(trasaType, offerId, profilVozidla, currency, storageProps)
    const stompTopic = getStompTopic(trasaType, linkType, offerId, profilVozidla, currency, storageProps);
    const [f, forceUpdate] = useState(false);
    const {fetch} = useFetchCustom<TransportTrasa>({ endpoint: href}, undefined, TransportTrasa);
    const {fetch: fetchPrejezd} = useFetchCustom<TransportPrejezdTrasa>({ endpoint: href}, undefined, TransportPrejezdTrasa);
    const {fetch: fetchDokladka} = useFetchCustom<TransportDokladkaTrasa>({ endpoint: href}, undefined, TransportDokladkaTrasa);
    const fetchRouteOnly = useFetchRouteOnly(offerId?.toString(), profilVozidla?.id?.toString(), currency.currencyCode, linkType);
    const prejezdFetchRouteOnly = usePrejezdFetchRouteOnly(offerId?.toString(), profilVozidla?.id?.toString(), currency.currencyCode, linkType, prejezd?.prejezdOd, prejezd?.prejezdKam);
    const dokladkaFetchRouteOnly = useDokladkaFetchRouteOnly(offerId?.toString(), profilVozidla?.id?.toString(), currency.currencyCode, linkType, dokladka?.nakladka, dokladka?.vykladka);
    const [newData, setNewData] = useState<TransportTrasa>(null);
    const [showRouteOnly, setShowRouteOnly] = useState(true);
    const routeOnly = storageProps.routeOnly && showRouteOnly
    const loadFunc = useMemo<LoadablePartProps<TransportTrasa>>(() => ({
        loadFun: () => exist(trasaData) ? new Promise<TransportTrasa>((resolve) => resolve(trasaData)) : routeOnly ? (trasaType===TrasaType.PREJEZD ? prejezdFetchRouteOnly() : (trasaType===TrasaType.DOKLADKA ? dokladkaFetchRouteOnly() : fetchRouteOnly())) :
            trasaType===TrasaType.PREJEZD ? fetchPrejezd(params) : trasaType===TrasaType.DOKLADKA ? fetchDokladka(params) : fetch(params),
        loadOnInit: false
        // eslint-disable-next-line
    }), [offerId, profilVozidla?.id, currency?.currencyCode, routeOnly, trasaData])
    const loadablePart = useLoadablePart<TransportTrasa>(loadFunc);
    const data = trasaType===TrasaType.PREJEZD ? loadablePart.data as TransportPrejezdTrasa : trasaType===TrasaType.DOKLADKA ? loadablePart.data as TransportDokladkaTrasa : loadablePart.data as TransportTrasa;
    const {replace} = useHistoryCustom();
    const {setDataChanged} = useAppContext();

    useEffect(() => {
        if (offerId) {
            loadablePart.reload();
        }
        // eslint-disable-next-line
    }, [f, routeOnly]);

    //reload dat, pokud prisel stomp update a nemam trasu
    useEffect(() => {
        const hasOsrmData = exist(data?.getCalculationResult()) && data.getCalculationResult().length>0 && !data.getCalculationResult().find(value => !exist(value.osrmData));
        const isCond = exist(data?.getCalculationResult()) && data?.progress>0 && !hasOsrmData;

        if(isCond) {
            loadablePart.reload();
        }
        // eslint-disable-next-line
    }, [data]);

    //zkopirovani trasy pokud neni v updatu
    useEffect(() => {
        if(exist(newData)) {
            mergeNotEmptyData(data, newData, "nazev");
            data?.calculationResult?.forEach((value, index) => {
                const cr = newData.getCalculationResultAtIndex(index);
                if (exist(cr) && !exist(cr?.osrmData) && exist(value.osrmData))
                    cr.osrmData = value.osrmData;
            });

            loadablePart.updateData(newData)
        }
        // eslint-disable-next-line
    }, [newData]);

    //znovunahrani dat, pokud ve stavu ceka
    useEffect(() =>{
        if (exist(data?.state) && data?.state === TrasaState.WAITING && f === false) {
            setTimeout(() => {
                forceUpdate(!f);
            }, 500);

        }
        // eslint-disable-next-line
    }, [data]);

    const stompCallback = useCallback((data: CrudUpdate) => {
        const updatedData = trasaType===TrasaType.PREJEZD ?
            new Mapper<TransportPrejezdTrasa>({constructor:TransportPrejezdTrasa}).readValue(data.entity) :
            trasaType===TrasaType.DOKLADKA ?
                new Mapper<TransportDokladkaTrasa>({constructor:TransportDokladkaTrasa}).readValue(data.entity) :
                new Mapper<TransportTrasa>({constructor:TransportTrasa}).readValue(data.entity);
        if(!routeOnly)
            setNewData(updatedData);
        // eslint-disable-next-line
    }, [offerId, profilVozidla, currency, routeOnly, trasaType]);
    const stompCallbackOptions = useMemo(() => ({clazz: CrudUpdate, callback: stompCallback, userOnly: true}), [stompCallback]);

    useStompSubscribe<CrudUpdate>(stompTopic, stompCallbackOptions);
    console.log(storageProps)
    return (
        <MuiDetailTabModal
            open
            onClose={() => {
                DataStorage.clear(storageKey, true, 'session');
                setDataChanged(false);
                replace('/kilometrovnik/trasy');
            }}
            fullScreen={true}
            title={t("Default.TabZakladni")}
        >
            <>
                <Loading show={loadablePart.loadableState === LoadablePartState.LOADING && !exist(data)} fullscreen/>
                {  loadablePart.loadableState===LoadablePartState.ERROR ?
                    <Alert severity="error">{loadablePart.lastError?.errorMessage}</Alert>
                : exist(data?.state) ?
                    <CodeBookForm
                          hideNewButtonOnEdit
                          data={data}
                          onSuccess={() => {
                            DataStorage.clear(storageKey, true, 'session');
                            setDataChanged(false);
                            replace('/kilometrovnik/trasy');
                          }}
                          excludeFieldsForDirtyCheck={['state', 'calculationResult', 'waypointy', 'profilVozidla', 'currency']}
                          clazz={TrasaBase}
                          url={'user/trasa'}
                          stopImmediatePropagation={true}
                          beforeSend={(data) => {
                              if (trasaType===TrasaType.PREJEZD) {
                                  const distanceOd = new GeoPosition(prejezd.prejezdOd?.koordinat?.coordinates[1], prejezd.prejezdOd?.koordinat.coordinates[0]).Distance(new GeoPosition(data.waypointy[0]?.koordinat?.coordinates[1], data.waypointy[0]?.koordinat?.coordinates[0]));
                                  const distanceKam = new GeoPosition(prejezd.prejezdKam?.koordinat?.coordinates[1], prejezd.prejezdKam?.koordinat.coordinates[0]).Distance(new GeoPosition(data.waypointy[data.waypointy.length-1]?.koordinat?.coordinates[1], data.waypointy[data.waypointy.length-1]?.koordinat?.coordinates[0]));
                                  const wp: Waypoint[] = [];
                                  if (distanceOd > 10)
                                      wp.push(prejezd.prejezdOd);
                                  wp.push(...data.waypointy);
                                  if (distanceKam > 10)
                                      wp.push(prejezd.prejezdKam);

                                  data.waypointy = wp;
                              }

                              if (trasaType===TrasaType.DOKLADKA) {
                                  const distanceOd = new GeoPosition(dokladka.nakladka?.koordinat?.coordinates[1], dokladka.nakladka?.koordinat.coordinates[0]).Distance(new GeoPosition(data.waypointy[0]?.koordinat?.coordinates[1], data.waypointy[0]?.koordinat?.coordinates[0]));
                                  const distanceKam = new GeoPosition(dokladka.vykladka?.koordinat?.coordinates[1], dokladka.vykladka?.koordinat.coordinates[0]).Distance(new GeoPosition(data.waypointy[data.waypointy.length-1]?.koordinat?.coordinates[1], data.waypointy[data.waypointy.length-1]?.koordinat?.coordinates[0]));
                                  const wp: Waypoint[] = [];
                                  if (distanceOd > 10)
                                      wp.push(dokladka.nakladka);
                                  wp.push(...data.waypointy);
                                  if (distanceKam > 10)
                                      wp.push(dokladka.vykladka);

                                  data.waypointy = wp;
                              }
                          }}
                          customButtons={(edited, data) =>
                              [(data.state !== TrasaState.CALCULATED && data.state !== TrasaState.CALCULATING) && !exist(trasaData) && <FormButton key={"delete"}
                                          skipBlock
                                          type={"calculate"}
                                          onClick={() => {
                                              if (offerId) {
                                                loadablePart.reload();
                                              }
                                          }}>
                                  {t("Trasa.Calculate")}
                              </FormButton>,
                              routeOnly&&<FormButton key={"route"}
                                          skipBlock
                                          type={"calculate"}
                                          onClick={() => {
                                              if (offerId) {
                                                  setShowRouteOnly(false)
                                              }
                                          }}>
                                  {t("Kilometrovnik.DisplayRoute")}
                              </FormButton>
                          ]}
                          render={() =>
                              <>
                                  {data.state !== TrasaState.CALCULATED && !exist(trasaData) ?
                                      <Grid container direction={"column"} spacing={1} style={{padding: 0, marginBottom: '1rem'}}>
                                          {(data.state===TrasaState.WAITING || data.state===TrasaState.CALCULATING) ? <Grid item><LinearProgress className={classes.linearProgress} value={data?.progress ?? 0} variant="determinate"/></Grid> : undefined}
                                          <Grid item className={data?.state===TrasaState.ERROR ? classes.dialogAlert : classes.dialogInfo}>
                                              <Alert severity={data.state===TrasaState.ERROR ? "error" : "info"}>
                                                  <AlertTitle>{t(`Enumerations.TrasaState.${data.state}`)}</AlertTitle>
                                                  {data.state===TrasaState.ERROR && exist(data?.status) && <Grid item> {t(`Enumerations.RouteCalculationStatus.${data?.status}`)}</Grid>}
                                              </Alert>
                                          </Grid>
                                      </Grid> : undefined}
                                  <Grid container id={"drawer-container"} style={{ overflow: "hidden", position:"relative" }}>
                                      <Grid container>
                                          <FormField<FormMapOptions, Waypoint[]>
                                              name={"waypointy"}
                                              title={t("Default.Map")}
                                              type={FormInputType.Custom}
                                              showIcon={false}
                                              customComponent={FormMap}
                                              isTabbedContainer={true}
                                              customComponentOptions={{
                                                  converters: mapDataConverterWaypoints,
                                                  updateMapOnOpen: true,
                                                  inlineMap: true,
                                                  mapHeight: '65vh',
                                                  route: (i) => {
                                                      return data.getCalculationResultsAtIndex(i)?.trasaCalculationResult?.osrmData && extractRouteFromOsrmData(JSON.parse(data.getCalculationResultsAtIndex(i)?.trasaCalculationResult?.osrmData));
                                                  },
                                                  viewType: TrasaFormViewType.OFFER,
                                                  routeOnly: routeOnly,
                                                  trasaType: trasaType??TrasaType.NORMAL,
                                                  waypoints: (i: number) => {
                                                      return (i===2 && trasaType===TrasaType.PREJEZD) ? [prejezd.prejezdOd, prejezd.prejezdKam]:
                                                             (i===2 && trasaType===TrasaType.DOKLADKA) ? [dokladka.nakladka, dokladka.vykladka]:
                                                          undefined
                                                  },
                                                  extraRoutes: (i: number) => (i===0 && trasaType===TrasaType.PREJEZD) ? [
                                                      {
                                                          route: data.getCalculationResultAtIndex(1)?.osrmData && extractRouteFromOsrmData(JSON.parse(data.getCalculationResultAtIndex(1).osrmData)),
                                                          color: "blue"
                                                      },
                                                      {
                                                          route: data.getCalculationResultAtIndex(3)?.osrmData && extractRouteFromOsrmData(JSON.parse(data.getCalculationResultAtIndex(3).osrmData)),
                                                          color: "red"
                                                      },
                                                      {
                                                          route: data.getCalculationResultAtIndex(4)?.osrmData && extractRouteFromOsrmData(JSON.parse(data.getCalculationResultAtIndex(4).osrmData)),
                                                          color: "red"
                                                      },
                                                  ] :  (i===0 && trasaType===TrasaType.DOKLADKA) ? [
                                                      {
                                                          route: data.getCalculationResultAtIndex(1)?.osrmData && extractRouteFromOsrmData(JSON.parse(data.getCalculationResultAtIndex(1).osrmData)),
                                                          color: "blue"
                                                      },
                                                      {
                                                          route: data.getCalculationResultAtIndex(0)?.osrmData && extractRouteFromOsrmData(JSON.parse(data.getCalculationResultAtIndex(0).osrmData)),
                                                          color: "red",
                                                          dashArray: "10 15"
                                                      }
                                                  ] : undefined,
                                                  hideRoute: (i: number) => i===0 && (trasaType===TrasaType.PREJEZD || trasaType===TrasaType.DOKLADKA),
                                                  extraWaypoints: (i: number) =>  i===0 && trasaType===TrasaType.PREJEZD ? [
                                                      {
                                                          label: "P<sub>1</sub>",
                                                          waypoint: prejezd.prejezdOd
                                                      },
                                                      {
                                                          label: "P<sub>2</sub>",
                                                          waypoint: prejezd.prejezdKam
                                                      },
                                                  ]: (i===0 && trasaType===TrasaType.DOKLADKA) ? [
                                                      {
                                                          label: "N",
                                                          waypoint: dokladka.nakladka
                                                      },
                                                      {
                                                          label: "V",
                                                          waypoint: dokladka.vykladka
                                                      },
                                                  ]:undefined
                                              }}
                                          />
                                      </Grid>
                                  </Grid>
                              </>
                          }
                    /> : null
                }
              </>
        </MuiDetailTabModal>
    )
}
