import * as React from 'react';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { Form, FormButton, FormComponent, FormField } from '../raal_components/form/Form';
import { http } from '../../common/utils/HttpUtils';
import { Token } from '../model/Token';
import { getConfig } from '../../Config';
import { Mapper } from '../../common/utils/objectmapper/Mapper';
import { useTranslation } from 'react-i18next';
import { Button, CssBaseline, Grid, Link, Paper, useTheme, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { showSnack } from '../../common/component/SnackContainer';
import { Box } from '@mui/material';
import qs from 'qs';
import { FormStatus } from '../raal_components/form/Form.d';
import { AssetCache } from '../../AssetCache';
import Environment from '../Environment';
import { DynamicTitle } from "../raal_components/DynamicTitle";


type LoginForm = {
    username?: string,
    password?: string,
    newPassword?: string,
    captcha?: string,
    rememberMe?: boolean
}
type Callback = (token:Token)=>void
const useStyles = makeStyles(theme => ({
    image: {
        width: '12%',
    },
    form: {
        border: "1px solid #1976d2",
        borderRadius: "10px",
    },
    submit: {
        marginBottom: "0px!important",
        margin: theme.spacing(2, 0, 0, 2),
    },
    cancel: {
        margin: theme.spacing(2, 0, 0, 2),
    },
    link: {
        display: "block",
        textAlign: "right"
    },
    rememberMe: {
        margin: "20px",
    },
    titleInfo: {
        fontSize: "1em",
        fontWeight: 600,
        textAlign: "center",
        padding: "1em",
        paddingBottom: 0,
        margin: 0,
    },
    loginInfo: {
        "& h3": {
            fontSize:"1em",
            marginBottom: "1px",
            textTransform: "uppercase",
        },
        "& a": {
            color: theme.palette.type !== 'dark' ? "blue" : "#68b8ff",
            textDecoration: 'underline',
        },
        "& a:nth-of-type(2), span": {
            color: theme.palette.type !== 'dark' ? "#e1001b" : "#ff455c",
            fontWeight: 600,
            whiteSpace: "nowrap",
        },
    },
}));

const useProcessLogin = (onSuccess:Callback):[((form: LoginForm) => Promise<FormStatus.Custom | FormStatus.Skip>), boolean, boolean, string] => {
    const [captchaRequired, setCaptchaRequired] = useState(false)
    const [passwordChangeRequired, setPasswordChangeRequired] = useState(false)
    const [username, setUsername] = useState<string>(undefined)
    const {t} = useTranslation();

    const process = async(form:LoginForm) => {
        if(form == null){
            setCaptchaRequired(false);
            setPasswordChangeRequired(false);
            return FormStatus.Custom;
        }

        if (form.rememberMe) {
            document.cookie = `rememberMe=${form.rememberMe}; expires=${new Date(Date.now() + 2592000000).toUTCString()}; path=/`;
            document.cookie = `username=${form.username}; expires=${new Date(Date.now() + 2592000000).toUTCString()}; path=/`;
            document.cookie = `password=${form.newPassword ?? form.password}; expires=${new Date(Date.now() + 2592000000).toUTCString()}; path=/`;
        } else {
            Cookies.remove('rememberMe');
            Cookies.remove('username');
            Cookies.remove('password');
        }

        let isError = false;
        setUsername(form.username);
        try {
            const result = await http(`${getConfig().backendUrl}/oauth/token`, {
                method: "POST",
                body: qs.stringify(
                    {
                        ...form,
                        grant_type: "password"
                    }, {skipNulls: true, charset: "utf-8"}
                ),
                headers: {
                    'Authorization': `Basic ${getConfig().backendClientId}`,
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            });
            if(result.response.status === 200) {
                const token = new Mapper<Token>({constructor:Token}).readValue(result.json);
                onSuccess(token);
            } else {
                if(result.json.passwordChangeRequired){
                    setPasswordChangeRequired(true);
                    showSnack({title:t("PasswordChangeRequired"), severity:"warning", duration: 10e3 });
                    return FormStatus.Custom;
                }
                if(passwordChangeRequired){
                    if(result.json.validationErrors?.length > 0){
                        let errors = [];
                        for (const error of result.json.validationErrors) {
                            errors.push(t(error))
                        }
                        showSnack({title:errors.join("\n"), severity:"error", duration: 10e3 });
                    } else {
                        showSnack({title:t("UnexpectedError"), severity:"error", duration: 10e3 });
                    }
                    return FormStatus.Custom;
                }

                if(result.response.status === 400) {
                    setCaptchaRequired(result.json.captchaRequired);
                }
                isError = true;
            }
        } catch (e) {
            isError = true;
        }
        if(isError) {
            if(form.password.length === 5){
                showSnack({title:t("LoginFailedGeneratedPwd"), severity:"error", duration: 10e3 });
            } else {
                showSnack({title:t("LoginFailed"), severity:"error", duration: 10e3 });
            }

            return FormStatus.Custom;
        }
        return FormStatus.Skip;
    };
    return [process, captchaRequired, passwordChangeRequired, username];
};

export const LoginPage = ({setToken}:{setToken:Callback}) => {
    const {t} = useTranslation();
    const [formData, setFormData] = useState<any>({});
    const [attempt, setAttempt] = useState<number>(0);
    const classes = useStyles();
    const [process, captchaRequired, passwordChangeRequired, username] = useProcessLogin(setToken);
    const theme = useTheme();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [troubleLoggingIn, setTroubleLoggingIn] = useState(false);

    return (<>
            <DynamicTitle title={("Raaltrans").toUpperCase()} />
            <Grid container component="main" justifyContent="center">
				<Environment />
                <CssBaseline />
                <Grid item xs={12} sm={12} md={12}>
                    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" marginBottom="1rem" marginTop="5rem">
                        <img style={{minWidth: "80px"}} src={AssetCache.Image.LoginLogo} alt="Raaltrans logo" className={classes.image} />
                        <h2 className={classes.titleInfo}>{t("LoginPage.Info")}</h2>
                    </Box>
                </Grid>
                <Grid item xs={10} sm={8} md={6} lg={4} className={classes.form}>
                    <Box margin="2rem">
                        <Form<LoginForm> disableFocusForSaveButton={true}
                                         blockSaveUntilChange={false}
                                         data={formData}
                                         simpleLabel
                                         onSend={async(form:FormComponent<LoginForm>) => {
                                             const r = await process(form.data)
                                             setAttempt(attempt + 1)
                                             setFormData({
                                                 ...formData,
                                                 captcha: undefined
                                             })
                                             return r
                                         }}
                                         localization={t("FormLocalization", {returnObjects:true})}
                                         style={{marginBottom:"10px"}}
                        >
                            <div style={{ display: passwordChangeRequired ? 'none' : 'block' }}>
                                <FormField title={t("User.login")} name={"username"} type={"text"} required textFieldProps={{margin:"normal"}}
                                           getValue={(data) => {
                                               if (!data.username) {
                                                   data.username = Cookies.get('username');
                                               }
                                               return data.username;
                                           }}
                                           setValue={(data, fieldName, value) => {
                                               data.username = value;
                                           }}
                                />
                                <FormField
                                    title={t("User.Password")}
                                    name={"password"}
                                    type={passwordVisible ? "text" : "password"}
                                    required
                                    textFieldProps={{margin:"normal"}}
                                    togglePasswordVisibility={() => setPasswordVisible(!passwordVisible)}
                                    passwordVisible={passwordVisible}
                                    getValue={(data) => {
                                        if (!data.password) {
                                            data.password = Cookies.get('password');
                                        }
                                        return data.password;
                                    }}
                                    setValue={(data, fieldName, value) => {
                                        data.password = value;
                                    }}
                                />
                                <FormField title={t("LoginPage.RememberMe")} name={"rememberMe"} type={"checkbox"} className={classes.rememberMe}
                                   getValue={(data) => {
                                       if (!data.rememberMe) {
                                           data.rememberMe = Cookies.get('rememberMe');
                                       }
                                       return data.rememberMe;
                                   }}
                                   setValue={(data, fieldName, value) => {
                                       data.rememberMe = value;
                                   }}
                                />
                                {captchaRequired && !passwordChangeRequired && <>
                                    <FormField title={t("LoginPage.Captcha")} name={"captcha"} type={"text"} required textFieldProps={{margin:"normal"}} />
                                    <Paper variant="outlined"><Box padding={1}><img className="image-center" style={{minHeight: "50px"}} key={attempt} src={`${getConfig().backendUrl}/captcha?username=${encodeURIComponent(username)}&attempt=${attempt}&theme=${theme.palette.type}`} alt={t("LoginPage.Captcha")}/></Box></Paper>
                                    <Link className={classes.link} onClick={(e:any)=>{
                                        setAttempt(attempt + 1)
                                        setFormData({
                                            ...formData,
                                            captcha: undefined
                                        })
                                    }}>{t("LoginPage.GenerateNewCaptcha")}</Link>
                                </>}
                            </div>
                            {passwordChangeRequired && <div>
                                <FormField
                                    title={t("LoginPage.NewPassword")}
                                    name={"newPassword"}
                                    type={newPasswordVisible ? "text" : "password"}
                                    required
                                    textFieldProps={{margin:"normal"}}
                                    togglePasswordVisibility={() => setNewPasswordVisible(!newPasswordVisible)}
                                    passwordVisible={newPasswordVisible}
                                />
                                <FormField
                                    title={t("LoginPage.ConfirmPassword")}
                                    name={"confirmPassword"}
                                    type={confirmPasswordVisible ? "text" : "password"}
                                    required
                                    textFieldProps={{margin:"normal"}}
                                    togglePasswordVisibility={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                    passwordVisible={confirmPasswordVisible}
                                />
                            </div>}
                            <div className={"modal-buttons"}>
                                {passwordChangeRequired &&
                                    <Button className={classes.cancel} variant={"contained"} color={"secondary"}
                                            onClick={async () => {
                                                setFormData({});
                                                await process(null);
                                            }}
                                    >{t("Buttons.Cancel")}</Button>
                                }
                                <FormButton className={classes.submit}>{
                                    passwordChangeRequired ? t("Buttons.Confirm") : t("Buttons.Login")
                                }</FormButton>
                            </div>
                        </Form>
                        <Link href={"#"} onClick={(e:any)=>{
                            e.preventDefault();
                            setTroubleLoggingIn(!troubleLoggingIn);
                        }}>
                            <span>{t("LoginPage.TroubleLoggingIn")}</span>
                        </Link>
                        <Zoom in={troubleLoggingIn} style={{display: troubleLoggingIn ? 'block' : 'none'}}>
                            <Box className={classes.loginInfo}>
                                <h3>{t("LoginPage.RegularCustomersTitle")}</h3>
                                <div dangerouslySetInnerHTML={{__html: t("LoginPage.RegularCustomersInfo")}}/>
                                <h3>{t("LoginPage.NewCustomersTitle")}</h3>
                                <div dangerouslySetInnerHTML={{__html: t("LoginPage.NewCustomersInfo")}}/>
                            </Box>
                        </Zoom>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};