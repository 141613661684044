export const admin_firma = "admin/firma";
export const admin_firma_history = "admin/firma-history";
export const admin_provozovna = "admin/provozovna";
export const admin_provozovna_history = "admin/provozovna-history";

export const stiznost_nepohodlna_osoba = "admin/stiznost/nepohodlna-osoba";
export const stiznost_dluh_pripad = "admin/stiznost/dluh-pripad";
export const stiznost_dluh_pripad_soubor = "admin/stiznost/dluh-pripad/{pripadId}/soubor";
export const stiznost_dluh_nadpripad = "admin/stiznost/dluh-nadpripad";
export const stiznost_dluh_nadpripad_soubor = "admin/stiznost/dluh-nadpripad/{nadPripadId}/soubor";
export const stiznost_dluh_pripad_faktura = "admin/stiznost/dluh-pripad/{pripadId}/faktura";
export const stiznost_dluh_pripad_faktura_soubor = "admin/stiznost/dluh-pripad/{pripadId}/faktura/{fakturaId}/soubor";

export const uzivatel_soubor = "/user/{userId}/soubor";

export const report_text = "admin/report/text";