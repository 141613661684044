import {useTranslation} from "react-i18next";
import {Dial} from "../../../../raal_components/dial/Dial";
import {SbernaSluzbaPreprava, VPFilter, VPList} from "../../../../model/PrepravaVozidlo";
import {Preprava} from "../../../../model/Preprava";
import {ExtendedView} from "../_vp/VPExtendedView";
import {CISELNIK_DRUH} from "../prohlizeni/PrepravyCiselnikView";
import * as React from "react";
import {SbernaSluzbaTrasa} from "../../../../model/Trasa";
import {useCiselnikValues, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import numeral from "numeral";
import {
    exportableFieldsView,
    getExportableProps,
    sizeIntegerFormat,
    sizeViewFormat,
    useVPPdfLayout
} from "../_vp/PrepravaAVozidlaShared";
import {CheckMark} from "../../../../../common/component/CheckMark";
import {DateCell} from "../_vp/VPColumns";
import {PhoneNumberDial} from "../../../../../common/component/PhoneNumberDial";
import {formatPrice, truncateString} from "../../../../../common/utils/Util";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {TabHelper} from "../../../../raal_components/controller/TabHelper";
import {InvalDuvodPreprava} from "../../../../model/CommonTypes";
import {useAppContext} from "../../../../context/AppContext";
import {CiselnikTyp} from "../../../../model/Ciselnik";

function useViewColumns<T extends VPList>() {
    const {t} = useTranslation();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);

    return [() => [
        {
            title: t("SbernaSluzba.prepravaOrder"),
            field: "prepravaOrder",
            sorting: false
        },
        {
            title: t("Preprava.odkud"),
            field: "odkud",
            render: (data: T) => data.odkudHelper,
            sorting: false
        },
        {
            title: t("Preprava.kam"),
            field: "kam",
            render: (data: T) => data.kamHelper,
            sorting: false
        },
        {
            title: t("Preprava.AirDistanceShort"),
            field: "airDistance",
            render: (data: T) => data.airDistance && numeral(data.airDistance).format(sizeIntegerFormat),
            cellStyle: {minWidth: 70},
            headerStyle: {minWidth: 70},
            sorting: false
        },
        {
            title: t("RAALKratkyKody.N"),
            field: "naves",
            render: (data: T) => <CheckMark checked={data.naves}/>,
            sorting: false
        },
        {
            title: t("RAALKratkyKody.S"),
            field: "souprava",
            render: (data: T) => <CheckMark checked={data.souprava}/>,
            sorting: false
        },
        {
            title: t("RAALKratkyKody.J"),
            field: "jine",
            render: (data: T) => <CheckMark checked={data.jine}/>,
            sorting: false
        },
        {
            title: t("Preprava.delkaShort"),
            field: "delka",
            render: (data: T) => data.delka && numeral(data.delka).format(sizeViewFormat),
            cellStyle: {minWidth: 70},
            headerStyle: {minWidth: 70},
            sorting: false
        },
        {
            title: t("Preprava.vahaShort"),
            field: "vaha",
            render: (data: T) => data.vaha && numeral(data.vaha).format(sizeViewFormat),
            cellStyle: {minWidth: 70},
            headerStyle: {minWidth: 70},
            sorting: false
        },
        {
            title: t("Preprava.druhy"),
            field: 'druhy',
            render: (data: T) => druhyJoined(data.druhy),
            cellStyle: {minWidth: 120},
            headerStyle: {minWidth: 120},
            filterStyle: {minWidth: 120},
            sorting: false
        },
        {
            title: t("Preprava.adr"),
            field: 'adr',
            render: (data: T) => <CheckMark checked={data.adr}/>,
            sorting: false
        },
        {
            title: t("Preprava.verejnaPozn"),
            field: 'verejnaPozn',
            sorting: false
        },
        {
            title: t("Default.Datum"),
            field: "datum",
            cellStyle: {minWidth: 120},
            headerStyle: {minWidth: 120},
            render: (data: T) => <DateCell data={data}/>,
            sorting: false
        },
        {
            title: t("Default.Telefon"),
            field: "rychlyKontakt",
            render: (data: T) => <PhoneNumberDial hideIcon phoneNumber={data.rychlyKontakt}/>,
            sorting: false
        },
        {
            title: t("Preprava.cena"),
            field: "cena",
            render: (data: T) => data.cena && data.currency.currencyCode ? formatPrice(data.cena, data.currency.currencyCode) : data.cena ?? '',
            cellStyle: {textAlign: "right"},
            sorting: false
        },
        {
            title: t("Provozovna.Title"),
            field: 'provozovna',
            render: (data: VPList) => truncateString(`${data.provozovna} (${data.firma})`, 20),
            sorting: false
        },
        {
            title: t("PVI.Modified"),
            field: 'modifiedOn',
            render: (data: T) => data.modifiedOn?.format("L LT"),
            sorting: false
        },
        {
            title: t("Preprava.paletyShort"),
            field: 'palety',
            cellStyle: {minWidth: 80},
            headerStyle: {minWidth: 80},
            sorting: false
        }
    ].filter(i => Boolean(i)) as Column<VPList<any>>[]];
}

export interface SbernaSluzbaPrepravyDialProps {
    sbernaSluzbaTrasa: SbernaSluzbaTrasa;
    onSuccess?: VoidFunction;
    prepravyCleared?: VoidFunction;
    processing: Boolean;
}

export function SbernaSluzbaPrepravyDial(props: SbernaSluzbaPrepravyDialProps) {
    const {t} = useTranslation();
    const [cols] = useViewColumns();
    const {sbernaSluzbaTrasa} = props;
    const [showConfirm] = useConfirmDialog();
    const endpoint = `user/sberna-sluzba/${sbernaSluzbaTrasa.id}/prepravy`;
    const {user} = useAppContext();
    const {pdfLayout} = useVPPdfLayout(user);
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);

    return <Dial<SbernaSluzbaPreprava, VPFilter, Preprava>
        logActivity
        config={{
            lockSupport: {enabled: false},
            exportConfig: {
                exportable: true,
                exportDetailOnly: true,
                endpoint: endpoint,
                fileName: "export_sberna_sluzba",
                translationPrefix: ['Preprava', 'User'],
                formats: ["pdf", "xls", "csv", "xml"],
                exportAllFormats: ["csv"],
                exportableProps: getExportableProps(exportableFieldsView),
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod
                            }
                        }
                    }
                ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "druhy",
                        format: (data) => {
                            return druhyJoined(data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            overflowHidden: true,
            tableTitle: t("Dials.Prepravy"),
            endpoint: endpoint,
            endpointDetail: `user/prepravaview`,
            clazz: SbernaSluzbaPreprava,
            filtering: false,
            hideAddNewActions: true,
            columns: cols(),
            hideDefaultDummyAction: false,
            deleteRowWithoutQuestion: true,
            initialPageSize: 10,
            rowFiltering: false,
            options: {
                rowStyle: (data: any) => {
                    if (data.klasifikace?.barva) {
                        return {backgroundColor: data.klasifikace?.barva}
                    }
                }
            },
            beforeAction: async (action, data, continuation) => {
                if(action==="delete") {
                    if(props.processing) {
                        showSnack({title: t("SbernaSluzba.processing"), severity: "info"});
                    } else {
                        if (data[0].prepravaOrder === 0) {
                            showConfirm({
                                body: t("SbernaSluzba.PrepravaRemoveConfirm"),
                                onConfirm: async () => {
                                    await continuation();
                                    props.prepravyCleared && props.prepravyCleared();
                                }
                            });
                        } else {
                            await continuation();
                        }
                    }
                }
            }
        }}
        isDetailReadOnly={true}
        isModal
        hideSaveButton
        hideNewButtonOnEdit
        hideEditButton
        clazzDetail={Preprava}
        crudConfig={{editEnabled: false, removeEnabled: true, addEnabled: false}}
        layoutDetail={() => <TabHelper<Preprava> render={(data) =>
            <>
                <ExtendedView ciselnikTyp={CISELNIK_DRUH} data={data} admin={false} archive={false} isSbernaSluzba />
            </>}/>}
        modalHeaderName={() => t("Dials.Prepravy")}
        filterClazz={VPFilter}
        onSuccess={() => props.onSuccess()}
    />;
}
