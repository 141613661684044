import React, {forwardRef, ReactNode} from "react";
import {Box, Menu, MenuItem,} from "@material-ui/core";
import {useStyleContext} from "./context/ThemeModeContext";

import MoreIcon from '@material-ui/icons/MoreVert';
import {HeaderWebsocketIndicator} from "./raal_components/Header/WebsocketIndicator";
import {HeaderModeSwitcherButton} from "./raal_components/Header/ThemeButton";
import {HeaderHelpButton} from "./raal_components/Header/HelpButton";
import {HeaderThunderNewsButton} from "./raal_components/Header/Bleskovky";
import {HeaderLanguageButton} from "./raal_components/Header/LanguageButton";
import {HeaderUserButton} from "./raal_components/Header/UserButton";
import {HeaderLogoutButton} from "./raal_components/Header/LogoutButton";
import {HeaderDropDown} from "./raal_components/Header/HeaderAction";


const Buttons = forwardRef<{}, {mobile?:boolean, wrap:(node:ReactNode)=>ReactNode}>(({wrap, mobile=false}, ref) => {
    return (
        <>
            {wrap(<HeaderWebsocketIndicator showText={mobile} />)}
            {wrap(<HeaderModeSwitcherButton showText={mobile}/>)}
            {!mobile&&wrap(<HeaderThunderNewsButton showText={mobile}/>)}
            {wrap(<HeaderHelpButton showText={mobile}/>)}
            {wrap(<HeaderLanguageButton showText/>)}
            {!mobile&&wrap(<HeaderUserButton showText/>)}
            {!mobile&&wrap(<HeaderLogoutButton showText/>)}
        </>
    );
});


export function Header() {
    const {classes} = useStyleContext();

    return (
        <>
            <div className={classes.sectionDesktop}>
                <Buttons wrap={node => (<Box className={classes.headerLink}>{node}</Box>)}/>
            </div>
            <div className={classes.sectionMobile}>
                <HeaderThunderNewsButton showText={false}/>
                <HeaderUserButton showText/>
                <HeaderLogoutButton showText={false}/>
                <HeaderDropDown icon={<MoreIcon />} render={()=>(
                    <Buttons mobile wrap={node =><MenuItem onClick={event => {}}>{node}</MenuItem>} />
                )} popupComponent={Menu} />
            </div>
        </>
    );
}