export class Hodnota {
    hodnota: string;
    nazev: string;
    pomocnaData?: string;
    typHodnoty?: string;
}

export class Template {
    id?: number;
    nazevFiltru: string;
    vychozi: boolean;
    typUlozenehoFiltru: string;
    hodnoty: Hodnota[];
}

export class SimpleTemplate {
    id: number;
    nazevFiltru: string;
    vychozi?: boolean
}
