import React, {PropsWithChildren, ReactNode} from "react";
import {useStyleContext} from "../../../web/context/ThemeModeContext";
import {Card, CardContent, CardHeader, Grid, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";

interface FormSectionCardProps {
    title?: string;
    avatar?: ReactNode;
}

export function FormSectionCard(props: PropsWithChildren<FormSectionCardProps>) {
    const {classes} = useStyleContext();

    return <Grid item xs={12}>
        <Card elevation={4} className={classes.inputSectionBackground}>
            <CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
                {props.title && <><CardHeader title={<Typography variant={"h6"}>{props.title}</Typography>} avatar={props.avatar} style={{padding: 0}}/><Divider className={clsx(classes.marginTop1, classes.marginBottom2)}/></>}
                <Grid container spacing={1}>
                    {props.children}
                </Grid>
            </CardContent>
        </Card>
    </Grid>
}
