import {CodeBookForm} from "../../../../raal_components/controller/CodebookForm";
import React, {useEffect, useRef, useState} from "react";
import {Grid} from "@material-ui/core";
import {KilometrovnikHlaseniUser} from "../../../../model/KilomentrovnikHlaseni";
import {FormField, FormInputType, useForm} from "../../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {MapReport, MapReportExposed} from "../../../../../common/component/map/MapReport";
import {exist} from "../../../../../common/utils/Util";
import {FieldError} from "../../../../../common/component/form/ValidationError";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {useAppContext} from "../../../../context/AppContext";
import {LatLng} from "leaflet";
import {OsmPlace} from "../../../../../common/component/map/Nominatim";
import {hasLicenseForPlace} from "../../../../../common/logic/permissions-logic";
import {FormNominatim, FormNominatimOptions} from "../../../../../common/component/form/FormNominatim";
import {StandaloneField} from "../../../../../common/component/form/StandaloneField";


export function KilometrovnikHlaseniForm() {
    const {t} = useTranslation();
    const [state, setState] = useState(new KilometrovnikHlaseniUser())
    const [showConfirm] = useConfirmDialog();

    const setOsrmId = (id: number) => {
        setState({...state, osrmId: id})
    }

    return <CodeBookForm data={state} clazz={KilometrovnikHlaseniUser} url={'user/kilometrovnik-hlaseni'} render={() =>
    {
        return <KilometrovnikHlaseniFormLayout setOsrmId={setOsrmId}/>;
    }
    }
                         onSuccess={() => {
                             showConfirm({
                                 title: "",
                                 hideCancel: true,
                                 body: t("KilometrovnikHlaseni.reportSent"),
                                 buttons: {
                                     confirm: t("ok")
                                 }
                             });
                             setState(new KilometrovnikHlaseniUser());
                         }
                         }
                         validate={(data: KilometrovnikHlaseniUser)  => {
                             if (!exist(data.osrmId)) {
                                 return [FieldError.Create(t("Map.noHighwaySelected"), false, "report")];
                             }
                             return null;
                         }}
    />
}

interface KilometrovnikHlaseniFormLayoutProps {
    setOsrmId: (id: number) => void
}

export function KilometrovnikHlaseniFormLayout(props: KilometrovnikHlaseniFormLayoutProps) {
    const {t} = useTranslation();
    const {data} = useForm<KilometrovnikHlaseniUser>();
    const {user} = useAppContext();
    const [f, forceUpdate] = useState(false);
    const [position, setPosition] = useState<LatLng>(new LatLng(50.0596288, 14.446459273258));
    const [zoom, setZoom] = useState(null);
    const reportRef = useRef<MapReportExposed>()
    const onSearchPicked = (place:OsmPlace) => {
        if (!place.address) return;
        if (hasLicenseForPlace(user, place)) {
            if (place && place.lat && place.lon) {
                setPosition(new LatLng(place.lat, place.lon));
                setZoom(18)
            }
        } else {
            showSnack({title: t("Map.noLicense"), severity:"error"});
            forceUpdate(!f);
        }
    };

    const waypointChanged = (id: number) => {
        props.setOsrmId(id);
    }

    useEffect(() => {
        if (!data.osrmId) reportRef.current?.clearMap()
    }, [data])

    return <Grid container spacing={2} direction="column">
        <Grid item container spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
                <StandaloneField<FormNominatimOptions>
                    type={FormInputType.Custom}
                    customComponent={FormNominatim}
                    customComponentOptions={{
                        disableClearable: true,
                        onChange: (osmPlace) => onSearchPicked(osmPlace)}}
                    title={t("Map.SearchNearPlace")}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} style={{marginLeft: 10, marginRight: 10, marginBottom: 10, marginTop: 5}}>
                <MapReport waypointChanged={waypointChanged} defaultPosition={position} defaultZoom={zoom} reportRef={reportRef} />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <FormField title={t("KilometrovnikHlaseni.report")} required type={FormInputType.TextArea} name="report"/>
            </Grid>
        </Grid>
    </Grid>
}
