import {JsonProperty} from "../../common/utils/objectmapper/Mapper";


export enum ReportLanguage {"cs"="cs", "sk"= "sk", "en"="en", "de"="de", "pl" = "pl"}

export class ReportTextItem {
    language: string;
    textValue: string;

    static of(language: string, textValue: string): ReportTextItem {
        const i = new ReportTextItem();
        i.language = language;
        i.textValue = textValue;
        return i;
    }
}

export class ReportText {
    id?: number;
    textKey?: string;
    @JsonProperty({type:{isArray:true, clazz:ReportTextItem}})
    texts?: ReportTextItem[]
}
