import {useTranslation} from "react-i18next";
import {FormPartBox} from "./FormPartBox";
import {Button, Grid} from "@material-ui/core";
import React, {PropsWithChildren} from "react";
import {useNetworkAction} from "../../../../../common/component/hooks/SharedHooks";
import {isInzerat, isInzeratViewDetail, isPreprava, isVozidlo} from "../_vp/AssertPredicates";
import {Preprava} from "../../../../model/Preprava";
import {Vozidlo} from "../../../../model/Vozidlo";
import {Inzerat, InzeratViewDetail} from "../../../../model/Inzerat";
import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import {Mapper} from "../../../../../common/utils/objectmapper/Mapper";
import {cloneClassObject} from "../../../../../common/utils/Util";
import {useHistoryCustom} from "../../../../raal_components/controller/NavigationHelper";

export type OfferDataType = Preprava | Vozidlo | InzeratViewDetail | Inzerat

export interface OfferArchiveActionsPartProps<T> {
    data: OfferDataType;
    restoreFunction?: (data: T) => Promise<boolean>;
}

export function OfferArchiveActionsPart<T>(props: PropsWithChildren<OfferArchiveActionsPartProps<T>>) {
    const {t} = useTranslation();
    const {protectedCall} = useNetworkAction();
    const {fetch: fetchPreprava} = useFetchCustom<Preprava, string>({endpoint: arg => `user/preprava-archiv/${arg}`}, undefined, Preprava);
    const {fetch: fetchVozidlo} = useFetchCustom<Vozidlo, string>({endpoint: arg => `user/vozidlo-archiv/${arg}`}, undefined, Vozidlo);
    const {fetch: fetchInzerat} = useFetchCustom<InzeratViewDetail, string>({endpoint: arg => `user/inzerat-archiv/${arg}`}, undefined, InzeratViewDetail);
    const {replace} = useHistoryCustom();
    const prepravaMapper = new Mapper<Preprava>({constructor:Preprava as {new(): Preprava}});
    const vozidloMapper = new Mapper<Vozidlo>({constructor:Vozidlo as {new(): Vozidlo}});
    const inzeratViewMapper = new Mapper<InzeratViewDetail>({constructor:InzeratViewDetail as {new(): InzeratViewDetail}});
    const inzeratMapper = new Mapper<Inzerat>({constructor:Inzerat as {new(): Inzerat}});

    return <>
        <FormPartBox title={t("Actions")}>
            <Grid item container spacing={1}>
                <Grid item>
                    <Button variant={"contained"} color={"primary"} onClick={async () => {
                        if (isPreprava(props.data)) {
                            await protectedCall(async () => {
                                const reloadData = await fetchPreprava({arg: props.data.id.toString()});
                                const data = cloneClassObject(reloadData);
                                data.id = undefined;
                                data.datOd = undefined;
                                data.datDo = undefined;
                                data.invalDuv = undefined;
                                data.pocetEditaci = 0;
                                replace("/zadat/prepravy/new", {
                                    entity: prepravaMapper.writeValueAsString(data as Preprava, {springSupport: false})
                                });
                            });
                        } else if (isVozidlo(props.data)) {
                            await protectedCall(async () => {
                                const reloadData = await fetchVozidlo({arg: props.data.id.toString()});
                                const data = cloneClassObject(reloadData);
                                data.id = undefined;
                                data.datOd = undefined;
                                data.datDo = undefined;
                                data.invalDuv = undefined;
                                data.pocetEditaci = 0;
                                replace("/zadat/volnevozy/new", {
                                    entity: vozidloMapper.writeValueAsString(data as Vozidlo, {springSupport: false})
                                });
                            });
                        } else if (isInzeratViewDetail(props.data)) {
                            await protectedCall(async () => {
                                const reloadData = await fetchInzerat({arg: props.data.id.toString()});
                                const data = cloneClassObject(reloadData);
                                data.id = undefined;
                                data.invalDuv = undefined;
                                data.pocetEditaci = 0;
                                replace("/zadat/inzerce/new", {
                                    entity: inzeratViewMapper.writeValueAsString(data as InzeratViewDetail, {springSupport: false})
                                });
                            });
                        } else if (isInzerat(props.data)) {
                            await protectedCall(async () => {
                                const reloadData = await fetchInzerat({arg: props.data.id.toString()});
                                const data = cloneClassObject(reloadData);
                                data.id = undefined;
                                data.invalDuv = undefined;
                                data.pocetEditaci = 0;
                                replace("/zadat/inzerce/new", {
                                    entity: inzeratMapper.writeValueAsString(data as Inzerat, {springSupport: false})
                                });
                            });
                        }
                    }}>{t("RestoreRecordShort")}</Button>
                </Grid>
                {props.children}
            </Grid>
        </FormPartBox>
    </>;
}
