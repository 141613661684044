import React, {ReactElement} from 'react';
import { Avatar, createStyles, Grid, ListItemAvatar, ListItemText, Theme, Tooltip, useMediaQuery } from '@material-ui/core';
import {isMobile} from 'react-device-detect';
import Phone from '@material-ui/icons/Phone';
import {SpeedDial, SpeedDialAction} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import {invoke} from "../utils/Invoke";
import { AccountBalance, ContactPhone, PhoneForwarded, PhoneInTalk, Sms, WhatsApp } from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import { pushNotificationCall } from '../../web/MobileApp';
import { useAppContext } from '../../web/context/AppContext';
import { isMobileDevice } from '../utils/Util';

const useStylesIcon = makeStyles((theme:Theme) =>
    createStyles({
        phoneNumberDial: {
            '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
                left: theme.spacing(0)
            },
            '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
                left: theme.spacing(0)
            }
        },
        phoneNumberDialRelative: {
            position: "relative"
        },
        actions: {
            position: 'absolute',
            top: "-3.0rem",
            // left: "10rem",
			left: "2rem",
			zIndex: 100
        },
        actionsMobile: {
            flexWrap: "wrap",
            width: "10rem",
            position: 'absolute',
            top: "-4rem",
            left: "2rem",
            zIndex: 100
        },
        fab: {
            backgroundColor: "#2eac00",
            '&:hover': {
                backgroundColor: "#2eac00"
            },
            width: "2.5rem",
            height: "2.5rem",
        },
        root: {
            zIndex: 1
        },
        phoneItem: {
            width: "3.5rem",
            position: "relative"
        },
        phoneNumberContainer: {
            position: "relative"
        },
        viber: {
            textDecoration: "none !important",
            color: "rgba(0, 0, 0, 0.54) !important",
            width: 24,
            height: 24,
            zIndex: 50
        },
        iconColor: {
            color: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50]
        }
    }),
);

const useStylesText = makeStyles((theme) =>
    createStyles({
        fab: {
            color: "#1976d2",
            textDecoration: "underline",
            width: "auto",
            height: "auto",
            backgroundColor: "transparent",
            '&:hover': {
                backgroundColor: "transparent",
                color: "#1976d2",
                textDecoration: "underline",
                border: 0,
                boxShadow: "none"
            },
            '&:active': {
                backgroundColor: "transparent",
                color: "#1976d2",
                textDecoration: "underline",
                border: 0,
                boxShadow: "none"
            },
            border: 0,
            boxShadow: "none",
            zIndex: 5,
            transition: "none !important"
        },
        actions: {
            position: 'absolute',
            left: "5.5rem",
            zIndex: 10
        },
        phoneItem: {
            position: "relative"
        },
        iconColor: {
            color: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50]
        }
    }),
);

type Button = {
    text: string,
    icon: ReactElement,
    call: () => void
}
export const PhoneNumberDial = (
    {phoneNumber = '-', showDials = true, hideIcon = false, hidePhoneNumber = false, tableVariant}
        :
        {phoneNumber?:string, showDials?: boolean, hideIcon?: boolean, hidePhoneNumber?: boolean, tableVariant?: boolean}) => {
    const {t} = useTranslation();
	const {user} = useAppContext();
    const classes = useStylesIcon();
    const classesText = useStylesText();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const generateDesktopLink = (number: string) => {
        return `viber://chat?number=${encodeURIComponent(
            number.replace(/[^0-9+]/g, '')
        )}`
    }
    /* not used for now
    const generateMobileLink = (number: string) => {
        return `viber://add?number=${encodeURIComponent(number.replace(/\D/g, ''))}`
    }*/


    const buttons: Button[] = [
        {text: t("Contacts.Call"), icon: <PhoneForwarded/>, call: () => {
            window.open("tel:" + phoneNumber, '_self');
        }},
        {text: t("Contacts.SMS"), icon: <Sms />, call: () => {
            window.open("sms:" + phoneNumber, '_self');
        }},
        {text: t("Contacts.WhatsApp"), icon: <WhatsApp />, call: () => {
            window.open("https://wa.me/" + phoneNumber.substr(1), '_blank');
        }},
        {text: t("Contacts.Viber"), icon: <PhoneInTalk />, call: () => {
            window.location.href = generateDesktopLink(phoneNumber);
        }}
    ];

	const phoneNumberCall = (e: MouseEvent | any) => {
		// Když má uživatel zaregistrované telefonní číslo, tak má i token. A tomhle případě rovnou poslat push notifikaci pro vytočení tel. čísla na mobilu.
		if (user.fcmToken && !isMobile) {
			e.preventDefault();
			(async () => {
				try {
					await pushNotificationCall(phoneNumber);
				} catch (e) {
					console.error("Failed to execute async function", e);
				}
			})();
			e.stopPropagation();
		}
	}

    // Kvoli spravnemu fungovaniu zIndexov v tabulke vraciame odlisne JSX pre tabulku a pre Detail
    // ktore nie su obalene vo flexboxe (flexbox vytvara novy stacking context)
    if (tableVariant) {
        return (
            <Grid>
                <Grid item>
                    <Grid className={classesText.phoneItem}>
                        {!hideIcon && <ListItemAvatar><Avatar className={"avatar-contact"}><Phone/></Avatar></ListItemAvatar>}
                        <SpeedDial
                            ariaLabel="phone"
                            className={showDials ? classes.phoneNumberDial : classes.phoneNumberDialRelative}
                            classes={{
                                root: classes.root,
                                actions: isDesktop || hideIcon ? hideIcon ? classesText.actions : classes.actions : classes.actionsMobile,
                                fab: classesText.fab
                            }}
                            icon={
                                <Grid item>
                                    <Grid className={classes.phoneNumberContainer}>
                                        {!hideIcon &&
                                            <ListItemText>
                                                <a href={`tel.:${phoneNumber}`} onClick={(e: any) => phoneNumberCall(e)}>{phoneNumber}</a>
                                            </ListItemText>
                                        }
                                        {hideIcon &&
                                            <span>
											<a href={`tel.:${phoneNumber}`} onClick={(e: any) => phoneNumberCall(e)}>{phoneNumber}</a>
										</span>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            onClose={handleClose}
                            onOpen={(_, reason) => {
                                if (reason === 'focus') {
                                    return
                                }
                                setOpen(true)
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                phoneNumberCall(e);
                                handleOpen();
                                e.stopPropagation();
                            }}
                            open={open}
                            direction={"right"}>
                            { showDials && buttons.map((btn, index) => (
                                <SpeedDialAction
                                    title={btn.text}
                                    key={index}
                                    icon={btn.icon}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleClose();
                                        invoke(btn.call)
                                    }}
                                />
                            ))}
                        </SpeedDial>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container justifyContent={"flex-start"} alignItems={"center"}>
            <Grid item >
                <Grid container justifyContent={"flex-start"} alignItems={"center"} className={classesText.phoneItem}>
					{!hideIcon && <ListItemAvatar><Avatar className={"avatar-contact"}><Phone/></Avatar></ListItemAvatar>}
                    <SpeedDial
                        ariaLabel="phone"
                        className={showDials ? classes.phoneNumberDial : classes.phoneNumberDialRelative}
                        classes={{
                            root: classes.root,
                            actions: isDesktop || hideIcon ? hideIcon ? classesText.actions : classes.actions : classes.actionsMobile,
                            fab: classesText.fab
                        }}
                        icon={
							<Grid item>
								<Grid container alignItems={"center"} className={classes.phoneNumberContainer}>
									{!hideIcon &&
										<ListItemText>
											<a href={`tel.:${phoneNumber}`} style={{userSelect: "text"}} onClick={(e: any) => phoneNumberCall(e)}>{phoneNumber}</a>
										</ListItemText>
									}
									{hideIcon &&
										<span>
											<a href={`tel.:${phoneNumber}`} style={{userSelect: "text"}} onClick={(e: any) => phoneNumberCall(e)}>{phoneNumber}</a>
										</span>
									}
								</Grid>
							</Grid>
						}
                        onClose={handleClose}
                        onOpen={(_, reason) => {
                            if (reason === 'focus') {
                                return
                            }
                            setOpen(true)
                        }}
                        onClick={(e) => {
                            e.preventDefault();
							phoneNumberCall(e);
							handleOpen();
                            e.stopPropagation();
                        }}
                        open={open}
                        direction={"right"}>
                        { showDials && buttons.map((btn, index) => (
                            <SpeedDialAction
                                title={btn.text}
                                key={index}
                                icon={btn.icon}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleClose();
                                    invoke(btn.call)
                                }}
                            />
                        ))}
                    </SpeedDial>
                </Grid>
            </Grid>
        </Grid>
    )
}
