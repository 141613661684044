import {Grid} from "@mui/material";
import {FormPartBox} from "../../user/prohlizeni/FormPartBox";
import {useTranslation} from "react-i18next";
import {FormField, FormInputType, useForm, useFormData} from "../../../../raal_components/form/Form";
import * as React from "react";
import {useCallback, useRef} from "react";
import {
    useEnumeratedSelect,
    useProvozovnaFullSelect,
    useStatSelectAsAutocomplete
} from "../../../../raal_components/SelectOptions";
import {DluhPripad, DluhPripadStav} from "../../../../model/Stiznosti";
import {Button, Divider, Typography} from "@material-ui/core";
import moment from "moment";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import {SectionLine} from "../../../../raal_components/parts/SectionLine";
import {Provozovna, Stav} from "../../../../model/Provozovna";
import {LetterComponent, LetterComponentExposed, LetterTypeEnum} from "./LetterComponent";
import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {ChangeEntityStateButton, useChangeStateAction} from "./state-change-action";
import {NadpripadActionEnum, useNadpripadActions} from "./nadpripad-actions";
import {exist} from "../../../../../common/utils/Util";
import {FormFieldExposed} from "../../../../raal_components/form/FormField";
import {useCodeBookDetailContextNew} from "../../../../raal_components/controller/CodeBookDetail";

enum ProvozovnaType {
    Dluznik, Veritel
}

export function DluhPripadLayoutForm() {
    const {t} = useTranslation();
    const statSelect = useStatSelectAsAutocomplete();
    const dluhPripad = useFormData<DluhPripad>();
    const {classes} = useStyleContext();
    const stavSelect = useEnumeratedSelect(DluhPripadStav, "Enumerations.DluhPripadStav", "number")
    const provozovnaSelect = useProvozovnaFullSelect({doNotFilterByLabel: true, autoOpenDisabled: true, isClearable: true});
    const modalRef = useRef<LetterComponentExposed>()
    const stavProvozovnySelect = useEnumeratedSelect(Stav, "Enumerations.Stav", "string", {isClearable:true});
    const form = useForm<DluhPripad>();
    const {fetch} = useFetchCustom({endpoint: 'admin/stiznost/dluh-pripad/any/find/existing-pripad-dle-provozoven'})
    const [showConfirm] = useConfirmDialog();
    const provozovnaDluznikRef = useRef<FormFieldExposed>();
    const provozovnaVerRef = useRef<FormFieldExposed>();
    const {edited} = useCodeBookDetailContextNew<DluhPripad>();
    const {NadpripadButton, showModal, navigateToNadpripad} = useNadpripadActions('admin/stiznost/dluh-nadpripad', dluhPripad.id);
    // Zobrazení potvrzovací hlášky
    const confirmation = (provozovnaType: ProvozovnaType, pripady?: DluhPripad[]) => {
        if (pripady?.length !== 0) {
            const pripadyIds = pripady.map(p => `<a onclick="window.open(this.href,'_blank');return false;" href='./${p.id.toString()}'>${p.id.toString()}</a>`).join(',');
            showConfirm({
                htmlBody: t("DluhPripad.PripadExist").replace('{pripadyIds}', pripadyIds),
                onCancel: () => {
                    if (provozovnaType === ProvozovnaType.Dluznik) {
                        provozovnaDluznikRef.current?.clearValue();
                    } else {
                        provozovnaVerRef.current?.clearValue();
                    }
                },
                title: t("DluhPripad.PripadExistTitle"),
                buttons: {
                    cancel: t("Default.No"),
                    confirm: t("Default.Yes")
                }
            });
        }
    }

    // Kontrola existence totžného případu
    const checkPripadExist = useCallback((provozovnaType: ProvozovnaType) => {
        if (dluhPripad?.provozovnaDluznika && dluhPripad?.provozovnaVer) {
            fetch({
                params:{provozovnaDluznikaId: dluhPripad.provozovnaDluznika.id, provozovnaVeriteleId: dluhPripad.provozovnaVer.id}
            }).then(res => {
                confirmation(provozovnaType, res as DluhPripad[]);
            }).catch(() => {
                showSnack({title: t("UnexpectedError"), severity: "error"});
            });
        }
        // eslint-disable-next-line
    },[dluhPripad, t, fetch])

    const changePripadStateAction = useChangeStateAction(`admin/stiznost/dluh-pripad/${dluhPripad.id}/action/`)
    const ChangeStateButton = (props: { action: string }) => <ChangeEntityStateButton {...props} changeStateAction={changePripadStateAction} labelKeyPrefix="DluhPripad.StavAction."/>

    const canCreateNadpripad = exist(dluhPripad.id) && !exist(dluhPripad.nadpripad?.id) && (dluhPripad.stav === DluhPripadStav.NEPOTVRZENY ||dluhPripad.stav === DluhPripadStav.NEUHRAZENY || dluhPripad.stav === DluhPripadStav.KE_SCHVALENI)

    const provozovnyLink = (provozovna: Provozovna, provozovny: Provozovna[]) => {
        const array = []
        array.push(`<a style='color: ${provozovna.stav === Stav.JEDE ? 'green' : 'red'}' onclick="window.open(this.href,'_blank');return false;" href='/admin/provozovny/${provozovna.id.toString()}'>${provozovna.kod.toString()}</a>`);
        array.push(...provozovny.filter(p => p.id !== provozovna.id).map((p: Provozovna) => `<a style='color: ${p.stav.toString() === 'JEDE' ? 'green' : 'red'}' onclick="window.open(this.href,'_blank');return false;" href='/admin/provozovny/${p.id.toString()}'>${p.kod.toString()}</a>`));
        return array.join(', ');
    }

    return <Grid container spacing={1} direction="column">
        <LetterComponent modalRef={modalRef} entity={dluhPripad} changeMainFocus={(state) => form.isChildFormFocused = state}/>
        {edited&&<Grid item xs>
            <FormPartBox title="">
                <Grid container justifyContent={"left"}>
                    <Grid item>
                        {(dluhPripad?.faktury && dluhPripad.faktury.length !== 0 && (dluhPripad.stav === DluhPripadStav.NEPOTVRZENY || dluhPripad.stav === DluhPripadStav.KE_SCHVALENI)) && (
                           <Button variant={"contained"} color={"primary"} type={"button"} style={{ margin: '0.3rem' }}
                                   onClick={() => modalRef.current.showModal(LetterTypeEnum.Veritel)}>{t("DluhPripad.GenerovatDopisVer")}</Button>
                        )}
                        {(dluhPripad.stav === DluhPripadStav.NEPOTVRZENY || dluhPripad.stav === DluhPripadStav.NEUHRAZENY) && (
                           <Button variant={"contained"} color={"primary"} type={"button"} style={{ margin: '0.3rem' }}
                                   onClick={() => modalRef.current.showModal(LetterTypeEnum.Dluznik)}>{t("DluhPripad.GenerovatDopisDluznik")}</Button>
                        )}
                        {(dluhPripad.stav !== DluhPripadStav.NEPOTVRZENY && dluhPripad.stav !== DluhPripadStav.KE_SCHVALENI && dluhPripad.stav !== DluhPripadStav.NEUHRAZENY) &&
                            <ChangeStateButton action="otevrit" />
                        }
                        {canCreateNadpripad && <NadpripadButton
                            action={NadpripadActionEnum.NEW}
                            labelKeyPrefix={"DluhNadpripad"}
                            changeStateAction={(action, labelKeyPrefix) => showModal(action, labelKeyPrefix)}/>
                        }
                        {exist(dluhPripad.id) && exist(dluhPripad.nadpripad?.id) && <NadpripadButton
                            action={NadpripadActionEnum.NAVIGATE}
                            labelKeyPrefix={"DluhNadpripad"}
                            changeStateAction={() => navigateToNadpripad(dluhPripad.nadpripad.id)}/>
                        }
                        {dluhPripad.stav === DluhPripadStav.NEUHRAZENY && (
                            <>
                                <ChangeStateButton action="uzavrit" />
                                <ChangeStateButton action="vyresitBezUhrady" />
                                <ChangeStateButton action="promlcet" />
                                <ChangeStateButton action="zamitnout" />
                            </>
                        )}
                    </Grid>
                </Grid>
            </FormPartBox>
        </Grid>}
        <Grid item xs>
            <FormPartBox title={t("DluhPripad.Dluznik")}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} lg={4}>
                        <FormField
                            name="provozovnaDluznika"
                            title={t("Firma.Provozovna")}
                            type={"select"}
                            required
                            disabled={edited}
                            selectProps={provozovnaSelect}
                            forceUpdateWhenDataChanged={[]}
                            fieldRef={provozovnaDluznikRef}
                            onWillChange={(field, newValue) => {
                                dluhPripad.regNazevDluznika = newValue?.firma?.r1Nazev;
                                dluhPripad.zodpMailDluz = newValue?.emaily?.length > 0 ? newValue.emaily[0] : undefined;
                                dluhPripad.icoDluznika = newValue?.firma?.ico;
                                dluhPripad.dicDluznika = newValue?.firma?.dic;
                            }}
                            onChanged={() => {
                                checkPripadExist(ProvozovnaType.Dluznik);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                        <FormField name={"regNazevDluznika"} title={t("DluhPripad.RegNazevDluznika")} type={"text"} textFieldProps={{inputProps: {maxLength: 120}}} serverValidation={{translate: t}}/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormField name={"icoDluznika"} title={t("DluhPripad.IcoDluznika")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}}/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormField name={"dicDluznika"} title={t("DluhPripad.DicDluznika")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}}/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormField name={"provozovnaDluznika.stav"} title={t("DluhPripad.StavProvozovnyDluznika")} type={"select"} selectProps={stavProvozovnySelect} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3}>
                        <FormField name={"provozovnaDluznika.pUlice"} title={t("DluhPripad.RegUliceDluz")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3}>
                        <FormField name={"provozovnaDluznika.pObec"} title={t("DluhPripad.RegSidloDluz")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormField name={"provozovnaDluznika.pPsc"} title={t("DluhPripad.RegPscDluz")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}} disabled/>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2}>
                        <FormField name={"provozovnaDluznika.pStat"} title={t("DluhPripad.RegStatDluz")} type={"select"} selectProps={statSelect} disabled/>
                    </Grid>
                    <SectionLine title={t("DluhPripad.ZodpOsoba")} marginTop={1} marginBottom={1}/>

                    <Grid item xs={12} sm={12} md={4}>
                        <FormField name={"zodpOsobaDluz"} title={t("DluhPripad.ZodpOsoba")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <FormField name={"zodpTelDluz"} title={t("DluhPripad.ZodpTel")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <FormField name={"zodpMailDluz"} title={t("DluhPripad.ZodpEmail")} type={"text"} textFieldProps={{inputProps: {maxLength: 50}}} serverValidation={{translate: t}}/>
                    </Grid>
                    {dluhPripad?.provozovnyDluznika ? <Grid item xs={12} sm={12} lg={12} marginTop={2}>
                        <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 0}}/>
                        <Grid item xs={12} lg={12}>
                            <Typography className={classes.sectionLabel} color="textSecondary" display="block"
                                        variant="caption">{t("DluhPripad.ProvozovnyFirmy")}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <div dangerouslySetInnerHTML={{__html: provozovnyLink(dluhPripad.provozovnaDluznika, dluhPripad.provozovnyDluznika)}}/>
                        </Grid>
                    </Grid>: null}
                </Grid>
            </FormPartBox>
        </Grid>
        <Grid item xs>
            <FormPartBox title={t("DluhPripad.Veritel")}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} lg={4}>
                        <FormField
                            name="provozovnaVer"
                            title={t("Firma.Provozovna")}
                            type={"select"}
                            selectProps={provozovnaSelect}
                            required
                            disabled={edited}
                            forceUpdateWhenDataChanged={[]}
                            fieldRef={provozovnaVerRef}
                            onWillChange={(field, newValue) => {
                                dluhPripad.regNazevVer = newValue?.firma?.r1Nazev;
                                dluhPripad.zodpMailVer = newValue?.emaily?.length > 0 ? newValue.emaily[0] : undefined
                                dluhPripad.icoVer = newValue?.firma?.ico;
                                dluhPripad.dicVer = newValue?.firma?.dic;
                            }}
                            onChanged={() => {
                                checkPripadExist(ProvozovnaType.Veritel);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                        <FormField name={"regNazevVer"} title={t("DluhPripad.RegNazevVer")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}}/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormField name={"icoVer"} title={t("DluhPripad.IcoVer")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}}/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormField name={"dicVer"} title={t("DluhPripad.DicVer")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}}/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormField name={"provozovnaVer.stav"} title={t("DluhPripad.StavProvozovnyVer")} type={"select"} selectProps={stavProvozovnySelect} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3}>
                        <FormField name={"provozovnaVer.pUlice"} title={t("DluhPripad.RegUliceVer")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3}>
                        <FormField name={"provozovnaVer.pObec"} title={t("DluhPripad.RegSidloVer")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormField name={"provozovnaVer.pPsc"} title={t("DluhPripad.RegPscVer")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}} disabled/>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2}>
                        <FormField name={"provozovnaVer.pStat"} title={t("DluhPripad.RegStatVer")} type={"select"} selectProps={statSelect} disabled/>
                    </Grid>

                    <SectionLine title={t("DluhPripad.ZodpOsoba")} marginTop={1} marginBottom={1}/>

                    <Grid item xs={12} sm={12} md={4}>
                        <FormField name={"zodpOsobaVer"} title={t("DluhPripad.ZodpOsoba")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <FormField name={"zodpTelVer"} title={t("DluhPripad.ZodpTel")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}} serverValidation={{translate: t}}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <FormField name={"zodpMailVer"} title={t("DluhPripad.ZodpEmail")} type={"text"} textFieldProps={{inputProps: {maxLength: 50}}} serverValidation={{translate: t}}/>
                    </Grid>
                    {dluhPripad?.provozovnyVeritele ? <Grid item xs={12} sm={12} lg={12} marginTop={2}>
                        <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 0}}/>
                        <Grid item xs={12} lg={12}>
                            <Typography className={classes.sectionLabel} color="textSecondary" display="block"
                                        variant="caption">{t("DluhPripad.ProvozovnyFirmy")}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <div dangerouslySetInnerHTML={{__html: provozovnyLink(dluhPripad.provozovnaVer, dluhPripad.provozovnyVeritele)}}/>
                        </Grid>
                    </Grid>: null}
                </Grid>
            </FormPartBox>
        </Grid>
        <Grid item xs>
            <FormPartBox title={t("DluhPripad.Stav")}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={2}>
                        <FormField name={"stav"} title={t("DluhFakt.Stav")} type={"select"} disabled selectProps={stavSelect} autoSelectFirstValueOnTab serverValidation={{translate: t}} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <FormField name={"aktKeDni"} title={t("DluhPripad.AktKeDni")} type={FormInputType.DateTime} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={1}>
                        <Button variant={"contained"} color={"primary"}
                                onClick={() => {
                                    dluhPripad.aktKeDni = moment();
                                    form.onRefreshFieldValue('aktKeDni');
                                }}
                                type={"button"}>{t("DluhPripad.AktKeDniButton")}</Button>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <FormField name={"datCas"} title={t("WithUserBase.DatCas")} type={FormInputType.DateTime} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <FormField name={"uzivatel"} title={t("WithUserBase.Uzivatel")} type={FormInputType.Text} disabled getValue={data => data.uzivatel?.displayName()}/>
                    </Grid>
                </Grid>
            </FormPartBox>
        </Grid>
    </Grid>
}
