import * as React from "react";
import {RefObject} from "react";
import {FormFieldInterface, FormFieldInterfaceProps} from "./FormFieldInterface";
import {Moment} from "moment";
import {
    CustomDateTimePicker,
    CustomDateTimePickerLocalization,
    CustomDateTimePickerProps,
    CustomRaalRowDatetimePicker,
    DatePickerExposed,
    DateTimePickerCustomComponent
} from "../CustomDateTimePicker";
import {exist, withEndOfDayTime} from "../../utils/Util";
import {Tooltip} from "@material-ui/core";
import {invoke} from "../../utils/Invoke";

const DateTimeValueConverterISO = (datetime: Moment) => datetime;


export type CustomDateTimePickerOptions = Omit<CustomDateTimePickerProps, "label" | "value" | "localization" | "onChange"> & {endOfDayTime?: boolean;}

export interface FormDatetimeProps extends FormFieldInterfaceProps<Moment> {
    dateTimeOptions?: CustomDateTimePickerOptions;
    localization:CustomDateTimePickerLocalization;
    defaultValue?: Moment;
}


export class FormDatetime extends React.Component<FormDatetimeProps> implements FormFieldInterface {
    pickerRef: RefObject<DatePickerExposed> = React.createRef();
    state = {value: this.props.value};
    input: HTMLInputElement;
    userClick = false;
    componentDidMount(): void {
        this.props.enableFocusSupport&&this.props.enableFocusSupport();
        if(this.props.focused) {
            this.input?.focus();
            if (this.pickerRef?.current) this.pickerRef.current.focusTextField();
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps: Readonly<FormDatetimeProps>, nextContext: any): void {
        const triggerFocus = nextProps.focused !== this.props.focused && nextProps.focused;
        this.setState({value:nextProps.value}, ()=>{
            if(triggerFocus) {
                this.input?.focus();
                if (this.pickerRef?.current) this.pickerRef.current.focusTextField();
            }
        });
    }

    onValueChange(value: Moment) {
        this.setState({value: value}, () => {
            if(this.props.dateTimeOptions?.endOfDayTime===true)
                this.props.onValueChanged(DateTimeValueConverterISO(withEndOfDayTime(value)))
            else
                this.props.onValueChanged(DateTimeValueConverterISO(value))
        });
    }

    open = () => {
        this.setState({open: true});
    };

    close = () => {
        this.setState({open: false});
    };

    render() {
        let errorProps = {};
        if(exist(this.props.error)) {
           errorProps = {
               helperText:this.props.error,
               error:true
           }
        }
        const PickerType = this.props.dateTimeOptions?.raalInlineStyle ? CustomDateTimePicker : this.props.dateTimeOptions?.raalRowStyle ? CustomRaalRowDatetimePicker : DateTimePickerCustomComponent;
        return (
            <Tooltip title={this.props.dataTip||""} disableHoverListener={!this.props.dataTip} disableFocusListener disableTouchListener>
                <PickerType label={this.props.title as string}
                            value={this.state.value}
                            onChange={this.onValueChange.bind(this)}
                            localization={this.props.localization}
                            disabled={this.props.disabled}
                            onError={e => this.props.onError([e])}
                            componentRef={this.pickerRef}
                            error={this.props.error}
                            onMouseDown={()=>this.userClick = true}
                            onMouseUp={()=>this.userClick = false}
                            onInteractStart={this.props.onInteractStart}
                            onInteractEnd={this.props.onInteractEnd}
                            onKeyDown={this.props.onKeyDown}
                            defaultValue={this.props.defaultValue}
                            onDisableActionKeys={(isDisabled, focusNext) => {
                                !isDisabled && invoke(this.props.onFocus,true);
                                invoke(this.props.onDisableActionKeys, isDisabled, focusNext);
                            }}
                            inputClassName={this.props.inputClassName}
                            textFieldProps={
                                {
                                    ...errorProps,

                                    inputRef: (input) => {
                                        this.input = input;
                                    },
                                    inputVariant: this.props.variant || "outlined",
                                    fullWidth: true,
                                    size: "small"
                                }
                            }
                            onFocus={(userClick?: boolean) => {
                                this.input?.select();
                                this.props.onFocus&&this.props.onFocus(userClick??this.userClick);
                            }}
                            onBlur={this.props.onBlur}
                            autoSelectFirstValueOnTab={this.props.autoSelectFirstValueOnTab}
                            {...this.props.dateTimeOptions} />
            </Tooltip>
        );
    }

}
