import {Button, CircularProgress, PropTypes, Tooltip} from "@material-ui/core";
import * as React from "react";
import {createRef} from "react";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import {FormComponent, FormDialog} from "./Form";

/********************
 *
 * FORM BUTTON
 *
 ********************/

export interface FormButtonProps<T=any> {
    color?:PropTypes.Color
    className?:string
    type?: string
    main?:boolean
    skipBlock?:boolean
    forceTabClick?:boolean
    onSend?: (form: FormComponent<T>, type?: string) => FormButtonClickEvent
    onSendWithConfirmation?: (form: FormComponent<T>, type?: string) => FormButtonClickEvent
    tooltip?: string
    disabled?: boolean
    confirmation?: {showDialog: any, dialog: FormDialog}
    onClick?: () => void
    enabledForRequiredFields?: string[]
}

export class FormButtonClickEvent {
    type?: string;
    modifyUrl?: (url: string) => string;
    requestInit?: RequestInit;
    skipValidation?: boolean
}

export class FormButtonComponent<T> extends React.Component<FormButtonProps<T>&{getForm():FormComponent<any>}, { loading: boolean, disabled:boolean }> {
    // @ts-ignore
    state = {loading: false, disabled:false};

    onButtonSend: (event: FormButtonClickEvent) => Promise<void>;

    static defaultProps = {
        color:"primary",
        skipBlock:false
    };

    buttonRef = createRef<HTMLButtonElement>();
    rippleRef= createRef<any>();
    componentDidMount(): void {
        const form = this.props.getForm();
        form.buttons.push(this);
        this.onButtonSend = form.send.bind(form);
    }
    componentWillUnmount(): void {
        const form = this.props.getForm();
        if(form) {
            form.buttons = form.buttons.filter(f=>f!==this);
        }
    }

    setStatus(status: boolean) {
        this.setState({loading: status});
    }
    setDisabled(disabled: boolean) {
        this.setState({disabled});
    }

    setFocus() {
        this.buttonRef.current.focus();
    }

    onBlur = () => {
        setTimeout(() => this.rippleRef?.current?.stop({}), 1);
    }

    onFocus = () => {
        this.rippleRef?.current?.start({ center: true });
    }

    doClick = () => {
        if(this.state.disabled) {
            return;
        }
        if (this.props.onSendWithConfirmation && this.props.confirmation) {
            this.props.confirmation.showDialog({
                ...this.props.confirmation.dialog,
                onConfirm: () => {
                    const event = this.props.onSendWithConfirmation(this.props.getForm(), this.props.type);
                    event.type = this.props.type;
                    this.onButtonSend(event);
                }})
        }
        else if (this.props.onClick) {
            if (this.props.confirmation) {
                this.props.confirmation.showDialog({
                    ...this.props.confirmation.dialog,
                    onConfirm: () => this.props.onClick()
                })
            }else {
                this.props.onClick();
            }
        }
        else{
            const event = this.props.onSend ? this.props.onSend(this.props.getForm(), this.props.type) : new FormButtonClickEvent();
            event.type = this.props.type;
            this.onButtonSend(event);
        }
    };
    render() {
        if (this.state.loading) {
            return <Button className={this.props.className} type={"button"} variant="contained" disabled={true} color={this.props.color} style={{marginLeft:10, marginBottom:10}}><CircularProgress color="inherit" size={23}/></Button>;
        }
        else {
            return (
                <Tooltip title={this.props.tooltip??''}>
                    <span>
                        <Button className={this.props.className} ref={this.buttonRef} type={"button"} variant="contained" onClick={this.doClick} disabled={!this.props.disabled ? this.props.skipBlock ? this.props.getForm().props.disabled : this.state.disabled || this.props.getForm().props.disabled : this.props.disabled} style={{marginLeft:10, marginBottom:10}} color={this.props.color}
                                disableRipple
                                onBlur={this.onBlur}
                                onFocus={this.onFocus}>
                            {this.props.children}
                            <TouchRipple ref={this.rippleRef} center />
                        </Button>
                    </span>
                </Tooltip>
            );
        }
    }
}
