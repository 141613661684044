import {useTranslation} from "react-i18next";
import * as React from "react";
import {useCallback, useRef, useState} from "react";
import {useViewColumns} from "../_vp/VPColumns";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {
    CISELNIK_DRUH,
    DEFAULT_ADVANCE_VALUE,
    DEFAULT_RANGE_VALUE,
    PARAM_ADVANCE_KEY,
    PARAM_RANGE_KEY
} from "../prohlizeni/PrepravyCiselnikView";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {
    DokladkaFilter,
    DokladkaList,
    DokladkaZadani,
    VPList
} from "../../../../model/PrepravaVozidlo";
import {Box, Grid} from "@material-ui/core";
import {Dial} from "../../../../raal_components/dial/Dial";
import {Preprava} from "../../../../model/Preprava";
import {
    exportableFieldsView,
    getExportableProps,
    getFilterVersion,
    sizeIntegerFormat,
    sizeViewFormat,
    useVPPdfLayout
} from "../_vp/PrepravaAVozidlaShared";
import {FilterForm} from "../_vp/VPFilter";
import {Waypoint} from "../../../../model/Waypoint";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {TFunction} from "i18next";
import numeral from "numeral";
import {FormField, FormInputType} from "../../../../raal_components/form/Form";
import {exist, useDataStore} from "../../../../../common/utils/Util";
import {FormNumberRange, FormNumberRangeOptions} from "../../../../../common/component/form/FormNumberRange";
import {useCiselnikValues, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {useHashId} from "../../../../raal_components/controller/CodeBookController";
import {InvalDuvodPreprava} from "../../../../model/CommonTypes";
import {useAppContext} from "../../../../context/AppContext";
import {useDidMount} from "../../../../../common/component/hooks/SharedHooks";
import DataStorage from "../../../../../common/DataStorage";
import {useLocation} from "react-router";
import {useHistoryCustom} from "../../../../raal_components/controller/NavigationHelper";
import {RouteComponentProps} from "../../../../routes";
import {convertFilterData, DefaultDokladkyPreprava, DokladkyFilterLayout} from "./DokladkyDefault";
import {DokladkaDetail} from "./DokladkaDetail";
import {useClearPrepravaViewRecords, useZaznamOznaceniStyle} from "../Oznaceni";

const vyhodnostSharedOptions = {spacing: 1, numberProps: {format: '0,0.[00]', constraint: {precision:9, scale:2, allowNegative: true}}}
const zajizdkaSharedOptions = {spacing: 1, numberProps: {format: '0,0.[00]', constraint: {precision:9, scale:2}}, valueMultiplier: 1000 }

export function prepareCols(createColumns: () => Column<VPList<any>>[], admin: boolean, t: TFunction) {
    let addCols = createColumns() as any;
    addCols = [
        {
            title: t("Dokladky.vyhodnost").toUpperCase(),
            field: "vyhodnost",
            defaultSort: "desc",
            render: (data: any) => data.vyhodnost && numeral(data.vyhodnost).format(sizeViewFormat),
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"vyhodnostRange", customComponentOptions: vyhodnostSharedOptions}),
        },
        {
            title: t("Dokladky.zajizdka").toUpperCase(),
            field: "zajizdka",
            defaultSort: "asc",
            render: (data: any) => data.zajizdka && numeral(data.zajizdka / 1000).format(sizeIntegerFormat),
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"zajizdkaRange", customComponentOptions: zajizdkaSharedOptions}),
        },
        ...addCols
    ]
    return addCols;
}

export function DokladkyDial(props: RouteComponentProps) {
    const {t} = useTranslation();
    const refreshFun = useRef(() => {});
    let [createColumns] = useViewColumns(CiselnikTyp.R, false, refreshFun, "user/preprava", [], PARAM_RANGE_KEY, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY, DEFAULT_ADVANCE_VALUE, false, {disableMultichoice: false, refactor: true}, true);
    const endpoint = "user/dokladka";
    const dtGrid = useRef<DataGridExposed<DokladkaList, DokladkaFilter>>();
    const [dokladkaFilter, setDokladkaFilter] = useState(() => new DokladkaFilter());
    const [dokladkaZadani, setDokladkaZadani] = useState(() => new DokladkaZadani());
    const [first, setFirst] = useState(true);
    const storageKey = "DokladkaZadani";
    const dataGridStorageKey = 'datagrid_dokladky';
    const [loadZadani, storeZadani] = useDataStore(DokladkaZadani, storageKey, true, "session")
    const [loadFilter, storeFilter] = useDataStore(DokladkaFilter, "DokladkaFilter", true, "session")
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const id = useHashId();
    const {user} = useAppContext();
    const {pdfLayout} = useVPPdfLayout(user);
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const {pathname, state} = useLocation<any>();
    const {replace} = useHistoryCustom();
    const savedFilter = JSON.parse(DataStorage.get(`${storageKey}-filter`, true, 'session'));
    const changeFilterData = useCallback(() => {
        const zadaniObj = loadZadani();
        const filterObj = loadFilter();
        if(exist(filterObj)){
            clearFilters();
            setDokladkaFilter(filterObj);
            setFirst(false);
        } else if(exist(zadaniObj)) {
            clearFilters(true);
            setDokladkaZadani(zadaniObj);
            const convertedFilter = convertFilterData(zadaniObj);
            setDokladkaFilter(convertedFilter);
            setFirst(false);
        }
        // eslint-disable-next-line
    }, [setDokladkaZadani, loadZadani, loadFilter, setDokladkaFilter])
    const redirectAfterSend = false;

    useDidMount(()=>{
        if (state?.clearFilter === true) {
            clearFilters(true);
            replace(pathname, { clearFilter : false })
        } else {
            setFirst(false);
        }
        changeFilterData();
    });

    const clearFilters = (clearFilterName?: boolean) => {
        DataStorage.clear(storageKey, true, 'session');
        DataStorage.clear(dataGridStorageKey, true, 'session');
        DataStorage.clear('DokladkaFilter', true, 'session');
        if(clearFilterName) DataStorage.clear(`DokladkaZadani-filter`, true, 'session');
        setDokladkaFilter(new DokladkaFilter());
        setDokladkaZadani(new DokladkaZadani());
    }

    const getCurrentFilter = (okoli: 'nakladka'|'vykladka') => {
        const data = JSON.parse(DataStorage.get(dataGridStorageKey, true, 'session'))
        return okoli === "nakladka" ?
            Waypoint.fromOkoli(data?.current?.filters?.okoliOdkud) ?? dokladkaFilter.nakladka
            :
            Waypoint.fromOkoli(data?.current?.filters?.okoliKam) ?? dokladkaFilter.vykladka;
    }

    const clearPrepravaViewRecords =
        useClearPrepravaViewRecords(() => {dtGrid.current?.table()?.refresh({}, null, true)});
    const nabidkaOznaceni = useZaznamOznaceniStyle(endpoint);

    const nakladka = getCurrentFilter('nakladka');
    const vykladka = getCurrentFilter('vykladka');
    return <Box style={{display: "flex", overflow: !first && (nakladka || exist(id)) ? "auto" : "initial", flexFlow: "column", flexGrow: 1}}>
        {!exist(id) && <DokladkyFilterLayout
            first={first || !(nakladka && vykladka)}
            dokladkaZadani={dokladkaZadani}
            dokladkaFilter={dokladkaFilter}
            storeZadani={(data) => {
                storeZadani(data);
                redirectAfterSend ? DataStorage.redirectWithTargetBlank(`/dokladky`) : changeFilterData();
                DataStorage.clear(storageKey, true, 'session');
            }}
            storeFilter={(data) => {
                storeFilter(data);
                redirectAfterSend ? DataStorage.redirectWithTargetBlank(`/dokladky`) : changeFilterData();
                DataStorage.clear(storageKey, true, 'session');
            }}
        />}
        {!first && (nakladka || exist(id)) && <>
            {dokladkaFilter.excludedNabidkaId && !exist(id) ? <DefaultDokladkyPreprava columns={createColumns()} dokladkaFilter={dokladkaFilter} /> : null}
                <Dial<DokladkaList, DokladkaFilter, Preprava>
                    mode={props.mode}
                    gridRef={dtGrid}
                    lastBrowsedDateKey={"dokladky"}
                    config={{
                        requiredColumns: ['odkud','kam','delka','vaha'],
                        defaultHiddenColumns: ["palety", "sirka", "vyska", "lozPlocha", "objem", "klasifikace"],
                        alternativeButtons: props.admin ? undefined : [clearPrepravaViewRecords],
						cache: {disabled: true},
                        exportConfig: {
                            exportable: true,
                            exportDetailOnly: true,
                            endpoint: endpoint,
                            fileName: "export_dokladky",
                            translationPrefix: ['Preprava', 'User'],
                            formats: ["pdf", "xls", "csv", "xml"],
                            exportAllFormats: ["csv"],
                            exportableProps: getExportableProps(exportableFieldsView),
                            pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                            extendedProps: [
                                {
                                    type: ["csv", "xls"],
                                    addExtendedProps: (data) => {
                                        return {
                                            provozovna: data.uzivatel?.provozovna?.kod
                                        }
                                    }
                                }
                            ],
                            formattedProps: [
                                {
                                    type: ["csv", "xls", "pdf"],
                                    field: "druhy",
                                    format: (data) => {
                                        return druhyJoined(data)
                                    }
                                },
                                {
                                    type: ["csv", "xls", "pdf"],
                                    field: "invalDuv",
                                    format: (data) => {
                                        return locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, data)
                                    }
                                },
                                {
                                    type: ["csv", "xls", "pdf"],
                                    field: "rychlyKontakt",
                                    format: (data) => {
                                        return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                                    }
                                }
                            ]
                        },
                        id: "dokladky",
                        overflowHidden: true,
                        version:getFilterVersion(),
                        tableTitle: t("Dials.Dokladky"), endpoint: endpoint, clazz: DokladkaList, filtering: true,
                        hideAddNewActions: true,
                        columns: prepareCols(createColumns, false, t),
                        hideDefaultDummyAction:true,
                        onClearFilter: () => {
                            clearFilters(true);
                        },
                        initialFilter: {id: savedFilter?.id, name: savedFilter?.name, data: dokladkaFilter},
                        watchChanges: true,
                        stomp: {
                            topic: '/crud-preprava',
                            toggleable: true,
                            allowStompUiUpdates: true
                        },
                        options: {
                            rowStyle: (data: any) => {
                                if (data.klasifikace?.barva) {
                                    return {backgroundColor: data.klasifikace?.barva}
                                }
                                const oznaceniStyle = nabidkaOznaceni.getStyle(data);
                                if(oznaceniStyle) return oznaceniStyle;
                            }
                        }
                    }}
                    hideSaveButton
                    hideNewButtonOnEdit
                    clazzDetail={Preprava}
                    crudConfig={{editEnabled: false, removeEnabled: false, addEnabled: false}}
                    layoutFilter={() => <>
                        <FilterForm {...{typCiselniku:CiselnikTyp.R, showOznaceno:false,
                            preprava: true, viewing: true, defaultRange:DEFAULT_RANGE_VALUE, typAdvance: PARAM_ADVANCE_KEY,
                            defaultAdvance: DEFAULT_ADVANCE_VALUE, admin: false, disableMultichoice: false, hideItemAge: true}}>
                            <Grid item lg={6} sm={6} xs={12}>
                                <FormField<FormNumberRangeOptions> title={t("Dokladky.vyhodnost")} name={"vyhodnostRange"} type={"Custom"}
                                                                   customComponent={FormNumberRange} customComponentOptions={vyhodnostSharedOptions} />
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <FormField<FormNumberRangeOptions> title={t("Dokladky.zajizdka")} name={"zajizdkaRange"} type={"Custom"}
                                                                   customComponent={FormNumberRange} customComponentOptions={zajizdkaSharedOptions}/>
                            </Grid>
                        </FilterForm>
                    </>}
                    layoutDetail={()=><TabHelperNew<Preprava> render={(data)=> {
                        return <DokladkaDetail data={data} nakladka={getCurrentFilter("nakladka")} vykladka={getCurrentFilter("vykladka")} />
                    }}/>}
                    modalHeaderName={() => t("Dials.Dokladky")}
                    filterClazz={DokladkaFilter}
                />
            </>}
        </Box>;
}
