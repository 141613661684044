import {JsonProperty, Mapped} from "../../common/utils/objectmapper/Mapper";
import {Misto, VPAbstract, VPList} from "./PrepravaVozidlo";
import {InvalDuvodVozidlo} from "./CommonTypes";
import {UserInfo} from "./UserInfo";

export class Vozidlo extends VPAbstract implements Mapped {

    @JsonProperty({type:{enum:InvalDuvodVozidlo}})
    invalDuv: InvalDuvodVozidlo;

    @JsonProperty({type:{clazz:Misto, isArray:true}})
    waypointy:Misto[] = [];
    getMista() {
        return this.waypointy;
    }
}

export class VozidlaList<T = any> extends VPList<T> {
    @JsonProperty({type:{clazz:Misto, isArray:true}})
    waypointy:Misto[] = [];

    @JsonProperty({type: {enum: InvalDuvodVozidlo}})
    invalDuv: InvalDuvodVozidlo;

    tabId?: string;

    @JsonProperty({type:{clazz:UserInfo}})
    lockUserInfo?: UserInfo;

    getMista() {
        return this.waypointy;
    }
}