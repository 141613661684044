import React, {useRef} from "react";
import {Dial} from "../../../../raal_components/dial/Dial";
import {useTranslation} from "react-i18next";
import {FormButton, FormDialog, FormField} from "../../../../raal_components/form/Form";
import {Preprava, PrepravyList} from "../../../../model/Preprava";
import {useAppContext} from "../../../../context/AppContext";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {exportableFields, getFilterVersion, useValidation, useVPPdfLayout} from "../_vp/PrepravaAVozidlaShared";
import {SystemParameter, SystemParamKey} from "../../../../model/SystemParameter";
import {VPFilter} from "../../../../model/PrepravaVozidlo";
import {useInputColumns} from "../_vp/VPColumns";
import {FilterForm} from "../_vp/VPFilter";
import {DetailFormNew} from "../_vp/VPDetailForm";
import {Grid} from "@material-ui/core";
import {globalStyles} from "../../../../context/ThemeModeContext";
import {InvalDuvodPreprava} from "../../../../model/CommonTypes";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {exist, isNumber} from "../../../../../common/utils/Util";
import {useCiselnikValues, useData, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {getCurrentTabId} from "../../../../../common/utils/unque-tab-id";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {PREPRAVA_CRUD_ENDPOINT} from "../../../../../common/constants";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {useHashId} from "../../../../raal_components/controller/CodeBookController";
import {PrepravaHistoryViewPartNew} from "../prohlizeni/PrepravaHistoryViewPart";
import {ComponentMode} from "../../../../routes";
import {MultiColumnToolbarAction} from "../../../../raal_components/grid/DataGrid.d";
import {useNabidkaMultiCRUD} from "../_vp/NabidkaShared";

interface PrepravyCiselnikProps {
    autofocus?:boolean
    isTabbed?:boolean
    mode?: ComponentMode
    /**
     * Defines custom base path.
     */
    basePath?: string
    minimumTableHeight?: number
    cacheGroup?: string
}

const CISELNIK_DRUH = CiselnikTyp.R;
const PARAM_RANGE_KEY = SystemParamKey.PREPRAVA_DATE_RANGE;
const PARAM_ADVANCE_KEY = SystemParamKey.PREPRAVA_DATE_ADVANCE_MAX;
const PARAM_MAX_DRUHU_KEY = SystemParamKey.PREPRAVY_DRUHY_MAX;
const MAX_OFFER_EDITS = SystemParamKey.MAX_OFFER_EDITS;
const DEFAULT_RANGE_VALUE = 7;
const DEFAULT_ADVANCE_VALUE = 15;

/**
 * TODO - Přepsáno do New, ale uvnitř není stejná logika jako v ExtendedViewNew, bude třeba ještě projít a sloučit.
 * @param props
 * @constructor
 */
export function PrepravyCiselnik(props: PrepravyCiselnikProps = {autofocus: false}) {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const {pdfLayout} = useVPPdfLayout(user);
    const realtimeErrorsDatOd = useRef<string[]>([]);
    const realtimeErrorsDatDo = useRef<string[]>([]);
    const {inlineValidation} = useValidation({preprava:true, realtimeErrorsOd:realtimeErrorsDatOd, realtimeErrorsDo:realtimeErrorsDatDo, maxDruhyKey:PARAM_MAX_DRUHU_KEY});
    const [createColumns] = useInputColumns({defaultRange:DEFAULT_RANGE_VALUE, typParametru:PARAM_RANGE_KEY,
        typCiselniku:CISELNIK_DRUH, realtimeErrorsDatOd, realtimeErrorsDatDo, preprava:true, typAdvance: PARAM_ADVANCE_KEY, defaultAdvance: DEFAULT_ADVANCE_VALUE, maxDruhyKey: PARAM_MAX_DRUHU_KEY});
    const dateKey = "preprava-insert-browse-date";
    const [showConfirm] = useConfirmDialog();
    const dtGrid = useRef<DataGridExposed<PrepravyList, VPFilter>>();
    const dialog: FormDialog = {
        body: t("Default.DeleteText"),
        title: t("Default.DeleteTitle"),
        buttons: { confirm: t("Buttons.Delete") }
    };
    const [maxOfferEdits] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === MAX_OFFER_EDITS));
    const getEditCounter = (pocetEditaci?: number) => {
        if (!isNumber(maxOfferEdits.value)) return 0;
        return Math.max(Number.parseInt(maxOfferEdits.value) - (pocetEditaci??0), 0);
    }
    const editCounterDisabled = Number.parseInt(maxOfferEdits.value) < 0;
    const requiredFields = ["odkud", "kam", "odkudHelper", "kamHelper", "naves", "souprava", "jine", "delka", "vaha", "druhy", "datOd", "datDo"]
    const id = useHashId();
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const nabidkaMultiCRUD = useNabidkaMultiCRUD<Preprava>({
                endpoint: PREPRAVA_CRUD_ENDPOINT,
                typParametru: PARAM_RANGE_KEY,
                defaultRange: DEFAULT_RANGE_VALUE ,
                typAdvance: PARAM_ADVANCE_KEY,
                defaultAdvance: DEFAULT_ADVANCE_VALUE
        });

    return (
        <>
           <Dial<PrepravyList, VPFilter, Preprava> getModificationDate={data => data.modifiedOn}
             mode={props.mode}
             isTabbed={props.isTabbed}
             tabDetailUrl= {props.basePath ? props.basePath : undefined}
             isModal={!exist(id)}
             lastBrowsedDateKey={dateKey}
             focusFieldWhenMounted
             focusedFieldWhenMounted={exist(id) ? 'okoliOdkud' : null}
             excludeFieldsForDirtyCheck={["waypointy", "odkudTouched", "kamTouched", "datum", "profilVozidla", "currency", "offerId", "linkType" ]}
             config={{
                 cache: {group: props.cacheGroup},
                 getToolbarMultiActions: (showMultiAction: (state: boolean) => void) => {
                     return [nabidkaMultiCRUD.getRefreshAction(showMultiAction), nabidkaMultiCRUD.getRemoveAction(showMultiAction)] as unknown as MultiColumnToolbarAction<any>[]
                 },
                 disableExtendedFilterButtons: true,
                 lockSupport: {enabled: true, stompPath: "Preprava"},
                 minimumTableHeight: props.minimumTableHeight,
                 options: {
                     rowStyle: (data: any) => {
                         if (exist(data.lockUserInfo) && (data.lockUserInfo.login !== user.login || data.tabId !== getCurrentTabId())) return globalStyles.rowStyleLocked;

                         if (data.invalDuv === InvalDuvodPreprava.DUPLICITA) return globalStyles.rowStyleDuplicated;

                         if (data.invalDuv === InvalDuvodPreprava.OK) return undefined;

                         return globalStyles.rowStyleAlert;
                     }
                 },
                 autofocus: props.autofocus,
                 stomp:{
                     topic: `/provozovna/${user.provozovna.kod}/crud-preprava`,
                     toggleable: true,
                     allowStompUiUpdates: true
                 },
                version: getFilterVersion(),
                watchChanges: true,
                tableTitle: t("Dials.Prepravy"),
                defaultHiddenColumns: ["jednotka", "uzivatel", "invalDuv", "modifiedOn", "palety", "verejnaPozn", "dispecer", "sirka", "vyska", "lozPlocha", "objem", "neverPozn"],
                templatesEnabled: true,
                exportConfig: {
                     exportable: true,
                     endpoint: PREPRAVA_CRUD_ENDPOINT,
                     fileName: "export_prepravy",
                     translationPrefix: ['Preprava', 'User'],
                     formats: ["pdf", "xls", "csv", "xml"],
                     exportableProps: exportableFields,
                     pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                     extendedProps: [
                        {
                            type: ["csv", "xls"],
                            addExtendedProps: (data) => {
                                return {
                                    provozovna: data.uzivatel?.provozovna?.kod
                                }
                            }
                        }
                     ],
                     formattedProps: [
                        {
                            type: ["csv", "xls", "pdf"],
                            field: "druhy",
                            format: (data) => {
                                return druhyJoined(data)
                            }
                        },
                        {
                            type: ["csv", "xls", "pdf"],
                            field: "invalDuv",
                            format: (data) => {
                                return locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, data)
                            }
                        },
                        {
                            type: ["csv", "xls", "pdf"],
                            field: "rychlyKontakt",
                            format: (data) => {
                                return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                            }
                        }
                    ]
                },
                endpoint: PREPRAVA_CRUD_ENDPOINT,
                clazz: PrepravyList,
                filtering:true,
                rowFiltering:false,
                disableRowClick: data => data && (data.invalDuv===InvalDuvodPreprava.DUPLICITA || data.invalDuv===InvalDuvodPreprava.ZNEPLATNENO_OPERATOREM || (!editCounterDisabled && (data.pocetEditaci) < 1) || (exist(data.lockUserInfo) && (data.lockUserInfo!.login!==user.login || data.tabId!==getCurrentTabId()))),
                defaultQueryParameters: {"userid": user.id},
                validate:inlineValidation,
                columns: createColumns()
              }}
              gridRef={dtGrid}
              crudConfig={{addEnabled:true, editEnabled:true, removeEnabled: true, refreshEnabled: true}}
              createInstance={() => {
                const preprava = new Preprava();
                preprava.initialize();
                return preprava;
              }}
              extendedButtonClicked={(data: Preprava) => {
                  data.initialize();
              }}
              clazzDetail={Preprava}
              hideEditButton={true}
              hideNewButtonOnEdit={true}
              modalHeaderName={()=>t("Dials.Prepravy")}
              filterClazz={VPFilter}
              beforeSend={(data) => {
                  if (data?.dispecer && Number(data.dispecer.id) === user.id) data.dispecer = null;
              }}
              isDetailReadOnly={data => data && (data.invalDuv===InvalDuvodPreprava.DUPLICITA || data.invalDuv===InvalDuvodPreprava.ZNEPLATNENO_OPERATOREM || (exist(data.lockUserInfo) && (data.lockUserInfo!.login!==user.login || data.tabId!==getCurrentTabId())))}
              isDetailLock={data => data && (exist(data.lockUserInfo) && (data.lockUserInfo!.login!==user.login || data.tabId!==getCurrentTabId()))}
              formDisabled={(edited, data) => data && (data.invalDuv===InvalDuvodPreprava.DUPLICITA || data.invalDuv===InvalDuvodPreprava.ZNEPLATNENO_OPERATOREM)}
              hideCustomButtons={'None'}
              showHideConnectedCols={[{cols: ["currency", "cena"]}]}
              saveAndNextButton={!id}
              customButtons={(edited, data, changed, disabled, changedFields, origin) => edited &&
                      [!disabled&&<FormButton
	                      skipBlock
                          main={false}
                          type={"new"}
                          key={"createNew"}
                          enabledForRequiredFields={requiredFields}
                          // disabled={
                          //     changed &&
                          //     ((changedFields().includes("datOd") && origin?.datOd && data?.datOd && data.datOd.diff(origin.datOd, 'day') === 0) ||
                          //       (changedFields().includes("datDo") && origin?.datDo && data?.datDo && data.datDo.diff(origin.datDo, 'day') === 0)) &&
                          //       changedFields().filter(f => f !== 'datOd' && f !== 'datDo').length === 0
                          // }
                          onSend={() => {
                               return {
                                   modifyUrl: (url: string) => `${url}/new`
                               };
                          }}>

                      {t("DialDefaults.CreateRecordAsNew")}
                      </FormButton>,
                      !disabled&&<FormButton key={"update"}
                                  skipBlock
                                  main={true}
                                  type={"update"}
                                  tooltip={!editCounterDisabled && getEditCounter(data.pocetEditaci) < 1 ?
                                      t("PVI.tooltip.UpdateDisabledButton"):
                                      t("PVI.tooltip.UpdateButton")
                                  }
                                  disabled={((changedFields().length > 1 || !changedFields().includes("neverPozn")) && (!editCounterDisabled && getEditCounter(data.pocetEditaci) < 1)) || (!changed && data.invalDuv !== InvalDuvodPreprava.OK && data.invalDuv !== InvalDuvodPreprava.UZIVATEL_POZASTAVEN)}
                                  onSend={() => {
                                      return {
                                          modifyUrl: (url: string) => `${url}/${data.id}`,
                                          skipValidation: !changed,
                                          requestInit: !changed ? {
                                              method: "PUT",
                                              headers: {'obnova': 'true'},
                                              body: null
                                          } : null
                                      }
                                  }}>
                          {!changed ?
                              editCounterDisabled ? t("PVI.Refresh") : t("PVI.RefreshRecord", {value: getEditCounter(data.pocetEditaci)}) :
                                (changedFields().length === 1 && changedFields().includes("neverPozn")) || editCounterDisabled ? t("DialDefaults.UpdateRecord") :
                                  t("PVI.UpdateRecord", {value: getEditCounter(data.pocetEditaci)})}
                      </FormButton>,
                      <FormButton key={"delete"}
                                  skipBlock
                                  type={"remove"}
                                  confirmation={{showDialog: showConfirm, dialog: dialog}}
                                  onSendWithConfirmation={() =>{
                                    return {
                                      modifyUrl: (url: string) => `${url}/${data.id}`,
                                      skipValidation: true,
                                      requestInit: {
                                          method: "DELETE",
                                          body: null
                                      }
                                    }
                                  }}>
                          {t("Buttons.Delete")}
                      </FormButton>
                  ]
              }
              layoutFilter={() => (
                  <>
                      <FilterForm {...{lastBrowseDateDataKey: dateKey, typCiselniku:CISELNIK_DRUH, preprava: true, defaultRange:DEFAULT_RANGE_VALUE, typParametru:PARAM_RANGE_KEY, typAdvance: PARAM_ADVANCE_KEY, defaultAdvance: DEFAULT_ADVANCE_VALUE}}/>
                      <Grid container spacing={1} style={{marginTop:4}}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <FormField title={t("Preprava.neverPozn")} name={"neverPozn"} type={"text"}/>
                            </Grid>
                        </Grid>
                      </Grid>
                  </>
              )}
               tabs={[{
                   title: t("Preprava.History"),
                   render: ()=><TabHelperNew<Preprava> render={(data, edited) => edited&&<PrepravaHistoryViewPartNew preprava={data} zadani mode={ComponentMode.GridMode}/>}/>,
                   disabled: (data, edited) => !edited
               }]}
              layoutForm={() => <DetailFormNew<Preprava> {...{typParametru:PARAM_RANGE_KEY, typCiselniku:CISELNIK_DRUH, preprava:true, defaultRange:DEFAULT_RANGE_VALUE, maxDruhyKey:PARAM_MAX_DRUHU_KEY, advanceParam: PARAM_ADVANCE_KEY, defaultAdvanceValue: DEFAULT_ADVANCE_VALUE}} />}
            />
        </>
    );
}
