import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {MomentConverters} from "./Converters";
import {Moment} from "moment/moment";

export class PravniDokumentace {
    typ: string;

    jazyk: string;

    nazevSouboru: string;

    @JsonProperty({converters: MomentConverters})
    datumVytvoreni: Moment;

    @JsonProperty({converters: MomentConverters})
    datumPopisuRaal: Moment;

    @JsonProperty({converters: MomentConverters})
    datumPopisuPrijemce: Moment;

    nutnyPodpisRaal: Boolean;
}