import {CustomFieldComponentProps} from "./FormFieldInterface";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Grid, GridSpacing} from "@material-ui/core";
import {StandaloneField} from "./StandaloneField";
import {FormInputType} from "../../../web/raal_components/form/Form";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {JsonProperty} from "../../utils/objectmapper/Mapper";
import {MomentConverters} from "../../../web/model/Converters";
import {useMountedEffect} from "../hooks/SharedHooks";
import DataStorage from "../../DataStorage";
import {useSubscribe} from 'use-pubsub-js'

export class ItemAge {
    age?:number;
    @JsonProperty({converters:MomentConverters})
    date?:string;
    constructor(age?:number, date?:string) {
        this.age = age;
        this.date = date;
    }

}

export type FormItemAgeOptions = {spacing?:GridSpacing, lastBrowseDateDataKey?:string, defaultValue?:ItemAge, filterData: (data: any) => void}
export function FormItemAge({onValueChanged, ...props}:CustomFieldComponentProps<ItemAge, FormItemAgeOptions>) {

    const {t} = useTranslation();
    const {spacing=1} = (typeof props.options === 'function' ? props.options() : props.options) ?? {};
    const [state, setState] = useState(props.value);
    const [lastBrowseDate, setLastBrowseDate] = useState<string>(null);

    const handler = useCallback((token?:string | symbol, data?: string) => {
        if (token === 'setLastBrowsedDateChanged') {
            if (data !== lastBrowseDate) {
                setLastBrowseDate(data);
            }
        }
    }, [lastBrowseDate])

    const { unsubscribe, resubscribe } = useSubscribe({ token: 'setLastBrowsedDateChanged', handler })

    props.enableFocusSupport && props.enableFocusSupport();
    useEffect(()=> {
        onValueChanged&&onValueChanged(state);
    }, [state, onValueChanged]);

    useMountedEffect(()=> {
        if (props.value?.age === -1) {
            browseDate();
        }
        setState(props.value);
    }, [props.value]);


    useEffect(() => {
        const defaultValue = (typeof props.options === 'function' ? props.options() : props.options)?.defaultValue;
        browseDate();
        setState(defaultValue);
        resubscribe();
        return () => {
            unsubscribe();
        }
        // eslint-disable-next-line
    }, [])

    const OldMax = t("ItemAge.OldMax");
    const ByDate = t("ItemAge.ByDate");
    const browseDate = () => {
        if ((typeof props.options === 'function' ? props.options() : props.options)?.lastBrowseDateDataKey) {
            const date = DataStorage.get((typeof props.options === 'function' ? props.options() : props.options)?.lastBrowseDateDataKey);
            if (date !== lastBrowseDate) setLastBrowseDate(date);
        }
    }

    const changeValue = (value: any) => {
        if (!value) setState(null);
        const data = new ItemAge(value, !value ? null : value === -1 ? lastBrowseDate : state?.date);
        setState(data);
        (typeof props.options === 'function' ? props.options() : props.options)?.filterData(data);
    }

    const TM = t("TimeMeasures", {returnObjects:true}) as any;
    const lastBrowseDateLabel = lastBrowseDate && moment(lastBrowseDate).isValid() ? moment(lastBrowseDate).format("L LT") : moment().format("L LT");
    const options = useMemo(()=>{
        return [
            {value:-1, label: `${t("ItemAge.FromLastBrowse")} (${lastBrowseDateLabel})`, group:ByDate},
            {value:5, label: `5 ${TM["minutes"]["genitivPlural"]}`, group:OldMax},
            {value:10, label: `10 ${TM["minutes"]["genitivPlural"]}`, group:OldMax},
            {value:30, label: `30 ${TM["minutes"]["genitivPlural"]}`, group:OldMax},
            {value:60, label: `60 ${TM["minutes"]["genitivPlural"]}`, group:OldMax},
            {value:60*2, label: `2 ${TM["hours"]["plural"]}`, group:OldMax},
            {value:60*4, label: `4 ${TM["hours"]["plural"]}`, group:OldMax},
            {value:60*6, label: `6 ${TM["hours"]["genitivPlural"]}`, group:OldMax},
            {value:60*8, label: `8 ${TM["hours"]["genitivPlural"]}`, group:OldMax},
            {value:60*24, label: `24 ${TM["hours"]["genitivPlural"]}`, group:OldMax},

        ]
    }, [OldMax, TM, lastBrowseDateLabel, ByDate, t]);

    return (
        <Grid container spacing={spacing} alignItems={"center"}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <StandaloneField variant={props.variant ?? "outlined"}
                                 focused={props.focused}
                                 onKeyDown={props.onKeyDown}
                                 value={options.find(a=>a.value === state?.age)}
                                 selectProps={
                                     {
                                         options:options,
                                         isClearable:true,
                                         formatValue:(v) => v.value,
                                         formatOption:v=>v
                                     }
                                 }
                                 type={FormInputType.Select}
                                 onValueChanged={value => changeValue(value)}
                                 title={t("ItemAge.Title")}

                />
            </Grid>
        </Grid>
    );
}
