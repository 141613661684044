import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {Geometry} from "./Geometry";
import {exist} from "../../common/utils/Util";
import {OsmPlace} from "../../common/component/map/Nominatim";
import {Misto} from "./PrepravaVozidlo";
import {Vicinity} from "../../common/component/form/FormVicinityContainer";
import {LatLng} from "leaflet";

export class Waypoint {
    nazevMista: string;
    countryCode: string;
    @JsonProperty({type:{clazz:Geometry}})
    koordinat: Geometry;

    static fromWaypoint(w: any): Waypoint {
        if(!exist(w))
            return undefined;

        const n = new Waypoint();
        n.nazevMista = w.nazevMista;
        n.countryCode = w.countryCode;
        n.koordinat = new Geometry(w.koordinat.coordinates[1], w.koordinat.coordinates[0])
        return n;
    }

    static fromMisto(misto: Misto): Waypoint {
        const n = new Waypoint();
        n.countryCode = misto.countryCode?.toUpperCase();
        n.nazevMista = misto.nazevMista;
        n.koordinat = new Geometry(misto?.koordinat?.getLatLng().lat, misto?.koordinat?.getLatLng().lng);
        return n;
    }

    static fromOkoli(vicinity: Vicinity): Waypoint {
        if (vicinity?.koordinat) {
            const getLatLng = (koordinat: Geometry) => {
                return exist(koordinat.coordinates) ? new LatLng(koordinat.coordinates[1], koordinat.coordinates[0]) : undefined;
            }
            const n = new Waypoint();
            n.countryCode = vicinity.countryCode?.toUpperCase();
            n.nazevMista = vicinity.osmPlace;
            n.koordinat = new Geometry(getLatLng(vicinity?.koordinat).lat, getLatLng(vicinity?.koordinat).lng);
            return n;
        }

        return null;
    }

    static fromOsmPlace(osmPlace: OsmPlace): Waypoint {
        const n = new Waypoint();
        n.countryCode = osmPlace.address?.country_code?.toUpperCase();
        n.nazevMista = osmPlace.display_name;
        n.koordinat = new Geometry(osmPlace.lat, osmPlace.lon);
        return n;
    }

    toOsmPlace(): OsmPlace {
        return {
            address: {
                neighbourhood: "",
                suburb: "",
                city: "",
                county: "",
                state: "",
                postcode: "",
                country: "",
                country_code: "",
                road: "",
                village: ""
            },
            addresstype: "",
            category: "",
            ignoreDetail: false,
            importance: "",
            isOption: false,
            licence: "",
            name: "",
            osm_id: "",
            osm_type: "",
            place_id: "",
            place_rank: "",
            type: "",
            display_name: this.nazevMista,
            lat: this.koordinat.getLatLng().lat,
            lon: this.koordinat.getLatLng().lng,
            isCustomValue: true,
            isInitialValue: true
        };
    }
}

export class WaypointBase {
    nazevMista: string;
    lon: number;
    lat: number;

    toString() {
        return `${this.lon},${this.lat}`;
    }

    static from(lon: number, lat: number, nazevMista?: string) {
        const r = new WaypointBase()
        r.lon = Number(lon)
        r.lat = Number(lat)
        r.nazevMista = nazevMista
        return r;
    }

    static fromWaypoint(w: WaypointBase): WaypointBase {
        if(!exist(w))
            return undefined;
        return this.from(w.lon, w.lat, w.nazevMista);
    }

    static fromString(s: string): WaypointBase {
        if(!exist(s))
            return undefined
        const a = s.split(",")
        if(a.length<2)
            return undefined;
        const r = new WaypointBase()
        r.lon = Number(a[0])
        r.lat = Number(a[1])
        return r;
    }

    toGeometry(): Geometry {
        return new Geometry(this.lat, this.lon)
    }
}
