import React, {
    forwardRef,
    MutableRefObject,
    ReactElement,
    useCallback,
    useContext,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import * as DG from "../DataGrid.d";
import {AutoRefreshContext, AutoRefreshIndicator, AutoRefreshStateHandler} from "../../../AutoRefresh";
import {LoadingExposed} from "../../../../common/component/Loading";
import {useTookHook} from "../../../../common/component/hooks/SharedHooks";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../context/AppContext";
import {PubSub} from "use-pubsub-js";
import {Box, Card, CardContent, Collapse} from "@material-ui/core";
import {exist} from "../../../../common/utils/Util";
import {Form} from "../../form/Form";
import {FormStatus} from "../../form/Form.d";
import {DGContext} from "../DataGrid";
import {FilterHeader, FilterHeaderExposed} from "./FilterHeader";
import {DynamicTitle, TitlePosition} from "../../DynamicTitle";
import {RefreshAlert} from "../../../RefreshAlert";

export type TableFilterProps<Filter = any, Data extends object = any> = {
    filtering:boolean,
    layoutFilter:(doFilter:(data?:Filter)=>void)=>ReactElement,
    enableIndicator:boolean,
    gridProps:DG.Props<Data>,
    arState?: MutableRefObject<AutoRefreshStateHandler>
    loadingRef?: MutableRefObject<LoadingExposed>
    hideItemAge?: boolean
    onClearFilter?: () => void
}

export type FilterTableExposed = {
    forceUpdate(): void
}

const TableFilter = <Filter extends any>({enableIndicator, layoutFilter, filtering, ...props}:TableFilterProps<Filter>) => {
    const {getState, table} = useContext(DGContext);
    const defaultFilter = () => {
        return getState().current.filters ?? {};
    };
    const onFilterChanged = useCallback((filter: any) => table.current?.onFilterChanged(filter), [table]);
    useTookHook("Render Filter");
    const data = {...defaultFilter()};
    const filtersRef = useRef(data)
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const {checkDataChanged} = useAppContext();
    const filterHeaderRef = useRef<FilterHeaderExposed>();

    const setOpenState = (open: boolean, skipRefresh?: boolean, autoFilter: boolean = false) => {
        if (open) {
            props.arState.current?.disableAutorefresh(true);
        } else {
            props.arState.current?.enableAutorefresh(skipRefresh);
            if(autoFilter) setTimeout(() => onFilterChanged(data), 300);
        }
        PubSub.publish('filterStateChanged' + props.gridProps.tableTitle ?? '', open)
        setOpen(open)
        table.current.setFiltersOpened(open);
    }

    const selectedFilterName = getState().current?.name;
    return (
        (filtering && layoutFilter) ? (
            <Box style={{marginBottom:5}} className={"Box-class"}>
                {selectedFilterName && <DynamicTitle
                    titlePosition={TitlePosition.FILTER_NAME}
                    combinedTitle={{
                        start: "[",
                        end: "]",
                        value: selectedFilterName,
                        appender: ", ",
                        id: props.gridProps.endpoint
                    }}
                />}
                <Card style={{ zIndex:11 }}>
                    <CardContent style={open ? {padding: 5, paddingBottom: 10} : {padding: "8px 12px"}}>
                        {
                            <FilterHeader
                                filterClazz={(props.gridProps as DG.FilterProps<Filter>).filterClazz}
                                gridProps={props.gridProps}
                                indicator={enableIndicator && !open && <AutoRefreshIndicator defaultValue={data?.itemAge} lastBrowseDateDataKey={props.gridProps.lastBrowsedDateKey} filterData={(itemAge) => {
                                    setOpenState(false);
                                    props.loadingRef.current?.setShow(true);
                                    setTimeout(() => onFilterChanged({...data, itemAge: exist(itemAge?.age) ? itemAge : null}), 300);
                                }} hideItemAge={open || props.hideItemAge} />}
                                filters={data}
                                onFilterChange={(f) => checkDataChanged(() => onFilterChanged(f), () => props.loadingRef?.current?.setShow(false))}
                                onValueChanged={() => setTimeout(() => table.current?.refresh(undefined, undefined, true), 700)}
                                open={open}
                                filterHeaderRef={filterHeaderRef}
                                filtersRef={filtersRef}
                                setOpen={setOpenState}
                                onClearFilter={props.onClearFilter}
                                loadingRef={props.loadingRef}
                                customFilterHeaderButtons={props.gridProps.customFilterHeaderButtons}
                            />
                        }
                    </CardContent>
                    <Collapse in={open} addEndListener={() => {}}>
                        <CardContent style={{padding: "0 10px 10px", position:"relative"}}>
                            <Form<Filter>
                                filterForm={true}
                                data={data}
                                onChange={(d) => {
                                    filterHeaderRef?.current?.filtersChanged(data);
                                    filtersRef.current = data
                                }}
                                onSend={async () => {
                                    return FormStatus.Custom;
                                }}
                                localization={t("FormLocalization", {returnObjects: true})}
                                simpleLabel
                                focusFieldWhenMounted={false}
                                onFilterButtonClick={() => {
                                    setOpenState(false, true);
                                    props.loadingRef.current?.setShow(true);
                                    setTimeout(() => onFilterChanged(data), 300);
                                }}
                                onFilterEscapePressed={() => {
                                    setOpenState(false, null, true);
                                }}
                            >
                                {layoutFilter(onFilterChanged)}
                            </Form>
                        </CardContent>
                    </Collapse>
                </Card>
            </Box>
        ) : (props.hideItemAge ? <Box style={{marginBottom:5}} className={"Box-class"}>
                <Card style={{ zIndex:11 }}>
                    <CardContent style={{paddingTop: 0, paddingBottom: 8}}>
                        <AutoRefreshContext {...props.gridProps} arState={props.arState}>
                            {enableIndicator && <AutoRefreshIndicator hideItemAge />}
                        </AutoRefreshContext>
                    </CardContent>
                </Card>
            </Box> : <AutoRefreshContext {...props.gridProps} arState={props.arState}>
                {enableIndicator && <AutoRefreshIndicator />}
            </AutoRefreshContext>
        )
    );
};

export const TableFilterComponent = forwardRef<FilterTableExposed>((props: any , ref) =>{
    const [refresh, setRefresh] = useState(false);
    const forceUpdate = () => setRefresh(!refresh)
    useImperativeHandle(ref, ()=>({
        forceUpdate
    }));

    return <TableFilter gridProps={props} arState={props.arState} enableIndicator={Boolean(props.stomp?.toggleable)} layoutFilter={props.layoutFilter} hideItemAge={props.hideItemAge} filtering={props.extendedFilter && props.filtering} onClearFilter={props.onClearFilter} loadingRef={props.loadingRef} />
});
