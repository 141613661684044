import {useThemeContext} from "../../context/ThemeModeContext";
import {useTranslation} from "react-i18next";
import DarkModeIcon from "@material-ui/icons/Brightness4";
import LightModeIcon from "@material-ui/icons/Brightness7";
import React from "react";
import {HeaderButtonProps, IconButtonWithText} from "./HeaderAction";
import {useAppContext} from "../../context/AppContext";

export function HeaderModeSwitcherButton({showText}:HeaderButtonProps) {
    const {toggleTheme, mode} = useThemeContext();
    const {t} = useTranslation();
    const {checkDataChanged} = useAppContext();
    let tooltip = t("Theme.ThemeChange");
    return (
        <IconButtonWithText icon={mode==="light"?<DarkModeIcon/>:<LightModeIcon />} onClick={() => checkDataChanged(() =>{
            toggleTheme();
        })} tooltip={tooltip} showText={showText} text={t("Theme.Title")}/>
    );
}