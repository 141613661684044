import {JsonProperty, Mapped} from "../../common/utils/objectmapper/Mapper";
import {Misto, VPAbstract, VPList} from "./PrepravaVozidlo";
import {InvalDuvodPreprava} from "./CommonTypes";
import {UserInfo} from "./UserInfo";
import {OsrmProfile} from "./OsrmProfile";

export enum RoadType {MOTORWAY, PRIMARY, SECONDARY, TERTIARY, TRUNK, OTHER}

class PrepravaKilometr {
    id:number;
    vzdalenost:number;
    kodZeme: string;
    osrmProfile: OsrmProfile;
    typSilnice: RoadType;
}



export class Preprava extends VPAbstract implements Mapped {
    @JsonProperty({type: {enum: InvalDuvodPreprava}})
    invalDuv: InvalDuvodPreprava;

    @JsonProperty({type:{clazz:PrepravaKilometr, isArray:true}})
    kilometry: PrepravaKilometr[];

    @JsonProperty({type:{clazz:Misto, isArray:true}})
    waypointy:Misto[] = [];
    
    getMista() {
        return this.waypointy;
    }
}

export class PrepravyList<T = any> extends VPList<T> {
    @JsonProperty({type: {enum: InvalDuvodPreprava}})
    invalDuv: InvalDuvodPreprava;

    @JsonProperty({type:{clazz:Misto, isArray:true}})
    waypointy:Misto[] = [];

    tabId?: string;

    @JsonProperty({type:{clazz:UserInfo}})
    lockUserInfo?: UserInfo;

    getMista() {
        return this.waypointy;
    }
}