import {ProfilVozidla} from "../../../../model/ProfilVozidla";
import {Dial} from "../../../../raal_components/dial/Dial";
import {FormInputType} from "../../../../raal_components/form/Form";
import React from "react";
import {useTranslation} from "react-i18next";
import {CenaMytaForm} from "./CenaMytaForm";
import {
    useCurrencySelectObj,
    useEnumeratedSelect,
    useStatSelectAsAutocomplete
} from "../../../../raal_components/SelectOptions";
import {RoadType} from "../../../../model/Preprava";
import {decimalNumberFormat, shortNumberConstraint} from "../../../../../common/common-constraints";
import {CenaMyta, CenaMytaFilter} from "../../../../model/CenaMyta";
import {FieldError} from "../../../../../common/component/form/ValidationError";
import {exist} from "../../../../../common/utils/Util";
import {ComponentMode} from "../../../../routes";
import {useParams} from "react-router-dom";

export interface CenyMytaDialProps {
    data: ProfilVozidla
    onBlurChildDataGridRow: () => void
    onFocusedChildDataGridRow: () => void
    mode?: ComponentMode
}

export function CenyMytaDial(props: CenyMytaDialProps) {
    const {t} = useTranslation();
    const statSelect = useStatSelectAsAutocomplete();
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const typSilniceSelect = useEnumeratedSelect(RoadType, "Enumerations.RoadType", "number");
    const {profilId} = useParams<{profilId?: string}>();
    const validation = (data: CenaMyta) => {
        const errors = [] as FieldError [];
        const requiredFields = ["kodZeme", "typSilnice", "sazba"];
        requiredFields.forEach(f => {
            if (!exist((data as any)[f])) {
                errors.push(FieldError.Create(t("FormLocalization.FieldMessages.FieldIsRequiredValue").replace("{0}", t(f)), false, f))
            }
        });
        if(data.sazba && !data.currency)
            errors.push(FieldError.Create(t("CenaMyta.CurrencyRequiredWhenPrice"), false, "currency"));

        return errors;
    }

    return <Dial<CenaMyta, CenaMytaFilter>
        mode={props.mode}
        config={
            {
                id: "cena-myta",
                clazz: CenaMyta,
                endpoint: `user/profilvozidla/${profilId ? profilId : props.data?.id??0}/cenamyta`,
                tableTitle: t("CenaMyta.title"),
                rowFiltering:false,
                autofocus: false,
                validate: validation,
                columns: [
                    {
                        title: t("CenaMyta.kodZeme"),
                        field: 'kodZeme',
                        defaultSort: "asc",
                        editProps: () => ({type: FormInputType.Select, selectProps: statSelect})
                    },
                    {
                        title: t("CenaMyta.typSilnice"),
                        field: 'typSilnice',
                        editProps: () => ({type: FormInputType.Select, selectProps: typSilniceSelect}),
                        render: (row)=> t(`Enumerations.RoadType.${row.typSilnice}`)
                    },
                    {
                        title: t("CenaMyta.sazba"),
                        field: 'sazba',
                        editProps: () => ({type: FormInputType.Number, numberProps: {format:decimalNumberFormat, constraint:shortNumberConstraint}})
                    },
                    {
                        title: t("Currency.Title"),
                        field: 'currency',
                        editProps: (data) => ({type: FormInputType.Select, selectProps: currencySelectProps, disabled: !data?.sazba}),
                        render: (row)=> row.currency?.name ?? null
                    }
                ]
            }
        }
        preventClearFunctions
        onBlurChildDataGridRow={props.onBlurChildDataGridRow}
        onFocusedChildDataGridRow={props.onFocusedChildDataGridRow}
        focusFieldWhenMounted={true}
        stopImmediatePropagation={true}
        hideNewButtonOnEdit
        crudConfig={{editEnabled:true, removeEnabled:true, addEnabled:true}}
        layoutForm={() => {
            return <CenaMytaForm/>
        }}
        tabDetailUrl={`/kilometrovnik/${props.data?.id}/ceny-myta`}
    />
}
