import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {MomentConverters} from "./Converters";
import {Moment} from "moment";
import {BasicUserData} from "./CommonTypes";

export class ProvozovnaZaznam {
    id: number;
    @JsonProperty({converters: MomentConverters})
    datum: Moment;
    text: string;
    autor: BasicUserData;
    uzavreno: boolean;
    @JsonProperty({converters: MomentConverters})
    datUzav: Moment;
}

export class ProvozovnaZaznamFilter {
    id: number;
    @JsonProperty({converters: MomentConverters})
    datum: Moment;
    text: string;
    autor: BasicUserData;
    uzavreno: boolean;
    @JsonProperty({converters: MomentConverters})
    datUzav: Moment;
}