import React from "react";
import {MessageComponent} from "../../../common/component/MessageComponent";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../context/AppContext";
import {UserRole} from "../../model/User";
import {TypZpravy} from "../../model/Zprava";

export function Announcement() {
    const {t} = useTranslation();
    const {hasUserRole} = useAppContext();
    return <MessageComponent admin={hasUserRole(UserRole.ROLE_ADMIN)} title={t("Sections.Learning")} typZpravy={TypZpravy.T} />;
}
