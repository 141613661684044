import {Dial} from "../../../../raal_components/dial/Dial";
import {DluhNepohOsoba, DluhNepohOsobaFilter} from "../../../../model/Stiznosti";
import {useTranslation} from "react-i18next";
import {stiznost_nepohodlna_osoba} from "../../../../raal_components/Endpoints";
import {FormField, FormInputType} from "../../../../raal_components/form/Form";
import {Grid} from "@mui/material";
import * as React from "react";
import {useProvozovnaSelectId} from "../../../../raal_components/SelectOptions";
import {FormDateRange} from "../../../../../common/component/form/FormDateRange";
import {RouteComponentProps} from "../../../../routes";


export function NepohodlnaOsobaDial(props: RouteComponentProps) {
    const {t} = useTranslation();

    return <Dial<DluhNepohOsoba, DluhNepohOsobaFilter>
         mode={props.mode}
         config={{
             clazz: DluhNepohOsoba,
             tableTitle: t("Dials.NepohodlneOsoby"),
             endpoint: stiznost_nepohodlna_osoba,
             filtering: true,
             columns: [
                 {
                     title: t("DluhNepohOsoba.Prijmeni"),
                     field: 'prijmeni',
                     defaultSort: "asc",
                     filterProps: () => ({type: FormInputType.Text})
                 },
                 {
                     title: t("DluhNepohOsoba.Jmeno"),
                     field: 'jmeno',
                     filterProps: () => ({type: FormInputType.Text})
                 },
                 {
                     title: t("DluhNepohOsoba.Rodcis"),
                     field: 'rodcis',
                     filterProps: () => ({type: FormInputType.Text})
                 },
                 {
                     title: t("DluhNepohOsoba.Adresa"),
                     field: 'adresa',
                     filterProps: () => ({type: FormInputType.Text})
                 },
                 {
                     title: t("DluhNepohOsoba.Ica"),
                     field: 'ica',
                     filterProps: () => ({type: FormInputType.Text})
                 },
                 {
                     title: t("DluhNepohOsoba.Poznamka"),
                     field: 'poznamka',
                     filterProps: () => ({type: FormInputType.Text})
                 },
                 {
                     title: t("DluhNepohOsoba.Provozovny"),
                     field: 'provozovny',
                     render: data => data.provozovny?.map(value => value.kod)?.join(","),
                     filterProps: () => ({type: FormInputType.Text}),
                     sorting: false
                 },
                 {
                     title: t("DluhNepohOsoba.DatCas"),
                     field: 'datCas',
                     render: data => data.datCas?.format("L LT"),
                     filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, name:"datCasRange", customComponentOptions:{timeFormat: false}}),
                 },
                 {
                     title: t("DluhNepohOsoba.Uzivatel"),
                     field: 'uzivatel',
                     render: data => data.uzivatel?.displayName(),
                     filterProps: () => ({type: FormInputType.Text}),
                 }
             ]
         }}
         crudConfig={{addEnabled:false}}
         layoutForm={() => <NepohodlnaOsobaLayoutForm/>}
    />
}


export function NepohodlnaOsobaLayoutForm() {
    const {t} = useTranslation();
    const provozovnaSelectId = useProvozovnaSelectId({isClearable:true, isMulti: true});

    return <Grid container spacing={1}>
        <Grid item xs={12} sm={6} lg={3}>
            <FormField name={"jmeno"} title={t("DluhNepohOsoba.Jmeno")} type={"text"} serverValidation={{translate:t}} required/>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
            <FormField name={"prijmeni"} title={t("DluhNepohOsoba.Prijmeni")} type={"text"} serverValidation={{translate:t}} required/>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
            <FormField name={"rodcis"} title={t("DluhNepohOsoba.Rodcis")} type={"text"} serverValidation={{translate:t}}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
            <FormField name={"ica"} title={t("DluhNepohOsoba.Ica")} type={"text"} serverValidation={{translate:t}}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
            <FormField name={"adresa"} title={t("DluhNepohOsoba.Adresa")} type={"text"} serverValidation={{translate:t}}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
            <FormField name={"provozovny"} title={t("DluhNepohOsoba.Provozovny")} type={"select"} selectProps={provozovnaSelectId} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
            <FormField name={"poznamka"} title={t("DluhNepohOsoba.Poznamka")} type={"textarea"} textFieldProps={{inputProps: {maxLength: 510}}}/>
        </Grid>
        <Grid item xs={12} sm={4} lg={6}>
            <FormField name={"datCas"} title={t("WithUserBase.DatCas")} type={FormInputType.DateTime} disabled/>
        </Grid>
        <Grid item xs={12} sm={4} lg={6}>
            <FormField name={"uzivatel"} title={t("WithUserBase.Uzivatel")} type={FormInputType.Text} disabled getValue={data => data.uzivatel?.displayName()}/>
        </Grid>
    </Grid>
}
