import {useTranslation} from "react-i18next";
import {Dial} from "../../../../raal_components/dial/Dial";
import {DluhNadpripad, DluhPripad, DluhPripadFilter, DluhPripadStav} from "../../../../model/Stiznosti";
import {stiznost_dluh_pripad, stiznost_dluh_pripad_soubor} from "../../../../raal_components/Endpoints";
import {FormInputType} from "../../../../raal_components/form/Form";
import * as React from "react";
import {DluhPripadLayoutForm} from "./DluhPripadLayoutForm";
import {exist} from "../../../../../common/utils/Util";
import {DluhFaktDial} from "./DluhFaktDial";
import {useEnumeratedSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {FormDateRange} from "../../../../../common/component/form/FormDateRange";
import {Link, useParams} from "react-router-dom";
import {useLocation} from "react-router";
import {globalStyles, useThemeContext} from "../../../../context/ThemeModeContext";
import {DluhSoubDial} from "./DlouSoubDial";
import {Stav} from "../../../../model/Provozovna";
import moment from "moment";
import {CheckMark} from "../../../../../common/component/CheckMark";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {ComponentMode} from "../../../../routes";
import {useHistoryCustom} from "../../../../raal_components/controller/NavigationHelper";
import {useAppContext} from "../../../../context/AppContext";

interface DluhPripadDialProps {
    dluhNadpripad?: DluhNadpripad;
    mode: ComponentMode
    isInTab?: boolean
}

export function DluhPripadDial(props: DluhPripadDialProps) {
    const {t} = useTranslation();
    const stavSelectProps = useEnumeratedSelect(DluhPripadStav, "Enumerations.DluhPripadStav", "number", {isClearable: true, isMulti: true})
    const {pathname} = useLocation();
    const {mode} = useThemeContext();
    const stavProvozovnySelect = useEnumeratedSelect(Stav, "Enumerations.Stav", "string", {isClearable:true});
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const {id} = useParams<{id?:string}>();
    const {push} = useHistoryCustom();
    const {setDataChanged} = useAppContext();

    return <Dial<DluhPripad, DluhPripadFilter>
        mode={props.mode}
        excludeFieldsForDirtyCheck={['stav', 'datCas', 'uzivatel', 'faktury']}
        config={{
            options: {
                rowStyle: (data: any) => {
                    if (data.stav===0 && mode==="light") return globalStyles.rowStyleCase00Light;
                    if (data.stav===0 && mode==="dark") return globalStyles.rowStyleCase00Dark;
                    if (data.stav===1 && mode==="light") return globalStyles.rowStyleCase01Light;
                    if (data.stav===1 && mode==="dark") return globalStyles.rowStyleCase01Dark;
                    if (data.stav===2 && mode==="light") return globalStyles.rowStyleCase02Light;
                    if (data.stav===2 && mode==="dark") return globalStyles.rowStyleCase02Dark;
                    if (data.stav===3 && mode==="light") return globalStyles.rowStyleCase03Light;
                    if (data.stav===3 && mode==="dark") return globalStyles.rowStyleCase03Dark;
                    if (data.stav===5 && mode==="light") return globalStyles.rowStyleCase05Light;
                    if (data.stav===5 && mode==="dark") return globalStyles.rowStyleCase05Dark;
                    if (data.stav===6 && mode==="light") return globalStyles.rowStyleCase06Light;
                    if (data.stav===6 && mode==="dark") return globalStyles.rowStyleCase06Dark;
                    return null;
                }
            },
            clazz: DluhPripad,
            tableTitle: t("Dials.Pripady"),
            endpoint: stiznost_dluh_pripad,
            filtering: true,
            defaultQueryParameters: exist(props.dluhNadpripad) ? {
                nadpripadId: props.dluhNadpripad.id
            } : undefined,
            initialFilter: exist(props.dluhNadpripad) ? undefined : {data: {active: true}},
            id: exist(props.dluhNadpripad) ? 'nadpripad-pripady' : 'pripady',
            exportConfig: {
                exportable: true,
                endpoint: stiznost_dluh_pripad,
                exportAll: true,
                fileName: "export_pripad",
                translationPrefix: ['DluhPripad', 'DluhNadpripad'],
                formats: ["csv"],
                exportableProps: [
                    {
                        type: ["csv"],
                        fields: ['nadpripadId', 'icoDluznika','dicDluznika', 'regNazevDluznika', 'provozovnaDluznika', 'provozovnaDluznikaStav', 'zodpMailDluz', 'icoVer','dicVer', 'regNazevVer', 'provozovnaVer', 'provozovnaVerStav', 'zodpMailVer', 'kdoZaloz', 'datCas', 'stav', 'active']
                    }
                ],
                extendedProps: [
                    {
                        type: ["csv"],
                        addExtendedProps: (data) => {
                            return {
                                nadpripadId: data.nadpripad?.id,
                                provozovnaDluznikaStav: data.provozovnaDluznika?.stav ? t(`Enumerations.Stav.${data.provozovnaDluznika.stav}`) : null,
                                provozovnaVerStav: data.provozovnaVer?.stav ? t(`Enumerations.Stav.${data.provozovnaVer.stav}`) : null,
                                provozovnaDluznika: data.provozovnaDluznika?.kod?.toString(),
                                provozovnaVer: data.provozovnaVer?.kod?.toString(),
                                stav: data.stav ? t(`Enumerations.DluhPripadStav.${DluhPripadStav[data.stav]}`) : null
                            }
                        }
                    }
                ]
            },
            columns: [
                {
                    title: t("Firma.Nadpripad"),
                    field: 'nadpripadId',
                    filterProps:() => ({type:FormInputType.Number}),
                    render: (data) => exist(data.nadpripadId) ? <Link to={{
                        pathname: `/stiznosti/dluh-nadpripad/${data.nadpripadId}`,
                        state: {
                            forcePreviousPage: pathname
                        }
                    }} onClick={(e) => {e.stopPropagation();}}>{data.nadpripadId}</Link> : undefined
                },
                {
                    title: t("DluhPripad.IcoDluznika"),
                    field: 'icoDluznika',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("DluhPripad.DicDluznika"),
                    field: 'dicDluznika',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("DluhPripad.RegNazevDluznika"),
                    field: 'regNazevDluznika',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("DluhPripad.ProvozovnaDluznika"),
                    field: 'provozovnaKodDluznik',
                    filterProps: () => ({type: FormInputType.Text}),
                    render: data => data.provozovnaDluznika?.kod
                },
                {
                    title: t("DluhPripad.StavProvozovnyDluznika"),
                    field: 'provozovnaDluznikaStav',
                    render: (row)=> row.provozovnaDluznika!=null ? t(`Enumerations.Stav.${row.provozovnaDluznika?.stav}`) : null,
                    filterProps: () => ({type: FormInputType.Select, selectProps: stavProvozovnySelect})
                },
                {
                    title: t("DluhPripad.IcoVer"),
                    field: 'icoVer',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("DluhPripad.DicVer"),
                    field: 'dicVer',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("DluhPripad.RegNazevVer"),
                    field: 'regNazevVer',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("DluhPripad.ProvozovnaVer"),
                    field: 'provozovnaKod',
                    filterProps: () => ({type: FormInputType.Text}),
                    render: data => data.provozovnaVer?.kod
                },
                {
                    title: t("DluhPripad.StavProvozovnyVer"),
                    field: 'provozovnaVerStav',
                    render: (row)=> row.provozovnaVer!=null ? t(`Enumerations.Stav.${row.provozovnaVer?.stav}`) : null,
                    filterProps: () => ({type: FormInputType.Select, selectProps: stavProvozovnySelect})
                },
                {
                    title: t("DluhPripad.KdoZaloz"),
                    field: 'kdoZaloz',
                    filterProps: () => ({type: FormInputType.Text}),
                    render: data => data.kdoZaloz?.displayName()
                },
                {
                    title: t("DluhPripad.DatCas"),
                    field: 'datCas',
                    defaultSort: "desc",
                    render: data => data.datCas?.format("L LT"),
                    filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, name:"datCasRange", customComponentOptions:{timeFormat: false}}),
                },
                {
                    title: t("DluhPripad.Stav"),
                    field: 'stav',
                    render: (row)=> t(`Enumerations.DluhPripadStav.${row.stav}`),
                    filterProps: () => ({type: FormInputType.Select, selectProps: stavSelectProps})
                },
                {
                    title: t("DluhPripad.Active"),
                    field: 'active',
                    sorting: false,
                    render: (row) => <CheckMark checked={row.active}/>,
                    filterProps: () => ({type: FormInputType.Select, selectProps:yesNoSelect}),
                },
            ]
        }}
        isModal={exist(props.dluhNadpripad)}
        isTabbed={exist(props.dluhNadpripad)}
        layoutForm={() => <DluhPripadLayoutForm />}
        crudConfig={{removeEnabled: false, addEnabled:false}}
        focusFieldWhenMounted
        reloadData={true}
        createInstance={() => {
            const pripad = new DluhPripad();
            pripad.stav = DluhPripadStav.KE_SCHVALENI;
            pripad.aktKeDni = moment();
            return pripad;
        }}
        afterSuccess={(data: any, edited) => {
            if (!edited) {
                setDataChanged(false);
                push(`/stiznosti/dluh-pripad/${data.id}#tab=1`);
            }
        }}
        preventCloseAfterSave={id === 'new'}
        tabs={[
            {
                title: t("DluhFakt.Title"),
                render: () => <TabHelperNew<DluhPripad> render={(data, edited) =>
                    edited&&<DluhFaktDial dluhPripad={data} mode={ComponentMode.GridMode} isInTab={true} />
                }/>,
                disabled: data => !exist(data?.id)
            },
            {
                title: t("DluhSoub.Title"),
                render: () => <TabHelperNew<DluhNadpripad> render={(data, edited) =>
                    edited&&<DluhSoubDial url={stiznost_dluh_pripad_soubor.replace("{pripadId}", data.id.toString())} mode={ComponentMode.GridMode}/>
                }/>,
                disabled: data => !exist(data?.id)
            }
        ]}
        tabDetailUrl={props.isInTab ? '/stiznosti/dluh-pripad' : null }
    />
}
