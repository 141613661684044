import {Form, FormButton, FormDialog,} from "../form/Form";
import {dispatchModal, ModalActionType} from "../../../common/component/ModalContainer";
import {Alert} from "@material-ui/lab";
import {Button, Link} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import * as React from "react";
import {useEffect, useImperativeHandle, useRef, useState} from "react";
import {IDClass} from "../../model/CommonTypes";
import {useTranslation} from "react-i18next";
import {useStyleContext} from "../../context/ThemeModeContext";
import {FieldError} from "../../../common/component/form/ValidationError";
import {GenericMap} from "../../../index.d";
import {httpEndpoint} from "../../../common/utils/HttpUtils";
import {useTheme} from "@material-ui/core/styles";
import {invoke} from "../../../common/utils/Invoke";
import {DialLocalization} from "../../i18n/LocType.d";
import {useTookHook} from "../../../common/component/hooks/SharedHooks";
import {useAppContext} from "../../context/AppContext";
import {useConfirmDialog} from "../ConfirmDialog";
import {useStompSubscribe} from "../../../common/utils/Websocket";
import {LockDto} from "../../model/LockResult";
import {getCurrentTabId} from "../../../common/utils/unque-tab-id";
import {showSnack} from "../../../common/component/SnackContainer";
import {deepEqualFields} from "../../../common/utils/Util";
import {CodeBookFormButtonsProps, CodeBookFormProps, FormButtonsExposed} from "./CodeBookForm.d";
import {FormButtonComponent} from "../form/FormButton";
import {ModalSaveDataTemplate, useDataTemplateStorage} from "../DataTemplate";

/**
 * Komponenta, ktera implementuje form a pridava zakladni mechanismy jako jsou tlacitka na odeslani, concurrency modification, defaultni translace formulare ci reload dat.
 * Nepouzivat zde useCodeBookControllerContext ani useCodeBookDetailContext.
 * CodeBookForm je designovany tak, aby se dal pouzivat samostatne mimo CodeBookController.
 *
 * @param formFieldOverrides
 * @param render
 * @param edited
 * @param props
 * @constructor
 */
export function CodeBookForm<D extends IDClass>({formFieldOverrides, render, edited, ...props}:CodeBookFormProps<D>&DialLocalization) {
    const {t} = useTranslation();
    const defaultLocalization:DialLocalization = t("DialDefaults", {returnObjects:true});
    const localization = {...defaultLocalization, ...props.localization};
    const theme = useTheme();
    const [errors, setErrors] = useState([] as FieldError[]);
    const [data, setData] = useState(props.data);
    const {formDisabled=()=>false} = props;
    const {setDataChanged, user} = useAppContext();
    useStompSubscribe<LockDto>( props.lockSupport?.enabled ? `/provozovna/${user.provozovna.kod}/lock/${props.lockSupport.stompPath}` : undefined, {callback:(lock) => {
        if(lock.uzivatel.login===user.login && getCurrentTabId()===lock.tabId && lock.locked===false && lock.unlockTabId !==getCurrentTabId()) {
                showSnack({title: t("Errors.LockLost"), severity:"warning", duration: 15000});
                props.hideDetail?.()
            }
        }});
    const reloadData = async () => {
        if(props.customReload) {
            await props.customReload();
        } else {
            const result = await httpEndpoint<D>(props.clazz, `${props.getDetailEndpoint(data)}`);
            if (result.response.status === 200) {
                updateOrigin.current = true;
                setData(result.data);
                setErrors([]);
                updateOrigin.current = false;
            }
        }
    };

    useEffect(() => {
        console.log("CodeBookForm mounted");
    }, [])

    useEffect(()=>{
        setData(props.data);
    }, [props.data]);
    useEffect(()=>{
        if(props.formRef) {
            props.formRef.current = {
                reload:()=>invoke(reloadData)
            }
        }
    });
    const updateOrigin = useRef(false);
    const disabled = formDisabled(edited, data);
    const headers: GenericMap = {};
    const buttonsRef = useRef<FormButtonsExposed>();
    if (data && props.getModificationDate) {
        const mDate = props.getModificationDate(data);
        if (mDate)
            headers["modificationDate"] = mDate.toISOStringWithMillis();
    }
    useTookHook("Codebook Form");
    return (
        <Form<D> excludeFieldsForDirtyCheck={[...props.excludeFieldsForDirtyCheck??[], "tableData", "validateOnLoad", "detail"]}
                 excludeFieldsForIntegerCheck={props.excludeFieldsForIntegerCheck??[]}
                 excludeFieldsForTimeCheck={props.excludeFieldsForTimeCheck??[]}
                 focusFieldWhenMounted={props.focusFieldWhenMounted && !disabled}
                 focusedFieldWhenMounted={props.focusedFieldWhenMounted}
                 formFieldOverrides={formFieldOverrides}
                 childForm={props.childForm}
                 autofillValues
                 origin={props.origin}
                 beforeSend={props.beforeSend}
                 saveAndNextButton={props.saveAndNextButton}
                 updateOriginToo={updateOrigin.current}
                 preventCloseAfterSave={props.preventCloseAfterSave}
                 validate={data1 => props.validate ? props.validate(data1) : null}
                 onChange={(form, isEqual) => {
                     invoke(props.onChange, form?.data);
                     buttonsRef.current?.formDataChanged(form.buttons, isEqual);
                 }}
                 stopImmediatePropagation={props.stopImmediatePropagation}
                 onDataChanged={(form, isEqual) => {
                     if (!buttonsRef?.current) setDataChanged(!isEqual);
                     buttonsRef.current?.formDataChanged(form.buttons, isEqual);
                 }}
                 blockSaveUntilChange
                 rest
                 disabled={disabled && (!props.hideCustomButtons || (props.hideCustomButtons && props.hideCustomButtons !== 'None'))}
                 isReadOnly={disabled}
                 data={data}
                 url={props.url}
                 {...props.formProps}
                 handlers={props.handlers}
                 onResult={(result, event) => {
                    if (result && result.response) {
                        if (result.response.status === 200 || result.response.status === 201) {
                            props.onSuccess&&props.onSuccess(result.data, event, result);
                        } else if (result.response.status === 422) {
                            let entitiesErrors = result.validationError.errors.filter(e => e.name === "entity");
                            if (entitiesErrors.length > 0) {
                                setErrors(entitiesErrors);
                            }
                        } else {
                            result.response.json().then(json => {
                                dispatchModal({
                                    type: ModalActionType.Show,
                                    title: `Chyba ${result.response.status}`,
                                    body: json["message"]
                                })
                            }).catch(() => {
                                dispatchModal({
                                    type: ModalActionType.Show,
                                    title: localization.ServerError,
                                    body: localization.ServerErrorMessage
                                })
                            })

                        }
                    }
        }} requestInit={{headers: headers}} localization={t("FormLocalization", {returnObjects:true})}
                 createParameters={props.createParameters}
        >
            {
                /************
                children (body)
                *************/
            }
            {errors.length > 0 && (
                <Alert severity={"error"} style={{marginRight: 20, marginBottom: 40}}>
                    {errors.map(e => {
                        let locMessage = t(e.message);
                        if(!locMessage)
                            locMessage = e.getErrorMessage();
                        if (e.message === "ModificationConcurrency") {
                            return <span key={e.message}>{locMessage}. <Link onClick={reloadData} style={{cursor: "pointer"}}>{t("Buttons.Refresh")}</Link></span>
                        } else {
                            return locMessage;
                        }
                    })}
                </Alert>
            )}
            <div style={disabled && props.hideCustomButtons ? {pointerEvents: 'none', opacity: 0.9, padding: 20, backgroundColor: theme.palette.type === 'dark' ? "#222222" : "#dddddd", borderRadius: 10} : {}} tabIndex={0}>
                {render(edited, data, setData)}
            </div>
            <FormButtons {...props} data={props.data} getDetailEndpoint={props.getDetailEndpoint} edited={edited} buttonsRef={buttonsRef} />
        </Form>
    );
}

const FormButtons = <D extends IDClass>(props:CodeBookFormButtonsProps<D>&DialLocalization) => {
    const {classes} = useStyleContext();
    const [changed, setChanged] = useState(false);
    const [reload, setReload] = useState(false);
    const {t} = useTranslation();
    const defaultLocalization:DialLocalization = t("DialDefaults", {returnObjects:true});
    const localization = {...defaultLocalization, ...props.localization};
    const {formDisabled=()=>false} = props;
    const {formLock=()=>false} = props;
    const {edited, data} = props
    const lock = formLock();
    const unlockDialog: FormDialog = {
        body: t("Default.UnlockText"),
        title: t("Default.UnlockTitle"),
        buttons: { confirm: t("DialDefaults.UnlockRecord") }
    };
    const [showConfirm] = useConfirmDialog();
    const disabled = formDisabled(edited, data);
    const {setDataChanged} = useAppContext();

    const formDataChanged = (formButtons: FormButtonComponent<D>[], isEqual?: boolean) => {
        formButtons.forEach(button=>button.setDisabled(false));
        const changedFields = deepEqualFields(data, props.origin, props.excludeFieldsForDirtyCheck, props.excludeFieldsForIntegerCheck, props.excludeFieldsForTimeCheck);
        const fb = formButtons.filter(b=>!b.props.skipBlock);
        for (let i = 0; i < fb.length; i++){
            if (!isEqual && formButtons[i].props.enabledForRequiredFields) {
                const enabled = changedFields?.some(ch => formButtons[i].props.enabledForRequiredFields?.includes(ch))
                formButtons[i].setDisabled(!enabled);
            } else {
                formButtons[i].setDisabled(isEqual);
            }
        }
        setDataChanged(!isEqual);
        setChanged(!isEqual);
        if (props.hideCustomButtons && props.hideCustomButtons === "None") setReload(!reload);
    }
    const dialog: FormDialog = {
        body: t("Default.DeleteText"),
        title: t("Default.DeleteTitle"),
        buttons: { confirm: t("Buttons.Delete") }
    };

    useImperativeHandle(props.buttonsRef, () => ({
        formDataChanged
    }));
    const dataTemplateStorage = useDataTemplateStorage();
    const isTemplateCreation = dataTemplateStorage.isTemplateCreation();

    return <>
        {
            ((!props.hideNewButtonOnEdit || !props.hideSaveButton || props.customButtons) && !props.useFormSaveButtons && !disabled) && <Divider className={classes.hrClass}/>
        }
        {
            !props.useFormSaveButtons&&!disabled&&(
                <div className="modal-buttons">
                    {
                        props.templatesEnabled && <FormButton
                            main={isTemplateCreation}
                            color={isTemplateCreation ? "primary" : "default"}
                            disabled={!dataTemplateStorage.hasDataChanged(data)}
                            onClick={() => {
                                dispatchModal({
                                    type: ModalActionType.Show, title: t("DataTemplate.Save"), body: (
                                        <ModalSaveDataTemplate data={data} callback={() => {
                                            isTemplateCreation ? props.hideDetail?.() : setReload(!reload);
                                        }} />
                                    )
                                });
                            }}>{t("DataTemplate.Save")}</FormButton>
                    }
                    {
                        (!edited && !props.hideSaveButton && props.saveAndNextButton) && (
                            <FormButton<D> main={false} type={"newAndNext"}
                                           onSend={() => {
                                               return {
                                                   modifyUrl: (url: string) => props.getDetailEndpoint(null)
                                               };
                                           }}>
                                {localization.CreateRecordAndNext}
                            </FormButton>
                        )
                    }
                    {
                        (((edited && !props.hideNewButtonOnEdit) || !edited) && !props.hideSaveButton) && (!isTemplateCreation || !props.templatesEnabled) && (
                            <FormButton<D> main={!edited} type={"new"}
                                           onSend={() => {
                                               return {
                                                   modifyUrl: (url: string) => props.getDetailEndpoint(null)
                                               };
                                           }}>
                                {edited ? localization.CreateRecordAsNew : localization.CreateRecord}
                            </FormButton>
                        )
                    }
                    {
                        edited && !props.hideSaveButton && !props.hideEditButton && (
                            <FormButton<D> main={edited} type={"save"}
                                           skipBlock={props.buttonSaveDisabledFunc ? props.buttonSaveDisabledFunc(data, props.origin) : false }
                                           onSend={() => {
                                               return {
                                                   modifyUrl: (url: string) => props.getDetailEndpoint(data)
                                               };
                                           }}>
                                {localization.UpdateRecord}
                            </FormButton>
                        )
                    }
                    {props.customButtons&&props.customButtons(edited, data, changed, disabled, () => deepEqualFields(data, props.origin, props.excludeFieldsForDirtyCheck, props.excludeFieldsForIntegerCheck, props.excludeFieldsForTimeCheck), props.origin)}
                    {edited && props.allowDelete && (
                            <FormButton<D> key={"delete"}
                                           skipBlock
                                           type={"remove"}
                                           confirmation={{showDialog: showConfirm, dialog: dialog}}
                                           onSendWithConfirmation={() =>{
                                               return {
                                                   modifyUrl: (url: string) => props.getDetailEndpoint(data),
                                                   skipValidation: true,
                                                   requestInit: {
                                                       method: "DELETE",
                                                       body: null
                                                   }
                                               }
                                           }}>
                                {localization.DeleteRecord}
                            </FormButton>
                        )
                    }
                </div>
            )
        }
        {
            (disabled && props.hideCustomButtons === 'None' && !lock)&&(
                <>
                    <Divider className={classes.hrClass}/>
                    <div className="modal-buttons">
                        {props.customButtons&&props.customButtons(edited, data, changed, disabled, () => deepEqualFields(data, props.origin, props.excludeFieldsForDirtyCheck, props.excludeFieldsForIntegerCheck, props.excludeFieldsForTimeCheck), props.origin)}
                    </div>
                </>)
        }
        {
            lock&&props.allowUnlock&&(
                <>
                    <Divider className={classes.hrClass}/>
                    <div className="modal-buttons">
                        <FormButton key={"unlock"}
                                    skipBlock
                                    type={"unlock"}
                                    confirmation={!props.compareTabId ? {showDialog: showConfirm, dialog: unlockDialog} : null}
                                    onClick={() => {
                                        props.detailLocker.unlock(data.id,
                                            {title: `${t("Errors.CouldNotCancelEdit")}`, severity: "error"}
                                        ).then(() => props.hideDetail?.());
                                    }}>
                            {t("DialDefaults.UnlockRecord")}
                        </FormButton>
                    </div>
                </>)
        }
        {
            edited && props.allowDelete && disabled && (
                <div className="modal-buttons">
                    <FormButton<D> key={"delete"}
                                   skipBlock
                                   type={"remove"}
                                   confirmation={{showDialog: showConfirm, dialog: dialog}}
                                   onSendWithConfirmation={() =>{
                                       return {
                                           modifyUrl: (url: string) => `${url}/${data.id}`,
                                           skipValidation: true,
                                           requestInit: {
                                               method: "DELETE",
                                               body: null
                                           }
                                       }
                                   }}>
                        {localization.DeleteRecord}
                    </FormButton>
                </div>
            )
        }
    </>
}

