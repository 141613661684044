import React, {useEffect, useState} from "react";
import {Drawer, Grid} from "@material-ui/core";
import {ArrowLeftIcon} from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import {makeStyles} from "@material-ui/core/styles";
import {ArrowRightIcon} from "@material-ui/pickers/_shared/icons/ArrowRightIcon";

export type DrawerContainerProps = {
    children: React.ReactNode,
    container: string,
    anchor: "left" | "top" | "bottom" | "right"
    openButton: React.ReactNode,
    buttonPosition?: "lt" | "rt",
    duration?: number,
    leftPos?: string,
    topPos?: string
    openOnMounted?: boolean
}

const topPos = "2.5rem"
const leftPos = "3rem"
const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        overflow:"hidden",
        border: 0,
        padding: "2rem",
        maxHeight: "80vh",
        paddingLeft: 0,
        background: theme.palette.type === 'dark' ? "rgba(66, 66, 66, 0.9)" : "rgba(255, 255, 255, 0.9)"
    },
    buttonLeftTop: {
        position: 'absolute',
        top: topPos,
        left: leftPos,
        zIndex: 500
    },
    buttonRightTop: {
        position: 'absolute',
        top: topPos,
        right: leftPos,
        zIndex: 500
    },
    backdrop: {
        position: 'absolute'
    },
    modal: {
        position: 'absolute'
    },
    container: {
        height: "63vh",
        overflowY: "auto",
        overflowX: "hidden"
    }
}));

export const DrawerContainer = (props:DrawerContainerProps) => {
    const classes = useStyles();
    const [isOpen, setOpen] = useState(false);
    // eslint-disable-next-line
    useEffect(() => setOpen(props.openOnMounted ?? false), [])

    return <>
        <Drawer
        anchor={props.anchor}
        open={isOpen}
        transitionDuration={props.duration ?? 1000}
        variant={"persistent"}
        PaperProps={{ className: classes.paper }}
        BackdropProps={{ className: classes.backdrop }}
        ModalProps={{
            container: document.getElementById(props.container),
            className: classes.modal
        }}
        onClose={() => setOpen(false)}>
            <Grid container direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2}>
                <Grid item lg={1} md={1} xs={12} style={{cursor: "pointer"}}>
                    <Grid container direction={"column"} justifyContent={"center"} alignItems={"stretch"} >
                        {props.anchor === "left" ? <ArrowLeftIcon fontSize={"large"} onClick={() => setOpen(false)}/> :
                            <ArrowRightIcon fontSize={"large"} onClick={() => setOpen(false)}/>}
                    </Grid>
                </Grid>
                <Grid item lg={11} md={11} xs={12} className={classes.container}>
                    {props.children}
                </Grid>
            </Grid>
        </Drawer>
        {!isOpen ? <div className={props.buttonPosition === 'rt' ?  classes.buttonRightTop : classes.buttonLeftTop}
                        style={{top: props.topPos ?? topPos}}
                        onClick={() => setOpen(true)}>
            {props.openButton}
        </div> : null}
    </>
}
