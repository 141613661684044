import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { getConfig } from '../../../../../Config';
import { Button, Grid, IconButton, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { Form, FormField } from '../../../../raal_components/form/Form';
import { Preprava } from '../../../../model/Preprava';
import { FormNominatim } from '../../../../../common/component/form/FormNominatim';
import { getNominatimOptions } from '../_vp/PrepravaAVozidlaShared';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMpzResolver } from '../../../../../common/utils/MpzUtils';
import { useTranslation } from 'react-i18next';
import { Vozidlo } from '../../../../model/Vozidlo';
import { FlagIcon } from '../../../../../common/component/FlagIcon';
import { OSMLocationSelectorComponent } from './OSMLocationSelectorComponent';
import { RouteResponse } from './HereResponse';
import { ButtonType } from './KilometrovnikLinkPart';

export interface Item {
	id: string;
	content: any;
}

export class ViaItem implements Item {
	id: string;
	content: any;

	constructor(id: string, content: any) {
		this.id = id;
		this.content = content;
	}

	duplicate(): ViaItem {
		const duplicatedContent = JSON.parse(JSON.stringify(this.content));
		return new ViaItem(this.id, duplicatedContent);
	}
}

export interface HereViaPointsProps {
	routing?: RouteResponse,
	tolls?: RouteResponse,
	viaItems: any[],
	setViaItems: React.Dispatch<React.SetStateAction<any>>,
	data?: Preprava | Vozidlo,
	getHereTrasa: (buttonType: ButtonType) => Promise<void>
}

export function HereViaPoints(props: HereViaPointsProps) {
	const {t} = useTranslation();
	const [viaFormData, setViaFormData] = useState<any>({});
	const [resolveMpz] = useMpzResolver();

	const handleOnDragEnd = (result: DropResult) => {
		if (!result.destination) return;

		const newItems = Array.from(props.viaItems);
		const reorderedItem = newItems.splice(result.source.index, 1)[0];
		newItems.splice(result.destination.index, 0, reorderedItem);

		props.setViaItems(newItems);
	};

	const moveItem = (index: number, direction: number) => {
		const newItems = Array.from(props.viaItems);
		const item = newItems.splice(index, 1)[0];
		newItems.splice(index + direction, 0, item);
		props.setViaItems(newItems);
	};

	const deleteItem = (index: number) => {
		const newItems = Array.from(props.viaItems);
		newItems.splice(index, 1);
		props.setViaItems(newItems);
	};

	const addViaItem = () => {
		const viaItem = new ViaItem(viaFormData.via, viaFormData);
		const itemExists = props.viaItems.some(existingItem => existingItem.id === viaItem.id);
		if (!itemExists) {
			props.setViaItems([...props.viaItems, viaItem.duplicate()]);
			// console.log('Item added:', viaItem);
		} else {
			// console.log('Item already exists:', viaItem);
		}
	}

	const getSimpleOdkudKamPolyline = () => {
		return [
			[props.data?.waypointy[0]?.koordinat?.getLatLng()?.lat, props.data?.waypointy[0]?.koordinat?.getLatLng()?.lng],
			[props.data?.waypointy[1]?.koordinat?.getLatLng()?.lat, props.data?.waypointy[1]?.koordinat?.getLatLng()?.lng]
		];
	}

	return <>
		{getConfig().hereEnabled && (
			<Grid item lg={12}>
				<Form<Preprava> disableFocusForSaveButton={true}
								blockSaveUntilChange={false}
								data={viaFormData}
								simpleLabel
								localization={t("FormLocalization", {returnObjects:true})}
				>
					<Grid container item spacing={2}>
						<Grid item>
							<FormField title={t("Here.Kilometrovnik.ViaInput")} name={"via"} type={"Custom"}
									   noAutofocus={true}
									   onChanged={() => {
										   viaFormData.odkudTouched = true;
									   }}
									   customComponent={FormNominatim}
									   customComponentOptions={{
										   ...getNominatimOptions(viaFormData, "via", t, resolveMpz, 3),
										   countryCodeForCompare: () => {},
										   checkUserProvozovna: true
									   }}
									   additionalServerErrorKeys={["waypointy[0].countryCode", "waypointy[0]"]}/>
						</Grid>
						<Grid item>
							<Button type={"button"} variant="contained" color={"primary"} onClick={(e) => {
								addViaItem();
							}}>{t("Here.Kilometrovnik.AddViaToList")}</Button>
						</Grid>
						<Grid item>
							<OSMLocationSelectorComponent viaItems={props.viaItems} setViaItems={props.setViaItems} polyline={getSimpleOdkudKamPolyline()}
								routing={props.routing} tolls={props.tolls} getHereTrasa={props.getHereTrasa}/>
						</Grid>
						{props.viaItems?.length > 0 && (
							<Grid item>
								<Grid item container>
									<FlagIcon mpz={props.data.regOd}/>
									<span style={{ fontWeight: 'bold', marginLeft: '8px' }}>{t('Preprava.odkud')}</span>: {props.data.odkudHelper}
								</Grid>
								<Grid item container style={{marginTop: "8px", marginBottom: "8px"}}>
									<DragDropContext onDragEnd={handleOnDragEnd}>
										<Droppable droppableId="items">
											{(provided) => (
												<List
													{...provided.droppableProps}
													ref={provided.innerRef}
													component={Paper}
												>
													{props.viaItems.map((item, index) => (
														<Draggable key={item.id} draggableId={item.id} index={index}>
															{(provided) => (
																<ListItem
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																>
																	<ListItemText primary={item.content.via} />
																	<div>
																		<IconButton
																			disabled={index === 0}
																			onClick={() => moveItem(index, -1)}
																		>
																			<ArrowUpwardIcon />
																		</IconButton>
																		<IconButton
																			disabled={index === props.viaItems.length - 1}
																			onClick={() => moveItem(index, 1)}
																		>
																			<ArrowDownwardIcon />
																		</IconButton>
																		<IconButton
																			onClick={() => deleteItem(index)}
																		>
																			<DeleteIcon />
																		</IconButton>
																	</div>
																</ListItem>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</List>
											)}
										</Droppable>
									</DragDropContext>
								</Grid>
								<Grid item container>
									<FlagIcon mpz={props.data.regKam}/>
									<span style={{fontWeight: "bold", marginLeft: "8px"}}>{t('Preprava.kam')}</span>: {props.data.kamHelper}
								</Grid>
							</Grid>
						)}
					</Grid>
				</Form>
			</Grid>
		)}
	</>
}