import {CustomFieldComponentProps} from "./FormFieldInterface";
import {Button, Tooltip} from "@material-ui/core";
import * as React from "react";
import {MouseEventHandler} from "react";

interface FormLinkButtonProps<T> {
    target?: string
    href?: (data: T) => string
    onClick?: (data: T) => MouseEventHandler
    disabled?: (data: T) => boolean
}

export function FormLinkButton<T>(props: CustomFieldComponentProps<T, FormLinkButtonProps<T>>) {
    const options = (typeof props.options === 'function' ? props.options() : props.options);
    const href = options.href && options.href(props.value);
    return <Tooltip title={props.dataTip||""} disableHoverListener={!props.dataTip} disableFocusListener={!props.dataTip} disableTouchListener={!props.dataTip}>
              <div>
                  <Button fullWidth={true}
                          variant={"contained"}
                          href={href}
                          target={options.target}
                          disabled={(options.disabled && options.disabled(props.value)) || props.disabled}
                          onClick={() => options.onClick(props.value)}
                  >{props.title}
                  </Button>
              </div>
            </Tooltip>;
}
