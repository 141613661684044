import {DluhFakt, DluhFaktFilter, DluhFakturaStav, DluhPripad} from "../../../../model/Stiznosti";
import {Dial} from "../../../../raal_components/dial/Dial";
import {stiznost_dluh_pripad_faktura, stiznost_dluh_pripad_faktura_soubor} from "../../../../raal_components/Endpoints";
import {useTranslation} from "react-i18next";
import {FormInputType} from "../../../../raal_components/form/Form";
import numeral from "numeral";
import {cenaFormat} from "../../user/zadani/InzerceCiselnik";
import {DluhFaktLayoutForm} from "./DluhFaktLayoutForm";
import {exist} from "../../../../../common/utils/Util";
import * as React from "react";
import {DluhSoubDial} from "./DlouSoubDial";
import {useEnumeratedSelect} from "../../../../raal_components/SelectOptions";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {ComponentMode} from "../../../../routes";
import {useParams} from "react-router-dom";


interface DluhFaktDialProps {
    dluhPripad: DluhPripad;
    mode: ComponentMode
    isInTab?: boolean
}

export function DluhFaktDial(props: DluhFaktDialProps) {
    const {t} = useTranslation();
    const stavSelectProps = useEnumeratedSelect(DluhFakturaStav, "Enumerations.DluhFakturaStav", "number", {isClearable: true});
    const {pripadId} = useParams<{pripadId?: string}>();

    return <Dial<DluhFakt, DluhFaktFilter>
        mode={props.mode}
        excludeFieldsForDirtyCheck={['datCas', 'stav', 'ohlasUhr', 'uzivatel']}
        config={{
            clazz: DluhFakt,
            tableTitle: t("Dials.Faktury"),
            endpoint: stiznost_dluh_pripad_faktura.replace("{pripadId}", pripadId ? pripadId : props.dluhPripad.id.toString()),
            filtering: true,
            columns: [
                {
                    title: t("DluhFakt.Cislo"),
                    field: 'cislo',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("DluhFakt.Castka"),
                    field: 'castka',
                    render: data => data.castka && numeral(data.castka).format(cenaFormat),
                },
                {
                    title: t("Currency.Title"),
                    field: 'currency',
                    render:(data:DluhFakt)=> data.currency?.name ?? ''
                },
                {
                    title: t("DluhFakt.DatSplat"),
                    field: 'datSplat',
                    render:(data:DluhFakt)=> data.datSplat?.format("L")
                },
                {
                    title: t("DluhFakt.KdyUhradil"),
                    field: 'kdyUhradil',
                    render:(data:DluhFakt)=> data.kdyUhradil?.format("L LT")
                },
                {
                    title: t("DluhFakt.KdoUhradil"),
                    field: 'kdoUhradil',
                    render:(data:DluhFakt)=> data.kdoUhradil?.displayName()
                },
                {
                    title: t("DluhFakt.KdyBezUhrady"),
                    field: 'kdyBezUhrady',
                    render:(data:DluhFakt)=> data.kdyBezUhrady?.format("L LT")
                },
                {
                    title: t("DluhFakt.KdoBezUhrady"),
                    field: 'kdoBezUhrady',
                    render:(data:DluhFakt)=> data.kdoBezUhrady?.displayName()
                },
                {
                    title: t("DluhFakt.Stav"),
                    field: 'stav',
                    render: (row)=> t(`Enumerations.DluhFakturaStav.${row.stav}`),
                    filterProps: () => ({type: FormInputType.Select, selectProps: stavSelectProps})
                },
            ]
        }}
        createInstance={() => DluhFakt.of()}
        crudConfig={{addEnabled:false}}
        focusFieldWhenMounted
        layoutForm={() => <DluhFaktLayoutForm />}
        tabDetailUrl={props.isInTab ? `/stiznosti/dluh-pripad/${props.dluhPripad.id}/faktury` : null}
        tabs={[
            {
                title: t("DluhSoub.Title"),
                render: () => <TabHelperNew<DluhFakt> render={(data, edited) =>
                    edited&&<DluhSoubDial url={stiznost_dluh_pripad_faktura_soubor.replace("{pripadId}", pripadId).replace("{fakturaId}", data.id.toString())} mode={ComponentMode.GridMode}/>
                }/>,
                disabled: data => !exist(data?.id)
            }
        ]}
    />
}
