import {useTranslation} from "react-i18next";
import {FormField} from "../../../../raal_components/form/Form";
import {Grid} from "@mui/material";
import {ReportLanguage, ReportText, ReportTextItem} from "../../../../model/ReportText";
import {exist} from "../../../../../common/utils/Util";
import {useCodeBookDetailContextNew} from "../../../../raal_components/controller/CodeBookDetail";

export const SUPPORTED_LANGUAGES = ["default", ...Object.keys(ReportLanguage).map(k => k)]

interface ReportTextLayoutFormProps {
    edited?: boolean
}

export function ReportTextLayoutForm(props: ReportTextLayoutFormProps) {
    const {t} = useTranslation();
    const {data: formData} = useCodeBookDetailContextNew<ReportText>();
    const originTexts = JSON.parse(JSON.stringify(formData.texts));

    return <Grid container spacing={1}>
        <Grid item xs={12} sm={6} lg={3}>
            <FormField name={"textKey"} title={t("ReportText.TextKey")} type={"text"} serverValidation={{translate: t}} disabled={props.edited}/>
        </Grid>
        {
            SUPPORTED_LANGUAGES.map((language, index) => {
                return <Grid item xs={12} sm={12} lg={12} key={index}>
                    <FormField
                        forceUpdateWhenDataChanged={[]}
                        name={`texts_${language}`}
                        title={`${t("ReportText.Translation")} - ${language}`}
                        type={"editor"}
                        textFieldProps={{rows:5}}
                        serverValidation={{translate: t}}
                        disabled={language==="default"}
                        customComponentOptions={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
                            inline: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                            minHeight: 200,
                            maxHeight: language==="default" ? null : 200,
                        }}
                    getValue={(data: ReportText) => {
                        return data.texts && data.texts.find(t => t.language===language)?.textValue;
                    }}
                    setValue={(data: ReportText, fieldName, newValue) => {
                        if(!exist(data.texts)) {
                            data.texts = [];
                        }

                        const item = data.texts.find(t => t.language===language);
                        const originItem = originTexts.find((t: any) => t.language===language);

                        if(!exist(item) && newValue) {
                            const newItem = ReportTextItem.of(language, newValue);
                            data.texts.push(newItem);
                        } else if (exist(item)) {
                            if (!originItem && !newValue){
                                const itemIndex = data.texts.findIndex(t => t.language===language);
                                data.texts.splice(itemIndex, 1);
                            } else {
                                item.textValue = newValue?.trim() ?? '';
                            }
                        }
                    }}
                    />
                </Grid>
            })
        }
    </Grid>
}
