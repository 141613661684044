import {useWebsocketContext} from "../../../common/utils/Websocket";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import PortableWifiOffIcon from "@material-ui/icons/PortableWifiOff";
import {IconButtonWithText} from "./HeaderAction";

const useWebsocketIndicatorColors = () => {
    //change color by mode
    return (
        {
            connectedColor:"inherit",
            connectingColor:"orange",
            disconnectedColor:"red"
        }
    )
};

export const HeaderWebsocketIndicator = ({showText}:{showText:boolean}) => {
    const {service} = useWebsocketContext();
    const [connected, setConnected] = useState(service.connected);
    const [attempts, setAttempts] = useState(0);
    const colors = useWebsocketIndicatorColors();
    const {t} = useTranslation();
    useEffect(()=> {
        service.addConnectionListener({
            onWebsocketConnected:() => {
                setAttempts(service.attempts);
                setConnected(true);
            },
            onWebsocketDisconnected:() => {
                setConnected(false);
            },
            onWebsocketReconnectFailed:() => {
                setAttempts(-1);
            },
            onWebsocketConnecting:() => {
                setAttempts(service.attempts);
            }
        });
    }, [service]);

    const Icon = connected?WifiTetheringIcon:PortableWifiOffIcon;
    let color = colors.connectedColor;
    let badge:string = undefined;
    let tooltip = t("Websocket.Connected");
    if(attempts === -1) {
        color = colors.disconnectedColor;
        tooltip = t("Websocket.Disconnected");
    } else if(!connected) {
        badge = attempts > 0 ?  `${attempts}` : undefined;
        color = colors.connectingColor;
        tooltip = t("Websocket.Connecting");
    }
    return (
        <IconButtonWithText icon={<Icon/>} tooltip={tooltip} showText={showText} text={tooltip} onClick={()=>service.connect()} disabled={attempts >= 0} color={color} badge={badge} />
    );
};