import {IconProp} from "@fortawesome/fontawesome-svg-core";
import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {Box, Button, createStyles, Grid, Paper, Theme, Typography} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme:Theme) =>
    createStyles({
        speedDial: {
            position: 'absolute',
            '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
                bottom: theme.spacing(2),
                left: theme.spacing(2),
            },
            '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
                bottom: theme.spacing(2),
                left: theme.spacing(2),
            },
        },
        disabledTileOverlay: {
            position: "absolute",
            left:0,
            top:0,
            height:"100%",
            width:"100%",
            backgroundColor:theme.palette.type === "light"?"rgba(255,255,255,0.81)":"rgba(131,131,131,0.81)"
        },
        dashboardTile:{
            border: theme.palette.type === 'dark' ? '3px solid #0064c5' : '3px solid #045E91',
            borderRadius: "13px",
            boxShadow: "none",
            filter: theme.palette.type === 'dark' ? "drop-shadow(3px 1px 2px rgb(0 0 0 / 0.2))" : "none",
            backgroundColor: "transparent",
            '& div > svg': {
                color: theme.palette.type === 'dark' ? '#0064c5' : '#045E91'
            },
            '&:hover': {
                cursor:"pointer",
                border: theme.palette.type === 'dark' ? '3px solid #005CB2' : '3px solid #034A70',
                '& div > svg': {color: theme.palette.type === 'dark' ? '#005CB2' : '#034A70'}
            }
        },
        highlitedTile: {
            border: theme.palette.type === 'dark' ? '3px solid #ED1C24' : '3px solid #ED1C24',
            '& div > svg': {color: theme.palette.type === 'dark' ? '#ED1C24' : '#ED1C24'},
            '&:hover': {
                cursor:"pointer",
                border: theme.palette.type === 'dark' ? '3px solid #ED1C24' : '3px solid #ED1C24',
                '& div > svg': {color: theme.palette.type === 'dark' ? '#d3171f' : '#d9191f'},
            }
        },
        tileButton:{
            backgroundColor: theme.palette.type === 'dark' ? '#0064c58f' : '#045e91',
            color: theme.palette.type === 'dark' ? 'white' : 'white',
            '&:hover': {
                backgroundColor: theme.palette.type === 'dark' ? '#005CB2' : '#034A70',
                color: 'white',
            }
        },
        highlitedTileButton:{
            backgroundColor: theme.palette.type === 'dark' ? '#ed1c24a6' : '#ed1c24e0',
            color: theme.palette.type === 'dark' ? 'white' : 'white',
            '&:hover': {
                backgroundColor: theme.palette.type === 'dark' ? '#ED1C24' : '#ED1C24',
                color: 'white',
            }
        },
    }),
);
export type DashboardButtons = {
    text: string,
    href: string
};
export function DashboardTile({disabled, text, icon, buttons, href, highlited}: { text: String, icon: IconProp, buttons?: DashboardButtons[], href?:string, disabled?:boolean, highlited?:boolean}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const {t} = useTranslation();
    const tile = useRef(null);

    const handleClose = () => {
        setOpen(false);
    };
    const {push} = useHistory();
    const handleOpen = () => {
        setOpen(true);
    };
    return (
        <Grid item xs={12} lg={4}>
            <Paper
                ref={tile}
                tabIndex={0}
                style={{position: "relative", margin: 0, height: "100%"}}
                elevation={disabled?0:3}
                onClick={(href&&!disabled)?(()=>{push(href)}):(!disabled)?(()=>handleOpen()):null}
                onMouseLeave={handleClose}
                className={`${classes.dashboardTile} ${highlited ? classes.highlitedTile : ''}`}>
                <div style={{padding: 25, paddingBottom: 2, textAlign: "center"}}>
                    <FontAwesomeIcon icon={icon} size="4x"/>
                    <Box mt={2}>
                        <Typography variant={"h5"} style={{fontWeight: 'bold', textTransform: 'uppercase', fontSize: "1.2rem"}} color={"inherit"}>{text}</Typography>
                    </Box>
                </div>
                <div style={{width: "100%", padding: "10px 5px", textAlign: "center"}}>
                    {(buttons && !disabled) && <div>{buttons.map(action => (
                        <Button
                            className={highlited ? classes.highlitedTileButton : classes.tileButton }
                            variant={"contained"} color={"default"}
                            key={action.text}
                            style={{padding: "0 5px", margin: "2px"}}
                            onClick={() => {
                                handleClose();
                                push(action.href);
                            }}
                        >{t(`PVI.tooltip.${action.text}`)}</Button>
                    ))}</div>}
                </div>
                <Typography className={classes.disabledTileOverlay} hidden={!disabled}/>
            </Paper>
        </Grid>
    );
}