import {Button, Grid} from "@material-ui/core";
import DataStorage from "../../../../../common/DataStorage";
import {exist} from "../../../../../common/utils/Util";
import React, {useState} from "react";
import {ModalProfilVozidlaForm} from "./ModalProfilVozidlaForm";
import {invoke} from "../../../../../common/utils/Invoke";
import {ProfilVozidla, ProfilVozidlaSelect} from "../../../../model/ProfilVozidla";
import {useTranslation} from "react-i18next";

interface KilometrovnikVozidlaPartProps {
    profilVozidla: ProfilVozidla | ProfilVozidlaSelect
    getProfil: () => void
    checkIsEqual?: () => void
}

export const KilometrovnikVozidlaPart = (props:KilometrovnikVozidlaPartProps) => {
    const [modalVozidlo, setModalVozidlo] = useState(false);
    const {t} = useTranslation();

    return <>
        <Grid item>
            <Grid item container spacing={1}>
                {exist(props.profilVozidla) ?
                    <Grid item><Button type={"button"} variant="contained" color={"secondary"} onClick={() => setModalVozidlo(true)}>{t("Kilometrovnik.Vozidlo")}</Button></Grid>
                    : <Grid item><Button type={"button"} variant="contained" color={"secondary"} onClick={() => DataStorage.redirectWithTargetBlank('/kilometrovnik/vozy')}>{t("Dials.ProfilyVozidel")}</Button></Grid>
                }

                {exist(props.profilVozidla) ? <ModalProfilVozidlaForm
                    profilVozidla={props.profilVozidla}
                    onClose={() => {
                        setModalVozidlo(false);
                        invoke(props.checkIsEqual);
                    }}
                    onSaved={() => {
                        props.getProfil();
                        setModalVozidlo(false);
                        invoke(props.checkIsEqual);
                    }}
                    open={exist(props.profilVozidla) && modalVozidlo}/> : null}
            </Grid>
        </Grid>
    </>
}
