import {JsonProperty} from "../../common/utils/objectmapper/Mapper";

export class Token {
    @JsonProperty({name:"access_token"})
    accessToken:string;

    @JsonProperty({name:"refresh_token"})
    refreshToken:string;

    @JsonProperty({name:"error"})
    error:string;

}