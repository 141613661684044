import {Box, Dialog, Tab, Tabs} from "@material-ui/core";
import * as React from "react";
import {ReactElement, useEffect, useState} from "react";
import Helmet from "react-helmet";
import {useStyleContext} from "../../context/ThemeModeContext";
import {useAppContext} from "../../context/AppContext";
import {Loading, LoadingContextProvider} from "../../../common/component/Loading";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {MuiTabPanel} from "../../../common/component/MuiTabs";
import LocalLoadingContext from "../../context/LocalLoadingContext";
import {useTranslation} from "react-i18next";
import {useFetchCustom} from "../../../common/utils/HttpUtils";
import {SimpleValue} from "../../model/SimpleValue";
import {showSnack} from "../../../common/component/SnackContainer";
import {exist, findHashParam, isNumber, useHashParams} from "../../../common/utils/Util";
import {useStylesModal} from "../../../common/component/MuiDetailTabModal";
import {useHistory, useLocation} from "react-router";
import {useHistoryCustom} from "../controller/NavigationHelper";
import {DynamicTitle, TitlePosition} from "../DynamicTitle";

// eslint-disable-next-line
export interface CodeBookTab<D> {
    title: string;
    render?: ()=>ReactElement;
    disabled?:()=>boolean;
    reloadData?: boolean;
    countEndPoint?: any;
    params?: any
    countFce?: (data: any) => string
}
export type CodeBookTabbedModalProps<D> = {
    handleClose: ()=> void,
    handleTabChanged: (reloadData: boolean)=> void,
    show:boolean,
    tabs:CodeBookTab<D>[]
    data: D
}

/**
 * Modalni dialog s tabama
 * @param tabs
 * @param show
 * @param props
 * @constructor
 */
export function CodeBookTabbedModal<D>({tabs, show, ...props}:CodeBookTabbedModalProps<D>) {
    const [title] = useState(Helmet.peek().title);
    const classes = useStylesModal();
    const {classes:cls} = useStyleContext();
    const [value, setValue] = useState(0);
    const {checkDataChanged} = useAppContext();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const change = (reload: boolean) => {
            setValue(newValue)
            props.handleTabChanged(reload);
        }
        if (tabs[value]?.reloadData) {
            checkDataChanged(() => change(false))
        }
        else if (tabs[newValue]?.reloadData)
            change(true);
        else{
            setValue(newValue)
        }
    };
    useEffect(()=>{
        if(show) setValue(0);
    }, [show]);

    const isTabDisabled = (cfg:CodeBookTab<D>) => {
        return cfg.disabled ? cfg.disabled() : false;
    };

    return (
        <LoadingContextProvider>
            <Dialog fullScreen onClose={props.handleClose} open={show}>
                <Loading show={false} style={{position: undefined}}/>
                <AppBar position="fixed" >
                    <Toolbar variant={"regular"}>
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            {tabs.map((cfg, i) => (
                                <Tab key={i} label={<TabWithCount title={cfg.title} data={props.data} params={cfg.params} countFce={cfg.countFce} countEndPoint={cfg.countEndPoint} />} disabled={isTabDisabled(cfg)}/>
                            ))}
                        </Tabs>
                        <section className={classes.rightToolbar}>
                            <IconButton edge="end" color="inherit" onClick={(e) => {
                                e.stopPropagation();
                                props.handleClose&&props.handleClose();
                            }} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </section>
                    </Toolbar>
                </AppBar>
                <div className={cls.toolbar} />
                <Box p={5}>
                    {show&&tabs.map((cfg, i)=> {
                        return (
                            <MuiTabPanel key={i} value={value} index={i}>
                                <DynamicTitle title={`- ${cfg.title}`} titlePosition={TitlePosition.TAB}/>
                                <LocalLoadingContext>
                                    {isTabDisabled(cfg) ? <></> : cfg.render()}
                                </LocalLoadingContext>
                            </MuiTabPanel>
                        );
                    })}
                </Box>
            </Dialog>
        </LoadingContextProvider>
    );
}

export type CodeBookTabbedModalPropsNew<D> = {
    handleClose: ()=> void,
    tabs:CodeBookTab<D>[]
    data: D
}

/**
 * Modalni dialog s tabama
 * @param tabs
 * @param show
 * @param props
 * @constructor
 */
export function CodeBookTabbedModalNew<D>({tabs, ...props}:CodeBookTabbedModalPropsNew<D>) {
    const [title] = useState(Helmet.peek().title);
    const classes = useStylesModal();
    const {classes:cls} = useStyleContext();
    const [value, setValue] = useState<number>(null);
    const {checkDataChanged} = useAppContext();
    const {pathname} = useLocation();
    const {push} = useHistoryCustom();
    const history = useHistory();
    const hashParams = useHashParams();

    useEffect(() => {
        const tab = hashParams.get('tab');
        if (exist(tab)) {
            setValue(Number(tab));
        } else {
            setValue(0);
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        return history.listen((location, action) => {
                if (action === 'POP') {
                    if (location.hash) {
                        const tab = findHashParam(location.hash, 'tab');
                        if (tab && isNumber(tab)) {
                            const v = Number(tab);
                            if (v !== value) setValue(v);
                        } else {
                            if (value !== 0) setValue(0);
                        }
                    } else {
                        if (value !== 0) setValue(0);
                    }
                }
            })
        // eslint-disable-next-line
    }, [history])

    const setTab = (newValue: number) => {
        checkDataChanged(() => {
            setValue(newValue);
            if (newValue !== 0) {
                push(`${pathname}#tab=${newValue}`);
            } else {
                push(pathname);
            }
        })
    }

    const isTabDisabled = (cfg:CodeBookTab<D>) => {
        return cfg.disabled ? cfg.disabled() : false;
    };

    return (exist(value) ? <Dialog fullScreen onClose={props.handleClose} open={true}>
            <AppBar position="fixed" >
                <Toolbar variant={"regular"}>
                    <Tabs value={value} onChange={(event, value) => setTab(value)} aria-label="simple tabs example">
                        {tabs.map((cfg, i) => (
                            <Tab key={i} label={<TabWithCount title={cfg.title} data={props.data} params={cfg.params} countFce={cfg.countFce} countEndPoint={cfg.countEndPoint} />} disabled={isTabDisabled(cfg)}/>
                        ))}
                    </Tabs>
                    <section className={classes.rightToolbar}>
                        <IconButton edge="end" color="inherit" onClick={(e) => {
                            e.stopPropagation();
                            props.handleClose&&props.handleClose();
                        }} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </section>
                </Toolbar>
            </AppBar>
            <div className={cls.toolbar} />
            <Box style={{display: "flex", overflow: "auto", flexFlow: "column", flexGrow: 1}}>
                {tabs.map((cfg, i)=> {
                    return (
                        <MuiTabPanel key={i} value={value} index={i} padding={5}>
                            <DynamicTitle title={`- ${cfg.title}`} titlePosition={TitlePosition.TAB}/>
                            <LocalLoadingContext>
                                {isTabDisabled(cfg) ? <></> : cfg.render()}
                            </LocalLoadingContext>
                        </MuiTabPanel>
                    );
                })}
            </Box>
        </Dialog> : null);
}

type TabWithCountProps = {
    title: string
    countEndPoint: string
    params?: any
    countFce?: (data: any) => string
    data?: any
}
const TabWithCount = (props:TabWithCountProps) => {
    const {t} = useTranslation();
    const {fetch} = useFetchCustom<SimpleValue>({endpoint: props.countEndPoint})
    const [count, setCount] = useState(null);

    useEffect(() => {
        if (props.countEndPoint && props.data?.id) {
            fetch({params: {...props.params, page: -1}}).then((data) => {
                if (!props.countFce) {
                    // @ts-ignore
                    setCount(data.total??0)
                } else {
                    setCount(props.countFce(data))
                }
            }).catch(() => {
                showSnack({title: `${t("Errors.CouldNotFetchCode")}`, severity: "error"})
            })
        } else {
            setCount(null);
        }
        // eslint-disable-next-line
    }, [])

    return <>{!exist(count) ? props.title : `${props.title} (${count})`}</>;
};
