import { useTranslation } from 'react-i18next';

export function useTranslationByLangAndParam() {
	const { t, i18n } = useTranslation();

	// Function to replace {0}, {1}, etc., in your translation strings
	const t2 = (key: string, language = i18n.language, params: string[] = []) => {
		// Fetch the translation string
		let translation = t(key, {lng: language});

		// Replace placeholders with their respective values
		params.forEach((value, index) => {
			translation = translation.replace(`{${index}}`, value);
		});

		return translation;
	};

	// Return the extended functionality
	return { ...useTranslation(), t2 };
}