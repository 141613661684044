import * as React from "react";
import {useRef} from "react";
import {Job} from "../../../model/Job";
import DataGrid, {DataGridExposed, useCRUDEditableActions} from "../../../raal_components/grid/DataGrid";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Stop from '@material-ui/icons/Stop';
import {httpEndpointCustom} from "../../../../common/utils/HttpUtils";
import {useTranslation} from "react-i18next";
import {Message, useStompSubscribe} from "../../../../common/utils/Websocket";
import {useDidMount} from "../../../../common/component/hooks/SharedHooks";

export function JobCiselnik() {
    const {t} = useTranslation();
    const jobsTableRef = useRef<DataGridExposed<Job>>();
    const [setCallback] = useStompSubscribe<Message>("job-finished", {});
    const crudOperations = useCRUDEditableActions<Job>({removeEnabled:false, addEnabled:false, editEnabled:false});

    useDidMount(() => {
        setCallback(() => {
            jobsTableRef?.current?.table()?.refresh();
        });
    });

    return (<div style={{flexGrow: "inherit", display: "flex"}}>
                <DataGrid<Job> dgRef={jobsTableRef} tableTitle={t("Dials.Jobs")} clazz={Job} id={"JobsX1"} endpoint={"admin/job"} actionsColumnIndex={3}
                               editable={crudOperations}
            columns={
                [
                    {
                        title: "Název",
                        field: "name",
                        sorting: false
                    },
                    {
                        title: "Popis",
                        field: "description",
                        sorting: false
                    },
                    {
                        title: t("Job.Stav"),
                        field: "running",
                        sorting: false,
                        render(data: Job) {
                            return <div>{t(`State.${data.running || false}`)}</div>
                        }
                    }
                ]
            }
            hideDefaultDummyAction
            filtering={false}
            pagingDisabled={true}
            stomp={{
                topic:"/ot-job-change"
            }}
            actions={[
                (data)=>(
                    {
                        tooltip: t("Job.Action"),
                        icon:() => !data.running&&<PlayArrowIcon/>,
                        onClick:async ()=>{
                            if (!data.running) {
                                try {
                                    await httpEndpointCustom(`admin/job/start?jobName=${data.name}`, {method: "POST"});
                                    jobsTableRef.current.table().refresh();
                                } catch (e) {}
                            }
                        }
                    }
                ),
                (data)=>(
                    {
                        tooltip: t("Job.Action"),
                        icon:() => data.running && data.interruptable && <Stop/>,
                        onClick:async ()=>{
                            if (data.running && data.interruptable) {
                                try {
                                    await httpEndpointCustom(`admin/job/stop?jobName=${data.name}`, {method: "POST"});
                                    jobsTableRef.current.table().refresh();
                                } catch (e) {}
                            }
                        }
                    }
                )

            ]}
                />
        </div>
    );
}
