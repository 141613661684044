import {Grid, useTheme} from "@mui/material";
import {Divider, Typography} from "@material-ui/core";
import * as React from "react";
import {useStyleContext} from "../../context/ThemeModeContext";

export interface SectionLineProps {
    title: string;
    marginTop?: number;
    marginBottom?: number;
}

export function SectionLine(props: SectionLineProps) {
    const {classes} = useStyleContext();
    const theme = useTheme();

    return <Grid item xs={12} sm={12} lg={12} marginTop={props.marginTop && theme.spacing(props.marginTop)} marginBottom={props.marginBottom && theme.spacing(props.marginBottom)}>
        <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 0}}/>
        <Grid item xs={12} lg={12}>
            <Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{props.title}</Typography>
        </Grid>
    </Grid>
}