import * as React from "react";
import {Stat} from "../../../model/Stat";
import {FormField, FormInputType} from "../../../raal_components/form/Form";
import {Dial} from "../../../raal_components/dial/Dial";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {CheckMark} from "../../../../common/component/CheckMark";
import {FlagIcon} from '../../../../common/component/FlagIcon';
import {useData} from "../../../context/DataContext";
import {useCodeBookDetailContextNew} from "../../../raal_components/controller/CodeBookDetail";
import {RouteComponentProps} from "../../../routes";

export const predvolbaConstraint = {
    allowNegative: false,
    precision:3,
    scale:0
};
export const predvolbaFormat = "0,0";

export const useCountryPhoneCode = () => {
    const {staty} = useData();
    return {
        getCountryPhoneCode: (mpz: string) => {
            if (!mpz) return null;
            const stat = staty.find(s => s.mpz === mpz)
            return stat?.predvolba ? `+${stat.predvolba}` : null
        }
    };
}

const StatForm = () => {
    const {t} = useTranslation();
    const {data} = useCodeBookDetailContextNew<Stat>()

    return  (
        <Grid container spacing={2}>
            <Grid item lg={6} sm={6} xs={12}>
                <FormField name={"nazevCz"} title={t("Default.DataName")} type={"text"} required textFieldProps={{inputProps: {maxLength: 20}}}/>
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
                <FormField name={"mpz"} title={t("Default.Mpz")} type={"text"} required textFieldProps={{inputProps: {maxLength: 3}}}/>
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
                <FormField name={"countryCode"} title={t("Stat.countryCode")} type={"text"} dataTip={t("Stat.TooltipKodZeme")} required textFieldProps={{inputProps: {maxLength: 2}}}/>
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
                <FormField name={"predvolba"} title={t("Stat.predvolba")} type={"number"} required numberProps={{format: predvolbaFormat, constraint: predvolbaConstraint}}/>
            </Grid>
            <Grid item lg={1} sm={3} xs={6}>
                <FormField name={"enabled"} title={t("Default.Enabled")} type={"checkbox"}/>
            </Grid>
            <Grid item lg={1} sm={3} xs={6} style={{paddingTop: 18}}>
                <FlagIcon mpz={data.mpz} />
            </Grid>
        </Grid>
    )
}

export function StatCiselnik(props: RouteComponentProps) {
    const {t} = useTranslation();
    return <Dial<Stat>
        mode={props.mode}
        config={{
            tableTitle : t("Dials.Countries"),
            endpoint:"admin/stat",
            clazz : Stat,
            filtering:true,
            exportConfig: {
                exportable: true,
                exportAll: true,
                endpoint: "admin/stat",
                fileName: "export_staty",
                translationPrefix: ["Stat", "Default"],
                formats: ["csv"],
                exportableProps: [
                    {
                        type: ["csv"],
                        fields: ["id", "nazevCz", "mpz", "countryCode", "predvolba", "enabled"]
                    },
                ],
            },
            columns: [
                {
                    title:t("Stat.Id"),
                    field:"id",
                    filterProps:() => ({type:FormInputType.Text})
                },
                {
                    title:t("Stat.Flag"),
                    field:"flag",
                    render:(data: Stat)=><FlagIcon mpz={data.mpz} />,
                    sorting: false
                },
                {
                    title:t("Stat.nazevCz"),
                    defaultSort: "asc",
                    field:"nazevCz",
                    filterProps:() => ({type:FormInputType.Text})
                },
                {
                    title:t("Default.Mpz"),
                    field:"mpz",
                    filterProps:() => ({type:FormInputType.Text})
                },
                {
                    title:t("Stat.countryCode"),
                    field:"countryCode",
                    filterProps:() => ({type:FormInputType.Text})
                },
                {
                    title:t("Stat.predvolba"),
                    field:"predvolba",
                    filterProps:() => ({type:FormInputType.Number})
                },
                {
                    title:t("Default.Enabled"),
                    field:"enabled",
                    render:(data: Stat)=><CheckMark checked={data.enabled}/>
                }
            ]
        }}
        hideNewButtonOnEdit
        focusFieldWhenMounted
        crudConfig={{editEnabled: true, removeEnabled:true, addEnabled:false}}
        modalHeaderName={()=>t("Default.ModalZakladni")}
        createInstance={() => {let s = new Stat(); s.enabled = true; return s;}}
        layoutForm={() => <StatForm />}
    />;
}
