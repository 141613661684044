import {Vozidlo} from "../../../../model/Vozidlo";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useRef} from "react";
import {useViewColumns} from "../_vp/VPColumns";
import {exist} from "../../../../../common/utils/Util";
import {NabidkaType} from "../../../../model/NabidkaType";
import {useEnumCiselnikSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {InvalDuvodVozidlo} from "../../../../model/CommonTypes";
import {useCiselnikValues, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {useAppContext} from "../../../../context/AppContext";
import {exportableFieldsAdminView, getFilterVersion, useVPPdfLayout} from "../_vp/PrepravaAVozidlaShared";
import {Dial} from "../../../../raal_components/dial/Dial";
import {VPFilter, VPListVozidloHistory} from "../../../../model/PrepravaVozidlo";
import {FilterForm} from "../_vp/VPFilter";
import { TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {ExtendedViewNew} from "../_vp/VPExtendedView";
import {
    CISELNIK_DRUH,
    DEFAULT_ADVANCE_VALUE,
    DEFAULT_RANGE_VALUE,
    PARAM_ADVANCE_KEY,
    PARAM_RANGE_KEY,
    prepareCols
} from "./VlastniVozyCiselnikView";
import {ComponentMode} from "../../../../routes";
import {VolneVozyViewAdminPartNew} from "./VolneVozyViewAdminPart";

type VolneVozyViewHistoryPartProps = {
    vozidlo?: Vozidlo
    archive?: boolean
    admin?: boolean
    zadani?: boolean
    mode: ComponentMode
}

export function VolneVozyViewHistoryPartNew({vozidlo, archive, admin, zadani, mode} : VolneVozyViewHistoryPartProps) {
    const {t} = useTranslation();
    const refreshFun = useRef(() => {});
    let [createColumns] = useViewColumns(CISELNIK_DRUH, undefined, refreshFun, "user/vozidlo", ["datIns", exist(vozidlo) ? undefined : "parentId"].filter(i => exist(i)), PARAM_RANGE_KEY, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY, DEFAULT_ADVANCE_VALUE, true,{idColumnName: t('Vozidlo.id'), endpoint: '/admin/nabidky/volnevozy', archive: archive, typ: NabidkaType.VOZIDLO, refactor: true}, true);
    const dateKey = "vozy-view-browse-date";
    const invalDuvSelect = useEnumCiselnikSelect({isClearable: true, ciselnikTyp: CiselnikTyp.V, enm: InvalDuvodVozidlo, enmName: "Enumerations.InvalDuvodVozidlo"});
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const cols = prepareCols(createColumns, true, archive, false, exist(vozidlo), t, yesNoSelect, locFun, invalDuvSelect);
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const accessUrl = admin ? 'admin' : 'user';
    const endpoint = archive ? `${accessUrl}/vozidlo-historie-archiv` : admin ? `${accessUrl}/vozidloview-history` : `${accessUrl}/vozidlo-history`
    const zadaniEndpoint = 'user/vozidlo-history';
    const {user} = useAppContext();
    const {pdfLayout} = useVPPdfLayout(user);

    return <Dial<VPListVozidloHistory, VPFilter, Vozidlo>
        mode={mode}
        lastBrowsedDateKey={dateKey}
        config={{
            lockSupport: {enabled: false},
            requiredColumns: ['odkud','kam','souprava','naves','jine','delka','vaha','druhy','druh','datum'],
            exportConfig: {
                exportable: true,
                endpoint: zadani ? zadaniEndpoint : endpoint,
                fileName: !archive ? "export_historie_volne_vozy" : "export_historie_archiv_volne_vozy",
                translationPrefix: ['Preprava', 'User'],
                formats: ["csv", "xls", "xml", "pdf"],
                exportableProps: exportableFieldsAdminView,
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod
                            }
                        }
                    }
                ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "druhy",
                        format: (data) => {
                            return druhyJoined(data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalDuvodVozidlo", InvalDuvodVozidlo, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            version: getFilterVersion(),
            overflowHidden: true,
            hideDefaultDummyAction:true,
            tableTitle: t("Dials.VolneVozy"), endpoint: zadani ? zadaniEndpoint : endpoint, clazz: VPListVozidloHistory, filtering: true,
            hideAddNewActions: true,
            columns: cols,
            defaultQueryParameters: vozidlo && {parentId: vozidlo.id},
        }}
        hideSaveButton
        hideNewButtonOnEdit
        clazzDetail={Vozidlo}
        crudConfig={{editEnabled: false, removeEnabled: false, addEnabled: false}}
        layoutFilter={() => <FilterForm {...{lastBrowseDateDataKey: dateKey, typCiselniku:CISELNIK_DRUH, historyTab: true, showOznaceno:true, preprava: false, defaultRange:DEFAULT_RANGE_VALUE, typParametru:PARAM_RANGE_KEY, typAdvance: PARAM_ADVANCE_KEY, defaultAdvance: DEFAULT_ADVANCE_VALUE, admin: true, history: true, singleView: exist(vozidlo)}}/>}
        layoutDetail={()=><TabHelperNew<Vozidlo> render={(data)=>
            <>
                <ExtendedViewNew ciselnikTyp={CISELNIK_DRUH} data={data} admin={admin} archive={false} history/>
                {admin && <VolneVozyViewAdminPartNew vozidlo={data} history archive={archive}/>}
            </>}/>
        }
        filterClazz={VPFilter}
        tabDetailUrl={`${admin ? `/${accessUrl}` : ''}${archive ? `/historie/volnevozy/archiv` : `/historie/volnevozy`}`}
    />;
}
