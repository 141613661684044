import React, {PropsWithChildren} from "react";
import DataStorage from "../../common/DataStorage";
import moment from "moment";
import {setLocaleAccessor} from "../../common/utils/LocaleAccessor";
import i18n from "../i18n/i18n";
import {I18nextProvider, useTranslation} from "react-i18next";
import {setHttpConfig} from "../../common/utils/HttpUtils";
import {Setter} from "../../index.d";
import {HttpLocalization} from "../../common/utils/HttpUtils.d";
import numeral from 'numeral';
import {useDidMount} from "../../common/component/hooks/SharedHooks";

const allowedLanguages = ['cs', 'sk', 'en', 'de', 'pl'];
const primaryLanguage = navigator.language.split('-')[0];
const defaultLang = allowedLanguages.includes(primaryLanguage) ? primaryLanguage : 'en';

export const localeKey = 'locale';
//global access for utility functions such as number formating and currency formating etc.
setLocaleAccessor(() => {
    return i18n.language;
});

export function useLocale():{locale:string, setLocale:Setter<string>} {
    const {i18n:l} = useTranslation();
    const setLocale = (newLocale:string) => {
        DataStorage.set(localeKey, newLocale, false);
        moment.locale(newLocale);
        numeral.locale(newLocale);
        // Form.setLocalization(t("FormLocalization"));
        i18n.changeLanguage(newLocale).then((t)=>{
            setHttpConfig({
                lang:newLocale,
                localization(): HttpLocalization {
                    return t("HttpLocalization", {returnObjects:true})
                }
            });
            document.documentElement.lang = newLocale
        });
    };
    return {
        locale:l.language,
        setLocale:setLocale
    };
}


export function LocaleContext({children}:{children:PropsWithChildren<{}>}) {
    const {setLocale} = useLocale();
    useDidMount(() => {
        setLocale(DataStorage.get(localeKey, false)||defaultLang);
    });
    return (
        <I18nextProvider i18n={i18n}>
            {children}
        </I18nextProvider>
    );
}
