import * as React from "react";
import {useState} from "react";
import {User, UserRole} from "../../../../model/User";
import {FormInputType} from "../../../../raal_components/form/Form";
import {Dial} from "../../../../raal_components/dial/Dial";
import {Stat} from "../../../../model/Stat";
import {JsonProperty, Mapper} from "../../../../../common/utils/objectmapper/Mapper";
import {useTranslation} from "react-i18next";
import {LicenceForm, useUzivatelTabs} from "../UzivatelCiselnik";
import {useEnumeratedSelect, useYesNoSelect, useRoleSelectFilter} from '../../../../raal_components/SelectOptions';
import {LicenseType, Region} from '../../../../model/CommonTypes';
import {Provozovna} from "../../../../model/Provozovna";
import {GenericMap} from "../../../../../index.d";
import AddIcon from "@material-ui/icons/AssignmentIndOutlined";
import {exist} from '../../../../../common/utils/Util';
import {CheckMark} from "../../../../../common/component/CheckMark";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {ComponentMode} from "../../../../routes";
import {globalStyles} from "../../../../context/ThemeModeContext";

class UserFilter {
    @JsonProperty({type:{clazz:Stat}})
    spStat:Stat;
    login:string;
    email:string;
    jmeno:string;
    isDispecer:boolean;
}
type UzivatelCiselnikUserProps = {
    provozovna?:Provozovna
    adminView?:boolean,
    defaultQueryParameters:GenericMap
    additionalColumns?:Column<User>[]
    mode?: ComponentMode
};
export function UzivatelCiselnikTabbed(props: UzivatelCiselnikUserProps) {
    const {t} = useTranslation();
    const tabs = useUzivatelTabs();
    const rolesSelect = useRoleSelectFilter({isClearable:true, isMulti:false});
    const [showLicence, setShowLicence] = useState(false);
    const licencesProps = useEnumeratedSelect(LicenseType, "Enumerations.LicenceTyp", "number", {isClearable:true});
    const regionProps = useEnumeratedSelect(Region, "Enumerations.Region", "number", {isClearable:true});
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const mapper = new Mapper({constructor: Provozovna} )

    return (
        <>
            <LicenceForm
                open={showLicence}
                firma={props.defaultQueryParameters?.firma}
                provozovna={props.provozovna}
                onSaved={() => setShowLicence(false)}
                onClose={() => setShowLicence(false)}
            />
            {
                !showLicence&&<Dial<User, UserFilter>
                    mode={props.mode}
                    config={{
                        id:"uzivatele_z_firmy_user",
                        clazz: User,
                        tableTitle: t("Dials.Users"),
                        endpoint: props.adminView ? "admin/user" : "user",
                        defaultQueryParameters: props.defaultQueryParameters,
                        alternativeButtons: !props.adminView ? undefined : [{
                            icon:()=><AddIcon />,
                            tooltip:t("User.ToolTipGenerateUsers"),
                            onClick: () => setShowLicence(true)
                        }],
                        options: {
                            rowStyle: (data: any) => {
                                if (data.roles && data.roles.indexOf(UserRole.ROLE_MASTER) > -1) return globalStyles.rowStyleUserMaster;
                                return undefined;
                            }
                        },
                        columns: [
                            {
                                field: "login", defaultSort: "asc", title: t("User.login"),
                                filterProps: () => ({type: FormInputType.Text})
                            },
                            {
                                field: "jmeno", title: t("User.jmeno"),
                                filterProps: () => ({type: FormInputType.Text})
                            },
                            {
                                field: "email", title: t("Default.Email"),
                                filterProps: () => ({type: FormInputType.Text})
                            },
                            {
                                field: "role", title: t("User.roles"),
                                filterProps: () => ({type: FormInputType.Select, selectProps: rolesSelect}),
                                render:(row: User)=> {return exist(row.roles) && row.roles.map((d: any) => t('Enumerations.Roles.' + d)).join(', ')},
                                sorting: false
                            },
                            {
                                field: "region", title: t("Default.region"),
                                filterProps:() => ({type: FormInputType.Select, selectProps: regionProps}),
                                render:(row: User)=> {return exist(row.region) &&  t(`Enumerations.Region.${row.region}`)}
                            },
                            {
                                field: "typLicence", title: t("Default.typLicence"),
                                filterProps:() => ({type: FormInputType.Select, selectProps: licencesProps}),
                                render:(row: User)=> {return exist(row.typLicence) &&  t(`Enumerations.LicenceTyp.${row.typLicence}`)}
                            },
                            {
                                field: "phoneNumbers", title: t("Default.PhoneNumbers"),
                                filterProps:() => ({type: FormInputType.Text}),
                                render:(row: User) => row.phoneNumbers?.join(", ") ?? undefined
                            },
                            { title:t("Default.Enabled"), field:"enabled", render:(data: User)=><CheckMark checked={data.enabled}/>},
                            ...props.additionalColumns ?? [],
                            {
                                field: "isDisp", title: t("Preprava.dispecer"),
                                filterProps:() => ({type: FormInputType.Select, selectProps: yesNoSelect}),
                                render:(row: User) => (<CheckMark checked={row.isDispecer()}/>)
                            },
                            {
                                field: "datCas", title: t("WithUserBase.DatCas"),
                                render: (data) => data.datCas?.format("L LT")
                            },
                            {
                                field: "kdoZmenil", title: t("User.KdoZmenil"),
                                render: (data) => (data.kdoZmenil?.toString() ?? ''),
                                filterProps: () => ({type: FormInputType.Text})
                            }
                        ],
                        filtering: true,
                        hideAddNewActions:false
                    }}
                    tabs={tabs}
                    hideNewButtonOnEdit
                    crudConfig={{editEnabled: true, removeEnabled:false, addEnabled:false}}
                    tabDetailUrl={'/admin/uzivatele'}
                    tabDetailData={{provozovna: props.provozovna && mapper.writeValueAsJson(props.provozovna, {springSupport: false}), firma:props.defaultQueryParameters?.firma, roles: [UserRole.ROLE_USER]}}
                />
            }
            </>
    );
}
