import {Avatar, Grid} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from 'react';
import {CodeBookForm} from "../../../../raal_components/controller/CodebookForm";
import {SbernaSluzbaZadani} from "../../../../model/PrepravaVozidlo";
import {FormButton, FormField, FormInputType, useForm} from "../../../../raal_components/form/Form";
import {FormNominatim} from "../../../../../common/component/form/FormNominatim";
import {Waypoint} from "../../../../model/Waypoint";
import {useTranslation} from "react-i18next";
import {
    useCiselnikSelect,
    useCurrencySelectObj,
    useProfilVozidlaSelectObj
} from "../../../../raal_components/SelectOptions";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import Divider from "@material-ui/core/Divider";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import {CustomFieldComponentProps} from "../../../../../common/component/form/FormFieldInterface";
import {cloneClassObject, exist} from "../../../../../common/utils/Util";
import {sizeFormat, vahaConstraint} from "../_vp/PrepravaAVozidlaShared";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {brown, deepOrange, deepPurple, green} from "@material-ui/core/colors";
import {FieldError} from "../../../../../common/component/form/ValidationError";
import {FormSectionCard} from "../../../../../common/component/form/FormSectionCard";
import {njsSwitch} from "../_vp/VPFilter";

interface SbernaSluzbaFormProps {
    sbernaSluzbaZadani: SbernaSluzbaZadani;
    commit: (sbernaSluzbaZadani: SbernaSluzbaZadani) => void;
}

export function SbernaSluzbaZadaniForm(props: SbernaSluzbaFormProps) {
    const {t} = useTranslation();
    const [sbernaSluzbaZadani, setSbernaSluzbaZadani] = useState(() => cloneClassObject(props.sbernaSluzbaZadani));

    useEffect(() => {
        setSbernaSluzbaZadani(props.sbernaSluzbaZadani);
    }, [props.sbernaSluzbaZadani]);

    return <CodeBookForm
        excludeFieldsForDirtyCheck={Object.keys(sbernaSluzbaZadani)}
        hideNewButtonOnEdit
        hideSaveButton
        data={sbernaSluzbaZadani}
        clazz={SbernaSluzbaZadani}
        url={""}
        focusFieldWhenMounted
        render={() =>
            <SbernaSluzbaZadaniContent {...props}/>}

        validate={data => {
            console.log("VALIDATE", data);
            if (!exist(data.profilVozidla?.currency) || !exist(data.profilVozidla?.spotreba) || !exist(data.profilVozidla?.cenaPaliva)) {
                return [FieldError.Create(t("SbernaSluzba.profilVozidlaAtributy"), false, "profilVozidla")];
            } else {
                return null;
            }
        }
        }
    />
}

export function SbernaSluzbaZadaniContent(props: SbernaSluzbaFormProps) {
    const {t} = useTranslation();
    const {data} = useForm<SbernaSluzbaZadani>();
    const ciselnikSelectFilter = useCiselnikSelect(CiselnikTyp.R, {isClearable: true, isMulti: true});
    const profilVozidlaSelectProps = useProfilVozidlaSelectObj({isClearable: true});
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const {classes: cls} = useStyleContext();
    const compareRegion = useCallback((field: "nakladka" | "vykladka") => {
        const nakladka = data.nakladka?.countryCode;
        const vykladka = data.vykladka?.countryCode;
        return (field === "vykladka" && nakladka === 'CZ') || (field === "nakladka" && vykladka === "CZ");
    }, [data])

    return <><Grid container spacing={2}>
        <FormSectionCard title={t("SbernaSluzba.trasa")} avatar={<Avatar style={{backgroundColor: green[500]}}><FontAwesomeIcon icon={faIcon.faRoute} size="sm"/></Avatar>}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormField required forceUpdateWhenDataChanged={["button"]} title={t("Dokladky.nakladka")} name={"nakladka"} type={"Custom"}
                           customComponent={FormNominatim}
                           getValue={(data) => {
                               return data.nakladka?.nazevMista;
                           }}
                           setValue={(data, fieldName, value) => {
                               data.nakladka.nazevMista = value;
                           }}
                           customComponentOptions={{
                               disableClearable: true,
                               autofillValue: true,
                               checkLicense: true,
                               countryCodeForCompare: () => compareRegion("nakladka"),
                               onChange: (osmPlace: any) => {
                                   data.nakladka = Waypoint.fromOsmPlace(osmPlace);
                               }
                           }}
                />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormField forceUpdateWhenDataChanged={["button"]} title={t("Dokladky.vykladka")} name={"vykladka"} type={"Custom"}
                           customComponent={FormNominatim}
                           getValue={(data) => {
                               return data.vykladka?.nazevMista;
                           }}
                           setValue={(data, fieldName, value) => {
                               if(!exist(value))
                                   data.vykladka=null;
                               else
                                data.vykladka.nazevMista = value;
                           }}
                           customComponentOptions={{
                               disableClearable: false,
                               autofillValue: true,
                               checkLicense: true,
                               countryCodeForCompare: () => compareRegion("vykladka"),
                               onChange: (osmPlace: any) => {
                                   data.vykladka = osmPlace && Waypoint.fromOsmPlace(osmPlace);
                               }
                           }}
                />
            </Grid>
        </FormSectionCard>
        <FormSectionCard title={t("SbernaSluzba.vozidlo")} avatar={<Avatar style={{backgroundColor: brown[500]}}><FontAwesomeIcon icon={faIcon.faTruck} size="sm"/></Avatar>}>
            <Grid item lg={4} md={6} sm={12} xs={12}>
                <FormField forceUpdateWhenDataChanged={["button", "delka", "vaha", "currency"]} required title={t("Trasa.profilVozidla")} name="profilVozidla" type={FormInputType.Select} selectProps={profilVozidlaSelectProps} setValue={(data1, fieldName, value) => {
                    data.profilVozidla = value;
                    data.delka = value?.delka;
                    data.vaha = value?.vaha;
                    data.currency = value?.currency;
                }}/>

            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
                <FormField required forceUpdateWhenDataChanged={["button"]} title={t("Preprava.delka")} name={"delka"} type={FormInputType.Number} numberProps={{format:sizeFormat, constraint:vahaConstraint}}/>
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
                <FormField required forceUpdateWhenDataChanged={["button"]} title={t("Preprava.vaha")} name={"vaha"} type={FormInputType.Number} numberProps={{format:sizeFormat, constraint:vahaConstraint}}/>
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
                <FormField title={t("Currency.Title")} name='currency' type='select' selectProps={currencySelectProps} required/>
            </Grid>
            <Grid item lg={8} md={12} sm={12} xs={12}>
                <FormField forceUpdateWhenDataChanged={["button"]} title={t("Preprava.druhy")} name={"druhySbernaSluzba"} type={"select"}
                           selectProps={ciselnikSelectFilter}/>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <FormField title={t("Preprava.naves")} name={"naves"} type={"checkbox"} onWillChange={(field, newValue) => {
                    if (newValue) njsSwitch('N', data);
                }} forceUpdateWhenDataChanged={['souprava', 'jine']} />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <FormField title={t("Preprava.souprava")} name={"souprava"} type={"checkbox"} onWillChange={(field, newValue) => {
                    if (newValue) njsSwitch('S', data)
                }} forceUpdateWhenDataChanged={['naves', 'jine']} />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <FormField title={t("Preprava.jine")} name={"jine"} type={"checkbox"} onWillChange={(field, newValue)=> {
                    if (newValue) njsSwitch('J', data)
                }} forceUpdateWhenDataChanged={['souprava', 'naves']} />
            </Grid>
        </FormSectionCard>
        <FormSectionCard title={t("SbernaSluzba.datum")} avatar={<Avatar style={{backgroundColor: deepOrange[500]}}><FontAwesomeIcon icon={faIcon.faCalendar} size="sm"/></Avatar>}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormField required forceUpdateWhenDataChanged={["button"]} title={t("SbernaSluzba.datumOd")} name={"datumOd"} type={FormInputType.DateTime}
                           dateTimeOptions={{
                               dateTimePickerProps:{
                                   disablePast:true,
                               },
                               timeFormat: false
                           }}/>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormField forceUpdateWhenDataChanged={["button"]} title={t("SbernaSluzba.datumDo")} name={"datumDo"} type={FormInputType.DateTime}
                           dateTimeOptions={{
                               timeFormat: false,
                               dateTimePickerProps:{
                                   disablePast:true,
                               },
                               endOfDayTime: true
                           }}/>
            </Grid>
        </FormSectionCard>
        <FormSectionCard title={t("SbernaSluzba.parametryVypoctu")} avatar={<Avatar style={{backgroundColor: deepPurple[500]}}><FontAwesomeIcon icon={faIcon.faPercent} size="sm"/></Avatar>}>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField title={t("SbernaSluzba.maxPrejezd")} name={"maxPrejezd"} type={FormInputType.Number} numberProps={{format:sizeFormat, constraint:vahaConstraint}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField title={t("SbernaSluzba.maxZajizdka")} name={"maxZajizdka"} type={FormInputType.Number} numberProps={{format:sizeFormat, constraint:vahaConstraint}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField title={t("SbernaSluzba.maxDelka")} name={"maxDelka"} type={FormInputType.Number} numberProps={{format:sizeFormat, constraint:vahaConstraint}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField title={t("SbernaSluzba.maxVaha")} name={"maxVaha"} type={FormInputType.Number} numberProps={{format:sizeFormat, constraint:vahaConstraint}}/>
            </Grid>
        </FormSectionCard>

    </Grid>
        <Divider className={cls.hrClass}/>
        <div className="modal-buttons">
            <FormField type={FormInputType.Custom} name={"button"} customComponent={SbernaSluzbaCalcButton} customComponentOptions={{props: props}}/>
        </div>
    </>;
}

function SbernaSluzbaCalcButton(props: CustomFieldComponentProps<any, any>) {
    const {t} = useTranslation();
    const {data, validate} = useForm<SbernaSluzbaZadani>();

    return <FormButton disabled={!data.isFilled()} key={"update"} type={"update"} skipBlock forceTabClick onClick={() => {
        if(validate())
            props.options.props.commit(cloneClassObject(data));
    }}>{t("SbernaSluzba.calculate")}</FormButton>
}
