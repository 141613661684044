import {
    Callback,
    httpEndpointCustom,
    resolveFetchParams,
    useFetchArray,
    useFetchCustom,
    useFetchWithCallback
} from "../../common/utils/HttpUtils";
import {Bleskovka} from "../model/Zprava";

const thunderNewsUrl = "thunder-news";

export const useCountUnreadMessages = (callback?:Callback<number>) => {
   return useFetchCustom<number>(`${thunderNewsUrl}/count`, callback);
};

export const useFetchAllMessages = (callback?:Callback<Bleskovka[]>) => {
  return useFetchArray<Bleskovka>(Bleskovka, `${thunderNewsUrl}`, callback);
};

export const useMarkRead = () => {
   return useFetchWithCallback<Bleskovka[], Bleskovka>(async (params)=> {
      const options = resolveFetchParams({}, params);
      await httpEndpointCustom(`${thunderNewsUrl}/mark-as-read?zprava=${options?.arg?.zprava.id}`, {method:"POST"});
      return null;
   })
};
