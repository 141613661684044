import React, { useCallback, useEffect } from 'react';
import { Content } from './Content';
import { useStyleContext } from './context/ThemeModeContext';
import { useAppContext } from './context/AppContext';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { ErrorComponent } from './ErrorComponent';
import { Consent } from './page/Dials/master/Consent';
import Environment from './Environment';
import { DynamicTitle } from "./raal_components/DynamicTitle";
import 'react-quill/dist/quill.snow.css';

const App = () => {
	const { classes } = useStyleContext();
	const { checkDataChanged } = useAppContext();
	const { user } = useAppContext();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const onUnload = useCallback((event: BeforeUnloadEvent) => {
		if (checkDataChanged(null)) {
			event.preventDefault();
			event.returnValue = t('DataChangedDialog.Body');
		}
		// eslint-disable-next-line
	}, [checkDataChanged]);

	useEffect(() => {
		window.removeEventListener('beforeunload', (event) => onUnload(event));
		window.addEventListener('beforeunload', (event) => onUnload(event));
		return () => {
			window.removeEventListener('beforeunload', (event) => onUnload(event));
		};
		// eslint-disable-next-line
	}, []);

	const switchComponent = () => {
		if (pathname === '/error') {
			return <ErrorComponent/>;
		}
		if (user.signDocumentation) {
			return <Consent/>;
		}
		return <Content/>;
	};

	return (
		<div className={classes.root} style={{ justifyContent: 'center' }}>
			<Environment />
			<DynamicTitle title={t('AppTitle').toUpperCase()}/>
			{switchComponent()}
		</div>
	);
};

export default App;
