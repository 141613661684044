import React, { useEffect, useRef, useState } from 'react';
import { ProfilVozidla, ProfilVozidlaSelect } from '../../../../model/ProfilVozidla';
import { JsonProperty } from '../../../../../common/utils/objectmapper/Mapper';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { useCurrencySelectObj, useHereProfilVozidlaSelectObj, useProfilVozidlaSelectObj } from '../../../../raal_components/SelectOptions';
import { FormInputType } from '../../../../raal_components/form/Form';
import { useTranslation } from 'react-i18next';
import { exist } from '../../../../../common/utils/Util';
import { Currency } from '../../../../model/Currency';
import { HttpResultSimple, useFetchCustom } from '../../../../../common/utils/HttpUtils';
import { StandaloneField } from '../../../../../common/component/form/StandaloneField';
import { ModalTrasaFormProps } from './ModalTrasaForm';
import { invoke } from '../../../../../common/utils/Invoke';
import { ModalPrejezdFilter, ModalPrejezdFilterExposed } from './ModalPrejezdFilter';
import { DokladkaZadani } from '../../../../model/PrepravaVozidlo';
import { ModalDokladkaFilter, ModalDokladkaFilterExposed } from './ModalDokladkaFilter';
import { Vozidlo } from '../../../../model/Vozidlo';
import { Preprava } from '../../../../model/Preprava';
import { KilometrovnikVozidlaPart } from './KilometrovnikVozidlaPart';
import { GoogleMapsButton } from '../../../../raal_components/parts/GoogleMapsComponents';
import DataStorage from '../../../../../common/DataStorage';
import { getConfig } from '../../../../../Config';
import { HereProfilVozidla, HereProfilVozidlaSelect } from '../../../../model/HereProfilVozidla';
import { HereProfilVozidlaForm } from './HereTrasa';
import { HereResponse } from './HereResponse';
import { useCachedFetch } from './HereCache';
import { HereViaPoints } from './HereViaPoints';

export enum KilometrovnikLinkType {PREPRAVA, VOZIDLO}

export enum ButtonType {TRASA = "TRASA", NAKLADY = "NAKLADY", TRASA_A_NAKLADY = "TRASA_A_NAKLADY"}

export class KilometrovnikLinkState {
    static of(offerId: number, linkType: KilometrovnikLinkType): KilometrovnikLinkState {
        const r = new KilometrovnikLinkState();
        r.linkType = linkType;
        r.offerId = offerId;
        return r;
    }

    @JsonProperty({type:{clazz:ProfilVozidlaSelect}})
    profilVozidla?: ProfilVozidla;
    @JsonProperty({type:{clazz:Currency}})
    currency: Currency;
    offerId: number;
    linkType: KilometrovnikLinkType;
	@JsonProperty({type:{clazz:HereProfilVozidlaSelect}})
	hereProfilVozidla?: HereProfilVozidla;
	hereTrasa?: HereResponse;
	hereTrasaError?: HttpResultSimple;
}

export interface KilometrovnikLinkPartProps {
    offerId: number
    data?: Preprava | Vozidlo
    linkType: KilometrovnikLinkType
    checkIsEqual?: () => void
    changeMainFocus?: (state: boolean) => void
    switchType: boolean
    isSbernaSluzba?: boolean
    zadani?: boolean
    hideDokladky?: boolean
}

export function KilometrovnikLinkPart(props: KilometrovnikLinkPartProps) {
    const [state, setState] = useState(() => KilometrovnikLinkState.of(props.offerId, props.linkType))
    const {fetch} = useFetchCustom<ProfilVozidla>({ endpoint: props.linkType===KilometrovnikLinkType.PREPRAVA ? 'user/profilvozidla/default-preprava' : 'user/profilvozidla/default-vozidlo'}, undefined, ProfilVozidla);
	const {fetch:hereFetch} = useFetchCustom<HereProfilVozidla>({ endpoint: props.linkType===KilometrovnikLinkType.PREPRAVA ? 'user/hereprofilvozidla/default-preprava' : 'user/hereprofilvozidla/default-vozidlo'}, undefined, HereProfilVozidla);
    const {t} = useTranslation();
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const profilVozidlaSelectProps = useProfilVozidlaSelectObj({isClearable: true, autoOpenDisabled: true, params: {profileType: props.linkType===KilometrovnikLinkType.PREPRAVA ? "PREPRAVA" : "VOZIDLO"}});
	const hereProfilVozidlaSelectProps = useHereProfilVozidlaSelectObj({isClearable: true, autoOpenDisabled: true, params: {profileType: props.linkType===KilometrovnikLinkType.PREPRAVA ? "PREPRAVA" : "VOZIDLO"}});
    const refPrejezdy = useRef<ModalPrejezdFilterExposed>();
    const refDokladky = useRef<ModalDokladkaFilterExposed>();
	const { fetchTrasa, loading: hereLoading, error: hereTrasaError } = useCachedFetch();
	const [viaItems, setViaItems] = useState<any[]>([]);

    useEffect(() => {
        getProfil();
		getHereProfil();
        // eslint-disable-next-line
    }, [props.offerId]);

	useEffect(() => {
		if (state.hereTrasa?.routing?.routes?.length > 0 || state.hereTrasa?.tolls?.routes?.length) {
			getHereTrasa(ButtonType.TRASA);
			const targetElement = document.getElementById("scrollPoint");
			targetElement.scrollIntoView({ behavior: 'smooth' });
		}
	}, [viaItems]);

    const getProfil = () => {
        fetch().then((d) => {
            if(exist(d.id))
                setState({...state, profilVozidla: d, currency: d.currency})
        })
    }
	const getHereProfil = () => {
		hereFetch().then((d) => {
			if(exist(d.id))
				setState({...state, hereProfilVozidla: d})
		})
	}

    const getData = (): DokladkaZadani => {
        const dokladka = new DokladkaZadani();
        dokladka.nakladka = props.data.getMista()[0];
        dokladka.vykladka = props.data.getMista()[1];
        dokladka.delka = state.profilVozidla?.delka && state.profilVozidla.delka > props.data.delka ? Number((state.profilVozidla?.delka - props.data.delka).toFixed(2)) : null;
        dokladka.vaha = state.profilVozidla?.vaha && state.profilVozidla.vaha > props.data.vaha ? Number((state.profilVozidla?.vaha - props.data.vaha).toFixed(2)) : null;
        dokladka.excludedNabidkaId = props.data.id;

        return dokladka;
    }

    const getTrasa = (routeOnly?: boolean) => {
        const storageKey = `kilometrovnik-trasa`;
        const trasaData : ModalTrasaFormProps = {
            profilVozidla: state.profilVozidla,
            currency: state.currency,
            routeOnly: routeOnly ?? false,
            linkType: props.linkType,
            offerId: props.offerId,
            prejezd: null,
            dokladka: null,
            data: null
        };
        DataStorage.set(storageKey, JSON.stringify(trasaData),  true, 'session');
        DataStorage.redirectWithTargetBlank(`/kilometrovnik/trasa`);
        DataStorage.clear(storageKey, true, 'session');
    }

	const getHereTrasa = async (buttonType: ButtonType) => {
		const id = props.data.id;
		const profileId = state.hereProfilVozidla?.id;
		const linkType = props.linkType;

		try {
			const data = await fetchTrasa(id, profileId, linkType, buttonType, getAnotherUrlParams());
			if (exist(data)) {
				setState({...state, hereTrasa: data});
			}
		} catch (e) {
			// Handle error
			console.log(e);
		}
	};

	const getAnotherUrlParams = () => {
		let anotherUrlParams = '';
		if (viaItems?.length > 0) {
			viaItems.map((item) => {
				anotherUrlParams += "&via=" + item.content.mistoKam.koordinat.coordinates[1] + "," + item.content.mistoKam.koordinat.coordinates[0];
			});
		}
		return anotherUrlParams;
	};

    return <Grid container spacing={2}>
        {getConfig().geoEnabled &&
            <><Grid item xs={12} md={2}>
                <StandaloneField title={t("Trasa.profilVozidla")} value={state.profilVozidla}
                                 onValueChanged={v => setState({...state, profilVozidla: v, currency: v?.currency})}
                                 type={FormInputType.Select} selectProps={profilVozidlaSelectProps}/>
            </Grid><Grid item xs={12} md={2}>
                <StandaloneField title={t("Trasa.currency")} value={state.currency}
                                 onValueChanged={v => setState({...state, currency: v})} type={FormInputType.Select}
                                 selectProps={currencySelectProps}/>
            </Grid></>
        }
		{getConfig().hereEnabled &&
		    <Grid item xs={12} md={2}>
			    <StandaloneField title={t("Here.Kilometrovnik.ProfilVozidla")} value={state.hereProfilVozidla}
			                     onValueChanged={v => setState({...state, hereProfilVozidla: v})}
			                     type={FormInputType.Select} selectProps={hereProfilVozidlaSelectProps}/>
		    </Grid>
		}
        <Grid item xs={12} md={8}>
            <Grid item container spacing={1}>
                {getConfig().geoEnabled &&
					<>
                    	<KilometrovnikVozidlaPart profilVozidla={state.profilVozidla} getProfil={getProfil} checkIsEqual={props.checkIsEqual} />
					</>
                }
                <Grid item>
                    <GoogleMapsButton from={props.data.getMista()[0].nazevMista} to={props.data.getMista()[1].nazevMista} />
                </Grid>
                {getConfig().geoEnabled &&
                    <><Grid item>
                        <Button type={"button"} variant="contained" color={"primary"}
                                disabled={!(exist(state.profilVozidla) && exist(state.currency))}
                                onClick={() => getTrasa(true)}>{t("Kilometrovnik.DisplayRouteOnly")}</Button>
                    </Grid><Grid item>
                        <Button type={"button"} variant="contained" color={"primary"}
                                disabled={!(exist(state.profilVozidla) && exist(state.currency))}
                                onClick={() => getTrasa()}>{t("Kilometrovnik.DisplayRoute")}</Button>
                    </Grid></>
                }
				{getConfig().hereEnabled && (
					<>
						<Grid item>
							<Button type={"button"} variant="contained" color={"primary"}
									disabled={!(exist(state.hereProfilVozidla))}
									onClick={() => getHereTrasa(ButtonType.TRASA)}>{t("Here.Kilometrovnik.Button.Trasa")}</Button>
						</Grid>
						<Grid item>
							<Button type={"button"} variant="contained" color={"primary"}
									disabled={!(exist(state.hereProfilVozidla))}
									onClick={() => getHereTrasa(ButtonType.NAKLADY)}>{t("Here.Kilometrovnik.Button.Naklady")}</Button>
						</Grid>
						<Grid item>
							<Button type={"button"} variant="contained" color={"primary"}
									disabled={!(exist(state.hereProfilVozidla))}
									onClick={() => getHereTrasa(ButtonType.TRASA_A_NAKLADY)}>{t("Here.Kilometrovnik.Button.TrasaANaklady")}</Button>
						</Grid>
					</>
				)}
                {!props.hideDokladky && props.data?.getMista()?.length === 2 && props.linkType === KilometrovnikLinkType.PREPRAVA && !props.isSbernaSluzba && !props.zadani ?
                    <Grid item><Button type={"button"} variant="contained" color={"primary"}
                                       onClick={() => {
                                           refDokladky.current?.setModalVisibility();
                                           invoke(props.changeMainFocus,true);
                                       }}>{t("Dokladky.dokladky")}</Button>
                    </Grid> : null
                }
                {props.data?.getMista()?.length === 2 && !props.isSbernaSluzba ? <Grid item><Button type={"button"} variant="contained" color={"primary"}
                                    onClick={() => {
                                        refPrejezdy.current?.setModalVisibility(true);
                                        invoke(props.changeMainFocus,true);
                                    }}>{!props.switchType ? t("Prejezdy.prejezdy") : props.linkType === KilometrovnikLinkType.PREPRAVA ? t("Prejezdy.volneVozy") : t("Prejezdy.prepravy")}</Button></Grid> : null}
            </Grid>
        </Grid>
		{hereLoading && (
			<Grid container item xs={12}>
				<Grid item xs={12}>
					<Box>
						<CircularProgress color="inherit" />
					</Box>
				</Grid>
			</Grid>)
		}
		{state.hereTrasa &&
			<Grid item style={{ position: 'relative' }}>
				{hereLoading && (
					<Grid
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(128, 128, 128, 0.5)',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							zIndex: 1,
						}}
					>
						<CircularProgress/>
					</Grid>
				)}
				<div id="scrollPoint" style={{display: "hidden"}} />
				<Grid style={{ position: 'relative', zIndex: 0 }}>
					{state.hereTrasa && (
						<HereProfilVozidlaForm
							routing={state.hereTrasa.routing}
							tolls={state.hereTrasa.tolls}
							uniqueLocations={state.hereTrasa.uniqueLocations}
							hereProfilVozidla={state.hereProfilVozidla}
						/>
					)}
				</Grid>
			</Grid>
		}
		{getConfig().hereEnabled && (
			<HereViaPoints viaItems={viaItems} setViaItems={setViaItems} data={props.data} routing={state.hereTrasa?.routing} tolls={state.hereTrasa?.tolls}
						   getHereTrasa={getHereTrasa}/>
		)}
		{hereTrasaError && (
			<Grid container item xs={12}>
				<Grid item xs={12}>
					{t('Here.TrasaDetail.HereError')}
				</Grid>
				<Grid item xs={12}>
					{hereTrasaError?.json?.message}
				</Grid>
			</Grid>)
		}
		{exist(props.data) ? <ModalPrejezdFilter
			modalRef={refPrejezdy}
			data={props.data}
			excludedNabidkaId={!props.switchType ? props.data.id : null}
			nabidkaId={props.switchType ? props.data.id : null}
			onClose={() => invoke(props.changeMainFocus, false)}
			redirectAfterSend={false}
			switchType={props.switchType}
			type={props.linkType} /> : null}
        {exist(props.data) ? <ModalDokladkaFilter
            modalRef={refDokladky}
            onClose={() => invoke(props.changeMainFocus,false)}
            dokladkaZadani={getData()}
            redirectAfterSend={false} /> : null}
    </Grid>
}
