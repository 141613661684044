import { useTranslation } from 'react-i18next';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Card,
	CardContent,
	createStyles,
	Grid,
	ListItem,
	ListItemText,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { TextTuple } from '../../../../../common/component/form/TextTuple';
import { useStyleContext } from '../../../../context/ThemeModeContext';
import Divider from '@material-ui/core/Divider';
import { carPlates } from '../../../../model/HereProfilVozidla';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { HereMapComponent } from './HereMapComponent';
import { Fare, HereResponse, Item, Location, Toll } from './HereResponse';

interface PriceAccumulator {
	totalConvertedPrice: number;
	totalConvertedPriceCurrency: string;
	totalPriceByCurrency: { [currency: string]: number };
}

const useStyles = makeStyles((theme) => createStyles({
	strikethrough: {
		textDecoration: 'line-through',
	},
	itemBorder: {
		border: '1px solid black',
	},
	noPadding: {
		padding: '0 !important',
	},
	padding6: {
		paddingLeft: '16px !important',
		paddingRight: '16px !important',
		paddingTop: '8px !important',
		paddingBottom: '8px !important',
	},
	secondDivHighlight: {
		'& > span > div:nth-child(2)': {
			color: 'red',
		}
	},
	secondDivHighlightAndBold: {
		'& > span > div:nth-child(2)': {
			color: 'red',
			fontWeight: 'bold',
		}
	}
}));

export function HereProfilVozidlaForm(props: HereResponse) {
	const { t } = useTranslation();
	const {classes} = useStyleContext();
	const classesHere = useStyles();
	const [displayTollDetail, setDisplayTollDetail] = useState([false]);
	const [displayMap, setDisplayMap] = useState(true);

	const toggleDisplayTollDetail = (index: number) => {
		setDisplayTollDetail(prev => {
			const updated = [...prev];
			if (!updated[index]) {
				updated[index] = true;
			} else {
				updated[index] = !updated[index];
			}
			return updated;
		});
	};

	const findLocationByLatAndLng = (uniqueLocations: Set<Location>, lat: number, lng: number): Location | undefined => {
		const locationsArray = Array.from(uniqueLocations);
		return locationsArray.find(location => location.lat === lat && location.lng === lng);
	}

	const renderTollSums = () => {
		let total: PriceAccumulator = {
			totalConvertedPrice: 0,
			totalConvertedPriceCurrency: "",
			totalPriceByCurrency: {}
		};
		let displayError = false;

		props.tolls?.routes[0]?.sections?.forEach((section) => {
			const totals = getTotals(section.tolls);

			total.totalConvertedPrice += totals.totalConvertedPrice;
			total.totalConvertedPriceCurrency = totals.totalConvertedPriceCurrency;

			Object.keys(totals.totalPriceByCurrency).forEach(currency => {
				if (currency in total.totalPriceByCurrency) {
					total.totalPriceByCurrency[currency] += totals.totalPriceByCurrency[currency];
				} else {
					total.totalPriceByCurrency[currency] = totals.totalPriceByCurrency[currency];
				}
			});

			if (section.transport?.mode != 'truck') {
				displayError = true;
			}
		});

		return (
			<>
				<Grid container item xs={12} spacing={3} className={classesHere.noPadding}>
					<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
						<ListItem>
							<ListItemText className={classesHere.secondDivHighlightAndBold}>
								<TextTuple title={t('Here.TrasaDetail.PoplatkyCelkem')}
										   content={`${total.totalConvertedPrice.toFixed(2)} ${total.totalConvertedPriceCurrency}`}/>
							</ListItemText>
						</ListItem>
					</Grid>
					{Object.entries(total.totalPriceByCurrency).map(([currency, sum], i) => (
						<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding} key={`renderTollSums-${i}`}>
							<ListItem>
								<ListItemText className={classesHere.secondDivHighlight}>
									<TextTuple title={t('Here.TrasaDetail.PoplatkyV') + ' ' + currency}
											   content={`${sum.toFixed(2)} ${currency}`}/>
								</ListItemText>
							</ListItem>
						</Grid>
					))}
				</Grid>
				{displayError &&
				<Grid container className={classesHere.noPadding} spacing={3}>
					<Grid item xs={12} className={classesHere.noPadding}>
						<ListItem>
							<ListItemText className={classesHere.secondDivHighlight}>
								<TextTuple title={t('Here.TrasaDetail.Upozorneni')}
										   content={t('Here.TrasaDetail.NotTruckTransportNotification')}/>
							</ListItemText>
						</ListItem>
					</Grid>
				</Grid>
				}
			</>
		);
	}

	const getTotals = (tolls: Toll[]): PriceAccumulator => {
		return tolls?.reduce(
			(acc: PriceAccumulator, currentToll) => {
				// Vezme všechny poplatky v rámci jednoho mýta. Ale mohou tam být předplatné denní, měsíční a roční. Potom je výsledný součet zavádějící.
				// const localPriceSum = currentToll.fares.reduce((sum, fare) => sum + fare.price.value, 0);
				// const convertedPriceSum = currentToll.fares.reduce((sum, fare) => sum + fare.convertedPrice.value, 0);
				// Vezme pouze první poplatek v rámci mýta.
				const localPriceSum = currentToll.fares[0].price.value;
				const convertedPriceSum = currentToll.fares[0].convertedPrice.value;

				// Add to total sums
				acc.totalConvertedPrice += convertedPriceSum;
				acc.totalConvertedPriceCurrency = currentToll.fares[0].convertedPrice.currency;
				const currency = currentToll.fares[0]?.price.currency ?? 'unknown';
				acc.totalPriceByCurrency[currency] = (acc.totalPriceByCurrency[currency] || 0) + localPriceSum;

				return acc;
			},
			{ totalConvertedPrice: 0, totalConvertedPriceCurrency: '', totalPriceByCurrency: {} }
		);
	}

	const renderTollSumsForSection = (tolls: Toll[]) => {
		const totals = getTotals(tolls);

		return (
			<Grid container item xs={12} spacing={3} className={classesHere.noPadding}>
				<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
					<ListItem>
						<ListItemText className={tollsHighlightAndBoldPricesSoloSection}>
							<TextTuple title={t('Here.TrasaDetail.PoplatkyCelkem')}
									   content={`${totals.totalConvertedPrice.toFixed(2)} ${totals.totalConvertedPriceCurrency}`}/>
						</ListItemText>
					</ListItem>
				</Grid>
				{Object.entries(totals.totalPriceByCurrency).map(([currency, sum], i) => (
					<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding} key={`renderTollSumsForSection-${i}`}>
						<ListItem>
							<ListItemText className={tollsHighlightPricesSoloSection}>
								<TextTuple title={t('Here.TrasaDetail.PoplatkyV') + ' ' + currency}
										   content={`${sum.toFixed(2)} ${currency}`}/>
							</ListItemText>
						</ListItem>
					</Grid>
				))}
			</Grid>
		);
	}

	const renderRoutingSum = () => {
		let summaryLength = 0;
		let summaryDuration = 0;

		props.routing?.routes[0]?.sections?.map((it, index) => {
			summaryLength += it.summary?.length;
			summaryDuration += it.summary?.duration;
		});

		const firstSection = props.routing?.routes[0]?.sections[0];
		const lastSection = props.routing?.routes[0]?.sections[props.routing?.routes[0]?.sections.length-1];

		return (
			<Grid container item xs={12} spacing={3}>
				<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
					<ListItem>
						<ListItemText>
							<TextTuple title={t("Preprava.odkud")} content={`${getLocationTitle(findLocationByLatAndLng(props.uniqueLocations, firstSection.departure?.place?.location?.lat, firstSection.departure?.place?.location?.lng).reverseGeocodingItems?.items[0])}`}/>
						</ListItemText>
					</ListItem>
				</Grid>
				<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
					<ListItem>
						<ListItemText>
							<TextTuple title={t("Preprava.kam")} content={`${getLocationTitle(findLocationByLatAndLng(props.uniqueLocations, lastSection.arrival?.place?.location?.lat, lastSection.arrival?.place?.location?.lng).reverseGeocodingItems?.items[0])}`}/>
						</ListItemText>
					</ListItem>
				</Grid>
				<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
					<ListItem>
						<ListItemText className={classesHere.secondDivHighlightAndBold}>
							<TextTuple title={t("Preprava.AirDistance")} content={`${(summaryLength / 1000).toFixed(1)} km`}/>
						</ListItemText>
					</ListItem>
				</Grid>
				<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
					<ListItem>
						<ListItemText className={classesHere.secondDivHighlightAndBold}>
							<TextTuple title={t("Here.TrasaDetail.OdhadovanaDobaJizdy")} content={`${(summaryDuration / 3600).toFixed(2)} h`}/>
						</ListItemText>
					</ListItem>
				</Grid>
			</Grid>
		)
	}

	const renderCasovyNaklad = () => {
		let summaryLength = 0;
		let summaryDuration = 0;

		props.routing?.routes[0]?.sections?.map((it, index) => {
			summaryLength += it.summary?.length;
			summaryDuration += it.summary?.duration;
		});

		return (
			<>
				{(props.hereProfilVozidla?.casovyNaklad || props.hereProfilVozidla?.nakladNaKm) && (
					<>
						<Card style={{height: "100%", border: '1px solid black', backgroundColor: 'lightcyan'}} className={classes.sectionBackground} elevation={3}>
							<CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}} className={classesHere.padding6}>
								<Typography variant="h6">{t("Here.CustomNaklady.Titulek")}</Typography>
								<Grid container item xs={12} spacing={3}>
									{props.hereProfilVozidla.casovyNaklad && props.hereProfilVozidla.nakladNaKm &&
										<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
											<ListItem>
												<ListItemText className={classesHere.secondDivHighlightAndBold}>
													<TextTuple title={t("Here.CustomNaklady.Celkem")} content={`${((summaryDuration/3600)*props.hereProfilVozidla.casovyNaklad+(summaryLength/1000)*props.hereProfilVozidla.nakladNaKm).toFixed(2)} ${props.hereProfilVozidla.currency}`}/>
												</ListItemText>
											</ListItem>
										</Grid>
									}
									{props.hereProfilVozidla?.casovyNaklad &&
										<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
											<ListItem>
												<ListItemText className={classesHere.secondDivHighlightAndBold}>
													<TextTuple title={t("Here.CustomNaklady.CelkemZaHodin")} content={`${((summaryDuration/3600)*props.hereProfilVozidla.casovyNaklad).toFixed(2)} ${props.hereProfilVozidla.currency}`}/>
												</ListItemText>
											</ListItem>
										</Grid>
									}
									{props.hereProfilVozidla.nakladNaKm &&
										<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
											<ListItem>
												<ListItemText className={classesHere.secondDivHighlightAndBold}>
													<TextTuple title={t("Here.CustomNaklady.CelkemZaKm")} content={`${((summaryLength/1000)*props.hereProfilVozidla.nakladNaKm).toFixed(2)} ${props.hereProfilVozidla.currency}`}/>
												</ListItemText>
											</ListItem>
										</Grid>
									}
								</Grid>
							</CardContent>
						</Card>
					</>
				)}
			</>
		)
	}

	const getLocationTitle = (item: Item): string => {
		const mpz = carPlates[item.address.countryCode];
		const postalCode = item.address.postalCode?.toUpperCase().replace(/\s+/g, '');
		return `${mpz}:${postalCode}:${item.address?.city?.toUpperCase()}`;
	}

	const passDisplay = (fare: Fare) => {
		return (
			<>
				{/*Zobrazení v případě, že je vyplněn atribut pass. Pravděpodobně předplatné za určité období.*/}
				{fare.pass?.validityPeriod?.period && (
					<> - {t(`Here.TrasaDetail.Table.Predplatne.${fare.pass?.validityPeriod?.period}`)}{fare.pass?.validityPeriod?.count && (<> ({fare.pass?.validityPeriod?.count})</>)}</>
				)}
			</>
		)
	}

	const tollsHighlightSoloSection = props.tolls?.routes[0]?.sections?.length === 1 ? { backgroundColor: 'lightgoldenrodyellow' } : {};

	const tollsHighlightAndBoldPricesSoloSection = props.tolls?.routes[0]?.sections?.length === 1 ? classesHere.secondDivHighlightAndBold : '';
	const tollsHighlightPricesSoloSection = props.tolls?.routes[0]?.sections?.length === 1 ? classesHere.secondDivHighlight : '';

	{/*TODO Optimalizovat. Toto bude na klientovi pomalé.*/}
	return <Grid container item spacing={1}>
		{
			<>
				{props.tolls?.routes?.length == 0 && props.routing?.routes?.length == 0 &&
					<Grid item xs={12} sm={12} lg={12} style={{color: "red"}}>
						{t("Here.TrasaDetail.HereErrorNoRoutes")}
					</Grid>
				}
				{props.routing?.routes?.length > 0 &&
					<>
						<Grid item xs={12} sm={12} lg={12}>
							<Card style={{height: "100%", border: '1px solid black', backgroundColor: 'lightcyan'}} elevation={3} >
								<CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}} className={classesHere.padding6}>
									<Box>
										<Typography variant="h6">{t("Here.TrasaDetail.Routing")}</Typography>
									</Box>
									{renderRoutingSum()}
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12}>
							{renderCasovyNaklad()}
						</Grid>
						{props.routing?.routes[0]?.sections?.length > 1 && props.routing?.routes[0]?.sections?.map((it, index) => (
							<Grid item xs={12} key={`routes-${index}`}>
								<Card style={{height: "100%", border: '1px solid black'}} elevation={3}>
									<CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}} className={classesHere.padding6}>
										<Typography variant="h6">{t("Here.TrasaDetail.Routing") + ` (${index+1}/${props.routing?.routes[0]?.sections.length})`}</Typography>
										<Grid container item xs={12} spacing={3} className={classesHere.noPadding}>
											<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
												<ListItem>
													<ListItemText>
														<TextTuple title={t("Preprava.odkud")} content={`${getLocationTitle(findLocationByLatAndLng(props.uniqueLocations, it.departure?.place?.location?.lat, it.departure?.place?.location?.lng).reverseGeocodingItems?.items[0])}`}/>
													</ListItemText>
												</ListItem>
											</Grid>
											<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
												<ListItem>
													<ListItemText>
														<TextTuple title={t("Preprava.kam")} content={`${getLocationTitle(findLocationByLatAndLng(props.uniqueLocations, it.arrival?.place?.location?.lat, it.arrival?.place?.location?.lng).reverseGeocodingItems?.items[0])}`}/>
													</ListItemText>
												</ListItem>
											</Grid>
											<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
												<ListItem>
													<ListItemText>
														<TextTuple title={t("Preprava.AirDistance")} content={`${(it.summary?.length / 1000).toFixed(1)} km`}/>
													</ListItemText>
												</ListItem>
											</Grid>
											<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
												<ListItem>
													<ListItemText>
														<TextTuple title={t("Here.TrasaDetail.OdhadovanaDobaJizdy")} content={`${(it.summary?.duration / 3600).toFixed(2)} h`}/>
													</ListItemText>
												</ListItem>
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						))}
					</>
				}
				{props.tolls?.routes?.length > 0 &&
					<>
						{props.tolls?.routes[0]?.sections?.length > 1 && (
							<Grid item xs={12} sm={12} lg={12}>
								<Card style={{height: "100%", border: '1px solid black', backgroundColor: 'lightgoldenrodyellow'}} elevation={3}>
									<CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
										<Box>
											<Typography variant="h6">{t("Here.TrasaDetail.Poplatky")}</Typography>
										</Box>
											<>
												<Grid container item xs={12} spacing={3} className={classesHere.noPadding}>
													<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
														<ListItem>
															<ListItemText>
																<TextTuple title={t('Preprava.odkud')}
																		   content={`${getLocationTitle(findLocationByLatAndLng(props.uniqueLocations, 
																			   props.tolls?.routes[0]?.sections[0].departure?.place?.location?.lat, 
																			   props.tolls?.routes[0]?.sections[0].departure?.place?.location?.lng).reverseGeocodingItems?.items[0])}`}/>
															</ListItemText>
														</ListItem>
													</Grid>
													<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
														<ListItem>
															<ListItemText>
																<TextTuple title={t('Preprava.kam')}
																		   content={`${getLocationTitle(findLocationByLatAndLng(props.uniqueLocations, 
																			   props.tolls?.routes[0]?.sections[props.tolls?.routes[0]?.sections.length-1].arrival?.place?.location?.lat, 
																			   props.tolls?.routes[0]?.sections[props.tolls?.routes[0]?.sections.length-1].arrival?.place?.location?.lng).reverseGeocodingItems?.items[0])}`}/>
															</ListItemText>
														</ListItem>
													</Grid>
												</Grid>
												{renderTollSums()}
											</>

									</CardContent>
								</Card>
							</Grid>
						)}
						{props.tolls?.routes[0]?.sections?.map((section, index) => (
							<Grid item xs={12} key={`toll-${index}`}>
								<Card style={{height: "100%", border: '1px solid black', ...tollsHighlightSoloSection}} elevation={3}>
									<CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
										{props.tolls?.routes[0]?.sections?.length > 1 && (
											<Box>
												<Typography variant="h6">{t("Here.TrasaDetail.Poplatky") + ` (${index+1}/${props.tolls?.routes[0]?.sections.length})`}</Typography>
											</Box>
										)}
										{props.tolls?.routes[0]?.sections?.length == 1 && (
											<Box>
												<Typography variant="h6">{t("Here.TrasaDetail.Poplatky")}</Typography>
											</Box>
										)}
										<Grid container item xs={12} className={classesHere.noPadding} spacing={3}>
											<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
												<ListItem>
													<ListItemText>
														<TextTuple title={t('Preprava.odkud')}
																   content={`${getLocationTitle(findLocationByLatAndLng(props.uniqueLocations, section.departure?.place?.location?.lat, section.departure?.place?.location?.lng).reverseGeocodingItems?.items[0])}`}/>
													</ListItemText>
												</ListItem>
											</Grid>
											<Grid item lg={3} sm={6} xs={12} className={classesHere.noPadding}>
												<ListItem>
													<ListItemText>
														<TextTuple title={t('Preprava.kam')}
																   content={`${getLocationTitle(findLocationByLatAndLng(props.uniqueLocations, section.arrival?.place?.location?.lat, section.arrival?.place?.location?.lng).reverseGeocodingItems?.items[0])}`}/>
													</ListItemText>
												</ListItem>
											</Grid>
										</Grid>
										{renderTollSumsForSection(section.tolls)}
										{section.transport?.mode != 'truck' &&
											<Grid container item xs={12} className={classesHere.noPadding} spacing={3}>
												<Grid xs={12} className={classesHere.noPadding}>
													<ListItem>
														<ListItemText className={classesHere.secondDivHighlight}>
															<TextTuple title={t('Here.TrasaDetail.Upozorneni')}
																	   content={t('Here.TrasaDetail.NotTruckTransportNotification')}/>
														</ListItemText>
													</ListItem>
												</Grid>
											</Grid>
										}
										{section.transport?.mode == 'truck' && section.tolls?.length == 0 &&
											<Grid container item xs={12} spacing={3}>
												<Grid xs={12} className={classesHere.noPadding}>
													<ListItem>
														<ListItemText className={classesHere.secondDivHighlight}>
															<TextTuple title={t('Here.TrasaDetail.Upozorneni')}
																	   content={t('Here.TrasaDetail.NotTollsTransportNotification')}/>
														</ListItemText>
													</ListItem>
												</Grid>
											</Grid>
										}
										<Grid item xs={12}>
											<Accordion expanded={displayTollDetail[index]} onChange={() => toggleDisplayTollDetail(index)} elevation={3}>
												<AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="p1-content" id="p1-header">
													<Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Here.TrasaDetail.DetailPoplatku")}</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<TableContainer component={Paper}>
														<Table aria-label="simple table">
															<TableHead>
																<TableRow>
																	<TableCell>{t('Here.TrasaDetail.Table.IndexMyta')}</TableCell>
																	{/*<TableCell>{t('Here.TrasaDetail.Table.IndexPoplatku')}</TableCell>*/}
																	<TableCell>{t('Here.TrasaDetail.Table.LokalniCena')}</TableCell>
																	<TableCell>{t('Here.TrasaDetail.Table.KovertovanaCena')}</TableCell>
																	<TableCell>{t('Here.TrasaDetail.Table.Zeme')}</TableCell>
																	<TableCell>{t('Here.TrasaDetail.Table.MytnySystem')}</TableCell>
																	<TableCell>{t('Here.TrasaDetail.Table.PlatebniMetody')}</TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																{
																	section.tolls.map((tIt, i) => (
																		<>
																			{tIt.fares.map((fIt, fareIndex) => (
																				<TableRow className={fareIndex > 0 && classesHere.strikethrough} key={`fare-${fareIndex}`}>
																					<TableCell>{i}</TableCell>
																					{/*<TableCell>{fareIndex}</TableCell>*/}
																					<TableCell>{fIt.price.value} {fIt.price.currency}</TableCell>
																					<TableCell>{fIt.convertedPrice.value} {fIt.convertedPrice.currency}</TableCell>
																					{/*TODO Upravit klic v prekladech.*/}
																					<TableCell>{t(`Here.ExcludeCountry.Country.${tIt.countryCode}`)}</TableCell>
																					<TableCell>{tIt.tollSystem}</TableCell>
																					<TableCell>
																						{fIt.paymentMethods.map(method => t(`Here.TrasaDetail.Table.PlatebniMetoda.${method}`)).join(', ')}{passDisplay(fIt)}
																					</TableCell>
																				</TableRow>
																			))
																			}
																		</>
																	))
																}
															</TableBody>
														</Table>
													</TableContainer>
												</AccordionDetails>
											</Accordion>
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						))}
					</>
				}
				{(props.tolls?.routes?.length > 0 || props.routing?.routes?.length > 0) &&
					<Grid item xs={12}>
						<Accordion style={{border: '1px solid black'}} expanded={displayMap} onChange={() => setDisplayMap(!displayMap)} elevation={3}>
							<AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="p1-content" id="p1-header">
								<Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Here.TrasaDetail.ZobrazitMapu")}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<HereMapComponent routing={props.routing} tolls={props.tolls}/>
							</AccordionDetails>
						</Accordion>
					</Grid>
				}
			</>
		}
	</Grid>
}