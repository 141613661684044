import * as React from "react";
import {cleanName, FormFieldInterface, FormFieldInterfaceProps} from "./FormFieldInterface";
import {exist} from "../../utils/Util";
import {InputAdornment, TextField, TextFieldProps, Tooltip} from "@material-ui/core";
import DialpadIcon from '@material-ui/icons/Dialpad';
import {DynamicInputFontSize} from "../DynamicFontSize";
import {FlagIcon} from "../FlagIcon";


export function ToLowerCase(value:string) {
    return value?.toLowerCase();
}
export function ToUpperCase(value:string) {
    return value?.toUpperCase();
}
export interface FormInputProps extends FormFieldInterfaceProps<any> {
    textFieldProps?: TextFieldProps,
    wrappingFunction?:(value:string)=>any,
    useDynamicFontSize?:boolean
}



export class FormPhoneNumber extends React.Component<FormInputProps, {value:string, backupTooltip?:string, isEmpty?:boolean, useDynamicFontSize?:boolean}> implements FormFieldInterface {
    dom:HTMLInputElement;
    inputRef = React.createRef<HTMLInputElement>();

    state = {
        value:this.props.value,
        backupTooltip: this.props.dataTip,
        isEmpty: false
    };

    userClick = false;
    static defaultProps = {
        showAdornment:true,
    };

    componentDidMount(): void {
        this.props.enableFocusSupport&&this.props.enableFocusSupport();
        if(this.props.focused) {
            this.dom.focus();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<FormInputProps>, nextContext: any): void {
        const triggerFocus = nextProps.focused !== this.props.focused && nextProps.focused;
        this.setState({value:nextProps.value}, ()=>{
            if(triggerFocus) {
                this.dom.focus();
            }
        });
    }

    onValueChange(value:any) {
        this.setState({
            value: value === "" ? null : value,
            isEmpty: value !== ""
        }, ()=>{
            this.props.onValueChanged(this.state.value);
        });
    }



    render() {
        const tProps:TextFieldProps = {...{variant:"outlined"}, ...this.props.textFieldProps};
        const {wrappingFunction=v=>v} = this.props;
        return (
            <>
                <Tooltip title={this.props.dataTip||""} disableHoverListener={!this.props.dataTip} disableFocusListener={!this.props.dataTip} disableTouchListener={!this.props.dataTip}>
                    <TextField
                        {...tProps}
                        size={"small"}
                        inputRef={(input) => { this.dom = input }}
                        ref={this.inputRef}
                        id={cleanName(this.props.name)}
                        name={cleanName(this.props.name)}
                        type={"text"}
                        autoComplete={"off"}
                        hiddenLabel={!Boolean(this.props.title)}
                        label={this.props.title}
                        value={!exist(this.state.value)?"":this.state.value}
                        disabled={this.props.disabled}
                        placeholder={this.props.placeholder}
                        onBlur={this.props.onBlur}
                        onChange={e => {
                            const value = e.target?.value;
                            this.onValueChange(wrappingFunction(value?.trim()));
                        }}
                        onFocus={() => {
                            this.props.onFocus&&this.props.onFocus(this.userClick);
                            this.dom.select();
                        }}

                        onMouseDown={()=>this.userClick = true}
                        onMouseUp={()=>this.userClick = false}
                        onKeyDown={this.props.onKeyDown}
                        onKeyUp={this.props.onKeyUp}
                        fullWidth
                        error={typeof this.props.error !== 'undefined'}
                        helperText={this.props.error}
                        InputProps={{
                            className: this.props.inputClassName,
                            endAdornment:
                                <InputAdornment position="end">
                                    <DialpadIcon/>
                                </InputAdornment>,
                            startAdornment: this.state.value ?
                                <InputAdornment position="start">
                                    <FlagIcon phone={this.state.value} />
                                </InputAdornment> : null
                        }}
                    />
                </Tooltip>
                {this.props.useDynamicFontSize&&<DynamicInputFontSize text={this.state?.value} target={this.inputRef} />}
            </>
        );
    }

}
