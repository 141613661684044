import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {User} from "./User";
import {Firma} from "./Firma";
import {Provozovna} from "./Provozovna";
import {MomentConverters} from "./Converters";
import {Moment} from "moment";
import { OznaceniTyp } from "../page/Dials/user/Oznaceni";

export enum TypZpravy {B="B", C="C", I="I", R="R", S="S", T = "T", H = "H", V = "V", A = "A"}

export class Zprava {
    id:number;

    predmet: string;

    clanek:string;

    @JsonProperty({type:{clazz:User, isArray:true}})
    uzivatele:User[];

    @JsonProperty({type:{enum:TypZpravy}})
    typZpravy:TypZpravy;

    @JsonProperty({converters:MomentConverters})
    platiDo:Moment;

    @JsonProperty({converters:MomentConverters})
    datum:Moment;
}

export class ZpravaDto extends Zprava {
    @JsonProperty({type:{clazz:Firma, isArray:true}})
    firmy:Firma[];
    @JsonProperty({type:{clazz:Provozovna, isArray:true}})
    provozovny:Provozovna[];
}



export class Bleskovka {
    @JsonProperty({type:{clazz:Zprava}})
    zprava:Zprava;
    @JsonProperty({converters:MomentConverters})
    precteno:Moment;
    uzivatelOznaceni?: [OznaceniTyp];
    provozovnaOznaceni?: [OznaceniTyp];
}
