import * as React from "react";
import {useRef, useState} from "react";
import {Dial} from "../../../raal_components/dial/Dial";
import {Form, FormButton, FormField, FormInputType} from "../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {HlidaneSlovo, HlidaneSlovoFirma} from "../../../model/HlidaneSlovo";
import {Firma} from "../../../model/Firma";
import {useFirmaSelect, useYesNoSelect} from "../../../raal_components/SelectOptions";
import {JsonProperty} from "../../../../common/utils/objectmapper/Mapper";
import {StandaloneField} from "../../../../common/component/form/StandaloneField";
import {httpEndpoint} from "../../../../common/utils/HttpUtils";
import {CheckMark} from "../../../../common/component/CheckMark";
import {
    AppBar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    List,
    ListItem,
    ListSubheader,
    Tab,
    Tabs,
    Typography
} from "@material-ui/core";
import {MuiTabPanel} from "../../../../common/component/MuiTabs";
import {invoke} from "../../../../common/utils/Invoke";
import {ScrollableList} from "../../../../common/utils/Util";
import {useStyleContext} from "../../../context/ThemeModeContext";
import {DataGridExposed} from "../../../raal_components/grid/DataGrid";
import {useDidMount} from "../../../../common/component/hooks/SharedHooks";
import {EnableIndicator} from "../../../../common/component/EnableIndicator";
import {useCodeBookDetailContext} from "../../../raal_components/controller/CodeBookDetail";

class VsechnaNevhodnaSlovaNastaveni {
    id: number;
    allWords: boolean;
}
class Filter {
    @JsonProperty({type: {clazz: Firma}})
    firma: Firma;
    slovo: string;
}

function VsechnaSlovaForm({firma, onSaved}:{firma:Firma, onSaved:VoidFunction}) {
    const {t} = useTranslation();
    const url = `admin/firma/nevhodneslovo-vse/${firma.id}`;
    const [nastaveni, setNastaveni] = useState(null as VsechnaNevhodnaSlovaNastaveni);
    useDidMount(()=>{
        httpEndpoint<VsechnaNevhodnaSlovaNastaveni>(VsechnaNevhodnaSlovaNastaveni, url).then(result=>{
            const n = result.data||new VsechnaNevhodnaSlovaNastaveni();
            n.id = firma.id;
            setNastaveni(n)
        })
    });
    return (
        nastaveni&&(
            <Form<VsechnaNevhodnaSlovaNastaveni> data={nastaveni} url={`admin/firma/nevhodneslovo-vse/`} localization={t("FormLocalization", {returnObjects:true})} onResult={onSaved}>
                <FormField title={t("NevhodnaSlova.AllWords")} name={"allWords"} type={FormInputType.Checkbox} />
                <span style={{marginLeft: 10}}><FormButton>{t("Buttons.Save")}</FormButton></span>
            </Form>
        )
    );
}

export function HlidanaSlovaKonfiguraceFirmaCiselnik() {
    const {t} = useTranslation();
    const [firma, setFirma] = useState(null as Firma);
    const firmaSelect = useFirmaSelect();
    const grid = useRef<DataGridExposed<any, any>>();
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const [s, setS] = useState(false);
    const forceUpdate = () => setS(!s);
    return (
        <>
            <Card style={{ marginBottom: 15, zIndex:11 }}>
                <CardHeader title={t("Firma.ChooseFirma")} titleTypographyProps={{variant:'h6'}} style={{ paddingLeft:20 }}/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <StandaloneField title={t("Firma.Title")} type={FormInputType.AutoComplete} value={firma} selectProps={firmaSelect} onValueChanged={setFirma}/>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Box mt={0}>
                                {
                                    firma&&<VsechnaSlovaForm firma={firma} key={firma.id} onSaved={()=>grid.current?.table().refresh()} />
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Dial<HlidaneSlovoFirma, Filter>
                gridRef={grid}
                config={{
                    version:1,
                    tableTitle : t("Dials.NevhodnaSlovaFirma"), endpoint:`admin/firma/nevhodneslovo/${firma ? firma.id : ""}`, clazz : HlidaneSlovoFirma, filtering:true,
                    hideDefaultDummyAction:true,
                    disableRowClick: true,
                    columns: [
                        {
                            title:t("Default.Slovo"),
                            defaultSort: "asc", field:"slovo",
                            filterProps:() => ({type:FormInputType.Text})
                        },
                        {
                            title:t("Firma.EnabledOnFirma"),
                            field:"enabled",
                            render:(data: HlidaneSlovoFirma) => <EnableIndicator method={"PUT"} checked={data.firmaMaSlovoId!==null} url={`admin/firma/nevhodneslovo/${firma ? firma.id : ""}/${data.id}`} afterCommit={() => {
                                forceUpdate();
                            }}/>,
                            filterProps: () => ({type: FormInputType.Select, selectProps:yesNoSelect}),
                            sorting: false

                        },
                        { title:t("Firma.FirmaMaSlovo"), field:"firmaMaSlovoId", render:(data: HlidaneSlovoFirma)=> <CheckMark checked={data.firmaMaSlovoId!==null||data.allWords||data.allCustomers}/>}
                    ],
                    hideAddNewActions:true
                }}
                crudConfig={{editEnabled: false, removeEnabled:false, addEnabled:false}}
                hideNewButtonOnEdit={true}
                filterClazz={Filter}
            />
        </>
    );
}



function HlidaneSlovoSeznamFirem({data}:{data:HlidaneSlovo}) {
    const [firmy, setFirmy] = useState([] as {nazev:string, ico:string}[]);
    const {t} = useTranslation();
    useDidMount(()=>{
        if(!data.vsichniZakaznici){
            const fetchFirmy = async () => {
                const result = await httpEndpoint<ScrollableList<any>>(ScrollableList, `admin/hlidanaslova-firmy/${data.id}?page=-1`);
                setFirmy(result.data.list);
            };
            invoke(fetchFirmy);
        }
    });
    return (
        <>
            <List component="nav" subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    {t("NevhodnaSlova.HlidaneSlovoFirmy")+':'}
                </ListSubheader>
            } style={{marginTop: 10, paddingBottom: 0}}>
                {firmy.length === 0 ?
                    <ListItem><Typography variant={"caption"}>{t(data.vsichniZakaznici ? "NevhodnaSlova.VsichniZakaznici" : "NevhodnaSlova.ZadniZakaznici")}</Typography></ListItem>
                    :
                    firmy.map((a, b)=><ListItem key={b}><Typography variant={"caption"}>{a.nazev} {a.ico?`(${a.ico})`:""}</Typography></ListItem>)}
            </List>
        </>
    );
}

function HlidanaSlovaCiselnikForm(): React.ReactElement {
    const {edited, data} = useCodeBookDetailContext<HlidaneSlovo>();
    const {t} = useTranslation();
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={10}>
                    <FormField name={"slovo"} title={t("Default.Slovo")} type={"text"} required textFieldProps={{inputProps: {maxLength: 100}}}/>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <FormField name={"vsichniZakaznici"} title={t("NevhodnaSlova.VsichniZakaznici")} type={FormInputType.Checkbox} />
                </Grid>
            </Grid>
            {edited&&<HlidaneSlovoSeznamFirem data={data}/>}
        </>
    );
}

export function HlidanaSlovaCiselnik() {
    const {t} = useTranslation();
    const [value, setValue] = useState(0);
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const {classes} = useStyleContext();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    return (
        <>
            <AppBar position="static" style={{boxShadow: 'none', marginTop: 0, marginBottom: 10}} className={classes.invertTabs}>
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary" textColor="primary">
                    <Tab label={t("Dials.HlidanaSlova")} />
                    <Tab label={t("Dials.HlidanaSlovaKonfiguraceFirem")} />
                </Tabs>
            </AppBar>
            <MuiTabPanel value={value} index={0}>
                <Dial<HlidaneSlovo>
                    config={{
                        version:1,
                        tableTitle : t("Dials.HlidanaSlova"), endpoint:`admin/hlidanaslova`, clazz : HlidaneSlovo, filtering:true,
                        exportConfig: {
                            exportable: true,
                            exportAll: true,
                            endpoint: "admin/hlidanaslova",
                            fileName: "export_hlidana_slova",
                            translationPrefix: ['Default', 'NevhodnaSlova'],
                            formats: ["csv"],
                            externalData: [
                                {
                                    type: ["csv"],
                                    asyncData: async (data) => {
                                        try {
                                            if(!data.vsichniZakaznici){
                                                const result = await httpEndpoint<ScrollableList<any>>(ScrollableList, `admin/hlidanaslova-firmy/${data.id}?page=-1`);
                                                let list: any[] = [];
                                                if (result?.data?.list) {
                                                    const d = result.data.list.map((l) => l.ico ?`${l.nazev} (${l.ico})` : l.nazev);
                                                    list = [...d];
                                                }
                                                return {
                                                    HlidaneSlovoFirmy: list.join(', ')
                                                };
                                            } else {
                                                return {};
                                            }
                                        } catch {
                                            return {};
                                        }
                                    }
                                }
                            ],
                            exportableProps: [
                                      {
                                      type: ["csv"],
                                      fields: ["id", "slovo", "vsichniZakaznici", "HlidaneSlovoFirmy"]
                                  }
                            ]
                        },
                        columns: [
                            { title:t("Default.Slovo"), defaultSort: "asc", field:"slovo", filterProps:() => ({type:FormInputType.Text})},
                            { title:t("NevhodnaSlova.VsichniZakaznici"), field:"vsichniZakaznici", filterProps:() => ({type:FormInputType.Select, selectProps:yesNoSelect}), render:(data: HlidaneSlovo)=><CheckMark checked={data.vsichniZakaznici}/>}
                        ]
                    }}
                    createInstance={() => {
                        const slovo = new HlidaneSlovo();
                        slovo.vsichniZakaznici = true;
                        return slovo;
                    }}
                    crudConfig={{editEnabled: true, removeEnabled:true, addEnabled:false}}

                    layoutForm={()=> <HlidanaSlovaCiselnikForm />}
                />
            </MuiTabPanel>
            <MuiTabPanel value={value} index={1}>
                <HlidanaSlovaKonfiguraceFirmaCiselnik/>
            </MuiTabPanel>
        </>
    );
}
