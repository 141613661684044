import React, {
    forwardRef,
    Ref,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react";
import {DataGridExposed, DGExposed} from "../grid/DataGrid";

import {IDClass} from "../../model/CommonTypes";
import {GenericMap} from "../../../index.d";

import {
    CBControllerContext,
    CBControllerContextNew,
    CodeBookControllerExposed,
    CodeBookControllerProps,
    CodeBookControllerState,
    GridData,
    ModalDetailExposed,
    Props,
} from "./CodeBookController.d";
import {useHashParams} from "../../../common/utils/Util";
import {invoke} from "../../../common/utils/Invoke";
import {useAppContext} from "../../context/AppContext";
import {CodeBookDetail, CodeBookDetailNew, useDetail} from "./CodeBookDetail";
import {CodeBookGrid, CodeBookGridNew} from "./CodeBookGrid";
import {CodeBookDetailLoading} from "./CodeBookDetailLoading";
import {useParams} from "react-router-dom";
import {ComponentMode} from "../../routes";
import {useLocation} from "react-router";
import {Mapper} from "../../../common/utils/objectmapper/Mapper";
import {useFetchDetail} from "../../../common/utils/HttpUtils";
import {AppActivitySubType} from "../../model/Aktivita";
import {useHistoryCustom} from "./NavigationHelper";
import {useDetailLocker} from "./DetailLock";


export const CodeBookControllerContext = React.createContext<CBControllerContext<IDClass>>(null);
/**
 * Context nad detailem i gridem, jakekoliv akce prerenderuji jak grid tak i detail
 */
export const useCodeBookControllerContext = <D extends object, T extends object = D>() => useContext(CodeBookControllerContext) as CBControllerContext<D, T>;

export function useHashId(skipGetId?: boolean) {
    const hashParams = useHashParams();
    const { id } = useParams<{id?: string}>();
    if (skipGetId) return null;
    return hashParams.get("id") ? hashParams.get("id") : id ? id : null;
}

export const useBasePath = (path: string) => {
    const { id } = useParams<{id?: string}>();
    return getBasePath(path, id);
};

export const getBasePath = (path: string, id: string) => {
    const lastSlashIndex = path.lastIndexOf(`/${id}`);
    return lastSlashIndex > 0 ? path.slice(0, lastSlashIndex) : path;
};

function ForwardedController<Row extends IDClass, Filter = GenericMap, Detail extends IDClass = Row>(props:Props<Row, Filter, Detail>&{gridRef?:Ref<DataGridExposed<Row, Filter>> }, ref:Ref<CodeBookControllerExposed>) {
    const [state, setState] = useState<CodeBookControllerState<Detail>>({});
    const detailRef = useRef<ModalDetailExposed>();
    const dataGridRef = useRef<DGExposed>();
    const {fetchDetail, showDetail, hideDetail, hashId, reloadDetail, detailLocker} = useDetail<Detail, Row>(state, setState, props, detailRef);
    const isDetailMode = Boolean(hashId) || Boolean(state.id);
    const { asyncClearFunctions} = useAppContext();
    const preventClearFunctions = props.preventClearFunctions;
    const exportConfig = props.config?.exportConfig;

    useImperativeHandle(ref, ()=>({
        reloadDetail
    }));

    useEffect(() =>{
        if (!preventClearFunctions)
            invoke(async() => await asyncClearFunctions());
        // eslint-disable-next-line
    }, [])

    return (
        <CodeBookControllerContext.Provider value={{...state, isDetailMode, fetchDetail, showDetail, hideDetail, hashId, reload:reloadDetail, detailLocker, detailRef, dataGridRef, preventClearFunctions, exportConfig}}>
            <CodeBookDetailLoading />
            <CodeBookDetail<Detail> {...props} detailRef={detailRef} />
            <CodeBookGrid<Row, Filter, Detail> {...props} dataGridRef={dataGridRef} />
        </CodeBookControllerContext.Provider>
    );
}

/**
 * Hlavni komponenta, ktera ma na starosti renderovani gridu a detailu zaznamu.
 * Umi nacitat a zobrazovat detail z url pomoci hash id #id=idzaznamu|new, nebo url parametru, take umi pracovat se zobrazovanim gridu i detailu zaznamu mimo url, k tomu slouzi property isTabbed (to kvuli kaskadovemu uzivani controlleru v tabech)
 * Přidána možnost načítání formu jako modal přes property isModal
 * Přidána možnost přidávat DataGrid do formu přes isFormGrid
 *
 * @param props
 * @constructor
 */
export function CodeBookController<Row extends IDClass, Filter = GenericMap, Detail extends IDClass = Row>(props:Props<Row, Filter, Detail>&{dialRef?:Ref<CodeBookControllerExposed>, gridRef?:Ref<DataGridExposed<Row, Filter>> }) {
    useEffect(() => {
        console.log("CodeBookControler mounted");
    }, [])
    const FW = forwardRef<CodeBookControllerExposed, Props<Row, Filter, Detail>>(ForwardedController);
    const {dialRef, ...props2} = props;
    return <FW {...props2} ref={dialRef} />;

}

export const CodeBookControllerContextNew = React.createContext<CBControllerContextNew<IDClass>>(null);
/**
 * Context nad detailem i gridem, jakekoliv akce prerenderuji jak grid tak i detail
 */
export const useCodeBookControllerContextNew = <D extends object, T extends object = D>() => useContext(CodeBookControllerContextNew) as CBControllerContextNew<D, T>;

export const useCodeBookController = <Detail extends IDClass, T extends IDClass>(props:CodeBookControllerProps<T, any, Detail>) => {
    const {push} = useHistoryCustom();
    const {pathname, state:locState, hash} = useLocation<{data:GridData<Detail>, previousPage?: string, forcePreviousPage?:string, entity?:string, previousPages?: string[]}>();
    const clazz = (props.clazzDetail ?? props.config.clazz) as {new(): Detail};
    const mapper = useMemo(()=>new Mapper({constructor: clazz} ), [clazz]);
    const {fetch:_fetch} = useFetchDetail<Detail>(clazz, props.config.endpointDetail ?? props.config.endpoint);
    const {logActivity} = props;
    const detailLocker = useDetailLocker(props.config);

    const fetchDetail = useCallback(async (type:AppActivitySubType, id:string|number) => {
        return await _fetch({
            arg:id,
            params:logActivity ? {"type":type} : null
        });
    }, [_fetch, logActivity]);

    const setDetail = (id: string, data?: Detail, origin?: Detail, merge?:boolean) => {
        const endpoint = `${pathname}/${id}`;
        const tabEndpoint = props.tabDetailUrl ? `${props.tabDetailUrl}/${id}` : null;
        push(tabEndpoint ?? endpoint, {
            data: {
                data: data ? mapper.writeValueAsJson(data, {springSupport: false}) : null,
                origin: origin ? mapper.writeValueAsJson(origin, {springSupport: false}) : null,
                merge: merge,
            },
            forcePreviousPage: locState?.forcePreviousPage,
            previousPages: tabEndpoint ? [...locState?.previousPages??[], `${pathname}${hash}`] : locState?.previousPages??[],
            entity: locState?.entity,
            tabDetailData: props.tabDetailData
        });
    };

    return {detailLocker, fetchDetail, setDetail}
}

/**
 * Hlavni komponenta, ktera ma na starosti renderovani gridu a detailu zaznamu.
 *
 * @param props
 * @constructor
 */
export const CodeBookControllerNew = <Row extends IDClass, Filter = GenericMap, Detail extends IDClass = Row>(props:Props<Row, Filter, Detail>) => {
    const {asyncClearFunctions} = useAppContext();
    const detailRef = useRef<ModalDetailExposed>();
    const dataGridRef = useRef<DGExposed>();
    const {fetchDetail, detailLocker, setDetail} = useCodeBookController<Detail, Row>(props);
    const exportConfig = props.config?.exportConfig;
    const [r, reload] = useState(false);
    const [rp, replace] = useState(false);

    if (!props.preventClearFunctions)
        invoke(async() => await asyncClearFunctions()); // eslint-disable-next-line

    useEffect(() => {
        console.log("CodeBookControlerNew mounted");
    }, [])

    const reloadDetailForm = () => {
        reload(!r);
    }

    const replaceDetailForm = () => {
        replace(true);
        setTimeout(() => replace(false), 10)
    }

    return <CodeBookControllerContextNew.Provider value={{fetchDetail, detailLocker, detailRef, dataGridRef, exportConfig, setDetail, reloadDetailForm, replaceDetailForm}}>
        {props.mode === ComponentMode.GridMode ?
            <CodeBookGridNew<Row, Filter, Detail> {...props} dataGridRef={dataGridRef} />
            :
            !rp ? <CodeBookDetailNew<Detail> {...props} detailRef={detailRef} /> : null}
    </CodeBookControllerContextNew.Provider>
}
