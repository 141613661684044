import React from 'react';
import {Grid} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {Dial} from "../../../raal_components/dial/Dial";
import {Zprava} from "../../../model/Zprava";
import {FormField, FormInputType} from "../../../raal_components/form/Form";
import {RouteComponentProps} from "../../../routes";


export function NewsForm() {
    const {t} = useTranslation();
    return <Grid container spacing={1}>
            <Grid item xs={12} sm={12} lg={12}>
                <FormField type="datetime" name={"platiDo"} title={t("ThunderNews.MessagesValidTo")} />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <FormField type="text" name={"predmet"} title={t("ThunderNews.Predmet")} textFieldProps={{rows:20}}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <FormField type="text" name={"typZpravy"} title={t("ThunderNews.TypZpravy")} textFieldProps={{rows:20}}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <FormField type="editor" name={"clanek"} title={t("Default.Text")} textFieldProps={{rows:20}}/>
            </Grid>
        </Grid>;
}

export function NewsAdmin(props: RouteComponentProps) {
    const {t} = useTranslation();

    return (
        <Dial<Zprava>
            mode={props.mode}
            config={{
                tableTitle : t("Dials.Messages"),
                columns:[{
                    field:"datum",
                    title:t("Default.Datum"),
                    defaultSort:"desc",
                    render:data=>data.datum?.format("L LT")
                },
                    {
                        field:"platiDo",
                        title:t("ThunderNews.MessagesValidTo"),
                        render:data=>data.platiDo?.format("L LT")
                    },
                    {
                        field:"predmet",
                        title:t("ThunderNews.Predmet")
                    },
                    {
                        field:"typZpravy",
                        title:t("ThunderNews.MessagesType"),
                        render:data=>t(`Enumerations.TypZpravy.${data.typZpravy}`),
                        filterProps:() => ({type:FormInputType.Text})
                    }
                ],
                clazz: Zprava,
                endpoint:"admin/zprava",
                filtering:true,
                hideAddNewActions: true,
            }}
            isDetailReadOnly
            formDisabled={() => true}
            crudConfig={{addEnabled:false, editEnabled: true, removeEnabled:false}}
            layoutForm={()=><NewsForm/>}
        />
    );
}
