import {InvalidDuvodInzerat, InzeratViewAdmin} from "../../../../model/Inzerat";
import {useTranslation} from "react-i18next";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import {useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {useCodeBookControllerContextNew} from "../../../../raal_components/controller/CodeBookController";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@material-ui/core";
import {invalidateInzerat, validateInzerat} from "../../../../../common/logic/inzerat-logic";
import Avatar from "@material-ui/core/Avatar";
import {Assignment, Visibility} from "@material-ui/icons";
import {TextTuple, TextTupleType} from "../../../../../common/component/form/TextTuple";
import React from "react";

interface InzeratViewAdminTabProps {
    inzerat: InzeratViewAdmin | any;
    history?: boolean;
    archive?: boolean;
}

export function InzerceViewAdminPart(props: InzeratViewAdminTabProps) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.I);
    const {replaceDetailForm} = useCodeBookControllerContextNew<InzeratViewAdmin>();
    const {inzerat} = props;
    return <Grid container spacing={2} alignItems="stretch" direction="row">
        <Grid item lg={12} sm={12} xs={12}>
            <Card style={{height: "100%"}} className={classes.sectionBackground}>
                <CardContent>
                    <Box marginBottom={2}>
                        <Typography variant="h6">{t("Sections.Administration")}</Typography>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} spacing={3}>
                            {props.history || props.archive ? undefined : <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemText>
                                        <Box hidden={inzerat.invalDuv===InvalidDuvodInzerat.ZNEPLATNENO_OPERATOREM}>
                                            <Grid item>
                                                <Button variant={"contained"} color={"secondary"}
                                                        onClick={async () => {
                                                            await invalidateInzerat(inzerat.id);
                                                            replaceDetailForm();
                                                        }}
                                                >{t("Buttons.MakeInvalid")}</Button>
                                            </Grid>
                                        </Box>
                                        <Box hidden={inzerat.invalDuv!==InvalidDuvodInzerat.ZNEPLATNENO_OPERATOREM}>
                                            <Grid item>
                                                <Button variant={"contained"} color={"secondary"}
                                                        onClick={async () => {
                                                            await validateInzerat(inzerat.id);
                                                            replaceDetailForm();
                                                        }}>{t("Buttons.MakeValid")}</Button>
                                            </Grid>
                                        </Box>
                                    </ListItemText>
                                </ListItem>
                            </Grid>}
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-admin"}><Visibility/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("PVI.Valid")} content={inzerat.valid} type={TextTupleType.Checkbox}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                            <Grid item lg={3} sm={6} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar className={"avatar-admin"}><Assignment/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("PVI.InvalDuv")} content={locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, inzerat.invalDuv)} type={TextTupleType.Text}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}
