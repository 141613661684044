import * as React from "react";
import {Dial} from "../../../raal_components/dial/Dial";
import {FormField, FormInputType} from "../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {NezadouciSlovo} from "../../../model/NezadouciSlovo";
import {Grid} from "@material-ui/core";
import {RouteComponentProps} from "../../../routes";

export function ZakazanaSlovaCiselnik(props: RouteComponentProps) {
    const {t} = useTranslation();
    return <Dial<NezadouciSlovo>
        mode={props.mode}
        config={{
            version:1,
            tableTitle : t("Dials.ZakazanaSlova"),
            endpoint:"admin/nezadouciSlovo",
            clazz : NezadouciSlovo,
            filtering: true,
            exportConfig: {
                exportable: true,
                exportAll: true,
                endpoint: "admin/nezadouciSlovo",
                fileName: "export_zakazana_slova",
                translationPrefix: 'Default',
                formats: ["csv"],
            },
            columns: [
                {
                    title:t("Default.Slovo"),
                    defaultSort: "asc",
                    field:"slovo",
                    filterProps:() => ({type:FormInputType.Text}),
                    cellStyle: {minWidth: 200},
                    headerStyle: {minWwidth: 200},
                    filterStyle: {minWidth: 200},
                    disableDynamicFontSize: true
                },
            ]
        }}
        crudConfig={{editEnabled: true, removeEnabled:true, addEnabled:false}}
        modalHeaderName={()=>t("Default.ModalZakladni")}
        layoutForm={() => {
           return (
               <Grid container spacing={2}>
                    <Grid item lg={12} sm={12} xs={12}>
                        <FormField name={"slovo"} title={t("Default.Slovo")} type={"text"} dataTip={"Vyplňte slovo, které uživatel nesmí použít"} required textFieldProps={{inputProps: {maxLength: 100}}}/>
                    </Grid>
                </Grid>
           );
        }}
    />;
}
