import { httpEndpointCustom } from '../common/utils/HttpUtils';
import { showSnack } from '../common/component/SnackContainer';
import { User } from './model/User';

export const receiveFCMToken = (token: string) => {
	localStorage.setItem("fcmtoken", token);
	console.log('Received FCM Token in WebView:', token);
	return token;
}

export const saveFCMTokenToUser = async (user: User) => {
	const fcmtoken = localStorage.getItem("fcmtoken");
	if (fcmtoken && (user.fcmToken === null || user.fcmToken === undefined || user.fcmToken.trim() === "" || user.fcmToken !== fcmtoken)) {
		const result = await httpEndpointCustom(`user/mobile/fcmtoken/${fcmtoken}`,
			{
				method: "POST"
			});
		console.log('FCM Token saved:', result);
	}
	// if (result?.response.status !== 200)
	// 	showSnack({title: "Error", severity: "error"});
}

export const pushNotificationCall = async (phone: string) => {
	const result = await httpEndpointCustom(`user/mobile/call/${phone}`,
		{
			method: "POST"
		});

	if (result?.response.status !== 200)
		showSnack({title: "Error", severity: "error"});
}