//load styles
import '../assets/main.scss';
//load react
import React from 'react';
import ReactDOM from 'react-dom';
//load application lang
// import config from '../Config';
//load localizations
import 'moment/min/locales';
import 'numeral/locales/';
//import other functions
import {setHttpConfig} from "../common/utils/HttpUtils";
import {exist} from "../common/utils/Util";
//import Root page Object
import Root from "./Root";

import "../common/utils/MomentExtensions";
import {setNominatimUrl} from "../common/component/map/Nominatim";
import {setTilesUrl} from "../common/component/map/MapOSM";
import { defaultConfig, getConfig, setConfig } from '../Config';
//some localization initialization and set default locale

function loadConfig() {
	fetch('/config.json')
		.then(response => {
			if (!response.ok) {
				console.error('Failed to load configuration.');
				initializeApp(getConfig());
			}
			return response.json();
		})
		.then(config => {
			initializeApp(config);
		})
		.catch(error => {
			console.error('Failed to load configuration.');
			initializeApp(getConfig());
		});
}

loadConfig();

// @ts-ignore
function initializeApp(config) {
	setConfig(config);

	if (!config || !config?.backendClientId) {
		setConfig(defaultConfig);
	}

	setNominatimUrl(getConfig().nominatimUrl);
	setTilesUrl(getConfig().tilesUrl);

	//set endpoint lang
	setHttpConfig({
		apiUrl:`${getConfig().backendUrl}/raal-api/`
	});

	//render root page
	if(exist(getConfig().backendUrl)  && exist(getConfig().backendClientId)) {
		ReactDOM.render(<Root />, document.getElementById('root'));
	} else {
		ReactDOM.render(<div>Prosím obnovte obrazovku pomocí tlačítka obnovit v prohlížeči. Případně použijte klávesu F5.</div>, document.getElementById('root'));
	}
}