import * as React from "react";
import {useTranslation} from "react-i18next";
import {Prompt} from "react-router-dom";
import {useAppContext} from "../../web/context/AppContext";

export const PromptCustom = () => {
    const {t} = useTranslation();
    const {checkDataChanged} = useAppContext();

    return <Prompt
        message={(location, action) => {
            if (action === 'POP' && checkDataChanged()) {
                return t("DataChangedDialog.Body").toString();
            } else {
                return true;
            }
        }}
    />
}
